import { selectUser } from "@/core/store/users/usersSlice";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Caption,
  Flex,
  IconButton,
  Text,
} from "@gogeo-io/ui-library";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

export function UserMenuAvatar(): JSX.Element {
  const user = useSelector(selectUser);

  return (
    <Flex css={{ gap: "$2", alignItems: "center", cursor: "pointer" }}>
      <>
        {!isEmpty(user.user_photo) ? (
          <Avatar size="small">
            {user.user_photo.photo_sm_url ? (
              <AvatarImage src={user.user_photo.photo_sm_url} />
            ) : (
              <AvatarFallback>{user.user_photo.fallback}</AvatarFallback>
            )}
          </Avatar>
        ) : (
          <Avatar size="small">
            <AvatarFallback>GO</AvatarFallback>
          </Avatar>
        )}
      </>
      <Flex css={{ flexDirection: "column", gap: "0px" }}>
        <Text size="body2">{user.name}</Text>
        <Caption>{user.email ?? "Não tem e-mail"}</Caption>
      </Flex>
      <IconButton color="gray" type="ghost" size="medium">
        <KeyboardArrowDownIcon fontSize="inherit" />
      </IconButton>
    </Flex>
  );
}
