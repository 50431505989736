import { EmptyContent } from "@/components/EmptyContent";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { AdvancedFiltersTags } from "@/features/attendance/components/advancedFilters/AdvancedFiltersTags";
import {
  getAvailableHistoryFilters,
  selectAvailableHistoryFilters,
  selectAvailableHistoryFiltersStatus,
  selectSelectedHistoryFilters,
  setSelectedHistoryFilters,
} from "@/features/history/store/historyFiltersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Caption,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  IconButton,
} from "@gogeo-io/ui-library";
import { Close } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { MdAdd, MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { AddNewAdvancedFiltersSkeleton } from "./AddNewAdvancedFiltersSkeleton";
import { FiltersThatTheUserCanSelect } from "./FiltersThatTheUserCanSelect";

export function AddNewAdvancedFilterToHistoryFilter() {
  const [advancedFiltersDialogIsOpen, setAdvancedFilterDialogIsOpen] =
    useState(false);
  const [tagsFiltersDialogIsOpen, setTagsFilterDialogIsOpen] = useState(false);

  const availableHistoryFilters = useSelector(selectAvailableHistoryFilters);
  const selectedHistoryFilters = useSelector(selectSelectedHistoryFilters);
  const availableHistoryFiltersStatus = useSelector(
    selectAvailableHistoryFiltersStatus
  );
  const [selectedHistoryFilterToAdd, setSelectedHistoryFilterToAdd] = useState<
    AdvancedFilter[]
  >([]);

  const dispatch = useAppDispatch();

  const handleSetSelectedClientFilter = async (advFilter: AdvancedFilter) => {
    const newFilters = [...selectedHistoryFilters, advFilter];

    setSelectedHistoryFilterToAdd(newFilters);
    await dispatch(setSelectedHistoryFilters(newFilters));

    handleSetSelectedClientFilterToEmpty();

    setAdvancedFilterDialogIsOpen(false);
  };

  const handleSetSelectedClientFilterToEmpty = () =>
    setSelectedHistoryFilterToAdd([]);

  const getFilterThatTheUserCanSelect = () => {
    const filters = availableHistoryFilters.filter(
      (availableFilter) =>
        !selectedHistoryFilters.some(
          (selectedFilter) => selectedFilter.id === availableFilter.id
        )
    );

    return filters;
  };

  useEffect(() => {
    async function loadHistoryAvailableFilters() {
      await dispatch(getAvailableHistoryFilters());
    }

    loadHistoryAvailableFilters();
  }, []);

  useEffect(() => {
    if (selectedHistoryFilters.length === 0) {
      handleSetSelectedClientFilterToEmpty();
    }
  }, [selectedHistoryFilters]);

  const filtersThatTheUserCanSelect = useMemo(
    () => getFilterThatTheUserCanSelect(),
    [availableHistoryFilters, selectedHistoryFilters]
  );

  return (
    <>
      <Dialog
        open={advancedFiltersDialogIsOpen}
        onOpenChange={(e) => setAdvancedFilterDialogIsOpen(e)}
      >
        <DialogTrigger asChild>
          <AddFilterButton onClick={() => setAdvancedFilterDialogIsOpen(true)}>
            <Caption css={{ color: "inherit" }} bold>
              Adicionar Filtro de ...
            </Caption>

            <MdAdd />
          </AddFilterButton>
        </DialogTrigger>
        <DialogContent style={{ minWidth: "400px" }}>
          <DialogClose asChild={true}>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "0.5rem", right: "0.9rem" }}
            >
              <Close />
            </IconButton>
          </DialogClose>
          <Flex
            css={{
              flexDirection: "column",
              gap: "$4",
              height: "100%",
            }}
          >
            {availableHistoryFiltersStatus === "success" ? (
              <Flex
                css={{
                  overflowY: "scroll",
                }}
              >
                {availableHistoryFilters.length === 0 ? (
                  <EmptyContent size="small">
                    Não encontramos filtros disponíveis
                  </EmptyContent>
                ) : (
                  <FiltersThatTheUserCanSelect
                    filtersThatTheUserCanSelect={filtersThatTheUserCanSelect}
                    selectedFilterToAdd={selectedHistoryFilterToAdd}
                    onSetSelectedFilter={handleSetSelectedClientFilter}
                    onOpenTagsDialog={() => setTagsFilterDialogIsOpen(true)}
                    onCloseAdvancedFiltersDialog={() =>
                      setAdvancedFilterDialogIsOpen(false)
                    }
                  />
                )}
              </Flex>
            ) : (
              <AddNewAdvancedFiltersSkeleton count={5} />
            )}
          </Flex>
        </DialogContent>
      </Dialog>

      <Dialog
        open={tagsFiltersDialogIsOpen}
        onOpenChange={(e) => setTagsFilterDialogIsOpen(e)}
      >
        <DialogTrigger asChild />

        <TagsDialogContent>
          <DialogClose asChild={true}>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
            >
              <MdClose />
            </IconButton>
          </DialogClose>

          <Flex
            css={{
              flexDirection: "column",
              gap: "$4",
              height: "100%",
            }}
          >
            <AdvancedFiltersTags
              onCloseDialogOfAdvancedFilters={() =>
                setAdvancedFilterDialogIsOpen(false)
              }
              onCloseDialogOfTags={() => setTagsFilterDialogIsOpen(false)}
            />
          </Flex>
        </TagsDialogContent>
      </Dialog>
    </>
  );
}

const AddFilterButton = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[1]} 0.5rem;
  border-radius: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.gray800};
  background: ${(props) => props.theme.colors.gray600};
  color: ${(props) => props.theme.colors.gray50};
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:hover {
    background: ${(props) => props.theme.colors.gray800};
  }

  svg {
    color: ${(props) => props.theme.colors.gray50};
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;

const TagsDialogContent = styled(DialogContent)`
  height: 780px;

  @media (max-width: ${({ theme }) => theme.mediaSizes.laptop}) {
    width: 80%;
  }

  @media (max-width: ${({ theme }) => theme.mediaSizes.smallLaptop}) {
    width: 98%;
  }
`;
