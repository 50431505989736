import { Params } from "@/core/models/params.model";
import UI from "../ui";
import { TimelineAttendanceRecordHeaderOfParams } from "./TimelineAttendanceRecordHeaderOfParams";
import { TimelineVerticalMark } from "./TimelineVerticalMark";

export interface TimeLineItemProps {
  params: Params;
}

export const TimelineItemOfParams = ({ params }: TimeLineItemProps) => {
  return (
    <UI.TimelineWrapper>
      <div className="left">
        <TimelineVerticalMark />
      </div>
      <div className="right">
        <TimelineAttendanceRecordHeaderOfParams params={params} />
      </div>
    </UI.TimelineWrapper>
  );
};
