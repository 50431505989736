export const useFormatPrice = () => {
  const formatBRLPrice = (value: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const formatNumberToDecimal = (value: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }).format(value);
  };

  const getMinMaxFromValue = (value: string) => {
    if (value) {
      const values = value.split("~");

      const minMax = {
        min: Number(values[0]),
        max: Number(values[1]),
      };

      return minMax;
    }
  };

  return { formatBRLPrice, formatNumberToDecimal, getMinMaxFromValue };
};
