import { UserCannotHaveAcess } from "@/components/UserCannotHaveAccess";
import { User } from "@/core/models/user.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, Text } from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useSelector } from "react-redux";
import {
  selectReportFilters,
  setReportFiltersStatus,
  updateReportFilter,
} from "../store/reportSlice";
import { ReportDateFilter } from "./ReportDateFilter";
import { ReportFilterContainer } from "./ReportFilterContainer";
import { ReportSupervisorsFilter } from "./ReportSupervisorsFilter";

interface ReportFiltersProps {
  salesmans: User[];
  supervisors: User[];
  selectedSalesmans: User[];
  selectedSupervisors: User[];
  onSetSalesmans: (user: User[]) => void;
  onSetSelectedSupervisors: (user: User[]) => void;
  onSetSelectedSalesmans: (user: User[]) => void;
}

export function ReportFilters({
  salesmans,
  supervisors,
  selectedSalesmans,
  selectedSupervisors,
  onSetSalesmans,
  onSetSelectedSalesmans,
  onSetSelectedSupervisors,
}: ReportFiltersProps) {
  const reportFilters = useSelector(selectReportFilters);

  const dispatch = useAppDispatch();

  const handleChangeDate = async (_, newDate: string) => {
    await dispatch(setReportFiltersStatus("not-filled"));
    await dispatch(
      updateReportFilter({ filter_field: "date_filter", value: newDate })
    );
    await dispatch(setReportFiltersStatus("filled"));
  };

  const handleChangeSalesmans = async (salesmans: User[]) => {
    await dispatch(setReportFiltersStatus("not-filled"));
    await dispatch(
      updateReportFilter({ filter_field: "salesmans", value: salesmans })
    );
    onSetSelectedSalesmans(salesmans);
    await dispatch(setReportFiltersStatus("filled"));
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$1" }}>
      <Text wider css={{ color: "$gray500" }}>
        Filtros
      </Text>
      <ReportFilterContainer>
        <Flex
          css={{
            gap: "$2",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {isEmpty(reportFilters) ? (
            <Text>Sem filtros para esse relatório</Text>
          ) : (
            <>
              {!isUndefined(reportFilters["date_filter"]) && (
                <ReportDateFilter
                  dateToReport={reportFilters["date_filter"]}
                  onChangeDate={handleChangeDate}
                />
              )}

              {!isUndefined(reportFilters["salesmans"]) && (
                <UserCannotHaveAcess roles={["SALESMAN"]}>
                  <ReportSupervisorsFilter
                    supervisors={supervisors}
                    salesmans={salesmans}
                    selectedSalesmans={selectedSalesmans}
                    selectedSupervisors={selectedSupervisors}
                    onSetSalesmans={onSetSalesmans}
                    onSetSelectedSalesmans={handleChangeSalesmans}
                    onSetSelectedSupervisors={onSetSelectedSupervisors}
                  />
                </UserCannotHaveAcess>
              )}
            </>
          )}
        </Flex>
      </ReportFilterContainer>
    </Flex>
  );
}
