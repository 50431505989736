import { SelectOption } from "@/core/models/advanced-filters.model";
import { getOptionsOfSelectAndMultiselectFilter as clientsOptions } from "@/features/attendance/store/clientAdvancedFiltersSlice";
import { getOptionsOfSelectAndMultiselectFilter as historyOptions } from "@/features/history/store/historyFiltersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { ReactNode, useEffect, useState } from "react";
import { GenericFilterPopover } from "../GenericFilterPopover";
import { SelectGenericOption } from "./SelectGenericOption";
import { SelectGenericOptionSkeleton } from "./SelectGenericOptionSkeleton";

interface GenericDateFilterProps {
  children?: ReactNode;
  hasError?: boolean;
  filterId: number;
  value: SelectOption;
  disableScroll?: boolean;
  optionsOf?: "history" | "clients";
  onUpdatedFilter: (filterId: number, newOption: SelectOption) => void;
}

interface FilterToChange {
  filterId: number;
  option: SelectOption;
}

export function GenericSelectFilter({
  children,
  hasError = false,
  filterId,
  value,
  disableScroll = false,
  optionsOf = "clients",
  onUpdatedFilter,
}: GenericDateFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterChanged, setFilterChanged] = useState<FilterToChange>(
    {} as FilterToChange
  );

  const dispatch = useAppDispatch();

  const handleOptionSelected = async (option: SelectOption) => {
    const filter: FilterToChange = { filterId, option };
    setFilterChanged(filter);
  };

  const handlePopoverOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const openPopover = () => {
    setIsOpen(true);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  const applyFilter = () => {
    onUpdatedFilter(filterChanged.filterId, filterChanged.option);
    setFilterChanged({} as FilterToChange);
    closePopover();
  };

  useEffect(() => {
    setIsLoading(true);

    const loadOptions = async () => {
      const dispatchOptions = async (action: any) => {
        const res = await dispatch(action({ filter_id: filterId }));
        if (res.meta.requestStatus === "fulfilled") {
          const responseOptions: SelectOption[] = res.payload as SelectOption[];
          setOptions(responseOptions);
          setIsLoading(false);
        }
      };

      if (optionsOf === "clients") {
        await dispatchOptions(clientsOptions);
      } else {
        await dispatchOptions(historyOptions);
      }
    };

    loadOptions();
  }, []);

  return (
    <GenericFilterPopover
      trigger={children}
      disableScroll={disableScroll}
      popoverIsOpen={hasError ? false : isOpen}
      onOpenPopover={!hasError && openPopover}
      onPopoverOpenChange={!hasError && handlePopoverOpenChange}
    >
      {isLoading ? (
        <SelectGenericOptionSkeleton />
      ) : (
        <SelectGenericOption
          optionSelected={value}
          setOptionSelected={handleOptionSelected}
          options={options}
          onApplyFilter={applyFilter}
        />
      )}
    </GenericFilterPopover>
  );
}
