import { ReportParams } from "@/core/models/reports.model";
import { User } from "@/core/models/user.model";
import {
  getUsersRelatedToUser,
  selectUser,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { isUndefined } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ReportFilters } from "../components/ReportFilters";
import { ReportItem } from "../components/ReportItem";
import {
  selectFiltersStatus,
  selectSelectedReport,
  setReportFilters,
  setReportFiltersStatus,
} from "../store/reportSlice";
import { ReportSkeletonPage } from "./ReportSkeletonPage";

export function SpecificReport() {
  const selectedReport = useSelector(selectSelectedReport);
  const filtersStatus = useSelector(selectFiltersStatus);
  const user = useSelector(selectUser);

  const [selectedSupervisors, setSelectedSupervisors] = useState<User[]>([]);
  const [supervisors, setSupervisors] = useState<User[]>([]);
  const [selectedSalesmans, setSelectedSalesmans] = useState<User[]>([]);
  const [salesmans, setSalesmans] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();

  const dateFilter = useMemo(() => {
    return selectedReport.filter_fields.find(
      (filter) => filter.name === "date_filter"
    );
  }, [selectedReport.filter_fields]);

  const salesmanFilters = useMemo(() => {
    return selectedReport.filter_fields.find(
      (filter) => filter.name === "salesman_ids"
    );
  }, [selectedReport.filter_fields]);

  useEffect(() => {
    const loadFilters = async () => {
      await dispatch(setReportFiltersStatus("not-filled"));
      setIsLoading(true);

      let filters: ReportParams = {} as ReportParams;

      if (dateFilter) {
        filters = {
          ...filters,
          date_filter: dateFilter?.default_value ?? "inthis1months",
        };
      }

      if (salesmanFilters) {
        if (user.role === "SALESMAN") {
          setSelectedSalesmans([user]);
          filters = {
            ...filters,
            salesmans: [user],
          };
        } else {
          await dispatch(getUsersRelatedToUser({ user_id: user.id })).then(
            async (res) => {
              if (res.meta.requestStatus === "fulfilled") {
                const users: User[] = res.payload;

                const { salesmans, supervisors } = users.reduce(
                  (acc, user) => {
                    if (user.role === "SALESMAN") {
                      acc.salesmans.push(user);
                    } else {
                      acc.supervisors.push(user);
                    }
                    return acc;
                  },
                  { salesmans: [], supervisors: [] }
                );

                setSelectedSupervisors(supervisors);
                setSupervisors(supervisors);
                setSelectedSalesmans(salesmans);
                setSalesmans(salesmans);

                filters = {
                  ...filters,
                  salesmans: salesmans,
                };
              }
            }
          );
        }
      }

      await dispatch(setReportFilters(filters));
    };

    loadFilters().then(async () => {
      await dispatch(setReportFiltersStatus("filled"));
      setIsLoading(false);
    });
  }, [selectedReport]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        width: "100%",
        height: "100%",
      }}
    >
      {filtersStatus === "filled" && !isLoading ? (
        <>
          <ReportFilters
            salesmans={salesmans}
            supervisors={supervisors}
            selectedSalesmans={selectedSalesmans}
            selectedSupervisors={selectedSupervisors}
            onSetSalesmans={(users) => setSalesmans(users)}
            onSetSelectedSupervisors={(users) => setSelectedSupervisors(users)}
            onSetSelectedSalesmans={(users) => setSelectedSalesmans(users)}
          />
          {!isUndefined(salesmanFilters) && selectedSalesmans.length <= 0 && (
            <Caption css={{ color: "$red500" }}>
              Selecione ao menos um vendedor para visualizar o relatório
            </Caption>
          )}

          <ReportItem />
        </>
      ) : (
        <ReportSkeletonPage />
      )}
    </Flex>
  );
}
