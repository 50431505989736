import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import {
  BusinessAssociateService,
  BusinessAssociateServices,
} from "@/core/models/business-associate.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface State {
  has500error: boolean;
  businessAssociatesServicesConfig: BusinessAssociateServices[];
  selectedBusinessAssociate: BusinessAssociateServices;
  selectedBusinessAssociateService: BusinessAssociateService;
  status: "loading" | "success" | "failed";
}

const initialState = {
  has500error: false,
  businessAssociatesServicesConfig: [],
  selectedBusinessAssociate: {},
  selectedBusinessAssociateService: {},
  status: "loading",
} as State;

export const getBusinessAssociatesServicesConfig = createAsyncThunk(
  "businessAssociatesServicesConfig/getBusinessAssociatesServicesConfig",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/business-associate/actions");
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface AssociateBusinessAssociateAndCompanyProps {
  service_id: number;
  body: object;
}

export const associateBusinessAssociateServicesConfigAndCompany =
  createAsyncThunk(
    "businessAssociateS/associatePartnerAndCompany",
    async (
      { service_id, body }: AssociateBusinessAssociateAndCompanyProps,
      thunkAPI
    ) => {
      try {
        const response = await axios.post(
          `/business-associate/actions/associate/${service_id}`,
          body
        );
        if (
          response.status !== 200 &&
          response.status !== 202 &&
          response.status !== 204
        ) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  );

export const businessAssociatesServicesConfigSlice = createSlice({
  name: "businessAssociatesServicesConfigSlice",
  initialState,
  reducers: {
    setSelectedBusinessAssociateService(
      state,
      action: PayloadAction<BusinessAssociateService>
    ) {
      state.selectedBusinessAssociateService = action.payload;
    },
    setSelectedBusinessAssociate(
      state,
      action: PayloadAction<BusinessAssociateServices>
    ) {
      state.selectedBusinessAssociate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getBusinessAssociatesServicesConfig.pending,
      (state, action) => {
        state.status = "loading";
      }
    );
    builder.addCase(
      getBusinessAssociatesServicesConfig.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.businessAssociatesServicesConfig = action.payload;
      }
    );
    builder.addCase(
      getBusinessAssociatesServicesConfig.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting  the business associates");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      associateBusinessAssociateServicesConfigAndCompany.pending,
      (state, action) => {
        state.status = "loading";
      }
    );
    builder.addCase(
      associateBusinessAssociateServicesConfigAndCompany.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
      }
    );
    builder.addCase(
      associateBusinessAssociateServicesConfigAndCompany.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting on update business associate service"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectBusinessAssociatesServicesConfig = (state: RootState) =>
  state.businessAssociatesServicesConfigState.businessAssociatesServicesConfig;
export const selectSelectedBusinessAssociateService = (state: RootState) =>
  state.businessAssociatesServicesConfigState.selectedBusinessAssociateService;
export const selectSelectedBusinessAssociate = (state: RootState) =>
  state.businessAssociatesServicesConfigState.selectedBusinessAssociate;
export const selectBusinessAssociatesServicesConfigStatus = (
  state: RootState
) => state.businessAssociatesServicesConfigState.status;
export const selectBusinessAssociatesServicesConfigHas500error = (
  state: RootState
) => state.businessAssociatesServicesConfigState.has500error;

export const {
  setSelectedBusinessAssociateService,
  setSelectedBusinessAssociate,
} = businessAssociatesServicesConfigSlice.actions;

export const businessAssociatesServicesConfigReducer =
  businessAssociatesServicesConfigSlice.reducer;
