import { Flex } from "@gogeo-io/ui-library";
import React from "react";
import RLSSkeleton from "react-loading-skeleton";

export const ClientOnKanbanSkeleton = React.forwardRef<HTMLDivElement, {}>(
  () => {
    return (
      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        <Flex css={{ gap: "$2" }}>
          <RLSSkeleton width={48} height={48} borderRadius={4} />
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <RLSSkeleton width={184} height={23} borderRadius={4} />
            <RLSSkeleton width={100} height={16} borderRadius={4} />
          </Flex>
        </Flex>

        <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
          <Flex css={{ flexDirection: "column" }}>
            <RLSSkeleton width={48} height={16} borderRadius={4} />
            <RLSSkeleton width={95} height={16} borderRadius={4} />
          </Flex>
          <RLSSkeleton width={32} height={32} borderRadius={4} />
        </Flex>
      </Flex>
    );
  }
);
