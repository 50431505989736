import { pt } from "date-fns/locale";
import { useState } from "react";
import { DateRange } from "react-date-range";

interface SelectedDate {
  selection: {
    endDate: Date;
    key: string;
    startDate: Date;
  };
}

interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  disablePastDate?: boolean;
}
export function DateRangePicker({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  disablePastDate = false,
}: DateRangePickerProps) {
  const [dates, setDates] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);

  function handleChangeDate(item: SelectedDate) {
    setDates([item.selection]);
    setStartDate(item.selection.startDate);
    setEndDate(item.selection.endDate);
  }

  return (
    <DateRange
      editableDateInputs={true}
      onChange={(item) => handleChangeDate(item)}
      ranges={dates}
      className="date"
      locale={pt}
      rangeColors={["#10B981"]}
      shownDate={dates[0].endDate}
      minDate={disablePastDate ? new Date() : new Date(2016, 0)}
    />
  );
}
