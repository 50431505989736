import { CustomColorPicker } from "@/components/CustomColorPicker";
import {
  BusinessGoal,
  BusinessGoalCategorie,
} from "@/core/models/business-goal.model";
import {
  Button,
  Caption,
  DialogClose,
  Flex,
  Input,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { MdChevronRight, MdHelpOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectBusinessGoalsStatus } from "../store/businessGoalsSlice";

interface UpdateBusinessGoalNameProps {
  businessGoal: BusinessGoal;
  onUpdateBusinessGoal: (
    id: number,
    name: string,
    color: string,
    categories: BusinessGoalCategorie[]
  ) => Promise<void>;
}

export function UpdateBusinessGoal({
  businessGoal,
  onUpdateBusinessGoal,
}: UpdateBusinessGoalNameProps) {
  const [newBusinessGoal, setNewBusinessGoal] = useState(businessGoal.name);
  const [selectedColor, setSelectedColor] = useState(businessGoal.color);
  const [categories, setCategories] = useState(businessGoal.categories);

  const businessGoalsStatus = useSelector(selectBusinessGoalsStatus);

  const handleCategorieNameChange = (newCategorie: BusinessGoalCategorie) => {
    setCategories((prevItems) => {
      const itemIndex = prevItems.findIndex(
        (item) => item.id === newCategorie.id
      );

      if (itemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems[itemIndex] = newCategorie;
        return updatedItems;
      }

      return prevItems;
    });
  };

  const handleUpdateBusinessGoal = () => {
    if (newBusinessGoal === "") {
      toast.error("O objetivo de negócio não pode ser vazio");
      return;
    }

    onUpdateBusinessGoal(
      businessGoal.id,
      newBusinessGoal,
      selectedColor,
      categories
    );
  };

  const handleSetNewBusinessGoal = (text: string) => setNewBusinessGoal(text);

  const handleSetSelectedColor = (color: string) => setSelectedColor(color);

  return (
    <Flex css={{ flexDirection: "column", gap: "$4", mt: "$2" }}>
      <Flex css={{ flexDirection: "column", gap: "$1" }}>
        <Caption css={{ color: "$gray600" }}>
          Nome do Objetivo de negócio
        </Caption>
        <Input
          value={newBusinessGoal}
          placeholder="Ex. Prospecção"
          onChange={(e) => handleSetNewBusinessGoal(e.target.value)}
          style={{ width: "100%" }}
        />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$1" }}>
        <Caption css={{ color: "$gray600" }}>Escolha uma cor</Caption>
        <CustomColorPicker
          selectedColor={selectedColor}
          onColorChange={(value) => handleSetSelectedColor(value)}
        />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        <Flex css={{ flexDirection: "column", gap: "$1" }}>
          <Caption css={{ color: "$gray600" }}>
            Mapeamento de Categorias
          </Caption>

          <Flex css={{ alignItems: "center", gap: "$2", color: "$gray400" }}>
            <MdHelpOutline color="inherit" />
            <Caption>
              Os campos da esquerda são apenas para controle interno. Não se
              preocupe, altere conforme sua necessidade.
            </Caption>
          </Flex>
        </Flex>

        <Flex css={{ flexDirection: "column", gap: "$2" }}>
          {categories.map((categorie) => {
            return (
              <Flex
                css={{ alignItems: "center", gap: "$2", color: "$gray400" }}
                key={categorie.field}
              >
                <Input value={categorie.field} disabled />
                <MdChevronRight color="inherit" />
                <Input
                  defaultValue={categorie.mapped_field}
                  onChange={(e) =>
                    handleCategorieNameChange({
                      id: categorie.id,
                      field: categorie.field,
                      mapped_field: e.target.value,
                    } as BusinessGoalCategorie)
                  }
                />
              </Flex>
            );
          })}
        </Flex>
      </Flex>

      <Flex
        css={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginTop: "15px",
          gap: "$2",
        }}
      >
        <DialogClose>
          <Button
            onClick={handleUpdateBusinessGoal}
            isLoading={businessGoalsStatus === "loading"}
          >
            Atualizar objetivo de negócio
          </Button>
        </DialogClose>
      </Flex>
    </Flex>
  );
}
