import styled from "styled-components";

export const FileAttached = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    color: ${(props) => props.theme.colors.gray700};
  }
`;
