import { LinkToAnotherTab } from "@/components/LinkToAnotherTab";
import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import {
  cleanColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { setClientsHasError500 } from "@/features/clients/store/clientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatPrice } from "@/hooks/useFormatPrice";
import { ListOfTags, SearchFilters } from "@core/models/listOfTags.model";
import { AttendanceListItemIcons } from "@features/attendance/components/AttendanceListItemIcons";
import { SelectedListActions } from "@features/attendance/components/SelectedListActions";
import {
  Caption,
  Flex,
  Progress,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { AttachMoney, EventNoteOutlined } from "@mui/icons-material";
import { MdOutlineMoreVert } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useAttendanceMixpanel } from "../hooks/useAttendanceMixpanel";
import {
  addTagsOnSelectedClientFilter,
  setSelectedAdvancedFilters,
} from "../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClientsFilters } from "../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import { selectList } from "../store/listSelectedSlice";
import { setSelectedSearchFilters } from "../store/selectedSearchFiltersSlice";
import UI from "../ui";
import { AttendanceListItemName } from "./AttendanceListItemName";
export interface AttendanceListItemProps {
  listSelected: ListOfTags;
}

export function AttendanceAllClientsListItem({
  listSelected,
}: AttendanceListItemProps) {
  const dispatch = useAppDispatch();
  const { myClientsListClickedEvent } = useAttendanceMixpanel();
  const { list_slug } = useParams();
  const { formatNumberToDecimal } = useFormatPrice();
  const navigate = useNavigate();

  const handleClickCard = async () => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClientsFilters(false));

    myClientsListClickedEvent(listSelected);

    const columnsToSort = listSelected.filters?.sort?.orders;
    if (columnsToSort !== undefined && columnsToSort.length > 0) {
      await dispatch(setColumnsToSort(columnsToSort));
    } else {
      await dispatch(cleanColumnsToSort());
    }

    const advancedFilters = listSelected.filters?.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = listSelected.filters?.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    const attrOpts = listSelected.filters?.attr_opts;
    if (attrOpts !== undefined) {
      await dispatch(setAttributesToFilter(attrOpts));
    } else {
      await dispatch(setDefaultAttributesToFilter());
    }

    const searchFilters: SearchFilters = {
      match_type: "Geral",
      match_value: "",
    };

    await dispatch(setSelectedSearchFilters(searchFilters));

    await dispatch(selectList(listSelected));
    await dispatch(setClientsHasError500(false));

    await dispatch(emitEventToChangeClients(true));

    navigate(`/attendance/${listSelected.slug}`);
  };

  const listMatchSelectedList = listSelected.slug === list_slug ? "active" : "";

  const realizedProgress = (
    listSelected.infos?.total > 0
      ? (listSelected.infos?.realized / listSelected.infos?.total) * 100
      : 0
  ).toFixed(2);

  const positivedProgress = (
    listSelected.infos?.total > 0
      ? (listSelected.infos?.positived / listSelected.infos?.total) * 100
      : 0
  ).toFixed(2);

  return (
    <LinkToAnotherTab url={`/attendance/${listSelected.slug}`}>
      <UI.AttendanceListItem
        className={listMatchSelectedList}
        onClick={handleClickCard}
      >
        <Flex
          css={{
            flexDirection: "column",
            gap: "$2",
            padding: "$1",
            width: "100%",
            height: "100%",
          }}
        >
          <Flex
            css={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              paddingLeft: "$1",
            }}
          >
            <AttendanceListItemName>{listSelected.name}</AttendanceListItemName>

            <SelectedListActions
              list={listSelected}
              icon={<MdOutlineMoreVert />}
            />
          </Flex>

          <AttendanceListItemIcons list={listSelected} />

          <UI.AttendanceIndicatorsWrapper>
            <Flex css={{ gap: "$2" }}>
              <UI.AttendanceIndicator
                className={
                  listSelected.infos?.realized >= listSelected.infos?.total
                    ? "complete"
                    : ""
                }
              >
                <EventNoteOutlined fontSize="inherit" />
                <Tooltip>
                  <TooltipTrigger
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Caption
                      bold
                      css={{
                        color: "$gray900",
                        marginRight: "5px",
                      }}
                    >
                      {listSelected.infos?.realized === undefined ? (
                        <> - </>
                      ) : (
                        <b>{listSelected.infos.realized}</b>
                      )}
                    </Caption>
                    <Caption css={{ color: "$gray700" }}>realizados</Caption>
                  </TooltipTrigger>
                  <TooltipContent aria-label="Progresso realizado">
                    Você realizou {realizedProgress}% dos seus atendimentos
                  </TooltipContent>
                </Tooltip>
              </UI.AttendanceIndicator>

              <UI.AttendanceIndicator
                className={
                  listSelected.infos?.positived === listSelected.infos?.realized
                    ? "complete"
                    : ""
                }
              >
                <AttachMoney fontSize="inherit" />
                <Tooltip>
                  <TooltipTrigger
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Caption
                      bold
                      css={{
                        color: "$gray900",
                        marginRight: "5px",
                      }}
                    >
                      {listSelected.infos?.positived === undefined ? (
                        <> - </>
                      ) : (
                        <b>{listSelected.infos.positived}</b>
                      )}
                    </Caption>
                    <Caption css={{ color: "$gray700" }}>efetivados</Caption>
                  </TooltipTrigger>
                  <TooltipContent aria-label="Progresso efetivado">
                    Você efetivou {positivedProgress}% dos seus atendimentos
                  </TooltipContent>
                </Tooltip>
              </UI.AttendanceIndicator>
            </Flex>

            <Tooltip>
              <TooltipTrigger
                style={{
                  background: "transparent",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Caption
                  bold
                  css={{
                    color: "$gray600",
                  }}
                >
                  {listSelected.infos?.total === undefined ? (
                    <> - </>
                  ) : (
                    <>{formatNumberToDecimal(listSelected.infos.total)}</>
                  )}
                </Caption>
              </TooltipTrigger>
              <TooltipContent aria-label="Progresso realizado">
                Quantidade total de cliente **nesta** lista
              </TooltipContent>
            </Tooltip>
          </UI.AttendanceIndicatorsWrapper>
          <Flex css={{ flexDirection: "column", gap: "$1", mt: "$1" }}>
            <Tooltip>
              <TooltipTrigger style={{ background: "transparent" }}>
                <Progress size="medium" value={Number(realizedProgress)} />
              </TooltipTrigger>
              <TooltipContent aria-label="Progresso realizado">
                Você realizou {realizedProgress}% dos seus atendimentos
              </TooltipContent>
            </Tooltip>
          </Flex>
        </Flex>
      </UI.AttendanceListItem>
    </LinkToAnotherTab>
  );
}
