import { BusinessGoal } from "@/core/models/business-goal.model";
import { Heading } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface BusinessNameCellProps {
  businessGoal: BusinessGoal;
}

export function BusinessNameCell({ businessGoal }: BusinessNameCellProps) {
  return (
    <Container businessGoal={businessGoal}>
      <HeadingStyled size="subtitle2" businessGoal={businessGoal}>
        {businessGoal.name}
      </HeadingStyled>
    </Container>
  );
}

const Container = styled.div<BusinessNameCellProps>`
  background: ${(props) =>
    props.theme.colors[`${props.businessGoal.color}600`]};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  border-radius: 4px;
  width: 150px;
  max-width: 180px;
  width: 100%;
`;

const HeadingStyled = styled(Heading)<BusinessNameCellProps>`
  color: ${(props) => props.theme.colors[`${props.businessGoal.color}100`]};
`;
