import { Client } from "@core/models/client.model";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Flex,
} from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import styled from "styled-components";

interface AvatarOfClientWithHoverInfoProps {
  client: Client;
}

export function AvatarOfClientWithHoverInfo({
  client,
}: AvatarOfClientWithHoverInfoProps) {
  return (
    <Flex css={{ position: "relative" }}>
      {!isEmpty(client.client_photo) ? (
        <Avatar size="medium">
          {client.client_photo.photo_sm_url ? (
            <AvatarImage src={client.client_photo.photo_sm_url} />
          ) : (
            <AvatarFallback>{client.client_photo.fallback}</AvatarFallback>
          )}
        </Avatar>
      ) : (
        <Avatar size="medium">
          <AvatarFallback>GO</AvatarFallback>
        </Avatar>
      )}
      {client.attendance_situation?.rescheduled_creation_date && (
        <RescheduledDateDot />
      )}
    </Flex>
  );
}

const RescheduledDateDot = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.primary500};
`;
