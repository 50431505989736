import styled from "styled-components";

export const ProductsFilterTags = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};

  .tags {
    width: 100%;
    height: 395px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[2]};
    padding: ${({ theme }) => theme.space[2]};
    background: ${({ theme }) => theme.colors.gray50};

    .accordeon-header-content {
      display: flex;
      gap: ${({ theme }) => theme.space[2]};
      align-items: center;
      justify-content: center;
    }
  }
`;
