import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  IconButton,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import { BussinessAssociateServiceConfigGenericFormStep1 } from "./bussinessAssociateGenericForm/BussinessAssociateServiceConfigGenericFormStep1";

interface UpdateBusinessAssociateDialogProps {
  onUpdateBusinessAssociate: () => void;
}

export function UpdateBusinessAssociateServiceConfigDialog({
  onUpdateBusinessAssociate,
}: UpdateBusinessAssociateDialogProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  return (
    <Dialog open={dialogIsOpen} onOpenChange={(e) => setDialogIsOpen(e)}>
      <DialogTrigger style={{ width: "150px", background: "transparent" }}>
        <SpanTrigger>
          <FaUserEdit color="inherit" size={20} />
          Editar Configurações
        </SpanTrigger>
      </DialogTrigger>
      <Content onClick={(event) => event.stopPropagation()}>
        <DialogClose asChild>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
            onClick={(event) => event.stopPropagation()}
          >
            <MdClose />
          </IconButton>
        </DialogClose>
        <Flex
          css={{
            flexDirection: "column",
            gap: "0.5rem",
            height: "100%",
          }}
        >
          <BussinessAssociateServiceConfigGenericFormStep1
            onEnableBusinessAssociate={onUpdateBusinessAssociate}
            onCloseDialog={() => setDialogIsOpen(false)}
          />
        </Flex>
      </Content>
    </Dialog>
  );
}

const SpanTrigger = styled.div`
  color: ${(props) => props.theme.colors.primary500};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  width: fit-content;
  white-space: nowrap;
  text-decoration: underline;
  text-underline-offset: 1px;

  &:hover {
    text-decoration: underline;
  }
`;

const Content = styled(DialogContent)`
  width: 450px;

  @media (max-width: ${({ theme }) => theme.mediaSizes.laptop}) {
    height: 500px;
  }

  @media (max-width: ${({ theme }) => theme.mediaSizes.smallLaptop}) {
    height: 400px;
  }
`;
