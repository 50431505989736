import { useEffect, useState } from "react";

interface UseMediaQueryProps {
  screen?: "laptop" | "tablet" | "mobile" | "custom";
  customWidth?: number;
}

export const useMediaQuery = ({
  screen = "laptop",
  customWidth = 1440,
}: UseMediaQueryProps) => {
  const [state, setState] = useState({
    windowWidth: window.innerWidth,
    isDesiredWidth: false,
  });

  const getMinValue = () => {
    switch (screen) {
      case "laptop":
        return 1440;
      case "tablet":
        return 1024;
      case "mobile":
        return 768;
      case "custom":
        return customWidth;
      default:
        return 1440;
    }
  };

  const [minWidth] = useState(getMinValue());

  useEffect(() => {
    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth;
      const isDesiredWidth = currentWindowWidth <= minWidth;
      setState({ windowWidth: currentWindowWidth, isDesiredWidth });
    };

    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, [state.windowWidth]);

  return state.isDesiredWidth;
};
