import { gvTheme } from "@gogeo-io/ui-library";
import Add from "@mui/icons-material/Add";
import styled from "styled-components";

export function FloatButtonToCreate() {
  return (
    <FloatButtonContainer>
      <Add />
    </FloatButtonContainer>
  );
}

export const FloatButtonContainer = styled.button`
  position: absolute;
  bottom: 10px;
  right: 15px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99999;

  background: ${(props) => props.theme.colors.primary500};

  svg {
    color: ${(props) => props.theme.colors.white};
    font-size: 30px;
  }

  @media (max-width: ${gvTheme.mediaSizes.laptop}) {
    bottom: 10px;
    right: 40px;
  }
`;
