import {
  Button,
  Flex,
  Heading,
  Select,
  SelectItem,
  SelectItemText,
} from "@gogeo-io/ui-library";
import { useNavigate } from "react-router-dom";
import { useClientNotesMixpanel } from "../../hooks/useClientNotesMixpanel";

interface ListNotesHeaderProps {
  selectedFilter: string;
  onSelectFilter: (filter: string) => void;
}

export function ListNotesHeader({
  selectedFilter,
  onSelectFilter,
}: ListNotesHeaderProps) {
  const navigate = useNavigate();
  const { notesCreateEvent } = useClientNotesMixpanel();

  const handleCreateNewNote = () => {
    notesCreateEvent();
    navigate("create");
  };

  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <Heading size="title6" css={{ color: "$gray700" }}>
        Minhas Notas
      </Heading>
      <Flex css={{ gap: "$2" }}>
        <Select
          defaultValue={selectedFilter}
          value={selectedFilter}
          ariaLabel="Filtro de Notas"
          onValueChange={onSelectFilter}
        >
          <SelectItem value="inserted_at,desc">
            <SelectItemText>Data(novo para velho)</SelectItemText>
          </SelectItem>
          <SelectItem value="inserted_at,asc">
            <SelectItemText>Data(velho para novo)</SelectItemText>
          </SelectItem>
          <SelectItem value="title,asc">
            <SelectItemText>Ordem Alfabética</SelectItemText>
          </SelectItem>
          <SelectItem value="title,desc">
            <SelectItemText>Ordem Alfabética Inversa</SelectItemText>
          </SelectItem>
          <SelectItem value="hasFiles">
            <SelectItemText>Com anexos</SelectItemText>
          </SelectItem>
        </Select>

        <Button onClick={handleCreateNewNote}>Adicionar Nota</Button>
      </Flex>
    </Flex>
  );
}
