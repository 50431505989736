import { DynamicFormFields } from "@/core/models/business-associate.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, Flex, Heading } from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import useCreateSchemaFormBusinessAsociateForm from "../../hooks/useCreateSchemaFormBusinessAsociateForm";
import {
  associateBusinessAssociateServicesConfigAndCompany,
  selectSelectedBusinessAssociateService,
} from "../../store/businessAssociatesServicesConfigSlice";
import { BusinessAssociateFieldsContainer } from "./BusinessAssociateFieldsContainer";
import { BussinessAssociateGenericFormDateType } from "./BussinessAssociateGenericFormDateType";
import { BussinessAssociateGenericFormInputType } from "./BussinessAssociateGenericFormInputType";
import { BussinessAssociateGenericFormLabel } from "./BussinessAssociateGenericFormLabel";
import { BussinessAssociateGenericFormMultiSelectType } from "./BussinessAssociateGenericFormMultiSelectType";
import { BussinessAssociateGenericFormPasswordType } from "./BussinessAssociateGenericFormPasswordType";
import { BussinessAssociateGenericFormSelectType } from "./BussinessAssociateGenericFormSelectType";
import { BussinessAssociateServiceConfigGenericFormStep2 } from "./BussinessAssociateServiceConfigGenericFormStep2";

interface BussinessAssociateGenericFormStep1Props {
  onCloseDialog: () => void;
  onEnableBusinessAssociate: () => void;
}

export function BussinessAssociateServiceConfigGenericFormStep1({
  onCloseDialog,
  onEnableBusinessAssociate,
}: BussinessAssociateGenericFormStep1Props) {
  const selectedBusinessAssociateService = useSelector(
    selectSelectedBusinessAssociateService
  );

  const { govendas_fields } = selectedBusinessAssociateService;
  const hasGoVendasFields =
    selectedBusinessAssociateService.govendas_fields &&
    selectedBusinessAssociateService.govendas_fields.length > 0;
  const hasAssociateFields =
    selectedBusinessAssociateService.associate_fields &&
    selectedBusinessAssociateService.associate_fields.length > 0;

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [loadStep2Form, setLoadStep2Form] = useState(false);
  const [govendasFieldsUpdate, setGovendasFieldsUpdate] = useState<
    DynamicFormFields[]
  >([]);

  const defaultValues: { [key: string]: any } = {};

  govendas_fields.forEach((field) => {
    switch (field.type) {
      case "string":
      case "password":
      case "date":
        defaultValues[field.property] = "";
        break;
      case "number":
        defaultValues[field.property] = 0;
        break;
      case "select":
        defaultValues[field.property] = "";
      case "multiselect":
        defaultValues[field.property] = [""];
        break;
      default:
        break;
    }
  });

  const { handleSubmit, control, setValue } = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(
      useCreateSchemaFormBusinessAsociateForm(govendas_fields)
    ),
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<object> = async (
    data: object
  ): Promise<void> => {
    setIsLoading(true);

    const updatedFields = govendas_fields.map((item) => {
      const prop = item.property;
      if (data[prop] !== undefined) {
        return {
          ...item,
          value: data[prop],
        };
      }
      return item;
    });

    if (hasAssociateFields) {
      setGovendasFieldsUpdate(updatedFields);
      setLoadStep2Form(true);
    } else {
      const newData = {
        enabled: true,
        govendas_fields: updatedFields,
      };

      await dispatch(
        associateBusinessAssociateServicesConfigAndCompany({
          service_id: selectedBusinessAssociateService.id,
          body: newData,
        })
      );

      onEnableBusinessAssociate();
      onCloseDialog();
    }
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível ativar o recurso");
    }
  };

  const handleReset = () => {
    govendas_fields.forEach((field: DynamicFormFields) => {
      setValue(field.property, field.value);
    });
  };

  useEffect(() => {
    govendas_fields.map((formField: DynamicFormFields) =>
      setValue(formField.property, formField.value)
    );
  }, [govendas_fields]);

  useEffect(() => {
    const checkIfHasFieldsToFill = async () => {
      if (!hasGoVendasFields && !hasAssociateFields) {
        const newData = {
          enabled: true,
          govendas_fields: selectedBusinessAssociateService.govendas_fields,
          associate_fields: selectedBusinessAssociateService.associate_fields,
        };

        onCloseDialog();

        await dispatch(
          associateBusinessAssociateServicesConfigAndCompany({
            service_id: selectedBusinessAssociateService.id,
            body: newData,
          })
        );

        onEnableBusinessAssociate();
      }
    };

    checkIfHasFieldsToFill();
  }, []);

  return (
    <>
      {hasAssociateFields && loadStep2Form ? (
        <BussinessAssociateServiceConfigGenericFormStep2
          govendas_fields={govendasFieldsUpdate}
          onEnableBusinessAssociate={onEnableBusinessAssociate}
          onCloseDialog={onCloseDialog}
        />
      ) : (
        <BussinessAssociateForm noValidate>
          <Heading>Campos para preencher - GoVendas</Heading>

          <BusinessAssociateFieldsContainer>
            {govendas_fields.length > 0 &&
              govendas_fields.map((formField: DynamicFormFields) => {
                return (
                  <Controller
                    key={formField.id}
                    control={control}
                    name={formField.property}
                    render={({ field, fieldState: { error } }) => {
                      if (formField.type === "string") {
                        return (
                          <Flex css={{ flexDirection: "column", gap: "$1" }}>
                            <BussinessAssociateGenericFormLabel
                              formField={formField}
                            />
                            <BussinessAssociateGenericFormInputType
                              formField={formField}
                              error={error}
                              field={field}
                              type="text"
                            />
                          </Flex>
                        );
                      }
                      if (formField.type === "number") {
                        return (
                          <Flex css={{ flexDirection: "column", gap: "$1" }}>
                            <BussinessAssociateGenericFormLabel
                              formField={formField}
                            />
                            <BussinessAssociateGenericFormInputType
                              formField={formField}
                              error={error}
                              field={field}
                              type="number"
                            />
                          </Flex>
                        );
                      }
                      if (formField.type === "password") {
                        return (
                          <Flex css={{ flexDirection: "column", gap: "$1" }}>
                            <BussinessAssociateGenericFormLabel
                              formField={formField}
                            />
                            <BussinessAssociateGenericFormPasswordType
                              formField={formField}
                              error={error}
                              field={field}
                            />
                          </Flex>
                        );
                      }
                      if (formField.type === "select") {
                        return (
                          <Flex css={{ flexDirection: "column", gap: "$1" }}>
                            <BussinessAssociateGenericFormLabel
                              formField={formField}
                            />
                            <BussinessAssociateGenericFormSelectType
                              formField={formField}
                              error={error}
                              onChange={field.onChange}
                            />
                          </Flex>
                        );
                      }
                      if (formField.type === "multiselect") {
                        return (
                          <Flex css={{ flexDirection: "column", gap: "$1" }}>
                            <BussinessAssociateGenericFormLabel
                              formField={formField}
                            />
                            <BussinessAssociateGenericFormMultiSelectType
                              formField={formField}
                              error={error}
                              onChange={field.onChange}
                            />
                          </Flex>
                        );
                      }
                      if (formField.type === "date") {
                        return (
                          <Flex css={{ flexDirection: "column", gap: "$1" }}>
                            <BussinessAssociateGenericFormLabel
                              formField={formField}
                            />
                            <BussinessAssociateGenericFormDateType
                              formField={formField}
                              error={error}
                              onChange={field.onChange}
                            />
                          </Flex>
                        );
                      }
                    }}
                  />
                );
              })}
          </BusinessAssociateFieldsContainer>

          {hasAssociateFields ? (
            <Flex
              css={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                gap: "$2",
                mt: "$4",
              }}
            >
              <Button
                isLoading={isLoading}
                onClick={() => handleSubmit(onSubmit, onError)()}
              >
                Próximo
              </Button>
            </Flex>
          ) : (
            <Flex
              css={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                gap: "$2",
                mt: "$4",
              }}
            >
              <Button bordered onClick={handleReset}>
                Limpar Alterações
              </Button>
              <Button
                isLoading={isLoading}
                onClick={() => handleSubmit(onSubmit, onError)()}
              >
                Ativar
              </Button>
            </Flex>
          )}
        </BussinessAssociateForm>
      )}
    </>
  );
}

const BussinessAssociateForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
  width: 100%;
  height: 100%;
`;
