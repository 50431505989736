import styled from "styled-components";

export const ClientInfoContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.space[2]};
`;
