import { EmptyContent } from "@/components/EmptyContent";
import { UserCannotHaveAcess } from "@/components/UserCannotHaveAccess";
import { User } from "@/core/models/user.model";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { SelectSalesmanToHistoryOfParams } from "@/features/params/components/SelectSalesmanToHistoryOfParams";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  getSalesmansRelatedToUser,
  selectUser,
} from "@core/store/users/usersSlice";
import { TimelineOfParams } from "@features/history/components/TimelineOfParams";
import {
  getHistoryOfParams,
  selectHistoryOfParams,
} from "@features/params/store/paramsSlice";
import { Flex } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HistoryPageSkeleton } from "../components/HistoryPageSkeleton";
import { useHistoryMixpanel } from "../hooks/useHistoryMixpanel";

export function ParamsHistoryPage() {
  const [salesmanToSeeHistoryOfParams, setSalesmanToSeeHistoryOfParams] =
    useState<User>({} as User);
  const [isLoading, setIsLoading] = useState(true);

  const selectedClient = useSelector(selectClient);
  const user = useSelector(selectUser);
  const historyOfParams = useSelector(selectHistoryOfParams);

  const dispatch = useAppDispatch();
  const { historyOfParamsLoadedEvent } = useHistoryMixpanel();

  useEffect(() => {
    loadSalesmansRelatedToUser();
  }, []);

  async function loadHistoryParams(userId: number) {
    await dispatch(
      getHistoryOfParams({
        client_id: selectedClient.id,
        user_id: userId,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        historyOfParamsLoadedEvent(res.payload);
        setIsLoading(false);
      }
    });
  }

  const loadSalesmansRelatedToUser = async () => {
    setIsLoading(true);

    if (user.role === "SALESMAN") {
      setSalesmanToSeeHistoryOfParams(user);
      loadHistoryParams(user.id);
    } else {
      await dispatch(getSalesmansRelatedToUser({ user_id: user.id })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            const relatedUsers: User[] = res.payload;
            setSalesmanToSeeHistoryOfParams(relatedUsers[0]);
            loadHistoryParams(relatedUsers[0].id);
          }
        }
      );
    }
  };

  const handleSetSalesmanToSeeHistoryOfParams = async (salesman: User) => {
    setIsLoading(true);

    setSalesmanToSeeHistoryOfParams(salesman);
    loadHistoryParams(salesman.id);
  };

  return (
    <>
      <G_UI.ClientInfoContent>
        {!isLoading ? (
          <>
            <Flex css={{ overflowY: "scroll" }}>
              <UserCannotHaveAcess roles={["SALESMAN"]}>
                <SelectSalesmanToHistoryOfParams
                  salesmanToSeeHistoryOfParams={salesmanToSeeHistoryOfParams}
                  onSetSalesmanToSeeHistoryOfParams={
                    handleSetSalesmanToSeeHistoryOfParams
                  }
                />
              </UserCannotHaveAcess>
              {historyOfParams.length === 0 ? (
                <EmptyContent size="medium">
                  Este cliente não possui histórico de alteração de parâmetros
                  para o vendededor
                </EmptyContent>
              ) : (
                <TimelineOfParams params={historyOfParams} />
              )}
            </Flex>
          </>
        ) : (
          <HistoryPageSkeleton />
        )}
      </G_UI.ClientInfoContent>
    </>
  );
}
