import { Checkbox, Flex, Text } from "@gogeo-io/ui-library";

interface NoteIsSharedFormProps {
  value: boolean;
  onChangeNoteIsSharedChange: (value: boolean) => void;
}

export function NoteIsSharedForm({
  value,
  onChangeNoteIsSharedChange,
}: NoteIsSharedFormProps) {
  return (
    <Flex css={{ gap: "$2", alignItems: "center" }}>
      <Checkbox
        checked={value}
        onCheckedChange={(e) => onChangeNoteIsSharedChange(Boolean(e))}
      />
      <Text>Essa nota é uma nota pessoal?</Text>
    </Flex>
  );
}
