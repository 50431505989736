import { NoPageSelected } from "@/components/NoPageSelected";
import { Main } from "@/layouts/Main";
import OpenTabsImg from "@assets/choose.svg";

export function NoNotificationSelected() {
  return (
    <Main>
      <NoPageSelected
        src={OpenTabsImg}
        alt="Selecione uma notificação"
        title="Selecione uma notificação"
      >
        <span>Selecione uma notificação.</span>
      </NoPageSelected>
    </Main>
  );
}
