import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { userMock } from "@/core/mocks/userMock";
import { userRelationshipsMock, usersMock } from "@/core/mocks/usersMock";
import { sleep } from "@/utils/sleep";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import {
  User,
  UserHandler,
  UserOnRelationship,
  UserRelationships,
} from "../../models/user.model";
import { RootState } from "../store";

interface UsersState {
  has500error: boolean;
  user: User;
  users: User[];
  salesmansRelatedToUser: User[];
  userStatus: "loading" | "success" | "failed";
  usersStatus: "loading" | "success" | "failed";
  selectedUsersToBatchActions: User[];
  selectedUsersToAssociate: UserOnRelationship[];
  selectedUsersToDisassociate: UserOnRelationship[];
  userRelationships: UserRelationships;
}

const initialState = {
  has500error: false,
  user: {},
  users: [],
  salesmansRelatedToUser: [],
  userStatus: "loading",
  usersStatus: "loading",
  selectedUsersToBatchActions: [],
  selectedUsersToAssociate: [],
  selectedUsersToDisassociate: [],
  userRelationships: {},
} as UsersState;

export const getUser = createAsyncThunk(
  "users/getUser",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return userMock;
    } else {
      try {
        const response = await axios.get(`/user`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const getActiveUsers = createAsyncThunk(
  "users/getActiveUsers",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return usersMock;
    } else {
      try {
        const response = await axios.get(`/user/active`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface GetUsersBaseProps {
  user_id: number;
}

interface GetUsersRelatedToUserProps extends GetUsersBaseProps {}

export const getUsersRelatedToUser = createAsyncThunk(
  "users/getUsersRelatedToUser",
  async ({ user_id }: GetUsersRelatedToUserProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return usersMock;
    } else {
      try {
        const response = await axios.get(`/related/users/${user_id}`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface GetUsersRelatedToUserProps extends GetUsersBaseProps {}

export const getSalesmansRelatedToUser = createAsyncThunk(
  "users/getSalesmansRelatedToUser",
  async ({ user_id }: GetUsersRelatedToUserProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return usersMock;
    } else {
      try {
        const response = await axios.get(
          `/related/users/${user_id}?role=SALESMAN`
        );
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const getUserRelationships = createAsyncThunk(
  "users/getUserRelationships",
  async ({ user_id }: GetUsersBaseProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return userRelationshipsMock;
    } else {
      try {
        const response = await axios.get(`/user/relationships/${user_id}`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface CreateUsersProps {
  users: User[];
}

export const createUsers = createAsyncThunk(
  "users/createUsers",
  async ({ users }: CreateUsersProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.post(`/user/create/batch`, users);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface UpdateRelationshipsProps extends GetUsersBaseProps {
  usersToAssociate: number[];
  usersToDisassociate: number[];
}

export const updateUserRelationships = createAsyncThunk(
  "users/updateUserRelationships",
  async (
    {
      user_id,
      usersToAssociate,
      usersToDisassociate,
    }: UpdateRelationshipsProps,
    thunkAPI
  ) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.put(`/user/relationships/${user_id}`, {
          add: usersToAssociate,
          remove: usersToDisassociate,
        });
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface UpdateUserProps extends GetUsersBaseProps {
  updated_user: UserHandler;
}

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ user_id, updated_user }: UpdateUserProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.put(`/user/${user_id}`, updated_user);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface UpdateLoggedUserProps {
  updated_user: UserHandler;
}

export const updateLoggedUser = createAsyncThunk(
  "users/updateLoggedUser",
  async ({ updated_user }: UpdateLoggedUserProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.put(`/user`, updated_user);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface AddUserProps {
  user: UserHandler;
}

export const addUser = createAsyncThunk(
  "users/addUser",
  async ({ user }: AddUserProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.post(`/user`, user);
        if (
          response.status !== 200 &&
          response.status !== 201 &&
          response.status !== 202
        ) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface UpdateUserEnabledProps {
  active: boolean;
  user_ids: number[];
}

export const updateUserEnabled = createAsyncThunk(
  "users/updateUserEnabled",
  async ({ user_ids, active }: UpdateUserEnabledProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.put(`/users/enabled/${active}`, user_ids);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return usersMock;
    } else {
      try {
        const response = await axios.get(`/user/list`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    setAllUserToBatchActions(state) {
      state.selectedUsersToBatchActions = state.users;
    },
    removeAllUserToBatchActions(state) {
      state.selectedUsersToBatchActions = [];
    },
    selectUserToBatchActions(state, action: PayloadAction<User>) {
      state.selectedUsersToBatchActions.push(action.payload);
      state.userStatus = "success";
    },
    removeUserToBatchActions(state, action: PayloadAction<User>) {
      const stateWithoutRemovedOne = state.selectedUsersToBatchActions.filter(
        (user) => user.id !== action.payload.id
      );
      state.selectedUsersToBatchActions = stateWithoutRemovedOne;
    },
    changeUserProfileInfo(state, action: PayloadAction<User>) {
      state.user.name = action.payload.name;
      state.user.email = action.payload.email;
      state.user.phone = action.payload.phone;
    },
    ////////////////
    setUsersToAssociate(state, action: PayloadAction<UserOnRelationship[]>) {
      state.selectedUsersToAssociate = action.payload;
    },
    setUsersToDisassociate(state, action: PayloadAction<UserOnRelationship[]>) {
      state.selectedUsersToDisassociate = action.payload;
    },
    selectUserToAssociate(state, action: PayloadAction<UserOnRelationship>) {
      state.selectedUsersToAssociate.push(action.payload);

      const stateWithoutRemovedOne = state.selectedUsersToDisassociate.filter(
        (user) => user.id !== action.payload.id
      );
      state.selectedUsersToDisassociate = stateWithoutRemovedOne;
    },
    selectUserToDisassociate(state, action: PayloadAction<UserOnRelationship>) {
      state.selectedUsersToDisassociate.push(action.payload);

      const stateWithoutRemovedOne = state.selectedUsersToAssociate.filter(
        (user) => user.id !== action.payload.id
      );
      state.selectedUsersToAssociate = stateWithoutRemovedOne;
    },
    setAllUsersToAssociate(state, action: PayloadAction<UserOnRelationship[]>) {
      state.selectedUsersToAssociate = action.payload;
      state.selectedUsersToDisassociate = [];
    },
    setAllUsersToDisassociate(
      state,
      action: PayloadAction<UserOnRelationship[]>
    ) {
      state.selectedUsersToAssociate = [];
      state.selectedUsersToDisassociate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.userStatus = "loading";
    });
    builder.addCase(getUser.fulfilled, (state, action: PayloadAction<User>) => {
      state.userStatus = "success";
      state.user = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
      state.userStatus = "failed";
      if (action.payload.status === 500) {
        state.has500error = true;
      }
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setTransactionName("Error getting the user");
        scope.setExtras(action.payload);
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });

    builder.addCase(getUsersRelatedToUser.pending, (state, action) => {
      state.usersStatus = "loading";
    });
    builder.addCase(
      getUsersRelatedToUser.fulfilled,
      (state, action: PayloadAction<User[]>) => {
        state.usersStatus = "success";
        state.salesmansRelatedToUser = action.payload;
      }
    );
    builder.addCase(
      getUsersRelatedToUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.usersStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the users related to user");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );

    builder.addCase(getSalesmansRelatedToUser.pending, (state, action) => {
      state.usersStatus = "loading";
    });
    builder.addCase(
      getSalesmansRelatedToUser.fulfilled,
      (state, action: PayloadAction<User[]>) => {
        state.usersStatus = "success";
        state.salesmansRelatedToUser = action.payload;
      }
    );
    builder.addCase(
      getSalesmansRelatedToUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.usersStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the salesmans related to user"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );

    builder.addCase(getUsers.pending, (state) => {
      state.usersStatus = "loading";
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.usersStatus = "success";
      state.users = action.payload;
    });
    builder.addCase(getUsers.rejected, (state, action: PayloadAction<any>) => {
      state.usersStatus = "failed";
      if (action.payload.status === 500) {
        state.has500error = true;
      }
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setTransactionName("Error getting the users");
        scope.setExtras(action.payload);
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });

    builder.addCase(getActiveUsers.pending, (state) => {
      state.usersStatus = "loading";
    });
    builder.addCase(getActiveUsers.fulfilled, (state) => {
      state.usersStatus = "success";
    });
    builder.addCase(
      getActiveUsers.rejected,
      (state, action: PayloadAction<any>) => {
        state.usersStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting active users");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );

    builder.addCase(getUserRelationships.pending, (state) => {
      state.usersStatus = "loading";
    });
    builder.addCase(getUserRelationships.fulfilled, (state, action) => {
      state.usersStatus = "success";
      state.userRelationships = action.payload;
    });
    builder.addCase(
      getUserRelationships.rejected,
      (state, action: PayloadAction<any>) => {
        state.usersStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting user relationships");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateUserRelationships.pending, (state) => {
      state.usersStatus = "loading";
    });
    builder.addCase(updateUserRelationships.fulfilled, (state, action) => {
      state.usersStatus = "success";
    });
    builder.addCase(
      updateUserRelationships.rejected,
      (state, action: PayloadAction<any>) => {
        state.usersStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating user relationships");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateLoggedUser.pending, (state) => {
      state.usersStatus = "loading";
    });
    builder.addCase(updateLoggedUser.fulfilled, (state, action) => {
      state.usersStatus = "success";
    });
    builder.addCase(
      updateLoggedUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.usersStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating user");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateUser.pending, (state) => {
      state.usersStatus = "loading";
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.usersStatus = "success";
    });
    builder.addCase(
      updateUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.usersStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating user");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateUserEnabled.pending, (state) => {
      state.usersStatus = "loading";
    });
    builder.addCase(updateUserEnabled.fulfilled, (state, action) => {
      state.usersStatus = "success";
    });
    builder.addCase(
      updateUserEnabled.rejected,
      (state, action: PayloadAction<any>) => {
        state.usersStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating user enabled");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(addUser.pending, (state) => {
      state.usersStatus = "loading";
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.usersStatus = "success";
    });
    builder.addCase(addUser.rejected, (state, action: PayloadAction<any>) => {
      state.usersStatus = "failed";
      if (action.payload.status === 500) {
        state.has500error = true;
      }
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setTransactionName("Error adding user");
        scope.setExtras(action.payload);
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });
    builder.addCase(createUsers.pending, (state) => {
      state.usersStatus = "loading";
    });
    builder.addCase(createUsers.fulfilled, (state, action) => {
      state.usersStatus = "success";
    });
    builder.addCase(
      createUsers.rejected,
      (state, action: PayloadAction<any>) => {
        state.usersStatus = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error createing users");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectUser = (state: RootState) => state.userState.user;
export const selectUsers = (state: RootState) => state.userState.users;
export const selectUserRelationships = (state: RootState) =>
  state.userState.userRelationships;
export const selectSelectedUsersToBatchActions = (state: RootState) =>
  state.userState.selectedUsersToBatchActions;
export const selectSelectedUsersToAssociate = (state: RootState) =>
  state.userState.selectedUsersToAssociate;
export const selectSelectedUsersToDisassociate = (state: RootState) =>
  state.userState.selectedUsersToDisassociate;
export const selectSalesmansRelatedToUser = (state: RootState) =>
  state.userState.salesmansRelatedToUser;
export const selectUserStatus = (state: RootState) =>
  state.userState.userStatus;
export const selectUsersStatus = (state: RootState) =>
  state.userState.usersStatus;
export const selectUserHas500error = (state: RootState) =>
  state.userState.has500error;

export const {
  setAllUserToBatchActions,
  removeAllUserToBatchActions,
  selectUserToBatchActions,
  removeUserToBatchActions,
  setUsersToAssociate,
  selectUserToAssociate,
  selectUserToDisassociate,
  setUsersToDisassociate,
  setAllUsersToAssociate,
  setAllUsersToDisassociate,
  changeUserProfileInfo,
} = usersSlice.actions;

export const usersReducer = usersSlice.reducer;
