import { gvTheme, Text } from "@gogeo-io/ui-library";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import G_UI from "@ui/index";

interface ByUpAndDownProps {
  text: string;
  tag_value: boolean;
}

export function ByUpAndDown({ text, tag_value }: ByUpAndDownProps) {
  return (
    <G_UI.ProductTagLabel>
      {tag_value ? (
        <ArrowUpward style={{ color: gvTheme.colors.primary500 }} />
      ) : (
        <ArrowDownward style={{ color: gvTheme.colors.danger500 }} />
      )}
      <Text>{text}</Text>
    </G_UI.ProductTagLabel>
  );
}
