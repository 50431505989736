import { selectUser } from "@/core/store/users/usersSlice";
import { useMixpanel } from "@/hooks/useMixpanel";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import OneSignal from "react-onesignal";
import { useSelector } from "react-redux";

export function Logout() {
  const user = useSelector(selectUser);

  const auth = useAuth();
  const { userLogoutEvent } = useMixpanel();

  useEffect(() => {
    OneSignal.logout().then(() => {
      OneSignal.User.removeTags(["user_id", "account_id"]);
    });
    auth.removeUser();
    userLogoutEvent(user);
    auth.signoutRedirect({ id_token_hint: auth.user.id_token });
  }, []);

  return null;
}
