import { Text } from "@gogeo-io/ui-library";
import styled from "styled-components";

export const Paragraph = styled(Text)`
  display: inline;
  line-height: 1.8;
  max-width: 100%;

  .token {
    white-space: nowrap;
    background: ${(props) => props.theme.colors.blue100};
    color: ${(props) => props.theme.colors.blue500};
    font-weight: bold;
    border-radius: 4px;
    padding: 0.2rem 0.4rem;
    text-align: center;
    margin: 0 2px;
  }
`;
