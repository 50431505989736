import { Text } from "@gogeo-io/ui-library";

interface ParamsFreqProps {
  freq: number;
  isBlocked?: boolean;
}

export function ParamsFreq({ freq, isBlocked = false }: ParamsFreqProps) {
  return (
    <Text css={{ color: "$gray500" }}>
      Frequência de atendimento: <strong> {freq} dias</strong>
    </Text>
  );
}
