import { BusinessAssociate } from "@/core/models/business-associate.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Caption, Flex, Heading, Text } from "@gogeo-io/ui-library";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { setSelectedBusinessAssociate } from "../store/businessAssociatesSlice";

interface BusinessAssociatesListItemProps {
  business: BusinessAssociate;
}

export function BusinessAssociatesListItem({
  business,
}: BusinessAssociatesListItemProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigate = async (business: BusinessAssociate) => {
    await dispatch(setSelectedBusinessAssociate(business));
    navigate(`/profile/integrations/partners/${business.id}`);
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        width: "300px",
        gap: "$4",
        cursor: "pointer",
        background: "$gray50",
        borderRadius: "6px",
        boxShadow: "$sm",
        padding: "$4",
      }}
      onClick={() => handleNavigate(business)}
    >
      <Heading size="subtitle2" css={{ fontWeight: "bold" }}>
        {business.name}
      </Heading>
      <Text size="body2">
        {business.description && business.description.length > 0
          ? business.description
          : `Traga os serviços da ${business.name} para sua conta`}
      </Text>

      <Flex
        css={{
          flexDirection: "column",
          gap: "$4",
          height: "100%",
          justifyContent: "flex-end",
        }}
      >
        {business.enabled ? (
          <Flex css={{ gap: "$2", alignItems: "center", color: "$primary500" }}>
            <FaCheckCircle color="inherit" />
            <Caption css={{ color: "$gray500" }}>Habilitado</Caption>
          </Flex>
        ) : (
          <Caption css={{ color: "$gray500" }}>
            {business.how_many_integrate_with_business_associate &&
            business.how_many_integrate_with_business_associate.length > 0
              ? business.how_many_integrate_with_business_associate
              : `Muitas empresas estão usando`}
          </Caption>
        )}
      </Flex>
    </Flex>
  );
}
