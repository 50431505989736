import { Flex } from "@gogeo-io/ui-library";
import { UsersTableSkeletonRow } from "./UsersTableSkeletonRow";

export function UsersTableSkeleton() {
  return (
    <Flex css={{ flexDirection: "column" }}>
      <UsersTableSkeletonRow />
      <UsersTableSkeletonRow />
      <UsersTableSkeletonRow />
      <UsersTableSkeletonRow />
    </Flex>
  );
}
