import {
  deleteReminder,
  removeReminderFromState,
  updateReminder,
  updateReminderToIsReadState,
  updateReminderToUnreadState,
} from "@/features/reminder/store/reminderSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Checkbox, Flex } from "@gogeo-io/ui-library";
import { Delete, KeyboardArrowRight } from "@mui/icons-material";
import { formatRelative } from "date-fns";
import { pt } from "date-fns/locale";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useReminderMixpanel } from "../hooks/useReminderMixpanel";
import UI from "../ui";
interface ReminderItemProps {
  id: number;
  text: string;
  date: string;
  isRead: boolean;
  client_id?: string;
  onCloseDialog: () => void;
}

export function ReminderItem({
  id,
  text,
  date,
  isRead,
  client_id,
  onCloseDialog,
}: ReminderItemProps) {
  const dispatch = useAppDispatch();
  const { reminderUpdateEvent, reminderDeleteEvent } = useReminderMixpanel();
  const navigate = useNavigate();

  const dateParsed = useMemo(() => {
    return formatRelative(new Date(date), new Date(), {
      locale: pt,
    });
  }, [date]);

  const handleCheckbox = async (isChecked: boolean) => {
    await dispatch(updateReminder({ id, isRead: isChecked })).then(
      async (obj) => {
        if (obj.meta.requestStatus === "fulfilled") {
          if (isChecked) {
            await dispatch(updateReminderToIsReadState(id));
            reminderUpdateEvent(id, isRead);
          } else {
            await dispatch(updateReminderToUnreadState(id));
            reminderUpdateEvent(id, isRead);
          }
        }
      }
    );
  };

  const handleRemoveReminder = async () => {
    await dispatch(deleteReminder(id)).then(async (obj) => {
      if (obj.meta.requestStatus === "fulfilled") {
        await dispatch(removeReminderFromState(id));
        reminderDeleteEvent(id, isRead);
      }
    });
  };

  const handleNavigateToClient = async () => {
    if (reminderHasClient) {
      onCloseDialog();
      navigate(`/info/client/${client_id}/client-info`);
    }
  };

  const reminderHasClient = useMemo(
    () => client_id !== undefined && client_id !== "",
    [client_id]
  );

  return (
    <UI.ReminderItem>
      <Flex
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "$3",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Checkbox
          checked={isRead}
          css={{ cursor: "pointer", minWidth: "16px", minHeight: "16px" }}
          onCheckedChange={(e) => handleCheckbox(Boolean(e))}
        />
        <Flex
          className="info"
          css={{
            flexDirection: "row",
            alignItems: "center",
            gap: "$3",
            width: "100%",
            justifyContent: "space-between",
          }}
          onClick={handleNavigateToClient}
        >
          <Flex css={{ flexDirection: "column" }}>
            <UI.ReminderItemTitle
              isRead={isRead}
              haveClientId={reminderHasClient}
            >
              {text}
            </UI.ReminderItemTitle>
            <UI.ReminderItemTime isRead={isRead}>
              {dateParsed}
            </UI.ReminderItemTime>
          </Flex>
          {reminderHasClient && <KeyboardArrowRight color="inherit" />}
        </Flex>
        <Delete onClick={handleRemoveReminder} />
      </Flex>
    </UI.ReminderItem>
  );
}
