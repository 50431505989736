import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { attendanceJustificationsActionsMock } from "@/core/mocks/attendanceJustificationsActionsMock";
import { AttendanceJustificationAction } from "@/core/models/attendance-justification.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

interface AttendanceJustificationsActionsState {
  available_actions: AttendanceJustificationAction[];
  has500error: boolean;
  status: "loading" | "success" | "failed";
}

const initialState = {
  available_actions: [],
  has500error: false,
  status: "loading",
} as AttendanceJustificationsActionsState;

interface GetAvailableActionsForAttendanceJustificationProps {
  category: string;
}

export const getAvailableActionsForAttendanceJustification = createAsyncThunk(
  "getAvailableActionsForAttendance/getAvailableActionsForAttendanceJustification",
  async (
    { category }: GetAvailableActionsForAttendanceJustificationProps,
    thunkAPI
  ) => {
    return attendanceJustificationsActionsMock;
    // try {
    //   const response = await axios.get(
    //     `/available-actions?category=${category}`
    //   );
    //   if (response.status !== 200 && response.status !== 202) {
    //     return thunkAPI.rejectWithValue({
    //       errorMessage: response.data.message,
    //       timestamp: response.data.timestamp,
    //       status: response.status,
    //       headers: response.headers,
    //     });
    //   }
    //   return response.data;
    // } catch (error: unknown) {
    //   if (error instanceof AxiosError) {
    //     return thunkAPI.rejectWithValue({
    //       errorMessage: error.response?.data.message,
    //       headers: error.config.headers,
    //       method: error.config.method,
    //       url: error.config.url,
    //       status: error.response.status,
    //       timestamp: new Date(),
    //     });
    //   }
    // }
  }
);

export const attendanceJustificationsActionsSlice = createSlice({
  name: "attendanceJustificationsActionsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAvailableActionsForAttendanceJustification.pending,
      (state, action: PayloadAction<any>) => {
        state.status = "loading";
        state.has500error = false;
      }
    );
    builder.addCase(
      getAvailableActionsForAttendanceJustification.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.has500error = false;
        state.available_actions = action.payload;
      }
    );
    builder.addCase(
      getAvailableActionsForAttendanceJustification.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error on getting the attendance justifications"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

///////////////////////////////////////

export const selectAttendanceJustificationsActions = (state: RootState) =>
  state.attendanceJustificationsActionsState.available_actions;
export const selectAttendanceJustificationsActionsStatus = (state: RootState) =>
  state.attendanceJustificationsActionsState.status;
export const selectAttendanceJustificationsActionsHasError500 = (
  state: RootState
) => state.attendanceJustificationsActionsState.has500error;

///////////////////////////////////////

export const attendanceJustificationsActionsReducer =
  attendanceJustificationsActionsSlice.reducer;
