import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";

export const useBreadcrumbsForBusinessAssociatesServicesConfigPage = () => {
  const dispatch = useAppDispatch();

  const showBreadcrumbs = async () => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Configurações", url: "/profile/user-profile/info" },
      {
        item: "Integrações",
        url: `/profile/integrations/partners/list`,
      },
      {
        item: "Serviços disponíveis",
        url: `/profile/integrations/services/list`,
        notNavigate: true,
      },
      { item: "Todos os serviços de parceiros goVendas", url: "" },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return {
    showBreadcrumbs,
  };
};
