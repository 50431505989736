import styled from "styled-components";

export const ProductTagLabel = styled.div`
  display: flex;
  height: 32px;
  gap: 0.25rem;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 4px;
  padding: 0.2rem 0.7rem;
  cursor: default;
`;
