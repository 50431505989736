import { useFormatDate } from "@/hooks/useFormatDate";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  IconButton,
} from "@gogeo-io/ui-library";
import CloseIcon from "@mui/icons-material/Close";
import { isEmpty } from "lodash";
import { BsCalendarEvent } from "react-icons/bs";
import { toast } from "react-toastify";
import { Calendar } from "../../../components/Calendar";

import { useClientInfoMixpanel } from "@/features/clientInfo/hooks/useClientInfoMixpanel";
import G_UI from "@ui/index";
import { addDays } from "date-fns";
import { useState } from "react";

interface RescheduleDateProps {
  onChange: (...event: any[]) => void;
  value: string;
}

export function RescheduleDate({ onChange, value }: RescheduleDateProps) {
  const [rescheduleDate, setRescheduleDate] = useState(value);

  const handleRescheduleDateChange = (date: string) => {
    setRescheduleDate(date);
  };

  const { rescheduleClientEvent } = useClientInfoMixpanel();
  const { formatLongDatePtBrWithoutWeekday, formatBasicDate } = useFormatDate();

  const handleSetRescheduleDate = () => {
    if (rescheduleDate === "") {
      toast.error("A data não pode ser vazia");
    } else {
      onChange(rescheduleDate);
      rescheduleClientEvent(rescheduleDate);
    }
  };

  const handleSetDefaultDate = () => {
    setRescheduleDate(formatBasicDate(addDays(new Date(), 1)));
  };

  return (
    <Dialog>
      <DialogTrigger asChild onClick={handleSetDefaultDate}>
        <Flex
          css={{
            gap: "$1",
            cursor: "pointer",
            alignItems: "center",
            color: "$primary500",
          }}
        >
          <BsCalendarEvent size={18} />
          {!isEmpty(value)
            ? formatLongDatePtBrWithoutWeekday(value, true)
            : "Reagendar"}
        </Flex>
      </DialogTrigger>

      <DialogContent>
        <DialogTitle>Reagendar Atendimento</DialogTitle>

        <Calendar
          date={rescheduleDate}
          dateToSet={handleRescheduleDateChange}
          disablePastDateAndToday
        />

        <DialogClose asChild>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogClose>

        <G_UI.ButtonGroup>
          <DialogClose asChild>
            <Button color="danger" variant="soft" onClick={() => onChange("")}>
              Remover data
            </Button>
          </DialogClose>
          <DialogClose asChild onClick={handleSetRescheduleDate}>
            <Button>Selecionar data</Button>
          </DialogClose>
        </G_UI.ButtonGroup>
      </DialogContent>
    </Dialog>
  );
}
