import { FormInputField } from "@/components/form/FormInputField";
import { FormLabel } from "@/components/form/FormLabel";
import { TrackingEvents } from "@/config/mixpanel";
import { RuptureRule } from "@/core/models/rules";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useMixpanel } from "@/hooks/useMixpanel";
import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Input,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { MdInfoOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { RuptureRuleConsumers } from "../components/RuptureRuleConsumers";
import { ruptureRulesSchema } from "../hooks/ruptureRulesSchema";
import { useBreadcrumbsForRuptureRulePage } from "../hooks/useBreadcrumbsForRuptureRulePage";
import { useCleanAndConvertRules } from "../hooks/useCleanAndConvertRules";
import { useRuptureRuleMixpanel } from "../hooks/useRuptureRuleMixpanel";
import {
  getRuptureRule,
  selectRuptureRule,
  setSelectRuptureRule,
  updateRuptureRule,
} from "../store/ruptureRuleSlice";

export function CreateRuptureRulePage() {
  const [submitLoading, setSubmitLoading] = useState(false);

  const rule = useSelector(selectRuptureRule);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const convertRules = useCleanAndConvertRules();
  const { breacrumbsToCreateRuptureRulePage } =
    useBreadcrumbsForRuptureRulePage();
  const { pageViewed } = useMixpanel();
  const { ruptureRuleCreateEvent } = useRuptureRuleMixpanel();

  const { handleSubmit, control, reset } = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(ruptureRulesSchema),
    defaultValues: {
      name: "Nova Regra",
      producers: [],
      consumers: [],
      schedule_prospects: false,
      rules: {
        sales_rupture_lower_bound: "",
        sales_rupture_upper_bound: "",
        visit_rupture_lower_bound: "",
        visit_rupture_upper_bound: "",
        generic_filter: "",
      },
    },
  });

  const onSubmit: SubmitHandler<RuptureRule> = async (
    data: RuptureRule
  ): Promise<void> => {
    setSubmitLoading(true);

    data.rules = convertRules(data.rules);

    const newRuleId = uuidv4();

    const updatedRelationships = [
      ...rule.rupture_rule.relationships,
      { ...data, id: newRuleId },
    ];

    await dispatch(
      updateRuptureRule({
        rule_id: rule.id,
        relationships: updatedRelationships,
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("regra adicionada com sucesso");
        ruptureRuleCreateEvent(rule.id, newRuleId, updatedRelationships);
        await dispatch(getRuptureRule()).then(async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            await dispatch(
              setSelectRuptureRule({
                ...data,
                id: newRuleId,
              })
            );
            navigate(`/profile/rupture-rule/${newRuleId}`);
          }
        });
      }
    });

    setSubmitLoading(false);
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível alterar regra de ruptura");
    }
  };

  const handleReset = () => {
    reset();
  };

  useEffect(() => {
    breacrumbsToCreateRuptureRulePage();
    pageViewed(TrackingEvents.CREATE_RUPTURE_RULE_PAGE);
  }, []);

  return (
    <Container>
      <>
        <FormContainer>
          <Heading size="title6">Criar regra multicanal</Heading>

          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$2" }}>
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    <Heading size="subtitle1">Nome da regra</Heading>
                    <Input
                      placeholder="Ex. Nova regra"
                      type="text"
                      isFullWidth
                      value={field.value}
                      {...field}
                    />
                  </Flex>
                  {!!error && (
                    <Text css={{ color: "$red500" }}>{error.message}</Text>
                  )}
                </Flex>
              );
            }}
          />

          <Flex css={{ flexDirection: "column", gap: "$2" }}>
            <Heading size="subtitle1">
              Defina o período de Ruptura ( em dias )
            </Heading>

            <Flex css={{ gap: "$4", alignItems: "center" }}>
              <Text css={{ width: "150px" }}>Ruptura de Visita</Text>

              <Controller
                control={control}
                name="rules.visit_rupture_lower_bound"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Flex css={{ flexDirection: "column", gap: "$1" }}>
                      <FormLabel label="Início" />
                      <FormInputField
                        placeholder="Ex. 25"
                        error={error}
                        field={field}
                        type="number"
                      />
                    </Flex>
                  );
                }}
              />
              <Controller
                control={control}
                name="rules.visit_rupture_upper_bound"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Flex css={{ flexDirection: "column", gap: "$1" }}>
                      <FormLabel label="Fim" />
                      <FormInputField
                        placeholder="Ex. 90"
                        error={error}
                        field={field}
                        type="number"
                      />
                    </Flex>
                  );
                }}
              />
            </Flex>

            <Flex css={{ gap: "$4", alignItems: "center" }}>
              <Text css={{ width: "150px" }}>Ruptura de Positivação</Text>

              <Controller
                control={control}
                name="rules.sales_rupture_lower_bound"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Flex css={{ flexDirection: "column", gap: "$1" }}>
                      <FormLabel label="Início" />
                      <FormInputField
                        placeholder="Ex. 25"
                        error={error}
                        field={field}
                        type="number"
                      />
                    </Flex>
                  );
                }}
              />
              <Controller
                control={control}
                name="rules.sales_rupture_upper_bound"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Flex css={{ flexDirection: "column", gap: "$1" }}>
                      <FormLabel label="Fim" />
                      <FormInputField
                        placeholder="Ex. 25"
                        error={error}
                        field={field}
                        type="number"
                      />
                    </Flex>
                  );
                }}
              />
            </Flex>
          </Flex>

          <Controller
            control={control}
            name="rules"
            render={({ fieldState: { error } }) => {
              return (
                <>
                  {!!error && (
                    <Text css={{ color: "$red500" }}>{error.message}</Text>
                  )}
                </>
              );
            }}
          />

          <Controller
            control={control}
            name="schedule_prospects"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$2" }}>
                  <Flex css={{ alignItems: "center", gap: "$1" }}>
                    <Checkbox
                      checked={value}
                      onCheckedChange={(e) => onChange(Boolean(e))}
                    />
                    <Heading size="subtitle1">Agendar prospects</Heading>
                  </Flex>

                  {!!error && (
                    <Text css={{ color: "$red500" }}>{error.message}</Text>
                  )}
                </Flex>
              );
            }}
          />

          <Controller
            control={control}
            name="producers"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$2" }}>
                  <Flex css={{ alignItems: "center", gap: "$1" }}>
                    <Heading size="subtitle1">
                      Defina os vendedores que terão a ruptura monitorada
                    </Heading>
                    <Tooltip>
                      <TooltipTrigger
                        style={{ background: "transparent" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        <MdInfoOutline />
                      </TooltipTrigger>
                      <TooltipContent aria-label="Vendedores com clientes que deixaram de comprar ou não foram visitados num determinado periódo que serão monitorados por essa regra">
                        Vendedores com clientes que deixaram de comprar ou não
                        foram visitados num determinado periódo que serão
                        monitorados por essa regra
                      </TooltipContent>
                    </Tooltip>
                  </Flex>
                  <RuptureRuleConsumers
                    selectedConsumers={value}
                    onChange={onChange}
                  />

                  {!!error && (
                    <Text css={{ color: "$red500" }}>{error.message}</Text>
                  )}
                </Flex>
              );
            }}
          />

          <Controller
            control={control}
            name="consumers"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$2" }}>
                  <Flex css={{ alignItems: "center", gap: "$1" }}>
                    <Heading size="subtitle1">
                      Defina os vendedores que atenderão clientes em ruptura
                    </Heading>
                    <Tooltip>
                      <TooltipTrigger
                        style={{ background: "transparent" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        <MdInfoOutline />
                      </TooltipTrigger>
                      <TooltipContent aria-label="Vendedores com clientes que deixaram de comprar ou não foram visitados num determinado periódo que serão monitorados por essa regra">
                        Vendedores na retaguarda da comercial que irão atender
                        os clientes em ruptura. Os clientes em ruptura
                        complementam a carteira do vendedor.
                      </TooltipContent>
                    </Tooltip>
                  </Flex>
                  <RuptureRuleConsumers
                    selectedConsumers={value}
                    onChange={onChange}
                  />

                  {!!error && (
                    <Text css={{ color: "$red500" }}>{error.message}</Text>
                  )}
                </Flex>
              );
            }}
          />

          <Controller
            control={control}
            name="rules.generic_filter"
            render={({ field, fieldState: { error } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$1" }}>
                  <Heading size="subtitle1">
                    Defina os filtros genéricos
                  </Heading>
                  <FormInputField
                    placeholder="Ex. (defaulting == True) and activity_id not in (1, 2, 3, 30, 34, 103)"
                    error={error}
                    field={field}
                    type="text"
                  />
                </Flex>
              );
            }}
          />
        </FormContainer>

        <Flex
          css={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
            gap: "$2",
            mt: "$4",
          }}
        >
          <Button bordered onClick={handleReset}>
            Limpar alterações
          </Button>
          <Button
            isLoading={submitLoading}
            onClick={() => handleSubmit(onSubmit, onError)()}
          >
            Salvar
          </Button>
        </Flex>
      </>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[6]};
  width: 100%;
  overflow-y: scroll;
  padding: 4px;
`;
