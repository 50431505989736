import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../core/store/store";
import { ClientsTypeOfView } from "../pages/ClientsListPage";

interface SelectedClientsTypeOfViewState {
  clientsTypeOfView: ClientsTypeOfView;
}

const initialState = {
  clientsTypeOfView: "list",
} as SelectedClientsTypeOfViewState;

export const selectedClientsTypeOfViewSlice = createSlice({
  name: "selectedClientsTypeOfViewSlice",
  initialState,
  reducers: {
    setSelectedClientsTypeOfView(
      state,
      action: PayloadAction<ClientsTypeOfView>
    ) {
      state.clientsTypeOfView = action.payload;
    },
  },
});

export const selectSelectedClientsTypeOfView = (state: RootState) =>
  state.selectedClientsTypeOfViewState.clientsTypeOfView;

export const { setSelectedClientsTypeOfView } =
  selectedClientsTypeOfViewSlice.actions;

export const selectedClientsTypeOfViewReducer =
  selectedClientsTypeOfViewSlice.reducer;
