import { FormInputField } from "@/components/form/FormInputField";
import { FormLabel } from "@/components/form/FormLabel";
import { FormRadioBooleanField } from "@/components/form/FormRadioBooleanField";
import { useProfileMixpanel } from "@/features/profile/hooks/useProfileMixpanel";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Flex,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import G_UI from "@ui/index";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  ParamsOfSalesmanFormData,
  paramsOfSalesmanFormSchema,
} from "../hooks/paramsOfSalesmanFormSchema";
import {
  selectSelectedSalesmanToChangeParams,
  updateUserParams,
} from "../store/paramsSlice";
import { DaysWithRuptureFormField } from "./DaysWithRuptureFormField";

export function ParamsOfSalesmanForm() {
  const [isLoading, setIsLoading] = useState(false);

  const salesman = useSelector(selectSelectedSalesmanToChangeParams);

  const dispatch = useAppDispatch();
  const { changeParamsOfSalesmanEvent } = useProfileMixpanel();

  const { handleSubmit, control, reset, setValue } =
    useForm<ParamsOfSalesmanFormData>({
      mode: "onBlur",
      resolver: yupResolver(paramsOfSalesmanFormSchema),
      defaultValues: {
        salesman_id: salesman.salesman_id,
        ignore_blocked: salesman.ignore_blocked,
        salesman_type: salesman.salesman_type,
        inactive_days: salesman.inactive_days,
        max_visit: salesman.max_visit,
        meta_parameters: {
          active_freq: salesman.meta_parameters?.active_freq,
          inactive_freq: salesman.meta_parameters?.inactive_freq,
          skip_defaulting: salesman.meta_parameters?.skip_defaulting,
          rupture_params: {
            freq: salesman.meta_parameters?.rupture_params?.freq,
            mon_pref: salesman.meta_parameters?.rupture_params?.mon_pref,
            tue_pref: salesman.meta_parameters?.rupture_params?.tue_pref,
            wed_pref: salesman.meta_parameters?.rupture_params?.wed_pref,
            thu_pref: salesman.meta_parameters?.rupture_params?.thu_pref,
            fri_pref: salesman.meta_parameters?.rupture_params?.fri_pref,
            sat_pref: salesman.meta_parameters?.rupture_params?.sat_pref,
            sun_pref: salesman.meta_parameters?.rupture_params?.sun_pref,
          },
        },
      },
    });

  const onSubmit: SubmitHandler<ParamsOfSalesmanFormData> = async (
    data: ParamsOfSalesmanFormData
  ): Promise<void> => {
    setIsLoading(true);

    await dispatch(
      updateUserParams({ user_id: salesman.id, params: data })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        changeParamsOfSalesmanEvent(data, salesman);
        toast.success("Parâmetros alterados com successo");
      }
    });

    setIsLoading(false);
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível alterar os parâmetros do vendedor");
    }
  };

  const handleReset = () => {
    reset();
  };

  useEffect(() => {
    setValue("salesman_id", salesman.salesman_id);
    setValue("ignore_blocked", salesman.ignore_blocked);
    setValue("salesman_type", salesman.salesman_type);
    setValue("inactive_days", salesman.inactive_days);
    setValue("max_visit", salesman.max_visit);
    setValue(
      "meta_parameters.active_freq",
      salesman.meta_parameters.active_freq
    );
    setValue(
      "meta_parameters.inactive_freq",
      salesman.meta_parameters.inactive_freq
    );
    setValue(
      "meta_parameters.skip_defaulting",
      salesman.meta_parameters.skip_defaulting
    );
    setValue(
      "meta_parameters.rupture_params.freq",
      salesman.meta_parameters.rupture_params.freq
    );
    setValue(
      "meta_parameters.rupture_params.mon_pref",
      salesman.meta_parameters.rupture_params.mon_pref
    );
    setValue(
      "meta_parameters.rupture_params.tue_pref",
      salesman.meta_parameters.rupture_params.tue_pref
    );
    setValue(
      "meta_parameters.rupture_params.wed_pref",
      salesman.meta_parameters.rupture_params.wed_pref
    );
    setValue(
      "meta_parameters.rupture_params.thu_pref",
      salesman.meta_parameters.rupture_params.thu_pref
    );
    setValue(
      "meta_parameters.rupture_params.fri_pref",
      salesman.meta_parameters.rupture_params.fri_pref
    );
    setValue(
      "meta_parameters.rupture_params.sat_pref",
      salesman.meta_parameters.rupture_params.sat_pref
    );
    setValue(
      "meta_parameters.rupture_params.sun_pref",
      salesman.meta_parameters.rupture_params.sun_pref
    );
  }, [salesman]);

  return (
    <Container>
      <FormContainer>
        <Controller
          control={control}
          name="ignore_blocked"
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Flex css={{ flexDirection: "column", gap: "$1" }}>
                <FormLabel label="Permitir bloqueados" />
                <FormRadioBooleanField
                  value={value}
                  onChange={onChange}
                  options={[
                    { title: "Sim", value: true },
                    { title: "Não", value: false },
                  ]}
                />
                <G_UI.ErrorMessage>
                  {!!error && error.message}
                </G_UI.ErrorMessage>
              </Flex>
            );
          }}
        />

        <Flex css={{ gap: "$4" }}>
          <Controller
            control={control}
            name="inactive_days"
            render={({ field, fieldState: { error } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$1" }}>
                  <FormLabel label="Dias sem compras para inativos" />
                  <FormInputField
                    placeholder="Ex. 90"
                    error={error}
                    field={field}
                    type="number"
                  />
                </Flex>
              );
            }}
          />
          <Controller
            control={control}
            name="max_visit"
            render={({ field, fieldState: { error } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$1" }}>
                  <FormLabel
                    label="Capacidade de clientes por dia"
                    isRequired
                  />
                  <FormInputField
                    placeholder="Ex. 25"
                    error={error}
                    field={field}
                    type="number"
                  />
                </Flex>
              );
            }}
          />
        </Flex>

        {/* <Flex css={{ flexDirection: "column", gap: "$1" }}>
          <Text>Coordenadas de partida:</Text>

          <Flex css={{ gap: "$4" }}>
            <Controller
              control={control}
              name="lat_start"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    <FormLabel label="Latitude" />
                    <FormInputField
                      placeholder="Ex. -3.097782"
                      error={error}
                      field={field}
                      type="number"
                    />
                  </Flex>
                );
              }}
            />
            <Controller
              control={control}
              name="lon_start"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    <FormLabel label="Longitude" />
                    <FormInputField
                      placeholder="Ex. -3.10571"
                      error={error}
                      field={field}
                      type="number"
                    />
                  </Flex>
                );
              }}
            />
          </Flex>
        </Flex>

        <Flex css={{ flexDirection: "column", gap: "$1" }}>
          <Text>Coordenadas de chegada:</Text>

          <Flex css={{ gap: "$4" }}>
            <Controller
              control={control}
              name="lat_end"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    <FormLabel label="Latitude" />
                    <FormInputField
                      placeholder="Ex. -59.94607"
                      error={error}
                      field={field}
                      type="number"
                    />
                  </Flex>
                );
              }}
            />
            <Controller
              control={control}
              name="lon_end"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    <FormLabel label="Longitude" />
                    <FormInputField
                      placeholder="Ex. -60.00741"
                      error={error}
                      field={field}
                      type="number"
                    />
                  </Flex>
                );
              }}
            />
          </Flex>
        </Flex> */}

        <Flex css={{ flexDirection: "column", gap: "$1" }}>
          <Flex css={{ alignItems: "center", gap: "$2", color: "$primary500" }}>
            <Text>Valores padrão para clientes não parametrizados</Text>
            <Tooltip>
              <TooltipTrigger
                style={{ background: "transparent", color: "inherit" }}
                onClick={(e) => e.preventDefault()}
              >
                <IoMdInformationCircleOutline color="inherit" size={24} />
              </TooltipTrigger>
              <TooltipContent aria-label="Os valores só serão aplicados caso o cliente não esteja parametrizado. Caso contrário, os valores a seguir serão ignorados">
                Os valores só serão aplicados caso o cliente não esteja
                parametrizado. Caso contrário, os valores a seguir serão
                ignorados.
              </TooltipContent>
            </Tooltip>
          </Flex>

          <Flex css={{ gap: "$4" }}>
            <Controller
              control={control}
              name="meta_parameters.active_freq"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    <FormLabel label="Frequência de clientes ativos" />
                    <FormInputField
                      placeholder="Ex. 7"
                      error={error}
                      field={field}
                      type="number"
                    />
                  </Flex>
                );
              }}
            />
            <Controller
              control={control}
              name="meta_parameters.inactive_freq"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    <FormLabel label="Frequência de clientes inativos" />
                    <FormInputField
                      placeholder="Ex. 14"
                      error={error}
                      field={field}
                      type="number"
                    />
                  </Flex>
                );
              }}
            />
          </Flex>
        </Flex>

        <Controller
          control={control}
          name="meta_parameters.skip_defaulting"
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Flex css={{ flexDirection: "column", gap: "$1" }}>
                <FormLabel label="Agendar inadimplentes" />
                <FormRadioBooleanField
                  value={value}
                  onChange={onChange}
                  options={[
                    { title: "Indefinido", value: null },
                    { title: "Sim", value: false },
                    { title: "Não", value: true },
                  ]}
                />
                <G_UI.ErrorMessage>
                  {!!error && error.message}
                </G_UI.ErrorMessage>
              </Flex>
            );
          }}
        />

        <Flex css={{ flexDirection: "column", gap: "$1" }}>
          <Text>Valores padrão para clientes em ruptura</Text>

          <Controller
            control={control}
            name="meta_parameters.rupture_params.freq"
            render={({ field, fieldState: { error } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$1" }}>
                  <FormLabel label="Frequência de clientes em ruptura" />
                  <FormInputField
                    placeholder="Ex. 14"
                    error={error}
                    field={field}
                    type="number"
                  />
                </Flex>
              );
            }}
          />

          <Controller
            control={control}
            name="meta_parameters.rupture_params"
            render={({ field: { value, onChange } }) => {
              return (
                <Flex css={{ flexDirection: "column", gap: "$1" }}>
                  <FormLabel label="Dias com agenda de ruptura" />
                  <DaysWithRuptureFormField value={value} onChange={onChange} />
                </Flex>
              );
            }}
          />
        </Flex>
      </FormContainer>

      <Flex
        css={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          gap: "$2",
          mt: "$4",
        }}
      >
        <Button bordered onClick={handleReset}>
          Limpar alterações
        </Button>
        <Button
          isLoading={isLoading}
          onClick={() => handleSubmit(onSubmit, onError)()}
        >
          Salvar
        </Button>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  width: 100%;
  height: 100%;
  margin-top: ${(props) => props.theme.space[2]};
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  width: 100%;
  overflow-y: scroll;
  padding: 4px;
`;
