import {
  PreDefinedMessage,
  PreDefinedMessageSubject,
} from "@/core/models/preDefinedMessage.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  DialogClose,
  Flex,
  Heading,
  Input,
  Select,
  SelectItem,
  SelectItemText,
  Text,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getPreDefinedMessageTokens,
  selectPreDefinedMessageTokensStatus,
} from "../store/preDefinedMessagesTokensSlice";
import { CreatePreDefinedMessageSkeleton } from "./CreatePreDefinedMessageSkeleton";
import { PreDefinedMessageSharedForm } from "./PreDefinedMessageSharedForm";
import { PreDefinedMessageTextArea } from "./PreDefinedMessageTextArea";

interface UpdatePreDefinedMessageProps {
  preDefinedMessage: PreDefinedMessage;
  onUpdatePreDefinedMessage: (
    id: number,
    subject: PreDefinedMessageSubject,
    title: string,
    message: string,
    shared: boolean
  ) => Promise<void>;
}

export function UpdatePreDefinedMessage({
  preDefinedMessage,
  onUpdatePreDefinedMessage,
}: UpdatePreDefinedMessageProps) {
  const [newTitle, setNewTitle] = useState(preDefinedMessage.title);
  const [newMessage, setNewMessage] = useState(preDefinedMessage.message);
  const [shared, setShared] = useState(preDefinedMessage.shared);
  const [preDefinedMessagesSubject, setPreDefinedMessagesSubject] =
    useState<PreDefinedMessageSubject>(preDefinedMessage.subject);

  const preDefinedMessageTokenStatus = useSelector(
    selectPreDefinedMessageTokensStatus
  );

  const handleSetOptionSelected = (option: PreDefinedMessageSubject) => {
    setPreDefinedMessagesSubject(option);
  };

  const dispatch = useAppDispatch();

  const handleSetNewMessage = (value: string) => setNewMessage(value);

  const handleOnInputChange = (value: string) => {
    setNewTitle(value);
  };

  const handleUpdateMessage = () => {
    if (newMessage === "") {
      toast.error("Texto da mensagem não pode ser vazio");
      return;
    }

    if (newTitle === "") {
      toast.error("Texto da título não pode ser vazio");
      return;
    }

    onUpdatePreDefinedMessage(
      preDefinedMessage.id,
      preDefinedMessagesSubject,
      newTitle,
      newMessage,
      !shared
    );
  };

  useEffect(() => {
    const getTokens = async () => {
      await dispatch(getPreDefinedMessageTokens(preDefinedMessagesSubject));
    };

    getTokens();
  }, [preDefinedMessagesSubject]);

  return (
    <>
      {preDefinedMessageTokenStatus === "success" ? (
        <Flex
          css={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Heading>Atualizar mensagem</Heading>
          <Flex css={{ gap: "1.5rem", padding: "1rem 0" }}>
            <Flex css={{ flexDirection: "column", gap: "$4" }}>
              <Flex css={{ flexDirection: "column", gap: "$2" }}>
                <Heading
                  size="subtitle2"
                  css={{ display: "flex", flexDirection: "row" }}
                >
                  Sujeito da Mensagem
                  <Text css={{ color: "$red500", fontWeight: "bold" }}>*</Text>
                </Heading>
                <Select
                  value={preDefinedMessagesSubject}
                  defaultValue={preDefinedMessagesSubject}
                  ariaLabel="Select subject"
                  onValueChange={handleSetOptionSelected}
                >
                  <SelectItem value="products_suggestion">
                    <SelectItemText>Sugestão de Produtos</SelectItemText>
                  </SelectItem>
                  <SelectItem value="client">
                    <SelectItemText>Clientes</SelectItemText>
                  </SelectItem>
                  <SelectItem value="general">
                    <SelectItemText>Geral</SelectItemText>
                  </SelectItem>
                </Select>
              </Flex>

              <Flex css={{ flexDirection: "column", gap: "$2" }}>
                <Heading
                  size="subtitle2"
                  css={{ display: "flex", flexDirection: "row" }}
                >
                  Título da Mensagem
                  <Text css={{ color: "$red500", fontWeight: "bold" }}>*</Text>
                </Heading>
                <Input
                  placeholder="Digite o título da mensagem"
                  value={newTitle}
                  onChange={(e) => handleOnInputChange(e.target.value)}
                  style={{ textAlign: "left" }}
                />
              </Flex>

              <PreDefinedMessageSharedForm
                value={shared}
                onChangeMessageIsSharedChange={(v) => setShared(v)}
              />
            </Flex>

            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              <Flex
                css={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "$2",
                }}
              >
                <Heading
                  size="subtitle2"
                  css={{ display: "flex", flexDirection: "row" }}
                >
                  Mensagem
                  <Text css={{ color: "$red500", fontWeight: "bold" }}>*</Text>
                </Heading>
              </Flex>
              <Flex css={{ gap: "$2", alignItems: "flex-start" }}>
                <PreDefinedMessageTextArea
                  newMessage={newMessage}
                  onNewMessageChange={handleSetNewMessage}
                />
              </Flex>
            </Flex>
          </Flex>

          <Flex
            css={{
              gap: "0.5rem",
              marginTop: "0.75rem",
              width: "100%",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <DialogClose asChild>
              <Button color="gray" onClick={(event) => event.stopPropagation()}>
                Cancelar
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleUpdateMessage();
                }}
              >
                Atualizar mensagem
              </Button>
            </DialogClose>
          </Flex>
        </Flex>
      ) : (
        <CreatePreDefinedMessageSkeleton />
      )}
    </>
  );
}
