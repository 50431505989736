import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  Text,
} from "@gogeo-io/ui-library";

interface FiltersWillBeLostDialogProps {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onContinue: () => Promise<void>;
}

export function FiltersWillBeLostDialog({
  isOpen,
  onOpenChange,
  onContinue,
}: FiltersWillBeLostDialogProps) {
  const handleCancel = () => onOpenChange(false);

  const handleContinue = () => {
    onContinue();
    onOpenChange(false);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger style={{ display: "none", visibility: "hidden" }} />
      <DialogContent
        style={{ width: "400px" }}
        onInteractOutside={(e) => e.preventDefault()}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <Flex
          css={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "$6",
          }}
        >
          <Heading>Você tem filtros que não foram salvos</Heading>

          <Flex
            css={{
              flexDirection: "column",
              alignItems: "center",
              gap: "$3",
            }}
          >
            <Text css={{ textAlign: "center" }}>
              As alterações feitas na lista serão perdidas se você proseguir
              para outros lista.
            </Text>

            <Text css={{ textAlign: "center" }}>Deseja continuar?</Text>
          </Flex>

          <Flex css={{ gap: "$4", width: "100%" }}>
            <Button fullWidth bordered onClick={handleCancel}>
              Cancelar
            </Button>
            <Button fullWidth onClick={handleContinue}>
              Continuar
            </Button>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
