import { DropzoneToAttachFile } from "@/features/attendance/components/register-attendance/DropzoneToAttachFile";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  IconButton,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";

interface AddFilesToEditorProps {
  files: File[];
  onFilesChange: (newFiles: File[]) => void;
}

export function AddFilesToEditor({
  files,
  onFilesChange,
}: AddFilesToEditorProps) {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleSetFiles = () => {
    onFilesChange(selectedFiles);
    setSelectedFiles(selectedFiles);
  };

  const handleSetSelectedFilesFiles = (filesToSet: File[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...filesToSet]);
  };

  const cancelAttachedFiles = () => {
    onFilesChange(files);
    setSelectedFiles(files);
  };

  const handleRemoveFileAttached = (fileNameToRemove: string) => {
    const filesWithoutRemovedOne = files.filter(
      (file) => file.name !== fileNameToRemove
    );
    setSelectedFiles(filesWithoutRemovedOne);
  };

  useEffect(() => {
    setSelectedFiles(files);
  }, [files]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Flex
          css={{
            width: "28px",
            height: "28px",
            fontSize: "18px",
            borderRadius: "4px",
            border: "1px solid $gray300",
            color: "$gray500",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <AiOutlinePlus color="inherit" fontSize="inherit" />
        </Flex>
      </DialogTrigger>
      <DialogContent style={{ width: "500px" }}>
        <DialogTitle>Arquivos</DialogTitle>
        <DialogTitle>
          <Flex css={{ flexDirection: "column" }}>
            Faça o upload e anexe arquivos na nota
          </Flex>
        </DialogTitle>

        <DialogClose asChild>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
            onClick={(event) => {
              event.stopPropagation();
              cancelAttachedFiles();
            }}
          >
            <AiOutlineClose />
          </IconButton>
        </DialogClose>

        <DropzoneToAttachFile
          value={selectedFiles}
          onAttachFiles={handleSetSelectedFilesFiles}
          onRemoveFileAttached={handleRemoveFileAttached}
        />

        <Flex
          css={{
            flexDirection: "row",
            gap: "$2",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <DialogClose asChild>
            <Button
              color="primary"
              bordered
              onClick={(event) => {
                event.stopPropagation();
                cancelAttachedFiles();
              }}
            >
              Cancelar
            </Button>
          </DialogClose>

          <DialogClose asChild>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                handleSetFiles();
              }}
            >
              Atualizar
            </Button>
          </DialogClose>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
