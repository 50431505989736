import { EmptyContent } from "@/components/EmptyContent";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useBreadcrumbsForSpecificReport } from "../hooks/useBreadcrumbsForSpecificReport";
import { useReportMixpanel } from "../hooks/useReportMixpanel";
import { ReportSkeletonPage } from "../pages/ReportSkeletonPage";
import {
  getReport,
  selectFiltersStatus,
  selectReportFilters,
  selectSelectedReport,
} from "../store/reportSlice";
import { ReportFrame } from "./ReportFrame";

export function ReportItem() {
  const [isLoading, setIsLoading] = useState(true);
  const [errorOnGetTheReport, setErrorOnGetTheReport] = useState(false);

  const filtersStatus = useSelector(selectFiltersStatus);
  const selectedReport = useSelector(selectSelectedReport);
  const reportFilters = useSelector(selectReportFilters);

  const { dashboard_name } = useParams();
  const dispatch = useAppDispatch();
  const { filterReportEvent } = useReportMixpanel();
  const { showBreadcrumbs } = useBreadcrumbsForSpecificReport();

  useEffect(() => {
    if (filtersStatus === "filled") {
      setIsLoading(true);

      async function loadReport() {
        await dispatch(
          getReport({
            dashboard_name,
            data: reportFilters,
          })
        ).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            filterReportEvent(reportFilters, res.payload);
            setIsLoading(false);
          } else {
            setErrorOnGetTheReport(true);
          }
        });
      }

      loadReport();
      showBreadcrumbs(selectedReport.title);
    }
  }, [filtersStatus]);

  if (errorOnGetTheReport) {
    return <EmptyContent>Não foi possível encontrar o relatório</EmptyContent>;
  }

  return <>{!isLoading ? <ReportFrame /> : <ReportSkeletonPage />}</>;
}
