import { selectClientsCount } from "@/features/clients/store/clientsSlice";
import { useFormatPrice } from "@/hooks/useFormatPrice";
import { Flex, Text } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectAllClientsSelectedToChangeParams,
  selectClientSelectedToChangeParams,
} from "../../store/clientSelectedToChangeParamsSlice";

export function ChangeParamsFormClientName() {
  const [value, setValue] = useState("");

  const selectedClientsToChangeParams = useSelector(
    selectClientSelectedToChangeParams
  );
  const clientsCount = useSelector(selectClientsCount);
  const allClientsSelectedToChangeParams = useSelector(
    selectAllClientsSelectedToChangeParams
  );
  const { formatNumberToDecimal } = useFormatPrice();

  useEffect(() => {
    if (allClientsSelectedToChangeParams) {
      const title = `${formatNumberToDecimal(
        clientsCount
      )} clientes selecionados`;
      setValue(title);
    } else {
      if (!isEmpty(selectedClientsToChangeParams)) {
        const title =
          selectedClientsToChangeParams.length <= 1
            ? selectedClientsToChangeParams[0].client.name
            : `${formatNumberToDecimal(
                selectedClientsToChangeParams.length
              )} clientes selecionados`;
        setValue(title);
      } else {
        setValue("Nenhum Cliente selecionado");
      }
    }
  }, [selectedClientsToChangeParams, allClientsSelectedToChangeParams]);

  return (
    <Flex css={{ gap: "0.5rem" }}>
      <Text size="body1" css={{ color: "$black" }}>
        Cliente(s):
      </Text>

      <Text size="body1" bold css={{ color: "$black" }}>
        {value}
      </Text>
    </Flex>
  );
}
