import { LabelWithTooltip } from "@/components/LabelWithTooltip";

export function PersonalNoteLabel() {
  return (
    <LabelWithTooltip
      title="Pessoal"
      description="Nota pessoal, apenas você pode vê-la"
      schemeColor="blue"
    />
  );
}
