import { User, UserHandler } from "@/core/models/user.model";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { AddUpdateUserModalContent } from "../AddUpdateUserModalContent";

interface UserActionsCellProps {
  user: User;
  onCloseDialog: () => void;
}

export function EditUserToCreateCell({
  user,
  onCloseDialog,
}: UserActionsCellProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const updateIser = async (updated_user: UserHandler) => {};

  return (
    <Box>
      <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
        <DialogTrigger asChild>
          <Button color="gray" bordered>
            Editar
          </Button>
        </DialogTrigger>

        <DialogContent>
          <AddUpdateUserModalContent
            onCloseDialog={onCloseDialog}
            onSubmitForm={updateIser}
            userToUpdate={user}
            submitButtonText="Editar Usuário"
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
