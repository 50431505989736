import { TagLabel } from "@/components/TagLabel";
import { Tag } from "@/core/models/tag.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, Caption, Flex, Text } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import {
  addTagsOnSelectedClientFilter,
  removeTagOnSelectedClientFilter,
  selectOriginalUrlFilters,
  selectSelectedTagsOnClientFilters,
} from "../../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClientsFilters } from "../../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../../store/emitEventToChangeClientsSlice";
import { selectSelectedList } from "../../store/listSelectedSlice";

interface SelectedTagsHeaderProps {
  onCloseDialogOfAdvancedFilters: () => void;
  onCloseDialogOfTags: () => void;
}

export function SelectedTagsHeader({
  onCloseDialogOfAdvancedFilters,
  onCloseDialogOfTags,
}: SelectedTagsHeaderProps) {
  const selectedTags = useSelector(selectSelectedTagsOnClientFilters);
  const selectedList = useSelector(selectSelectedList);
  const originalUrlFilters = useSelector(selectOriginalUrlFilters);

  const dispatch = useAppDispatch();

  const handleRemoveTag = async (tagToRemove: Tag) => {
    await dispatch(removeTagOnSelectedClientFilter(tagToRemove));
  };

  const handleCancelChanges = async () => {
    if (!isEmpty(selectedList)) {
      await dispatch(addTagsOnSelectedClientFilter(selectedList.filters.tags));
    } else {
      await dispatch(
        addTagsOnSelectedClientFilter(originalUrlFilters.filters.tags)
      );
    }
    onCloseDialogOfTags();
  };

  const applyFilters = async () => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClients(true));
    await dispatch(emitEventToChangeClientsFilters(true));
    onCloseDialogOfAdvancedFilters();
    onCloseDialogOfTags();
  };

  return (
    <Flex css={{ flexDirection: "column" }}>
      <Caption wider>Tags Selecionadas</Caption>
      <Flex
        css={{
          padding: "$2 $2",
          borderRadius: "4px",
          background: "$gray50",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "$6",
        }}
      >
        <Flex css={{ gap: "$2", flexWrap: "wrap" }}>
          <>
            {selectedTags && selectedTags.length > 0 ? (
              <>
                {selectedTags &&
                  selectedTags.map((tag) => {
                    return (
                      <TagLabel
                        key={tag.id}
                        hasCloseButton={true}
                        onClickCloseButton={() => handleRemoveTag(tag)}
                      >
                        <Caption css={{ color: "$gray500" }}>Tag: </Caption>
                        {tag.label}
                      </TagLabel>
                    );
                  })}
              </>
            ) : (
              <Text>Sem filtros</Text>
            )}
          </>
        </Flex>

        <Flex css={{ gap: "$2" }}>
          <Button bordered onClick={handleCancelChanges}>
            Cancelar
          </Button>
          <Button onClick={applyFilters}>Aplicar</Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
