import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { paginatedClientsMock } from "@/core/mocks/paginatedClientsMock";
import { PaginatedRequestBaseProps } from "@/core/models/paginatedRequest.model";
import { RootState } from "@/core/store/store";
import { sleep } from "@/utils/sleep";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { PaginatedClientsResponse } from "../../../core/models/client.model";

interface ClientsState {
  clients: PaginatedClientsResponse;
  has500error: boolean;
  status: "loading" | "success" | "failed";
  clientsCount: number;
}

const initialState = {
  clients: {},
  has500error: false,
  status: "loading",
  clientsCount: 0,
} as ClientsState;

interface GetClientsByFiltersProps extends PaginatedRequestBaseProps {
  params?: object;
  bringParams?: boolean;
  user_id?: number;
}

export const getClientsByFilters = createAsyncThunk(
  "client/getClientsByFilters",
  async (
    {
      page,
      size,
      params,
      bringParams = false,
      user_id,
    }: GetClientsByFiltersProps,
    thunkAPI
  ) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return paginatedClientsMock;
    } else {
      try {
        const url = user_id
          ? `/client/search?params=${bringParams}&page=${page}&size=${size}&userId=${user_id}`
          : `/client/search?params=${bringParams}&page=${page}&size=${size}`;
        const response = await axios.post(url, params);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface GetClientsCountProps {
  params?: object;
  user_id?: number;
}

export const getClientsCount = createAsyncThunk(
  "client/getClientsCount",
  async ({ params, user_id }: GetClientsCountProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return 35;
    } else {
      try {
        const url = user_id
          ? `/client/search/count?userId=${user_id}`
          : `/client/search/count`;
        const response = await axios.post(url, params);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const clientsSlice = createSlice({
  name: "clientsSlice",
  initialState,
  reducers: {
    setClientsHasError500(state, action) {
      state.has500error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientsByFilters.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(getClientsByFilters.fulfilled, (state, action) => {
      state.status = "success";
      state.clients = action.payload;
      state.has500error = false;
    });
    builder.addCase(
      getClientsByFilters.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error on filtering clients by params");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(getClientsCount.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getClientsCount.fulfilled, (state, action) => {
      state.status = "success";
      state.clientsCount = action.payload;
    });
    builder.addCase(
      getClientsCount.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error on couting clients by params");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectClientsHasError500 = (state: RootState) =>
  state.clientsState.has500error;
export const selectClientsCount = (state: RootState) =>
  state.clientsState.clientsCount;

export const { setClientsHasError500 } = clientsSlice.actions;

export const clientsReducer = clientsSlice.reducer;
