import { ContentWithImage } from "@/components/ContentWithImage";
import useAppDispatch from "@/hooks/useAppDispatch";
import FireWorksImage from "@assets/fireworks.svg";
import {
  Flex,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BusinessAssociateConfigServiceList } from "../components/BusinessAssociateConfigServiceList";
import { BusinessAssociateDetailedDescription } from "../components/BusinessAssociateDetailedDescription";
import { BusinessAssociatedServiceHeader } from "../components/BusinessAssociatedServiceHeader";
import { UpdateBusinessAssociateServiceConfigDialog } from "../components/UpdateBusinessAssociateServiceConfigDialog";
import { useBreadcrumbsForBusinessAssociateServicesConfigPage } from "../hooks/useBreadcrumbsForBusinessAssociateServicesConfigPage";
import { useBusinessAssociateMixpanel } from "../hooks/useBusinessAssociateMixpanel";
import {
  associateBusinessAssociateServicesConfigAndCompany,
  getBusinessAssociatesServicesConfig,
  selectBusinessAssociatesServicesConfig,
  selectSelectedBusinessAssociate,
  selectSelectedBusinessAssociateService,
  setSelectedBusinessAssociate,
  setSelectedBusinessAssociateService,
} from "../store/businessAssociatesServicesConfigSlice";

export function BusinessAssociateServiceOfPartnerConfigPage() {
  const [selectedTab, setSelectedTab] = useState("description");

  const businessAssociates = useSelector(
    selectBusinessAssociatesServicesConfig
  );
  const selectedBusinessAssociate = useSelector(
    selectSelectedBusinessAssociate
  );
  const selectedBusinessAssociateService = useSelector(
    selectSelectedBusinessAssociateService
  );
  const [
    businessAssociateServiceIsEnabled,
    setBusinessAssociateServiceIsEnabled,
  ] = useState(selectedBusinessAssociateService.enabled);

  const dispatch = useAppDispatch();
  const { showBreadcrumbs } =
    useBreadcrumbsForBusinessAssociateServicesConfigPage();
  const navigate = useNavigate();
  const { business_associate_id, service_id } = useParams();
  const {
    businessAssociateServiceOpenedEvent,
    businessAssociateServiceDisabledEvent,
    businessAssociateServiceEnabledEvent,
  } = useBusinessAssociateMixpanel();

  const handleEnableBusinessAssociate = async () => {
    await dispatch(getBusinessAssociatesServicesConfig()).then(() => {
      navigate("/profile/integrations/services/list");
      businessAssociateServiceEnabledEvent(
        selectedBusinessAssociateService,
        true
      );
    });
  };

  const disableBusinessAssociate = async () => {
    const data = {
      enabled: false,
    };

    await dispatch(
      associateBusinessAssociateServicesConfigAndCompany({
        service_id: selectedBusinessAssociateService.id,
        body: data,
      })
    );

    businessAssociateServiceDisabledEvent(
      selectedBusinessAssociateService,
      false
    );
    await dispatch(getBusinessAssociatesServicesConfig()).then(() => {
      navigate("/profile/integrations/services/list");
    });
  };

  useEffect(() => {
    const handleSetSelectedBusinessAssociateByUrl = async () => {
      const findedBusinessAssociate = businessAssociates.find(
        (businessAssociate) => {
          return businessAssociate.id === business_associate_id;
        }
      );
      if (findedBusinessAssociate) {
        const findedService = findedBusinessAssociate.services.find(
          (service) => {
            return String(service.id) === service_id;
          }
        );

        if (findedService) {
          await dispatch(setSelectedBusinessAssociateService(findedService));
          await dispatch(setSelectedBusinessAssociate(findedBusinessAssociate));
          showBreadcrumbs(findedBusinessAssociate, findedService);
        } else {
          navigate(`/profile/integrations/services/list`);
          toast.error("Não encontramos o serviço que você estava buscando.");
        }
      } else {
        navigate(`/profile/integrations/services/list`);
        toast.error("Não encontramos o serviço que você estava buscando.");
      }
    };

    handleSetSelectedBusinessAssociateByUrl();
  }, []);

  useEffect(() => {
    setBusinessAssociateServiceIsEnabled(
      selectedBusinessAssociateService.enabled
    );
    businessAssociateServiceOpenedEvent(selectedBusinessAssociateService);
  }, [selectedBusinessAssociate]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "900px",
        margin: "0 auto",
        height: "100%",
      }}
    >
      <BusinessAssociatedServiceHeader
        onDisableBusinessAssociate={disableBusinessAssociate}
        onEnableBusinessAssociate={handleEnableBusinessAssociate}
      />

      <Flex
        css={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
          gap: "$4",
          mt: "$2",
        }}
      >
        <TabsRoot
          defaultValue={selectedTab}
          value={selectedTab}
          onValueChange={(v) => setSelectedTab(v)}
          css={{
            width: "100%",
            height: "100%",
            boxShadow: "none",
          }}
        >
          <TabsList aria-label="Manage your account">
            <TabsTrigger value="description">Descrição</TabsTrigger>
            {businessAssociateServiceIsEnabled && (
              <TabsTrigger value="config">Configurações</TabsTrigger>
            )}
          </TabsList>
          <TabsContent value="description">
            <BusinessAssociateDetailedDescription
              content={selectedBusinessAssociateService.detailed_description}
            />
          </TabsContent>
          {businessAssociateServiceIsEnabled && (
            <TabsContent value="config" css={{ height: "100%" }}>
              <>
                {selectedBusinessAssociateService.govendas_fields.length > 0 ||
                selectedBusinessAssociateService.associate_fields.length > 0 ? (
                  <Flex
                    css={{
                      flexDirection: "column",
                      gap: "$4",
                      height: "200px",
                    }}
                  >
                    <UpdateBusinessAssociateServiceConfigDialog
                      onUpdateBusinessAssociate={handleEnableBusinessAssociate}
                    />

                    <BusinessAssociateConfigServiceList />
                  </Flex>
                ) : (
                  <ContentWithImage size="large" image={FireWorksImage}>
                    Para esse serviço, não é necessário configuração.
                  </ContentWithImage>
                )}
              </>
            </TabsContent>
          )}
        </TabsRoot>
      </Flex>
    </Flex>
  );
}
