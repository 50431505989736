import { EmptyContent } from "@/components/EmptyContent";
import { User } from "@/core/models/user.model";
import {
  Box,
  Button,
  Caption,
  Checkbox,
  Flex,
  Input,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components";

interface ReportSalesmanFilterProps {
  salesmans: User[];
  selectedSalesmans: User[];
  onSetSelectedSalesmans: (user: User[]) => void;
}

export function ReportSalesmanFilter({
  salesmans,
  selectedSalesmans,
  onSetSelectedSalesmans,
}: ReportSalesmanFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [auxSalesmans, setAuxSalesmans] = useState(salesmans);
  const [auxSelectedSalesmans, setAuxSelectedSalesmans] =
    useState(selectedSalesmans);

  const handleSelectedSalesmanChange = async (checked: boolean, user: User) => {
    let users: User[] = [];
    if (!checked) {
      users = auxSelectedSalesmans.filter((sup) => sup.id !== user.id);
    } else {
      users = [...auxSelectedSalesmans, user];
    }

    setAuxSelectedSalesmans(users);
  };

  const handleApply = () => {
    onSetSelectedSalesmans(auxSelectedSalesmans);
    setIsOpen(false);
  };

  const cleanChanges = () => {
    setAuxSalesmans(salesmans);
    setAuxSelectedSalesmans(salesmans);
  };

  const handleInputChange = (value: string) => {
    const searchTerm = value.toLowerCase();
    const filteredUsers = salesmans.filter((user) =>
      user.name.toLowerCase().includes(searchTerm)
    );
    setAuxSalesmans(filteredUsers);
  };

  useEffect(() => {
    setAuxSalesmans(salesmans);
  }, [salesmans]);

  useEffect(() => {
    setAuxSelectedSalesmans(selectedSalesmans);
  }, [selectedSalesmans]);

  return (
    <>
      <Flex css={{ gap: "$2" }}>
        <Popover open={isOpen} onOpenChange={(v) => setIsOpen(v)}>
          <PopoverTrigger style={{ background: "transparent" }}>
            <FilterContainer>
              <Flex
                css={{
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Caption css={{ color: "$gray500" }}>
                  Vendedores selecionados:
                </Caption>
                <Caption css={{ color: "$gray700", ml: "4px" }}>
                  {auxSelectedSalesmans.length}
                </Caption>
              </Flex>
            </FilterContainer>
          </PopoverTrigger>
          <PopoverContent
            align="center"
            css={{
              maxHeight: "450px",
              maxWidth: "600px",
              width: "100%",
              borderRadius: "6px",
              boxShadow: "$md",
            }}
          >
            <PopoverClose aria-label="Close">
              <MdClose />
            </PopoverClose>

            <Flex css={{ flexDirection: "column", gap: "$4" }}>
              <Flex css={{ flexDirection: "column", gap: "$1" }}>
                <Caption>Procurar vendedor</Caption>
                <Input
                  placeholder="Ex. Vendedor 4"
                  onChange={(e) => handleInputChange(e.target.value)}
                />
              </Flex>
              <Box
                css={{
                  minWidth: "300px",
                  maxHeight: "300px",
                  overflowY: "scroll",
                }}
              >
                <Flex css={{ flexDirection: "column", gap: "$2" }}>
                  {auxSalesmans.length > 0 ? (
                    <>
                      {auxSalesmans.map((salesman) => {
                        return (
                          <Flex key={salesman.id} css={{ gap: "$2" }}>
                            <Checkbox
                              checked={auxSelectedSalesmans.includes(salesman)}
                              onCheckedChange={(e) =>
                                handleSelectedSalesmanChange(
                                  Boolean(e),
                                  salesman
                                )
                              }
                            ></Checkbox>
                            <Text>{salesman.name}</Text>
                          </Flex>
                        );
                      })}
                    </>
                  ) : (
                    <EmptyContent size="small">
                      Sem usuários associados ao supervisor/gerente
                    </EmptyContent>
                  )}
                </Flex>
              </Box>

              <Flex
                css={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  gap: "$2",
                }}
              >
                <Button bordered onClick={cleanChanges}>
                  Cancelar alterações
                </Button>
                <Button onClick={handleApply}>Aplicar</Button>
              </Flex>
            </Flex>
          </PopoverContent>
        </Popover>
      </Flex>
    </>
  );
}

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[1]} 0.5rem;
  border-radius: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.gray100};
  cursor: pointer;

  svg {
    color: ${(props) => props.theme.colors.gray500};
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;
