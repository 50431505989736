import { ClientsSkeletonPage } from "@/components/Skeleton/ClientsSkeletonPage";
import {
  ListOfTagsFilter,
  SearchFilters,
  UrlFilters,
} from "@/core/models/listOfTags.model";
import { setDefaultAttributesToFilter } from "@/features/clientInfo/store/clientAttrOptsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { useConvertUrlFiltersToValidFilters } from "../hooks/useConvertUrlFiltersToValidFilters";
import {
  addSelectedFiltersOnUrl,
  addTagsOnSelectedClientFilter,
  getFiltersBasedOnUrlFilters,
  setSelectedAdvancedFilters,
} from "../store/clientAdvancedFiltersSlice";
import { getFunnels } from "../store/funnelsSlice";
import { setSelectedClientsTypeOfView } from "../store/selectedClientsTypeOfViewSlice";
import { setSelectedSearchFilters } from "../store/selectedSearchFiltersSlice";
import { ClientsListContainer } from "./ClientsListContainer";
import { ClientsTypeOfView } from "./ClientsListPage";

export function ListWithUrlFilterPage() {
  const [filtersStatus, setFiltersStatus] = useState("loading");
  const [getFunnelsStatus, setGetFunnelsStatus] = useState("loading");

  const dispatch = useAppDispatch();
  const convertUrlFilters = useConvertUrlFiltersToValidFilters();

  const handleClientsTypeOfViewView = async (option: ClientsTypeOfView) => {
    await dispatch(setSelectedClientsTypeOfView(option));
  };

  const handleSetFilters = async (filters: ListOfTagsFilter) => {
    const advancedFilters = filters.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = filters.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    await dispatch(setDefaultAttributesToFilter());

    const searchFilters: SearchFilters = {
      match_type: filters.match.match_type,
      match_value: filters.match.match_value,
    };

    await dispatch(setSelectedSearchFilters(searchFilters));
    setFiltersStatus("fulfilled");
  };

  useEffect(() => {
    setFiltersStatus("loading");
    setGetFunnelsStatus("loading");

    async function loadFilters() {
      const url = window.location.href;
      const queryParams = new URLSearchParams(url.split("?")[1]);

      const convertedFilters = convertUrlFilters(queryParams);

      await dispatch(getFiltersBasedOnUrlFilters(convertedFilters)).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            const response: UrlFilters = res.payload;
            handleSetFilters(response.filters);
            await dispatch(
              addSelectedFiltersOnUrl({
                ...response,
                filters: { shared_name: convertedFilters.shared_name },
              })
            );
          }
        }
      );
    }

    const loadFunnels = async () => {
      await dispatch(getFunnels()).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setGetFunnelsStatus("fulfilled");
        }
      });
    };

    loadFilters();
    loadFunnels();
  }, []);

  return (
    <>
      {filtersStatus === "fulfilled" && getFunnelsStatus === "fulfilled" ? (
        <ClientsListContainer
          onClientsTypeOfViewChange={handleClientsTypeOfViewView}
        />
      ) : (
        <ClientsSkeletonPage />
      )}
    </>
  );
}
