import { gvTheme, Text } from "@gogeo-io/ui-library";
import { Close, Done } from "@mui/icons-material";
import G_UI from "@ui/index";

interface ByUpAndDownProps {
  text: string;
  tag_value: boolean;
}

export function ByOkNo({ text, tag_value }: ByUpAndDownProps) {
  return (
    <G_UI.ProductTagLabel>
      {tag_value ? (
        <Done style={{ color: gvTheme.colors.primary500 }} />
      ) : (
        <Close style={{ color: gvTheme.colors.danger500 }} />
      )}
      <Text>{text}</Text>
    </G_UI.ProductTagLabel>
  );
}
