import { ClientsTableSkeletonRow } from "@/components/Skeleton/ClientsTableSkeletonRow";
import { ClientParamsResponse } from "@/core/models/client.model";
import useOnScreen from "@/hooks/useOnScreen";
import { Table } from "antd";
import { useEffect, useRef } from "react";

interface ParamsClientsTableProps {
  columns: any;
  data: any;
  onPageChange: (page: number) => void;
  currentPage: number;
  onIncreasePageNumber: () => void;
  hasMorePages: boolean;
  status: string;
}

export function ParamsClientsTable({
  columns,
  data,
  onPageChange,
  currentPage,
  onIncreasePageNumber,
  hasMorePages = true,
  status,
}: ParamsClientsTableProps) {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const generateRowKey = (record: ClientParamsResponse) => {
    return `${record.client.index}-${record.client.id}`;
  };

  useEffect(() => {
    if (currentPage !== 0) {
      if (hasMorePages) {
        onPageChange(currentPage);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (hasMorePages) {
      onIncreasePageNumber();
    }
  }, [isVisible]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        loading={status === "loading"}
        pagination={false}
        rowKey={generateRowKey}
        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                style={{
                  position: "sticky",
                  top: -25,
                  zIndex: 1,
                }}
              />
            ),
          },
        }}
      />
      <div ref={ref}>{hasMorePages ? <ClientsTableSkeletonRow /> : null}</div>
    </>
  );
}
