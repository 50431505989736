import { Caption, Flex } from "@gogeo-io/ui-library";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

interface TagLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  isSelectable?: boolean;
  isSelected?: boolean;
  hasError?: boolean;
  onClick?: () => void;
  hasCloseButton?: boolean;
  onClickCloseButton?: () => void;
  children: any;
}

export function TagLabel({
  isSelectable = false,
  isSelected = false,
  hasCloseButton = false,
  hasError = false,
  onClick,
  onClickCloseButton,
  children,
  ...props
}: TagLabelProps) {
  return (
    <Container
      $isSelected={isSelected}
      $isSelectable={isSelectable}
      $hasError={hasError}
      onClick={onClick}
      {...props}
    >
      <Flex
        css={{
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {isSelected ? (
          <Caption css={{ color: hasError ? "$red500" : "$gray200" }}>
            {children}
          </Caption>
        ) : (
          <Caption css={{ color: hasError ? "$red500" : "$gray700" }}>
            {children}
          </Caption>
        )}
      </Flex>
      {hasCloseButton && <CloseIcon onClick={onClickCloseButton} />}
    </Container>
  );
}

interface ContainerProps {
  $isSelected: boolean;
  $isSelectable: boolean;
  $hasError: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[1]} 0.5rem;
  border-radius: ${(props) => props.theme.space[2]};
  border: 1px solid
    ${(props) =>
      props.$hasError ? props.theme.colors.red300 : props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.gray100};
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  ${({ theme, $isSelected, $isSelectable }) =>
    $isSelected &&
    $isSelectable &&
    `
      background-color: ${theme.colors.gray700};
      border: none;
    `}

  &:hover {
    ${({ theme, $isSelected, $isSelectable }) =>
      $isSelectable &&
      `
    background-color: ${
      $isSelected ? theme.colors.gray900 : theme.colors.gray200
    };
  `}
  }

  svg {
    color: ${(props) =>
      props.$hasError ? props.theme.colors.red500 : props.theme.colors.gray500};
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;
