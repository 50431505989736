import { FileMetadata } from "@/core/models/file.model";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { TextEditorFileMetadata } from "./TextEditorFileMetadata";

interface TextEditorFilesMetadataProps {
  title: string;
  files: FileMetadata[];
  onRemoveFile: (fileNameToRemove: string) => void;
}

export function TextEditorFilesMetadata({
  title,
  files,
  onRemoveFile,
}: TextEditorFilesMetadataProps) {
  return (
    <Flex css={{ gap: "$3", alignItems: "center", flexWrap: "wrap" }}>
      <Caption css={{ color: "$gray500" }}>{title}</Caption>
      {files &&
        files.map((file) => {
          return (
            <TextEditorFileMetadata
              key={file.filename}
              file={file}
              onRemoveFile={onRemoveFile}
            />
          );
        })}
    </Flex>
  );
}
