import { User } from "@/core/models/user.model";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Caption,
  Checkbox,
  Flex,
  Heading,
} from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";

interface UserToShareListOnGovendasProps {
  user: User;
  userIsSelected: boolean;
  onChangeUserSelected: (value: boolean, user: User) => void;
}

export function UserToShareListOnGovendas({
  user,
  userIsSelected,
  onChangeUserSelected,
}: UserToShareListOnGovendasProps) {
  const checkboxChanged = (value: boolean) => {
    onChangeUserSelected(value, user);
  };

  return (
    <Flex css={{ alignItems: "center", gap: "$3" }}>
      <Checkbox
        checked={userIsSelected}
        onCheckedChange={(e) => checkboxChanged(Boolean(e))}
      />

      <Flex css={{ flexDirection: "row", gap: "$1" }}>
        <Flex css={{ position: "relative" }}>
          {!isEmpty(user.user_photo) ? (
            <Avatar size="medium">
              {user.user_photo.photo_sm_url ? (
                <AvatarImage src={user.user_photo.photo_sm_url} />
              ) : (
                <AvatarFallback>{user.user_photo.fallback}</AvatarFallback>
              )}
            </Avatar>
          ) : (
            <Avatar size="medium">
              <AvatarFallback>GO</AvatarFallback>
            </Avatar>
          )}
        </Flex>

        <Flex css={{ flexDirection: "column", alignItems: "flex-start" }}>
          <Heading size="subtitle1">{user.name}</Heading>

          <Caption>{user.email}</Caption>
        </Flex>
      </Flex>
    </Flex>
  );
}
