import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function ProductsFiltersSkeleton() {
  return (
    <Flex css={{ width: "600px", flexDirection: "column", gap: "1rem" }}>
      <RLSSkeleton width={320} height={30} />
      <RLSSkeleton width={50} height={20} />
      <RLSSkeleton width={120} height={20} />

      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <RLSSkeleton width={50} height={20} />
        <RLSSkeleton width={50} height={20} />
      </Flex>
    </Flex>
  );
}
