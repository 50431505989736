import { AttendanceRecord } from "@/core/models/history.model";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { useState } from "react";
import UI from "../ui";
import { TimelineAttendanceRecordAttendanceInfo } from "./TimelineAttendanceRecordAttendanceInfo";
import { TimelineHeaderInfoContentHeader } from "./TimelineHeaderInfo/TimelineHeaderInfoContentHeader";
import { TimelineHeaderInfoOrderFiles } from "./TimelineHeaderInfo/TimelineHeaderInfoOrderFiles";
import { TimelineHeaderInfoOrderInfo } from "./TimelineHeaderInfo/TimelineHeaderInfoOrderInfo";
import { TimelineHeaderInfoOrderObservations } from "./TimelineHeaderInfo/TimelineHeaderInfoOrderObservations";

interface TimelineAttendanceRecordAttendanceItemProps {
  attendance: AttendanceRecord;
}

export function TimelineAttendanceRecordAttendanceItem({
  attendance,
}: TimelineAttendanceRecordAttendanceItemProps) {
  const [open, setOpen] = useState<boolean>(false);

  const {
    feedback_timestamp,
    feedback_id,
    observations,
    attendance_info,
    feedback,
    order_info,
    files,
  } = attendance;

  const handleOpen = () => setOpen((prev) => !prev);

  return (
    <>
      <UI.TimelineAttendanceRecordAttendanceItem>
        <TimelineHeaderInfoContentHeader
          feedback={feedback}
          feedback_id={feedback_id}
          feedback_timestamp={feedback_timestamp}
          files={files}
          observations={observations}
          onMoreInfoOpen={handleOpen}
        />

        <Flex css={{ alignItems: "center", gap: "$3" }}>
          {attendance_info && (
            <TimelineAttendanceRecordAttendanceInfo
              attendance_info={attendance_info}
              isBackgroundGray={false}
            />
          )}

          {order_info && (
            <TimelineHeaderInfoOrderInfo
              order_amount={order_info.amount}
              order_id={order_info.order_id}
            />
          )}
        </Flex>
      </UI.TimelineAttendanceRecordAttendanceItem>
      {open && observations && observations !== "" && (
        <Flex css={{ flexDirection: "column", paddingBottom: "$3" }}>
          <Caption>Observações:</Caption>
          <TimelineHeaderInfoOrderObservations observations={observations} />
        </Flex>
      )}

      {open && files.length > 0 && (
        <Flex css={{ flexDirection: "column", paddingBottom: "$3" }}>
          <Caption>Anexos:</Caption>
          <TimelineHeaderInfoOrderFiles files={files} />
        </Flex>
      )}
    </>
  );
}
