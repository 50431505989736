import { useFormatDate } from "@/hooks/useFormatDate";
import { Flex, Text } from "@gogeo-io/ui-library";

import { addMinutes, getHours, getMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { ReminderPickHourAmPm } from "./ReminderPickHourAmPm";
import { ReminderPickHourInput } from "./ReminderPickHourInput";
import { ReminderPickMinutesInput } from "./ReminderPickMinutesInput";

interface PickHourProps {
  onChange: (...event: any[]) => void;
}

export function ReminderPickHour({ onChange }: PickHourProps) {
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [amPm, setAmPm] = useState<"am" | "pm">("am");
  const [isLoading, setIsLoading] = useState(true);

  const { formatWithDigits } = useFormatDate();

  const handleSetAmPm = (option: "am" | "pm") => setAmPm(option);

  const handleHourChange = (hour: number) => setHour(hour);

  const handleMinutesChange = (minutes: number) => setMinutes(minutes);

  const getInitialTime = () => {
    const now = new Date(new Date());

    const dateWith10MinutesAdded = addMinutes(now, 10);

    const hours = getHours(dateWith10MinutesAdded);
    const minutes = getMinutes(dateWith10MinutesAdded);

    const amPmTime: "am" | "pm" = hours < 12 || hours === 24 ? "am" : "pm";

    return {
      hour: hours,
      minutes: minutes,
      amPm: amPmTime,
    };
  };

  useEffect(() => {
    let hourFormatted = hour;

    if (amPm === "pm") {
      hourFormatted = hourFormatted + 12;
    }

    onChange(
      `${formatWithDigits(hourFormatted, 2)}:${formatWithDigits(minutes, 2)}`
    );
  }, [hour, minutes, amPm]);

  useEffect(() => {
    setIsLoading(false);

    const initialTime = getInitialTime();

    setHour(initialTime.hour);
    setMinutes(initialTime.minutes);
    setAmPm(initialTime.amPm);

    setIsLoading(false);
  }, []);

  return (
    <Flex css={{ flexDirection: "column" }}>
      {!isLoading && (
        <>
          <Text css={{ marginBottom: "$2", textAlign: "left" }}>
            Selecione a hora do lembrete:
          </Text>
          <Flex css={{ alignItems: "center", gap: "$4" }}>
            <ReminderPickHourInput
              handleHourChange={handleHourChange}
              defaultHour={hour}
            />
            :
            <ReminderPickMinutesInput
              handleMinutesChange={handleMinutesChange}
              defaultMinutes={minutes}
            />
            <ReminderPickHourAmPm
              selectedOption={amPm}
              onSelectedOptionChange={handleSetAmPm}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
}
