import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { NoRuptureRuleSelected } from "../components/NoRuptureRuleSelected";
import { RuptureRuleDisabled } from "../components/RuptureRuleDisabled";
import { selectRuptureRule } from "../store/ruptureRuleSlice";

export function RuptureRuleListPage() {
  const rule = useSelector(selectRuptureRule);

  const { pathname } = useLocation();

  return (
    <>
      {rule.enabled ? (
        <>
          {pathname === "/rupture-rule" ? (
            <NoRuptureRuleSelected />
          ) : (
            <Outlet />
          )}
        </>
      ) : (
        <RuptureRuleDisabled />
      )}
    </>
  );
}
