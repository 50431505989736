import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, Flex, Heading, Text } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  enableRule,
  getRuptureRule,
  selectRuptureRule,
} from "../store/ruptureRuleSlice";

export function RuptureRuleDisabled() {
  const rule = useSelector(selectRuptureRule);

  const dispatch = useAppDispatch();

  const handleEnableRule = async () => {
    await dispatch(enableRule({ rule_id: rule.id, enabled: true })).then(
      async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          await dispatch(getRuptureRule()).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
              toast.success("Regras multicanais habilitadas");
            }
          });
        }
      }
    );
  };

  return (
    <Flex
      css={{
        width: "100%",
        height: "100%",
        gap: "$4",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Heading size="title4">Que pena!</Heading>
      <Text>As regras multicanais não estão habilitadas para sua empresa!</Text>
      <Button onClick={() => handleEnableRule()}>
        Habilitar regras multicanal
      </Button>
    </Flex>
  );
}
