import { NoPageSelected } from "@/components/NoPageSelected";
import { RuptureRule } from "@/core/models/rules";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Main } from "@/layouts/Main";
import OpenTabsImg from "@assets/choose.svg";
import { Button } from "@gogeo-io/ui-library";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useBreadcrumbsForRuptureRulePage } from "../hooks/useBreadcrumbsForRuptureRulePage";
import { setSelectRuptureRule } from "../store/ruptureRuleSlice";

export function NoRuptureRuleSelected() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleNavigate = async () => {
    await dispatch(setSelectRuptureRule({} as RuptureRule));
    navigate("create-rupture-rule");
  };

  const { basicBreadrumb } = useBreadcrumbsForRuptureRulePage();

  useEffect(() => {
    basicBreadrumb();
  }, []);

  return (
    <Main>
      <NoPageSelected
        src={OpenTabsImg}
        alt="Selecione uma regra de ruptura"
        title="Selecione uma regra de ruptura"
      >
        <span style={{ display: "block" }}>Selecione uma regra de ruptura</span>
        <span style={{ display: "block" }}>ou</span>
        <Button onClick={handleNavigate}>Crie uma nova regra</Button>
      </NoPageSelected>
    </Main>
  );
}
