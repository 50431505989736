import { PreDefinedMessageSubject } from "@/core/models/preDefinedMessage.model";

export const useTranslateSubject = () => {
  return (subject: PreDefinedMessageSubject) => {
    switch (subject) {
      case "general":
        return "Geral";
      case "products_suggestion":
        return "Sugestão de Produtos";
      case "client":
        return "Cliente";
      default:
        return "Geral";
    }
  };
};
