import { selectUser } from "@/core/store/users/usersSlice";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { useSelector } from "react-redux";

export const BuildMixpanelProps = () => {
  const selectedClient = useSelector(selectClient);
  const user = useSelector(selectUser);

  return (type?: string) => {
    if (type === "client") {
      return {
        client_id: selectedClient?.id,
        client_name: selectedClient?.name,
        user_id: user.id,
        account_id: user.account_id,
        user_role: user.role,
        user_name: user.name,
      };
    } else {
      return {
        user_id: user.id,
        account_id: user.account_id,
        user_role: user.role,
        user_name: user.name,
      };
    }
  };
};
