import * as yup from "yup";

export const ruptureRulesSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  schedule_prospects: yup.boolean().required("Campo obrigatório"),
  producers: yup
    .array()
    .of(yup.string())
    .min(
      1,
      "Pelo menos um vendedor com ruptura monitorada deve ser preenchido"
    ),
  consumers: yup
    .array()
    .of(yup.string())
    .min(
      1,
      "Pelo menos um vendedor que monitorará clientes em ruptura deve ser preenchido"
    ),
  rules: yup
    .object()
    .shape({
      sales_rupture_lower_bound: yup.string(),
      sales_rupture_upper_bound: yup.string(),
      visit_rupture_lower_bound: yup.string(),
      visit_rupture_upper_bound: yup.string(),
      generic_filter: yup.string(),
    })
    .test(
      "at-least-one-property-is-required",
      "Pelo menos um dos campos de Ruptura de Visita ou Ruptura de Positivação é obrigatório",
      (value) =>
        !!(
          value.sales_rupture_lower_bound ||
          value.sales_rupture_upper_bound ||
          value.visit_rupture_lower_bound ||
          value.visit_rupture_upper_bound
        )
    ),
});
