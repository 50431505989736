import { SortOrder } from "@/core/models/listOfTags.model";
import { RootState } from "@/core/store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ClientColumnsToOrderState {
  columnsToSort: SortOrder[];
  status: "loading" | "success" | "failed";
}

const initialState = {
  columnsToSort: [],
  status: "loading",
} as ClientColumnsToOrderState;

export const clientColumnsToSortSlice = createSlice({
  name: "clientColumnsToSortSlice",
  initialState,
  reducers: {
    setColumnsToSort(state, action: PayloadAction<SortOrder[]>) {
      state.columnsToSort = action.payload;
      state.status = "success";
    },
    cleanColumnsToSort(state) {
      state.columnsToSort = [];
      state.status = "success";
    },
  },
});

export const selectColumnsToSort = (state: RootState) =>
  state.clientColumnsToSortState.columnsToSort;
export const selectColumnsToSortStatus = (state: RootState) =>
  state.clientColumnsToSortState.status;

export const { setColumnsToSort, cleanColumnsToSort } =
  clientColumnsToSortSlice.actions;

export const clientColumnsToSortReducer = clientColumnsToSortSlice.reducer;
