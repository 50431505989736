import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ListOfTags,
  ListOfTagsFilter,
} from "../../../core/models/listOfTags.model";
import { RootState } from "../../../core/store/store";

interface ListSelectedState {
  list: ListOfTags;
  status: "loading" | "success" | "failed";
}

const initialState = {
  list: {},
  status: "loading",
} as ListSelectedState;

export interface UpdateListOfTags {
  id: string;
  filters: ListOfTagsFilter;
}

export const listSelectedSlice = createSlice({
  name: "listOfTagsSlice",
  initialState,
  reducers: {
    selectList(state, action: PayloadAction<ListOfTags>) {
      state.list = action.payload;
      state.status = "success";
    },
  },
});

export const selectSelectedList = (state: RootState) =>
  state.listSelectedState.list;

export const { selectList } = listSelectedSlice.actions;

export const listSelectedReducer = listSelectedSlice.reducer;
