import { History } from "@/core/models/history.model";
import { Flex } from "@gogeo-io/ui-library";
import { HistoryPageSkeleton } from "./HistoryPageSkeleton";
import { TimelineItem } from "./TimelineItem";

interface TimelineListProps {
  history: History[];
  historyIsCollapsed?: boolean;
  hasMorePages: boolean;
}

export const TimelineList = ({
  history,
  historyIsCollapsed = false,
  hasMorePages,
}: TimelineListProps) => {
  return (
    <>
      {history.map((hist, idx) => {
        return (
          <div key={idx}>
            {hist.history_entries.map((entrie, idx) => {
              return (
                <TimelineItem
                  key={idx}
                  attendance_record={entrie.attendance_record}
                  order_total_amount={entrie.order_total_amount}
                  salesman={entrie.salesman}
                  historyIsCollapsed={historyIsCollapsed}
                />
              );
            })}
          </div>
        );
      })}
      <div id="sentry">
        {hasMorePages ? (
          <Flex css={{ mt: "$1" }}>
            <HistoryPageSkeleton count={1} />
          </Flex>
        ) : null}
      </div>
    </>
  );
};
