import styled from "styled-components";

export const ListIsGoVendasSuggested = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.primary100};
  color: ${({ theme }) => theme.colors.primary600};
  margin-right: 0.5rem;
`;
