export const useMask = () => {
  const useCpfCnpjMask = (value: string) => {
    if (!value) return "";

    value = value.replace(/\D/g, "");

    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      value = value.replace(/^(\d{2})(\d)/, "$1.$2");
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
      value = value.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return value;
  };

  const usePhoneMask = (value: string) => {
    if (!value) return "";

    value = value.replace(/\D/g, "");

    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");

    return value;
  };

  const useCepMask = (value: string) => {
    if (!value) return "";

    value = value.replace(/\D/g, "");

    value = value.replace(/^(\d{5})(\d)/, "$1-$2");

    return value;
  };

  return { useCpfCnpjMask, usePhoneMask, useCepMask };
};
