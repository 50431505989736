import { ErrorContent } from "@/components/ErrorContent";
import { Box } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { selectClientNotesHasError500 } from "../store/clientNotesSlice";

export function ClientNotesPage() {
  const clientNotesHasError500 = useSelector(selectClientNotesHasError500);

  return (
    <Box css={{ width: "100%", height: "100%" }}>
      {clientNotesHasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos as notas do cliente, tente novamente mais tarde ou
          atualize a página
        </ErrorContent>
      ) : (
        <Outlet />
      )}
    </Box>
  );
}
