import { ListDivider } from "@/components/ListDivider";
import { RegisterAttendance } from "@/features/attendance/components/register-attendance/RegisterAttendance";
import { getFeedbackMapping } from "@/features/attendance/store/feedbackMappingSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectClient } from "../store/clientSlice";
import UI from "../ui";
import { ClientProfileAvatar } from "./ClientProfileAvatar";

export const ClientProfile = () => {
  const selectedClient = useSelector(selectClient);

  const dispatch = useAppDispatch();

  useEffect(() => {
    async function loadFeedbackMapping() {
      await dispatch(getFeedbackMapping());
    }

    loadFeedbackMapping();
  }, []);

  return (
    <UI.ClientProfile>
      <ClientProfileAvatar client={selectedClient} />

      {/* <ClientProfileContact client={selectedClient} /> */}

      <ListDivider />

      <UI.ClientInfoWrapper>
        <RegisterAttendance />
      </UI.ClientInfoWrapper>
    </UI.ClientProfile>
  );
};
