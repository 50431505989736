import { Caption, Flex, Text } from "@gogeo-io/ui-library";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import styled from "styled-components";
import { FileAttached } from "./FileAttached";

interface DropzoneToAttachFileProps {
  value: Array<any>;
  onAttachFiles: (files: Array<any>) => void;
  onRemoveFileAttached: (fileNameToRemove: string) => void;
}

export function DropzoneToAttachFile({
  value,
  onRemoveFileAttached,
  onAttachFiles,
}: DropzoneToAttachFileProps) {
  const [files, setFiles] = useState(value);

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 10000000,
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            toast.warn(
              "Este arquivo é muito grande! Tente novamente com um arquivo menor que 10 mb."
            );
          }
        });
      });

      const files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles((prevFiles) => [...prevFiles, ...files]);
      onAttachFiles(files);
    },
  });

  const handleRemoveFileAttached = (fileNameToRemove: string) => {
    onRemoveFileAttached(fileNameToRemove);

    const filesWithoutRemovedOne = files.filter(
      (file) => file.name !== fileNameToRemove
    );
    setFiles(filesWithoutRemovedOne);
  };

  return (
    <Flex css={{ padding: "2.5rem", flexDirection: "column", gap: "2rem" }}>
      <DragContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <CloudUploadIcon />
        <Text css={{ color: "$gray600", textAlign: "center" }}>
          Arraste aqui seu arquivo ou selecione um arquivo
        </Text>
        <Caption css={{ color: "$gray400" }}>
          Tamanho máximo do arquivo: 10mb
        </Caption>
      </DragContainer>

      <FilesAttachedContainer>
        {files &&
          files.map((file) => {
            return (
              <FileAttached
                file={file}
                key={file.name}
                onRemoveFileAttached={handleRemoveFileAttached}
              />
            );
          })}
      </FilesAttachedContainer>
    </Flex>
  );
}

export const DragContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  padding: 2.5rem;
  border-radius: 2px;
  border: 2px dotted ${(props) => props.theme.colors.primary700};
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    padding: 1rem;
  }

  svg {
    color: ${(props) => props.theme.colors.primary600};
  }
`;

export const FilesAttachedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
  max-height: 300px;
  overflow-y: scroll;
  padding-right: ${(props) => props.theme.space[2]};

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    max-height: 180px;
  }

  @media (max-width: ${(props) => props.theme.mediaSizes.smallLaptop}) {
    max-height: 80px;
  }
`;
