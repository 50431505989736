import { ShowAndChangePreferedDays } from "@/components/PreferedDays/ShowAndChangePreferedDays";
import { PreferedDaysAndWeeksSubtitle } from "@/components/PreferedDaysAndWeeksSubtitle";
import { DaysWeekPref } from "@/core/models/params.model";
import { changeParams } from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, Switch, SwitchThumb, Text } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectAllClientsSelectedToChangeParams,
  selectClientSelectedToChangeParams,
} from "../../store/clientSelectedToChangeParamsSlice";

export function ChangeParamsFormContactDate() {
  const [errorMessage, setErrorMessage] = useState("");
  const [canChangeFreqToSelectedClients, setCanChangeFreqToSelectedClients] =
    useState(false);
  const [daysWeekPref, setDaysWeekPref] = useState<DaysWeekPref>(
    {} as DaysWeekPref
  );

  const selectedClientsToChangeParams = useSelector(
    selectClientSelectedToChangeParams
  );
  const allClientsSelectedToChangeParams = useSelector(
    selectAllClientsSelectedToChangeParams
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    setCanChangeFreqToSelectedClients(false);

    const setParams = async () => {
      if (allClientsSelectedToChangeParams) {
        setDaysWeekPref({} as DaysWeekPref);
        setErrorMessage("");
      } else {
        if (!isEmpty(selectedClientsToChangeParams)) {
          if (selectedClientsToChangeParams.length === 1) {
            const clientSelected = selectedClientsToChangeParams[0];
            if (
              clientSelected.params.days_week_pref !== undefined &&
              !isEmpty(clientSelected.params.days_week_pref)
            ) {
              setDaysWeekPref(clientSelected.params.days_week_pref);
              setErrorMessage("");
            } else {
              setErrorMessage("*Atenção parâmetro não configurado");
            }
          } else {
            setDaysWeekPref({} as DaysWeekPref);
            setErrorMessage("");
          }
        } else {
          setDaysWeekPref({} as DaysWeekPref);
          setErrorMessage("");
        }
      }
    };

    setParams();
  }, [selectedClientsToChangeParams, allClientsSelectedToChangeParams]);

  const handleSwitchChange = async (value: boolean) => {
    setCanChangeFreqToSelectedClients(value);
    if (value) {
      setDaysWeekPref(daysWeekPref);
      await dispatch(changeParams({ days_week_pref: daysWeekPref }));
    } else {
      setDaysWeekPref({} as DaysWeekPref);
      await dispatch(changeParams({ days_week_pref: "indeterminate" }));
    }
  };

  const handleChangedDaysWeekPref = (params: DaysWeekPref) => {
    setDaysWeekPref(params);
  };

  const handleCalculatePreferenceIsDisabled = (): boolean => {
    if (
      selectedClientsToChangeParams.length > 1 ||
      allClientsSelectedToChangeParams
    ) {
      return canChangeFreqToSelectedClients;
    } else if (selectedClientsToChangeParams.length === 1) {
      return true;
    } else {
      return false;
    }
  };

  const preferedDaysIsDisabled: boolean = handleCalculatePreferenceIsDisabled();

  return (
    <Flex css={{ flexDirection: "column", gap: "$3" }}>
      <Flex css={{ alignItems: "center", gap: "$2" }}>
        <Text size="body1" css={{ color: "$black" }}>
          Dias preferidos para contato
        </Text>
        {((!isEmpty(selectedClientsToChangeParams) &&
          selectedClientsToChangeParams.length > 1) ||
          allClientsSelectedToChangeParams) && (
          <div className="item">
            <Switch
              defaultChecked
              id="freq"
              checked={canChangeFreqToSelectedClients}
              onCheckedChange={handleSwitchChange}
            >
              <SwitchThumb />
            </Switch>
          </div>
        )}
      </Flex>
      <ShowAndChangePreferedDays
        isBlocked={preferedDaysIsDisabled}
        daysWeekPrefReceived={daysWeekPref}
        onChangeDaysWeekPref={handleChangedDaysWeekPref}
        canChangeFreqToSelectedClients={canChangeFreqToSelectedClients}
        allClientsSelectedToChangeParams={allClientsSelectedToChangeParams}
      />
      <PreferedDaysAndWeeksSubtitle />
      {errorMessage && <G_UI.Info>{errorMessage}</G_UI.Info>}
    </Flex>
  );
}
