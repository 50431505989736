import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { Client } from "@/core/models/client.model";
import {
  ProductRecommendation,
  ProductsFilter,
} from "@/core/models/productsSugestion.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useProductsSuggestionMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const sendedMessageWithProductEvent = (
    client: Client,
    listName: string,
    recommendation: ProductRecommendation
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PRODUCTS_SUGGESTION_SENDED_MESSAGE_WITH_PRODUCT,
      {
        ...buildProps("client"),
        client,
        listName,
        recommendation,
      }
    );
  };

  const likedProductEvent = (
    product_name: string,
    product_id: string,
    salesman_id: string
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PRODUCTS_SUGGESTION_LIKED,
      {
        ...buildProps("client"),
        "Nome do produto": product_name,
        "ID do produto": product_id,
        "ID do vendedor": salesman_id,
      }
    );
  };

  const dislikedProductEvent = (
    product_name: string,
    product_id: string,
    salesman_id: string
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PRODUCTS_SUGGESTION_DISLIKED,
      {
        ...buildProps("client"),
        "Nome do produto": product_name,
        "ID do produto": product_id,
        "ID do vendedor": salesman_id,
      }
    );
  };

  const filteredProductsEvent = (filters: ProductsFilter) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PRODUCTS_SUGGESTION_FILTERED,
      {
        ...buildProps("client"),
        filters,
      }
    );
  };

  return {
    sendedMessageWithProductEvent,
    filteredProductsEvent,
    likedProductEvent,
    dislikedProductEvent,
  };
};
