import axios from "axios";
import { useEffect, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { App } from "./App";
import { AppUnderMaintenance } from "./components/AppUnderMaintenance";
import { SplashScreen } from "./components/SplashScreen";
import { User } from "./core/models/user.model";
import { setError401State } from "./core/store/error401slice";
import { getUser, selectUserStatus } from "./core/store/users/usersSlice";
import {
  getAccount,
  selectAccountStatus,
} from "./features/profile/store/accountSlice";
import useAppDispatch from "./hooks/useAppDispatch";
import { useCheckIfUserIsInWorkingTime } from "./hooks/useCheckIfUserIsInWorkingTime";
import { useMixpanel } from "./hooks/useMixpanel";

const timezoneOffset = new Date().getTimezoneOffset() / -60;

export const defaultHeaders = {
  Accept: "application/json",
  "content-type": "application/json",
  "Accept-Language": "pt-BR",
  "Accept-Timezone": `UTC${
    timezoneOffset !== 0
      ? `${timezoneOffset > 0 ? `+` : ""}${timezoneOffset}`
      : ""
  }`,
};

export const ProdMode: React.FC = () => {
  const userStatus = useSelector(selectUserStatus);
  const accountStatus = useSelector(selectAccountStatus);

  const [tokenIsExpiring, setTokenIsExpiring] = useState(false);
  const [tokenHasExpired, setTokenHasExpired] = useState(false);
  const [isUserLoadedFirstTime, setIsUserLoadedFirstTime] = useState(true);
  const [keycloakIsDown, setKeycloakIsDown] = useState(false);
  const [appIsDown, setAppIsDown] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { checkIfUserIsInWorkingTime } = useCheckIfUserIsInWorkingTime();
  const auth = useAuth();
  const { userLoginEvent } = useMixpanel();

  const loadUserInfo = async () => {
    axios.defaults.baseURL = import.meta.env.VITE_BASE_URL;
    axios.defaults.headers = {
      ...defaultHeaders,
      Authorization: `Bearer ${auth.user?.access_token}`,
    } as any;

    await dispatch(getUser()).then((res) => {
      if (res.payload.status === 500) {
        setAppIsDown(true);
      }

      if (res.payload.status === 403 || res.payload.status === 404) {
        navigate("/logout");
      }

      const user: User = res.payload as User;

      setIsUserLoadedFirstTime(false);

      userLoginEvent(user);

      if (!checkIfUserIsInWorkingTime({ user })) {
        navigate("/user-is-not-in-working-time");
      }
    });

    await dispatch(getAccount()).then((res) => {
      if (res.payload.status === 500) {
        setAppIsDown(true);
      }

      if (res.payload.status === 403 || res.payload.status === 404) {
        navigate("/logout");
      }
    });

    await dispatch(setError401State(false));
  };

  // when token change, update the Authorization on axios headers
  useEffect(() => {
    axios.defaults.headers = {
      ...defaultHeaders,
      Authorization: `Bearer ${auth.user?.access_token}`,
    } as any;
  }, [auth.user?.access_token]);

  // check if user is logged
  useEffect(() => {
    const checkIfExistingSession = async () => {
      if (!auth.isLoading && !auth.isAuthenticated) {
        await auth.signinRedirect();
      }
    };

    checkIfExistingSession();

    if (auth.isAuthenticated && isUserLoadedFirstTime) {
      loadUserInfo();
    }
  }, [auth.isAuthenticated]);

  // error on token renew
  useEffect(() => {
    return auth.events.addSilentRenewError(() => {
      setTokenHasExpired(true);
    });
  }, [auth.events]);

  // token is expiring due to inactivity
  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      setTokenIsExpiring(true);
    });
  }, [auth.events]);

  // token expired
  useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      setTokenHasExpired(true);
    });
  }, [auth.events]);

  // if keycloak is down
  useEffect(() => {
    if (auth.error?.message === "Session not active") {
    }
    if (auth.error?.message === "Session doesn't have required client") {
    }
    if (auth.error?.message === "Failed to fetch") {
      setKeycloakIsDown(true);
    }
  }, [auth.error]);

  // automatically sign-in
  useEffect(() => {
    const automaticallySignIn = async () => {
      if (
        !hasAuthParams() &&
        !auth.isAuthenticated &&
        !auth.activeNavigator &&
        !auth.isLoading
      ) {
        await auth.signinRedirect();
      }
    };

    automaticallySignIn();
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
  ]);

  if (keycloakIsDown || appIsDown) {
    return <AppUnderMaintenance />;
  }

  return userStatus !== "success" && accountStatus !== "success" ? (
    <SplashScreen />
  ) : (
    <App tokenIsExpiring={tokenIsExpiring} tokenHasExpired={tokenHasExpired} />
  );
};
