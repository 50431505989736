import { EmptyContent } from "@/components/EmptyContent";
import { ErrorContent } from "@/components/ErrorContent";
import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import {
  PreDefinedMessage,
  PreDefinedMessageSubject,
} from "@/core/models/preDefinedMessage.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CreatePreDefinedMessage } from "../components/CreatePreDefinedMessage";
import { PreDefinedMessagesTable } from "../components/PreDefinedMessagesTable";
import { UserProfileHeaderToPage } from "../components/UserProfileHeaderToPage";
import { useBreadcrumbsForPreDefinedMessagesPage } from "../hooks/useBreadcrumbsForPreDefinedMessagesPage";
import { usePreDefinedMessagesColumns } from "../hooks/usePreDefinedMessagesColumns";
import { usePreDefinedMessagesMixpanel } from "../hooks/usePreDefinedMessagesMixpanel";
import {
  createPreDefinedMessage,
  deletePreDefinedMessage,
  getPreDefinedMessages,
  selectPreDefinedMessagesHasError500,
  setTotalPreDefinedMessages,
  updatePreDefinedMessage,
} from "../store/preDefinedMessagesSlice";

export function PreDefinedMessagesPage() {
  const [preDefinedMessages, setPreDefinedMessages] = useState<
    PreDefinedMessage[]
  >([]);
  const [preDefinedMessageStatus, setPreDefinedMessageStatus] =
    useState("loading");
  const [loadedPage, setLoadedPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useAppDispatch();
  const { showBreadcrumbs } = useBreadcrumbsForPreDefinedMessagesPage();

  const preDefinedMessagesHasError500 = useSelector(
    selectPreDefinedMessagesHasError500
  );

  const {
    createPreDefinedMessageEvent,
    editPreDefinedMessageEvent,
    deletedPreDefinedMessageEvent,
    getMessagesPreDefinedMessageEvent,
  } = usePreDefinedMessagesMixpanel();

  useEffect(() => {
    async function loadPreDefinedMessages() {
      await dispatch(
        getPreDefinedMessages({
          page: 0,
          size: import.meta.env.VITE_PAGE_SIZE,
        })
      ).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setPreDefinedMessages((prevMessages) => [
            ...prevMessages,
            ...res.payload.content,
          ]);
          getMessagesPreDefinedMessageEvent();
          setLoadedPage(true);
          setPreDefinedMessageStatus("fulfilled");
          await dispatch(setTotalPreDefinedMessages(res.payload.total));
        }
      });
    }

    loadPreDefinedMessages();
    showBreadcrumbs();
  }, []);

  const getDefinedMessages = async (currentPage: number) => {
    await dispatch(
      getPreDefinedMessages({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (!isEmpty(res.payload.content)) {
          setPreDefinedMessages((prevMessages) => [
            ...prevMessages,
            ...res.payload.content,
          ]);
        }
      }
    });
  };

  const handleDeletePreDefinedMessage = async (id: number) => {
    setPreDefinedMessageStatus("loading");

    await dispatch(deletePreDefinedMessage({ id })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        deletedPreDefinedMessageEvent(id, res.payload);
        setPreDefinedMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.id !== id)
        );
        toast.success("Mensagem removida com sucesso");
        setPreDefinedMessageStatus("fulfilled");
      } else {
        toast.error("Houve um problema ao remover a mensagem");
      }
    });
  };

  const handleEditPreDefinedMessage = async (
    id: number,
    subject: PreDefinedMessageSubject,
    title: string,
    message: string,
    shared: boolean
  ) => {
    setPreDefinedMessageStatus("loading");

    await dispatch(
      updatePreDefinedMessage({ id, subject, title, message, shared })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPreDefinedMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === res.payload.id ? res.payload : msg
          )
        );
        toast.success("Mensagem atualizada com sucesso");
        editPreDefinedMessageEvent(id, res.payload);
        setPreDefinedMessageStatus("fulfilled");
      } else {
        toast.error("Houve um problema ao editar a mensagem");
      }
    });
  };

  const handleCreatePreDefinedMessage = async (
    subject: PreDefinedMessageSubject,
    title: string,
    message: string,
    shared: boolean
  ) => {
    setPreDefinedMessageStatus("loading");

    await dispatch(
      createPreDefinedMessage({ subject, title, message, shared })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPreDefinedMessages((prevMessages) => [...prevMessages, res.payload]);
        createPreDefinedMessageEvent(res.payload);
        setPreDefinedMessageStatus("fulfilled");
      }
    });
  };

  const handleEditSharedMessage = async (id: number, shared: boolean) => {
    setPreDefinedMessageStatus("loading");

    await dispatch(updatePreDefinedMessage({ id, shared })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPreDefinedMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === res.payload.id ? res.payload : msg
          )
        );
        toast.success("Mensagem atualizada com sucesso");
        editPreDefinedMessageEvent(id, res.payload);
        setPreDefinedMessageStatus("fulfilled");
      } else {
        toast.error("Houve um problema ao editar a mensagem");
      }
    });
  };

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const preDefinedMessagesColumns = usePreDefinedMessagesColumns(
    handleDeletePreDefinedMessage,
    handleEditSharedMessage,
    handleEditPreDefinedMessage
  );

  return (
    <>
      <Flex css={{ flexDirection: "column", gap: "0.1rem" }}>
        <UserProfileHeaderToPage
          title="Mensagens Pré Definidas"
          description=" Mensangens pré definidas para você conversar com seu cliente com mais
          facilidade e praticidade."
        />
      </Flex>

      <CreatePreDefinedMessage
        onCreatePreDefinedMessage={handleCreatePreDefinedMessage}
      />

      {preDefinedMessagesHasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos buscar suas mensagens pré definidas, tente novamente
          mais tarde ou atualize a página
        </ErrorContent>
      ) : (
        <>
          {loadedPage ? (
            <>
              {!isEmpty(preDefinedMessages) ? (
                <PreDefinedMessagesTable
                  columns={preDefinedMessagesColumns}
                  data={preDefinedMessages}
                  onPageChange={getDefinedMessages}
                  currentPage={currentPage}
                  onIncreasePageNumber={handleIncreasePageNumber}
                  status={preDefinedMessageStatus}
                />
              ) : (
                <EmptyContent size="medium">
                  Você não tem mensagens pré definidas
                </EmptyContent>
              )}
            </>
          ) : (
            <ClientsTableSkeletonPage />
          )}
        </>
      )}
    </>
  );
}
