import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { UserPermission } from "@/core/models/user-permission.model";
import {
  User,
  UserHandler,
  UserOnRelationship,
} from "@/core/models/user.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useUsersMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const activeUserEvent = (user: User) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.USERS_MANAGMENT_ACTIVE_USER,
      {
        ...buildProps(),
        "Usuário ativado": user,
      }
    );
  };

  const activeUsersEvent = (users: User[]) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.USERS_MANAGMENT_ACTIVE_USERS,
      {
        ...buildProps(),
        "Usuários ativados": users,
      }
    );
  };

  const inactiveUserEvent = (user: User) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.USERS_MANAGMENT_INACTIVE_USER,
      {
        ...buildProps(),
        "Usuário desativado": user,
      }
    );
  };

  const inactiveUsersEvent = (users: User[]) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.USERS_MANAGMENT_INACTIVE_USERS,
      {
        ...buildProps(),
        "Usuários desativados": users,
      }
    );
  };

  const changeUserPermissionsEvent = (
    user: User,
    permissions: UserPermission[]
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.USERS_MANAGMENT_CHANGE_USER_PERMISSIONS,
      {
        ...buildProps(),
        "Usuário para alterar permissões": user,
        "Permissões alteradas": permissions,
      }
    );
  };

  const changeUsersPermissionsEvent = (
    users: User[],
    permissions: UserPermission[]
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.USERS_MANAGMENT_CHANGE_USER_PERMISSIONS,
      {
        ...buildProps(),
        "Usuários para alterar permissões": users,
        "Permissões alteradas": permissions,
      }
    );
  };

  const createUserEvent = (user: UserHandler) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.USERS_MANAGMENT_CREATE_USER,
      {
        ...buildProps(),
        "Usuário criado": user,
      }
    );
  };

  const createUsersEvent = (users: User[]) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.USERS_MANAGMENT_CREATE_USERS,
      {
        ...buildProps(),
        "Usuários criados": users,
      }
    );
  };

  const editUserEvent = (user: UserHandler) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.USERS_MANAGMENT_EDIT_USER,
      {
        ...buildProps(),
        "Usuário editado": user,
      }
    );
  };

  const changeUserRelationships = (
    user: User,
    associatedUsers: UserOnRelationship[],
    desassociatedUsers: UserOnRelationship[]
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.USERS_MANAGMENT_ASSOCIATED_USER_CHANGE,
      {
        ...buildProps(),
        "Usuário com o relacionamento alterado": user,
        "Usuários associados": associatedUsers,
        "Usuários desassociados": desassociatedUsers,
      }
    );
  };

  return {
    activeUserEvent,
    activeUsersEvent,
    inactiveUserEvent,
    inactiveUsersEvent,
    changeUserPermissionsEvent,
    changeUsersPermissionsEvent,
    createUserEvent,
    createUsersEvent,
    editUserEvent,
    changeUserRelationships,
  };
};
