import { useNavigate } from "react-router-dom";

export const useGoBack = () => {
  const navigate = useNavigate();

  const navigateGoBack = (): void => {
    navigate(-1);
  };

  return { navigateGoBack };
};
