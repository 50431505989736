import { Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";
import { useDateByDateFilter } from "@hooks/useDateByDateField";
import { useEffect, useState } from "react";

interface InThisOneDateSelectProps {
  filterId: number;
  value: string;
  onFilterValueChange: (filterId: number, newValue: string) => void;
}

export function InThisOneDateSelect({
  filterId,
  value,
  onFilterValueChange,
}: InThisOneDateSelectProps) {
  const { getValuesByDateFilter } = useDateByDateFilter();

  const dateFilter = getValuesByDateFilter(
    value !== undefined ? value : "inthis1months"
  );
  const [thisDate, setThisDate] = useState(
    dateFilter.inthis !== undefined ? dateFilter.inthis.date_option : "months"
  );

  useEffect(() => {
    const formattedDate = `inthis1${thisDate}`;
    onFilterValueChange(filterId, formattedDate);
  }, [thisDate]);

  return (
    <Select
      defaultValue={thisDate}
      ariaLabel="Past Date"
      onValueChange={(v) => setThisDate(v)}
    >
      <SelectItem value="days">
        <SelectItemText>Dia</SelectItemText>
      </SelectItem>
      <SelectItem value="weeks">
        <SelectItemText>Semana</SelectItemText>
      </SelectItem>
      <SelectItem value="months">
        <SelectItemText>Mes</SelectItemText>
      </SelectItem>
      <SelectItem value="years">
        <SelectItemText>Ano</SelectItemText>
      </SelectItem>
    </Select>
  );
}
