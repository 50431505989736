import { ReactNode } from "react";
import styled from "styled-components";

interface IconWithBadgeProps {
  children: ReactNode;
  count?: number;
}

export function IconWithBadge({ children, count = 0 }: IconWithBadgeProps) {
  return (
    <Button>
      <>{children}</>
      {count > 0 && (
        <Badge>
          <p>{count}</p>
        </Badge>
      )}
    </Button>
  );
}

const Button = styled.div`
  position: relative;
  background: white;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    height: 21px;
    width: 21px;
    background: transparent;
    color: ${(props) => props.theme.colors.gray700};
  }
`;

const Badge = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.red500};
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  position: absolute;
  top: -12px;
  right: -6px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    font-size: 14px;
  }
`;
