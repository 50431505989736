import styled from "styled-components";

export const ReportShowFilterBar = styled.div`
  padding: 2px 5px;
  border: 0.5px solid ${(props) => props.theme.colors.gray200};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
