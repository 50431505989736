export const useConvertUrlFiltersToValidFilters = () => {
  return (queryParams: URLSearchParams) => {
    const filtersObj = {
      advanced_filters: [],
      tags: [],
      match: {},
      shared_name: "",
    };

    queryParams.forEach((value, key) => {
      if (key.startsWith("sharedName")) {
        filtersObj.shared_name = value;
      } else if (key.startsWith("tagId")) {
        filtersObj.tags.push({ id: Number(value) });
      } else if (key.startsWith("tagSlug")) {
        filtersObj.tags.push({ slug: value });
      } else if (key.startsWith("currency")) {
        let property = key
          .replace("currency", "")
          .replace(/([A-Z])/g, "_$1")
          .toLowerCase();
        if (property.startsWith("_")) property = property.substring(1);
        filtersObj.advanced_filters.push({
          type: "currency",
          property,
          value,
        });
      } else if (key.startsWith("date")) {
        let property = key
          .replace("date", "")
          .replace(/([A-Z])/g, "_$1")
          .toLowerCase();
        if (property.startsWith("_")) property = property.substring(1);
        filtersObj.advanced_filters.push({ type: "date", property, value });
      } else if (key.startsWith("multiselect")) {
        let property = key
          .replace(/(multiselect|Id|Value)/g, "")
          .replace(/([A-Z])/g, "_$1")
          .toLowerCase();
        if (property.startsWith("_")) property = property.substring(1);
        const filter = filtersObj.advanced_filters.find(
          (f) => f.property === property && f.type === "multiselect"
        );
        if (!filter) {
          filtersObj.advanced_filters.push({
            type: "multiselect",
            property,
            value: [],
          });
        }
        const currentFilter = filtersObj.advanced_filters.find(
          (f) => f.property === property && f.type === "multiselect"
        );
        if (key.includes("Id")) {
          currentFilter.value.push({ id: Number(value) });
        } else if (key.includes("Value")) {
          currentFilter.value.push({ value });
        }
      } else if (key.startsWith("select")) {
        let property = key
          .replace(/(select|Id|Value)/g, "")
          .replace(/([A-Z])/g, "_$1")
          .toLowerCase();
        if (property.startsWith("_")) property = property.substring(1);
        let filter = filtersObj.advanced_filters.find(
          (f) => f.property === property && f.type === "select"
        );
        if (!filter) {
          filter = { type: "select", property, value: {} };
          filtersObj.advanced_filters.push(filter);
        }
        if (key.includes("Id")) {
          filter.value.id = Number(value);
        } else if (key.includes("Value")) {
          filter.value.value = value;
        }
      } else if (key.startsWith("match")) {
        let matchKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
        if (matchKey.startsWith("_")) matchKey = matchKey.substring(1);
        filtersObj.match[matchKey] = decodeURIComponent(value);
      }
    });

    return filtersObj;
  };
};
