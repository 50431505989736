import { Flex, Text } from "@gogeo-io/ui-library";

interface TextCellProps {
  value: string;
}

export function TextCell({ value }: TextCellProps) {
  return (
    <Flex>
      {value ? (
        <Text size="body2" css={{ textAlign: "center" }}>
          {value}
        </Text>
      ) : (
        <Text size="body2" css={{ textAlign: "center" }}>
          Não Possui
        </Text>
      )}
    </Flex>
  );
}
