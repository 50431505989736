import {
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";

interface AttendanceListItemNameProps {
  children: string;
}

export function AttendanceListItemName({
  children,
}: AttendanceListItemNameProps) {
  return (
    <Tooltip>
      <TooltipTrigger
        style={{
          background: "transparent",
          whiteSpace: "nowrap",
          width: "100%",
          overflow: "hidden",
          boxSizing: "border-box",
          display: "flex",
        }}
      >
        <Text
          size="body1"
          css={{
            color: "$gray900",
          }}
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {children}
        </Text>
      </TooltipTrigger>
      <TooltipContent aria-label={children}>{children}</TooltipContent>
    </Tooltip>
  );
}
