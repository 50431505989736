import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { BusinessAssociate } from "@/core/models/business-associate.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface State {
  has500error: boolean;
  businessAssociates: BusinessAssociate[];
  selectedBusinessAssociate: BusinessAssociate;
  status: "loading" | "success" | "failed";
}

const initialState = {
  has500error: false,
  businessAssociates: [],
  selectedBusinessAssociate: {},
  status: "loading",
} as State;

export const getBusinessAssociates = createAsyncThunk(
  "businessAssociateS/getBusinessAssociates",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/business-associate");
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface AssociateBusinessAssociateAndCompanyProps {
  partner_id: string;
  body: object;
}

export const associateBusinessAssociateAndCompany = createAsyncThunk(
  "businessAssociateS/associatePartnerAndCompany",
  async (
    { partner_id, body }: AssociateBusinessAssociateAndCompanyProps,
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `/business-associate/associate/${partner_id}`,
        body
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const businessAssociatesSlice = createSlice({
  name: "businessAssociatesSlice",
  initialState,
  reducers: {
    setSelectedBusinessAssociate(
      state,
      action: PayloadAction<BusinessAssociate>
    ) {
      state.selectedBusinessAssociate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusinessAssociates.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getBusinessAssociates.fulfilled, (state, action) => {
      state.status = "success";
      state.businessAssociates = action.payload;
    });
    builder.addCase(
      getBusinessAssociates.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting  the business associates");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      associateBusinessAssociateAndCompany.pending,
      (state, action) => {
        state.status = "loading";
      }
    );
    builder.addCase(
      associateBusinessAssociateAndCompany.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
      }
    );
    builder.addCase(
      associateBusinessAssociateAndCompany.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting on update business associate"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectBusinessAssociates = (state: RootState) =>
  state.businessAssociatesState.businessAssociates;
export const selectSelectedBusinessAssociate = (state: RootState) =>
  state.businessAssociatesState.selectedBusinessAssociate;
export const selectBusinessAssociatesStatus = (state: RootState) =>
  state.businessAssociatesState.status;
export const selectBusinessAssociatesHas500error = (state: RootState) =>
  state.businessAssociatesState.has500error;

export const { setSelectedBusinessAssociate } = businessAssociatesSlice.actions;

export const businessAssociatesReducer = businessAssociatesSlice.reducer;
