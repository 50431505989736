import { IconWithBadge } from "@/components/IconWithBadge";
import { LinkToAnotherTab } from "@/components/LinkToAnotherTab";
import { selectNotifications } from "@/features/notifications/store/notificationsSlice";
import { selectAccount } from "@/features/profile/store/accountSlice";
import { Reminders } from "@/features/reminder";
import { useReminderMixpanel } from "@/features/reminder/hooks/useReminderMixpanel";
import {
  getCountOfRemindersUnreaded,
  selectCountOfRemindersUnreaded,
  selectReminders,
} from "@/features/reminder/store/reminderSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import LogoAppBarSvg from "@assets/logo-app-bar.svg";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTrigger,
  Flex,
  Text,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import { MdAccessAlarm, MdNotificationsNone } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BreadCrumbs } from "./BreadCrumbs";
import { UserMenuWithKeycloak } from "./UserMenuWithKeycloak";

export function Appbar() {
  const [dialogOfRemindersIsOpen, setDialogOfRemindersIsOpen] = useState(false);
  const [countOfNotificationsUnreaded, setCountOfNotificationsUnreaded] =
    useState(0);

  const countOfRemindersUnreaded = useSelector(selectCountOfRemindersUnreaded);
  const reminders = useSelector(selectReminders);
  const account = useSelector(selectAccount);
  const notifications = useSelector(selectNotifications);

  const { createFeedbackModalOpenedEvent } = useReminderMixpanel();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClickOnLogo = () => navigate("/attendance");

  const handleNavigateToNotifications = () => navigate("/notifications");

  useEffect(() => {
    const loadReminders = async () => {
      await dispatch(getCountOfRemindersUnreaded());
    };

    loadReminders();
  }, [reminders]);

  useEffect(() => {
    const countOfUnreaded = notifications.reduce(
      (acc, notification) => acc + (!notification.read ? 1 : 0),
      0
    );
    setCountOfNotificationsUnreaded(countOfUnreaded);
  }, [notifications]);

  return (
    <Container>
      <Flex css={{ gap: "$4", alignItems: "center", cursor: "pointer" }}>
        <Flex onClick={handleClickOnLogo}>
          <img src={LogoAppBarSvg} alt="GoVendas Logo" />
        </Flex>

        {account.name && account.name !== "" && (
          <Flex
            css={{ alignItems: "center", color: "$gray700", gap: "$1" }}
            onClick={handleClickOnLogo}
          >
            <FaHome color="inherit" />
            <Text css={{ color: "inherit" }}>Lubriartes</Text>
          </Flex>
        )}
        <BreadCrumbs />
      </Flex>
      <Flex css={{ gap: "$4", alignItems: "center" }}>
        <Flex css={{ gap: "$3", alignItems: "center" }}>
          <LinkToAnotherTab url="/notifications">
            <Box onClick={handleNavigateToNotifications}>
              <IconWithBadge count={countOfNotificationsUnreaded}>
                <MdNotificationsNone />
              </IconWithBadge>
            </Box>
          </LinkToAnotherTab>

          <Dialog
            open={dialogOfRemindersIsOpen}
            onOpenChange={(e) => setDialogOfRemindersIsOpen(e)}
          >
            <DialogTrigger onClick={() => createFeedbackModalOpenedEvent()}>
              <IconWithBadge count={countOfRemindersUnreaded}>
                <MdAccessAlarm />
              </IconWithBadge>
            </DialogTrigger>
            <DialogContent>
              <Reminders
                onCloseDialog={() => setDialogOfRemindersIsOpen(false)}
              />
            </DialogContent>
          </Dialog>
        </Flex>

        <Flex css={{ height: "30px", width: "2px", background: "$gray200" }} />

        <UserMenuWithKeycloak />
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  height: 4rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray100};
`;
