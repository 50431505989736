import { gvTheme } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface NoPageSelectedProps {
  src: string;
  alt: string;
  title: string;
  children?: React.ReactNode;
}

export function NoPageSelected({
  src,
  alt,
  children,
  title,
}: NoPageSelectedProps) {
  return (
    <Wrapper>
      <img src={src} alt={alt} />
      <Content>
        <h3>{title}</h3>
        {children}
      </Content>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[2]};
  margin-top: 3rem;
  margin-left: 5rem;

  img {
    width: 500px;
    height: 600px;

    @media (max-width: ${gvTheme.mediaSizes.laptop}) {
      width: 400px;
      height: 500px;
    }

    @media (max-width: ${gvTheme.mediaSizes.smallLaptop}) {
      width: 350px;
      height: 450px;
    }

    @media (max-width: ${gvTheme.mediaSizes.tablet}) {
      width: 300px;
      height: 400px;
    }
  }
`;

export const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[2]};

  @media (max-width: ${gvTheme.mediaSizes.smallLaptop}) {
    font-size: 1rem;
  }

  @media (max-width: ${gvTheme.mediaSizes.tablet}) {
    font-size: 0.9rem;
  }

  h3 {
    font-size: 3rem;

    @media (max-width: ${gvTheme.mediaSizes.smallLaptop}) {
      font-size: 2rem;
    }

    @media (max-width: ${gvTheme.mediaSizes.tablet}) {
      font-size: 1.5rem;
    }
  }
`;
