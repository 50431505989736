import { TagCloudFeedbackMapping } from "@/features/attendance/components/feedback/TagCloudFeedbackMapping";
import { Grid } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import {
  selectFeedbackMapping,
  selectFeedbackMappingStatus,
} from "../../store/feedbackMappingSlice";
import { Skeleton } from "./FeedbackMappingSkeleton";

interface FeedbackMappingProps {
  attendanceStatusSelected: string;
  onChange: (...event: any[]) => void;
  value: number;
}

export function FeedbackMapping({
  attendanceStatusSelected,
  onChange,
  value,
}: FeedbackMappingProps) {
  const feedbackMapping = useSelector(selectFeedbackMapping);
  const status = useSelector(selectFeedbackMappingStatus);

  const feedbackMappingFiltered = feedbackMapping.filter((feedback) => {
    const { feedback_id } = feedback;

    if (attendanceStatusSelected === "Vendi") {
      return feedback_id >= 100 && feedback_id < 200;
    } else if (attendanceStatusSelected === "Não Vendi") {
      return feedback_id >= 200;
    } else if (attendanceStatusSelected === "Em andamento") {
      return feedback_id >= 10 && feedback_id < 100;
    } else {
      return feedback;
    }
  });

  const handleClickChangeFeedbackStatus = async (feedback_id: number) => {
    onChange(feedback_id);
  };

  return (
    <Grid
      css={{
        gap: "$2",
        alignItems: "center",
        flexWrap: "wrap",
        overflowY: "auto",
      }}
    >
      {status !== "success" ? (
        <Skeleton />
      ) : (
        <TagCloudFeedbackMapping
          feedbackMapping={feedbackMappingFiltered}
          feedbackSelected={value}
          onFeedbackSelected={(item) => handleClickChangeFeedbackStatus(item)}
        />
      )}
    </Grid>
  );
}
