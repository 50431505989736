import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function ShareOnGovendasSkeletonPage() {
  const timesToRepeat = Array.from(Array(5).keys());

  return (
    <Flex css={{ flexDirection: "column", gap: "$4" }}>
      <RLSSkeleton width={400} height={16} />

      <RLSSkeleton height={32} />

      <Flex css={{ flexDirection: "column", gap: "$5", height: "100%" }}>
        <Flex css={{ alignItems: "center", gap: "$3" }}>
          <RLSSkeleton width={20} height={20} />

          <RLSSkeleton width={166} height={23} />

          <RLSSkeleton width={25} height={25} />
        </Flex>

        <Flex
          css={{
            flexDirection: "column",
            gap: "$3",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {timesToRepeat.map((count) => {
            return (
              <Flex key={count} css={{ alignItems: "center", gap: "$3" }}>
                <RLSSkeleton width={20} height={20} />

                <Flex css={{ flexDirection: "row", gap: "$1" }}>
                  <Flex css={{ position: "relative" }}>
                    <RLSSkeleton width={48} height={48} />
                  </Flex>

                  <Flex
                    css={{ flexDirection: "column", alignItems: "flex-start" }}
                  >
                    <RLSSkeleton width={134} height={23} />

                    <RLSSkeleton width={155} height={14} />
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}
