import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { AttendanceJustification } from "@/core/models/attendance-justification.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useAttendanceJustificationsMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const createAttendanceJustificationEvent = (
    justification: AttendanceJustification
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.ATTENDANCE_JUSTIFICATION_CREATED,
      {
        ...buildProps(),
        "Justificativa de atendimento criada": justification,
      }
    );
  };

  const editAttendanceJustificationEvent = (
    edited_justification_id: number,
    newAttendanceJustification: AttendanceJustification
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.ATTENDANCE_JUSTIFICATION_UPDATED,
      {
        ...buildProps(),
        "ID da justificativa de atendimento criada": edited_justification_id,
        "Nova justificativa de atendimento": newAttendanceJustification,
      }
    );
  };

  const deletedAttendanceJustificationEvent = (
    deleted_justification_id: number,
    deletedJustification: AttendanceJustification
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.ATTENDANCE_JUSTIFICATION_DELETED,
      {
        ...buildProps(),
        "ID da justificativa de atendimento": deleted_justification_id,
        "Justificativa de atendimento deletada": deletedJustification,
      }
    );
  };

  const getAttendanceJustificationsEvent = () => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.ATTENDANCE_JUSTIFICATION_GET,
      {
        ...buildProps(),
      }
    );
  };

  return {
    createAttendanceJustificationEvent,
    editAttendanceJustificationEvent,
    deletedAttendanceJustificationEvent,
    getAttendanceJustificationsEvent,
  };
};
