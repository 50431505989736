import styled from "styled-components";

export const NotificationsItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  position: relative;
  cursor: pointer;
  top: 0;
  right: 0;
  margin-bottom: -10px;
  padding: ${(props) => props.theme.space[1]} ${(props) => props.theme.space[3]};

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  &:hover {
    background: ${(props) => props.theme.colors.gray50};
  }

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 4px;
    height: 80%;
    background: transparent;
    transition: all 150ms ease-out;
  }

  &.active {
    background: ${(props) => props.theme.colors.gray50};

    &::before {
      background: ${(props) => props.theme.colors.primary400};
    }
  }

  transition: all 150ms ease-out;
`;
