import { ListOfTags } from "@/core/models/listOfTags.model";
import { getListById } from "@/features/attendance/store/listOfTagsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { getBusinessAssociateServicesOfList } from "../store/businessAssociateServicesSlice";
import { BusinessAssociateServicesModalContentSkeleton } from "./BusinessAssociateServicesModalContentSkeleton";
import { BusinessAssociateServicesOfListModalContent } from "./BusinessAssociateServicesOfListModalContent";

interface BusinessAssociateServicesOfListModalContentContainerProps {
  list_id: string;
  onCloseDialog: () => void;
}

export function BusinessAssociateServicesOfListModalContentContainer({
  list_id,
  onCloseDialog,
}: BusinessAssociateServicesOfListModalContentContainerProps) {
  const [selectedList, setSelectedList] = useState<ListOfTags>(
    {} as ListOfTags
  );
  const [status, setStatus] = useState("loading");

  const dispatch = useAppDispatch();

  useEffect(() => {
    setStatus("loading");

    const loadBusinessAssociateServices = async () => {
      await dispatch(getBusinessAssociateServicesOfList());
      await dispatch(getListById({ id: list_id })).then(async (res) => {
        setSelectedList(res.payload);
      });
      setStatus("success");
    };

    loadBusinessAssociateServices();
  }, []);

  return (
    <>
      {status === "success" ? (
        <BusinessAssociateServicesOfListModalContent
          onCloseDialog={onCloseDialog}
          selectedList={selectedList}
        />
      ) : (
        <BusinessAssociateServicesModalContentSkeleton />
      )}
    </>
  );
}
