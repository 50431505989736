import { ListOfTags } from "@/core/models/listOfTags.model";
import { User } from "@/core/models/user.model";
import {
  Button,
  Caption,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
  Text,
} from "@gogeo-io/ui-library";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import { useShareListMixpanel } from "../../hooks/useShareListMixpanel";
import { ShareOnGovendas } from "./ShareOnGovendas";
import { ShareOutsideGovendas } from "./ShareOutsideGovendas";

interface ShareListDialogProps {
  shareUrl: string;
  title: string;
  list: ListOfTags;
  shareListDialogIsOpen: boolean;
  selectedUsersToShare: User[];
  onSetDefaultFilters: () => void;
  onSetShareDialogIsOpen: (value: boolean) => void;
  onOpenInfoToSharedListDialog: () => void;
  onSetSelectedUsersToShare: (users: User[]) => void;
}

export function ShareListDialog({
  shareUrl,
  title,
  list,
  shareListDialogIsOpen,
  selectedUsersToShare,
  onSetDefaultFilters,
  onSetShareDialogIsOpen,
  onOpenInfoToSharedListDialog,
  onSetSelectedUsersToShare,
}: ShareListDialogProps) {
  const [showMessage, setShowMessage] = useState(false);
  const [selectedTab, setSelectedTab] = useState("tab1");

  const inputRef = useRef(null);
  const { listCopedEvent } = useShareListMixpanel();

  const handleCopy = () => {
    if (inputRef.current) {
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          listCopedEvent(list, inputRef.current.value);
          setShowMessage(true);
          setTimeout(() => setShowMessage(false), 2000);
        })
        .catch((err) => {
          console.error("Falha ao copiar o texto: ", err);
        });
    }
  };

  return (
    <Dialog
      open={shareListDialogIsOpen}
      onOpenChange={(e) => onSetShareDialogIsOpen(e)}
    >
      <DialogTrigger />
      <DialogContent
        style={{ width: "550px", maxHeight: "90vh" }}
        onClick={(event) => event.stopPropagation()}
      >
        <DialogTitle>Enviar cópia</DialogTitle>

        <DialogClose asChild>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
            onClick={(event) => event.stopPropagation()}
          >
            <MdClose />
          </IconButton>
        </DialogClose>

        <Flex
          css={{
            flexDirection: "column",
            gap: "$2",
            mt: "$2",
            heigth: "100%",
          }}
        >
          <Flex
            css={{
              flexDirection: "column",
              gap: "$6",
              heigth: "100%",
            }}
          >
            <Flex css={{ flexDirection: "column", gap: "0", height: "60px" }}>
              <Heading size="subtitle1" css={{ color: "$gray800" }}>
                Gerencie quem irá receber
              </Heading>
              <Text
                size="body2"
                css={{ color: "$gray500", fontSize: "0.813rem" }}
              >
                Seus filtros estão prontos para serem copiados! Envie para
                alguém por algum app, ou compartilhe dentro do goVendas. Obs: Os
                clientes podem mudar de acordo com quem acessar o filtro, os
                clientes e resultados que você vê, é com base na *sua conta*.
              </Text>
            </Flex>

            {selectedTab === "tab1" && (
              <Flex css={{ flexDirection: "column", gap: "$1" }}>
                <Caption css={{ color: "$gray700" }}>Link para copiar</Caption>

                <AnimatePresence>
                  <UrlToCopyContainer
                    initial={{ scale: 1 }}
                    animate={showMessage ? { scale: [1, 1.1, 1] } : {}}
                    transition={{ duration: 0.6 }}
                  >
                    <input
                      ref={inputRef}
                      type="text"
                      value={shareUrl}
                      readOnly
                      style={{
                        display: "flex",
                        flex: "1",
                        background: "none",
                        flexWrap: "wrap",
                      }}
                    />
                    <Button color="gray" bordered onClick={handleCopy}>
                      {showMessage ? "Texto copiado" : "Copiar"}
                    </Button>
                  </UrlToCopyContainer>
                </AnimatePresence>
              </Flex>
            )}

            <TabsRoot
              defaultValue="tab1"
              css={{
                width: "100%",
                height: "100%",
                boxShadow: "none",
              }}
              onValueChange={(value) => {
                onSetDefaultFilters();
                setSelectedTab(value);
              }}
            >
              <TabsList
                aria-label="Manage your account"
                defaultValue="tab1"
                css={{ borderBottom: "1px solid $gray200" }}
              >
                <TabsTrigger value="tab1">Compartilhar com apps</TabsTrigger>
                <TabsTrigger value="tab2">Compartilhar no goVendas</TabsTrigger>
              </TabsList>
              <TabsContent value="tab1" css={{ padding: "$5 0 0 $2" }}>
                <ShareOutsideGovendas shareUrl={shareUrl} title={title} />
              </TabsContent>
              <TabsContent
                value="tab2"
                css={{ padding: "$5 0 0 $2", height: "100%" }}
              >
                <ShareOnGovendas
                  selectedUsersToShare={selectedUsersToShare}
                  onCloseShareListDialog={() => onSetShareDialogIsOpen(false)}
                  onSetSelectedUsersToShare={onSetSelectedUsersToShare}
                  onOpenAddInfoToSharedListDialog={onOpenInfoToSharedListDialog}
                />
              </TabsContent>
            </TabsRoot>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}

export const UrlToCopyContainer = styled(motion.div)`
  display: flex;
  padding: ${(props) => props.theme.space[2]} ${(props) => props.theme.space[2]};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.gray50};
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.space[2]};
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.theme.colors.gray100};
`;
