import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { History } from "@/core/models/history.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";
import { Params } from "@sentry/react/types/types";

export const useHistoryMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const historyLoadedEvent = (history: History, filters: AdvancedFilter[]) => {
    MixpanelTracking.getInstance().track(TrackingEvents.HISTORY_LOADED, {
      ...buildProps("client"),
      "Histórico de atendimento": history,
      "Filtros aplicados": filters,
    });
  };

  const historyOfParamsLoadedEvent = (history: Params[]) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.HISTORY_OF_PARAMS_LOADED,
      {
        ...buildProps("client"),
        "Histórico de parâmetros de atendimento": history,
      }
    );
  };

  return {
    historyLoadedEvent,
    historyOfParamsLoadedEvent,
  };
};
