import styled from "styled-components";

export const TagsListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 4px;
  background: ${(props) => props.theme.colors.gray50};
  cursor: pointer;
  transition: all 150ms ease-out;

  &.active {
    background: ${(props) => props.theme.colors.green50};

    &::before {
      background: ${(props) => props.theme.colors.primary400};
    }
  }
`;
