import { Params } from "@core/models/params.model";
import { TimelineListOfParams } from "@features/history/components/TimelineListOfParams";
import styled from "styled-components";

interface TimelineOfParamsProps {
  params: Params[];
}

export const TimelineOfParams = ({ params }: TimelineOfParamsProps) => {
  return (
    <Wrapper>
      <TimelineListOfParams params={params} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${(props) => props.theme.space[4]};
  position: relative;
  width: fit-content;
  margin: 0 auto;
`;
