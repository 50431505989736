import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";

export const useBreadcrumbsForSecurityPage = () => {
  const dispatch = useAppDispatch();

  const showBreadcrumbs = async () => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Configurações", url: "/profile/user-profile/info" },
      { item: "Segurança", url: "" },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return {
    showBreadcrumbs,
  };
};
