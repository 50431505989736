import { FileMetadata } from "@/core/models/file.model";
import UI from "../../ui";
import { TimelineAttachments } from "../TimelineAttachments";

interface TimelineHeaderInfoOrderFilesProps {
  files: FileMetadata[];
}

export function TimelineHeaderInfoOrderFiles({
  files,
}: TimelineHeaderInfoOrderFilesProps) {
  return (
    <UI.TimelineAttendanceItemBox>
      <TimelineAttachments files={files} />
    </UI.TimelineAttendanceItemBox>
  );
}
