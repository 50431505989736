import { ListDivider } from "@/components/ListDivider";
import { FormInputField } from "@/components/form/FormInputField";
import { FormLabel } from "@/components/form/FormLabel";
import { FormRadioBooleanField } from "@/components/form/FormRadioBooleanField";
import { DaysWithRuptureFormField } from "@/features/params/components/DaysWithRuptureFormField";
import useAppDispatch from "@/hooks/useAppDispatch";
import { AccountInfoPageSkeleton } from "@features/profile/components/Skeleton/AccountInfoPageSkeleton";
import {
  Button,
  Flex,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import G_UI from "@ui/index";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { UserProfileHeaderToPage } from "../components/UserProfileHeaderToPage";
import {
  ParamsOfAccountFormData,
  paramsOfAccountFormSchema,
} from "../hooks/paramsOfAccountFormSchema";
import { useAccountInfoBreadcrumbs } from "../hooks/useAccountInfoBreadcrumbs";
import { useProfileMixpanel } from "../hooks/useProfileMixpanel";
import {
  selectAccount,
  selectAccountStatus,
  updateAccountParams,
} from "../store/accountSlice";

export function ParamsOfAccountFormPage() {
  const [isLoading, setIsLoading] = useState(false);

  const account = useSelector(selectAccount);
  const accountStatus = useSelector(selectAccountStatus);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { paramsOfAccountBreadcrumbs } = useAccountInfoBreadcrumbs();
  const { userAccountProfileParamsEvent, userAccountProfileChangeParamsEvent } =
    useProfileMixpanel();

  const { handleSubmit, control, reset, setValue } =
    useForm<ParamsOfAccountFormData>({
      mode: "onBlur",
      resolver: yupResolver(paramsOfAccountFormSchema),
      defaultValues: {
        inactive_days: account.inactive_days,
        meta_parameters: {
          active_freq: account.meta_parameters?.active_freq,
          inactive_freq: account.meta_parameters?.inactive_freq,
          skip_defaulting: account.meta_parameters?.skip_defaulting,
          rupture_params: {
            freq: account.meta_parameters?.rupture_params?.freq,
            mon_pref: account.meta_parameters?.rupture_params?.mon_pref,
            tue_pref: account.meta_parameters?.rupture_params?.tue_pref,
            wed_pref: account.meta_parameters?.rupture_params?.wed_pref,
            thu_pref: account.meta_parameters?.rupture_params?.thu_pref,
            fri_pref: account.meta_parameters?.rupture_params?.fri_pref,
            sat_pref: account.meta_parameters?.rupture_params?.sat_pref,
            sun_pref: account.meta_parameters?.rupture_params?.sun_pref,
          },
        },
      },
    });

  const onSubmit: SubmitHandler<ParamsOfAccountFormData> = async (
    data: ParamsOfAccountFormData
  ): Promise<void> => {
    setIsLoading(true);

    await dispatch(updateAccountParams({ params: data })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        userAccountProfileChangeParamsEvent(data);
        toast.success("Parâmetros alterados com successo");
      }
    });

    setIsLoading(false);
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível alterar os parâmetros da conta");
    }
  };

  const handleReset = () => {
    reset();
    navigate("/profile/account/info");
  };

  useEffect(() => {
    if (accountStatus === "success") {
      paramsOfAccountBreadcrumbs();

      userAccountProfileParamsEvent({
        inactive_days: account.inactive_days,
        skip_scheduling: account.skip_scheduling,
        ...account.meta_parameters,
      });

      setValue("inactive_days", account.inactive_days);
      setValue(
        "meta_parameters.active_freq",
        account.meta_parameters?.active_freq
      );
      setValue(
        "meta_parameters.inactive_freq",
        account.meta_parameters?.inactive_freq
      );
      setValue(
        "meta_parameters.skip_defaulting",
        account.meta_parameters?.skip_defaulting
      );
      setValue(
        "meta_parameters.rupture_params",
        account.meta_parameters?.rupture_params
      );
      setValue(
        "meta_parameters.rupture_params.freq",
        account.meta_parameters?.rupture_params?.freq
      );
      setValue(
        "meta_parameters.rupture_params.mon_pref",
        account.meta_parameters?.rupture_params?.mon_pref
      );
      setValue(
        "meta_parameters.rupture_params.tue_pref",
        account.meta_parameters?.rupture_params?.tue_pref
      );
      setValue(
        "meta_parameters.rupture_params.wed_pref",
        account.meta_parameters?.rupture_params?.wed_pref
      );
      setValue(
        "meta_parameters.rupture_params.thu_pref",
        account.meta_parameters?.rupture_params?.thu_pref
      );
      setValue(
        "meta_parameters.rupture_params.fri_pref",
        account.meta_parameters?.rupture_params?.fri_pref
      );
      setValue(
        "meta_parameters.rupture_params.sat_pref",
        account.meta_parameters?.rupture_params?.sat_pref
      );
      setValue(
        "meta_parameters.rupture_params.sun_pref",
        account.meta_parameters?.rupture_params?.sun_pref
      );
    }
  }, [accountStatus]);

  return (
    <Form noValidate>
      <UserProfileHeaderToPage
        title="Parâmetros de atendimento da conta"
        description="Aqui você consegue alterar os parâmetros de atendimento de toda a conta"
      />
      <ListDivider />
      <>
        {accountStatus === "success" ? (
          <>
            <FormContainer>
              <Flex css={{ gap: "$4" }}>
                <Controller
                  control={control}
                  name="inactive_days"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <FormLabel
                          label="Dias sem compras para inativos"
                          isRequired
                        />
                        <FormInputField
                          placeholder="Ex. 90"
                          error={error}
                          field={field}
                          type="number"
                        />
                      </Flex>
                    );
                  }}
                />
              </Flex>

              <Flex css={{ flexDirection: "column", gap: "$1" }}>
                <Flex
                  css={{
                    alignItems: "center",
                    gap: "$2",
                    color: "$primary500",
                  }}
                >
                  <Text>Valores padrão para clientes não parametrizados</Text>
                  <Tooltip>
                    <TooltipTrigger
                      style={{ background: "transparent", color: "inherit" }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <IoMdInformationCircleOutline color="inherit" size={24} />
                    </TooltipTrigger>
                    <TooltipContent aria-label="Os valores só serão aplicados caso o cliente não esteja parametrizado. Caso contrário, os valores a seguir serão ignorados">
                      Os valores só serão aplicados caso o cliente não esteja
                      parametrizado. Caso contrário, os valores a seguir serão
                      ignorados.
                    </TooltipContent>
                  </Tooltip>
                </Flex>

                <Flex css={{ gap: "$4" }}>
                  <Controller
                    control={control}
                    name="meta_parameters.active_freq"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <Flex css={{ flexDirection: "column", gap: "$1" }}>
                          <FormLabel label="Frequência de clientes ativos" />
                          <FormInputField
                            placeholder="Ex. 7"
                            error={error}
                            field={field}
                            type="number"
                          />
                        </Flex>
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="meta_parameters.inactive_freq"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <Flex css={{ flexDirection: "column", gap: "$1" }}>
                          <FormLabel label="Frequência de clientes inativos" />
                          <FormInputField
                            placeholder="Ex. 14"
                            error={error}
                            field={field}
                            type="number"
                          />
                        </Flex>
                      );
                    }}
                  />
                </Flex>
              </Flex>

              <Controller
                control={control}
                name="meta_parameters.skip_defaulting"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Flex css={{ flexDirection: "column", gap: "$1" }}>
                      <FormLabel label="Agendar inadimplentes" />
                      <FormRadioBooleanField
                        value={value}
                        onChange={onChange}
                        options={[
                          { title: "Sim", value: false },
                          { title: "Não", value: true },
                        ]}
                      />
                      <G_UI.ErrorMessage>
                        {!!error && error.message}
                      </G_UI.ErrorMessage>
                    </Flex>
                  );
                }}
              />

              <Flex css={{ flexDirection: "column", gap: "$1" }}>
                <Text>Valores padrão para clientes em ruptura</Text>

                <Controller
                  control={control}
                  name="meta_parameters.rupture_params.freq"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <FormLabel label="Frequência de clientes em ruptura" />
                        <FormInputField
                          placeholder="Ex. 14"
                          error={error}
                          field={field}
                          type="number"
                        />
                      </Flex>
                    );
                  }}
                />

                <Controller
                  control={control}
                  name="meta_parameters.rupture_params"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <FormLabel label="Dias com agenda de ruptura" />
                        <DaysWithRuptureFormField
                          value={value}
                          onChange={onChange}
                        />
                      </Flex>
                    );
                  }}
                />
              </Flex>
            </FormContainer>

            <Flex
              css={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                gap: "$2",
                mt: "$4",
              }}
            >
              <Button bordered onClick={handleReset}>
                Cancelar
              </Button>
              <Button
                isLoading={isLoading}
                onClick={() => handleSubmit(onSubmit, onError)()}
              >
                Salvar
              </Button>
            </Flex>
          </>
        ) : (
          <AccountInfoPageSkeleton />
        )}
      </>
    </Form>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  width: 100%;
  height: 100%;
`;
