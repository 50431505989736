import { EmptyContent } from "@/components/EmptyContent";
import { ErrorContent } from "@/components/ErrorContent";
import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import {
  BusinessGoal,
  BusinessGoalCategorie,
} from "@/core/models/business-goal.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BusinessGoalTable } from "../BusinessGoalTable";
import { CreateBusinessGoal } from "../components/CreateBusinessGoal";
import { UserProfileHeaderToPage } from "../components/UserProfileHeaderToPage";
import { useBusinessGoalsColumns } from "../hooks/useBusinessGoalsColumns";
import { useBusinessGoalsMixpanel } from "../hooks/useBusinessGoalsMixpanel";
import {
  addBusinessGoalOnState,
  createBusinessGoal,
  deleteBusinessGoal,
  getBusinessGoals,
  removeBusinessGoalFromState,
  selectBusinessGoals,
  selectBusinessGoalsHasError500,
  selectBusinessGoalsStatus,
  updateBusinessGoal,
  updateBusinessGoalFromState,
} from "../store/businessGoalsSlice";

export function BusinessGoalsPage() {
  const businessGoals = useSelector(selectBusinessGoals);
  const businessGoalsStatus = useSelector(selectBusinessGoalsStatus);
  const businessGoalsHasError500 = useSelector(selectBusinessGoalsHasError500);

  const dispatch = useAppDispatch();

  const {
    createBusinessGoalEvent,
    editBusinessGoalEvent,
    deletedBusinessGoalEvent,
    getBusinessGoalsEvent,
  } = useBusinessGoalsMixpanel();

  useEffect(() => {
    async function loadBusinessGoals() {
      await dispatch(getBusinessGoals()).then(() => getBusinessGoalsEvent());
    }

    loadBusinessGoals();
  }, []);

  const handleDeleteBusinessGoal = async (id: number) => {
    await dispatch(deleteBusinessGoal({ id })).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const businessGoalDeleted = res.payload as BusinessGoal;
        await dispatch(removeBusinessGoalFromState(businessGoalDeleted.id));
        deletedBusinessGoalEvent(id, businessGoalDeleted);
      }
    });
  };

  const handleEditBusinessGoalCategories = async (
    id: number,
    categories: BusinessGoalCategorie[]
  ) => {
    await dispatch(updateBusinessGoal({ id, categories })).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const editedBusinessGoal = res.payload as BusinessGoal;
        await dispatch(updateBusinessGoalFromState(editedBusinessGoal));
        editBusinessGoalEvent(id, editedBusinessGoal);
      }
    });
  };

  const handleEditBusinessGoal = async (
    id: number,
    name: string,
    color: string,
    categories: BusinessGoalCategorie[]
  ) => {
    await dispatch(updateBusinessGoal({ id, name, color, categories })).then(
      async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const editedBusinessGoal = res.payload as BusinessGoal;
          await dispatch(updateBusinessGoalFromState(editedBusinessGoal));
          editBusinessGoalEvent(id, editedBusinessGoal);
        }
      }
    );
  };

  const handleCreateBusinessGoal = async (
    name: string,
    color: string,
    categories: BusinessGoalCategorie[]
  ) => {
    await dispatch(createBusinessGoal({ name, color, categories })).then(
      async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const createdBusinessGoal = res.payload as BusinessGoal;
          await dispatch(addBusinessGoalOnState(createdBusinessGoal));
          createBusinessGoalEvent(createdBusinessGoal);
        }
      }
    );
  };

  const businessGoalsColumns = useBusinessGoalsColumns(
    handleDeleteBusinessGoal,
    handleEditBusinessGoalCategories,
    handleEditBusinessGoal
  );

  return (
    <>
      <Flex css={{ flexDirection: "column", gap: "0.1rem" }}>
        <UserProfileHeaderToPage
          title="Objetivos de negócio"
          description="Os objetivos de negócio vieram pra facilitar sua vida ao trabalhar com atenidentos."
        />
      </Flex>

      <CreateBusinessGoal onCreateBusinessGoal={handleCreateBusinessGoal} />

      {businessGoalsHasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos buscar seus objetivos de negócio, tente novamente mais
          tarde ou atualize a página
        </ErrorContent>
      ) : (
        <>
          {businessGoalsStatus === "success" ? (
            <>
              {!isEmpty(businessGoals) ? (
                <BusinessGoalTable
                  columns={businessGoalsColumns}
                  data={businessGoals}
                />
              ) : (
                <EmptyContent size="medium">
                  Você não mensagens objetivos de negócio
                </EmptyContent>
              )}
            </>
          ) : (
            <ClientsTableSkeletonPage />
          )}
        </>
      )}
    </>
  );
}
