import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { notificationsMock } from "@/core/mocks/notificationsMock";
import { Notification } from "@/core/models/notification.model";
import { RootState } from "@/core/store/store";
import { sleep } from "@/utils/sleep";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface NotificationState {
  notifications: Notification[];
  selected_notification: Notification;
  notifications_types: { id: string; value: string }[];
  notifications_selected_type: string;
  notifications_query: string;
  notification_status: "loading" | "success" | "failed";
  notification_types_status: "loading" | "success" | "failed";
}

const initialState = {
  notifications: [],
  selected_notification: {},
  notifications_types: [],
  notifications_selected_type: "all",
  notifications_query: "",
  notification_status: "loading",
  notification_types_status: "loading",
} as NotificationState;

interface GetNotificationsWithTypesProps {
  type: string;
  query: string;
}

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async ({ type, query }: GetNotificationsWithTypesProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return notificationsMock;
    } else {
      try {
        const response = await axios.get(
          `/notifications?type=${type}&titleSearch=${query}`
        );
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const getNotificationTypes = createAsyncThunk(
  "notifications/getNotificationTypes",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      return [];
    } else {
      try {
        const response = await axios.get("/notifications/type");
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface UpdateNotificationProps {
  id: number;
  isRead: boolean;
}

export const updateNotification = createAsyncThunk(
  "reminotificationsnder/updateNotification",
  async ({ id, isRead }: UpdateNotificationProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.put(
          `/notifications/${id}?isRead=${isRead}`
        );
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const markAllNotificationsToRead = createAsyncThunk(
  "reminotificationsnder/markAllNotificationsToRead",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.put(`/notifications/read`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    readNotification(state, action: PayloadAction<number>) {
      [...state.notifications].filter((notification) =>
        notification.id === action.payload
          ? (notification.read = true)
          : notification
      );
    },
    updateNotificationSelectedType(state, action: PayloadAction<string>) {
      state.notifications_selected_type = action.payload;
    },
    updateNotificationsQuery(state, action: PayloadAction<string>) {
      state.notifications_query = action.payload;
    },
    setSelectedNotification(state, action: PayloadAction<Notification>) {
      state.selected_notification = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state, action) => {
      state.notification_status = "loading";
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notification_status = "success";
      state.notifications = action.payload;
    });
    builder.addCase(
      getNotifications.rejected,
      (state, action: PayloadAction<any>) => {
        state.notification_status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the notifications");
        });
        Sentry.captureException(action.payload);
        toast.error(action.payload.errorMessage);
      }
    );

    builder.addCase(updateNotification.pending, (state, action) => {
      state.notification_status = "loading";
    });
    builder.addCase(updateNotification.fulfilled, (state, action) => {
      state.notification_status = "success";
    });
    builder.addCase(
      updateNotification.rejected,
      (state, action: PayloadAction<any>) => {
        state.notification_status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating the notification status");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );

    builder.addCase(markAllNotificationsToRead.pending, (state, action) => {
      state.notification_status = "loading";
    });
    builder.addCase(markAllNotificationsToRead.fulfilled, (state, action) => {
      state.notification_status = "success";
    });
    builder.addCase(
      markAllNotificationsToRead.rejected,
      (state, action: PayloadAction<any>) => {
        state.notification_status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error marking all notifications to readed");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );

    builder.addCase(getNotificationTypes.pending, (state, action) => {
      state.notification_types_status = "loading";
    });
    builder.addCase(getNotificationTypes.fulfilled, (state, action) => {
      state.notification_types_status = "success";
      state.notifications_types = action.payload;
    });
    builder.addCase(
      getNotificationTypes.rejected,
      (state, action: PayloadAction<any>) => {
        state.notification_types_status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the notification types");
        });
        Sentry.captureException(action.payload);
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectNotifications = (state: RootState) =>
  state.notificationsState.notifications;
export const selectSelectedNotification = (state: RootState) =>
  state.notificationsState.selected_notification;
export const selectNotificationsStatus = (state: RootState) =>
  state.notificationsState.notification_status;
export const selectNotificationTypes = (state: RootState) =>
  state.notificationsState.notifications_types;
export const selectNotificationTypesStatus = (state: RootState) =>
  state.notificationsState.notification_types_status;
export const selectNotificationsSelectedType = (state: RootState) =>
  state.notificationsState.notifications_selected_type;
export const selectNotificationsQuery = (state: RootState) =>
  state.notificationsState.notifications_query;
export const selectCountOfNotificationsNotReaded = (state: RootState) =>
  state.notificationsState.notifications.filter(
    (notification) => !notification.read
  ).length;

export const {
  readNotification,
  updateNotificationSelectedType,
  updateNotificationsQuery,
  setSelectedNotification,
} = notificationSlice.actions;

export const notificationsReducer = notificationSlice.reducer;
