import { ListDivider } from "@/components/ListDivider";
import { MatchTypes, SearchFilters } from "@/core/models/listOfTags.model";
import { selectMatchTypes } from "@/features/clientInfo/store/matchTypesSlice";
import { useClientsMixpanel } from "@/features/clients/hooks/useClientsMixpanel";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useByPlaceholder } from "@/hooks/useByPlaceholder";
import {
  Caption,
  Flex,
  Input,
  Select,
  SelectItem,
  SelectItemText,
} from "@gogeo-io/ui-library";
import { Search } from "@mui/icons-material";
import G_UI from "@ui/index";
import { debounce, isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ClientsTypeOfView } from "../pages/ClientsListPage";
import { selectOriginalUrlFilters } from "../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import { selectSelectedList } from "../store/listSelectedSlice";
import {
  selectSelectedSearchFilters,
  setSelectedSearchFilters,
} from "../store/selectedSearchFiltersSlice";
import { SelectAttrOptsPopover } from "./SelectAttrOptsPopover";
import { SelectClientsTypeOfView } from "./SelectClientsTypeOfView";
import { SelectFunnel } from "./SelectFunnel";
import { SelectIndicators } from "./SelectIndicators";
import { SelectedFilters } from "./SelectedFilters";

interface SearchClientsOnClientsListPageProps {
  clientsTypeOfView: ClientsTypeOfView;
  onClientsTypeOfViewChange: (option: ClientsTypeOfView) => void;
}

export function SearchClientsOnClientsListPage({
  clientsTypeOfView,
  onClientsTypeOfViewChange,
}: SearchClientsOnClientsListPageProps) {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const selectedSearchFilters = useSelector(selectSelectedSearchFilters);
  const originalUrlFilters = useSelector(selectOriginalUrlFilters);
  const selectedList = useSelector(selectSelectedList);
  const [filterOptionSelected, setFilterOptionSelected] = useState<MatchTypes>(
    selectedSearchFilters.match_type
  );

  const [searchTerm, setSearchTerm] = useState(
    selectedSearchFilters.match_value
  );

  const match_types = useSelector(selectMatchTypes);

  const { buildPlaceholder } = useByPlaceholder();
  const { searchClientEvent } = useClientsMixpanel();
  const dispatch = useAppDispatch();

  const handleSelectFilterOptionChange = async (value: MatchTypes) => {
    await dispatch(emitEventToChangeClients(false));
    setFilterOptionSelected(value);

    const searchFilters: SearchFilters = {
      match_type: value,
      match_value: searchTerm,
    };

    if (searchTerm !== "") {
      await dispatch(setSelectedSearchFilters(searchFilters));
      await dispatch(emitEventToChangeClients(true));
    }
  };

  const errorsAreNotUndefined = () => {
    return (
      originalUrlFilters.errors.advanced_filters &&
      originalUrlFilters.errors.tags
    );
  };

  const handleInputChange = async (value: string) => {
    await dispatch(emitEventToChangeClients(false));
    setSearchTerm(value);

    const searchFilters: SearchFilters = {
      match_type: filterOptionSelected,
      match_value: value,
    };

    await dispatch(setSelectedSearchFilters(searchFilters));
    await dispatch(emitEventToChangeClients(true));

    searchClientEvent(value, filterOptionSelected);
  };

  useEffect(() => {
    searchInputRef.current.value = selectedSearchFilters.match_value;
    setSearchTerm(selectedSearchFilters.match_value);
  }, [selectedSearchFilters]);

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <Flex css={{ flexDirection: "column" }}>
        <Caption wider>Filtros</Caption>
        <Flex
          css={{
            padding: "$2 $2",
            borderRadius: "4px",
            background: "$gray50",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SelectedFilters />
        </Flex>
        {isEmpty(selectedList) && (
          <>
            {errorsAreNotUndefined() &&
              originalUrlFilters.errors.advanced_filters.length +
                originalUrlFilters.errors.tags.length >
                0 && (
                <G_UI.ErrorMessage>
                  {`Ocorreu erro em ${
                    originalUrlFilters.errors.advanced_filters.length +
                    originalUrlFilters.errors.tags.length
                  } filtro(s). Corriga-o(s)ou o(s) remova para visualizar a lista de clientes`}
                </G_UI.ErrorMessage>
              )}
          </>
        )}
      </Flex>

      <ListDivider />

      <Flex
        css={{
          alignItems: "center",
          justifyContent: "space-between",
          gap: "$2",
        }}
      >
        <Flex
          css={{
            gap: "$2",
            alignItems: "center",
            overflow: "hidden",
            justifyContent: "flex-start",
            paddingLeft: "$1",
          }}
        >
          <Input
            ref={searchInputRef}
            preffix={<Search fontSize="inherit" />}
            placeholder={buildPlaceholder(filterOptionSelected)}
            isFullWidth
            style={{
              paddingLeft: "0px",
              paddingRight: "$1",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "calc(100% - 150px)",
            }}
            defaultValue={searchTerm}
            onChange={(e) => debounceOnChangeInput(e.target.value)}
          />
          <Flex
            style={{
              flexDirection: "column",
              width: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Select
              defaultValue={filterOptionSelected}
              ariaLabel="Select filter"
              onValueChange={(e) =>
                handleSelectFilterOptionChange(e as MatchTypes)
              }
            >
              {match_types.map((match) => {
                return (
                  <SelectItem key={match} value={match}>
                    <SelectItemText>{match}</SelectItemText>
                  </SelectItem>
                );
              })}
            </Select>
          </Flex>
        </Flex>

        <Flex
          css={{
            gap: "$2",
            alignItems: "center",
            overflow: "hidden",
            justifyContent: "flex-end",
          }}
        >
          <SelectClientsTypeOfView
            clientsTypeOfView={clientsTypeOfView}
            onClientsTypeOfViewChange={onClientsTypeOfViewChange}
          />

          <Flex style={{ width: "220px", flexDirection: "column" }}>
            {clientsTypeOfView === "list" && <SelectAttrOptsPopover />}
            {clientsTypeOfView === "kanban" && <SelectFunnel />}
            {clientsTypeOfView === "reports" && <SelectIndicators />}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
