import G_UI from "@ui/index";
import RLSSkeleton from "react-loading-skeleton";

export function PreDefinedMessagesSkeletonRow() {
  return (
    <>
      <G_UI.Td>
        <RLSSkeleton width={110} height={32} borderRadius={4} />
      </G_UI.Td>
      <G_UI.Td>
        <RLSSkeleton width={110} height={32} borderRadius={4} />
      </G_UI.Td>
      <G_UI.Td>
        <RLSSkeleton width={515} height={45} borderRadius={4} />
      </G_UI.Td>
    </>
  );
}
