export function removeUnwantedErrorsFromConsole() {
  const auxConsole = console.error;

  console.error = function filterWarnings(...messages: string[]) {
    const supressedWarnings = [
      { error: "Warning: validateDOMNesting(...):", keyword: "<button>" },
      { error: "Warning: validateDOMNesting(...):", keyword: "<p>" },
      {
        error: "Warning: Unknown event handler property",
        keyword: "onCheckedChange",
      },
    ];

    if (
      supressedWarnings.filter(
        (warn) =>
          messages.filter((msg) => msg.includes(warn.error)).length > 0 &&
          messages.filter((msg) => msg.includes(warn.keyword)).length > 0
      ).length === 0
    ) {
      auxConsole.apply(console, messages); // remove supressed warning from console
    }
  };
}
