import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, DialogClose, Flex } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  selectSelectedAdvancedFilters,
  selectSelectedTagsOnClientFilters,
} from "@/features/attendance/store/clientAdvancedFiltersSlice";
import { useParamsMixpanel } from "../../hooks/useParamsMixpanel";
import {
  removeAllClientToChangeParams,
  selectAllClientsSelectedToChangeParams,
  selectClientSelectedToChangeParams,
  selectClientsToChangeParams,
} from "../../store/clientSelectedToChangeParamsSlice";
import {
  changeParams,
  selectParams,
  selectSelectedSalesmanToChangeParams,
  updateParams,
} from "../../store/paramsSlice";

export interface ChangeParamsFormActionsProps {
  onUpdateParams: () => void;
}

export function ChangeParamsFormActions({
  onUpdateParams,
}: ChangeParamsFormActionsProps) {
  const [isLoading, setIsLoading] = useState(false);

  const selectedSalesmanToChangeParams = useSelector(
    selectSelectedSalesmanToChangeParams
  );
  const paramsToUpdate = useSelector(selectParams);
  const selectedClientsToChangeParams = useSelector(
    selectClientSelectedToChangeParams
  );
  const allClientsSelectedToChangeParams = useSelector(
    selectAllClientsSelectedToChangeParams
  );
  const selectedAdvancedFilters = useSelector(selectSelectedAdvancedFilters);
  const selectedTagsOnClientFilters = useSelector(
    selectSelectedTagsOnClientFilters
  );

  const dispatch = useAppDispatch();
  const { changedParamsEvent } = useParamsMixpanel();

  const handleCreateParams = async () => {
    if (
      isEmpty(selectedClientsToChangeParams) &&
      !allClientsSelectedToChangeParams
    ) {
      toast.error("Selecione primeiro um cliente para alterar parâmetros");
    }
    const clientsMapped = selectedClientsToChangeParams.map(
      (client) => client.client.id
    );

    const body = {
      user_id: selectedSalesmanToChangeParams.id,
      params: paramsToUpdate,
      ...(clientsMapped.length > 0
        ? {
            client_ids: clientsMapped,
          }
        : {
            filters: {
              advanced_filters: [...selectedAdvancedFilters],
              tags: [...selectedTagsOnClientFilters],
            },
          }),
    };

    if (isEmpty(paramsToUpdate.observations)) {
      toast.error("O motivo de alteração é um campo obrigatório");
      return;
    } else {
      setIsLoading(true);
      await dispatch(updateParams(body)).then(async (obj) => {
        if (obj.meta.requestStatus === "fulfilled") {
          toast.success("Parâmetros alterados com sucesso");
          changedParamsEvent(paramsToUpdate, clientsMapped, {
            advanced_filters: [...selectedAdvancedFilters],
            tags: [...selectedTagsOnClientFilters],
          });
          await dispatch(removeAllClientToChangeParams());
          await dispatch(changeParams({ params: "cleanAllFilters" }));
          onUpdateParams();
        }
      });
      setIsLoading(false);
    }
  };

  const handleCancelChanges = async () => {
    await dispatch(removeAllClientToChangeParams());
    await dispatch(selectClientsToChangeParams(selectedClientsToChangeParams));
    await dispatch(changeParams({ params: "cleanAllFilters" }));
    onUpdateParams();
  };

  const notHaveClientsSelected: boolean =
    selectedClientsToChangeParams.length <= 0 &&
    !allClientsSelectedToChangeParams;

  return (
    <Flex css={{ alignItems: "center", gap: "1rem" }}>
      <DialogClose asChild>
        <Button
          fullWidth
          onClick={handleCancelChanges}
          color="primary"
          bordered
        >
          Cancelar
        </Button>
      </DialogClose>
      <DialogClose asChild>
        <Button
          fullWidth
          isLoading={isLoading}
          onClick={handleCreateParams}
          disabled={notHaveClientsSelected}
        >
          Aplicar
        </Button>
      </DialogClose>
    </Flex>
  );
}
