import { Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";
import { useState } from "react";

interface UntilProps {
  valueSelected: string;
  setValueSelected: (value: string) => void;
}

export function WeekRestrictionUntil({
  valueSelected,
  setValueSelected,
}: UntilProps) {
  const [weekSelected, setWeekSelected] = useState(valueSelected);

  const handleValueChange = (value: string) => {
    setWeekSelected(value);
    setValueSelected(value);
  };

  return (
    <>
      <Select
        defaultValue={weekSelected}
        value={weekSelected === "indeterminate" ? "S4" : weekSelected}
        ariaLabel="Until"
        onValueChange={handleValueChange}
      >
        <SelectItem value="S0">
          <SelectItemText>S0</SelectItemText>
        </SelectItem>
        <SelectItem value="S1">
          <SelectItemText>S1</SelectItemText>
        </SelectItem>
        <SelectItem value="S2">
          <SelectItemText>S2</SelectItemText>
        </SelectItem>
        <SelectItem value="S3">
          <SelectItemText>S3</SelectItemText>
        </SelectItem>
        <SelectItem value="S4">
          <SelectItemText>S4</SelectItemText>
        </SelectItem>
      </Select>
    </>
  );
}
