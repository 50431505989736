import RLSSkeleton from "react-loading-skeleton";
import styled from "styled-components";

export function ProfileSkeleton() {
  return (
    <Wrapper>
      <Row>
        <RLSSkeleton width={42} height={42} borderRadius={4} />
        <Column>
          <RLSSkeleton height={16} />
          <RLSSkeleton width={30} height={16} />
        </Column>
      </Row>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  gap: ${(props) => props.theme.space[2]};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[2]};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.theme.space[2]};
`;
