import { ListDivider } from "@/components/ListDivider";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
  Input,
  Select,
  SelectItem,
  SelectItemText,
} from "@gogeo-io/ui-library";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CreateAttendanceJustificationProps } from "../pages/AttendanceJustificationsPage";
import { selectAttendanceJustificationsActions } from "../store/attendanceJustificationsActionsSlice";
import {
  selectBusinessGoals,
  selectCategoriesByBusinessGoals,
} from "../store/businessGoalsSlice";
import { FloatButtonToCreate } from "./FloatButtonToCreate";

interface ICreateAttendanceJustificationProps {
  businessGoalSelected: string;
  categorySelected: string;
  actionSelected: string;
  onActionSelected: (text: string) => void;
  onBusinessGoalSelected: (text: string) => void;
  onCategorySelected: (text: string) => void;
  onCreateAttendanceJustification: (
    data: CreateAttendanceJustificationProps
  ) => Promise<void>;
}

export function CreateAttendanceJustification({
  businessGoalSelected,
  categorySelected,
  actionSelected,
  onCreateAttendanceJustification,
  onBusinessGoalSelected,
  onActionSelected,
  onCategorySelected,
}: ICreateAttendanceJustificationProps) {
  const businessGoals = useSelector(selectBusinessGoals);
  const categories = useSelector(selectCategoriesByBusinessGoals);
  const actions = useSelector(selectAttendanceJustificationsActions);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [justifyText, setJustifyText] = useState("");
  const [justifyDescriptionText, setJustifyDescriptionText] = useState("");
  const [templateText, setTemplateText] = useState("");

  const handleCreateAttendanceJustification = () => {
    if (businessGoalSelected === "") {
      toast.error("O objetivo de negócio não pode ser vazio");
      return;
    }

    if (categorySelected === "") {
      toast.error("A categoria não pode ser vazia");
      return;
    }

    if (justifyText === "") {
      toast.error("A justificativa não pode ser vazia");
      return;
    }

    if (actionSelected === "") {
      toast.error("A ação esperada não pode ser vazia");
      return;
    }
  };

  const handleSetNewJustifyText = (text: string) => setJustifyText(text);

  const handleSetNewSelectedAction = (text: string) => onActionSelected(text);

  const handleSetNewJustifyDescripionText = (text: string) =>
    setJustifyDescriptionText(text);

  const handleSetNewTemplateText = (text: string) => setTemplateText(text);

  const handleSetNewBusinessGoalSelected = (text: string) =>
    onBusinessGoalSelected(text);

  const handleSetNewCategorySelected = (text: string) =>
    onCategorySelected(text);

  const handleDialogOpen = (value: boolean) => {
    setModalIsOpen(value);
  };

  useEffect(() => {
    onBusinessGoalSelected(businessGoals[0].name);
    onCategorySelected(categories[0].mapped_field);
    onActionSelected(actions[0].text);
  }, []);

  return (
    <Dialog onOpenChange={handleDialogOpen} open={modalIsOpen}>
      <DialogTrigger>
        <FloatButtonToCreate />
      </DialogTrigger>
      <DialogContent
        style={{ width: "425px", maxHeight: "750px", overflowY: "scroll" }}
      >
        <DialogClose>
          <IconButton
            size="small"
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </DialogClose>
        <DialogTitle>Criar Justificativa</DialogTitle>

        <ListDivider />

        <Flex css={{ gap: "1.5rem", padding: "1rem 0" }}>
          <Flex
            css={{
              width: "100%",
              flexDirection: "column",
              gap: "$4",
            }}
          >
            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              <Heading
                size="subtitle2"
                css={{ display: "flex", flexDirection: "row" }}
              >
                Objetivo de Negócio
                <Heading
                  size="subtitle2"
                  css={{ color: "$red500", fontWeight: "bold" }}
                >
                  *
                </Heading>
              </Heading>
              <Select
                value={businessGoalSelected}
                defaultValue={businessGoalSelected}
                ariaLabel="Select business goal"
                onValueChange={handleSetNewBusinessGoalSelected}
              >
                {businessGoals.map((goal) => {
                  return (
                    <SelectItem value={goal.name} key={goal.id}>
                      <SelectItemText>{goal.name}</SelectItemText>
                    </SelectItem>
                  );
                })}
              </Select>
            </Flex>

            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              <Heading
                size="subtitle2"
                css={{ display: "flex", flexDirection: "row" }}
              >
                Categoria
                <Heading
                  size="subtitle2"
                  css={{ color: "$red500", fontWeight: "bold" }}
                >
                  *
                </Heading>
              </Heading>
              <Select
                value={categorySelected}
                defaultValue={categorySelected}
                ariaLabel="Select business goal"
                onValueChange={handleSetNewCategorySelected}
              >
                {categories.map((category) => {
                  return (
                    <SelectItem value={category.mapped_field} key={category.id}>
                      <SelectItemText>{category.mapped_field}</SelectItemText>
                    </SelectItem>
                  );
                })}
              </Select>
            </Flex>

            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              <Heading
                size="subtitle2"
                css={{ display: "flex", flexDirection: "row" }}
              >
                Justificativa
                <Heading
                  size="subtitle2"
                  css={{ color: "$red500", fontWeight: "bold" }}
                >
                  *
                </Heading>
              </Heading>
              <Input
                placeholder="Digite o nome da justificativa"
                value={justifyText}
                onChange={(e) => handleSetNewJustifyText(e.target.value)}
              />
            </Flex>

            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              <Heading
                size="subtitle2"
                css={{ display: "flex", flexDirection: "row" }}
              >
                Descrição da justificativa
              </Heading>

              <Input
                placeholder="Ex. essa justifica fará algo..."
                value={justifyDescriptionText}
                onChange={(e) =>
                  handleSetNewJustifyDescripionText(e.target.value)
                }
                style={{ height: "100px" }}
              />
            </Flex>

            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              <Heading
                size="subtitle2"
                css={{ display: "flex", flexDirection: "row" }}
              >
                Ação
                <Heading
                  size="subtitle2"
                  css={{ color: "$red500", fontWeight: "bold" }}
                >
                  *
                </Heading>
              </Heading>
              <Select
                value={actionSelected}
                defaultValue={actionSelected}
                ariaLabel="Select business goal"
                onValueChange={handleSetNewSelectedAction}
              >
                {actions.map((action) => {
                  return (
                    <SelectItem value={action.text} key={action.id}>
                      <SelectItemText>{action.text}</SelectItemText>
                    </SelectItem>
                  );
                })}
              </Select>
            </Flex>

            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              <Heading
                size="subtitle2"
                css={{ display: "flex", flexDirection: "row" }}
              >
                Template
              </Heading>
              <Input
                placeholder="Ex:
                Última cotação ganha: 05/11/2001
                Produto(s):
                Previsão de retorno:"
                value={templateText}
                onChange={(e) => handleSetNewTemplateText(e.target.value)}
              />
            </Flex>
          </Flex>
        </Flex>

        <ListDivider />

        <Flex
          css={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: "15px",
            gap: "$4",
          }}
        >
          <DialogClose>
            <Button bordered color="primary" style={{ background: "white" }}>
              Cancelar
            </Button>
          </DialogClose>
          <Button onClick={handleCreateAttendanceJustification}>
            Criar Justificativa
          </Button>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
