import { SelectOption } from "@/core/models/advanced-filters.model";
import { Box, Button, Caption, Flex, Input, Text } from "@gogeo-io/ui-library";
import { useState } from "react";
import styled from "styled-components";

interface SelectGenericOptionProps {
  optionSelected: SelectOption;
  setOptionSelected: (option: SelectOption) => void;
  options: SelectOption[];
  onApplyFilter: () => void;
}

export function SelectGenericOption({
  optionSelected,
  setOptionSelected,
  options,
  onApplyFilter,
}: SelectGenericOptionProps) {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selected, setSelected] = useState(optionSelected);

  const handleInputChange = (value: string) => {
    const searchTerm = value.toLowerCase();
    const filteredOptions = options.filter((option) =>
      option.value.toLowerCase().includes(searchTerm)
    );
    setFilteredOptions(filteredOptions);
  };

  const handleClickOption = (option: SelectOption) => {
    setOptionSelected({
      id: option.id,
      value: option.value,
    });
    setSelected(option);
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$4" }}>
      <Flex css={{ flexDirection: "column", gap: "$4" }}>
        <Flex css={{ flexDirection: "column", gap: "$1" }}>
          <Caption>Procurar</Caption>
          <Input
            placeholder="Digite o termo de busca"
            onChange={(e) => handleInputChange(e.target.value)}
          />
        </Flex>
        <Box css={{ maxHeight: "300px", overflowY: "scroll" }}>
          <Flex css={{ flexDirection: "column", gap: "$2" }}>
            {filteredOptions.map((option) => {
              return (
                <SelectUser
                  key={option.id}
                  onClick={() => handleClickOption(option)}
                  selected={option.id === selected.id}
                >
                  <Text>{option.value}</Text>
                </SelectUser>
              );
            })}
          </Flex>
        </Box>
      </Flex>

      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <Button onClick={onApplyFilter}>Aplicar</Button>
      </Flex>
    </Flex>
  );
}

interface SelectUserProps {
  selected?: boolean;
}

export const SelectUser = styled.div<SelectUserProps>`
  padding: 0.75rem;
  cursor: pointer;
  border-radius: ${(props) => props.theme.sizes[1]};

  background-color: ${(props) =>
    props.selected ? props.theme.colors.gray100 : "transparent"};

  &:hover {
    background: ${(props) => props.theme.colors.gray100};
  }
`;
