import { PreDefinedMessagesSkeletonRow } from "@/components/Skeleton/PreDefinedMessagesSkeletonRow";
import { PreDefinedMessage } from "@/core/models/preDefinedMessage.model";
import { Box } from "@gogeo-io/ui-library";
import { Table } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectTotalPreDefinedMessages } from "../store/preDefinedMessagesSlice";

interface ClientsTableProps {
  columns: any;
  data: any;
  onPageChange: (page: number) => void;
  currentPage: number;
  onIncreasePageNumber: () => void;
  status: string;
}

export function PreDefinedMessagesTable({
  columns,
  data,
  onPageChange,
  currentPage,
  onIncreasePageNumber,
  status,
}: ClientsTableProps) {
  const totalPreDefinedMessages = useSelector(selectTotalPreDefinedMessages);

  const generateRowKey = (record: PreDefinedMessage) => {
    return `${record.id}-${record.title}`;
  };

  useEffect(() => {
    if (currentPage !== 0) {
      if (data.length < totalPreDefinedMessages) {
        onPageChange(currentPage);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    const instersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        if (data.length < totalPreDefinedMessages) {
          onIncreasePageNumber();
        }
      }
    });

    instersectionObserver.observe(document.querySelector("#sentry"));

    return () => instersectionObserver.disconnect();
  }, []);

  return (
    <Box>
      <Table
        columns={columns}
        dataSource={data}
        loading={status === "loading"}
        pagination={false}
        rowKey={generateRowKey}
      />

      <div id="sentry">
        {data.length < totalPreDefinedMessages ? (
          <PreDefinedMessagesSkeletonRow />
        ) : null}
      </div>
    </Box>
  );
}
