import { Flex, Heading } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { selectSelectedBusinessAssociateService } from "../store/businessAssociatesServicesConfigSlice";
import { BusinessAssociateConfigMapOfFields } from "./BusinessAssociateConfigMapOfFields";

export function BusinessAssociateConfigServiceList() {
  const selectedBusinessAssociateService = useSelector(
    selectSelectedBusinessAssociateService
  );
  const { govendas_fields, associate_fields } =
    selectedBusinessAssociateService;

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {govendas_fields && govendas_fields.length > 0 && (
        <Flex css={{ flexDirection: "column", gap: "$2" }}>
          <Heading>Configurações - GoVendas</Heading>

          <BusinessAssociateConfigMapOfFields fields={govendas_fields} />
        </Flex>
      )}

      {associate_fields && associate_fields.length > 0 && (
        <Flex css={{ flexDirection: "column", gap: "$2" }}>
          <Heading>
            Configurações - {selectedBusinessAssociateService.name}
          </Heading>

          <BusinessAssociateConfigMapOfFields fields={associate_fields} />
        </Flex>
      )}
    </Flex>
  );
}
