import { RootState } from "@/core/store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ClientsToChangeParamsCountState {
  count: number;
}

const initialState = {
  count: 0,
} as ClientsToChangeParamsCountState;

export const clientsToChangeParamsCountSlice = createSlice({
  name: "clientsToChangeParamsCountSlice",
  initialState,
  reducers: {
    setClientToChangeParamsCount(state, action: PayloadAction<number>) {
      state.count = action.payload;
    },
  },
});

export const selectClientsSelectedToChangeParamsCountSlice = (
  state: RootState
) => state.clientsToChangeParamsCountState.count;

export const { setClientToChangeParamsCount } =
  clientsToChangeParamsCountSlice.actions;

export const clientsToChangeParamsCountReducer =
  clientsToChangeParamsCountSlice.reducer;
