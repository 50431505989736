import { Status } from "@/core/models/client.model";
import { Caption } from "@gogeo-io/ui-library";
import styled from "styled-components";
import { DynamicBsIcon } from "../icons/DynamicBsIcon";
import { DynamicFaIcon } from "../icons/DynamicFaIcon";
import { DynamicMdIcon } from "../icons/DynamicMdIcon";

interface PillCellProps {
  status: Status;
}

export default function StatusCell({ status }: PillCellProps) {
  return (
    <>
      {status && (
        <StatusPill $bgcolor={status.color}>
          <StatusIcon>
            {status.icon.lib === "md" && (
              <DynamicMdIcon icon={status.icon.name} />
            )}
            {status.icon.lib === "bs" && (
              <DynamicBsIcon icon={status.icon.name} />
            )}
            {status.icon.lib === "fa" && (
              <DynamicFaIcon icon={status.icon.name} />
            )}
          </StatusIcon>
          <Caption css={{ color: "$white" }}>{status.description}</Caption>
        </StatusPill>
      )}
    </>
  );
}

interface StatusPillProps {
  $bgcolor: string;
}

export const StatusPill = styled.div<StatusPillProps>`
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  height: 2rem;
  padding-inline: 0.75rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 999rem;
  color: white;
  width: fit-content;
  background: ${({ theme, $bgcolor }) =>
    $bgcolor ? $bgcolor : theme.colors.gray500};
`;

export const StatusIcon = styled.div`
  margin-right: 0.25rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
`;
