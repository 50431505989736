import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function RuptureRuleSkeletonPage() {
  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <RLSSkeleton height={30} />

      <Flex css={{ flexDirection: "column", gap: "20px" }}>
        <RLSSkeleton height={120} />
        <RLSSkeleton width={300} height={28} />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "20px" }}>
        <RLSSkeleton height={120} />
        <RLSSkeleton width={300} height={28} />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "20px" }}>
        <RLSSkeleton height={120} />
        <RLSSkeleton width={300} height={28} />
      </Flex>

      <RLSSkeleton height={30} />
    </Flex>
  );
}
