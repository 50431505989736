import { UserRole } from "@/core/models/user.model";
import { selectUser } from "@/core/store/users/usersSlice";
import { Box, Text } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";

interface UserCannotHaveAcessProps {
  roles: UserRole[];
  defaultFallback?: boolean;
  adicionalMessageToDefaultFallback?: string;
  customFallback?: React.ReactElement;
  children: React.ReactElement;
}

export function UserCannotHaveAcess({
  roles,
  defaultFallback = false,
  customFallback,
  adicionalMessageToDefaultFallback,
  children,
}: UserCannotHaveAcessProps) {
  const user = useSelector(selectUser);

  return (
    <>
      {!roles.includes(user.role) ? (
        <Box>{children}</Box>
      ) : (
        <>
          {!customFallback && defaultFallback && (
            <Box>
              <Text>
                Você não tem acesso a esse recurso.{" "}
                {adicionalMessageToDefaultFallback}
              </Text>
            </Box>
          )}
          {customFallback && <Box>{customFallback}</Box>}
        </>
      )}
    </>
  );
}
