import { Flex, Grid } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

interface SkeletonProps {
  count?: number;
}

export function BusinessAssociatesListSkeleton({ count = 3 }: SkeletonProps) {
  const timesToRepeat = Array.from(Array(count).keys());

  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <RLSSkeleton width={400} height={28} />

      <RLSSkeleton width={100} height={28} />

      <Grid
        css={{
          gridTemplateColumns: "repeat(auto-fill, 300px)",
          gap: "32px",
          mt: "$4",
        }}
      >
        {timesToRepeat.map((c) => {
          return (
            <Flex css={{ flexDirection: "column", gap: "$2" }} key={c}>
              <RLSSkeleton width={300} height={132} />
            </Flex>
          );
        })}
      </Grid>
    </Flex>
  );
}
