import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  gvTheme,
} from "@gogeo-io/ui-library";
import { ReactElement } from "react";
import { ChangeParamsPage } from "../pages/ChangeParamsPage";

interface ChangeParamsDialogProps {
  onUpdateParams: () => void;
  children: ReactElement;
}

export function ChangeParamsDialog({
  onUpdateParams,
  children,
}: ChangeParamsDialogProps) {
  return (
    <Dialog>
      <DialogTrigger
        style={{
          background: "transparent",
          cursor: "pointer",
          color: gvTheme.colors.white,
        }}
      >
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Alterar parâmetros de clientes</DialogTitle>

        <ChangeParamsPage onUpdateParams={onUpdateParams} />
      </DialogContent>
    </Dialog>
  );
}
