import * as yup from "yup";

export interface ParamsOfAccountFormData {
  inactive_days: number;
  meta_parameters: {
    active_freq: number;
    inactive_freq: number;
    skip_defaulting: boolean;
    rupture_params: {
      freq: number;
      mon_pref: number;
      tue_pref: number;
      wed_pref: number;
      thu_pref: number;
      fri_pref: number;
      sat_pref: number;
      sun_pref: number;
    };
  };
}

export const paramsOfAccountFormSchema = yup.object().shape({
  inactive_days: yup
    .number()
    .min(30, "Deve ser maior que 30 dias")
    .required("Campo obrigatório"),
  meta_parameters: yup.object().shape({
    active_freq: yup.number(),
    inactive_freq: yup.number(),
    skip_defaulting: yup.boolean(),
    rupture_params: yup.object().shape({
      freq: yup.number(),
      mon_pref: yup.number().oneOf([0, 1]),
      tue_pref: yup.number().oneOf([0, 1]),
      wed_pref: yup.number().oneOf([0, 1]),
      thu_pref: yup.number().oneOf([0, 1]),
      fri_pref: yup.number().oneOf([0, 1]),
      sat_pref: yup.number().oneOf([0, 1]),
      sun_pref: yup.number().oneOf([0, 1]),
    }),
  }),
});
