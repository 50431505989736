import { ListDivider } from "@/components/ListDivider";
import { Client } from "@/core/models/client.model";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  gvTheme,
} from "@gogeo-io/ui-library";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdClose, MdOutlinePhone } from "react-icons/md";
import styled from "styled-components";
import { BusinessAssociateServicesOfVoipContentContainer } from "./BusinessAssociateServicesOfVoipContentContainer";
import { BusinessAssociateServicesOfWhatsappModalContentContainer } from "./BusinessAssociateServicesOfWhatsappModalContentContainer";
import { OpenClientWhatsappDialog } from "./OpenClientWhatsappDialog";
import { ProfileInfo } from "./ProfileInfo";

interface ClientInfoProps {
  label: string;
  phone: string;
  unformatted_phone: string;
  client: Client;
  phoneActionsIsVisible?: boolean;
}

export function ClientProfileInfoPhone({
  label,
  phone,
  unformatted_phone,
  client,
  phoneActionsIsVisible = false,
}: ClientInfoProps) {
  const [isVisible, setIsVisible] = useState(phoneActionsIsVisible);
  const [servicesOfWhatsappDialogIsOpen, setServicesOfWhatsappDialogIsOpen] =
    useState(false);
  const [servicesOfVoipDialogIsOpen, setServicesOfVoipDialogIsOpen] =
    useState(false);
  const [openGoVendasDefaultWhatsapp, setOpenGoVendasDefaultWhatsapp] =
    useState(false);

  const handleOpenGoVendasDefaultWhatsapp = () => {
    setServicesOfWhatsappDialogIsOpen(false);
    setOpenGoVendasDefaultWhatsapp(true);
  };

  const showButtons = () => setIsVisible(true);
  const hideButtons = () => setIsVisible(false);

  return (
    <Wrapper
      onMouseEnter={!phoneActionsIsVisible ? showButtons : () => {}}
      onMouseLeave={!phoneActionsIsVisible ? hideButtons : () => {}}
    >
      <ProfileInfo label={label} value={phone} />

      {!label.toLowerCase().includes("email") && (
        <AnimatePresence>
          {isVisible && (
            <motion.div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                alignItems: "center",
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.15 } }}
              exit={{ opacity: 0 }}
            >
              <Dialog
                open={servicesOfVoipDialogIsOpen}
                onOpenChange={setServicesOfVoipDialogIsOpen}
              >
                <DialogTrigger style={{ background: "transparent" }}>
                  <Tooltip>
                    <TooltipTrigger
                      style={{
                        background: "transparent",
                        color: gvTheme.colors.primary500,
                        cursor: "pointer",
                      }}
                    >
                      <MdOutlinePhone color="inherit" fontSize={22} />
                    </TooltipTrigger>
                    <TooltipContent aria-label="Abrir Whatsapp">
                      Conversar com cliente por serviço de voip integrado
                    </TooltipContent>
                  </Tooltip>
                </DialogTrigger>
                <DialogContent
                  style={{ width: "450px" }}
                  onClick={(event) => event.stopPropagation()}
                >
                  <DialogTitle>Serviços Voip Disponíveis</DialogTitle>

                  <DialogClose asChild>
                    <IconButton
                      type="ghost"
                      css={{ position: "absolute", top: "1rem", right: "1rem" }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <MdClose />
                    </IconButton>
                  </DialogClose>

                  <ListDivider />

                  <BusinessAssociateServicesOfVoipContentContainer
                    phoneNumber={unformatted_phone}
                    client={client}
                    onCloseDialog={() => setServicesOfVoipDialogIsOpen(false)}
                  />
                </DialogContent>
              </Dialog>

              <Dialog
                open={servicesOfWhatsappDialogIsOpen}
                onOpenChange={setServicesOfWhatsappDialogIsOpen}
              >
                <DialogTrigger
                  style={{ background: "transparent" }}
                  onClick={() => setOpenGoVendasDefaultWhatsapp(false)}
                >
                  <Tooltip>
                    <TooltipTrigger
                      style={{
                        background: "transparent",
                        color: gvTheme.colors.primary500,
                        cursor: "pointer",
                      }}
                    >
                      <FaWhatsapp color="inherit" fontSize={20} />
                    </TooltipTrigger>
                    <TooltipContent aria-label="Abrir WhatsApp">
                      Conversar com cliente por serviço de WhatsApp integrado
                    </TooltipContent>
                  </Tooltip>
                </DialogTrigger>
                <DialogContent
                  style={{ width: "450px" }}
                  onClick={(event) => event.stopPropagation()}
                >
                  <DialogTitle>Serviços WhatsApp Disponíveis</DialogTitle>

                  <DialogClose asChild>
                    <IconButton
                      type="ghost"
                      css={{ position: "absolute", top: "1rem", right: "1rem" }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <MdClose />
                    </IconButton>
                  </DialogClose>

                  <ListDivider />

                  <BusinessAssociateServicesOfWhatsappModalContentContainer
                    phoneNumber={unformatted_phone}
                    client={client}
                    onCloseDialog={() =>
                      setServicesOfWhatsappDialogIsOpen(false)
                    }
                    onOpenGoVendasDefaultWhatsapp={
                      handleOpenGoVendasDefaultWhatsapp
                    }
                  />
                </DialogContent>
              </Dialog>

              {openGoVendasDefaultWhatsapp && (
                <OpenClientWhatsappDialog
                  client={client}
                  phone={unformatted_phone}
                  onCloseDialog={() => setServicesOfWhatsappDialogIsOpen(false)}
                />
              )}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 35px;
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
