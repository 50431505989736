import { DynamicFormFields } from "@/core/models/business-associate.model";
import { Flex, Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { FieldError } from "react-hook-form";

interface BussinessAssociateGenericFormSelectTypeProps {
  formField: DynamicFormFields;
  error: FieldError;
  onChange: (...event: any[]) => void;
}

export function BussinessAssociateGenericFormSelectType({
  formField,
  error,
  onChange,
}: BussinessAssociateGenericFormSelectTypeProps) {
  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <Select
        value={formField.value}
        defaultValue={formField.value}
        ariaLabel="Select option"
        onValueChange={(selectedOption) => onChange(selectedOption)}
      >
        {formField.options &&
          formField.options.map((option) => {
            return (
              <SelectItem value={option.value} key={option.id}>
                <SelectItemText>{option.value}</SelectItemText>
              </SelectItem>
            );
          })}
      </Select>
      {!!error && <G_UI.ErrorMessage>{error?.message}</G_UI.ErrorMessage>}
    </Flex>
  );
}
