import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchFilters } from "../../../core/models/listOfTags.model";
import { RootState } from "../../../core/store/store";

interface SelectFiltersInitialState {
  selectedSearchFilters: SearchFilters;
}

const initialState = {
  selectedSearchFilters: {
    match_type: "Geral",
    match_value: "",
  },
} as SelectFiltersInitialState;

export const selectedSearchFiltersSlice = createSlice({
  name: "selectedSearchFilters",
  initialState,
  reducers: {
    setSelectedSearchFilters(state, action: PayloadAction<SearchFilters>) {
      state.selectedSearchFilters = action.payload;
    },
  },
});

export const selectSelectedSearchFilters = (state: RootState) =>
  state.selectedSearchFiltersState.selectedSearchFilters;

export const { setSelectedSearchFilters } = selectedSearchFiltersSlice.actions;

export const selectedSearchFiltersReducer = selectedSearchFiltersSlice.reducer;
