import { useFormatDate } from "@/hooks/useFormatDate";
import { pt } from "date-fns/locale";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Calendar as RDGCalendar } from "react-date-range";
import styled from "styled-components";

interface CalendarProps {
  date?: string;
  dateToSet: (date: string) => void;
  disablePastDate?: boolean;
  disablePastDateAndToday?: boolean;
}

export function Calendar({
  date,
  dateToSet,
  disablePastDate = false,
  disablePastDateAndToday = false,
}: CalendarProps) {
  const { newDateBasedOnDateWithoutTimezone } = useFormatDate();

  const [calendarDate, setCalendarDate] = useState(
    !isEmpty(date) ? newDateBasedOnDateWithoutTimezone(date) : null
  );

  const { formatBasicDate } = useFormatDate();

  const handleOnChangeCalendar = (date): void => {
    setCalendarDate(date);
    dateToSet(formatBasicDate(date));
  };

  const getMinDate = (): Date => {
    if (disablePastDate || disablePastDateAndToday) {
      if (disablePastDate) {
        return new Date();
      } else {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        return tomorrow;
      }
    } else {
      return new Date(2016, 0);
    }
  };

  return (
    <Wrapper>
      <RDGCalendar
        onChange={(item) => handleOnChangeCalendar(item)}
        locale={pt}
        date={calendarDate}
        color="#10B981"
        minDate={getMinDate()}
      />
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
