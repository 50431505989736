import { Button, DialogClose } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface ProductsFilterActionsProps {
  onFilter: () => void;
}

export function ProductsFilterActions({
  onFilter,
}: ProductsFilterActionsProps) {
  const handleFilter = async () => {
    onFilter();
  };

  return (
    <ButtonWrapper>
      <DialogClose asChild>
        <Button color="danger" variant="soft">
          Cancelar
        </Button>
      </DialogClose>
      <DialogClose asChild onClick={handleFilter}>
        <Button>Filtrar</Button>
      </DialogClose>
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.space[3]};
  margin-top: ${({ theme }) => theme.space[3]};
`;
