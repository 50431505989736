import { AddNewAdvancedFilterToHistoryFilter } from "@/components/advancedFilters/AddNewAdvancedFilterToHistoryFilter";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, Text } from "@gogeo-io/ui-library";
import {
  removeSelectedHistoryFilter,
  updateValueFromSelectedHistoryFilter,
} from "../../store/historyFiltersSlice";
import { HistoryFilterContainer } from "./HistoryFilterContainer";
import { HistoryFilterTypes } from "./HistoryFilterTypes";

export function HistoryFilter() {
  const dispatch = useAppDispatch();

  const handleRemoveFilter = async (filter: AdvancedFilter) => {
    await dispatch(removeSelectedHistoryFilter(filter));
  };

  const handleUpdateValueFromSelectedHistoryFilter = async (
    filterId: number,
    newValue: any
  ) => {
    await dispatch(
      updateValueFromSelectedHistoryFilter({ filterId, newValue })
    );
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$1" }}>
      <Text wider css={{ color: "$gray500" }}>
        Filtros
      </Text>
      <HistoryFilterContainer>
        <Flex css={{ alignItems: "center", gap: "$4" }}>
          <AddNewAdvancedFilterToHistoryFilter />
        </Flex>
        <Flex
          css={{
            gap: "$2",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <HistoryFilterTypes
            onUpdateFilter={handleUpdateValueFromSelectedHistoryFilter}
            onRemoveFilter={handleRemoveFilter}
          />
        </Flex>
      </HistoryFilterContainer>
    </Flex>
  );
}
