import styled from "styled-components";

export const ClientProfile = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  overflow-y: auto;
`;
