import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserHas500error } from "./core/store/users/usersSlice";
import { selectMyListOfTagsHasError500 } from "./features/attendance/store/listOfTagsSlice";
import { selectClientHas500error } from "./features/clientInfo/store/clientSlice";

interface Error500InterceptorProps {
  children: ReactNode;
}

export function Error500Interceptor({ children }: Error500InterceptorProps) {
  const userHas500error = useSelector(selectUserHas500error);
  const myListOfTagsHasError400 = useSelector(selectMyListOfTagsHasError500);
  const clientHas500error = useSelector(selectClientHas500error);

  const navigate = useNavigate();

  useEffect(() => {
    if (userHas500error || myListOfTagsHasError400 || clientHas500error) {
      return navigate("/internal-server-error");
    }
  }, [userHas500error, myListOfTagsHasError400, clientHas500error]);

  return <>{children}</>;
}
