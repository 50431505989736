import { User } from "@/core/models/user.model";
import {
  removeAllUserToBatchActions,
  updateUserEnabled,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  Text,
} from "@gogeo-io/ui-library";
import { ReactNode, useState } from "react";
import { useUsersMixpanel } from "../hooks/useUsersMixpanel";
import { emitEventToChangeUsers } from "../store/emitEventToChangeUsersSlice";

interface DeactivateUserModalProps {
  children: ReactNode;
  user: User;
}

export function DeactivateUserModal({
  children,
  user,
}: DeactivateUserModalProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { inactiveUserEvent } = useUsersMixpanel();

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const closeDialog = () => setDialogIsOpen(false);

  const deactivateUser = async (user: User) => {
    await dispatch(emitEventToChangeUsers(false));

    closeDialog();

    await dispatch(
      updateUserEnabled({ active: false, user_ids: [user.id] })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        inactiveUserEvent(user);
        await dispatch(removeAllUserToBatchActions());
      }
    });

    await dispatch(emitEventToChangeUsers(true));
  };

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <Flex
          css={{
            flexDirection: "column",
            gap: "$4",
            width: "500px",
            textAlign: "center",
          }}
        >
          <Heading size="title6">Desativar esse Usuário</Heading>
          <Heading size="subtitle1">{user.name}</Heading>
          <Text>
            Você tem certeza que deseja desativar esse usuário? Ao desativá-lo,
            ele não poderá mais entrar na aplicação.
          </Text>

          <Flex
            css={{
              alignItems: "flex-end",
              justifyContent: "flex-end",
              gap: "$3",
              mt: "$3",
            }}
          >
            <Button color="danger" variant="soft" onClick={closeDialog}>
              Cancelar
            </Button>
            <Button onClick={() => deactivateUser(user)}>
              Desativar Usuário
            </Button>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
