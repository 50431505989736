import { Text } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";

interface ByStringProps {
  text: string;
}

export function ByString({ text }: ByStringProps) {
  return (
    <G_UI.ProductTagLabel>
      <Text>{text}</Text>
    </G_UI.ProductTagLabel>
  );
}
