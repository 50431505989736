import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function NotificationSkeletonPage() {
  return (
    <Flex css={{ flexDirection: "column", gap: "$5" }}>
      <Flex css={{ flexDirection: "column" }}>
        <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
          <RLSSkeleton width={200} height={30} borderRadius={4} />

          <RLSSkeleton width={150} height={20} borderRadius={4} />
        </Flex>
        <RLSSkeleton width={270} height={30} borderRadius={4} />
      </Flex>

      <Flex css={{ flexDirection: "column" }}>
        <RLSSkeleton width={100} height={30} borderRadius={4} />
        <RLSSkeleton width={200} height={30} borderRadius={4} />
      </Flex>

      <RLSSkeleton width={270} height={50} borderRadius={4} />
    </Flex>
  );
}
