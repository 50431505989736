import { DynamicFormFields } from "@/core/models/business-associate.model";
import { Text } from "@gogeo-io/ui-library";

interface BussinessAssociateGenericFormLabelProps {
  formField: DynamicFormFields;
}

export function BussinessAssociateGenericFormLabel({
  formField,
}: BussinessAssociateGenericFormLabelProps) {
  return (
    <Text css={{ marginBottom: "$2" }}>
      {formField.label}
      {formField.required && (
        <Text bold css={{ color: "$red500", display: "inline" }}>
          *
        </Text>
      )}
    </Text>
  );
}
