import {
  BusinessAssociateService,
  BusinessAssociateServices,
} from "@/core/models/business-associate.model";
import { Client } from "@/core/models/client.model";
import { selectUser } from "@/core/store/users/usersSlice";
import {
  selectBusinessAssociateServicesOfVoip,
  selectBusinessAssociateServicesOfVoipStatus,
} from "@/features/businessAssociate/store/businessAssociateServicesSlice";
import {
  Button,
  Flex,
  Heading,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTranslateBusinessAttendanceServiceUrl } from "../hooks/useTranslateBusinessAttendanceServiceUrl";

interface BusinessAssociateServicesOfVoipModalContentProps {
  phoneNumber: string;
  client: Client;
  onCloseDialog: () => void;
}

export function BusinessAssociateServicesOfVoipModalContent({
  phoneNumber,
  client,
  onCloseDialog,
}: BusinessAssociateServicesOfVoipModalContentProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBusinessAssociate, setSelectedBusinessAssociate] =
    useState<BusinessAssociateServices>({} as BusinessAssociateServices);
  const [
    selectedBusinessAssociateService,
    setSelectedBusinessAssociateService,
  ] = useState<BusinessAssociateService>({} as BusinessAssociateService);

  const businessAssociateServicesOfVoip = useSelector(
    selectBusinessAssociateServicesOfVoip
  );
  const businessAssociateServicesOfVoipStatus = useSelector(
    selectBusinessAssociateServicesOfVoipStatus
  );
  const user = useSelector(selectUser);

  const { replaceUrl } = useTranslateBusinessAttendanceServiceUrl();

  const cancelChanges = () => {
    onCloseDialog();
  };

  const handleSelectBusinessService = async () => {
    setIsLoading(true);

    const replacedUrl = replaceUrl(
      phoneNumber,
      selectedBusinessAssociateService.redirect_url,
      user,
      selectedBusinessAssociate.govendas_fields,
      selectedBusinessAssociateService.govendas_fields
    );
    window.open(replacedUrl, "_blank", "noreferrer");
    onCloseDialog();

    setIsLoading(false);
  };

  const handleSetSelectedBusinessAssociateService = (
    service: BusinessAssociateService,
    businessAssociate: BusinessAssociateServices
  ) => {
    setSelectedBusinessAssociateService(service);
    setSelectedBusinessAssociate(businessAssociate);
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$3", mt: "$3" }}>
      {businessAssociateServicesOfVoip &&
        businessAssociateServicesOfVoip.map((businessAssociate) => {
          if (businessAssociate && businessAssociate.services.length > 0) {
            return (
              <Flex
                css={{ flexDirection: "column", gap: "$1" }}
                key={businessAssociate.id}
              >
                <Heading>{businessAssociate.name}</Heading>

                <Flex css={{ flexDirection: "column", gap: "$2" }}>
                  {businessAssociate.services.map((service) => {
                    return (
                      <div key={service.id}>
                        <Tooltip>
                          <TooltipTrigger
                            style={{
                              color: "inherit",
                              width: "100%",
                              background: "transparent",
                            }}
                          >
                            <ServiceItem
                              $isSelected={
                                service.id ===
                                selectedBusinessAssociateService.id
                              }
                              key={service.id}
                              onClick={() =>
                                handleSetSelectedBusinessAssociateService(
                                  service,
                                  businessAssociate
                                )
                              }
                            >
                              <Text css={{ color: "inherit" }}>
                                {service.name}
                              </Text>
                            </ServiceItem>
                          </TooltipTrigger>
                          <TooltipContent
                            style={{ zIndex: 100000 }}
                            aria-label={service.name}
                          >
                            {service.description}
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    );
                  })}
                </Flex>
              </Flex>
            );
          }
        })}

      <Flex
        css={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          gap: "$2",
          mt: "$3",
        }}
      >
        <Button bordered onClick={cancelChanges}>
          Cancelar
        </Button>
        <Button onClick={handleSelectBusinessService} isLoading={isLoading}>
          Selecionar
        </Button>
      </Flex>
    </Flex>
  );
}

interface ServiceItemProps {
  $isSelected: boolean;
}

const ServiceItem = styled.div<ServiceItemProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.sizes[1]} ${(props) => props.theme.sizes[2]};
  border-radius: 2px;
  border: 1px solid
    ${(props) =>
      props.$isSelected
        ? props.theme.colors.primary700
        : props.theme.colors.gray300};
  background: ${(props) =>
    props.$isSelected ? props.theme.colors.white : props.theme.colors.white};
  color: ${(props) =>
    props.$isSelected
      ? props.theme.colors.primary700
      : props.theme.colors.gray600};
  cursor: pointer;
`;
