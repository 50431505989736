import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import {
  Box,
  Flex,
  Input,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { MdOutlineInfo, MdSearch } from "react-icons/md";
import { EmptyContent } from "../EmptyContent";
import { AdvancedFilterListItem } from "./styles";

interface FiltersThatTheUserCanSelectProps {
  filtersThatTheUserCanSelect: AdvancedFilter[];
  selectedFilterToAdd: AdvancedFilter[];
  showTagsFilter?: boolean;
  showAssociatedUsersFilter?: boolean;
  onSetSelectedFilter: (advFilter: AdvancedFilter) => void;
  onOpenTagsDialog: () => void;
  onCloseAdvancedFiltersDialog: () => void;
}

export function FiltersThatTheUserCanSelect({
  filtersThatTheUserCanSelect,
  selectedFilterToAdd,
  showTagsFilter = false,
  showAssociatedUsersFilter = true,
  onSetSelectedFilter,
  onOpenTagsDialog,
  onCloseAdvancedFiltersDialog,
}: FiltersThatTheUserCanSelectProps) {
  const [filteredFilters, setFilteredFilters] = useState(
    filtersThatTheUserCanSelect
  );

  const addAdvancedFilter = (advFilter: AdvancedFilter) => {
    onSetSelectedFilter(advFilter);
  };

  const handleSelectFilter = (advFilter: AdvancedFilter) => {
    addAdvancedFilter(advFilter);
  };

  const handleInputChange = (searchTerm: string) => {
    if (searchTerm === "") {
      setFilteredFilters(filtersThatTheUserCanSelect);
    } else {
      const filteredFilters = filtersThatTheUserCanSelect.filter((filter) =>
        filter.label
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase())
      );
      setFilteredFilters(filteredFilters);
    }
  };

  const getFiltersToUserSelect = (): AdvancedFilter[] => {
    if (showAssociatedUsersFilter) {
      return filteredFilters.filter(
        (item) => !selectedFilterToAdd.includes(item)
      );
    } else {
      return filteredFilters.filter(
        (item) =>
          !selectedFilterToAdd.includes(item) &&
          item.property !== "associated_users"
      );
    }
  };

  return (
    <Box css={{ width: "100%", height: "100%" }}>
      <Flex
        css={{
          flexDirection: "column",
          marginBottom: "$4",
          padding: "4px",
          gap: "$2",
        }}
      >
        <Text css={{ color: "$gray500" }}>
          Selecione o filtro que deseja adicionar
        </Text>

        <Input
          placeholder="Buscar Filtro"
          preffix={<MdSearch />}
          onChange={(e) => handleInputChange(e.target.value)}
        />
      </Flex>

      <Flex
        css={{
          flexDirection: "column",
          gap: "$3",
        }}
      >
        {showTagsFilter && (
          <AdvancedFilterListItem
            onClick={() => {
              onOpenTagsDialog();
              onCloseAdvancedFiltersDialog();
            }}
          >
            <Flex css={{ gap: "2px" }}>
              <Text size="body2" css={{ color: "$gray900" }}>
                Tags Inteligentes
              </Text>
            </Flex>
          </AdvancedFilterListItem>
        )}
        {filteredFilters.length > 0 ? (
          <>
            {getFiltersToUserSelect().map((filter) => {
              return (
                <AdvancedFilterListItem
                  key={filter.property}
                  onClick={() => handleSelectFilter(filter)}
                >
                  <Flex key={filter.id} css={{ gap: "2px" }}>
                    <Text size="body2" css={{ color: "$gray900" }}>
                      {filter.label}
                    </Text>
                    {filter.description && (
                      <Tooltip>
                        <TooltipTrigger
                          style={{
                            background: "transparent",
                          }}
                        >
                          <Flex css={{ color: "$gray900" }}>
                            <MdOutlineInfo color="inherit" />
                          </Flex>
                        </TooltipTrigger>
                        <TooltipContent
                          aria-label={filter.description}
                          style={{ zIndex: "9999" }}
                        >
                          {filter.description}
                        </TooltipContent>
                      </Tooltip>
                    )}
                  </Flex>
                </AdvancedFilterListItem>
              );
            })}
          </>
        ) : (
          <EmptyContent size="small">
            Não há mais filtros disponíveis para você selecionar
          </EmptyContent>
        )}
      </Flex>
    </Box>
  );
}
