import { EmptyContent } from "@/components/EmptyContent";
import { Breadcrumb } from "@/core/models/breadcrumb";
import { Notification } from "@/core/models/notification.model";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatDate } from "@/hooks/useFormatDate";
import { Button, Caption, Flex, Heading, Text } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNotificationsMixpanel } from "../hooks/useNotificationsMixpanel";
import {
  getNotifications,
  readNotification,
  selectNotifications,
  selectNotificationsQuery,
  selectNotificationsSelectedType,
  selectSelectedNotification,
  setSelectedNotification,
  updateNotification,
} from "../store/notificationsSlice";
import { NotificationSkeletonPage } from "./NotificationSkeletonPage";

export function NotificationPage() {
  const [notificationNotFinded, setNotificationNotFinded] = useState(false);
  const [notificationIsLoading, setNotificationIsLoading] = useState(false);

  const notifications = useSelector(selectNotifications);
  const selectedNotification = useSelector(selectSelectedNotification);
  const notificationsQuery = useSelector(selectNotificationsQuery);
  const notificationsSelectedType = useSelector(
    selectNotificationsSelectedType
  );

  const { formatLongDatePtBr } = useFormatDate();
  const { notificationReadedEvent } = useNotificationsMixpanel();
  const dispatch = useAppDispatch();
  const { notification_id } = useParams();

  const handleNavigation = () => {
    window.location.href = selectedNotification.link;
  };

  const handleReadNotification = async (value: boolean) => {
    await dispatch(
      updateNotification({ id: selectedNotification.id, isRead: value })
    ).then(async (obj) => {
      if (obj.meta.requestStatus === "fulfilled") {
        notificationReadedEvent(selectedNotification.id);
        await dispatch(readNotification(selectedNotification.id));
        await dispatch(
          getNotifications({
            query: notificationsQuery,
            type: notificationsSelectedType,
          })
        );
      }
    });
  };

  async function loadBreadcrumbs(notification: Notification) {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Notificações", url: "/notifications" },
      { item: `${notification.title}`, url: `${notification.link}` },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  }

  const dateParsed = useMemo(() => {
    return selectedNotification.date
      ? formatLongDatePtBr(selectedNotification.date)
      : "Data inválida";
  }, [selectedNotification.date]);

  useEffect(() => {
    setNotificationNotFinded(false);
    setNotificationIsLoading(false);

    if (isEmpty(selectedNotification) && notifications.length > 0) {
      setNotificationIsLoading(true);

      const setNofificationSelected = async () => {
        const findedNotification = notifications.find(
          (notification) => notification.id === Number(notification_id)
        );

        if (findedNotification) {
          await dispatch(setSelectedNotification(findedNotification));
          loadBreadcrumbs(findedNotification);
        } else {
          setNotificationNotFinded(true);
        }

        setNotificationIsLoading(false);
      };

      setNofificationSelected();
    }
  }, [notifications]);

  useEffect(() => {
    loadBreadcrumbs(selectedNotification);
  }, [selectedNotification]);

  if (notificationNotFinded) {
    return (
      <EmptyContent>Não foi possível encontrar a notificação</EmptyContent>
    );
  }

  return (
    <Flex css={{ flexDirection: "column", gap: "$5" }}>
      {notificationIsLoading ? (
        <NotificationSkeletonPage />
      ) : (
        <Flex css={{ flexDirection: "column", gap: "$5" }}>
          <Flex css={{ flexDirection: "column" }}>
            <Flex
              css={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Heading size="title6">{selectedNotification.title}</Heading>
              <Heading
                size="subtitle2"
                css={{
                  color: "$gray600",
                  textDecoration: "underline",
                  textUnderlineOffset: "2px",
                  cursor: "pointer",
                  width: "fit-content",
                }}
                onClick={() =>
                  handleReadNotification(
                    selectedNotification.read ? false : true
                  )
                }
              >
                {selectedNotification.read
                  ? "Marcar como não lida"
                  : "Marcar como lida"}
              </Heading>
            </Flex>
            <Caption>{dateParsed}</Caption>
          </Flex>

          <Flex css={{ flexDirection: "column" }}>
            <Caption>Decrição:</Caption>
            <Text>{selectedNotification.description}</Text>
          </Flex>

          <Button
            bordered
            onClick={handleNavigation}
            style={{ width: "fit-content" }}
          >
            Clique aqui para acessar
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
