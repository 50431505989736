import { Button, DialogClose, Flex, Text } from "@gogeo-io/ui-library";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

interface ShareOutsideGovendasProps {
  shareUrl: string;
  title: string;
}

export function ShareOutsideGovendas({
  shareUrl,
  title,
}: ShareOutsideGovendasProps) {
  return (
    <Flex css={{ flexDirection: "column", gap: "$4" }}>
      <Text css={{ color: "$gray800" }}>
        Apps para você escolher onde compartilhar
      </Text>

      <Flex
        css={{
          padding: "$2 $2",
          borderRadius: "4px",
          background: "$gray50",
          alignItems: "center",
          gap: "$2",
          flexWrap: "wrap",
          border: "1px solid $gray100",
        }}
      >
        <FacebookShareButton url={shareUrl} title={title}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton url={shareUrl} title={title}>
          <XIcon size={32} round />
        </TwitterShareButton>

        <TelegramShareButton url={shareUrl} title={title}>
          <TelegramIcon size={32} round />
        </TelegramShareButton>

        <WhatsappShareButton url={shareUrl} title={title}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <LinkedinShareButton url={shareUrl} title={title}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>

        <RedditShareButton url={shareUrl} title={title}>
          <RedditIcon size={32} round />
        </RedditShareButton>

        <EmailShareButton url={shareUrl} subject={title}>
          <EmailIcon size={32} round />
        </EmailShareButton>
      </Flex>

      <Flex
        css={{
          gap: "$2",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          mt: "$4",
        }}
      >
        <DialogClose asChild>
          <Button bordered>Fechar</Button>
        </DialogClose>
      </Flex>
    </Flex>
  );
}
