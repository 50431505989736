import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useNotificationsMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const notificationReadedEvent = (id: number) => {
    MixpanelTracking.getInstance().track(TrackingEvents.NOTIFICATION_READ, {
      ...buildProps(),
      "ID da Notificação": id,
    });
  };

  const notificationAccessedEvent = (id: number) => {
    MixpanelTracking.getInstance().track(TrackingEvents.NOTIFICATION_ACCESSED, {
      ...buildProps(),
      "ID da Notificação": id,
    });
  };

  const notificationSearchEvent = (query: string, type: string) => {
    MixpanelTracking.getInstance().track(TrackingEvents.NOTIFICATION_SEARCH, {
      ...buildProps(),
      Busca: query,
      "Tipo de notificação": type,
    });
  };

  const markAllNotificationsToReadEvent = () => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.NOTIFICATION_MARK_ALL_TO_READ,
      {
        ...buildProps(),
      }
    );
  };

  return {
    notificationReadedEvent,
    notificationSearchEvent,
    markAllNotificationsToReadEvent,
    notificationAccessedEvent,
  };
};
