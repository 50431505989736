import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { Client } from "@/core/models/client.model";
import { ListOfTags, ListOfTagsFilter } from "@/core/models/listOfTags.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useClientsMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const searchClientEvent = (searchTerm: string, match_type: string) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENTS_SEARCH, {
      ...buildProps(),
      "Termo de busca": searchTerm,
      "Busca feita por": match_type,
    });
  };

  const loadClientsEvent = (
    list: ListOfTags,
    filters: ListOfTagsFilter,
    clients: Client[]
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENTS_SEARCH_FOUND, {
      ...buildProps(),
      "Lista selecionada": list,
      "Filtros selecionados": filters,
      "Clientes encontrados": clients,
    });
  };

  const searchClientWithClientsReturnedEvent = (
    searchTerm: string,
    match_type: string,
    filters: ListOfTagsFilter,
    clients: Client[]
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENTS_SEARCH, {
      ...buildProps(),
      "Termo de busca": searchTerm,
      "Busca feita por": match_type,
      "Filtros selecionados": filters,
      "Clientes encontrados": clients,
    });
  };

  const sortClientsEvent = (
    list: ListOfTags,
    filters: ListOfTagsFilter,
    clients: Client[]
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENTS_SORT_CHANGE, {
      ...buildProps(),
      "Lista selecionada": list,
      "Filtros selecionados": filters,
      "Clientes encontrados": clients,
    });
  };

  return {
    searchClientEvent,
    loadClientsEvent,
    sortClientsEvent,
    searchClientWithClientsReturnedEvent,
  };
};
