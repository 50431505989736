import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import {
  BusinessAssociate,
  BusinessAssociateService,
} from "@/core/models/business-associate.model";
import { ListOfTags } from "@/core/models/listOfTags.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useBusinessAssociateMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const businessAssociateOpenedEvent = (partner: BusinessAssociate) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.BUSINESS_ASSOCIATE_PARTNER_INTEGRATION_OPENED,
      {
        ...buildProps(),
        "Nome Parceiro": partner.name,
        "ID do parceiro": partner.id,
        "Informações completas do parceiro": partner,
      }
    );
  };

  const businessAssociateDisabledEvent = (
    partner: BusinessAssociate,
    enabled: boolean
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.BUSINESS_ASSOCIATE_PARTNER_INTEGRATION_DISABLED,
      {
        ...buildProps(),
        "Nome Parceiro": partner.name,
        "ID do parceiro": partner.id,
        "Informações completas do parceiro": partner,
        Habilitado: enabled,
      }
    );
  };

  const businessAssociateEnabledEvent = (
    partner: BusinessAssociate,
    enabled: boolean
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.BUSINESS_ASSOCIATE_PARTNER_INTEGRATION_ENABLED,
      {
        ...buildProps(),
        "Nome Parceiro": partner.name,
        "ID do parceiro": partner.id,
        "Informações completas do parceiro": partner,
        Habilitado: enabled,
      }
    );
  };

  const businessAssociateServiceOpenedEvent = (
    service: BusinessAssociateService
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.BUSINESS_ASSOCIATE_PARTNER_SERVICE_INTEGRATION_OPENED,
      {
        ...buildProps(),
        "Nome do serviço Parceiro": service.name,
        "ID serviço do parceiro": service.id,
        "Informações completas do serviço do parceiro": service,
      }
    );
  };

  const businessAssociateServiceDisabledEvent = (
    service: BusinessAssociateService,
    enabled: boolean
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.BUSINESS_ASSOCIATE_PARTNER_SERVICE_INTEGRATION_DISABLED,
      {
        ...buildProps(),
        "Nome do serviço Parceiro": service.name,
        "ID serviço do parceiro": service.id,
        "Informações completas do serviço do parceiro": service,
        Habilitado: enabled,
      }
    );
  };

  const businessAssociateServiceEnabledEvent = (
    service: BusinessAssociateService,
    enabled: boolean
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.BUSINESS_ASSOCIATE_PARTNER_SERVICE_INTEGRATION_ENABLED,
      {
        ...buildProps(),
        "Nome do serviço Parceiro": service.name,
        "ID serviço do parceiro": service.id,
        "Informações completas do serviço do parceiro": service,
        Habilitado: enabled,
      }
    );
  };

  const businessAssociateConfigChangeEvent = (
    partner: BusinessAssociate,
    body: any
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.BUSINESS_ASSOCIATE_PARTNER_INTEGRATION_CHANGED,
      {
        ...buildProps(),
        "Nome Parceiro": partner.name,
        "ID do parceiro": partner.id,
        "Informações completas do parceiro": partner,
        "Campos alterados": body,
      }
    );
  };

  const businessAssociateServiceConfigChangeEvent = (
    service: BusinessAssociateService,
    body: any
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.BUSINESS_ASSOCIATE_PARTNER_SERVICE_INTEGRATION_CHANGED,
      {
        ...buildProps(),
        "Nome do serviço Parceiro": service.name,
        "ID serviço do parceiro": service.id,
        "Informações completas do serviço do parceiro": service,
        "Campos alterados": body,
      }
    );
  };

  const businessAssociateServicesOfListChanged = (
    selectedList: ListOfTags,
    selectedBusinessAssociateServicesIds: number[]
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.BUSINESS_ASSOCIATE_PARTNER_SERVICE_INTEGRATION_CHANGED,
      {
        ...buildProps(),
        "ID da lista": selectedList.id,
        "Nome da lista": selectedList.name,
        "Informações completas da lista": selectedList,
        "IDs dos serviços ativos": selectedBusinessAssociateServicesIds,
      }
    );
  };

  return {
    businessAssociateOpenedEvent,
    businessAssociateDisabledEvent,
    businessAssociateEnabledEvent,
    businessAssociateServiceOpenedEvent,
    businessAssociateServiceDisabledEvent,
    businessAssociateServiceEnabledEvent,
    businessAssociateConfigChangeEvent,
    businessAssociateServiceConfigChangeEvent,
    businessAssociateServicesOfListChanged,
  };
};
