import { ListDivider } from "@/components/ListDivider";
import isHotkey from "is-hotkey";
import { useCallback, useMemo } from "react";
import {
  AiOutlineAlignCenter,
  AiOutlineAlignLeft,
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineUnderline,
} from "react-icons/ai";
import { BsCode, BsJustify } from "react-icons/bs";
import { MdOutlineFormatListNumbered } from "react-icons/md";
import {
  PiListBulletsFill,
  PiNumberSquareOneFill,
  PiNumberSquareTwoFill,
} from "react-icons/pi";
import { Descendant, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { TEXT_EDITOR_HOTKEYS } from "../config/textEditorConfig";
import { useTextEditorActions } from "../hooks/useTextEditorActions";
import { textEditMock } from "../mocks/textEditMock";
import { IFrameTextEditor } from "./IFrameTextEditor";
import { TextEditBlockButton } from "./TextEditBlockButton";
import { TextEditorElement } from "./TextEditorElement";
import { TextEditorFiles } from "./TextEditorFiles";
import { TextEditorLeaf } from "./TextEditorLeaf";
import { TextEditorMarkButton } from "./TextEditorMarkButton";
import { TextEditToolbar } from "./TextEditorToolbar";

interface TextEditorProps {
  files: File[];
  onContentChange: (newContent: Descendant[]) => void;
  onFilesChange: (newFiles: File[]) => void;
  onRemoveFile: (fileNameToRemove: string) => void;
}

export function TextEditor({
  files,
  onContentChange,
  onFilesChange,
  onRemoveFile,
}: TextEditorProps) {
  const renderElement = useCallback(
    (props) => <TextEditorElement {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <TextEditorLeaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const handleBlur = useCallback(() => ReactEditor.deselect(editor), [editor]);

  const { toggleMark } = useTextEditorActions();

  return (
    <Slate
      editor={editor}
      initialValue={textEditMock}
      onChange={(e) => onContentChange(e)}
    >
      <TextEditToolbar>
        <TextEditorMarkButton format="bold" icon={<AiOutlineBold />} />
        <TextEditorMarkButton format="italic" icon={<AiOutlineItalic />} />
        <TextEditorMarkButton
          format="underline"
          icon={<AiOutlineUnderline />}
        />
        <TextEditorMarkButton format="code" icon={<BsCode />} />
        <TextEditBlockButton
          format="heading-one"
          icon={<PiNumberSquareOneFill />}
        />
        <TextEditBlockButton
          format="heading-two"
          icon={<PiNumberSquareTwoFill />}
        />
        <TextEditBlockButton
          format="numbered-list"
          icon={<MdOutlineFormatListNumbered />}
        />
        <TextEditBlockButton
          format="bulleted-list"
          icon={<PiListBulletsFill />}
        />
        <TextEditBlockButton format="left" icon={<AiOutlineAlignLeft />} />
        <TextEditBlockButton format="center" icon={<AiOutlineAlignCenter />} />
        <TextEditBlockButton format="right" icon={<AiOutlineAlignLeft />} />
        <TextEditBlockButton format="justify" icon={<BsJustify />} />
      </TextEditToolbar>

      <TextEditorFiles
        title="Anexos: "
        files={files}
        onFilesChange={onFilesChange}
        onRemoveFile={onRemoveFile}
      />

      <ListDivider />

      <IFrameTextEditor onBlur={handleBlur}>
        <Editable
          // disableDefaultStyles
          style={{
            border: "none",
            outline: "none",
            overflowX: "hidden",
            width: "100%",
            height: "100%",
          }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Insira aqui seu texto"
          renderPlaceholder={({ children, attributes }) => (
            <div {...attributes}>
              <p>{children}</p>
            </div>
          )}
          spellCheck
          onKeyDown={(event) => {
            for (const hotkey in TEXT_EDITOR_HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault();
                const mark = TEXT_EDITOR_HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </IFrameTextEditor>
    </Slate>
  );
}
