import { Flex } from "@gogeo-io/ui-library";
import { ReactNode } from "react";

interface TextEditToolbarProps {
  children: ReactNode;
}

export function TextEditToolbar({ children }: TextEditToolbarProps) {
  return (
    <Flex
      css={{
        padding: "$2",
        display: "flex",
        alignItems: "center",
        gap: "$2",
      }}
    >
      {children}
    </Flex>
  );
}
