import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function UsersTableSkeletonRow() {
  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <RLSSkeleton width={50} height={50} />
      <RLSSkeleton width={100} height={15} />
      <RLSSkeleton width={100} height={15} />
      <RLSSkeleton width={100} height={15} />
      <RLSSkeleton width={120} height={25} />
      <RLSSkeleton width={25} height={25} />
    </Flex>
  );
}
