import { TrackingEvents } from "@/config/mixpanel";
import { selectUser } from "@/core/store/users/usersSlice";
import { ProfileInfo } from "@/features/attendance/components/ProfileInfo";
import { useMixpanel } from "@/hooks/useMixpanel";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  IconButton,
  Text,
} from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { UpdtateUserInfoProfile } from "../components/UpdtateUserInfoProfile";
import { UserProfileAvatar } from "../components/UserProfileAvatar";
import { UserProfileHeaderToPage } from "../components/UserProfileHeaderToPage";
import { useBreadcrumbsForUserProfileInfoPage } from "../hooks/useBreadcrumbsForUserProfileInfoPage";

export function UserProfileInfoPage() {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const user = useSelector(selectUser);

  const { showBreadcrumbs } = useBreadcrumbsForUserProfileInfoPage();
  const { pageViewed } = useMixpanel();

  const closeDialog = () => setDialogIsOpen(false);

  useEffect(() => {
    showBreadcrumbs();
    pageViewed(TrackingEvents.PROFILE_USER_INFO_PAGE);
  }, []);

  return (
    <Flex css={{ flexDirection: "column", gap: "$5" }}>
      <UserProfileHeaderToPage
        title="Minhas Informações"
        description="As informações do seu perfil, você encontra aqui."
      />

      <Flex
        css={{
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <UserProfileAvatar />

        <Dialog open={dialogIsOpen} onOpenChange={(v) => setDialogIsOpen(v)}>
          <DialogTrigger asChild>
            <Flex
              css={{
                color: "$primary500",
                width: "fit-content",
                alignItems: "center",
                gap: "$1",
                cursor: "pointer",
              }}
            >
              <FaUserEdit color="inherit" />
              <Text
                css={{
                  color: "inherit",
                }}
              >
                Editar minhas informações
              </Text>
            </Flex>
          </DialogTrigger>
          <DialogContent>
            <DialogClose asChild>
              <IconButton
                type="ghost"
                css={{ position: "absolute", top: "1rem", right: "1rem" }}
              >
                <MdClose />
              </IconButton>
            </DialogClose>

            <DialogTitle>Editar Usuário</DialogTitle>
            <UpdtateUserInfoProfile onCloseDialog={closeDialog} />
          </DialogContent>
        </Dialog>

        {!isEmpty(user.name) && !isUndefined(user.name) ? (
          <ProfileInfo label="Nome" value={user.name} />
        ) : (
          <ProfileInfo label="Nome" value="Não consta" />
        )}

        {!isEmpty(user.salesman_id) && !isUndefined(user.salesman_id) ? (
          <ProfileInfo label="Código RCA" value={user.salesman_id} />
        ) : (
          <ProfileInfo label="Código RCA" value="Não consta" />
        )}

        {!isEmpty(user.account_name) && !isUndefined(user.account_name) ? (
          <ProfileInfo label="Conta" value={user.account_name} />
        ) : (
          <ProfileInfo label="Conta" value="Não consta" />
        )}

        {!isEmpty(user.email) && !isUndefined(user.email) ? (
          <ProfileInfo
            asOuterLink
            label="Email"
            value={user.email}
            href={user.email}
          />
        ) : (
          <ProfileInfo label="Email" value="Não consta" />
        )}

        {!isEmpty(user.phone) && !isUndefined(user.phone) ? (
          <ProfileInfo label="Telefone" value={user.phone} />
        ) : (
          <ProfileInfo label="Telefone" value="Não consta" />
        )}
      </Flex>
    </Flex>
  );
}
