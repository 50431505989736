import {
  BsFileEarmark,
  BsFiletypeCsv,
  BsFiletypeDoc,
  BsFiletypeDocx,
  BsFiletypeGif,
  BsFiletypeJpg,
  BsFiletypeMp3,
  BsFiletypeMp4,
  BsFiletypePdf,
  BsFiletypePng,
  BsFiletypeSvg,
  BsFiletypeTxt,
  BsFiletypeXls,
  BsFiletypeXlsx,
} from "react-icons/bs";

export const useAttachmentIcon = () => {
  return (type: string) => {
    const fileExtension = type.split(/\.(?=[^\.]+$)/);

    if (fileExtension.includes("txt")) {
      return <BsFiletypeTxt fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("pdf")) {
      return <BsFiletypePdf fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("xlsx")) {
      return <BsFiletypeXlsx fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("xls")) {
      return <BsFiletypeXls fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("csv")) {
      return <BsFiletypeCsv fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("mp3")) {
      return <BsFiletypeMp3 fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("mp4")) {
      return <BsFiletypeMp4 fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("svg")) {
      return <BsFiletypeSvg fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("png")) {
      return <BsFiletypePng fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("jpg") || type.includes("jpeg")) {
      return <BsFiletypeJpg fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("doc")) {
      return <BsFiletypeDoc fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("docx")) {
      return <BsFiletypeDocx fontSize="inherit" color="inherit" />;
    } else if (fileExtension.includes("gif")) {
      return <BsFiletypeGif fontSize="inherit" color="inherit" />;
    } else {
      return <BsFileEarmark fontSize="inherit" color="inherit" />;
    }
  };
};
