import styled from "styled-components";

export const ListIsItemFixed = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  svg {
    color: ${({ theme }) => theme.colors.primary600};
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
  }
`;
