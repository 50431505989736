import { listIndicatorsReducer } from "@/features/indicators/store/listIndicatosSlice";
import { supportReducer } from "@/features/support";
import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import { clientFiltersReducer } from "../../features/attendance/store/clientAdvancedFiltersSlice";
import { emitEventToChangeClientsFiltersReducer } from "../../features/attendance/store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClientsReducer } from "../../features/attendance/store/emitEventToChangeClientsSlice";
import { feedbackMappingReducer } from "../../features/attendance/store/feedbackMappingSlice";
import { feedbackReducer } from "../../features/attendance/store/feedbackSlice";
import { funnelsReducer } from "../../features/attendance/store/funnelsSlice";
import { listsOfTagsReducer } from "../../features/attendance/store/listOfTagsSlice";
import { listSelectedReducer } from "../../features/attendance/store/listSelectedSlice";
import { selectedClientsTypeOfViewReducer } from "../../features/attendance/store/selectedClientsTypeOfViewSlice";
import { selectedSearchFiltersReducer } from "../../features/attendance/store/selectedSearchFiltersSlice";
import { businessAssociateServicesReducer } from "../../features/businessAssociate/store/businessAssociateServicesSlice";
import { businessAssociatesServicesConfigReducer } from "../../features/businessAssociate/store/businessAssociatesServicesConfigSlice";
import { businessAssociatesReducer } from "../../features/businessAssociate/store/businessAssociatesSlice";
import { clientAttrOptsReducer } from "../../features/clientInfo/store/clientAttrOptsSlice";
import { clientColumnsToSortReducer } from "../../features/clientInfo/store/clientColumnsToSortSlice";
import { clientReducer } from "../../features/clientInfo/store/clientSlice";
import { matchTypesReducer } from "../../features/clientInfo/store/matchTypesSlice";
import { clientsReducer } from "../../features/clients/store/clientsSlice";
import { historyFiltersReducer } from "../../features/history/store/historyFiltersSlice";
import { historyReducer } from "../../features/history/store/historySlice";
import { indicatorsReducer } from "../../features/indicators/store/indicatorsSlice";
import { notificationsReducer } from "../../features/notifications/store/notificationsSlice";
import { clientSelectedToChangeParamsReducer } from "../../features/params/store/clientSelectedToChangeParamsSlice";
import { clientsToChangeParamsCountReducer } from "../../features/params/store/clientsToChangeParamsCountSlice";
import { paramsReducer } from "../../features/params/store/paramsSlice";
import { productsListTypeReducer } from "../../features/productsSuggestion/store/productsListTypeSlice";
import { productsSugestionReducer } from "../../features/productsSuggestion/store/productsSuggestionSlice";
import { accountReducer } from "../../features/profile/store/accountSlice";
import { attendanceJustificationsActionsReducer } from "../../features/profile/store/attendanceJustificationsActionsSlice";
import { attendanceJustificationsReducer } from "../../features/profile/store/attendanceJustificationsSlice";
import { businessGoalsReducer } from "../../features/profile/store/businessGoalsSlice";
import { clientNotesReducer } from "../../features/profile/store/clientNotesSlice";
import { preDefinesMessagesReducer } from "../../features/profile/store/preDefinedMessagesSlice";
import { preDefinedMessageTokenReducer } from "../../features/profile/store/preDefinedMessagesTokensSlice";
import { reminderReducer } from "../../features/reminder/store/reminderSlice";
import { reportReducer } from "../../features/reports/store/reportSlice";
import { reportsReducer } from "../../features/reports/store/reportsSlice";
import { ruptureRuleReducer } from "../../features/ruptureRules/store/ruptureRuleSlice";
import { emitEventToChangeUsersReducer } from "../../features/users/store/emitEventToChangeUsersSlice";
import { userFiltersReducer } from "../../features/users/store/usersAdvancedFiltersSlice";
import { userPermissionsReducer } from "../../features/usersManagment/store/userPermissionsSlice";
import { breadcrumbsReducer } from "./breadcrumbs/breadcrumbsSlice";
import { error401Reducer, setError401State } from "./error401slice";
import { listNameSelectedToChangeReducer } from "./listNameSelectedToChange";
import { loginReducer } from "./login/loginSlice";
import { uploadFileReducer } from "./uploadFile/uploadFileSlice";
import { usersReducer } from "./users/usersSlice";

const authMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      if (action.payload && action.payload.status === 401) {
        dispatch(setError401State(true));
      }
    }
    return next(action);
  };

export const store = configureStore({
  reducer: {
    reportsState: reportsReducer,
    ruptureRuleState: ruptureRuleReducer,
    userState: usersReducer,
    reportState: reportReducer,
    indicatorsState: indicatorsReducer,
    listOfIndicatorsState: listIndicatorsReducer,
    clientState: clientReducer,
    clientsState: clientsReducer,
    historyState: historyReducer,
    historyFiltersState: historyFiltersReducer,
    productsSugestionState: productsSugestionReducer,
    productsListTypeState: productsListTypeReducer,
    listsOfTagsState: listsOfTagsReducer,
    emitEventToChangeUsersState: emitEventToChangeUsersReducer,
    feedbackMappingState: feedbackMappingReducer,
    businessAssociateServicesState: businessAssociateServicesReducer,
    businessAssociatesState: businessAssociatesReducer,
    businessAssociatesServicesConfigState:
      businessAssociatesServicesConfigReducer,
    selectedClientsTypeOfViewState: selectedClientsTypeOfViewReducer,
    clientFiltersState: clientFiltersReducer,
    feedbackState: feedbackReducer,
    listSelectedState: listSelectedReducer,
    clientSelectedToChangeParamsState: clientSelectedToChangeParamsReducer,
    paramsState: paramsReducer,
    notificationsState: notificationsReducer,
    reminderState: reminderReducer,
    supportState: supportReducer,
    loginState: loginReducer,
    uploadFileState: uploadFileReducer,
    emitEventToChangeClientsState: emitEventToChangeClientsReducer,
    emitEventToChangeClientsFiltersState:
      emitEventToChangeClientsFiltersReducer,
    listNameSelectedToChangeState: listNameSelectedToChangeReducer,
    error401ReducerState: error401Reducer,
    preDefinedMessagesState: preDefinesMessagesReducer,
    attendanceJustificationsState: attendanceJustificationsReducer,
    attendanceJustificationsActionsState:
      attendanceJustificationsActionsReducer,
    preDefinedMessageTokensState: preDefinedMessageTokenReducer,
    selectedSearchFiltersState: selectedSearchFiltersReducer,
    clientAttrOptsState: clientAttrOptsReducer,
    clientColumnsToSortState: clientColumnsToSortReducer,
    matchTypesState: matchTypesReducer,
    clientsToChangeParamsCountState: clientsToChangeParamsCountReducer,
    breadcrumbsState: breadcrumbsReducer,
    businessGoalsState: businessGoalsReducer,
    clientNotes: clientNotesReducer,
    accountState: accountReducer,
    funnelsState: funnelsReducer,
    userPermissionsState: userPermissionsReducer,
    userFiltersState: userFiltersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(authMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
