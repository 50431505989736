import { TextArea } from "@/components/TextArea";
import { ListOfTags } from "@/core/models/listOfTags.model";
import { User } from "@/core/models/user.model";
import { selectUser } from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Box,
  Button,
  Caption,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  IconButton,
  Input,
  Text,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useShareListMixpanel } from "../../hooks/useShareListMixpanel";
import { ShareListBodyProps, shareList } from "../../store/listOfTagsSlice";

interface AddInfoToShareListDialogProps {
  title: string;
  shareUrl: string;
  description: string;
  list: ListOfTags;
  infoToSharedListDialogIsOpen: boolean;
  selectedUsersToShare: User[];
  onTitleChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
  onSetInfoToSharedListDialog: (value: boolean) => void;
  onSetShareDialogIsOpen: (value: boolean) => void;
  onRemoveSelectedUserToShare: (user: User) => void;
}

export function AddInfoToShareListDialog({
  title,
  shareUrl,
  list,
  description,
  infoToSharedListDialogIsOpen,
  selectedUsersToShare,
  onTitleChange,
  onDescriptionChange,
  onSetInfoToSharedListDialog,
  onSetShareDialogIsOpen,
  onRemoveSelectedUserToShare,
}: AddInfoToShareListDialogProps) {
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);

  const dispatch = useAppDispatch();
  const { shareListEvent } = useShareListMixpanel();

  const handleShareList = async () => {
    setIsLoading(true);

    if (title === "") {
      setIsLoading(false);
      toast.error("Titúlo não pode ser vazio");
      return;
    }

    if (description === "") {
      setIsLoading(false);
      toast.error("A descrição não pode ser vazia");
      return;
    }

    if (selectedUsersToShare.length <= 0) {
      setIsLoading(false);
      toast.error("Selecione ao menos um usuário para compartilhar");
      return;
    }

    const body: ShareListBodyProps = {
      name: title,
      message: description,
      url: shareUrl,
      targets: {
        companies: [
          {
            account_id: user.account_id,
            user_event: {
              user_ids: selectedUsersToShare.map((user) => user.id),
            },
          },
        ],
      },
    };

    await dispatch(shareList(body)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        shareListEvent(list, body);
        toast.success("Evento compartilhado com sucesso");
        onSetInfoToSharedListDialog(false);
        onSetShareDialogIsOpen(false);
      }

      setIsLoading(false);
    });
  };

  useEffect(() => {
    onTitleChange(list.name);
  }, []);

  return (
    <>
      <Dialog
        open={infoToSharedListDialogIsOpen}
        onOpenChange={(e) => onSetInfoToSharedListDialog(e)}
      >
        <DialogTrigger />
        <DialogContent
          style={{ width: "550px", maxHeight: "90vh" }}
          onClick={(event) => event.stopPropagation()}
        >
          <DialogTitle>Adicionar informações</DialogTitle>

          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
              onClick={(event) => event.stopPropagation()}
            >
              <MdClose />
            </IconButton>
          </DialogClose>

          <Flex css={{ flexDirection: "column", gap: "$4", mt: "$2" }}>
            <Flex css={{ flexDirection: "column", gap: "$1" }}>
              <Caption
                css={{
                  display: "inline-flex",
                }}
              >
                Nome <Box css={{ color: "$red500" }}>&nbsp;*</Box>
              </Caption>
              <Input
                placeholder="Ex.: Clientes sem compra há mais de 6 meses"
                value={title}
                onChange={(e) => onTitleChange(e.target.value)}
              />
            </Flex>

            <Flex css={{ flexDirection: "column", gap: "$1" }}>
              <Caption
                css={{
                  display: "inline-flex",
                }}
              >
                Descrição <Box css={{ color: "$red500" }}>&nbsp;*</Box>
              </Caption>
              <TextArea
                placeholder="Ex.: Verifique os clientes dessa lista aqui no goVendas."
                value={description}
                onChange={(e) => onDescriptionChange(e.target.value)}
              />
            </Flex>

            <Flex css={{ flexDirection: "column", gap: "$1" }}>
              <Caption style={{ display: "inline-flex" }}>
                Usuários selecionados
                <Box css={{ color: "$red500" }}>&nbsp;*</Box>
              </Caption>

              <Flex css={{ alignItems: "center", flexWrap: "wrap", gap: "$2" }}>
                {selectedUsersToShare.length > 0 ? (
                  <>
                    {selectedUsersToShare.map((user) => {
                      return (
                        <UserSelected key={user.id}>
                          <Caption css={{ color: "$gray600" }}>
                            {user.name}
                          </Caption>
                          <MdClose
                            onClick={() => onRemoveSelectedUserToShare(user)}
                          />
                        </UserSelected>
                      );
                    })}
                  </>
                ) : (
                  <Text>Sem usuários selecionados</Text>
                )}
              </Flex>
            </Flex>

            <Flex
              css={{
                flexDirection: "flex-end",
                justifyContent: "flex-end",
                gap: "$2",
              }}
            >
              <DialogClose asChild>
                <Button bordered>Fechar</Button>
              </DialogClose>

              <Button isLoading={isLoading} onClick={handleShareList}>
                Enviar cópia do filtro
              </Button>
            </Flex>
          </Flex>
        </DialogContent>
      </Dialog>
    </>
  );
}

const UserSelected = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[1]} 0.5rem;
  border-radius: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.gray100};
  cursor: pointer;
  color: ${(props) => props.theme.colors.gray500};

  svg {
    color: inherit;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;
