import {
  PreDefinedMessage,
  PreDefinedMessageSubject,
} from "@/core/models/preDefinedMessage.model";
import { selectUser } from "@/core/store/users/usersSlice";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { MessageCell } from "../components/CustomCells/MessageCell";
import { PreDefinedMessageCreatedBy } from "../components/CustomCells/PreDefinedMessageCreatedBy";
import { PreDefinedMessagesActionsCell } from "../components/CustomCells/PreDefinedMessagesActionsCell";
import { SharedPreDefinedMessageCell } from "../components/CustomCells/SharedPreDefinedMessageCell";
import { SubjectMessageCell } from "../components/CustomCells/SubjectMessageCell";
import { TitleCell } from "../components/CustomCells/TitleCell";

export const usePreDefinedMessagesColumns = (
  onDeletePreDefinedMessage: (id: number) => void,
  onUpdateSharedPreDefinedMessage: (id: number, shared: boolean) => void,
  onUpdatePreDefinedMessage: (
    id: number,
    subject: PreDefinedMessageSubject,
    title: string,
    message: string,
    shared: boolean
  ) => Promise<void>
) => {
  const user = useSelector(selectUser);

  const columns = useMemo(() => {
    return [
      {
        title: "Sujeito",
        dataIndex: "subject",
        key: "subject",
        render: (text) => <SubjectMessageCell value={text} />,
      },
      {
        title: "Título",
        dataIndex: "title",
        key: "title",
        render: (text) => <TitleCell value={text} />,
      },
      {
        title: "Mensagem",
        dataIndex: "message",
        key: "message",
        render: (text) => <MessageCell value={text} />,
      },
      {
        title: "Status",
        dataIndex: "shared",
        key: "shared",
        render: (value) => (
          <SharedPreDefinedMessageCell value={Boolean(value)} />
        ),
      },
      {
        title: "Criada por",
        dataIndex: "created_by",
        key: "created_by",
        render: (value) => <PreDefinedMessageCreatedBy created_by={value} />,
        width: "10%",
      },
      {
        title: "Ações",
        dataIndex: "message",
        key: "message",
        render: (_, record: PreDefinedMessage) => {
          return (
            <>
              {record.created_by.id === user.id && (
                <PreDefinedMessagesActionsCell
                  preDefinedMessage={record}
                  onDeletePreDefinedMessage={onDeletePreDefinedMessage}
                  onUpdatePreDefinedMessage={onUpdatePreDefinedMessage}
                  onUpdateSharedPreDefinedMessage={
                    onUpdateSharedPreDefinedMessage
                  }
                />
              )}
            </>
          );
        },
      },
    ];
  }, []);

  return columns;
};
