import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function UsersToCreateSkeleton() {
  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <RLSSkeleton width={420} height={22} borderRadius={4} />
      <RLSSkeleton width={530} height={20} borderRadius={4} />

      <Flex css={{ gap: "$4" }}>
        <RLSSkeleton width={270} height={48} borderRadius={4} />
        <RLSSkeleton width={24} height={24} borderRadius={4} />
        <RLSSkeleton width={120} height={48} borderRadius={4} />
        <RLSSkeleton width={24} height={24} borderRadius={4} />
        <RLSSkeleton width={98} height={36} borderRadius={4} />
      </Flex>

      <Flex css={{ gap: "$4" }}>
        <RLSSkeleton width={270} height={48} borderRadius={4} />
        <RLSSkeleton width={24} height={24} borderRadius={4} />
        <RLSSkeleton width={120} height={48} borderRadius={4} />
        <RLSSkeleton width={24} height={24} borderRadius={4} />
        <RLSSkeleton width={98} height={36} borderRadius={4} />
      </Flex>

      <Flex css={{ gap: "$4" }}>
        <RLSSkeleton width={270} height={48} borderRadius={4} />
        <RLSSkeleton width={24} height={24} borderRadius={4} />
        <RLSSkeleton width={120} height={48} borderRadius={4} />
        <RLSSkeleton width={24} height={24} borderRadius={4} />
        <RLSSkeleton width={98} height={36} borderRadius={4} />
      </Flex>

      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <RLSSkeleton width={140} height={36} borderRadius={4} />
        <RLSSkeleton width={100} height={36} borderRadius={4} />
      </Flex>
    </Flex>
  );
}
