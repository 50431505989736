import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { BusinessGoal } from "@/core/models/business-goal.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useBusinessGoalsMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const createBusinessGoalEvent = (businessGoal: BusinessGoal) => {
    MixpanelTracking.getInstance().track(TrackingEvents.BUSINESS_GOAL_CREATED, {
      ...buildProps(),
      "ID do objetivo de negócio criado": businessGoal.id,
      "Objetivo de negócio criado": businessGoal,
    });
  };

  const editBusinessGoalEvent = (
    edited_business_goal_id: number,
    newBusinessGoal: BusinessGoal
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.BUSINESS_GOAL_UPDATED, {
      ...buildProps(),
      "ID do objetivo de negócio editado": edited_business_goal_id,
      "Objetivo de negócio editado": newBusinessGoal,
    });
  };

  const deletedBusinessGoalEvent = (
    deleted_business_goal_id: number,
    deletedBusinessGoal: BusinessGoal
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.BUSINESS_GOAL_DELETED, {
      ...buildProps(),
      "ID o objetivo de negócio deletedo": deleted_business_goal_id,
      "Objetivo de negócio deletada": deletedBusinessGoal,
    });
  };

  const getBusinessGoalsEvent = () => {
    MixpanelTracking.getInstance().track(TrackingEvents.BUSINESS_GOAL_GET, {
      ...buildProps(),
    });
  };

  return {
    createBusinessGoalEvent,
    editBusinessGoalEvent,
    deletedBusinessGoalEvent,
    getBusinessGoalsEvent,
  };
};
