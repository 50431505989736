import { EmptyContent } from "@/components/EmptyContent";
import { User } from "@/core/models/user.model";
import { Box } from "@gogeo-io/ui-library";
import { Table } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

interface UsersTableProps {
  columns: any;
  data: any;
  status: string;
}

export function UsersTable({ columns, data, status }: UsersTableProps) {
  const [users, setUsers] = useState(data);

  const generateRowKey = (record: User) => {
    return `${record.id}-${record.name}`;
  };

  useEffect(() => {
    setUsers(data);
  }, [data]);

  return (
    <>
      {!isEmpty(users) ? (
        <Box css={{ overflow: "auto" }}>
          <Table
            columns={columns}
            dataSource={users}
            loading={status === "loading"}
            pagination={false}
            rowKey={generateRowKey}
            sticky={{ offsetHeader: 0 }}
            scroll={{ x: "100%" }}
          />
        </Box>
      ) : (
        <>
          <EmptyContent size="medium">Nenhum usuário encontrado</EmptyContent>
        </>
      )}
    </>
  );
}
