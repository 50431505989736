import { Flex, Text } from "@gogeo-io/ui-library";
import { formatRelative } from "date-fns";
import { pt } from "date-fns/locale";
import { useMemo } from "react";

interface ReadReminderToastConfirmationProps {
  reminderDate: string;
  reminderText: string;
  reminderId: number;
}

export function ReadReminderToastConfirmation({
  reminderDate,
  reminderText,
}: ReadReminderToastConfirmationProps) {
  const dateParsed = useMemo(() => {
    return formatRelative(new Date(reminderDate), new Date(), {
      locale: pt,
    });
  }, [reminderDate]);

  return (
    <Flex css={{ flexDirection: "column" }}>
      <Text size="body1" css={{ color: "inherit" }}>
        {reminderText}
      </Text>
      <Text size="body2" css={{ color: "inherit" }}>
        {dateParsed}
      </Text>
    </Flex>
  );
}
