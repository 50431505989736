import { User } from "@/core/models/user.model";
import { selectSalesmansRelatedToUser } from "@/core/store/users/usersSlice";
import {
  Box,
  Caption,
  Flex,
  IconButton,
  Input,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import styled from "styled-components";

interface SelectSalesmanToHistoryOfParamsProps {
  salesmanToSeeHistoryOfParams: User;
  onSetSalesmanToSeeHistoryOfParams: (salesman: User) => void;
}

export function SelectSalesmanToHistoryOfParams({
  salesmanToSeeHistoryOfParams,
  onSetSalesmanToSeeHistoryOfParams,
}: SelectSalesmanToHistoryOfParamsProps) {
  const salesmansRelatedToUser = useSelector(selectSalesmansRelatedToUser);

  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [filteredSalesmans, setFilteredSalesmans] = useState(
    salesmansRelatedToUser
  );

  const handleInputChange = (value: string) => {
    const searchTerm = value.toLowerCase();
    const filteredSalesmen = salesmansRelatedToUser.filter((salesman) =>
      salesman.name.toLowerCase().includes(searchTerm)
    );
    setFilteredSalesmans(filteredSalesmen);
  };

  const handleSelectedSalesmanChange = async (salesman: User) => {
    const findedSalesman = salesmansRelatedToUser.find(
      (user) => user.id === salesman.id
    );
    onSetSalesmanToSeeHistoryOfParams(findedSalesman);

    setPopoverIsOpen(false);
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        justifyContent: "center",
        gap: "$1",
      }}
    >
      <Popover open={popoverIsOpen} onOpenChange={setPopoverIsOpen}>
        <PopoverTrigger style={{ background: "transparent" }}>
          <Flex css={{ flexDirection: "column", padding: "0 1rem" }}>
            <Caption css={{ cursor: "pointer", textAlign: "left" }}>
              Vendedor selecionado:
            </Caption>
            <SelectUserButton>
              <Text>{salesmanToSeeHistoryOfParams.name}</Text>
            </SelectUserButton>
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverClose asChild={true}>
            <IconButton
              type="ghost"
              css={{
                position: "absolute",
                top: "0.25rem",
                right: "0rem",
                cursor: "pointer",
              }}
            >
              <MdClose />
            </IconButton>
          </PopoverClose>

          <Flex css={{ flexDirection: "column", gap: "$4" }}>
            <Flex css={{ flexDirection: "column", gap: "$1" }}>
              <Caption>Procurar vendedor</Caption>
              <Input
                placeholder="Ex. Vendedor 4"
                onChange={(e) => handleInputChange(e.target.value)}
              />
            </Flex>
            <Box css={{ maxHeight: "500px", overflowY: "scroll" }}>
              <Flex css={{ flexDirection: "column", gap: "$2" }}>
                {filteredSalesmans.map((salesman) => {
                  return (
                    <SelectUser
                      key={salesman.id}
                      onClick={() => handleSelectedSalesmanChange(salesman)}
                    >
                      <Text>{salesman.name}</Text>
                    </SelectUser>
                  );
                })}
              </Flex>
            </Box>
          </Flex>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}

export const SelectUser = styled.div`
  padding: 0.75rem;
  cursor: pointer;
  border-radius: ${(props) => props.theme.sizes[1]};

  &:hover {
    background: ${(props) => props.theme.colors.gray100};
  }
`;

export const SelectUserButton = styled.div`
  all: unset;
  min-width: 130px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  font-size: 13px;
  line-height: 1rem;
  height: 32px;
  gap: 6px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.gray800};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 6px;

  &:hover {
    background: ${(props) => props.theme.colors.gray100};
  }
`;
