import { useEffect } from "react";

interface EmptyDateSelectedProps {
  filterId: number;
  onFilterValueChange: (filterId: number, newValue: string) => void;
}

export function EmptyDateSelected({
  filterId,
  onFilterValueChange,
}: EmptyDateSelectedProps) {
  useEffect(() => {
    onFilterValueChange(filterId, "");
  }, []);

  return null;
}
