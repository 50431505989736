import { AttendanceIndicator } from "./AttendanceIndicator";
import { AttendanceIndicatorsWrapper } from "./AttendanceIndicatorsWrapper";
import { AttendanceListItem } from "./AttendanceListItem";
import { ListIsBrandNew } from "./ListIsBrandNew";
import { ListIsGoVendasSuggested } from "./ListIsGoVendasSuggested";
import { ListIsItemFixed } from "./ListIsItemFixed";
import { RegisterAttendanceForm } from "./RegisterAttendanceForm";
import { RegisterAttendanceReminderForm } from "./RegisterAttendanceReminderForm";
import { TagsListItem } from "./TagsListItem";

export default {
  AttendanceListItem,
  AttendanceIndicatorsWrapper,
  AttendanceIndicator,
  RegisterAttendanceReminderForm,
  RegisterAttendanceForm,
  ListIsGoVendasSuggested,
  ListIsItemFixed,
  ListIsBrandNew,
  TagsListItem,
};
