import {
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { InfoParamsAccountPage } from "./InfoParamsAccountPage";
import { InfoParamsUserPage } from "./InfoParamsUserPage";

export function ProfileParamsPage() {
  const [selectedTab, setSelectedTab] = useState("your-params");

  return (
    <TabsRoot
      defaultValue={selectedTab}
      value={selectedTab}
      onValueChange={(v) => setSelectedTab(v)}
      css={{
        width: "100%",
        height: "100%",
        boxShadow: "none",
      }}
    >
      <TabsList aria-label="Manage your account">
        <TabsTrigger value="your-params">
          Meus parâmetros de atendimento
        </TabsTrigger>
        <TabsTrigger value="account-params">
          Parâmetros de atendimento da conta
        </TabsTrigger>
      </TabsList>
      <TabsContent value="your-params">
        <InfoParamsUserPage />
      </TabsContent>
      <TabsContent value="account-params">
        <InfoParamsAccountPage />
      </TabsContent>
    </TabsRoot>
  );
}
