import { gvTheme } from "@gogeo-io/ui-library";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface ProgressCircleProps {
  value: number;
}

export function ProgressCircle({ value }: ProgressCircleProps) {
  return (
    <CircularProgressbar
      value={value * 100}
      strokeWidth={50}
      styles={buildStyles({
        strokeLinecap: "butt",
        pathColor: gvTheme.colors.primary500,
        trailColor: gvTheme.colors.primary200,
      })}
    />
  );
}
