import { WeekPref } from "@/core/models/params.model";
import { selectClientSelectedToChangeParams } from "@/features/params/store/clientSelectedToChangeParamsSlice";
import { changeParams } from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { WeekRestrictionBeginAt } from "./WeekRestrictionBeginAt";
import { WeekRestrictionSelectOption } from "./WeekRestrictionSelectOption";
import { WeekRestrictionUnrestrictedSelected } from "./WeekRestrictionUnrestrictedSelected";
import { WeekRestrictionUntil } from "./WeekRestrictionUntil";

export function ChangeParamsFormWeekRestriction() {
  const [optionSelected, setOptionSelected] = useState("");
  const [valueSelected, setValueSelected] = useState("");

  const clients = useSelector(selectClientSelectedToChangeParams);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isEmpty(clients) && !isEmpty(clients[0].params.week_pref)) {
      setOptionSelected(clients[0].params.week_pref.range);
      setValueSelected(clients[0].params.week_pref.value);
    } else {
      setOptionSelected("Irrestrito");
      setValueSelected("S0");
    }
  }, [clients]);

  const handleOptionSelected = async (range: string) => {
    setOptionSelected(range);

    const week_pref: WeekPref = {
      range,
    };

    if (range !== "Irrestrito") {
      week_pref.value = valueSelected;
    }

    await dispatch(changeParams({ week_pref: week_pref }));
  };

  const handleValueSelected = async (value: string) => {
    setValueSelected(value);

    const week_pref: WeekPref = {
      range: optionSelected,
      value,
    };

    await dispatch(changeParams({ week_pref: week_pref }));
  };

  return (
    <Container>
      <WeekRestrictionSelectOption
        optionSelected={optionSelected}
        setOptionSelected={handleOptionSelected}
      />

      {optionSelected === "Irrestrito" && (
        <WeekRestrictionUnrestrictedSelected />
      )}

      {optionSelected === "A partir da" && (
        <WeekRestrictionBeginAt
          valueSelected={valueSelected}
          setValueSelected={handleValueSelected}
        />
      )}

      {optionSelected === "Até" && (
        <WeekRestrictionUntil
          valueSelected={valueSelected}
          setValueSelected={handleValueSelected}
        />
      )}
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[2]};
`;
