import { ProductsFilter } from "@/core/models/productsSugestion.model";
import { Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectProductsListType } from "../store/productsListTypeSlice";
import UI from "../ui";

interface ProductsFilterListTypeProps {
  filters: ProductsFilter;
  onFiltersChange: (filters: ProductsFilter) => void;
}

export function ProductsFilterListType({
  filters,
  onFiltersChange,
}: ProductsFilterListTypeProps) {
  const [listType, setListType] = useState(
    filters.list_types && filters.list_types.length > 0
      ? filters.list_types[0]
      : [0]
  );

  const productsListTypes = useSelector(selectProductsListType);

  const handleSetListType = (value: string) => {
    setListType(Number(value));
    onFiltersChange({
      ...filters,
      list_types: [Number(value)],
    });
  };

  return (
    <UI.ProductsFilterPrice>
      <Select
        defaultValue={String(listType)}
        value={String(listType)}
        ariaLabel="Select listType"
        onValueChange={(v) => handleSetListType(v)}
      >
        {productsListTypes.map((listType) => {
          return (
            <SelectItem
              key={listType.list_type}
              value={String(listType.list_type)}
            >
              <SelectItemText>{listType.list_name}</SelectItemText>
            </SelectItem>
          );
        })}
      </Select>
    </UI.ProductsFilterPrice>
  );
}
