import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { App } from "./App";
import { AppUnderMaintenance } from "./components/AppUnderMaintenance";
import { SplashScreen } from "./components/SplashScreen";
import { User } from "./core/models/user.model";
import { getUser, selectUserStatus } from "./core/store/users/usersSlice";
import {
  getAccount,
  selectAccountStatus,
} from "./features/profile/store/accountSlice";
import useAppDispatch from "./hooks/useAppDispatch";
import { useCheckIfUserIsInWorkingTime } from "./hooks/useCheckIfUserIsInWorkingTime";

const timezoneOffset = new Date().getTimezoneOffset() / -60;

export const defaultHeaders = {
  Accept: "application/json",
  "content-type": "application/json",
  "Accept-Language": "pt-BR",
  "Accept-Timezone": `UTC${
    timezoneOffset !== 0
      ? `${timezoneOffset > 0 ? `+` : ""}${timezoneOffset}`
      : ""
  }`,
};

export const MockMode: React.FC = () => {
  const userStatus = useSelector(selectUserStatus);
  const accountStatus = useSelector(selectAccountStatus);

  const [appIsNotWorking, _] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { checkIfUserIsInWorkingTime } = useCheckIfUserIsInWorkingTime();

  const loadUserInfo = async () => {
    axios.defaults.baseURL = import.meta.env.VITE_BASE_URL_STAGING;
    axios.defaults.headers = {
      ...defaultHeaders,
      Authorization: `Bearer token`,
    } as any;

    await dispatch(getUser()).then((obj) => {
      if (obj.payload.status === 403 || obj.payload.status === 404) {
        navigate("/logout");
      }

      const user: User = obj.payload as User;

      if (!checkIfUserIsInWorkingTime({ user })) {
        navigate("/user-is-not-in-working-time");
      }
    });

    await dispatch(getAccount()).then((obj) => {
      if (obj.payload.status === 403 || obj.payload.status === 404) {
        navigate("/logout");
      }
    });
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  if (appIsNotWorking) {
    return <AppUnderMaintenance />;
  }

  return userStatus !== "success" && accountStatus !== "success" ? (
    <SplashScreen />
  ) : (
    <App tokenIsExpiring={false} tokenHasExpired={false} />
  );
};
