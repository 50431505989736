import { useFormatDate } from "@/hooks/useFormatDate";
import { isEmpty } from "lodash";

interface TimelineHeaderInfoTimestampProps {
  feedback_timestamp: string;
}

export function TimelineHeaderInfoTimestampOfParams({
  feedback_timestamp,
}: TimelineHeaderInfoTimestampProps) {
  const {
    formatHourAndMinutes,
    formatBasicDateWithSlash,
    newDateBasedOnDateWithoutTimezone,
  } = useFormatDate();

  return (
    <>
      {!isEmpty(feedback_timestamp) ? (
        <strong>
          <>
            {formatBasicDateWithSlash(
              newDateBasedOnDateWithoutTimezone(feedback_timestamp)
            )}
            &nbsp;às&nbsp;
            {formatHourAndMinutes(new Date(feedback_timestamp))}
          </>
        </strong>
      ) : (
        <strong>–—/–—/–—–—&nbsp;|&nbsp;–—:–—&nbsp;|&nbsp;</strong>
      )}
    </>
  );
}
