import { ListDivider } from "@/components/ListDivider";
import { selectUser } from "@/core/store/users/usersSlice";
import { UserPermissionsSkeleton } from "@/features/users/components/skeleton/UserPermissionsSkeleton";
import {
  getUserPermissions,
  selectUserPermissions,
  selectUserPermissionsStatus,
} from "@/features/usersManagment/store/userPermissionsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Caption, Flex, Text } from "@gogeo-io/ui-library";
import { useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import { UserProfileHeaderToPage } from "../components/UserProfileHeaderToPage";
import { useBreadcrumbsForPermissionsListPage } from "../hooks/useBreadcrumbsForPermissionsListPage";
import { useProfileMixpanel } from "../hooks/useProfileMixpanel";

export function UserPermissionsListPage() {
  const user = useSelector(selectUser);
  const userPermissionsStatus = useSelector(selectUserPermissionsStatus);
  const userPermissions = useSelector(selectUserPermissions);

  const dispatch = useAppDispatch();

  const { showBreadcrumbs } = useBreadcrumbsForPermissionsListPage();
  const { userListPermissionsEvent } = useProfileMixpanel();

  const loadUserPermissions = async () => {
    await dispatch(getUserPermissions({})).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        userListPermissionsEvent(res.payload);
      }
    });
  };

  useEffect(() => {
    showBreadcrumbs();
    loadUserPermissions();
  }, []);

  return (
    <Flex css={{ flexDirection: "column", gap: "$5" }}>
      <UserProfileHeaderToPage
        title="Permissões"
        description="Suas permissões ficam disponíveis aqui para você visualizar"
      />

      <Flex
        css={{
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {userPermissionsStatus === "loading" ? (
          <UserPermissionsSkeleton />
        ) : (
          <Flex css={{ flexDirection: "column", gap: "$2" }}>
            {userPermissions.map((permission, idx) => {
              return (
                <Flex
                  css={{ flexDirection: "column", gap: "$3" }}
                  key={permission.property}
                >
                  <Flex css={{ flexDirection: "column", gap: "$2" }}>
                    <Flex css={{ flexDirection: "column", gap: "0px" }}>
                      <Text size="body1" css={{ color: "$gray900" }}>
                        {permission.title}
                      </Text>
                      <Caption>{permission.description}</Caption>
                    </Flex>
                    {permission.value ? (
                      <Flex
                        css={{
                          gap: "$2",
                          alignItems: "center",
                          color: "$primary500",
                        }}
                      >
                        <FaCheckCircle color="inherit" size={18} />
                        <Caption css={{ color: "$gray500" }}>
                          Habilitado
                        </Caption>
                      </Flex>
                    ) : (
                      <Flex
                        css={{
                          gap: "$2",
                          alignItems: "center",
                          color: "$red500",
                        }}
                      >
                        <IoCloseCircle color="inherit" size={22} />
                        <Caption css={{ color: "$gray500" }}>
                          Desabilitado
                        </Caption>
                      </Flex>
                    )}
                  </Flex>
                  {idx !== userPermissions.length - 1 && <ListDivider />}
                </Flex>
              );
            })}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
