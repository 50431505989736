import { PriceRange } from "@/components/PriceRange";
import { useFormatPrice } from "@/hooks/useFormatPrice";
import { Button, Flex } from "@gogeo-io/ui-library";
import { ReactNode, useState } from "react";
import { GenericFilterPopover } from "../GenericFilterPopover";

interface GenericCurrencyFilterProps {
  children?: ReactNode;
  hasError?: boolean;
  filterId: number;
  value: string;
  onUpdatedFilter: (filterId: number, newValue: string) => void;
}

interface FilterToChange {
  filterId: number;
  value: string;
}

export function GenericCurrencyFilter({
  children,
  hasError = false,
  filterId,
  value,
  onUpdatedFilter,
}: GenericCurrencyFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [filterChanged, setFilterChanged] = useState<FilterToChange>(
    {} as FilterToChange
  );

  const { getMinMaxFromValue } = useFormatPrice();

  const [minValue, setMinValue] = useState<number>(
    getMinMaxFromValue(value)?.min ?? 0
  );
  const [maxValue, setMaxValue] = useState<number>(
    getMinMaxFromValue(value)?.max ?? 0
  );

  const handleSetMinValue = (new_value: string) => {
    setMinValue(Number(new_value));

    const minMax = {
      min: new_value,
      max: maxValue,
    };

    const newValue = `${minMax.min}~${minMax.max}`;

    const filter: FilterToChange = { filterId, value: newValue };
    setFilterChanged(filter);
  };

  const handleSetMaxValue = (new_value: string) => {
    setMaxValue(Number(new_value));

    const minMax = {
      min: minValue,
      max: new_value,
    };

    const newValue = `${minMax.min}~${minMax.max}`;

    const filter: FilterToChange = { filterId, value: newValue };
    setFilterChanged(filter);
  };

  const handlePopoverOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const openPopover = () => {
    setIsOpen(true);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  const applyFilter = () => {
    onUpdatedFilter(filterChanged.filterId, filterChanged.value);
    setFilterChanged({} as FilterToChange);
    closePopover();
  };

  return (
    <GenericFilterPopover
      trigger={children}
      popoverIsOpen={hasError ? false : isOpen}
      onOpenPopover={!hasError && openPopover}
      onPopoverOpenChange={!hasError && handlePopoverOpenChange}
    >
      <Flex
        css={{
          flexDirection: "column",
          gap: "$4",
        }}
      >
        <PriceRange
          selectedMinValue={minValue}
          selectedMaxValue={maxValue}
          onSelectMinValue={handleSetMinValue}
          onSelectMaxValue={handleSetMaxValue}
        />
      </Flex>
      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <Button onClick={applyFilter}>Aplicar</Button>
      </Flex>
    </GenericFilterPopover>
  );
}
