import UnderConstructionSvg from "@/assets/under-construction.svg";
import { ContentWithImage } from "@/components/ContentWithImage";
import { ClientsSkeletonPage } from "@/components/Skeleton/ClientsSkeletonPage";
import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, gvTheme } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SearchClientsOnClientsListPage } from "../components/SearchClientsOnClientsListPage";
import { SelectedListHeader } from "../components/SelectedListHeader";
import { SelectedListHeaderWithFilters } from "../components/SelectedListHeaderWithFilters";
import {
  getFunnel,
  getFunnelCountOfClients,
  selectAvailableFunnels,
} from "../store/funnelsSlice";
import { selectSelectedList } from "../store/listSelectedSlice";
import { selectSelectedClientsTypeOfView } from "../store/selectedClientsTypeOfViewSlice";
import { ClientsTypeOfView } from "./ClientsListPage";
import { IndicatorsViewOfClients } from "./IndicatorsViewOfClients";
import { KanbanViewOfClients } from "./KanbanViewOfClients";
import { ListViewOfClients } from "./ListViewOfClients";

interface ClientsListContainerProps {
  onClientsTypeOfViewChange: (option: ClientsTypeOfView) => void;
}

export function ClientsListContainer({
  onClientsTypeOfViewChange,
}: ClientsListContainerProps) {
  const [funnelsStatus, setFunnelsStatus] = useState("loading");

  const dispatch = useAppDispatch();
  const { paramsOnEditListView } = useGetClientsByFiltersParams();

  const selectedList = useSelector(selectSelectedList);
  const selectedClientsTypeOfView = useSelector(
    selectSelectedClientsTypeOfView
  );
  const availableFunnels = useSelector(selectAvailableFunnels);

  useEffect(() => {
    setFunnelsStatus("loading");

    const params = paramsOnEditListView();

    const loadFunnel = async () => {
      await dispatch(getFunnel({ id: availableFunnels[0].id, params })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            await dispatch(
              getFunnelCountOfClients({ id: res.payload.id, params })
            );

            setFunnelsStatus("fulfilled");
          }
        }
      );
    };

    loadFunnel();
  }, []);

  const getComponent = (option: ClientsTypeOfView) => {
    if (option === "list") {
      return <ListViewOfClients />;
    } else if (option === "kanban") {
      return <KanbanViewOfClients />;
    } else if (option === "reports") {
      return <IndicatorsViewOfClients />;
    } else {
      return (
        <ContentWithImage size="large" image={UnderConstructionSvg}>
          Funcionalidade em construção.
        </ContentWithImage>
      );
    }
  };

  return (
    <Container>
      {funnelsStatus === "fulfilled" ? (
        <Flex
          css={{
            flexDirection: "column",
            height: "100%",
            gap: "$2",
          }}
        >
          {!isEmpty(selectedList) ? (
            <SelectedListHeader />
          ) : (
            <SelectedListHeaderWithFilters />
          )}
          <SearchClientsOnClientsListPage
            clientsTypeOfView={selectedClientsTypeOfView}
            onClientsTypeOfViewChange={onClientsTypeOfViewChange}
          />

          <Flex
            css={{
              flexDirection: "column",
              height: "100%",
              overflow: "auto",
              gap: "$2",
            }}
          >
            {getComponent(selectedClientsTypeOfView)}
          </Flex>
        </Flex>
      ) : (
        <ClientsSkeletonPage />
      )}
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${({ theme }) => theme.space[2]};

  // @media (max-width: ${gvTheme.mediaSizes.laptop}) {
  //   margin-right: 20px;
  // }
`;
