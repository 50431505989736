import {
  Button,
  Caption,
  DialogClose,
  Flex,
  Input,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedList } from "../../store/listSelectedSlice";
import { SelectedFilters } from "../SelectedFilters";

interface SaveAsProps {
  onSaveAsList: (list_name: string, description: string) => void;
}

export function SaveListDialog({ onSaveAsList }: SaveAsProps) {
  const selectedList = useSelector(selectSelectedList);

  const [newListName, setNewListName] = useState(selectedList.name);
  const [description, setDescription] = useState(selectedList.description);

  const handleSaveList = () => {
    onSaveAsList(newListName, description);
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$4", mt: "$2" }}>
      <Flex css={{ flexDirection: "column", gap: "$1" }}>
        <Caption style={{ display: "block" }}>Nome</Caption>
        <Input
          placeholder="Nome da segmentação"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
        />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$1" }}>
        <Caption style={{ display: "block" }}>Descrição</Caption>
        <Input
          placeholder="Descrição da segmentação"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$1" }}>
        <Caption style={{ display: "block" }}>Filtros Aplicados</Caption>
        <SelectedFilters canUpdateFilters={false} />
      </Flex>

      <Flex
        css={{
          gap: "$2",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          mt: "$4",
        }}
      >
        <DialogClose asChild>
          <Button bordered>Cancelar</Button>
        </DialogClose>
        <DialogClose>
          <Button onClick={handleSaveList}>Salvar Segmentação</Button>
        </DialogClose>
      </Flex>
    </Flex>
  );
}
