import styled from "styled-components";

export const Container = styled.div<{
  $isBlocked: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  height: 32px;
  pointer-events: ${({ $isBlocked }) => ($isBlocked ? "all" : "none")};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
`;

export const Day = styled.div`
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  padding: 0.175rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const Tip = styled.div`
  width: 13px;
  height: 3px;
  border-radius: 3px;
  font-weight: bold;
`;
