import { EmptyContent } from "@/components/EmptyContent";
import { ListDivider } from "@/components/ListDivider";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, Input } from "@gogeo-io/ui-library";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useClientNotesBreadcrumbs } from "../hooks/useClientNotesBreadcrumbs";
import { useClientNotesMixpanel } from "../hooks/useClientNotesMixpanel";
import {
  getClientNotes,
  selectClientNotes,
  selectClientNotesStatus,
} from "../store/clientNotesSlice";
import { ClientNotesSkeleton } from "./Skeleton/ClientNotesSkeleton";
import { ClientNotesList } from "./clientNotes/ClientNotesList";
import { ListNotesHeader } from "./clientNotes/ListNotesHeader";

export function ClientNotes() {
  const [selectedFilter, setSelectedFilter] = useState("inserted_at,desc");
  const [search, setSearch] = useState("");

  const dispatch = useAppDispatch();
  const { clientNotesBreadcrumbs } = useClientNotesBreadcrumbs();
  const { notesLoadedEvent } = useClientNotesMixpanel();

  const selectedClient = useSelector(selectClient);
  const clientNotesStatus = useSelector(selectClientNotesStatus);
  const clientNotes = useSelector(selectClientNotes);

  const handleSetSelectedFilter = (filter: string) => setSelectedFilter(filter);

  const handleInputChange = async (value: string) => {
    setSearch(value);

    await dispatch(
      getClientNotes({
        client_id: selectedClient.id,
        title: value,
        sort: selectedFilter,
      })
    );
  };

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  useEffect(() => {
    async function loadClientNotes() {
      await dispatch(
        getClientNotes({
          client_id: selectedClient.id,
          sort: selectedFilter,
          title: search,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          notesLoadedEvent(res.payload);
        }
      });
    }

    loadClientNotes();
    clientNotesBreadcrumbs();
  }, [selectedFilter, selectedClient]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        padding: "$4 0",
        gap: "$5",
        width: "100%",
        height: "100%",
      }}
    >
      <ListNotesHeader
        selectedFilter={selectedFilter}
        onSelectFilter={handleSetSelectedFilter}
      />

      <ListDivider />

      <Flex css={{ flexDirection: "column", gap: "$6" }}>
        <Flex css={{ alignItems: "center", justifyContent: "center" }}>
          <Input
            placeholder="Busque uma nota"
            preffix={<AiOutlineSearch />}
            style={{ width: "300px" }}
            onChange={(e) => debounceOnChangeInput(e.target.value)}
          />
        </Flex>

        {clientNotesStatus !== "success" ? (
          <ClientNotesSkeleton />
        ) : (
          <Flex
            css={{
              mt: "2",
              width: "100%",
              height: "100%",
            }}
          >
            {clientNotes.length === 0 ? (
              <EmptyContent>Você não tem notas para este cliente</EmptyContent>
            ) : (
              <ClientNotesList />
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
