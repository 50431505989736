import { TrackingEvents } from "@/config/mixpanel";
import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useMixpanel } from "@/hooks/useMixpanel";
import { PageLayout } from "@/layouts/PageLayout";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { NoNotificationSelected } from "../components/NoNotificationSelected";
import { NotificationsList } from "../components/NotificationsList";

export function NotificationsPage() {
  const { pageViewed } = useMixpanel();
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    pageViewed(TrackingEvents.NOTIFICATIONS_PAGE);

    async function loadBreadcrumbs() {
      const breadcrumbs: Breadcrumb[] = [
        { item: "Notificações", url: "/notifications" },
      ];
      await dispatch(setBreadcrumbs(breadcrumbs));
    }

    loadBreadcrumbs();
  }, []);

  return (
    <PageLayout
      leftSidebar={<NotificationsList />}
      content={
        pathname === "/notifications" ? <NoNotificationSelected /> : <Outlet />
      }
    />
  );
}
