import { Flex } from "@gogeo-io/ui-library";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";
import UI from "../ui";

interface HourProps {
  handleHourChange: (hour: number) => void;
  defaultHour: number;
}

export function ReminderPickHourInput({
  handleHourChange,
  defaultHour,
}: HourProps) {
  const [hour, setHour] = useState(defaultHour);

  const handleInputChange = (e: number) =>
    hour >= 0 && hour < 12 ? setHour(e) : setHour(0);

  const handleDecrementHour = () =>
    hour > 0 && hour < 12 ? setHour(hour - 1) : setHour(0);

  const handleIncrementHour = () =>
    hour >= 0 && hour < 12 ? setHour(hour + 1) : setHour(0);

  useEffect(() => {
    handleHourChange(hour);

    if (hour > 12) {
      setHour(0);
      handleHourChange(0);
    }
  }, [hour]);

  return (
    <UI.ReminderPickHourInput>
      <Flex onClick={handleIncrementHour}>
        <KeyboardArrowUpIcon />
      </Flex>
      <input
        value={hour}
        onChange={(e) => handleInputChange(Number(e.target.value))}
      />
      <Flex onClick={handleDecrementHour}>
        <KeyboardArrowDownIcon />
      </Flex>
    </UI.ReminderPickHourInput>
  );
}
