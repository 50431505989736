import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function ClientNotesSkeleton() {
  return (
    <Flex
      css={{
        mt: "$2",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        gap: "$6",
      }}
    >
      <Flex
        css={{
          flexWrap: "wrap",
          gap: "3.25rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <RLSSkeleton width={300} height={215} />
        <RLSSkeleton width={300} height={215} />
        <RLSSkeleton width={300} height={215} />
        <RLSSkeleton width={300} height={215} />
        <RLSSkeleton width={300} height={215} />
        <RLSSkeleton width={300} height={215} />
      </Flex>
    </Flex>
  );
}
