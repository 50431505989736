import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { ListOfTags } from "@/core/models/listOfTags.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";
import { ShareListBodyProps } from "../store/listOfTagsSlice";

export const useShareListMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const listCopedEvent = (selectedList: ListOfTags, url: string) => {
    MixpanelTracking.getInstance().track(TrackingEvents.SHARE_LIST_COPIED, {
      ...buildProps(),
      "Lista copiada": selectedList,
      URL: url,
    });
  };

  const shareListEvent = (
    selectedList: ListOfTags,
    body: ShareListBodyProps
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.SHARED_LIST, {
      ...buildProps(),
      "Lista compartilhada": selectedList,
      Informações: body,
    });
  };

  return {
    listCopedEvent,
    shareListEvent,
  };
};
