import { AverageTicketSkeleton } from "@/components/Skeleton/AverageTicketSkeleton";
import { ProfileSkeleton } from "@/components/Skeleton/ProfileSkeleton";
import { StatusSkeleton } from "@/components/Skeleton/StatusSkeleton";
import { TimeWithoutBuySkeleton } from "@/components/Skeleton/TimeWithoutBuySkeleton";
import styled from "styled-components";

export function ClientsTableSkeletonPage() {
  const timesToRepeat = Array.from(Array(11).keys());

  return (
    <Container>
      {timesToRepeat.map((t) => {
        return (
          <div className="row" key={t}>
            <ProfileSkeleton />

            <AverageTicketSkeleton />

            <TimeWithoutBuySkeleton />

            <StatusSkeleton />
          </div>
        );
      })}
    </Container>
  );
}

export const Container = styled.div`
  width: 100%;

  .row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 7rem;
  }
`;
