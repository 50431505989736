import { SelectedDateTypeOption } from "@/components/SelectedDateTypeOption";
import { BeforeDateSelect } from "@/components/advancedFilters/GenericDateFilter/BeforeDateSelect";
import { BetweenDateSelect } from "@/components/advancedFilters/GenericDateFilter/BetweenDateSelect";
import { EmptyDateSelected } from "@/components/advancedFilters/GenericDateFilter/EmptyDateSelect";
import { InThisDateSelect } from "@/components/advancedFilters/GenericDateFilter/InThisDateSelect";
import { InThisOneDateSelect } from "@/components/advancedFilters/GenericDateFilter/InThisOneDateSelect";
import { DynamicFormFields } from "@/core/models/business-associate.model";
import { useDateByDateFilter } from "@/hooks/useDateByDateField";
import { Flex } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { useState } from "react";
import { FieldError } from "react-hook-form";

interface BussinessAssociateGenericFormDateTypeProps {
  formField: DynamicFormFields;
  error: FieldError;
  onChange: (...event: any[]) => void;
}

export function BussinessAssociateGenericFormDateType({
  formField,
  error,
  onChange,
}: BussinessAssociateGenericFormDateTypeProps) {
  const { getValuesByDateFilter } = useDateByDateFilter();

  const dateFilter = getValuesByDateFilter(formField.value);
  const [optionSelected, setOptionSelected] = useState<string>(dateFilter.type);

  const handleOptionSelected = (newValue: string) => {
    setOptionSelected(newValue);
  };

  const handleFilterValueChange = (_, newValue: string) => {
    onChange(newValue);
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <Flex css={{ gap: "$2", alignItems: "center" }}>
        <SelectedDateTypeOption
          optionSelected={optionSelected}
          setOptionSelected={handleOptionSelected}
        />

        {optionSelected === "empty" && (
          <EmptyDateSelected
            filterId={1}
            onFilterValueChange={handleFilterValueChange}
          />
        )}

        {optionSelected === "before" && (
          <BeforeDateSelect
            value={formField.value}
            filterId={1}
            onFilterValueChange={handleFilterValueChange}
          />
        )}

        {optionSelected === "inthis1" && (
          <InThisOneDateSelect
            value={formField.value}
            filterId={1}
            onFilterValueChange={handleFilterValueChange}
          />
        )}

        {optionSelected === "inthis" && (
          <InThisDateSelect
            value={formField.value}
            filterId={1}
            onFilterValueChange={handleFilterValueChange}
          />
        )}

        {optionSelected === "between" && (
          <BetweenDateSelect
            value={formField.value}
            filterId={1}
            onFilterValueChange={handleFilterValueChange}
          />
        )}
      </Flex>

      {!!error && <G_UI.ErrorMessage>{error?.message}</G_UI.ErrorMessage>}
    </Flex>
  );
}
