import { SalesmanToRuptureRule } from "@/core/models/user.model";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  Text,
} from "@gogeo-io/ui-library";
import { ReactNode, useState } from "react";

interface RemoveUserFromRuptureRuleModalProps {
  children: ReactNode;
  user: SalesmanToRuptureRule;
  onRemoveUser: (user: SalesmanToRuptureRule) => void;
}

export function RemoveUserFromRuptureRuleModal({
  children,
  user,
  onRemoveUser,
}: RemoveUserFromRuptureRuleModalProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const closeDialog = () => setDialogIsOpen(false);

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <Flex
          css={{
            flexDirection: "column",
            gap: "$4",
            width: "500px",
            textAlign: "center",
          }}
        >
          <Heading size="title6">Remover vendedor</Heading>
          <Text>
            Você tem certeza que deseja remover esse vendedor? Ao remove-lo, ele
            irá parar de ser monitorado
          </Text>

          <Flex
            css={{
              alignItems: "flex-end",
              justifyContent: "flex-end",
              gap: "$3",
              mt: "$3",
            }}
          >
            <Button color="danger" variant="soft" onClick={closeDialog}>
              Cancelar
            </Button>
            <Button onClick={() => onRemoveUser(user)}>Remover Usuário</Button>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
