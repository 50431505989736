import styled from "styled-components";

export const PreDefinedMessageTextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  height: 220px;
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  resize: none;
  margin-left: 2px;
  transition: outline 150ms ease-in-out;
  outline: 2px solid ${({ theme }) => theme.colors.gray200};
  color: ${({ theme }) => theme.colors.gray600};

  font-family: ${({ theme }) => theme.fonts.base};
  line-height: 1.35;

  &:focus-within,
  &:hover {
    outline: 2px solid ${({ theme }) => theme.colors.gray400};
  }

  &:focus-within {
    color: ${({ theme }) => theme.colors.gray900};
  }
`;

export const PreDefinedMessageTextArea = styled.textarea`
  flex: 1;
  resize: none;
  color: ${({ theme }) => theme.colors.gray600};
  background: transparent;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

export const PreDefinedMessageTextAreaActions = styled.div`
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;
