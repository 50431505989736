import { DynamicFormFields } from "@/core/models/business-associate.model";
import { ProfileInfo } from "@/features/attendance/components/ProfileInfo";
import { useTranslateDateFilter } from "@/hooks/useTranslateDateFilter";

interface BusinessAssociateConfigMapOfFieldsProps {
  fields: DynamicFormFields[];
}

export function BusinessAssociateConfigMapOfFields({
  fields,
}: BusinessAssociateConfigMapOfFieldsProps) {
  const translateDate = useTranslateDateFilter();

  return (
    <>
      {fields.map((field) => {
        if (field.type === "multiselect") {
          return (
            <ProfileInfo
              key={field.id}
              label={field.label}
              value={field.value.map((value) => value).join("-")}
            />
          );
        } else if (field.type === "password") {
          return (
            <ProfileInfo
              key={field.id}
              label={field.label}
              value="Não mostrar"
            />
          );
        } else if (field.type === "date") {
          return (
            <ProfileInfo
              key={field.id}
              label={field.label}
              value={translateDate(field.value)}
            />
          );
        } else {
          return (
            <ProfileInfo
              key={field.id}
              label={field.label}
              value={field.value}
            />
          );
        }
      })}
    </>
  );
}
