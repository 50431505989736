import { ListDivider } from "@/components/ListDivider";
import { PreDefinedMessageSubject } from "@/core/models/preDefinedMessage.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
  Input,
  Select,
  SelectItem,
  SelectItemText,
} from "@gogeo-io/ui-library";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectPreDefinedMessagesStatus } from "../store/preDefinedMessagesSlice";
import {
  getPreDefinedMessageTokens,
  selectPreDefinedMessageTokensStatus,
} from "../store/preDefinedMessagesTokensSlice";
import { CreatePreDefinedMessageSkeleton } from "./CreatePreDefinedMessageSkeleton";
import { FloatButtonToCreate } from "./FloatButtonToCreate";
import { PreDefinedMessageSharedForm } from "./PreDefinedMessageSharedForm";
import { PreDefinedMessageTextArea } from "./PreDefinedMessageTextArea";

interface CreatePreDefinedMessageProps {
  onCreatePreDefinedMessage: (
    subject: PreDefinedMessageSubject,
    title: string,
    message: string,
    shared: boolean
  ) => Promise<void>;
}

export function CreatePreDefinedMessage({
  onCreatePreDefinedMessage,
}: CreatePreDefinedMessageProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [shared, setShared] = useState(false);
  const [preDefinedMessagesSubject, setPreDefinedMessagesSubject] =
    useState<PreDefinedMessageSubject>("general");

  const preDefinedMessageTokenStatus = useSelector(
    selectPreDefinedMessageTokensStatus
  );
  const preDefinedMessageStatus = useSelector(selectPreDefinedMessagesStatus);

  const dispatch = useAppDispatch();

  const handleSetOptionSelected = (option: PreDefinedMessageSubject) => {
    setPreDefinedMessagesSubject(option);
  };

  const handleSetNewMessage = (message: string) => setNewMessage(message);

  const handleOnInputChange = (value: string) => {
    setNewTitle(value);
  };

  const handleCreateMessage = () => {
    if (newMessage === "") {
      toast.error("Texto da mensagem não pode ser vazio");
      return;
    }

    if (newTitle === "") {
      toast.error("Texto da título não pode ser vazio");
      return;
    }

    onCreatePreDefinedMessage(
      preDefinedMessagesSubject,
      newTitle,
      newMessage,
      !shared
    ).then(() => {
      if (preDefinedMessageStatus === "success") {
        setModalIsOpen(false);
      }
    });
  };

  const handleDialogOpen = (value: boolean) => {
    setModalIsOpen(value);
  };

  const cleanStates = () => {
    setNewTitle("");
    setNewMessage("");
    setPreDefinedMessagesSubject("general");
  };

  useEffect(() => {
    const getTokens = async () => {
      await dispatch(getPreDefinedMessageTokens(preDefinedMessagesSubject));
    };

    getTokens();
  }, [preDefinedMessagesSubject]);

  useEffect(() => {
    cleanStates();
  }, []);

  return (
    <Dialog onOpenChange={handleDialogOpen} open={modalIsOpen}>
      <DialogTrigger onClick={cleanStates}>
        <FloatButtonToCreate />
      </DialogTrigger>
      <DialogContent>
        {preDefinedMessageTokenStatus !== "success" ? (
          <CreatePreDefinedMessageSkeleton />
        ) : (
          <>
            <DialogClose>
              <IconButton
                size="small"
                type="ghost"
                css={{ position: "absolute", top: "1rem", right: "1rem" }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </DialogClose>
            <DialogTitle>Criar nova mensagem</DialogTitle>

            <ListDivider />

            <Flex css={{ gap: "1.5rem", padding: "1rem 0" }}>
              <Flex css={{ flexDirection: "column", gap: "$4" }}>
                <Flex css={{ flexDirection: "column", gap: "$2" }}>
                  <Heading
                    size="subtitle2"
                    css={{ display: "flex", flexDirection: "row" }}
                  >
                    Sujeito da Mensagem
                    <Heading
                      size="subtitle2"
                      css={{ color: "$red500", fontWeight: "bold" }}
                    >
                      *
                    </Heading>
                  </Heading>
                  <Select
                    value={preDefinedMessagesSubject}
                    defaultValue={preDefinedMessagesSubject}
                    ariaLabel="Select subject"
                    onValueChange={handleSetOptionSelected}
                  >
                    <SelectItem value="products_suggestion">
                      <SelectItemText>Sugestão de Produtos</SelectItemText>
                    </SelectItem>
                    <SelectItem value="client">
                      <SelectItemText>Clientes</SelectItemText>
                    </SelectItem>
                    <SelectItem value="general">
                      <SelectItemText>Geral</SelectItemText>
                    </SelectItem>
                  </Select>
                </Flex>

                <Flex css={{ flexDirection: "column", gap: "$2" }}>
                  <Heading
                    size="subtitle2"
                    css={{ display: "flex", flexDirection: "row" }}
                  >
                    Título da Mensagem
                    <Heading
                      size="subtitle2"
                      css={{ color: "$red500", fontWeight: "bold" }}
                    >
                      *
                    </Heading>
                  </Heading>
                  <Input
                    placeholder="Digite o título da mensagem"
                    value={newTitle}
                    onChange={(e) => handleOnInputChange(e.target.value)}
                  />
                </Flex>

                <PreDefinedMessageSharedForm
                  value={shared}
                  onChangeMessageIsSharedChange={(v) => setShared(v)}
                />
              </Flex>

              <Flex css={{ flexDirection: "column", gap: "$2" }}>
                <Flex
                  css={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "$2",
                  }}
                >
                  <Heading
                    size="subtitle2"
                    css={{ display: "flex", flexDirection: "row" }}
                  >
                    Mensagem
                    <Heading
                      size="subtitle2"
                      css={{ color: "$red500", fontWeight: "bold" }}
                    >
                      *
                    </Heading>
                  </Heading>
                </Flex>
                <Flex css={{ gap: "$2", alignItems: "flex-start" }}>
                  <PreDefinedMessageTextArea
                    newMessage={newMessage}
                    onNewMessageChange={handleSetNewMessage}
                  />
                </Flex>
              </Flex>
            </Flex>
            <ListDivider />

            <Flex
              css={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}
            >
              <Button
                onClick={handleCreateMessage}
                isLoading={preDefinedMessageStatus === "loading"}
              >
                Criar Mensagem
              </Button>
            </Flex>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
