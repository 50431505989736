import { ParamsFreq } from "@/components/ParamsFreq";
import { ShowPreferedDays } from "@/components/PreferedDays/ShowPreferedDays";
import { PreferedDaysAndWeeksSubtitle } from "@/components/PreferedDaysAndWeeksSubtitle";
import { PreferedWeeks } from "@/components/PreferedWeeks";
import { Params } from "@/core/models/params.model";
import {
  Flex,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { TimelineHeaderInfoTimestampOfParams } from "./TimelineHeaderInfo/TimelineHeaderInfoTimestampOfParams";

export interface TimelineAttendanceRecordHeaderOfParamsProps {
  params: Params;
}

export function TimelineAttendanceRecordHeaderOfParams({
  params,
}: TimelineAttendanceRecordHeaderOfParamsProps) {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        padding: "$2",
      }}
    >
      <Text css={{ color: "$gray500" }}>
        Parametrizado por: <strong> {params.generated_by}</strong>
      </Text>

      <Text css={{ color: "$gray500" }}>
        Para o vendedor: <strong> {params.from_user}</strong>
      </Text>

      <Text css={{ color: "$gray500" }}>
        Do grupo: <strong> {params.group}</strong>
      </Text>

      <Text css={{ color: "$gray500" }}>
        Data da alteração:{" "}
        <TimelineHeaderInfoTimestampOfParams feedback_timestamp={params.date} />
      </Text>

      <ParamsFreq freq={params.freq} isBlocked />

      <PreferedWeeks params={params} isBlocked />

      <Text css={{ color: "$gray500" }}>
        Gerar agenda: <strong> {params.non_schedulable ? "Não" : "Sim"}</strong>
      </Text>

      <Flex css={{ flexDirection: "column", gap: "$1" }}>
        <Text css={{ color: "$gray500" }}> Dias preferidos no mês:</Text>

        <Tooltip>
          <TooltipTrigger style={{ background: "transparent" }}>
            <ShowPreferedDays
              isBlocked
              daysWeekPrefReceived={params.days_week_pref}
            />
          </TooltipTrigger>
          <TooltipContent aria-label="Legenda dos dias preferidos">
            <PreferedDaysAndWeeksSubtitle isOnTooltip />
          </TooltipContent>
        </Tooltip>
      </Flex>

      <Text css={{ color: "$gray500" }}>
        Observações: <strong> {params.observations}</strong>
      </Text>
    </Flex>
  );
}
