import { TrackingEvents } from "@/config/mixpanel";
import { RuptureRule } from "@/core/models/rules";
import { selectUser } from "@/core/store/users/usersSlice";
import { ClientsPageSkeleton } from "@/features/clientInfo/components/ClientsPageSkeleton";
import {
  getRuptureRule,
  setSelectRuptureRule,
} from "@/features/ruptureRules/store/ruptureRuleSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useMixpanel } from "@/hooks/useMixpanel";
import { PageLayout } from "@/layouts/PageLayout";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { UserProfile } from "../components/UserProfile";

export function UserProfilePage() {
  const [ruptureRulesIsLoaded, setRuptureRulesIsLoaded] = useState(false);
  const [ruptureRulesHaveError, setRuptureRulesHaveError] = useState(false);

  const user = useSelector(selectUser);

  const dispatch = useAppDispatch();
  const { pageViewed } = useMixpanel();

  const loadRuptureRule = async () => {
    setRuptureRulesHaveError(false);

    await dispatch(getRuptureRule()).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        await dispatch(setSelectRuptureRule({} as RuptureRule));
        setRuptureRulesHaveError(false);
      } else {
        setRuptureRulesHaveError(true);
        toast.error(
          "Gestão de ruptura não disponível, houve um problema na busca de informações"
        );
      }
      setRuptureRulesIsLoaded(true);
    });
  };

  useEffect(() => {
    pageViewed(TrackingEvents.RUPTURE_RULE_LIST_PAGE);
    setRuptureRulesIsLoaded(false);

    if (user.role === "SALESMAN") {
      setRuptureRulesIsLoaded(true);
    } else {
      loadRuptureRule();
    }
  }, []);

  return (
    <PageLayout
      leftSidebar={
        ruptureRulesIsLoaded ? (
          <UserProfile ruptureRulesNotHaveError={!ruptureRulesHaveError} />
        ) : (
          <ClientsPageSkeleton />
        )
      }
      content={ruptureRulesIsLoaded ? <Outlet /> : <ClientsPageSkeleton />}
    />
  );
}
