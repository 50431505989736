import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import {
  getClientTokens,
  getGeneralTokens,
  getProductSuggestionTokens,
} from "@/core/mocks/preDefinedMessageTokensMock";
import { PreDefinedMessageSubject } from "@/core/models/preDefinedMessage.model";
import { PreDefinedMessageToken } from "@/core/models/preDefinedMessageToken.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

interface PreDefinedMessagesTokenState {
  preDefinedMessageTokens: PreDefinedMessageToken[];
  status: "loading" | "success" | "failed";
  totalPreDefinedMessages: number;
}

const initialState = {
  preDefinedMessageTokens: [],
  status: "loading",
} as PreDefinedMessagesTokenState;

export const getPreDefinedMessageTokens = createAsyncThunk(
  "preDefinedMessageTokens/getPreDefinedMessageTokens",
  async (subject: PreDefinedMessageSubject, thunkAPI) => {
    if (subject === "client") {
      return getClientTokens();
    } else if (subject === "products_suggestion") {
      return getProductSuggestionTokens();
    } else {
      return getGeneralTokens();
    }
  }
);

export const preDefinedMessageTokensSlice = createSlice({
  name: "preDefinedMessageTokensSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getPreDefinedMessageTokens.pending,
      (state, action: PayloadAction<any>) => {
        state.status = "loading";
      }
    );
    builder.addCase(
      getPreDefinedMessageTokens.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.preDefinedMessageTokens = action.payload;
      }
    );
    builder.addCase(
      getPreDefinedMessageTokens.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error on getting defined messages tokens");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectPreDefinedMessageTokens = (state: RootState) =>
  state.preDefinedMessageTokensState.preDefinedMessageTokens;

export const selectPreDefinedMessageTokensStatus = (state: RootState) =>
  state.preDefinedMessageTokensState.status;

export const preDefinedMessageTokenReducer =
  preDefinedMessageTokensSlice.reducer;
