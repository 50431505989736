import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import { ClientParamsResponse } from "@/core/models/client.model";
import { SortOrder } from "@/core/models/listOfTags.model";
import { selectUser } from "@/core/store/users/usersSlice";
import {
  selectColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { ClientsTable } from "@/features/clients/components/ClientsTable";
import { useBreadcrumbsForClientsListPage } from "@/features/clients/hooks/useBreadcrumbsForClientsListPage";
import { useClientsColumns } from "@/features/clients/hooks/useClientsColumns";
import { useClientsMixpanel } from "@/features/clients/hooks/useClientsMixpanel";
import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import {
  getClientsByFilters,
  getClientsCount,
} from "@/features/clients/store/clientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Box } from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  emitEventToChangeClients,
  selectStatusOfEmitEventToChangeClients,
} from "../store/emitEventToChangeClientsSlice";
import { selectSelectedList } from "../store/listSelectedSlice";

export function ListViewOfClients() {
  const [hasMorePages, setHasMorePages] = useState(true);
  const [clients, setClients] = useState<ClientParamsResponse[]>([]);
  const [clientStatus, setClientsStatus] = useState("loading");
  const [currentPage, setCurrentPage] = useState(0);
  const [loadedPage, setLoadedPage] = useState(false);

  const selectedList = useSelector(selectSelectedList);
  const user = useSelector(selectUser);
  const statusOfEmitEventToChangeClients = useSelector(
    selectStatusOfEmitEventToChangeClients
  );
  const selectedColumnsToSort = useSelector(selectColumnsToSort);

  const { showBreadcrumbs, showBreadcrumbsToFiltersPage } =
    useBreadcrumbsForClientsListPage();
  const { loadClientsEvent } = useClientsMixpanel();
  const { paramsOnEditListView } = useGetClientsByFiltersParams();
  const dispatch = useAppDispatch();

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSetSort = async (
    direction: "ASC" | "DESC" | "",
    property: string
  ) => {
    await dispatch(emitEventToChangeClients(false));

    const newColumn: SortOrder = { direction, index: 0, property };

    if (selectedColumnsToSort.length === 0) {
      await dispatch(setColumnsToSort([newColumn]));
    } else {
      const findedColumn = selectedColumnsToSort.find(
        (selectedColum) => selectedColum.property === newColumn.property
      );

      let columnsWithNewColumn: SortOrder[] = [];

      if (isUndefined(findedColumn)) {
        columnsWithNewColumn = [newColumn, ...selectedColumnsToSort];
      } else {
        const columnsWithoutRemovedOne = selectedColumnsToSort.filter(
          (selectedColum) => selectedColum.property !== newColumn.property
        );
        columnsWithNewColumn = [newColumn, ...columnsWithoutRemovedOne];
      }

      const columnsWithoutEmptyDirection = columnsWithNewColumn.filter(
        (selectedColum) => selectedColum.direction !== ""
      );

      const newOrderOfColumns = columnsWithoutEmptyDirection.map(
        (col, idx) => col && { ...col, index: idx }
      );

      await dispatch(setColumnsToSort(newOrderOfColumns));
    }

    await dispatch(emitEventToChangeClients(true));
  };

  useEffect(() => {
    if (statusOfEmitEventToChangeClients) {
      setCurrentPage(0);
      setHasMorePages(true);
      setClientsStatus("loading");

      loadClients();
    }
  }, [statusOfEmitEventToChangeClients]);

  useEffect(() => {
    setCurrentPage(0);
    setHasMorePages(true);
    setClientsStatus("loading");

    loadClients();
  }, []);

  async function loadClients() {
    const params = paramsOnEditListView();

    await dispatch(
      getClientsByFilters({
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        params,
      })
    ).then(async (res) => {
      if (res.payload.clients.length < import.meta.env.VITE_PAGE_SIZE) {
        setHasMorePages(false);
        setClients(res.payload.clients);
      } else {
        setClients(res.payload.clients);
      }
      if (!isEmpty(selectedList)) {
        loadClientsEvent(selectedList, params, res.payload.clients);
        showBreadcrumbs(selectedList);
      } else {
        showBreadcrumbsToFiltersPage();
      }

      setClientsStatus("fulfilled");
      setLoadedPage(true);
    });
    await dispatch(
      getClientsCount({
        params,
      })
    );
  }

  const getPaginatedClients = async (currentPage: number) => {
    const params = paramsOnEditListView();

    await dispatch(
      getClientsByFilters({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
        params,
      })
    ).then((res) => {
      if (res.payload.clients.length === 0) {
        setHasMorePages(false);
      } else {
        setClients((prevClients) => [...prevClients, ...res.payload.clients]);
      }
    });
  };

  const clientsColumns = useClientsColumns(handleSetSort);

  return (
    <Box css={{ height: "100%" }}>
      {loadedPage ? (
        <ClientsTable
          columns={clientsColumns}
          data={clients}
          onPageChange={getPaginatedClients}
          currentPage={currentPage}
          onIncreasePageNumber={handleIncreasePageNumber}
          hasMorePages={hasMorePages}
          status={clientStatus}
        />
      ) : (
        <ClientsTableSkeletonPage />
      )}
    </Box>
  );
}
