import { Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";

interface UsersRelatedToUserSelectRoleProps {
  selectedRole: string;
  onSelectRole: (value: string) => void;
}

export function UsersRelatedToUserSelectRole({
  selectedRole,
  onSelectRole,
}: UsersRelatedToUserSelectRoleProps) {
  return (
    <Select
      defaultValue={selectedRole}
      value={selectedRole}
      onValueChange={(value) => onSelectRole(value)}
      ariaLabel="Funções do cliente"
    >
      <SelectItem value="all">
        <SelectItemText>Todas</SelectItemText>
      </SelectItem>
      <SelectItem value="ADMIN">
        <SelectItemText>Administrador</SelectItemText>
      </SelectItem>
      <SelectItem value="MANAGER">
        <SelectItemText>Gerente</SelectItemText>
      </SelectItem>
      <SelectItem value="SUPERVISOR">
        <SelectItemText>Supervisor</SelectItemText>
      </SelectItem>
      <SelectItem value="SALESMAN">
        <SelectItemText>Vendedor</SelectItemText>
      </SelectItem>
    </Select>
  );
}
