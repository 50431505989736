import { useClientInfoMixpanel } from "@/features/clientInfo/hooks/useClientInfoMixpanel";
import { Text } from "@gogeo-io/ui-library";

type Props = {
  href: string;
  children?: string;
};

export default function OuterLink({ href, children }: Props) {
  const { sendedEmailToClientEvent } = useClientInfoMixpanel();

  const handleClickOuterLink = () => {
    sendedEmailToClientEvent(children);
  };

  return (
    <Text
      onClick={handleClickOuterLink}
      size="body2"
      css={{
        textDecoration: "underline",
        color: "$gray900",
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <a target="_blank" href={"mailto:" + href} rel="noreferrer">
        {children}
      </a>
    </Text>
  );
}
