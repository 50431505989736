import { Breadcrumb } from "@/core/models/breadcrumb";
import { ListOfTags, SearchFilters } from "@/core/models/listOfTags.model";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import {
  addTagsOnSelectedClientFilter,
  setSelectedAdvancedFilters,
} from "@/features/attendance/store/clientAdvancedFiltersSlice";
import { selectList } from "@/features/attendance/store/listSelectedSlice";
import { setSelectedSearchFilters } from "@/features/attendance/store/selectedSearchFiltersSlice";
import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import {
  cleanColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { setClientsHasError500 } from "../store/clientsSlice";

export const useBreadcrumbsForClientsListPage = () => {
  const dispatch = useAppDispatch();

  const handleSetFiltersOfList = async (list: ListOfTags) => {
    const columnsToSort = list.filters?.sort?.orders;
    if (columnsToSort !== undefined && columnsToSort.length > 0) {
      await dispatch(setColumnsToSort(columnsToSort));
    } else {
      await dispatch(cleanColumnsToSort());
    }

    const advancedFilters = list.filters?.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = list.filters?.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    const attrOpts = list.filters?.attr_opts;
    if (attrOpts !== undefined) {
      await dispatch(setAttributesToFilter(attrOpts));
    } else {
      await dispatch(setDefaultAttributesToFilter());
    }

    const searchFilters: SearchFilters = {
      match_type: "Geral",
      match_value: "",
    };

    await dispatch(setSelectedSearchFilters(searchFilters));

    await dispatch(selectList(list));
    await dispatch(setClientsHasError500(false));
  };

  const showBreadcrumbs = async (list: ListOfTags) => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Atendimento", url: "/attendance" },
      {
        item: list.name,
        url: `/attendance/${list.slug}`,
        adicionalAction: () => handleSetFiltersOfList(list),
        notNavigate: true,
      },
      { item: "Editar Filtro", url: "" },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  const showBreadcrumbsToFiltersPage = async () => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Atendimento", url: "/attendance" },
      { item: "Lista com filtros", url: "" },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return {
    showBreadcrumbs,
    showBreadcrumbsToFiltersPage,
  };
};
