import { Background } from "./Background";
import { Page } from "./Page";

interface ErrorPageLayoutProps {
  content: React.ReactNode;
}

export function ErrorPageLayout({ content }: ErrorPageLayoutProps) {
  return (
    <Background>
      <Page>{content}</Page>
    </Background>
  );
}
