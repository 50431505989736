import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { Report, ReportParams } from "@/core/models/reports.model";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { RootState } from "../../../core/store/store";

interface ReportsState {
  selectedReport: Report;
  filters: ReportParams;
  reportFrame: string;
  filtersStatus: "filled" | "not-filled";
}

const initialState = {
  selectedReport: {},
  filters: {},
  reportFrame: "",
  filtersStatus: "not-filled",
} as ReportsState;

export interface GetReportProps {
  data: ReportParams;
  dashboard_name: string;
}

export const getReport = createAsyncThunk(
  "report/getReport",
  async ({ data, dashboard_name }: GetReportProps, thunkAPI) => {
    try {
      let body: ReportParams = {
        ...data,
        salesman_type: null,
        schedule_type: null,
      };

      if (data.salesmans !== undefined) {
        body = {
          ...body,
          salesman_ids: data.salesmans.map((salesman) => salesman.salesman_id),
        };
        delete body["salesmans"];
      }

      const response = await axios.post(`/dashboards/${dashboard_name}`, body);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    setSelectedReport(state, action: PayloadAction<Report>) {
      state.selectedReport = action.payload;
    },
    setReportFilters(state, action: PayloadAction<ReportParams>) {
      state.filters = action.payload;
    },
    setReportFiltersStatus(
      state,
      action: PayloadAction<"filled" | "not-filled">
    ) {
      state.filtersStatus = action.payload;
    },
    updateReportFilter(
      state,
      action: PayloadAction<{ filter_field: string; value: any }>
    ) {
      state.filters[action.payload.filter_field] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.reportFrame = action.payload?.url;
    });
    builder.addCase(getReport.rejected, (state, action: PayloadAction<any>) => {
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setTransactionName("Error getting the report");
        scope.setExtras(action.payload);
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });
  },
});

export const selectSelectedReport = (state: RootState) =>
  state.reportState.selectedReport;
export const selectReportFilters = (state: RootState) =>
  state.reportState.filters;
export const selectFiltersStatus = (state: RootState) =>
  state.reportState.filtersStatus;
export const selectReportFrame = (state: RootState) =>
  state.reportState.reportFrame;

export const {
  setSelectedReport,
  setReportFiltersStatus,
  setReportFilters,
  updateReportFilter,
} = reportSlice.actions;

export const reportReducer = reportSlice.reducer;
