import { useNavigate } from "react-router-dom";

interface LinkToAnotherTabProps {
  url: string;
  isFullWidth?: boolean;
  children: any;
}

export function LinkToAnotherTab({
  url,
  isFullWidth = false,
  children,
}: LinkToAnotherTabProps) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (e.button === 0) {
      e.preventDefault();
      navigate(url);
    }
  };

  return (
    <a
      href={url}
      onClick={handleClick}
      style={{ width: isFullWidth ? "100%" : "initial" }}
    >
      {children}
    </a>
  );
}
