import { UserPermission } from "@/core/models/user-permission.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ReduxThunkError } from "../../../core/errors/ReduxThunkError";

interface ReminderState {
  has500error: boolean;
  permissions: UserPermission[];
  status: "loading" | "success" | "failed";
}

const initialState = {
  has500error: false,
  permissions: [],
  status: "loading",
} as ReminderState;

interface GetPermissionsProps {
  user_id?: number;
}

export const getUserPermissions = createAsyncThunk(
  "userPermissions/getPermissions",
  async ({ user_id }: GetPermissionsProps, thunkAPI) => {
    try {
      const url = user_id ? `/user/permission/${user_id}` : `/user/permission`;
      const response = await axios.get(url);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface UpdateUserPermissionsProps {
  user_id: number;
  updated_permissions: UserPermission[];
}

export const updateUserPermissions = createAsyncThunk(
  "userPermissions/updatePermissions",
  async (
    { user_id, updated_permissions }: UpdateUserPermissionsProps,
    thunkAPI
  ) => {
    try {
      const response = await axios.put(`/user/permission`, {
        user_ids: [user_id],
        permissions: updated_permissions,
      });
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface UpdateUsersPermissionsProps {
  user_ids: number[];
  updated_permissions: UserPermission[];
}

export const updateUsersPermissions = createAsyncThunk(
  "userPermissions/updateUsersPermissions",
  async (
    { user_ids, updated_permissions }: UpdateUsersPermissionsProps,
    thunkAPI
  ) => {
    try {
      const response = await axios.put(`/user/permission`, {
        user_ids,
        permissions: updated_permissions,
      });
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const userPermissionsSlice = createSlice({
  name: "userPermissionsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserPermissions.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(getUserPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
      state.status = "success";
      state.has500error = false;
    });
    builder.addCase(
      getUserPermissions.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the user permissions");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateUserPermissions.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(updateUserPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
      state.status = "success";
      state.has500error = false;
    });
    builder.addCase(
      updateUserPermissions.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating user permissions");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateUsersPermissions.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(updateUsersPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
      state.status = "success";
      state.has500error = false;
    });
    builder.addCase(
      updateUsersPermissions.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating users permissions");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectUserPermissions = (state: RootState) =>
  state.userPermissionsState.permissions;
export const selectUserPermissionsStatus = (state: RootState) =>
  state.userPermissionsState.status;
export const selectUserPermissionsHasError500 = (state: RootState) =>
  state.userPermissionsState.has500error;

export const userPermissionsReducer = userPermissionsSlice.reducer;
