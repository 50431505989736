import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { useReminderMixpanel } from "@/features/reminder/hooks/useReminderMixpanel";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
} from "@gogeo-io/ui-library";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useState } from "react";
import { TbAlarmPlus } from "react-icons/tb";
import { useSelector } from "react-redux";
import styled from "styled-components";
import RegisterAttendanceReminderForm from "../register-attendance/RegisterAttendanceReminderForm";

export function FeedbackReminder() {
  const [canSeeReminderForm, setCanSeeReminderForm] = useState(false);

  const selectedClient = useSelector(selectClient);

  const { createFeedbackModalOpenedEvent } = useReminderMixpanel();

  return (
    <Dialog open={canSeeReminderForm} onOpenChange={setCanSeeReminderForm}>
      <DialogTrigger asChild>
        <Flex
          css={{
            gap: "$1",
            cursor: "pointer",
            alignItems: "center",
            color: "$primary500",
          }}
          onClick={() => createFeedbackModalOpenedEvent()}
        >
          <TbAlarmPlus size={22} />
          Adicionar lembrete
        </Flex>
      </DialogTrigger>

      <DialogContent>
        <Content>
          <div className="formTitle">
            <Heading size="subtitle1">Adicionar lembrete</Heading>
            <CalendarTodayIcon fontSize="inherit" />
          </div>
          <RegisterAttendanceReminderForm
            client_name={selectedClient.name}
            client_id={selectedClient.id}
            handleCanSeeReminderForm={setCanSeeReminderForm}
          />
        </Content>
      </DialogContent>
    </Dialog>
  );
}

export const Content = styled.div`
  width: 350px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[5]};

  .formTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      font-size: 25px;
      font-weight: bold;
    }
  }
`;
