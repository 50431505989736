import { Flex } from "@gogeo-io/ui-library";
import { ReactNode } from "react";
import { Appbar } from "./Appbar";
import { Background } from "./Background";
import { Main } from "./Main";
import { Page } from "./Page";
import SidebarLeft from "./SidebarLeft";

interface PageLayoutProps {
  leftSidebar: ReactNode;
  content: ReactNode;
  zeroContentPadding?: boolean;
  showLeftSidebar?: boolean;
}

export function PageLayout({
  leftSidebar,
  content,
  zeroContentPadding = false,
  showLeftSidebar = true,
}: PageLayoutProps) {
  return (
    <Background>
      <Page>
        <Appbar />
        <Flex css={{ width: "100%", height: "100%" }}>
          {showLeftSidebar && <SidebarLeft>{leftSidebar}</SidebarLeft>}
          <Main zeroContentPadding={zeroContentPadding}>{content}</Main>
        </Flex>
      </Page>
    </Background>
  );
}
