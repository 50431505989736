import { ClientNote } from "@/core/models/client-notes.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  IconButton,
  RightSlot,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { BiSolidPencil } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs";
import { MdFolderShared, MdMoreVert } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useClientNotesMixpanel } from "../hooks/useClientNotesMixpanel";
import {
  deleteClientNote,
  getClientNote,
  removeClientNoteFromState,
  updateSharedOnClientNote,
} from "../store/clientNotesSlice";
import { DeleteClientNoteConfirmation } from "./clientNotes/DeleteClientNoteConfirmation";
import { ShareClientNoteConfirmation } from "./clientNotes/ShareClientNoteConfirmation";
import { UnshareClientNoteConfirmation } from "./clientNotes/UnshareClientNoteConfirmation";

interface ClientNoteActionsProps {
  note: ClientNote;
}

export function ClientNoteActions({ note }: ClientNoteActionsProps) {
  const [deleteNoteDialogIsOpen, setDeleteNoteDialogIsOpen] = useState(false);
  const [sharedNoteDialogIsOpen, setSharedDialogIsOpen] = useState(false);
  const [unsharedNoteDialogIsOpen, setUnsharedDialogIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { noteDeletedEvent, noteUnSharedEvent, noteSharedEvent } =
    useClientNotesMixpanel();

  const handleClickNote = async (note_id: string) => {
    await dispatch(getClientNote(note_id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate(`edit/${note.id}`);
      }
    });
  };

  const handleRemoveClientNote = async () => {
    await dispatch(deleteClientNote(note.id)).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        noteDeletedEvent(note);
        await dispatch(removeClientNoteFromState(res.payload.id));
      }
    });
  };

  const handleSetSharedNoteToTrue = async () => {
    await dispatch(
      updateSharedOnClientNote({ note_id: note.id, shared: true })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        noteSharedEvent(note);
      }
    });
  };

  const handleSetSharedNoteToFalse = async () => {
    await dispatch(
      updateSharedOnClientNote({ note_id: note.id, shared: false })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        noteUnSharedEvent(note);
      }
    });
  };

  return (
    <Flex>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton color="gray" type="ghost">
            <MdMoreVert />
          </IconButton>
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5}>
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
              handleClickNote(note.id);
            }}
          >
            Editar
            <RightSlot>
              <BiSolidPencil color="inherit" fontSize="inherit" />
            </RightSlot>
          </DropdownMenuItem>

          <DropdownMenuItem
            onClick={async (event) => {
              event.stopPropagation();
              setDeleteNoteDialogIsOpen(true);
            }}
          >
            Deletar
            <RightSlot>
              <BsTrashFill color="inherit" fontSize="inherit" />
            </RightSlot>
          </DropdownMenuItem>

          {note.shared ? (
            <DropdownMenuItem
              onClick={async (event) => {
                event.stopPropagation();
                setUnsharedDialogIsOpen(true);
              }}
            >
              Tornar nota pessoal
              <RightSlot>
                <MdFolderShared color="inherit" fontSize="inherit" />
              </RightSlot>
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem
              onClick={async (event) => {
                event.stopPropagation();
                setSharedDialogIsOpen(true);
              }}
            >
              Tornar nota compartilhada
              <RightSlot>
                <MdFolderShared color="inherit" fontSize="inherit" />
              </RightSlot>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog
        open={deleteNoteDialogIsOpen}
        onOpenChange={(e) => setDeleteNoteDialogIsOpen(e)}
      >
        <DialogTrigger />
        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <DeleteClientNoteConfirmation
            onDeleteClientNote={handleRemoveClientNote}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteNoteDialogIsOpen}
        onOpenChange={(e) => setDeleteNoteDialogIsOpen(e)}
      >
        <DialogTrigger />
        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <DeleteClientNoteConfirmation
            onDeleteClientNote={handleRemoveClientNote}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={sharedNoteDialogIsOpen}
        onOpenChange={(e) => setSharedDialogIsOpen(e)}
      >
        <DialogTrigger />
        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <ShareClientNoteConfirmation
            onShareClientNote={handleSetSharedNoteToTrue}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={unsharedNoteDialogIsOpen}
        onOpenChange={(e) => setUnsharedDialogIsOpen(e)}
      >
        <DialogTrigger />
        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <UnshareClientNoteConfirmation
            onShareClientNote={handleSetSharedNoteToFalse}
          />
        </DialogContent>
      </Dialog>
    </Flex>
  );
}
