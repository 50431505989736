import {
  removeAllUserToBatchActions,
  setAllUserToBatchActions,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Checkbox } from "@gogeo-io/ui-library";
import { useState } from "react";

export function SelectAllUsersCheckbox() {
  const [checked, setChecked] = useState(false);

  const dispatch = useAppDispatch();

  const handleCheckboxChange = async (isChecked: boolean) => {
    setChecked(isChecked);

    if (isChecked) {
      await dispatch(setAllUserToBatchActions());
    } else {
      await dispatch(removeAllUserToBatchActions());
    }
  };

  return (
    <Checkbox
      checked={checked}
      onCheckedChange={handleCheckboxChange}
      css={{ cursor: "pointer" }}
    />
  );
}
