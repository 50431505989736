import { Text } from "@gogeo-io/ui-library";

interface FormLabelProps {
  label: string;
  isRequired?: boolean;
}

export function FormLabel({ label, isRequired = false }: FormLabelProps) {
  return (
    <Text css={{ marginBottom: "$2" }}>
      {label}
      {isRequired && (
        <Text bold css={{ color: "$red500", display: "inline" }}>
          *
        </Text>
      )}
    </Text>
  );
}
