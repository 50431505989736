import { Flex, Text } from "@gogeo-io/ui-library";

interface RemindersWithoutReadDialogProps {
  remindersNotReadedCount: number;
}

export function RemindersWithoutReadDialog({
  remindersNotReadedCount,
}: RemindersWithoutReadDialogProps) {
  return (
    <Text size="body1" css={{ color: "inherit" }}>
      Você tem {remindersNotReadedCount}
      {remindersNotReadedCount === 1 ? " lembrete" : " lembretes"} não
      {remindersNotReadedCount === 1 ? " lido" : " lidos"}
    </Text>
  );
}
