import styled from "styled-components";

export const AttendanceIndicatorsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space[3]};
  padding-left: ${({ theme }) => theme.space[1]};
`;
