import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import {
  AttendanceJustification,
  PaginatedAttendanceJustificationResponse,
} from "@/core/models/attendance-justification.model";
import { PaginatedRequestBaseProps } from "@/core/models/paginatedRequest.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface AttendanceJustificationsState {
  attendanceJustifications: PaginatedAttendanceJustificationResponse;
  has500error: boolean;
  status: "loading" | "success" | "failed";
  totalOfAttendanceJustifications: number;
}

const initialState = {
  attendanceJustifications: {},
  has500error: false,
  status: "loading",
} as AttendanceJustificationsState;

interface GetAttendanceJustificationsProps extends PaginatedRequestBaseProps {}

export const getAttendanceJustifications = createAsyncThunk(
  "attendanceJustifications/getAttendanceJustifications",
  async ({ page, size }: GetAttendanceJustificationsProps, thunkAPI) => {
    try {
      const response = await axios.get(
        `/attendance-justifications?page=${page}&size=${size}`
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response?.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          status: error.response.status,
          timestamp: new Date(),
        });
      }
    }
  }
);

type CreateAttendanceJustificationProps = Omit<AttendanceJustification, "id">;

export const createAttendanceJustification = createAsyncThunk(
  "attendanceJustifications/createAttendanceJustification",
  async (data: CreateAttendanceJustificationProps, thunkAPI) => {
    try {
      const response = await axios.post("/attendance-justifications", data);
      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 202
      ) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response?.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: new Date(),
        });
      }
    }
  }
);

export const updateAttendanceJustification = createAsyncThunk(
  "attendanceJustifications/updateAttendanceJustification",
  async (data: AttendanceJustification, thunkAPI) => {
    try {
      const response = await axios.put(
        `/attendance-justifications/${data.id}`,
        data
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response?.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: new Date(),
        });
      }
    }
  }
);

interface DeleteAttendanceJustificationProps {
  id: number;
}

export const deleteAttendanceJustification = createAsyncThunk(
  "attendanceJustifications/deleteAttendanceJustification",
  async (data: DeleteAttendanceJustificationProps, thunkAPI) => {
    try {
      const response = await axios.delete(
        `/attendance-justifications/${data.id}`
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response?.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: new Date(),
        });
      }
    }
  }
);

export const attendanceJustificationsSlice = createSlice({
  name: "attendanceJustificationsSlice",
  initialState,
  reducers: {
    setTotalOfAttendanceJustifications(state, action: PayloadAction<number>) {
      state.totalOfAttendanceJustifications = action.payload;
    },
    updateAttendanceJustificationsState(
      state,
      action: PayloadAction<AttendanceJustification>
    ) {
      state.attendanceJustifications.attendance_justifications =
        state.attendanceJustifications.attendance_justifications.map((att) =>
          att.id === action.payload.id ? action.payload : att
        );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAttendanceJustifications.pending,
      (state, action: PayloadAction<any>) => {
        state.status = "loading";
        state.has500error = false;
      }
    );
    builder.addCase(
      getAttendanceJustifications.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.has500error = false;
        state.attendanceJustifications = action.payload;
      }
    );
    builder.addCase(
      getAttendanceJustifications.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error on getting the attendance justifications"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      createAttendanceJustification.pending,
      (state, action: PayloadAction<any>) => {
        state.status = "loading";
      }
    );
    builder.addCase(
      createAttendanceJustification.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
      }
    );
    builder.addCase(
      createAttendanceJustification.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error creating a pre defined message");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );

    builder.addCase(
      updateAttendanceJustification.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating a pre defined message");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );

    builder.addCase(
      deleteAttendanceJustification.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error deleting a pre defined message");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

///////////////////////////////////////

export const selectTotalOfAttendanceJustifications = (state: RootState) =>
  state.attendanceJustificationsState.totalOfAttendanceJustifications;
export const selectAttendanceJustificationsStatus = (state: RootState) =>
  state.attendanceJustificationsState.status;
export const selectAttendanceJustificationsHasError500 = (state: RootState) =>
  state.attendanceJustificationsState.has500error;

///////////////////////////////////////

export const {
  setTotalOfAttendanceJustifications,
  updateAttendanceJustificationsState,
} = attendanceJustificationsSlice.actions;

export const attendanceJustificationsReducer =
  attendanceJustificationsSlice.reducer;
