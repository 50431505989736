import { ListOfTagsFilter } from "@/core/models/listOfTags.model";
import { isEmpty } from "lodash";

const capitalizeText = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const toCamelCase = (str: string) => {
  return str.replace(/_([a-z])/g, (_, p1) => p1.toUpperCase());
};

export const useConvertValidFiltersToUrlFilters = () => {
  return (filters: ListOfTagsFilter, list_name: string) => {
    const queryParams = [];

    list_name &&
      !isEmpty(list_name) &&
      queryParams.push(`sharedName=${list_name}`);

    filters &&
      filters.tags &&
      filters.tags.forEach((tag) => {
        if (tag.id) {
          queryParams.push(`tagId=${tag.id}`);
        } else if (tag.slug) {
          queryParams.push(`tagSlug=${tag.slug}`);
        }
      });

    filters &&
      filters.advanced_filters &&
      filters.advanced_filters.forEach((filter) => {
        let prefix;
        switch (filter.type) {
          case "currency":
            prefix = "currency";
            break;
          case "date":
            prefix = "date";
            break;
          case "multiselect":
            prefix = "multiselect";
            break;
          case "select":
            prefix = "select";
            break;
          default:
            prefix = "";
        }

        if (filter.type === "multiselect") {
          filter.value.forEach((val) => {
            if (val.id) {
              queryParams.push(
                `${prefix}${capitalizeText(toCamelCase(filter.property))}Id=${
                  val.id
                }`
              );
            } else if (val.value) {
              queryParams.push(
                `${prefix}${capitalizeText(
                  toCamelCase(filter.property)
                )}Value=${val.value}`
              );
            }
          });
        } else if (filter.type === "select") {
          if (filter.value.id) {
            queryParams.push(
              `${prefix}${capitalizeText(toCamelCase(filter.property))}Id=${
                filter.value.id
              }`
            );
          }
          if (filter.value.value) {
            queryParams.push(
              `${prefix}${capitalizeText(toCamelCase(filter.property))}Value=${
                filter.value.value
              }`
            );
          }
        } else {
          queryParams.push(
            `${prefix}${capitalizeText(toCamelCase(filter.property))}=${
              filter.value
            }`
          );
        }
      });

    filters &&
      filters.match &&
      Object.keys(filters.match).forEach((key) => {
        const matchKey = `${toCamelCase(key)}`;
        queryParams.push(
          `${matchKey}=${encodeURIComponent(filters.match[key])}`
        );
      });

    return `https://web.govendas.com/attendance/filter?${queryParams.join(
      "&"
    )}`;
  };
};
