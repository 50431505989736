import { gvTheme } from "@gogeo-io/ui-library";
import styled from "styled-components";

export const Th = styled.th`
  width: 100%;

  position: sticky;
  cursor: pointer;
  z-index: 0;

  top: -20px;
  background-color: ${({ theme }) => theme.colors.white};

  padding: 1rem 0;
  font-size: 0.9rem;

  font-family: ${({ theme }) => theme.fonts.headings};
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 1.063rem;
  font-weight: 600;

  @media (max-width: ${gvTheme.mediaSizes.mobile}) {
    font-size: 0.875rem;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
