import { ListDivider } from "@/components/ListDivider";
import RLSSkeleton from "react-loading-skeleton";
import styled from "styled-components";

interface SkeletonProps {
  count?: number;
}

export function TagGroupListSkeleton({ count = 3 }: SkeletonProps) {
  const timesToRepeat = Array.from(Array(count).keys());

  return (
    <>
      {timesToRepeat.map((c, idx) => {
        return (
          <div key={c}>
            <Wrapper>
              <RLSSkeleton width={115} height={20} style={{ zIndex: 0 }} />
              <Row>
                <RLSSkeleton width={115} height={25} style={{ zIndex: 0 }} />
                <RLSSkeleton width={115} height={25} style={{ zIndex: 0 }} />
                <RLSSkeleton width={30} height={20} style={{ zIndex: 0 }} />
              </Row>
            </Wrapper>
            {idx !== timesToRepeat.length - 1 && <ListDivider $isSkeleton />}
          </div>
        );
      })}
    </>
  );
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 5px;
  gap: ${(props) => props.theme.space[2]};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[2]};
`;
