import { TextEditorToListNote } from "@/features/textEditor/components/TextEditorToListNote";
import { Flex } from "@gogeo-io/ui-library";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useClientNotesMixpanel } from "../hooks/useClientNotesMixpanel";
import { selectSelectedClientNote } from "../store/clientNotesSlice";
import { ClientNoteListHeader } from "./clientNotes/ClientNoteListHeader";

export function ClientNoteList() {
  const selectedClientNote = useSelector(selectSelectedClientNote);

  const { noteOpenedEvent } = useClientNotesMixpanel();

  useEffect(() => {
    noteOpenedEvent(selectedClientNote);
  }, []);

  return (
    <>
      <Flex
        css={{
          flexDirection: "column",
          padding: "$4 0",
          gap: "$5",
          width: "100%",
          height: "100%",
        }}
      >
        <ClientNoteListHeader
          title={selectedClientNote.title}
          buttonTitle="Editar Nota"
        />

        <TextEditorToListNote
          files={selectedClientNote.files}
          content={selectedClientNote.content}
        />
      </Flex>
    </>
  );
}
