import { useFormatDate } from "@/hooks/useFormatDate";
import { Caption, Flex } from "@gogeo-io/ui-library";

interface TimelineHeaderInfoRescheduledDateProps {
  rescheduled_creation_date: string;
}

export function TimelineHeaderInfoRescheduleCreationDate({
  rescheduled_creation_date,
}: TimelineHeaderInfoRescheduledDateProps) {
  const { formatBasicDateWithSlash, newDateBasedOnDateWithoutTimezone } =
    useFormatDate();

  return (
    <Flex css={{ alignItems: "center" }}>
      <Caption wider css={{ color: "$gray500" }}>
        &nbsp;|&nbsp;
      </Caption>
      <Caption css={{ display: "block" }}>Reagendado em:&nbsp;</Caption>
      <Caption css={{ display: "block", color: "$gray600" }}>
        {formatBasicDateWithSlash(
          newDateBasedOnDateWithoutTimezone(rescheduled_creation_date)
        )}
      </Caption>
    </Flex>
  );
}
