import { gvTheme } from "@gogeo-io/ui-library";
import styled from "styled-components";

export const AttendanceIndicator = styled.div`
  display: inline-flex;
  line-height: 1;
  color: ${({ theme }) => theme.colors.gray700};

  gap: ${({ theme }) => theme.space[1]};

  align-items: center;

  padding-inline: ${({ theme }) => theme.space[2]};

  height: 1.75rem;

  background: ${({ theme }) => theme.colors.gray200};

  border-radius: ${({ theme }) => theme.radii.sm};

  b {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray900};
  }

  &.completed {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary400};
  }

  @media (max-width: ${gvTheme.mediaSizes.tablet}) {
    svg {
      display: none;
    }

    p {
      font-size: 0.8rem;
    }
  }
`;
