import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { accountMock } from "@/core/mocks/accountMock";
import { UserAccount } from "@/core/models/account.model";
import { RootState } from "@/core/store/store";
import { sleep } from "@/utils/sleep";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ParamsOfAccountFormData } from "../hooks/paramsOfAccountFormSchema";

interface AccountState {
  has500error: boolean;
  account: UserAccount;
  status: "loading" | "success" | "failed";
}

const initialState = {
  has500error: false,
  account: {},
  status: "loading",
} as AccountState;

export const getAccount = createAsyncThunk(
  "account/getAccount",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return accountMock;
    } else {
      try {
        const response = await axios.get(`/account/params`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface UpdateAccountParamsProps {
  params: ParamsOfAccountFormData;
}

export const updateAccountParams = createAsyncThunk(
  "account/updateAccountParams",
  async ({ params }: UpdateAccountParamsProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.put(`/account/params`, params);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const accountSlice = createSlice({
  name: "accountSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccount.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(
      getAccount.fulfilled,
      (state, action: PayloadAction<UserAccount>) => {
        state.status = "success";
        state.account = action.payload;
      }
    );
    builder.addCase(
      getAccount.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting account info");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateAccountParams.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(
      updateAccountParams.fulfilled,
      (state, action: PayloadAction<UserAccount>) => {
        state.status = "success";
        state.account = action.payload;
      }
    );
    builder.addCase(
      updateAccountParams.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating account info");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectAccount = (state: RootState) => state.accountState.account;
export const selectAccountStatus = (state: RootState) =>
  state.accountState.status;
export const selectAccountHas500error = (state: RootState) =>
  state.accountState.has500error;

export const accountReducer = accountSlice.reducer;
