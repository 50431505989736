import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../core/store/store";

interface EmitEventToChangeClientsState {
  status: boolean;
}

const initialState = {
  status: false,
} as EmitEventToChangeClientsState;

export const emitEventToChangeClientsFiltersSlice = createSlice({
  name: "emitEventToChangeClientsSlice",
  initialState,
  reducers: {
    emitEventToChangeClientsFilters(state, action: PayloadAction<boolean>) {
      state.status = action.payload;
    },
  },
});

export const selectStatusOfEmitEventToChangeClientsFilters = (
  state: RootState
) => state.emitEventToChangeClientsFiltersState.status;

export const { emitEventToChangeClientsFilters } =
  emitEventToChangeClientsFiltersSlice.actions;

export const emitEventToChangeClientsFiltersReducer =
  emitEventToChangeClientsFiltersSlice.reducer;
