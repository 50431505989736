import { DaysWeekPref } from "@/core/models/params.model";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { HighPref } from "./HighPref";
import { LowPref } from "./LowPref";
import { NonePref } from "./NonePref";
import { NormalPref } from "./NormalPref";
import { Container } from "./styles";

interface Day {
  prop: string;
  name: string;
}

const days: Day[] = [
  { prop: "mon_pref", name: "seg" },
  { prop: "tue_pref", name: "ter" },
  { prop: "wed_pref", name: "qua" },
  { prop: "thu_pref", name: "qui" },
  { prop: "fri_pref", name: "sex" },
  { prop: "sat_pref", name: "sab" },
  { prop: "sun_pref", name: "dom" },
];

interface PreferedDaysProps {
  daysWeekPrefReceived: DaysWeekPref;
  isBlocked?: boolean;
}

export function ShowPreferedDays({
  daysWeekPrefReceived,
  isBlocked = true,
}: PreferedDaysProps) {
  const [daysWeekPref, setDaysWeekPref] = useState<DaysWeekPref>(
    {} as DaysWeekPref
  );

  useEffect(() => {
    if (!isEmpty(daysWeekPrefReceived)) {
      setDaysWeekPref(daysWeekPrefReceived);
    } else {
      setDaysWeekPref({
        mon_pref: 0,
        tue_pref: 0,
        wed_pref: 0,
        thu_pref: 0,
        fri_pref: 0,
        sat_pref: 0,
        sun_pref: 0,
      } as any);
    }
  }, [daysWeekPrefReceived]);

  const calculatePreference = (prop: string, day: string) => {
    if (daysWeekPref) {
      if (daysWeekPref[prop] && daysWeekPref[prop] === 1)
        return <LowPref day={day} prop={prop} />;
      else if (daysWeekPref[prop] && daysWeekPref[prop] === 2)
        return <NormalPref day={day} prop={prop} />;
      else if (daysWeekPref[prop] && daysWeekPref[prop] === 3)
        return <HighPref day={day} prop={prop} />;
      else return <NonePref day={day} prop={prop} />;
    } else {
      return <NonePref day={day} prop={prop} />;
    }
  };

  return (
    <Container $isBlocked={isBlocked}>
      {days.map((day) => (
        <div key={day.prop}>{calculatePreference(day.prop, day.name)}</div>
      ))}
    </Container>
  );
}
