import { Button, DialogClose, Flex, Heading, Text } from "@gogeo-io/ui-library";

interface DeleteBusinessGoalConfirmationProps {
  onDeleteBusinessGoal: () => void;
}

export function DeleteBusinessGoalConfirmation({
  onDeleteBusinessGoal,
}: DeleteBusinessGoalConfirmationProps) {
  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5rem",
        textAlign: "center",
      }}
    >
      <Heading>Você tem certeza?</Heading>
      <Text>
        Tem certeza que quer excluir esse objetivo de negócio? Essa operação não
        poderá ser desfeita.
      </Text>

      <Flex css={{ gap: "0.5rem", marginTop: "0.75rem" }}>
        <DialogClose asChild>
          <Button color="gray" onClick={(event) => event.stopPropagation()}>
            Cancelar
          </Button>
        </DialogClose>
        <DialogClose asChild>
          <Button
            color="danger"
            onClick={(event) => {
              event.stopPropagation();
              onDeleteBusinessGoal();
            }}
          >
            Excluir
          </Button>
        </DialogClose>
      </Flex>
    </Flex>
  );
}
