import styled from "styled-components";

export const ReminderFormTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    font-size: 25px;
    font-weight: bold;
  }
`;
