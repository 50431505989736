import { ClientParamsResponse } from "@/core/models/client.model";
import { RootState } from "@/core/store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ClientSelectedToChangeParamsState {
  clients: ClientParamsResponse[];
  status: "loading" | "success";
  allClientsSelectedToChangeParams: boolean;
}

const initialState = {
  clients: [],
  status: "loading",
  allClientsSelectedToChangeParams: false,
} as ClientSelectedToChangeParamsState;

export const clientSelectedToChangeParamsSlice = createSlice({
  name: "clientSelectedToChangeParamsSlice",
  initialState,
  reducers: {
    selectClientsToChangeParams(
      state,
      action: PayloadAction<ClientParamsResponse[]>
    ) {
      state.clients = action.payload;
      state.status = "success";
    },
    selectClientToChangeParams(
      state,
      action: PayloadAction<ClientParamsResponse>
    ) {
      state.clients.push(action.payload);
      state.status = "success";
    },
    removeAllClientToChangeParams(state) {
      state.clients = [];
      state.status = "success";
    },
    removeClientToChangeParams(
      state,
      action: PayloadAction<ClientParamsResponse>
    ) {
      const stateWithoutRemovedOne = state.clients.filter(
        (client) => client.client.id !== action.payload.client.id
      );
      state.clients = stateWithoutRemovedOne;
    },
    setAllClientsSelectedToChangeParams(state) {
      state.allClientsSelectedToChangeParams =
        !state.allClientsSelectedToChangeParams;
    },
  },
});

export const selectClientSelectedToChangeParams = (state: RootState) =>
  state.clientSelectedToChangeParamsState.clients;
export const selectAllClientsSelectedToChangeParams = (state: RootState) =>
  state.clientSelectedToChangeParamsState.allClientsSelectedToChangeParams;

export const {
  selectClientToChangeParams,
  selectClientsToChangeParams,
  setAllClientsSelectedToChangeParams,
  removeClientToChangeParams,
  removeAllClientToChangeParams,
} = clientSelectedToChangeParamsSlice.actions;

export const clientSelectedToChangeParamsReducer =
  clientSelectedToChangeParamsSlice.reducer;
