import { ListDivider } from "@/components/ListDivider";
import { Flex, Text } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { ListIncorrectPropsToCorrect } from "./ListIncorrectPropsToCorrect";
import { UsersToCreateDialog } from "./UsersToCreateDialog";

interface UsersToValidateContentProps {
  data: any[];
  onCloseDialog: () => void;
}

const correctProps = [
  "email",
  "password",
  "salesman_id",
  "role",
  "name",
  "phone",
  "skip_scheduling",
  "max_visit",
  "access_all_clients",
  "allow_change_params",
  "salesman_type",
];

export function UsersToValidateContent({
  data,
  onCloseDialog,
}: UsersToValidateContentProps) {
  const [dataIsValid, setDataIsValid] = useState(false);
  const [initialDataIsValid, setInitialDataIsValid] = useState(false);
  const [incorrectProps, setIncorretProps] = useState<string[]>([]);

  const checkInitialDataValidity = () => {
    let isValid = true;
    const incorrect = [];

    const firstUser = data[0];

    for (const prop in firstUser) {
      if (!correctProps.includes(prop)) {
        incorrect.push(prop);
        isValid = false;
      }
    }

    if (!isValid) {
      setInitialDataIsValid(false);
      setIncorretProps(incorrect);
    } else {
      setInitialDataIsValid(true);
    }
  };

  const checkDataValidity = () => {
    let isValid = true;
    const incorrect = [];

    const firstUser = data[0];

    for (const prop in firstUser) {
      if (!correctProps.includes(prop)) {
        incorrect.push(prop);
        isValid = false;
      }
    }

    if (!isValid) {
      setDataIsValid(false);
    } else {
      setDataIsValid(true);
    }
  };

  const handleCorrection = (incorrectProp: string, correctProp: string) => {
    data.forEach((item) => {
      if (item.hasOwnProperty(incorrectProp)) {
        item[correctProp] = item[incorrectProp];
        delete item[incorrectProp];
      }
    });

    checkDataValidity();
  };

  useEffect(() => {
    checkInitialDataValidity();
  }, []);

  return (
    <Flex css={{ flexDirection: "column", gap: "$4" }}>
      <Flex css={{ flexDirection: "column", gap: "$4" }}>
        <Text css={{ color: "$red600" }}>
          * Não conseguimos identificar os seguintes campos:
        </Text>

        <Flex css={{ gap: "$4", alignItems: "center" }}>
          <Text css={{ width: "100px", color: "$gray900" }}>Colunas</Text>
          <Flex css={{ gap: "$2", width: "200px", color: "$gray900" }}>
            <Text>Campo esperado</Text>
          </Flex>
        </Flex>

        <ListIncorrectPropsToCorrect
          correctProps={correctProps}
          incorrectProps={incorrectProps}
          onSelectCorrectProps={handleCorrection}
        />

        <ListDivider />

        <UsersToCreateDialog
          initialDataIsValid={initialDataIsValid}
          dataIsValid={dataIsValid}
          data={data}
          onCloseUsersCreateModal={onCloseDialog}
        />
      </Flex>
    </Flex>
  );
}
