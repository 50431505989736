import { CellHeaderWithoutSort } from "@/components/CustomCells/CellHeaderWithoutSort";
import { SalesmanToRuptureRule } from "@/core/models/user.model";
import { UserEmailCell } from "@/features/users/components/cells/UserEmailCell";
import { UserPhoneCell } from "@/features/users/components/cells/UserPhoneCell";
import { Text } from "@gogeo-io/ui-library";
import { useMemo } from "react";
import { RuptureRuleConsumersActionsCell } from "../components/RuptureRuleConsumersActionsCell";

export const useRuptureRuleConsumersColumns = (
  onRemoveUser: (user: SalesmanToRuptureRule) => void,
  onEditUser: (user: SalesmanToRuptureRule) => void
) => {
  const userIdColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Código" />,
        dataIndex: "user",
        key: "id",
        render: (_, record: SalesmanToRuptureRule) => (
          <Text
            size="body2"
            css={{
              textTransform: "capitalize",
              maxWidth: "200px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "$gray700",
            }}
          >
            {record.salesman_id}
          </Text>
        ),
      },
    ];
  }, []);

  const userNameColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Vendedor" />,
        dataIndex: "user",
        key: "name",
        render: (_, record: SalesmanToRuptureRule) => (
          <Text
            size="body2"
            css={{
              textTransform: "capitalize",
              maxWidth: "200px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: "$gray700",
            }}
          >
            {record.name}
          </Text>
        ),
      },
    ];
  }, []);

  const userEmailColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Email" />,
        dataIndex: "user",
        key: "email",
        render: (_, record: SalesmanToRuptureRule) => (
          <UserEmailCell email={record.email} />
        ),
      },
    ];
  }, []);

  const userPhoneColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Telefone" />,
        dataIndex: "user",
        key: "phone",
        render: (_, record: SalesmanToRuptureRule) => (
          <UserPhoneCell phone={record.phone} />
        ),
      },
    ];
  }, []);

  const userActionsColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Ações" />,
        dataIndex: "",
        key: "actions",
        render: (text: string, record: SalesmanToRuptureRule) => (
          <RuptureRuleConsumersActionsCell
            user={record}
            onEditUser={onEditUser}
            onRemoveUser={onRemoveUser}
          />
        ),
        width: "70px",
      },
    ];
  }, []);

  return [
    ...userIdColumn,
    ...userNameColumn,
    ...userEmailColumn,
    ...userPhoneColumn,
    // ...userActionsColumn,
  ];
};
