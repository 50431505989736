import {
  Caption,
  Flex,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";

interface UserNameCellProps {
  name: string;
  id?: string;
}

export function UserNameCell({ name, id }: UserNameCellProps) {
  return (
    <>
      {name && (
        <Flex>
          <Flex css={{ flexDirection: "column", width: "100%", gap: "0rem" }}>
            <Tooltip>
              <TooltipTrigger
                style={{ background: "transparent", textAlign: "left" }}
              >
                <Text
                  size="body2"
                  css={{
                    textTransform: "capitalize",
                    maxWidth: "200px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    color: "$gray700",
                  }}
                >
                  {name.toLowerCase()}
                </Text>
              </TooltipTrigger>
              <TooltipContent aria-label={`Nome do cliente é ${name}`}>
                {name}
              </TooltipContent>
            </Tooltip>

            {!!id && <Caption css={{ color: "$gray500" }}>#{id}</Caption>}
          </Flex>
        </Flex>
      )}
    </>
  );
}
