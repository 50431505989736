import { Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";

interface SelectOptionProps {
  optionSelected: string;
  setOptionSelected: (value: string) => void;
}

export function WeekRestrictionSelectOption({
  optionSelected,
  setOptionSelected,
}: SelectOptionProps) {
  return (
    <Select
      defaultValue="Irrestrito"
      value={optionSelected === "indeterminate" ? "Irrestrito" : optionSelected}
      ariaLabel="Select option"
      onValueChange={setOptionSelected}
    >
      <SelectItem value="Irrestrito">
        <SelectItemText>Irrestrito</SelectItemText>
      </SelectItem>
      <SelectItem value="A partir da">
        <SelectItemText>A partir da</SelectItemText>
      </SelectItem>
      <SelectItem value="Até">
        <SelectItemText>Até</SelectItemText>
      </SelectItem>
    </Select>
  );
}
