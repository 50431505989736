import { NoPageSelected } from "@/components/NoPageSelected";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Main } from "@/layouts/Main";
import ReportsImage from "@assets/reports.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { selectSelectedReport, setSelectedReport } from "../store/reportSlice";
import { selectReports } from "../store/reportsSlice";
import { ReportSkeletonPage } from "./ReportSkeletonPage";

export const ReportLoaded = () => {
  const [reportIsLoaded, setReportIsLoaded] = useState(false);
  const [reportIsFinded, setReportIsFinded] = useState(false);

  const selectedReport = useSelector(selectSelectedReport);
  const reports = useSelector(selectReports);

  const dispatch = useAppDispatch();
  const { dashboard_name } = useParams();

  useEffect(() => {
    async function loadReports() {
      if (Object.keys(selectedReport).length <= 0) {
        const findedReport = reports.find(
          (report) => report.dashboard_name === dashboard_name
        );

        if (findedReport) {
          await dispatch(setSelectedReport(findedReport));
          setReportIsLoaded(true);
          setReportIsFinded(true);
        } else {
          setReportIsLoaded(true);
          setReportIsFinded(false);
        }
      } else {
        setReportIsLoaded(true);
        setReportIsFinded(true);
      }
    }

    loadReports();
  }, []);

  if (!reportIsFinded) {
    return (
      <Main>
        <NoPageSelected
          src={ReportsImage}
          alt="Selecione um relatório"
          title="Selecione um relatório"
        >
          <span>
            Relatório não encontrado, selecione um relatório e acompanhe o
            rendimento de seu time
          </span>
        </NoPageSelected>
      </Main>
    );
  }

  return <>{!reportIsLoaded ? <ReportSkeletonPage /> : <Outlet />}</>;
};
