import {
  ListOfTags,
  ListOfTagsFilter,
  SearchFilters,
} from "@/core/models/listOfTags.model";
import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import {
  cleanColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  IconButton,
} from "@gogeo-io/ui-library";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAttendanceMixpanel } from "../../hooks/useAttendanceMixpanel";
import {
  addTagsOnSelectedClientFilter,
  setSelectedAdvancedFilters,
} from "../../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClientsFilters } from "../../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../../store/emitEventToChangeClientsSlice";
import {
  addListOnState,
  createListOfTags,
  updateListFromState,
} from "../../store/listOfTagsSlice";
import { selectList, selectSelectedList } from "../../store/listSelectedSlice";
import { setSelectedSearchFilters } from "../../store/selectedSearchFiltersSlice";
import { SaveListAsDialog } from "./SaveListAsDialog";
import { SaveListDialog } from "./SaveListDialog";

interface AdvancedFiltersActionsProps {
  onCleanFilters: () => Promise<void>;
}

export function AdvancedFiltersActions({
  onCleanFilters,
}: AdvancedFiltersActionsProps) {
  const [saveListAsDialogIsOpen, setSaveListAsDialogIsOpen] = useState(false);
  const [saveListDialogIsOpen, setSaveListDialogIsOpen] = useState(false);

  const selectedList = useSelector(selectSelectedList);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { updateListOfTagsEvent, createListOfTagsEvent } =
    useAttendanceMixpanel();
  const { paramsOnEditListView } = useGetClientsByFiltersParams();

  const handleSetSaveAsDialogIsOpen = (value: boolean) =>
    setSaveListAsDialogIsOpen(value);

  const handleSetSaveDialogIsOpen = (value: boolean) =>
    setSaveListDialogIsOpen(value);

  const setSelectedFilters = async (list: ListOfTags) => {
    const columnsToSort = list.filters?.sort?.orders;
    if (columnsToSort !== undefined && columnsToSort.length > 0) {
      await dispatch(setColumnsToSort(columnsToSort));
    } else {
      await dispatch(cleanColumnsToSort());
    }

    const advancedFilters = list.filters?.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = list.filters?.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    const attrOpts = list.filters?.attr_opts;
    if (attrOpts !== undefined) {
      await dispatch(setAttributesToFilter(attrOpts));
    } else {
      await dispatch(setDefaultAttributesToFilter());
    }

    const searchFilters: SearchFilters = {
      match_type: "Geral",
      match_value: "",
    };

    await dispatch(setSelectedSearchFilters(searchFilters));
  };

  const handleCreateList = async (list_name: string, description: string) => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClientsFilters(false));

    let filters: ListOfTagsFilter = {};

    filters = paramsOnEditListView();
    delete filters.match;

    if (list_name === "")
      return toast.error("Dê um nome a lista antes de salvar");

    const args = {
      name: list_name,
      description,
      filters,
    };

    await dispatch(createListOfTags(args)).then(async (value) => {
      if (value.payload.status === 409) {
        return;
      }

      if (value.meta.requestStatus === "fulfilled") {
        const createdList: ListOfTags = value.payload;

        toast.success("Segmentação criada com sucesso");

        createListOfTagsEvent(createdList);
        await dispatch(addListOnState(createdList));
        await dispatch(selectList(createdList));
        await setSelectedFilters(createdList);
        await dispatch(emitEventToChangeClients(true));
        await dispatch(emitEventToChangeClientsFilters(true));

        navigate(`/attendance/${createdList.slug}`);

        await dispatch(emitEventToChangeClients(false));
        await dispatch(emitEventToChangeClientsFilters(false));
      }
    });
  };

  const handleUpdateList = async (list_name: string, description: string) => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClientsFilters(false));

    let filters: ListOfTagsFilter = {};

    filters = paramsOnEditListView();
    delete filters.match;

    const args = {
      id: selectedList.id,
      name: list_name,
      description,
      filters,
    };

    await dispatch(createListOfTags(args)).then(async (value) => {
      if (value.meta.requestStatus === "fulfilled") {
        const createdList: ListOfTags = value.payload;

        toast.success("Segmentação alterada com sucesso");

        updateListOfTagsEvent(selectedList, value.payload);

        await dispatch(selectList(createdList));
        await dispatch(updateListFromState(createdList));
        await setSelectedFilters(createdList);
        await dispatch(emitEventToChangeClients(true));
        await dispatch(emitEventToChangeClientsFilters(true));

        navigate(`/attendance/${createdList.slug}`);
        await dispatch(emitEventToChangeClientsFilters(false));
      }
    });
  };

  const handleCancel = async () => {
    onCleanFilters();
    await dispatch(emitEventToChangeClientsFilters(true));
  };

  return (
    <>
      <Flex css={{ gap: "$4" }}>
        <Button fullWidth bordered onClick={handleCancel}>
          Cancelar
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button fullWidth>Salvar</Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={5}>
            <DropdownMenuItem
              disabled={selectedList.is_suggested_by_govendas}
              onClick={() => setSaveListDialogIsOpen(true)}
            >
              Salvar
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setSaveListAsDialogIsOpen(true)}>
              Salvar como
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Flex>

      <Dialog
        open={saveListDialogIsOpen}
        onOpenChange={handleSetSaveDialogIsOpen}
      >
        <DialogTrigger />
        <DialogContent style={{ width: "450px" }}>
          <DialogTitle>Salvar Segmentação</DialogTitle>

          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
            >
              <Close />
            </IconButton>
          </DialogClose>

          <SaveListDialog onSaveAsList={handleUpdateList} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={saveListAsDialogIsOpen}
        onOpenChange={handleSetSaveAsDialogIsOpen}
      >
        <DialogTrigger />
        <DialogContent style={{ width: "450px" }}>
          <DialogTitle>Salvar Nova Segmentação</DialogTitle>

          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
            >
              <Close />
            </IconButton>
          </DialogClose>

          <SaveListAsDialog onSaveAsList={handleCreateList} />
        </DialogContent>
      </Dialog>
    </>
  );
}
