import { ProgressCircle } from "@/components/ProgressCircle";
import { Flex, Text } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";

interface ByUpAndDownProps {
  text: string;
  tag_value: number;
}

export function ByProgress({ text, tag_value }: ByUpAndDownProps) {
  return (
    <G_UI.ProductTagLabel>
      <Flex css={{ width: "20px", height: "20px" }}>
        <ProgressCircle value={tag_value} />
      </Flex>
      <Text>{text}</Text>
    </G_UI.ProductTagLabel>
  );
}
