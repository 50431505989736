export const convertRichTextToString = (textToMap) => {
  let output = "";

  textToMap.forEach((rootEntry) => {
    if (rootEntry.text) {
      output += rootEntry.text;
    } else if (rootEntry.children && Array.isArray(rootEntry.children)) {
      output += convertRichTextToString(rootEntry.children);
    }
  });

  return output;
};
