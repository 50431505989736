import { EmptyContent } from "@/components/EmptyContent";
import { User } from "@/core/models/user.model";
import { Table } from "antd";
import { isEmpty } from "lodash";
import styled from "styled-components";

interface RelatedUsersTableProps {
  columns: any;
  data: any;
  status: string;
}

export function RelatedUsersTable({
  columns,
  data,
  status,
}: RelatedUsersTableProps) {
  const generateRowKey = (record: User) => {
    return `${record.id}-${record.account_id}`;
  };

  return (
    <>
      {!isEmpty(data) ? (
        <Wrapper>
          <Table
            columns={columns}
            dataSource={data}
            loading={status === "loading"}
            pagination={false}
            rowKey={generateRowKey}
            sticky={{ offsetHeader: 0 }}
            scroll={{ x: "100%" }}
          />
        </Wrapper>
      ) : (
        <>
          <EmptyContent size="small">Nenhum usuário encontrado</EmptyContent>
        </>
      )}
    </>
  );
}

const Wrapper = styled.div`
  height: 400px;
  overflow: scroll;

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    height: 250px;
  }

  @media (max-width: ${(props) => props.theme.mediaSizes.smallLaptop}) {
    height: 200px;
  }
`;
