import { FormInputField } from "@/components/form/FormInputField";
import { FormLabel } from "@/components/form/FormLabel";
import { Rule, RuptureRule } from "@/core/models/rules";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Checkbox,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
  Input,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  gvTheme,
} from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { MdDeleteOutline, MdInfoOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { DeleteRuptureRuleConfirmation } from "../components/DeleteRuptureRuleConfirmation";
import { RuptureRuleConsumers } from "../components/RuptureRuleConsumers";
import { ruptureRulesSchema } from "../hooks/ruptureRulesSchema";
import { useBreadcrumbsForRuptureRulePage } from "../hooks/useBreadcrumbsForRuptureRulePage";
import { useCleanAndConvertRules } from "../hooks/useCleanAndConvertRules";
import { useRuptureRuleMixpanel } from "../hooks/useRuptureRuleMixpanel";
import { useSetValuesForFormOfRuptureRule } from "../hooks/useSetValuesForFormOfRuptureRule";
import {
  getRuptureRule,
  selectRuptureRule,
  selectSelectedRuptureRule,
  setSelectRuptureRule,
  updateRuptureRule,
} from "../store/ruptureRuleSlice";
import { RuptureRuleSkeletonPage } from "./RuptureRuleSkeletonPage";

export function RuptureRulePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const rule = useSelector(selectRuptureRule);
  const selectedRuptureRule = useSelector(selectSelectedRuptureRule);

  const dispatch = useAppDispatch();
  const setValuesOfRuptureRule = useSetValuesForFormOfRuptureRule();
  const convertRules = useCleanAndConvertRules();
  const navigate = useNavigate();
  const { rupture_rule } = useParams();
  const { breacrumbsToRuptureRulePage } = useBreadcrumbsForRuptureRulePage();
  const {
    ruptureRuleSelectedEvent,
    ruptureRuleUpdatedEvent,
    ruptureRuleDeleteEvent,
  } = useRuptureRuleMixpanel();

  const { handleSubmit, control, reset, setValue } = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(ruptureRulesSchema),
    defaultValues: {
      name: "",
      producers: [],
      consumers: [],
      schedule_prospects: false,
      rules: {
        sales_rupture_lower_bound: "",
        sales_rupture_upper_bound: "",
        visit_rupture_lower_bound: "",
        visit_rupture_upper_bound: "",
        generic_filter: "",
      },
    },
  });

  const onSubmit: SubmitHandler<RuptureRule> = async (
    data: RuptureRule
  ): Promise<void> => {
    setSubmitLoading(true);

    data.rules = convertRules(data.rules);

    let ruptureRuleId: number;

    const foundIndex = rule.rupture_rule.relationships.findIndex(
      (rel) => rel.id === data.id
    );

    let updatedRelationships;

    if (foundIndex !== -1) {
      updatedRelationships = rule.rupture_rule.relationships.map((rel, index) =>
        index === foundIndex ? { ...data } : rel
      );
      ruptureRuleId = foundIndex;
    } else {
      const id = uuidv4();
      updatedRelationships = [
        ...rule.rupture_rule.relationships,
        { ...data, id },
      ];
      ruptureRuleId = id;
    }

    await dispatch(
      updateRuptureRule({
        rule_id: rule.id,
        relationships: updatedRelationships,
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("regra alterada com sucesso");
        ruptureRuleUpdatedEvent(rule.id, ruptureRuleId, updatedRelationships);

        await dispatch(getRuptureRule()).then(async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            await dispatch(setSelectRuptureRule(data));
            navigate(`/profile/rupture-rule/${rule.id}`);
          }
        });
      }
    });

    setSubmitLoading(false);
  };

  const deleteRuptureRule = async (ruptureRuleToRemove: RuptureRule) => {
    const ruptureRulesExceptedRuleForRemove =
      rule.rupture_rule.relationships.filter((ruptureRule) => {
        return ruptureRule.id !== ruptureRuleToRemove.id;
      });

    await dispatch(
      updateRuptureRule({
        rule_id: rule.id,
        relationships: ruptureRulesExceptedRuleForRemove,
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("regra removida com sucesso");
        ruptureRuleDeleteEvent(
          rule.id,
          ruptureRuleToRemove.id,
          ruptureRulesExceptedRuleForRemove
        );
        await dispatch(getRuptureRule()).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            const rule: Rule = res.payload[0] as Rule;
            if (rule.rupture_rule.relationships.length > 0) {
              navigate(
                `/profile/rupture-rule/${rule.rupture_rule.relationships[0].id}`
              );
            } else {
              navigate("/profile/rupture-rule/create-rupture-rule");
            }
          }
        });
      }
    });
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível alterar regra de ruptura");
    }
  };

  const handleReset = () => {
    reset({
      name: selectedRuptureRule.name ?? "",
      producers: selectedRuptureRule.producers ?? [],
      consumers: selectedRuptureRule.consumers ?? [],
      schedule_prospects: selectedRuptureRule.schedule_prospects ?? false,
      rules: {
        sales_rupture_lower_bound:
          selectedRuptureRule.rules?.sales_rupture_lower_bound ?? "",
        sales_rupture_upper_bound:
          selectedRuptureRule.rules?.sales_rupture_upper_bound ?? "",
        visit_rupture_lower_bound:
          selectedRuptureRule.rules?.visit_rupture_lower_bound ?? "",
        visit_rupture_upper_bound:
          selectedRuptureRule.rules?.visit_rupture_upper_bound ?? "",
        generic_filter: selectedRuptureRule.rules?.generic_filter ?? "",
      },
    });
  };

  useEffect(() => {
    setValuesOfRuptureRule(setValue, selectedRuptureRule);
    breacrumbsToRuptureRulePage(selectedRuptureRule.name);
    ruptureRuleSelectedEvent(selectedRuptureRule);
  }, [selectedRuptureRule]);

  useEffect(() => {
    setIsLoading(true);

    const handleSetSelectedRuptureRule = async () => {
      if (isEmpty(selectedRuptureRule)) {
        const findedRuptureRule = rule.rupture_rule.relationships.find(
          (ruptureRule) => ruptureRule.id === rupture_rule
        );

        if (!findedRuptureRule) {
          navigate("/rupture-rule");
        } else {
          await dispatch(setSelectRuptureRule(findedRuptureRule));
        }
      }
    };

    handleSetSelectedRuptureRule();

    setIsLoading(false);
  }, [selectedRuptureRule, rupture_rule]);

  return (
    <Container>
      {isLoading ? (
        <RuptureRuleSkeletonPage />
      ) : (
        <>
          <FormContainer>
            <Flex
              css={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Heading size="title6">Regras de multicanal</Heading>
              <Dialog>
                <DialogTrigger
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <MdDeleteOutline size={24} color={gvTheme.colors.gray500} />
                </DialogTrigger>
                <DialogContent style={{ width: "450px" }}>
                  <DialogClose asChild>
                    <IconButton
                      type="ghost"
                      css={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                      }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <AiOutlineClose />
                    </IconButton>
                  </DialogClose>

                  <DeleteRuptureRuleConfirmation
                    onDeleteRuptureRule={() =>
                      deleteRuptureRule(selectedRuptureRule)
                    }
                  />
                </DialogContent>
              </Dialog>
            </Flex>

            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$2" }}>
                    <Flex css={{ flexDirection: "column", gap: "$1" }}>
                      <Heading size="subtitle1">Nome da regra</Heading>
                      <Input
                        placeholder="Ex. Nova regra"
                        type="text"
                        isFullWidth
                        value={field.value}
                        {...field}
                      />
                    </Flex>
                    {!!error && (
                      <Text css={{ color: "$red500" }}>{error.message}</Text>
                    )}
                  </Flex>
                );
              }}
            />

            <Flex css={{ flexDirection: "column", gap: "$2" }}>
              <Heading size="subtitle1">
                Defina o período de Ruptura ( em dias )
              </Heading>

              <Flex css={{ gap: "$4", alignItems: "center" }}>
                <Text css={{ width: "150px" }}>Ruptura de Visita</Text>

                <Controller
                  control={control}
                  name="rules.visit_rupture_lower_bound"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <FormLabel label="Início" />

                        <FormInputField
                          placeholder="Ex. 25"
                          error={error}
                          field={field}
                          type="number"
                        />
                      </Flex>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="rules.visit_rupture_upper_bound"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <FormLabel label="Fim" />
                        <FormInputField
                          placeholder="Ex. 90"
                          error={error}
                          field={field}
                          type="number"
                        />
                      </Flex>
                    );
                  }}
                />
              </Flex>

              <Flex css={{ gap: "$4", alignItems: "center" }}>
                <Text css={{ width: "150px" }}>Ruptura de Positivação</Text>

                <Controller
                  control={control}
                  name="rules.sales_rupture_lower_bound"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <FormLabel label="Início" />
                        <FormInputField
                          placeholder="Ex. 25"
                          error={error}
                          field={field}
                          type="number"
                        />
                      </Flex>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="rules.sales_rupture_upper_bound"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <FormLabel label="Fim" />
                        <FormInputField
                          placeholder="Ex. 25"
                          error={error}
                          field={field}
                          type="number"
                        />
                      </Flex>
                    );
                  }}
                />
              </Flex>
            </Flex>

            <Controller
              control={control}
              name="rules"
              render={({ fieldState: { error } }) => {
                return (
                  <>
                    {!!error && (
                      <Text css={{ color: "$red500" }}>{error.message}</Text>
                    )}
                  </>
                );
              }}
            />

            <Controller
              control={control}
              name="schedule_prospects"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$2" }}>
                    <Flex css={{ alignItems: "center", gap: "$1" }}>
                      <Checkbox
                        checked={value}
                        onCheckedChange={(e) => onChange(Boolean(e))}
                      />
                      <Heading size="subtitle1">Agendar prospects</Heading>
                    </Flex>

                    {!!error && (
                      <Text css={{ color: "$red500" }}>{error.message}</Text>
                    )}
                  </Flex>
                );
              }}
            />

            <Controller
              control={control}
              name="producers"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$2" }}>
                    <Flex css={{ alignItems: "center", gap: "$1" }}>
                      <Heading size="subtitle1">
                        Defina os vendedores que terão a ruptura monitorada
                      </Heading>
                      <Tooltip>
                        <TooltipTrigger
                          style={{
                            background: "transparent",
                          }}
                          onClick={(e) => e.preventDefault()}
                        >
                          <MdInfoOutline />
                        </TooltipTrigger>
                        <TooltipContent aria-label="Vendedores com clientes que deixaram de comprar ou não foram visitados num determinado periódo que serão monitorados por essa regra">
                          Vendedores com clientes que deixaram de comprar ou não
                          foram visitados num determinado periódo que serão
                          monitorados por essa regra
                        </TooltipContent>
                      </Tooltip>
                    </Flex>
                    <RuptureRuleConsumers
                      selectedConsumers={value}
                      onChange={onChange}
                    />

                    {!!error && (
                      <Text css={{ color: "$red500" }}>{error.message}</Text>
                    )}
                  </Flex>
                );
              }}
            />

            <Controller
              control={control}
              name="consumers"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$2" }}>
                    <Flex css={{ alignItems: "center", gap: "$1" }}>
                      <Heading size="subtitle1">
                        Defina os vendedores que atenderão clientes em ruptura
                      </Heading>
                      <Tooltip>
                        <TooltipTrigger
                          style={{ background: "transparent" }}
                          onClick={(e) => e.preventDefault()}
                        >
                          <MdInfoOutline />
                        </TooltipTrigger>
                        <TooltipContent aria-label="Vendedores com clientes que deixaram de comprar ou não foram visitados num determinado periódo que serão monitorados por essa regra">
                          Vendedores na retaguarda da comercial que irão atender
                          os clientes em ruptura. Os clientes em ruptura
                          complementam a carteira do vendedor.
                        </TooltipContent>
                      </Tooltip>
                    </Flex>
                    <RuptureRuleConsumers
                      selectedConsumers={value}
                      onChange={onChange}
                    />

                    {!!error && (
                      <Text css={{ color: "$red500" }}>{error.message}</Text>
                    )}
                  </Flex>
                );
              }}
            />

            <Controller
              control={control}
              name="rules.generic_filter"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    <Heading size="subtitle1">
                      Defina os filtros genéricos
                    </Heading>
                    <FormInputField
                      placeholder="Ex. (defaulting == True) and activity_id not in (1, 2, 3, 30, 34, 103)"
                      error={error}
                      field={field}
                      type="text"
                    />
                  </Flex>
                );
              }}
            />
          </FormContainer>

          <Flex
            css={{
              alignItems: "flex-end",
              justifyContent: "flex-end",
              gap: "$2",
              mt: "$4",
            }}
          >
            <Button bordered onClick={handleReset}>
              Limpar alterações
            </Button>
            <Button
              isLoading={submitLoading}
              onClick={() => handleSubmit(onSubmit, onError)()}
            >
              Salvar
            </Button>
          </Flex>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[6]};
  width: 100%;
  overflow-y: scroll;
  padding: 4px;
`;
