import { CustomColorPicker } from "@/components/CustomColorPicker";
import { BusinessGoalCategorie } from "@/core/models/business-goal.model";
import {
  Button,
  Caption,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  IconButton,
  Input,
} from "@gogeo-io/ui-library";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectBusinessGoalsStatus } from "../store/businessGoalsSlice";
import { FloatButtonToCreate } from "./FloatButtonToCreate";

interface CreateBusinessGoalProps {
  onCreateBusinessGoal: (
    name: string,
    color: string,
    categories: BusinessGoalCategorie[]
  ) => Promise<void>;
}

export function CreateBusinessGoal({
  onCreateBusinessGoal,
}: CreateBusinessGoalProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newBusinessGoal, setNewBusinessGoal] = useState("");
  const [selectedColor, setSelectedColor] = useState("blue");
  const [categories, setCategories] = useState<BusinessGoalCategorie[]>([
    {
      id: 1,
      field: "Não Vendi",
      mapped_field: "Não Vendi",
    },
    {
      id: 2,
      field: "Vendi",
      mapped_field: "Vendi",
    },
    {
      id: 3,
      field: "Em andamento",
      mapped_field: "Em andamento",
    },
  ]);

  const businessGoalsStatus = useSelector(selectBusinessGoalsStatus);

  const handleCategorieNameChange = (newCategorie: BusinessGoalCategorie) => {
    setCategories((prevItems) => {
      const itemIndex = prevItems.findIndex(
        (item) => item.id === newCategorie.id
      );

      if (itemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems[itemIndex] = newCategorie;
        return updatedItems;
      }

      return prevItems;
    });
  };

  const handleCreateBusinessGoal = () => {
    if (newBusinessGoal === "") {
      toast.error("O objetivo de negócio não pode ser vazio");
      return;
    }

    onCreateBusinessGoal(newBusinessGoal, selectedColor, categories).then(
      () => {
        if (businessGoalsStatus === "success") {
          setModalIsOpen(false);
        }
      }
    );
  };

  const handleSetNewBusinessGoal = (text: string) => setNewBusinessGoal(text);

  const handleDialogOpen = (value: boolean) => setModalIsOpen(value);

  const handleSetSelectedColor = (color: string) => setSelectedColor(color);

  return (
    <Dialog onOpenChange={handleDialogOpen} open={modalIsOpen}>
      <DialogTrigger>
        <FloatButtonToCreate />
      </DialogTrigger>
      <DialogContent>
        <DialogClose>
          <IconButton
            size="small"
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </DialogClose>
        <DialogTitle>Criar novo objetivo de negócio</DialogTitle>

        <Flex css={{ flexDirection: "column", gap: "$4", mt: "$2" }}>
          <Flex css={{ flexDirection: "column", gap: "$1", marginTop: "20px" }}>
            <Caption>Nome do Objetivo de negócio</Caption>
            <Input
              placeholder="Ex. Prospecção"
              onChange={(e) => handleSetNewBusinessGoal(e.target.value)}
              style={{ width: "100%" }}
            />
          </Flex>

          <Flex css={{ flexDirection: "column", gap: "$1", marginTop: "20px" }}>
            <Caption>Escolha uma cor</Caption>
            <CustomColorPicker
              selectedColor={selectedColor}
              onColorChange={(value) => handleSetSelectedColor(value)}
            />
          </Flex>

          <Flex css={{ flexDirection: "column", gap: "$2" }}>
            {categories.map((categorie) => {
              return (
                <Flex
                  css={{ alignItems: "center", gap: "$2", color: "$gray400" }}
                  key={categorie.field}
                >
                  <Input value={categorie.field} disabled />
                  <MdChevronRight color="inherit" />
                  <Input
                    defaultValue={categorie.mapped_field}
                    onChange={(e) =>
                      handleCategorieNameChange({
                        id: categorie.id,
                        field: categorie.field,
                        mapped_field: e.target.value,
                      } as BusinessGoalCategorie)
                    }
                  />
                </Flex>
              );
            })}
          </Flex>
        </Flex>

        <Flex
          css={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
            marginTop: "15px",
          }}
        >
          <Button
            onClick={handleCreateBusinessGoal}
            isLoading={businessGoalsStatus === "loading"}
          >
            Criar Objetivo de Negócio
          </Button>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
