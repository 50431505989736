import { UserOnRelationship } from "@/core/models/user.model";
import {
  selectSelectedUsersToAssociate,
  selectUserToAssociate,
  selectUserToDisassociate,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Checkbox } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface UserIsAssociatedCellProps {
  user: UserOnRelationship;
}

export function UserIsAssociatedCell({ user }: UserIsAssociatedCellProps) {
  const [checked, setChecked] = useState(false);

  const selectedUsersToAssociate = useSelector(selectSelectedUsersToAssociate);

  const dispatch = useAppDispatch();

  const handleCheckboxChange = async (
    isChecked: boolean,
    userToPerformActions: UserOnRelationship
  ) => {
    if (isChecked) {
      await dispatch(selectUserToAssociate(userToPerformActions));
    } else {
      await dispatch(selectUserToDisassociate(userToPerformActions));
    }
  };

  useEffect(() => {
    const checkedIsMarked = selectedUsersToAssociate.some(
      (u) => u.id === user.id
    );
    setChecked(checkedIsMarked);
  }, [selectedUsersToAssociate]);

  return (
    <>
      <Checkbox
        checked={checked}
        onCheckedChange={(checked) =>
          handleCheckboxChange(Boolean(checked), user)
        }
        css={{ cursor: "pointer" }}
      />
    </>
  );
}
