import styled from "styled-components";

export const TimelineAttendanceRecordHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
  font-size: ${({ theme }) => theme.fontSizes.sm};

  .top {
    width: 100%;
    display: flex;
    gap: ${(props) => props.theme.space[2]};
    align-items: center;
    justify-content: space-between;
  }

  .bottom {
    width: 100%;
    display: flex;
    gap: ${(props) => props.theme.space[2]};
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      line-height: 1.25rem;
    }

    .action {
      p {
        display: flex;
        align-items: center;
      }
    }

    svg {
      color: ${(props) => props.theme.colors.gray500};
    }
  }

  .observations {
    margin-top: ${(props) => props.theme.space[2]};
    display: flex;
    flex-direction: column;
  }
`;
