import { AttrOpts } from "@/core/models/listOfTags.model";
import {
  removeAttributeToFilter,
  selectAttrOptsToFilter,
  selectClientAttrOpts,
  setAttributeToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Caption,
  Checkbox,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { isEmpty, isNull, isUndefined } from "lodash";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useAttendanceMixpanel } from "../hooks/useAttendanceMixpanel";
import { emitEventToChangeClientsFilters } from "../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import { selectSelectedList } from "../store/listSelectedSlice";

export function SelectAttrOptsPopover() {
  const client_attributes = useSelector(selectClientAttrOpts);
  const attributes_to_filter = useSelector(selectAttrOptsToFilter);
  const selected_list = useSelector(selectSelectedList);

  const { attributeAddedEvent, attributeRemovedEvent } =
    useAttendanceMixpanel();
  const dispatch = useAppDispatch();

  const attributesToFilterHasAttribute = (attribute: AttrOpts): boolean => {
    return (
      attributes_to_filter.filter(
        (a) =>
          !isUndefined(a) && !isNull(a) && !isEmpty(a) && a.id === attribute.id
      ).length > 0
    );
  };

  const handleCheckboxChange = async (value: boolean, attribute: AttrOpts) => {
    await dispatch(emitEventToChangeClients(false));

    if (value) {
      await dispatch(setAttributeToFilter(attribute));
      attributeAddedEvent(attribute, selected_list);
    } else {
      await dispatch(removeAttributeToFilter(attribute));
      attributeRemovedEvent(attribute, selected_list);
    }

    await dispatch(emitEventToChangeClients(true));
    await dispatch(emitEventToChangeClientsFilters(true));
  };

  const cannotShowMoreColumns = (attribute: AttrOpts) =>
    attributes_to_filter.length === 5 &&
    !attributesToFilterHasAttribute(attribute);

  const renderColumnsCount = (attributes_to_filter: AttrOpts[]) => {
    if (attributes_to_filter.length <= 1) {
      return "";
    } else if (attributes_to_filter.length === 2) {
      return ` +1 coluna`;
    } else {
      return ` +${attributes_to_filter.length - 1} colunas`;
    }
  };
  const renderFirstAttributeName = (attributes_to_filter: AttrOpts[]) => {
    if (attributes_to_filter.length < 1) {
      return "Selecionar colunas";
    }
    return attributes_to_filter[0].name;
  };

  const getOtherSelectedColumns = (): AttrOpts[] => {
    return attributes_to_filter.slice(1);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Trigger>
          <Tooltip>
            <TooltipTrigger
              style={{
                background: "transparent",
                cursor: "pointer",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {renderFirstAttributeName(attributes_to_filter)}
            </TooltipTrigger>
            <TooltipContent
              aria-label={renderFirstAttributeName(attributes_to_filter)}
            >
              {renderFirstAttributeName(attributes_to_filter)}
            </TooltipContent>
          </Tooltip>
          <Flex css={{ flexDirection: "row", gap: "$1" }}>
            {attributes_to_filter.length > 1 && (
              <Tooltip>
                <TooltipTrigger
                  style={{
                    background: "transparent",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    minWidth: "60px",
                  }}
                >
                  {renderColumnsCount(attributes_to_filter)}
                </TooltipTrigger>
                <TooltipContent aria-label="Colunas selecionadas">
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    {getOtherSelectedColumns().map((attribute) => {
                      return <div key={attribute.id}>• {attribute.name}</div>;
                    })}
                  </Flex>
                </TooltipContent>
              </Tooltip>
            )}

            <Flex
              style={{
                background: "transparent",
                cursor: "pointer",
                whiteSpace: "nowrap",
                overflow: "hidden",
                minWidth: "14px",
              }}
            >
              <FaChevronDown color="inherit" />
            </Flex>
          </Flex>
        </Trigger>
      </PopoverTrigger>
      <PopoverContent align="center" css={{ padding: "0" }}>
        <ColumnsContent>
          {client_attributes.map((attribute) => {
            return (
              <Flex key={attribute.id} css={{ gap: "0.5rem" }}>
                {cannotShowMoreColumns(attribute) ? (
                  <Tooltip>
                    <TooltipTrigger style={{ background: "transparent" }}>
                      <Checkbox disabled />
                    </TooltipTrigger>
                    <TooltipContent aria-label="Você pode escolher no máximo 2 colunas">
                      Você pode escolher no máximo 5 colunas, remove uma pelo
                      menos para selecionar outra(s).
                    </TooltipContent>
                  </Tooltip>
                ) : (
                  <Checkbox
                    checked={attributesToFilterHasAttribute(attribute)}
                    onCheckedChange={(e) =>
                      handleCheckboxChange(Boolean(e), attribute)
                    }
                  />
                )}

                <Text>{attribute.name}</Text>
              </Flex>
            );
          })}
        </ColumnsContent>
        <Flex
          css={{
            padding: "0rem 1.25rem 1rem",
          }}
        >
          <Caption css={{ color: "$gray700" }}>
            * Para melhor atender as dimensões do seu dispositivo apenas 5
            informações adicionais poderão ser exibidas ao mesmo tempo
          </Caption>
        </Flex>
      </PopoverContent>
    </Popover>
  );
}

const Trigger = styled.div`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${(props) => props.theme.sizes[2]};
  font-size: 0.813rem;
  line-height: 1rem;
  height: 35px;
  gap: ${(props) => props.theme.sizes[2]};
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.gray800};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 5px;

  &:hover {
    background: ${(props) => props.theme.colors.gray100};
  }
`;

const ColumnsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 50vh;
  overflow-y: scroll;
  padding: 1.25rem;
`;
