import { RootState } from "@/core/store/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as movidesk from "../api/movidesk";
import Ticket, { CreateTicketRequestDTO } from "../model/ticket";

type SuportSlice = {
  tickets: Ticket[];
  status: "loading" | "success" | "failed";
};

const initialState: SuportSlice = {
  tickets: [],
  status: "loading",
};

export const listTicketsForMe = createAsyncThunk(
  "supportSlice/listTicketsForMe",
  async () => {
    const myTickets = await movidesk.listTicketsForUser();
    return myTickets;
  }
);

export const getTicketDetails = createAsyncThunk(
  "supportSlice/getTicketDetails",
  async (ticketId: string | number) => {
    const ticket = await movidesk.getTicketByID(ticketId.toString());
    return ticket;
  }
);

export const createTicket = createAsyncThunk(
  "supportSlice/createTicket",
  async (payload: CreateTicketRequestDTO) => {
    try {
      const created = await movidesk.createTicket(payload);
      const ticket: Ticket = {
        id: created.id,
        subject: `${payload.userName} - ${payload.title}`,
        createdDate: new Date().toDateString(),
        status: "Novo",
        tags: [],
      };
      return ticket;
    } catch (err: any) {
      console.error(err.message);
    }
  }
);

export const supportSlice = createSlice({
  initialState,
  name: "supportSlice",
  reducers: {},
  extraReducers: (builder) => {
    // List tickets
    builder.addCase(listTicketsForMe.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(listTicketsForMe.fulfilled, (state, action) => {
      state.status = "success";
      state.tickets = action.payload;
    });
    builder.addCase(listTicketsForMe.rejected, (state, action) => {
      state.status = "failed";
    });
    // Get Ticket Details
    builder.addCase(getTicketDetails.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getTicketDetails.fulfilled, (state, action) => {
      let added = false;
      state.status = "success";
      state.tickets.forEach((ticket) => {
        if (ticket.id === action.payload.id) {
          ticket = action.payload;
          added = true;
        }
      });
      if (!added) state.tickets.push(action.payload);
    });
    builder.addCase(getTicketDetails.rejected, (state, action) => {
      state.status = "failed";
    });
    // Create Ticket
    builder.addCase(createTicket.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createTicket.fulfilled, (state, action) => {
      state.status = "success";
      state.tickets.push(action.payload);
    });
    builder.addCase(createTicket.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const supportActions = supportSlice.actions;
export const supportReducer = supportSlice.reducer;

export const selectSupport = (state: RootState) => state.supportState;

export const selectSupportById = (state: RootState, id: number) =>
  state.supportState.tickets.find((i) => i.id === id);
