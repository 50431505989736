import { FilterTrigger } from "@/components/advancedFilters/FilterTrigger";
import {
  AdvancedFilter,
  SelectOption,
} from "@/core/models/advanced-filters.model";
import { useEffect, useState } from "react";

interface SelectFilterTriggerProps {
  filter: AdvancedFilter;
  hasError?: boolean;
  value: SelectOption;
  onRemoveFilter?: (filter: AdvancedFilter) => void;
  notShowCloseIcon?: boolean;
}

export function SelectFilterTrigger({
  filter,
  hasError = false,
  value: selectedValue,
  onRemoveFilter,
  notShowCloseIcon,
}: SelectFilterTriggerProps) {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(selectedValue.value);
  }, [selectedValue]);

  return (
    <FilterTrigger
      value={value}
      hasError={hasError}
      filter={filter}
      onRemoveFilter={onRemoveFilter}
      notShowCloseIcon={notShowCloseIcon}
    />
  );
}
