import { selectClient } from "@/features/clientInfo/store/clientSlice";
import {
  Button,
  Caption,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
  Input,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineClose } from "react-icons/ai";
import { BsFillPencilFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface UpdateCreateClientNoteHeaderProps {
  title: string;
  saveButtonTitle: string;
  onTitleChange: (newTitle: string) => void;
  onCreateNewClientNote: () => Promise<void>;
  clientNotesStatus: string;
}

export function UpdateCreateClientNoteHeader({
  title,
  saveButtonTitle,
  onTitleChange,
  onCreateNewClientNote,
  clientNotesStatus,
}: UpdateCreateClientNoteHeaderProps) {
  const [newTitle, setNewTitle] = useState(title);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const navigate = useNavigate();

  const selectedClient = useSelector(selectClient);

  const handleCancel = () => {
    setNewTitle(title);
    onTitleChange(title);
    setDialogIsOpen(false);
  };

  const handleSaveNewTitle = () => {
    setDialogIsOpen(false);
    onTitleChange(newTitle);
  };

  const handleNavigateToNotes = () => {
    navigate(`/info/client/${selectedClient.id}/notes`);
  };

  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <Flex
        css={{
          color: "$gray700",
          fontSize: "20px",
          alignItems: "center",
          gap: "$2",
        }}
      >
        <AiOutlineArrowLeft
          color="inherit"
          fontSize="inherit"
          style={{ cursor: "pointer" }}
          onClick={handleNavigateToNotes}
        />
        <Heading size="title6" css={{ color: "inherit", gap: "$2" }}>
          {title}
        </Heading>

        <Dialog open={dialogIsOpen} onOpenChange={setDialogIsOpen}>
          <DialogTrigger asChild>
            <BsFillPencilFill
              color="inherit"
              fontSize="inherit"
              style={{ cursor: "pointer" }}
            />
          </DialogTrigger>
          <DialogContent style={{ width: "500px" }}>
            <DialogTitle>Nome da Nota</DialogTitle>

            <DialogClose asChild>
              <IconButton
                type="ghost"
                css={{ position: "absolute", top: "1rem", right: "1rem" }}
              >
                <AiOutlineClose />
              </IconButton>
            </DialogClose>

            <Flex
              css={{ flexDirection: "column", mt: "$4", gap: "$3" }}
              onKeyDown={(e) => (e.key === "Enter" ? handleSaveNewTitle() : {})}
            >
              <Flex css={{ flexDirection: "column", gap: "$1" }}>
                <Caption>Digite o Nome da Nota</Caption>
                <Input
                  placeholder="Ex. Requisição do Cliente"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                />
              </Flex>

              <Flex
                css={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  gap: "$2",
                }}
              >
                <DialogClose>
                  <Button
                    bordered
                    style={{ background: "white" }}
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </DialogClose>
                <DialogClose>
                  <Button onClick={handleSaveNewTitle}>Salvar</Button>
                </DialogClose>
              </Flex>
            </Flex>
          </DialogContent>
        </Dialog>
      </Flex>

      <Button
        onClick={onCreateNewClientNote}
        isLoading={clientNotesStatus === "loading"}
      >
        {saveButtonTitle}
      </Button>
    </Flex>
  );
}
