import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function RuptureRuleTableSkeleton() {
  return (
    <Flex css={{ flexDirection: "column", gap: "20px" }}>
      <RLSSkeleton height={120} />
      <RLSSkeleton width={300} height={28} />
    </Flex>
  );
}
