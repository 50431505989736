import styled from "styled-components";

interface TrProps {
  isDisabled?: boolean;
}

export const Tr = styled.tr<TrProps>`
  cursor: pointer;
  transition: "background 150ms ease-in-out";
  display: table-row;
  border: none;
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;
