import RLSSkeleton from "react-loading-skeleton";
import styled from "styled-components";
import UI from "../ui";

interface HistoryPageSkeletonProps {
  count?: number;
}

export function HistoryPageSkeleton({ count = 5 }: HistoryPageSkeletonProps) {
  const timesToRepeatSkeletonRow = Array.from(Array(count).keys());

  return (
    <UI.Timeline>
      {timesToRepeatSkeletonRow.map((t) => {
        return (
          <Item key={t}>
            <Row>
              <RLSSkeleton width={120} height={32} />
              <RLSSkeleton width={190} height={25} />
            </Row>

            <Row style={{ justifyContent: "space-between" }}>
              <RLSSkeleton width={250} height={20} />
              <RLSSkeleton width={75} height={25} />
            </Row>
          </Item>
        );
      })}
    </UI.Timeline>
  );
}

export const Item = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: ${(props) => props.theme.space[4]};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[2]};
`;
