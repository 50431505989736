import { ErrorContent } from "@/components/ErrorContent";
import { ClientsSkeletonPage } from "@/components/Skeleton/ClientsSkeletonPage";
import { ListOfTags, SearchFilters } from "@/core/models/listOfTags.model";
import { selectUser } from "@/core/store/users/usersSlice";
import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import { selectClientsHasError500 } from "@/features/clients/store/clientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAttendanceMixpanel } from "../hooks/useAttendanceMixpanel";
import {
  addTagsOnSelectedClientFilter,
  setSelectedAdvancedFilters,
} from "../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClientsFilters } from "../store/emitEventToChangeClientsFiltersSlice";
import { getFunnels } from "../store/funnelsSlice";
import { getListBySlug, getListOfAllClients } from "../store/listOfTagsSlice";
import { selectList, selectSelectedList } from "../store/listSelectedSlice";
import { setSelectedClientsTypeOfView } from "../store/selectedClientsTypeOfViewSlice";
import { setSelectedSearchFilters } from "../store/selectedSearchFiltersSlice";
import { ClientsListContainer } from "./ClientsListContainer";

export type ClientsTypeOfView = "list" | "kanban" | "reports";

export function ClientsListPage() {
  const [findListStatus, setFindListStatus] = useState("loading");
  const [getFunnelsStatus, setGetFunnelsStatus] = useState("loading");

  const user = useSelector(selectUser);
  const selectedList = useSelector(selectSelectedList);
  const hasError500 = useSelector(selectClientsHasError500);

  const { list_slug } = useParams();
  const dispatch = useAppDispatch();
  const { listOfTagsViewedEvent } = useAttendanceMixpanel();

  const loadSelectedList = async (requestResponse) => {
    if (requestResponse.meta.requestStatus === "fulfilled") {
      const foundedList: ListOfTags = requestResponse.payload;
      listOfTagsViewedEvent(foundedList);
      await dispatch(selectList(foundedList));

      const advancedFilters = foundedList.filters.advanced_filters;
      if (advancedFilters !== undefined && advancedFilters.length > 0) {
        await dispatch(setSelectedAdvancedFilters(advancedFilters));
      } else {
        await dispatch(setSelectedAdvancedFilters([]));
      }

      const tags = foundedList.filters.tags;
      if (tags !== undefined && tags.length > 0) {
        await dispatch(addTagsOnSelectedClientFilter(tags));
      } else {
        await dispatch(addTagsOnSelectedClientFilter([]));
      }

      const attrOpts = foundedList.filters.attr_opts;
      if (attrOpts !== undefined) {
        await dispatch(setAttributesToFilter(attrOpts));
      } else {
        await dispatch(setDefaultAttributesToFilter());
      }

      const searchFilters: SearchFilters = {
        match_type: "Geral",
        match_value: "",
      };

      await dispatch(setSelectedSearchFilters(searchFilters));

      setFindListStatus("fulfilled");
    }
  };

  useEffect(() => {
    const my_clients_list_slug = `${user.account_id}_${user.id}_clientes`;

    async function loadListIfUserEnterByListUrl() {
      if (isEmpty(selectedList)) {
        if (isUndefined(list_slug) || list_slug === `${my_clients_list_slug}`) {
          await dispatch(getListOfAllClients()).then(async (obj) => {
            await loadSelectedList(obj);
          });
        } else {
          await dispatch(getListBySlug({ slug: list_slug })).then(
            async (obj) => {
              await loadSelectedList(obj);
            }
          );
        }
      } else {
        setFindListStatus("fulfilled");
      }
    }

    const loadFunnels = async () => {
      await dispatch(getFunnels()).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setGetFunnelsStatus("fulfilled");
        }
      });
    };

    const setEmitFiltersToFalse = async () => {
      await dispatch(emitEventToChangeClientsFilters(false));
    };

    loadListIfUserEnterByListUrl();
    loadFunnels();
    setEmitFiltersToFalse();
  }, [selectedList]);

  const handleClientsTypeOfViewView = async (option: ClientsTypeOfView) => {
    await dispatch(setSelectedClientsTypeOfView(option));
  };

  return (
    <>
      {hasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos buscar os clientes de sua lista, tente novamente mais
          tarde ou atualize a página
        </ErrorContent>
      ) : (
        <>
          {findListStatus === "fulfilled" &&
          getFunnelsStatus === "fulfilled" ? (
            <ClientsListContainer
              onClientsTypeOfViewChange={handleClientsTypeOfViewView}
            />
          ) : (
            <ClientsSkeletonPage />
          )}
        </>
      )}
    </>
  );
}
