import { gvTheme } from "@gogeo-io/ui-library";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { createRoot } from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import { AuthProvider } from "react-oidc-context";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import { MockMode } from "./MockMode";
import { ProdMode } from "./ProdMode";
import { oidcConfig } from "./config/keycloak";
import { store } from "./core/store/store";
import { GlobalStyle } from "./global.styles";
import { removeUnwantedErrorsFromConsole } from "./utils/removeUnwantedErrorsFromConsole";

removeUnwantedErrorsFromConsole();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <ThemeProvider theme={gvTheme}>
      <GlobalStyle />
      <AuthProvider {...oidcConfig}>
        <Provider store={store}>
          {import.meta.env.VITE_USE_MOCK === "YES" ? (
            <MockMode />
          ) : (
            <ProdMode />
          )}
        </Provider>
        <ToastContainer theme="colored" draggable newestOnTop />
      </AuthProvider>
    </ThemeProvider>
  </BrowserRouter>
);
