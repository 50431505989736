import { EmptyContent } from "@/components/EmptyContent";
import { ListDivider } from "@/components/ListDivider";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getNotificationTypes,
  getNotifications,
  selectNotifications,
  selectNotificationsQuery,
  selectNotificationsSelectedType,
  selectNotificationsStatus,
} from "../store/notificationsSlice";
import { NotReadedNotificationsList } from "./NotReadedNotificationsList";
import { NotificationsListHeader } from "./NotificationsListHeader";
import { NotificationsListSkeleton } from "./NotificationsListSkeleton";
import { ReadedNotificationsList } from "./ReadedNotificationsList";

export function NotificationsList() {
  const [readedNotifications, setReadedNotifications] = useState([]);
  const [notReadedNotifications, setNotReadedNotifications] = useState([]);

  const notifications = useSelector(selectNotifications);
  const notificationStatus = useSelector(selectNotificationsStatus);
  const notificationsQuery = useSelector(selectNotificationsQuery);
  const notificationsSelectedType = useSelector(
    selectNotificationsSelectedType
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const { readed, notReaded } = notifications.reduce(
      (acc, notification) => {
        if (notification.read) {
          acc.readed.push(notification);
        } else {
          acc.notReaded.push(notification);
        }
        return acc;
      },
      { readed: [], notReaded: [] }
    );

    setReadedNotifications(readed);
    setNotReadedNotifications(notReaded);
  }, [notifications]);

  useEffect(() => {
    const loadNotificationTypes = async () => {
      await dispatch(getNotificationTypes());
    };

    const loadNotifications = async () => {
      await dispatch(
        getNotifications({
          type: notificationsSelectedType,
          query: notificationsQuery,
        })
      );
    };

    loadNotificationTypes();
    loadNotifications();
  }, []);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        height: "100%",
        width: "100%",
      }}
    >
      <NotificationsListHeader />

      <ListDivider />

      <Flex
        css={{
          flexDirection: "column",
          gap: "$2",
          width: "100%",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          mt: "$2",
        }}
      >
        {notificationStatus === "success" ? (
          <>
            {isEmpty(notifications) ? (
              <EmptyContent size="medium">
                Você não possui notificações
              </EmptyContent>
            ) : (
              <Flex css={{ flexDirection: "column", gap: "$4" }}>
                <NotReadedNotificationsList
                  notReadedNotifications={notReadedNotifications}
                />

                <ReadedNotificationsList
                  readedNotifications={readedNotifications}
                />
              </Flex>
            )}
          </>
        ) : (
          <NotificationsListSkeleton />
        )}
      </Flex>
    </Flex>
  );
}
