import { FileMetadata } from "@/core/models/file.model";
import { Flex, Text } from "@gogeo-io/ui-library";
import { Close as CloseIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";

interface AttachedFilesOnRegisterProps {
  onChange: (...event: any[]) => void;
  value: FileMetadata[];
}

export function AttachedFilesOnRegister({
  onChange,
  value,
}: AttachedFilesOnRegisterProps) {
  const [files, setFiles] = useState<FileMetadata[]>([]);

  useEffect(() => {
    setFiles(value);
  }, [value]);

  const handleRemoveFileAttached = (fileNameToRemove: string) => {
    const filesWithoutRemovedOne = files.filter(
      (file) => file.filename !== fileNameToRemove
    );
    setFiles(filesWithoutRemovedOne);
    onChange(filesWithoutRemovedOne);
  };

  return (
    <>
      <Flex css={{ width: "100%", flexWrap: "wrap", gap: "$3" }}>
        {files &&
          files.map((file) => {
            return (
              <Flex
                css={{
                  alignItems: "center",
                  color: "$gray500",
                  fontSize: "16px",
                  border: "1px solid $gray300",
                  gap: "2px",
                  padding: "$1 $2",
                  borderRadius: "$sm",
                }}
                key={file.filename}
              >
                <Text css={{ color: "$gray600" }}>{file.filename}</Text>
                <CloseIcon
                  color="inherit"
                  fontSize="inherit"
                  cursor="pointer"
                  onClick={() => handleRemoveFileAttached(file.filename)}
                />
              </Flex>
            );
          })}
      </Flex>
    </>
  );
}
