import {
  BusinessAssociateService,
  BusinessAssociateServices,
} from "@/core/models/business-associate.model";
import { Client } from "@/core/models/client.model";
import { BusinessAssociateServicesModalContentSkeleton } from "@/features/businessAssociate/components/BusinessAssociateServicesModalContentSkeleton";
import { getBusinessAssociateServicesOfWhatsapp } from "@/features/businessAssociate/store/businessAssociateServicesSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Text } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { BusinessAssociateServicesOfWhatsappModalContent } from "./BusinessAssociateServicesOfWhatsappModalContent";

interface BusinessAssociateServicesOfWhatsappModalContentContainerProps {
  phoneNumber: string;
  client: Client;
  onCloseDialog: () => void;
  onOpenGoVendasDefaultWhatsapp: () => void;
}

export function BusinessAssociateServicesOfWhatsappModalContentContainer({
  phoneNumber,
  client,
  onCloseDialog,
  onOpenGoVendasDefaultWhatsapp,
}: BusinessAssociateServicesOfWhatsappModalContentContainerProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [accountDontHaveServices, setAccountDontHaveServices] = useState(false);
  const [selectedBusinessAssociate, setSelectedBusinessAssociate] =
    useState<BusinessAssociateServices>({} as BusinessAssociateServices);
  const [
    selectedBusinessAssociateService,
    setSelectedBusinessAssociateService,
  ] = useState<BusinessAssociateService>({} as BusinessAssociateService);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoading(true);

    const loadBusinessAssociateServices = async () => {
      await dispatch(getBusinessAssociateServicesOfWhatsapp()).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const services: BusinessAssociateServices[] = res.payload;

          if (services && services.length > 0) {
            const firstService = services[0];

            if (firstService && firstService.services.length > 0) {
              const businesAssociateFirstService = firstService.services[0];

              setSelectedBusinessAssociateService(businesAssociateFirstService);
              setSelectedBusinessAssociate(firstService);
              setIsLoading(false);
            } else {
              setAccountDontHaveServices(true);
              setIsLoading(false);
            }
          } else {
            setAccountDontHaveServices(true);
            setIsLoading(false);
          }
        }
      });
    };

    loadBusinessAssociateServices();
  }, []);

  return (
    <>
      {!isLoading ? (
        <>
          {!accountDontHaveServices ? (
            <BusinessAssociateServicesOfWhatsappModalContent
              phoneNumber={phoneNumber}
              client={client}
              onCloseDialog={onCloseDialog}
              onOpenGoVendasDefaultWhatsapp={onOpenGoVendasDefaultWhatsapp}
              defaultBusinessAssociate={selectedBusinessAssociate}
              defaultBusinessAssociateService={selectedBusinessAssociateService}
            />
          ) : (
            <Text>Você não tem serviços habilitados</Text>
          )}
        </>
      ) : (
        <BusinessAssociateServicesModalContentSkeleton />
      )}
    </>
  );
}
