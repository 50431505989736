import { Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";

interface SelectOptionProps {
  optionSelected: string;
  setOptionSelected: (value: string) => void;
}

export function SelectedDateTypeOption({
  optionSelected,
  setOptionSelected,
}: SelectOptionProps) {
  return (
    <Select
      value={optionSelected}
      defaultValue={optionSelected}
      ariaLabel="Select option"
      onValueChange={setOptionSelected}
    >
      <SelectItem value="empty">
        <SelectItemText>Sem valor selecionado</SelectItemText>
      </SelectItem>
      <SelectItem value="before">
        <SelectItemText>Anterior à</SelectItemText>
      </SelectItem>
      <SelectItem value="inthis1">
        <SelectItemText>No(a) último(a)</SelectItemText>
      </SelectItem>
      <SelectItem value="inthis">
        <SelectItemText>Nos(as) últimos(as)</SelectItemText>
      </SelectItem>
      <SelectItem value="between">
        <SelectItemText>Entre</SelectItemText>
      </SelectItem>
    </Select>
  );
}
