import {
  removeClientToChangeParams,
  selectAllClientsSelectedToChangeParams,
  selectClientSelectedToChangeParams,
  selectClientToChangeParams,
} from "@/features/params/store/clientSelectedToChangeParamsSlice";
import { changeParams } from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { ClientParamsResponse } from "@core/models/client.model";
import {
  Checkbox,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface SelectClientToChangeParamsProps {
  client: ClientParamsResponse;
}

export function SelectClientToChangeParams({
  client,
}: SelectClientToChangeParamsProps) {
  const [checked, setChecked] = useState(false);

  const allClientsSelectedToChangeParams = useSelector(
    selectAllClientsSelectedToChangeParams
  );
  const selectedClientsToChange = useSelector(
    selectClientSelectedToChangeParams
  );

  const dispatch = useAppDispatch();

  const handleCheckboxChange = async (
    checked: boolean,
    client: ClientParamsResponse
  ) => {
    if (checked) {
      await dispatch(selectClientToChangeParams(client));
      await dispatch(changeParams({ params: "cleanAllFilters" }));
    } else {
      await dispatch(removeClientToChangeParams(client));
    }
  };

  useEffect(() => {
    const checkedIsMarked = selectedClientsToChange.some(
      (c) => c.client.id === client.client.id
    );
    setChecked(checkedIsMarked);
  }, [selectedClientsToChange]);

  return (
    <>
      {!allClientsSelectedToChangeParams && (
        <>
          {client.client.exclusion ? (
            <Tooltip>
              <TooltipTrigger style={{ background: "transparent" }}>
                <Checkbox disabled={client.client.exclusion} checked />
              </TooltipTrigger>
              <TooltipContent aria-label="Remarcação de agendamento">
                Este excluído foi excluído
              </TooltipContent>
            </Tooltip>
          ) : (
            <Checkbox
              checked={checked}
              onCheckedChange={(checked) =>
                handleCheckboxChange(Boolean(checked), client)
              }
              css={{ cursor: "pointer" }}
            />
          )}
        </>
      )}
    </>
  );
}
