import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../core/store/store";

interface EmitEventToChangeClientsState {
  status: boolean;
}

const initialState = {
  status: false,
} as EmitEventToChangeClientsState;

export const emitEventToChangeClientsSlice = createSlice({
  name: "emitEventToChangeClientsSlice",
  initialState,
  reducers: {
    emitEventToChangeClients(state, action: PayloadAction<boolean>) {
      state.status = action.payload;
    },
  },
});

export const selectStatusOfEmitEventToChangeClients = (state: RootState) =>
  state.emitEventToChangeClientsState.status;

export const { emitEventToChangeClients } =
  emitEventToChangeClientsSlice.actions;

export const emitEventToChangeClientsReducer =
  emitEventToChangeClientsSlice.reducer;
