import { useFormatDate } from "@/hooks/useFormatDate";
import { Flex, Text } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";

interface DateCellProps {
  value: string;
}

export function DateCell({ value }: DateCellProps) {
  const { formatLongDatePtBrWithoutWeekday } = useFormatDate();

  return (
    <Flex>
      {!isEmpty(value) ? (
        <Text size="body2" css={{ textAlign: "center" }}>
          {formatLongDatePtBrWithoutWeekday(value)}
        </Text>
      ) : (
        <Text size="body2" css={{ textAlign: "center" }}>
          Não Possui
        </Text>
      )}
    </Flex>
  );
}
