import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";

export const useAccountInfoBreadcrumbs = () => {
  const dispatch = useAppDispatch();

  const accountInfoBreadcrumbs = async (): Promise<void> => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Configurações", url: "/profile/user-profile/info" },
      { item: "Conta", url: "/profile/account/info", notNavigate: true },
      { item: "Informações da conta", url: "" },
    ];
    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  const paramsOfAccountBreadcrumbs = async (): Promise<void> => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Configurações", url: "/profile/user-profile/info" },
      {
        item: "Gestão de atendimento",
        url: "/profile/params/change-params-account",
        notNavigate: true,
      },
      {
        item: "Parâmetros de conta",
        url: "/profile/params/change-params-account",
        notNavigate: true,
      },
      { item: "Alterar parâmetros de atendimento da conta", url: "" },
    ];
    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return { accountInfoBreadcrumbs, paramsOfAccountBreadcrumbs };
};
