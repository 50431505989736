import {
  Flex,
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { CloseFullscreen as CloseFullscreenIcon } from "@mui/icons-material";

interface TimelineExpandButtonProps {
  onHistoryIsCollapsed: () => void;
}

export const TimelineExpandButton = ({
  onHistoryIsCollapsed,
}: TimelineExpandButtonProps) => {
  return (
    <Flex css={{ gap: "$2", alignItems: "center" }}>
      <Tooltip>
        <TooltipTrigger style={{ background: "transparent" }}>
          <IconButton
            onClick={onHistoryIsCollapsed}
            type="soft"
            color="gray"
            bordered
          >
            <CloseFullscreenIcon />
          </IconButton>
        </TooltipTrigger>
        <TooltipContent aria-label="Colapsar/abrir histórico de atendimento">
          Colapsar/abrir histórico de atendimento
        </TooltipContent>
      </Tooltip>
    </Flex>
  );
};
