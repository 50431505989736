import { SelectOption } from "@/core/models/advanced-filters.model";
import { getOptionsOfSelectAndMultiselectFilter as clientsOptions } from "@/features/attendance/store/clientAdvancedFiltersSlice";
import { getOptionsOfSelectAndMultiselectFilter as historyOptions } from "@/features/history/store/historyFiltersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { ReactNode, useEffect, useState } from "react";
import { GenericFilterPopover } from "../GenericFilterPopover";
import { GenericMultiSelectFilterContent } from "./GenericMultiSelectFilterContent";
import { GenericMultiSelectFilterContentSkeleton } from "./GenericMultiSelectFilterContentSkeleton";

interface GenericMultiSelectFilterProps {
  children?: ReactNode;
  hasError?: boolean;
  filterId: number;
  value: SelectOption[];
  optionsOf?: "history" | "clients";
  onUpdatedFilter: (filterId: number, newOptions: SelectOption[]) => void;
}

interface FilterToChange {
  filterId: number;
  newOptions: SelectOption[];
}

export function GenericMultiSelectFilter({
  children,
  hasError = false,
  filterId,
  value,
  optionsOf = "clients",
  onUpdatedFilter,
}: GenericMultiSelectFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterChanged, setFilterChanged] = useState<FilterToChange>(
    {} as FilterToChange
  );
  const [optionsSelected, setOptionsSelected] = useState(value);

  const dispatch = useAppDispatch();

  const handleCheckboxChange = (isChecked: boolean, option: SelectOption) => {
    if (isChecked) {
      const newOptions = [...optionsSelected, option];
      setOptionsSelected(newOptions);
      const filter: FilterToChange = { filterId, newOptions };
      setFilterChanged(filter);
    } else {
      const newOptions = optionsSelected.filter((opt) => opt.id !== option.id);
      setOptionsSelected(newOptions);
      const filter: FilterToChange = { filterId, newOptions };
      setFilterChanged(filter);
    }
  };

  const handlePopoverOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const openPopover = () => {
    setIsOpen(true);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  const applyFilter = () => {
    onUpdatedFilter(filterChanged.filterId, filterChanged.newOptions);
    setFilterChanged({} as FilterToChange);
    closePopover();
  };

  useEffect(() => {
    setIsLoading(true);

    const loadOptions = async () => {
      const dispatchOptions = async (action: any) => {
        const res = await dispatch(action({ filter_id: filterId }));
        if (res.meta.requestStatus === "fulfilled") {
          const responseOptions: SelectOption[] = res.payload as SelectOption[];
          setOptions(responseOptions);
          setIsLoading(false);
        }
      };

      if (optionsOf === "clients") {
        await dispatchOptions(clientsOptions);
      } else {
        await dispatchOptions(historyOptions);
      }
    };

    loadOptions();
  }, []);

  return (
    <GenericFilterPopover
      trigger={children}
      popoverIsOpen={hasError ? false : isOpen}
      onOpenPopover={!hasError && openPopover}
      onPopoverOpenChange={!hasError && handlePopoverOpenChange}
    >
      {isLoading ? (
        <GenericMultiSelectFilterContentSkeleton />
      ) : (
        <GenericMultiSelectFilterContent
          options={options}
          optionsSelected={optionsSelected}
          onCheckboxChange={handleCheckboxChange}
          onApplyFilter={applyFilter}
        />
      )}
    </GenericFilterPopover>
  );
}
