import Papa from "papaparse";
import { useState } from "react";
import styled from "styled-components";

interface CSVSelectorProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onFilesAreCompleted: (data: any) => void;
}

export const CSVSelector = ({
  onFilesAreCompleted,
  ...inputProps
}: CSVSelectorProps) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0];
        setSelectedFileName(file.name);

        Papa.parse<string[]>(file, {
          worker: true,
          header: true,
          dynamicTyping: true,
          complete({ data }) {
            onFilesAreCompleted(data);
            setFileUploaded(true);
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <DropzoneWrapper $fileUploaded={fileUploaded}>
      <FileInput
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        {...inputProps}
      />
      <DropzoneText $fileUploaded={fileUploaded}>
        {!fileUploaded
          ? "Arraste e solte o arquivo aqui ou clique para selecionar"
          : "Uploado concluído com sucesso "}
      </DropzoneText>
      {selectedFileName && (
        <DropzoneText $fileUploaded={fileUploaded}>
          {selectedFileName}
        </DropzoneText>
      )}
    </DropzoneWrapper>
  );
};

interface SelectedFileProps {
  $fileUploaded: boolean;
}

const DropzoneWrapper = styled.div<SelectedFileProps>`
  border: 2px dashed
    ${({ theme, $fileUploaded }) =>
      $fileUploaded ? theme.colors.primary600 : theme.colors.gray400};
  gap: ${(props) => props.theme.sizes[3]};
  cursor: pointer;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes[1]};
`;

const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const DropzoneText = styled.p<SelectedFileProps>`
  font-size: 16px;
  text-align: center;
  color: ${({ theme, $fileUploaded }) =>
    $fileUploaded ? theme.colors.primary600 : theme.colors.gray400};
`;
