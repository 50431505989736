import { DynamicMdIcon } from "@/components/icons/DynamicMdIcon";
import { AttendanceInfo } from "@/core/models/history.model";
import { Tooltip, TooltipContent, TooltipTrigger } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface AttendanceInfoProps {
  attendance_info: AttendanceInfo[];
  isBackgroundGray?: boolean;
}

export function TimelineAttendanceRecordAttendanceInfo({
  attendance_info,
  isBackgroundGray = true,
}: AttendanceInfoProps) {
  return (
    <Wrapper $isBackgroundGray={isBackgroundGray}>
      {attendance_info.map((info) => {
        return (
          <Tooltip key={info.text}>
            <TooltipTrigger style={{ background: "none" }}>
              <Item $scheme_color={info.scheme_color}>
                <DynamicMdIcon
                  icon={`Md${info.icon ?? "SentimentVeryDissatisfied"}`}
                />
              </Item>
            </TooltipTrigger>
            <TooltipContent aria-label="Informações do atendimento">
              {info?.description ?? "Sem descrição"}
            </TooltipContent>
          </Tooltip>
        );
      })}
    </Wrapper>
  );
}

interface WrapperProps {
  $isBackgroundGray: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme, $isBackgroundGray }) =>
    $isBackgroundGray && theme.colors.gray50};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii.base};
  margin-top: 5px;
`;

interface ItemProps {
  $scheme_color: string;
}

const Item = styled.div<ItemProps>`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.theme.colors[
      `${props.$scheme_color !== "" ? props.$scheme_color : `primary`}100`
    ]};

  svg {
    width: 18px;
    height: 18px;
    color: ${(props) =>
      props.theme.colors[
        `${props.$scheme_color !== "" ? props.$scheme_color : `primary`}500`
      ]};
  }
`;
