import { FileMetadata } from "@/core/models/file.model";
import {
  Caption,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  IconButton,
} from "@gogeo-io/ui-library";
import { AiOutlineClose } from "react-icons/ai";
import { RemoveFileFromNoteConfirmation } from "./RemoveFileFromNoteConfirmation";

interface TextEditorFileMetadataProps {
  file: FileMetadata;
  onRemoveFile: (fileNameToRemove: string) => void;
}

export function TextEditorFileMetadata({
  file,
  onRemoveFile,
}: TextEditorFileMetadataProps) {
  return (
    <Dialog>
      <DialogTrigger style={{ background: "transparent" }}>
        <Flex
          key={file.filename}
          css={{
            alignItems: "center",
            fontSize: "0.875rem",
            gap: "$2",
            padding: "$1 $3",
            border: "1px solid $gray400",
            color: "$gray500",
            borderRadius: "10px",
          }}
        >
          <Caption css={{ color: "inherit" }}>{file.filename}</Caption>
          <AiOutlineClose color="inherit" fontSize="inherit" cursor="pointer" />
        </Flex>
      </DialogTrigger>
      <DialogContent style={{ width: "450px" }}>
        <DialogClose asChild>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
            onClick={(event) => event.stopPropagation()}
          >
            <AiOutlineClose />
          </IconButton>
        </DialogClose>

        <RemoveFileFromNoteConfirmation
          onRemoveFile={() => onRemoveFile(file.filename)}
        />
      </DialogContent>
    </Dialog>
  );
}
