import { RuptureParams } from "@/core/models/ruptureParams.model";
import { Flex } from "@gogeo-io/ui-library";
import { DayWithRuptureFormField } from "./DayWithRuptureFormField";
import { DayWithRuptureFormFieldDisabledToChange } from "./DayWithRuptureFormFieldDisabledToChange";

interface DaysWithRuptureFormFieldProps {
  value: RuptureParams;
  onChange: (...event: any[]) => void;
  disabledToChange?: boolean;
}

const options = [
  {
    option: "mon_pref",
    text: "Seg",
  },
  {
    option: "tue_pref",
    text: "Ter",
  },
  {
    option: "wed_pref",
    text: "Qua",
  },
  {
    option: "thu_pref",
    text: "Qui",
  },
  {
    option: "fri_pref",
    text: "Sex",
  },
  {
    option: "sat_pref",
    text: "Sab",
  },
  {
    option: "sun_pref",
    text: "Dom",
  },
];

export function DaysWithRuptureFormField({
  value,
  onChange,
  disabledToChange = false,
}: DaysWithRuptureFormFieldProps) {
  const handleChangeDayWithRupture = (option: string, newValue: number) => {
    value[option] = newValue;
    onChange(value);
  };

  return (
    <Flex css={{ gap: "$3" }}>
      {options.map((option) => {
        return (
          <div key={option.option}>
            {disabledToChange ? (
              <DayWithRuptureFormFieldDisabledToChange
                text={option.text}
                value={value !== undefined ? value[option.option] : 0}
              />
            ) : (
              <DayWithRuptureFormField
                text={option.text}
                value={value !== undefined ? value[option.option] : 0}
                option={option.option}
                onChange={handleChangeDayWithRupture}
              />
            )}
          </div>
        );
      })}
    </Flex>
  );
}
