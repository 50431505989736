import { selectUser } from "@/core/store/users/usersSlice";
import { useFormatDate } from "@/hooks/useFormatDate";
import { useMixpanel } from "@/hooks/useMixpanel";
import { ErrorPageLayout } from "@/layouts/ErrorPageLayout";
import { Button, Flex, Heading, Text } from "@gogeo-io/ui-library";
import { useCheckIfUserIsInWorkingTime } from "@hooks/useCheckIfUserIsInWorkingTime";
import { format } from "date-fns";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import OneSignal from "react-onesignal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import SummerSvg from "../assets/summer.svg";

export function UserIsNotInWorkingTime() {
  const user = useSelector(selectUser);

  const navigate = useNavigate();
  const { getDay } = useFormatDate();
  const { checkIfUserIsInWorkingTime } = useCheckIfUserIsInWorkingTime();
  const auth = useAuth();
  const { userLogoutEvent } = useMixpanel();

  const workingTime = user.meta_parameters.working_time;

  const getToday = format(new Date(), "E").toLowerCase();

  const workingTimeThatHasTheDayOfWork = workingTime.find((wt) =>
    wt.days.includes(getToday)
  );

  const days = workingTimeThatHasTheDayOfWork?.days;
  const periodTime = workingTimeThatHasTheDayOfWork?.period_time;

  const handleLogout = () => {
    OneSignal.logout().then(() => {
      OneSignal.User.removeTags(["user_id", "account_id"]);
    });
    userLogoutEvent(user);
    navigate("/");
    auth.removeUser();
    auth.signoutRedirect({ id_token_hint: auth.user.id_token });
  };

  const handleNavigateToAttendancePage = () => {
    if (!checkIfUserIsInWorkingTime({ user })) {
      toast.warn("Você ainda está fora do horário de trabalho");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (!checkIfUserIsInWorkingTime({ user })) {
      toast.warn("Você ainda está fora do horário de trabalho");
    } else {
      navigate("/");
    }
  }, []);

  return (
    <ErrorPageLayout
      content={
        <Wrapper>
          <img src={SummerSvg} alt="Horário de trabalho encerrado" />

          <Flex css={{ gap: "$6" }}>
            <Heading size="title4">Horário de trabalho encerrado:</Heading>
            <Heading size="title4">
              {periodTime !== undefined &&
              periodTime[periodTime?.length - 1].end
                ? periodTime[periodTime?.length - 1].end
                : "Sem horário definido"}
              , {format(new Date(), "dd/MM/yyyy")}
            </Heading>
          </Flex>

          <Text size="body1">
            Você está fora do horário de trabalho estipulado, que corresponde a:
          </Text>

          <Flex>
            {days !== undefined ? (
              days.map((day) => {
                return (
                  <Text size="body1" key={day}>
                    {`${getDay(day)},`}&nbsp;
                  </Text>
                );
              })
            ) : (
              <Text size="body1" bold>
                *Sem horário definido*
              </Text>
            )}
            {periodTime !== undefined &&
              periodTime.map((period, i, arr) => {
                if (i + 1 === arr.length) {
                  return (
                    <Text size="body1" key={period.start}>
                      {`${period.start} às ${period.end}`}.
                    </Text>
                  );
                } else {
                  return (
                    <Text size="body1" key={period.start}>
                      {`${period.start} às ${period.end}`} e&nbsp;
                    </Text>
                  );
                }
              })}
          </Flex>

          <Text size="body1">
            Recarregue o govendas em algum dos horários acima para retornar o
            acesso.
          </Text>

          <Flex css={{ gap: "$4" }}>
            <Button onClick={handleNavigateToAttendancePage} bordered>
              Recarregar
            </Button>
            <Button onClick={handleLogout}>Deslogar</Button>
          </Flex>
        </Wrapper>
      }
    />
  );
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[6]};

  img {
    width: 600px;
  }
`;
