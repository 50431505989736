import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { UrlFilters } from "@/core/models/listOfTags.model";
import { PaginatedRequestBaseProps } from "@/core/models/paginatedRequest.model";
import { Tag } from "@/core/models/tag.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { isUndefined } from "lodash";
import { toast } from "react-toastify";

interface ClientAdvancedFiltersState {
  available_advanced_filters: AdvancedFilter[];
  selected_advanced_filters: AdvancedFilter[];
  selected_tags: Tag[];
  available_tags: Tag[];
  trending_tags: Tag[];
  recent_created_tags: Tag[];
  url_filters: UrlFilters;
  advanced_filters_status: "loading" | "success" | "failed";
  tags_status: "loading" | "success" | "failed";
}

const initialState = {
  available_advanced_filters: [],
  selected_advanced_filters: [],
  selected_tags: [],
  available_tags: [],
  url_filters: {},
  advanced_filters_status: "loading",
  tags_status: "loading",
} as ClientAdvancedFiltersState;

export const getAvailableClientFilters = createAsyncThunk(
  "clientAdvancedFilters/getAvailableClientFilters",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/available-advanced-filters/client`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface GetUserTagsProps extends PaginatedRequestBaseProps {
  query?: string;
}

export const getUserTags = createAsyncThunk(
  "clientAdvancedFilters/getUserTags",
  async ({ page, size, query }: GetUserTagsProps, thunkAPI) => {
    try {
      let url = "";
      if (query) {
        url = `/tags?page=${page}&size=${size}&query=${query}&countClients=${true}`;
      } else {
        url = `/tags?page=${page}&size=${size}&countClients=${true}`;
      }
      const response = await axios.get(url);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const getRecommendedTags = createAsyncThunk(
  "clientAdvancedFilters/getRecommendedTags",
  async (_, thunkAPI) => {
    try {
      const url = `/tags/recommended?countClients=${true}`;
      const response = await axios.get(url);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const getFiltersBasedOnUrlFilters = createAsyncThunk(
  "clientAdvancedFilters/getFiltersBasedOnUrlFilters",
  async (filters: any, thunkAPI) => {
    try {
      const response = await axios.post(`/enrichment/filter`, filters);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface GetOptionsOfSelectAndMultiselectFilterProps {
  filter_id: number;
}

export const getOptionsOfSelectAndMultiselectFilter = createAsyncThunk(
  "clientAdvancedFilters/getOptionsOfSelectAndMultiselectFilter",
  async (
    { filter_id }: GetOptionsOfSelectAndMultiselectFilterProps,
    thunkAPI
  ) => {
    try {
      const response = await axios.get(
        `/available-advanced-filters/client/options?id=${filter_id}`
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const clientAdvancedFiltersSlice = createSlice({
  name: "clientAdvancedFiltersSlice",
  initialState,
  reducers: {
    updateValueFromSelectedAdvancedFilter(
      state,
      action: PayloadAction<{ filterId: number; newValue: any }>
    ) {
      state.selected_advanced_filters = state.selected_advanced_filters.map(
        (filter) =>
          filter.id === action.payload.filterId
            ? { ...filter, value: action.payload.newValue }
            : filter
      );
    },
    addTagsOnSelectedClientFilter(state, action: PayloadAction<Tag[]>) {
      state.selected_tags = action.payload;
    },
    pushTagsOnSelectedClientFilter(state, action: PayloadAction<Tag[]>) {
      state.selected_tags = [...state.selected_tags, ...action.payload];
    },
    addSelectedFiltersOnUrl(state, action: PayloadAction<UrlFilters>) {
      state.url_filters = action.payload;
    },
    addTagOnSelectedClientFilter(state, action: PayloadAction<Tag>) {
      const tagToAdd = action.payload;

      state.selected_tags = state.selected_tags ? state.selected_tags : [];
      const index = state.selected_tags.findIndex(
        (tag) =>
          tag.slug === tagToAdd.slug ||
          tag.label === tagToAdd.slug ||
          tag.id === tagToAdd.id
      );
      if (index === -1) {
        state.selected_tags.push(tagToAdd);
      } else {
        state.selected_tags[index] = tagToAdd;
      }
    },
    removeTagOnSelectedClientFilter(state, action: PayloadAction<Tag>) {
      const tagToRemove = action.payload;

      const idx = state.selected_tags.findIndex(
        (tag) =>
          tag.slug === tagToRemove.slug ||
          tag.label === tagToRemove.slug ||
          tag.id === tagToRemove.id
      );
      state.selected_tags.splice(idx, 1);
    },
    removeSelectedAdvancedFilter(state, action: PayloadAction<AdvancedFilter>) {
      state.selected_advanced_filters =
        state.selected_advanced_filters &&
        state.selected_advanced_filters.filter((filter) => {
          if (!isUndefined(filter.id)) {
            return filter.id !== action.payload.id;
          } else {
            return filter.property !== action.payload.property;
          }
        });
    },
    removeSelectedAdvancedFilterFromOriginalFilters(
      state,
      action: PayloadAction<AdvancedFilter>
    ) {
      state.url_filters.errors.advanced_filters =
        state.url_filters.errors.advanced_filters &&
        state.url_filters.errors.advanced_filters.filter((filter) => {
          if (!isUndefined(filter.id)) {
            return filter.id !== action.payload.id;
          } else {
            return filter.property !== action.payload.property;
          }
        });
    },
    removeSelectedTagFromOriginalFilters(state, action: PayloadAction<Tag>) {
      state.url_filters.errors.tags =
        state.url_filters.errors.tags &&
        state.url_filters.errors.tags.filter((tag) => {
          if (!isUndefined(tag.id)) {
            return tag.id !== action.payload.id;
          } else {
            return tag.slug !== action.payload.slug;
          }
        });
    },
    addSelectedAdvancedFilter(state, action: PayloadAction<AdvancedFilter[]>) {
      state.selected_advanced_filters = [
        ...state.selected_advanced_filters,
        ...action.payload,
      ];
    },
    setSelectedAdvancedFilters(state, action: PayloadAction<AdvancedFilter[]>) {
      state.selected_advanced_filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailableClientFilters.pending, (state, action) => {
      state.advanced_filters_status = "loading";
    });
    builder.addCase(getAvailableClientFilters.fulfilled, (state, action) => {
      state.advanced_filters_status = "success";
      state.available_advanced_filters = action.payload;
    });
    builder.addCase(
      getAvailableClientFilters.rejected,
      (state, action: PayloadAction<any>) => {
        state.advanced_filters_status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the available filters for client"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(getUserTags.pending, (state, action) => {
      state.tags_status = "loading";
    });
    builder.addCase(getUserTags.fulfilled, (state, action) => {
      state.tags_status = "success";
      state.available_tags = action.payload.tags;
    });
    builder.addCase(
      getUserTags.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting user tags");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(getRecommendedTags.pending, (state, action) => {
      state.tags_status = "loading";
    });
    builder.addCase(getRecommendedTags.fulfilled, (state, action) => {
      state.tags_status = "success";
      state.trending_tags = action.payload["top_used_tags"];
      state.recent_created_tags = action.payload["last_created_tags"];
    });
    builder.addCase(
      getRecommendedTags.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting user recommended tags");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      getFiltersBasedOnUrlFilters.rejected,
      (_, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting filters based on url filters"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      getOptionsOfSelectAndMultiselectFilter.rejected,
      (_, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting options of select filter");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

////////////////////////////////////////////////////

export const selectAvailableAdvancedFilters = (state: RootState) =>
  state.clientFiltersState.available_advanced_filters;
export const selectSelectedTagsOnClientFilters = (state: RootState) =>
  state.clientFiltersState.selected_tags;
export const selectSelectedAdvancedFilters = (state: RootState) =>
  state.clientFiltersState.selected_advanced_filters;
export const selectAvailableAdvancedFiltersStatus = (state: RootState) =>
  state.clientFiltersState.advanced_filters_status;
export const selectTagsStatus = (state: RootState) =>
  state.clientFiltersState.tags_status;
export const selectAvailableTags = (state: RootState) =>
  state.clientFiltersState.available_tags;
export const selectTrendingTags = (state: RootState) =>
  state.clientFiltersState.trending_tags;
export const selectRecentCreatedTags = (state: RootState) =>
  state.clientFiltersState.recent_created_tags;
export const selectOriginalUrlFilters = (state: RootState) =>
  state.clientFiltersState.url_filters;

////////////////////////////////////////////////////

export const {
  setSelectedAdvancedFilters,
  removeSelectedAdvancedFilter,
  updateValueFromSelectedAdvancedFilter,
  addSelectedAdvancedFilter,
  addTagOnSelectedClientFilter,
  removeTagOnSelectedClientFilter,
  addTagsOnSelectedClientFilter,
  pushTagsOnSelectedClientFilter,
  addSelectedFiltersOnUrl,
  removeSelectedAdvancedFilterFromOriginalFilters,
  removeSelectedTagFromOriginalFilters,
} = clientAdvancedFiltersSlice.actions;

////////////////////////////////////////////////////

export const clientFiltersReducer = clientAdvancedFiltersSlice.reducer;
