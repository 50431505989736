import { TagLabel } from "@/components/TagLabel";
import { Client } from "@/core/models/client.model";
import {
  Caption,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import styled from "styled-components";

interface ClientTags {
  client: Client;
}

export function ClientProfileTags({ client }: ClientTags) {
  const { tags } = client;

  const twoFirstTags = tags && tags.slice(0, 2);
  const othersTags = tags && tags.slice(2);

  const renderTagLabel = (label: string) => {
    if (label.length > 25) {
      return (
        <Tooltip key={label}>
          <TooltipTrigger style={{ background: "transparent" }}>
            <TagLabel>{`${label.slice(0, 24)}...`}</TagLabel>
          </TooltipTrigger>
          <TooltipContent aria-label="Progresso efetivado">
            {label}
          </TooltipContent>
        </Tooltip>
      );
    } else {
      return <TagLabel>{label}</TagLabel>;
    }
  };

  return (
    <Container>
      <Caption wider>Tags</Caption>
      {twoFirstTags.map((tag) => {
        return <div key={tag.id}>{renderTagLabel(tag.label)}</div>;
      })}
      <Popover>
        <PopoverTrigger>
          <TagLabel style={{ fontWeight: "bold", cursor: "pointer" }}>
            + {othersTags.length}
          </TagLabel>
        </PopoverTrigger>
        <PopoverContent align="center">
          <Content>
            {othersTags.map((tag) => {
              return <TagLabel key={tag.id}>{tag.label}</TagLabel>;
            })}
          </Content>
        </PopoverContent>
      </Popover>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[2]};
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-height: 400px;
  overflow-y: auto;
`;
