export type AttendanceStatus = "Não Vendi" | "Em andamento" | "Vendi";

export interface BusinessGoalCategorie {
  id: number;
  field: AttendanceStatus;
  mapped_field: string;
}

export const systemColors = [
  "blue",
  "danger",
  "gray",
  "green",
  "primary",
  "red",
  "secondary",
  "yellow",
] as const;

export type SystemColors = (typeof systemColors)[number];

export interface BusinessGoal {
  id: number;
  name: string;
  color: string;
  categories: BusinessGoalCategorie[];
}
