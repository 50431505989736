import { ProfileInfo } from "@/features/attendance/components/ProfileInfo";
import { DaysWithRuptureFormField } from "@/features/params/components/DaysWithRuptureFormField";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AccountInfoPageSkeleton } from "../components/Skeleton/AccountInfoPageSkeleton";
import { UserProfileHeaderToPage } from "../components/UserProfileHeaderToPage";
import { useAccountInfoBreadcrumbs } from "../hooks/useAccountInfoBreadcrumbs";
import { useProfileMixpanel } from "../hooks/useProfileMixpanel";
import { selectAccount, selectAccountStatus } from "../store/accountSlice";

export function InfoParamsAccountPage() {
  const account = useSelector(selectAccount);
  const accountStatus = useSelector(selectAccountStatus);
  const { inactive_days, meta_parameters, skip_scheduling } = account;

  const { accountInfoBreadcrumbs } = useAccountInfoBreadcrumbs();
  const { userAccountProfileParamsEvent } = useProfileMixpanel();

  useEffect(() => {
    accountInfoBreadcrumbs();
    userAccountProfileParamsEvent({
      inactive_days,
      ...meta_parameters,
      skip_scheduling,
    });
  }, []);

  return (
    <Flex css={{ flexDirection: "column", gap: "$5" }}>
      <UserProfileHeaderToPage
        title="Parâmetros de atendimento da conta"
        description="Os parâmetros de atendimento da sua conta, você encontra aqui."
      />

      <>
        {accountStatus === "success" ? (
          <Flex
            css={{
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <ProfileInfo
              label="Dias sem compras para inativos"
              value={inactive_days ? String(inactive_days) : "Não consta"}
            />

            <ProfileInfo
              label="Frequência de clientes ativos"
              value={
                meta_parameters?.active_freq
                  ? String(meta_parameters.active_freq)
                  : "Não consta"
              }
            />

            <ProfileInfo
              label="Frequência de clientes inativos"
              value={
                meta_parameters?.inactive_freq
                  ? String(meta_parameters.inactive_freq)
                  : "Não consta"
              }
            />

            <ProfileInfo
              label="Agendar inadimplentes"
              value={skip_scheduling ? "Sim" : "Não"}
            />

            <ProfileInfo
              label="Frequência de clientes em ruptura"
              value={
                meta_parameters?.rupture_params?.freq
                  ? String(meta_parameters?.rupture_params?.freq)
                  : "Não consta"
              }
            />

            <Flex css={{ flexDirection: "column", gap: "$1" }}>
              <Caption style={{ display: "block" }}>
                Dias com agenda de ruptura
              </Caption>
              <DaysWithRuptureFormField
                value={account.meta_parameters?.rupture_params}
                onChange={() => {}}
                disabledToChange
              />
            </Flex>
          </Flex>
        ) : (
          <AccountInfoPageSkeleton />
        )}
      </>
    </Flex>
  );
}
