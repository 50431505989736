import {
  Button,
  Caption,
  DialogClose,
  Flex,
  Input,
} from "@gogeo-io/ui-library";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectOriginalUrlFilters } from "../../store/clientAdvancedFiltersSlice";
import { selectSelectedList } from "../../store/listSelectedSlice";
import { SelectedFilters } from "../SelectedFilters";

interface SaveAsProps {
  onSaveAsList: (list_name: string, description: string) => void;
}

export function SaveListAsDialog({ onSaveAsList }: SaveAsProps) {
  const selectedList = useSelector(selectSelectedList);
  const originalUrlFilters = useSelector(selectOriginalUrlFilters);

  const sharedNameIsAvailable = () => {
    return !!(
      originalUrlFilters &&
      originalUrlFilters.filters &&
      originalUrlFilters.filters.shared_name
    );
  };

  const sharedNameIsNotEmpty = () => {
    return originalUrlFilters.filters.shared_name !== "";
  };

  const getActualDate = () => {
    return format(new Date(), "dd/MM/yyyy 'às' hh:mm:ss'h'");
  };

  const getNewListName = () => {
    if (!isEmpty(selectedList)) {
      return `${selectedList.name} - cópia em ${getActualDate()}`;
    }

    return sharedNameIsAvailable() && sharedNameIsNotEmpty()
      ? originalUrlFilters.filters.shared_name
      : `Lista criada em ${getActualDate()}`;
  };

  const [newListName, setNewListName] = useState(getNewListName());
  const [description, setDescription] = useState("");

  const handleSaveList = () => {
    onSaveAsList(newListName, description);
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$4", mt: "$2" }}>
      <Flex css={{ flexDirection: "column", gap: "$1" }}>
        <Caption style={{ display: "block" }}>Nome</Caption>
        <Input
          placeholder="Nome da segmentação"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
        />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$1" }}>
        <Caption style={{ display: "block" }}>Descrição</Caption>
        <Input
          placeholder="Descrição da segmentação"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$1" }}>
        <Caption style={{ display: "block" }}>Filtros Aplicados</Caption>
        <SelectedFilters canUpdateFilters={false} />
      </Flex>

      <Flex
        css={{
          gap: "$2",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          mt: "$4",
        }}
      >
        <DialogClose asChild>
          <Button bordered>Cancelar</Button>
        </DialogClose>
        <DialogClose>
          <Button onClick={handleSaveList}>Salvar Nova Segmentação</Button>
        </DialogClose>
      </Flex>
    </Flex>
  );
}
