import {
  PreDefinedMessage,
  PreDefinedMessageSubject,
} from "@/core/models/preDefinedMessage.model";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  IconButton,
  RightSlot,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { BiSolidPencil } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs";
import { MdClose, MdFolderShared, MdMoreVert } from "react-icons/md";
import { SharePreDefinedMessageConfirmation } from "../SharePreDefinedMessageConfirmation";
import { UnsharePreDefinedMessageConfirmation } from "../UnsharePreDefinedMessageConfirmation";
import { UpdatePreDefinedMessage } from "../UpdatePreDefinedMessage";
import { DeletePreDefinedMessageConfirmation } from "./DeletePreDefinedMessageConfirmation";

interface PreDefinedMessagesActionsCellProps {
  preDefinedMessage: PreDefinedMessage;
  onDeletePreDefinedMessage: (id: number) => void;
  onUpdateSharedPreDefinedMessage: (id: number, shared: boolean) => void;
  onUpdatePreDefinedMessage: (
    id: number,
    subject: PreDefinedMessageSubject,
    title: string,
    message: string,
    shared: boolean
  ) => Promise<void>;
}

export function PreDefinedMessagesActionsCell({
  preDefinedMessage,
  onDeletePreDefinedMessage,
  onUpdateSharedPreDefinedMessage,
  onUpdatePreDefinedMessage,
}: PreDefinedMessagesActionsCellProps) {
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [sharedIsOpen, setSharedIsOpen] = useState(false);
  const [unsharedIsOpen, setUnsharedIsOpen] = useState(false);

  const handleDeletePreDefinedMessage = () => {
    onDeletePreDefinedMessage(preDefinedMessage.id);
  };

  const handleSetSharedMessage = () =>
    onUpdateSharedPreDefinedMessage(preDefinedMessage.id, true);

  const handleSetUnsharedMessage = () =>
    onUpdateSharedPreDefinedMessage(preDefinedMessage.id, false);

  return (
    <Flex css={{ gap: "$2" }}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton color="gray" type="ghost">
            <MdMoreVert />
          </IconButton>
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5}>
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
              setEditIsOpen(true);
            }}
          >
            Editar
            <RightSlot>
              <BiSolidPencil color="inherit" fontSize="inherit" />
            </RightSlot>
          </DropdownMenuItem>

          <DropdownMenuItem
            onClick={async (event) => {
              event.stopPropagation();
              setDeleteIsOpen(true);
            }}
          >
            Deletar
            <RightSlot>
              <BsTrashFill color="inherit" fontSize="inherit" />
            </RightSlot>
          </DropdownMenuItem>

          {preDefinedMessage.shared ? (
            <DropdownMenuItem
              onClick={async (event) => {
                event.stopPropagation();
                setUnsharedIsOpen(true);
              }}
            >
              Tornar mensagem pessoal
              <RightSlot>
                <MdFolderShared color="inherit" fontSize="inherit" />
              </RightSlot>
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem
              onClick={async (event) => {
                event.stopPropagation();
                setSharedIsOpen(true);
              }}
            >
              Tornar mensagem compartilhada
              <RightSlot>
                <MdFolderShared color="inherit" fontSize="inherit" />
              </RightSlot>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={deleteIsOpen} onOpenChange={(e) => setDeleteIsOpen(e)}>
        <DialogTrigger />

        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
              onClick={(event) => event.stopPropagation()}
            >
              <MdClose />
            </IconButton>
          </DialogClose>

          <DeletePreDefinedMessageConfirmation
            onDeletePrefinedMessage={handleDeletePreDefinedMessage}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={editIsOpen} onOpenChange={(e) => setEditIsOpen(e)}>
        <DialogTrigger />

        <DialogContent onClick={(event) => event.stopPropagation()}>
          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
              onClick={(event) => event.stopPropagation()}
            >
              <MdClose />
            </IconButton>
          </DialogClose>

          <DialogTitle>Atualizar mensagem</DialogTitle>

          <UpdatePreDefinedMessage
            preDefinedMessage={preDefinedMessage}
            onUpdatePreDefinedMessage={onUpdatePreDefinedMessage}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={sharedIsOpen} onOpenChange={(e) => setSharedIsOpen(e)}>
        <DialogTrigger />
        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <SharePreDefinedMessageConfirmation
            onShareMessage={handleSetSharedMessage}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={unsharedIsOpen} onOpenChange={(e) => setUnsharedIsOpen(e)}>
        <DialogTrigger />
        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <UnsharePreDefinedMessageConfirmation
            onShareMessage={handleSetUnsharedMessage}
          />
        </DialogContent>
      </Dialog>
    </Flex>
  );
}
