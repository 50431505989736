import { ContentWithImage } from "@/components/ContentWithImage";
import { BusinessAssociate } from "@/core/models/business-associate.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import FireWorksImage from "@assets/fireworks.svg";
import {
  Flex,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BusinessAssociateConfigList } from "../components/BusinessAssociateConfigList";
import { BusinessAssociateDetailedDescription } from "../components/BusinessAssociateDetailedDescription";
import { BusinessAssociatedHeader } from "../components/BusinessAssociatedHeader";
import { UpdateBusinessAssociateDialog } from "../components/UpdateBusinessAssociateDialog";
import { useBreadcrumbsForBusinessAssociatePage } from "../hooks/useBreadcrumbsForBusinessAssociatePage";
import { useBusinessAssociateMixpanel } from "../hooks/useBusinessAssociateMixpanel";
import {
  associateBusinessAssociateAndCompany,
  getBusinessAssociates,
  selectBusinessAssociates,
  selectSelectedBusinessAssociate,
  setSelectedBusinessAssociate,
} from "../store/businessAssociatesSlice";

export function BusinessAssociatePage() {
  const [selectedTab, setSelectedTab] = useState("description");

  const businessAssociates = useSelector(selectBusinessAssociates);
  const selectedBusinessAssociate = useSelector(
    selectSelectedBusinessAssociate
  );

  const [businessAssociateIsEnabled, setBusinessAssociateIsEnabled] = useState(
    selectedBusinessAssociate.enabled
  );

  const dispatch = useAppDispatch();
  const { showBreadcrumbs } = useBreadcrumbsForBusinessAssociatePage();
  const navigate = useNavigate();
  const { business_attendance_id } = useParams();
  const {
    businessAssociateOpenedEvent,
    businessAssociateDisabledEvent,
    businessAssociateEnabledEvent,
  } = useBusinessAssociateMixpanel();

  const handleEnableBusinessAssociate = async () => {
    setBusinessAssociateIsEnabled(true);
    setSelectedTab("config");
    await dispatch(getBusinessAssociates()).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const businessAssociates: BusinessAssociate[] = res.payload;
        const findedBusinessAssociate = businessAssociates.find(
          (businessAssociate) => {
            return businessAssociate.id === business_attendance_id;
          }
        );
        await dispatch(setSelectedBusinessAssociate(findedBusinessAssociate));
        businessAssociateEnabledEvent(findedBusinessAssociate, true);
      }
    });
  };

  const disableBusinessAssociate = async () => {
    const data = {
      enabled: false,
    };

    await dispatch(
      associateBusinessAssociateAndCompany({
        partner_id: selectedBusinessAssociate.id,
        body: data,
      })
    );
    businessAssociateDisabledEvent(selectedBusinessAssociate, false);
    await dispatch(getBusinessAssociates()).then(() => {
      navigate("/profile/integrations/partners/list");
    });
  };

  useEffect(() => {
    const handleSetSelectedBusinessAssociateByUrl = async () => {
      const findedBusinessAssociate = businessAssociates.find(
        (businessAssociate) => {
          return businessAssociate.id === business_attendance_id;
        }
      );

      if (findedBusinessAssociate) {
        await dispatch(setSelectedBusinessAssociate(findedBusinessAssociate));
      } else {
        navigate(`/profile/integrations/partners/list`);
        toast.error("Não encontramos a integração que você estava buscando.");
      }
    };

    handleSetSelectedBusinessAssociateByUrl();
  }, []);

  useEffect(() => {
    setBusinessAssociateIsEnabled(selectedBusinessAssociate.enabled);
    showBreadcrumbs(selectedBusinessAssociate);
    businessAssociateOpenedEvent(selectedBusinessAssociate);
  }, [selectedBusinessAssociate]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "900px",
        margin: "0 auto",
        height: "100%",
      }}
    >
      <BusinessAssociatedHeader
        business={selectedBusinessAssociate}
        urlToNavigate="/profile/integrations/partners/list"
        onDisableBusinessAssociate={disableBusinessAssociate}
        onEnableBusinessAssociate={handleEnableBusinessAssociate}
      />

      <Flex
        css={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
          gap: "$4",
          mt: "$2",
        }}
      >
        <TabsRoot
          defaultValue={selectedTab}
          value={selectedTab}
          onValueChange={(v) => setSelectedTab(v)}
          css={{
            width: "100%",
            height: "100%",
            boxShadow: "none",
          }}
        >
          <TabsList aria-label="Manage your account">
            <TabsTrigger value="description">Descrição</TabsTrigger>
            {businessAssociateIsEnabled && (
              <TabsTrigger value="config">Configurações</TabsTrigger>
            )}
          </TabsList>
          <TabsContent value="description">
            <BusinessAssociateDetailedDescription
              content={selectedBusinessAssociate.detailed_description}
            />
          </TabsContent>
          {businessAssociateIsEnabled && (
            <TabsContent value="config">
              <>
                {selectedBusinessAssociate.govendas_fields.length > 0 ||
                selectedBusinessAssociate.associate_fields.length > 0 ? (
                  <Flex
                    css={{
                      flexDirection: "column",
                      gap: "$4",
                      height: "200px",
                    }}
                  >
                    <UpdateBusinessAssociateDialog
                      businessAssociate={selectedBusinessAssociate}
                      onUpdateBusinessAssociate={handleEnableBusinessAssociate}
                    />

                    <BusinessAssociateConfigList
                      businessAssociate={selectedBusinessAssociate}
                    />
                  </Flex>
                ) : (
                  <ContentWithImage size="large" image={FireWorksImage}>
                    Para essa integração, não é necessário configuração.
                  </ContentWithImage>
                )}
              </>
            </TabsContent>
          )}
        </TabsRoot>
      </Flex>
    </Flex>
  );
}
