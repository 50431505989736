import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { Indicators } from "@/core/models/indicators.model";
import { ListOfTagsFilter } from "@/core/models/listOfTags.model";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { RootState } from "../../../core/store/store";

interface IndicatorsState {
  data: Indicators;
  status: "loading" | "success" | "failed";
}

const initialState = {
  data: {},
  status: "loading",
} as IndicatorsState;

export interface GetIndicatorsProps {
  data: ListOfTagsFilter;
  indicators_name: string;
}

export const getIndicators = createAsyncThunk(
  "indicators/getIndicators",
  async ({ data, indicators_name }: GetIndicatorsProps, thunkAPI) => {
    try {
      const response = await axios.post(`/indicators/${indicators_name}`, data);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const indicatorsSlice = createSlice({
  name: "indicatorsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIndicators.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getIndicators.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    builder.addCase(
      getIndicators.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the indicators");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectIndicators = (state: RootState) =>
  state.indicatorsState.data;
export const selectIndicatorsStatus = (state: RootState) =>
  state.indicatorsState.status;

export const indicatorsReducer = indicatorsSlice.reducer;
