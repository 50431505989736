import { UserRole } from "@/core/models/user.model";
import { useTranslateRole } from "@/features/profile/hooks/useTranslateRole";
import { Text } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface UserRoleCellProps {
  role: UserRole;
}

type Colors = "primary500" | "red500" | "blue500" | "yellow500";

export function UserRoleCell({ role }: UserRoleCellProps) {
  const translatedRole = useTranslateRole();

  const getColorOfRole = (role: UserRole): Colors => {
    switch (role) {
      case "SALESMAN":
        return "primary500";
      case "SUPERVISOR":
        return "yellow500";
      case "MANAGER":
        return "blue500";
      case "ADMIN":
        return "red500";
      default:
        return "primary500";
    }
  };

  return (
    <RoleCell $color={getColorOfRole(role)}>
      <Text css={{ color: "inherit", fontSize: "inherit", fontWeight: 500 }}>
        {translatedRole(role)}
      </Text>
    </RoleCell>
  );
}

interface RoleCellProps {
  $color: Colors;
}

const RoleCell = styled.div<RoleCellProps>`
  width: 125px;
  padding: ${(props) => props.theme.space[1]} ${(props) => props.theme.space[4]};
  text-align: center;
  border: 1px solid ${(props) => props.theme.colors[props.$color]};
  color: ${(props) => props.theme.colors[props.$color]};
  font-size: 0.875rem;

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    width: 120px;
  }
`;
