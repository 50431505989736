import styled from "styled-components";

export const ReminderPickHourInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    width: 60px;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    border-radius: ${({ theme }) => theme.space[1]};
    padding: ${({ theme }) => theme.space[2]};
    text-align: center;
  }

  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray500};
  }
`;
