import { DateFilter } from "@core/models/dateFilter.model";
import moment from "moment";

export const useDateByDateFilter = () => {
  const getDateByDateFilter = (date_filter: string) => {
    if (date_filter) {
      const dateNameRegex =
        /(before|next|inthis)(\d*)(minute[s]?|hour[s]?|day[s]?|week[s]?|month[s]?|year[s]?)(\~?)/;
      const dateFormatRegex = /(\d{4}-\d{2}-\d{2})\~(\d{4}-\d{2}-\d{2})/;

      let startDate = moment();
      let endDate = moment();

      if (dateNameRegex.test(date_filter)) {
        const match = date_filter.match(dateNameRegex);
        const value = parseInt(match[2], 10);
        const unit = match[3] as moment.DurationInputArg2;
        if (match[1] === "before") {
          startDate = startDate.subtract(value, unit);
          if (match[4].length !== 0) {
            startDate = startDate.subtract(1, "days");
            endDate = endDate.subtract(1, "days");
          }
        } else if (match[1] === "next") {
          endDate = endDate.add(value, unit);
          if (match[4].length !== 0) {
            startDate = startDate.add(1, "days");
            endDate = endDate.add(1, "days");
          }
        } else if (match[1] === "inthis") {
          startDate = startDate.startOf(unit);
          endDate = endDate.endOf(unit);
        }
      } else if (dateFormatRegex.test(date_filter)) {
        const match = date_filter.match(dateFormatRegex);
        startDate = moment(match[1]);
        endDate = moment(match[2]);
      }

      return `${startDate.format("YYYY/MM/DD")}~${endDate.format(
        "YYYY-MM-DD"
      )}`;
    } else return null;
  };

  const getValuesByDateFilter = (date: string) => {
    if (date) {
      const dateNameRegex =
        /(before|next|inthis)(\d*)(minute[s]?|hour[s]?|day[s]?|week[s]?|month[s]?|year[s]?)(\~?)/;
      const dateFormatRegex = /(\d{4}-\d{2}-\d{2})\~(\d{4}-\d{2}-\d{2})/;

      if (dateNameRegex.test(date)) {
        const match = date.match(dateNameRegex);
        const value = parseInt(match[2], 10);
        if (match[1] === "before") {
          const dateFilter: DateFilter = {
            type: "before",
            before: {
              value: value,
              date_option: match[3],
            },
          };

          return dateFilter;
        } else if (match[1] === "next") {
          const dateFilter: DateFilter = {
            type: "next",
            next: {
              value: value,
              date_option: match[3],
            },
          };

          return dateFilter;
        } else if (match[1] === "inthis") {
          const dateFilter: DateFilter = {
            type: "inthis",
            inthis: {
              value: value,
              date_option: match[3],
            },
          };

          return dateFilter;
        }
      } else if (dateFormatRegex.test(date)) {
        const match = date.match(dateFormatRegex);
        const dateFilter: DateFilter = {
          type: "between",
          between: {
            start_date: match[1],
            end_date: match[2],
          },
        };

        return dateFilter;
      }
    } else {
      const dateFilter: DateFilter = {
        type: "inthis",
        inthis: {
          value: 3,
          date_option: "months",
        },
      };

      return dateFilter;
    }
  };

  return { getDateByDateFilter, getValuesByDateFilter };
};
