import { Notification } from "@/core/models/notification.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatDate } from "@/hooks/useFormatDate";
import { Caption, Heading } from "@gogeo-io/ui-library";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNotificationsMixpanel } from "../hooks/useNotificationsMixpanel";
import { setSelectedNotification } from "../store/notificationsSlice";
import UI from "../ui";

interface ReminderItemProps {
  notification: Notification;
}

export function NotificationsItem({ notification }: ReminderItemProps) {
  const { id, date, title } = notification;

  const { formatLongDatePtBr } = useFormatDate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { notification_id } = useParams();
  const { notificationAccessedEvent } = useNotificationsMixpanel();

  const dateParsed = useMemo(() => {
    return formatLongDatePtBr(date);
  }, [date]);

  const handleNavigate = async () => {
    await dispatch(setSelectedNotification(notification));
    notificationAccessedEvent(id);
    navigate(`/notifications/${id}`);
  };

  return (
    <UI.NotificationsItem
      className={id === Number(notification_id) ? "active" : ""}
      onClick={handleNavigate}
    >
      <Heading size="subtitle1" style={{ cursor: "pointer" }}>
        {title}
      </Heading>
      <Caption>{dateParsed}</Caption>
    </UI.NotificationsItem>
  );
}
