import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { PaginatedRequestBaseProps } from "@/core/models/paginatedRequest.model";
import {
  PaginatedPreDefinedMessageResponse,
  PreDefinedMessageSubject,
} from "@/core/models/preDefinedMessage.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface PreDefinedMessagesState {
  preDefinedMessages: PaginatedPreDefinedMessageResponse;
  has500error: boolean;
  status: "loading" | "success" | "failed";
  totalPreDefinedMessages: number;
}

const initialState = {
  preDefinedMessages: {},
  has500error: false,
  status: "loading",
} as PreDefinedMessagesState;

interface GetPrefinedMessagesProps extends PaginatedRequestBaseProps {}

export const getPreDefinedMessages = createAsyncThunk(
  "preDefinedMessages/getPreDefinedMessages",
  async ({ page, size }: GetPrefinedMessagesProps, thunkAPI) => {
    try {
      const response = await axios.get(`/message?page=${page}&size=${size}`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface CreatePreDefinedMessageProps {
  subject: PreDefinedMessageSubject;
  title: string;
  message: string;
  shared: boolean;
}

export const createPreDefinedMessage = createAsyncThunk(
  "preDefinedMessages/createPreDefinedMessage",
  async (data: CreatePreDefinedMessageProps, thunkAPI) => {
    try {
      const response = await axios.post("/message", data);
      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 202
      ) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface UpdatePreDefinedMessageProps {
  id: number;
  subject?: PreDefinedMessageSubject;
  title?: string;
  message?: string;
  shared: boolean;
}

export const updatePreDefinedMessage = createAsyncThunk(
  "preDefinedMessages/updatePreDefinedMessage",
  async (data: UpdatePreDefinedMessageProps, thunkAPI) => {
    try {
      const response = await axios.put(`/message/${data.id}`, data);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface DeletePreDefinedMessageProps {
  id: number;
}

export const deletePreDefinedMessage = createAsyncThunk(
  "preDefinedMessages/deletePreDefinedMessage",
  async (data: DeletePreDefinedMessageProps, thunkAPI) => {
    try {
      const response = await axios.delete(`/message/${data.id}`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const preDefinedMessagesSlice = createSlice({
  name: "preDefinedMessagesSlice",
  initialState,
  reducers: {
    setTotalPreDefinedMessages(state, action: PayloadAction<number>) {
      state.totalPreDefinedMessages = action.payload;
    },
    updatePreMessagesState(state, action: PayloadAction<any>) {
      state.preDefinedMessages.messages = state.preDefinedMessages.messages.map(
        (msg) => (msg.id === action.payload.id ? action.payload : msg)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPreDefinedMessages.pending,
      (state, action: PayloadAction<any>) => {
        state.status = "loading";
        state.has500error = false;
      }
    );
    builder.addCase(
      getPreDefinedMessages.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.has500error = false;
        state.preDefinedMessages = action.payload;
      }
    );
    builder.addCase(
      getPreDefinedMessages.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error on getting pre defined messages");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      createPreDefinedMessage.pending,
      (state, action: PayloadAction<any>) => {
        state.status = "loading";
      }
    );
    builder.addCase(
      createPreDefinedMessage.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
      }
    );
    builder.addCase(
      createPreDefinedMessage.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error creating a pre defined message");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );

    builder.addCase(
      updatePreDefinedMessage.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating a pre defined message");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );

    builder.addCase(
      deletePreDefinedMessage.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error deleting a pre defined message");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectPreDefinedMessagesBySubject =
  (option: PreDefinedMessageSubject) => (state: RootState) =>
    state.preDefinedMessagesState.preDefinedMessages.messages !== undefined
      ? state.preDefinedMessagesState.preDefinedMessages.messages.filter(
          (msg) => msg.subject === option
        )
      : [];
export const selectPreDefinedMessageByTitleAndSubject =
  (title: string, option: PreDefinedMessageSubject) => (state: RootState) =>
    state.preDefinedMessagesState.preDefinedMessages.messages?.find(
      (msg) => msg.title === title && msg.subject === option
    );
export const selectTotalPreDefinedMessages = (state: RootState) =>
  state.preDefinedMessagesState.totalPreDefinedMessages;
export const selectPreDefinedMessagesStatus = (state: RootState) =>
  state.preDefinedMessagesState.status;
export const selectPreDefinedMessagesHasError500 = (state: RootState) =>
  state.preDefinedMessagesState.has500error;

export const { setTotalPreDefinedMessages, updatePreMessagesState } =
  preDefinedMessagesSlice.actions;

export const preDefinesMessagesReducer = preDefinedMessagesSlice.reducer;
