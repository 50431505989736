import { UserPermission } from "@/core/models/user-permission.model";
import {
  removeAllUserToBatchActions,
  selectSelectedUsersToBatchActions,
} from "@/core/store/users/usersSlice";
import {
  getUserPermissions,
  selectUserPermissionsStatus,
  updateUsersPermissions,
} from "@/features/usersManagment/store/userPermissionsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  Text,
} from "@gogeo-io/ui-library";
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { useUsersMixpanel } from "../hooks/useUsersMixpanel";
import { UsersPermissionsModalContent } from "./UsersPermissionsModalContent";
import { UserPermissionsSkeleton } from "./skeleton/UserPermissionsSkeleton";

interface UserPermissionsModalProps {
  children: ReactNode;
}

export function UsersPermissionsModal({ children }: UserPermissionsModalProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const selectedUsersToBatchActions = useSelector(
    selectSelectedUsersToBatchActions
  );
  const userPermissionsStatus = useSelector(selectUserPermissionsStatus);

  const dispatch = useAppDispatch();
  const { changeUsersPermissionsEvent } = useUsersMixpanel();

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const closeDialog = () => setDialogIsOpen(false);

  const changePermissions = async (updated_permissions: UserPermission[]) => {
    await dispatch(
      updateUsersPermissions({
        user_ids: selectedUsersToBatchActions.map((user) => user.id),
        updated_permissions: updated_permissions,
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        changeUsersPermissionsEvent(
          selectedUsersToBatchActions,
          updated_permissions
        );
        await dispatch(removeAllUserToBatchActions());
      }
    });
  };

  const loadUserPermissions = async () => {
    await dispatch(
      getUserPermissions({ user_id: selectedUsersToBatchActions[0].id })
    );
  };

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild onClick={loadUserPermissions}>
        {children}
      </DialogTrigger>
      <DialogContent style={{ width: "500px", maxHeight: "90vh" }}>
        <DialogTitle>Permissões dos Usuários</DialogTitle>

        {selectedUsersToBatchActions.length === 0 ? (
          <Flex
            css={{
              flexDirection: "column",
              gap: "$2",
              height: "100%",
            }}
          >
            <Text css={{ mt: "$4" }}>
              Selecione ao menos um usuário para prosseguir.
            </Text>

            <Flex
              css={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                gap: "$2",
                mt: "$4",
              }}
            >
              <Button bordered onClick={() => closeDialog()}>
                Cancelar
              </Button>
              <Button disabled>Salvar</Button>
            </Flex>
          </Flex>
        ) : (
          <>
            {userPermissionsStatus === "loading" ? (
              <UserPermissionsSkeleton />
            ) : (
              <UsersPermissionsModalContent
                onChangePermissions={changePermissions}
                onCloseDialog={closeDialog}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
