import { Button, Flex, Heading } from "@gogeo-io/ui-library";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectSelectedClientNote } from "../../store/clientNotesSlice";
import { PersonalNoteLabel } from "./PersonalNoteLabel";
import { PublicNoteLabel } from "./PublicNoteLabel";

interface ClientNoteListHeaderProps {
  title: string;
  buttonTitle: string;
}

export function ClientNoteListHeader({
  title,
  buttonTitle,
}: ClientNoteListHeaderProps) {
  const navigate = useNavigate();

  const selectedClientNote = useSelector(selectSelectedClientNote);

  const handleClickToEditNote = async () => {
    navigate(
      `/info/client/${selectedClientNote.client_id}/notes/edit/${selectedClientNote.id}`
    );
  };

  const handleNavigateToNotes = () => {
    navigate(`/info/client/${selectedClientNote.client_id}/notes`);
  };

  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <Flex css={{ flexDirection: "column", gap: "$3" }}>
        <Flex
          css={{
            color: "$gray700",
            fontSize: "20px",
            alignItems: "center",
            gap: "$2",
          }}
        >
          <AiOutlineArrowLeft
            color="inherit"
            fontSize="inherit"
            style={{ cursor: "pointer" }}
            onClick={handleNavigateToNotes}
          />
          <Heading size="title6" css={{ color: "inherit", gap: "$2" }}>
            {title}
          </Heading>
        </Flex>

        {selectedClientNote && selectedClientNote.shared ? (
          <PublicNoteLabel />
        ) : (
          <PersonalNoteLabel />
        )}
      </Flex>

      <Button onClick={handleClickToEditNote}>{buttonTitle}</Button>
    </Flex>
  );
}
