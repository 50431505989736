import ClientStatus from "@/components/ClientStatus";
import { FileMetadata } from "@/core/models/file.model";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { AiFillFile } from "react-icons/ai";
import { BiSolidCommentDetail } from "react-icons/bi";

interface TimelineHeaderInfoContentHeaderProps {
  feedback_timestamp: string;
  feedback_id: number;
  feedback: string;
  observations: string;
  files: FileMetadata[];
  onMoreInfoOpen: () => void;
}

export function TimelineHeaderInfoContentHeader({
  feedback_timestamp,
  feedback_id,
  feedback,
  observations,
  files,
  onMoreInfoOpen,
}: TimelineHeaderInfoContentHeaderProps) {
  return (
    <Caption
      css={{
        color: "$gray500",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        gap: "$2",
      }}
    >
      {!isEmpty(feedback_timestamp) ? (
        <>{format(new Date(feedback_timestamp), "hh:mm a ")}</>
      ) : (
        <>--/--/-- | --:--</>
      )}
      <ClientStatus feedback_id={feedback_id} />
      {feedback}
      <Flex onClick={onMoreInfoOpen} css={{ gap: "$2", cursor: "pointer" }}>
        {observations && observations !== "" && (
          <BiSolidCommentDetail fontSize="inherit" />
        )}
        {files && files.length > 0 && <AiFillFile fontSize="inherit" />}
      </Flex>
    </Caption>
  );
}
