import { Flex, Heading } from "@gogeo-io/ui-library";
import { ProfileSubMenuList } from "./ProfileSubMenuList";

interface UserProfileProps {
  ruptureRulesNotHaveError: boolean;
}

export function UserProfile({ ruptureRulesNotHaveError }: UserProfileProps) {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$3",
        overflowY: "auto",
      }}
    >
      <Heading size="title6" css={{ paddingLeft: "$1" }}>
        Configurações
      </Heading>

      <ProfileSubMenuList ruptureRulesNotHaveError={ruptureRulesNotHaveError} />
    </Flex>
  );
}
