import { FileAttached } from "./FileAttached";
import { Timeline } from "./Timeline";
import { TimelineAttendanceItemBox } from "./TimelineAttendanceItemBox";
import { TimelineAttendanceRecord } from "./TimelineAttendanceRecord";
import { TimelineAttendanceRecordAttendanceItem } from "./TimelineAttendanceRecordAttendanceItem";
import { TimelineAttendanceRecordHeader } from "./TimelineAttendanceRecordHeader";
import { TimelineDot } from "./TimelineDot";
import { TimelineSeparator } from "./TimelineSeparator";
import { TimelineWrapper } from "./TimelineWrapper";

export default {
  Timeline,
  TimelineWrapper,
  TimelineSeparator,
  TimelineDot,
  TimelineAttendanceRecord,
  TimelineAttendanceRecordHeader,
  TimelineAttendanceItemBox,
  TimelineAttendanceRecordAttendanceItem,
  FileAttached,
};
