import { Breadcrumb } from "@/core/models/breadcrumb";
import { BreadcrumbText } from "./BreadCrumbs";

interface LastBreadcrumbItemProps {
  breadcrumb: Breadcrumb;
}

export function LastBreadcrumbItem({ breadcrumb }: LastBreadcrumbItemProps) {
  return (
    <BreadcrumbText css={{ filter: "brightness(140%)", pointerEvents: "none" }}>
      {breadcrumb.item}
    </BreadcrumbText>
  );
}
