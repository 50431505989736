import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function BusinessAssociateServicesModalContentSkeleton() {
  const timesToRepeat = Array.from(Array(3).keys());

  return (
    <Flex css={{ flexDirection: "column", gap: "$3", mt: "$3" }}>
      <>
        {timesToRepeat.map((c) => {
          return (
            <Flex css={{ flexDirection: "column", gap: "$1" }} key={c}>
              <RLSSkeleton width={120} height={23} />
              <Flex
                css={{ flexDirection: "column", gap: "$2", marginLeft: "$2" }}
              >
                <Flex css={{ gap: "$1" }}>
                  <RLSSkeleton width={20} height={20} />

                  <RLSSkeleton width={130} height={20} />
                </Flex>
                <Flex css={{ gap: "$1" }}>
                  <RLSSkeleton width={20} height={20} />

                  <RLSSkeleton width={130} height={20} />
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </>
    </Flex>
  );
}
