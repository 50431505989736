import { useFormatPrice } from "@/hooks/useFormatPrice";
import { Flex, Text } from "@gogeo-io/ui-library";

interface TimelineHeaderInfoOrderInfoProps {
  order_id: string;
  order_amount: number;
}

export function TimelineHeaderInfoOrderInfo({
  order_id,
  order_amount,
}: TimelineHeaderInfoOrderInfoProps) {
  const { formatBRLPrice } = useFormatPrice();

  return (
    <Flex
      css={{
        flexDirection: "row",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <Text css={{ color: "$gray500" }}>#{order_id}</Text>
      <Text>|</Text>
      <Text css={{ color: "$gray900" }} bold>
        {formatBRLPrice(order_amount)}
      </Text>
    </Flex>
  );
}
