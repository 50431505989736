import { ListDivider } from "@/components/ListDivider";
import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function UserPermissionsSkeleton() {
  const timesToRepeat = Array.from(Array(4).keys());

  return (
    <Flex css={{ flexDirection: "column", gap: "$4" }}>
      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        {timesToRepeat.map((t, idx) => {
          return (
            <Flex css={{ flexDirection: "column", gap: "$3" }} key={t}>
              <Flex css={{ flexDirection: "column", gap: "$3" }}>
                <RLSSkeleton width={150} height={22} />
                <Flex
                  css={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <RLSSkeleton width={60} height={20} />
                  <RLSSkeleton width={20} height={20} />
                </Flex>
                <Flex
                  css={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <RLSSkeleton width={60} height={20} />
                  <RLSSkeleton width={20} height={20} />
                </Flex>
                <RLSSkeleton width={200} height={22} />
              </Flex>
              {idx !== timesToRepeat.length - 1 && <ListDivider $isSkeleton />}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}
