import { Caption, Flex } from "@gogeo-io/ui-library";
import { AiOutlineClose } from "react-icons/ai";
import { AddFilesToEditor } from "./AddFilesToEditor";

interface TextEditorFilesProps {
  title: string;
  files: File[];
  onFilesChange: (newFiles: File[]) => void;
  onRemoveFile: (fileNameToRemove: string) => void;
}

export function TextEditorFiles({
  title,
  files,
  onFilesChange,
  onRemoveFile,
}: TextEditorFilesProps) {
  return (
    <Flex css={{ gap: "$3", alignItems: "center" }}>
      <Caption css={{ color: "$gray500" }}>{title}</Caption>
      <Flex css={{ flexWrap: "wrap", gap: "$3" }}>
        {files &&
          files.map((file) => {
            return (
              <Flex
                key={file.name}
                css={{
                  alignItems: "center",
                  fontSize: "0.875rem",
                  gap: "$2",
                  padding: "$1 $3",
                  border: "1px solid $gray400",
                  color: "$gray500",
                  borderRadius: "10px",
                }}
              >
                <Caption css={{ color: "inherit" }}>{file.name}</Caption>
                <AiOutlineClose
                  color="inherit"
                  fontSize="inherit"
                  cursor="pointer"
                  onClick={() => onRemoveFile(file.name)}
                />
              </Flex>
            );
          })}
      </Flex>

      <AddFilesToEditor files={files} onFilesChange={onFilesChange} />
    </Flex>
  );
}
