import { EmptyContent } from "@/components/EmptyContent";
import { Flex, Grid, Heading, Text } from "@gogeo-io/ui-library";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BusinessAssociatesServiceConfigListItem } from "../components/BusinessAssociatesServiceConfigListItem";
import { useBreadcrumbsForBusinessAssociatesServicesConfigPage } from "../hooks/useBreadcrumbsForBusinessAssociatesServicesConfigPage";
import { selectBusinessAssociatesServicesConfig } from "../store/businessAssociatesServicesConfigSlice";

export function BusinessAssociatesServicesPage() {
  const businessAssociates = useSelector(
    selectBusinessAssociatesServicesConfig
  );

  const { showBreadcrumbs } =
    useBreadcrumbsForBusinessAssociatesServicesConfigPage();

  const totalOfServices = businessAssociates.reduce((total, associate) => {
    return total + associate.services.length;
  }, 0);

  useEffect(() => {
    showBreadcrumbs();
  }, []);

  return (
    <Flex css={{ flexDirection: "column", gap: "$4", mt: "$2" }}>
      <Flex css={{ flexDirection: "column" }}>
        <Heading size="title6">
          Todos os serviços disponíveis no goVendas
        </Heading>

        <Text>{totalOfServices} serviços encontrados</Text>
      </Flex>

      {totalOfServices <= 0 ? (
        <EmptyContent>
          Você não tem serviços disponíveis. Faça a integração com algum
          parceiro para ter seus serviços disponíveis
        </EmptyContent>
      ) : (
        <Flex css={{ flexDirection: "column", gap: "$6" }}>
          {businessAssociates.map((business) => {
            return (
              <Flex
                key={business.id}
                css={{ flexDirection: "column", gap: "0" }}
              >
                <Heading size="subtitle2">{business.name}</Heading>

                <Grid
                  css={{
                    gridTemplateColumns: "repeat(auto-fill, 300px)",
                    gap: "32px",
                    mt: "$4",
                  }}
                >
                  {business.services.map((service) => {
                    return (
                      <BusinessAssociatesServiceConfigListItem
                        key={service.id}
                        businessAssociate={business}
                        service={service}
                      />
                    );
                  })}
                </Grid>
              </Flex>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
}
