import { ListDivider } from "@/components/ListDivider";
import { User } from "@/core/models/user.model";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  IconButton,
} from "@gogeo-io/ui-library";
import { ReactNode, useState } from "react";
import { MdClose } from "react-icons/md";
import { UsersRelatedToUserModalContent } from "./UsersRelatedToUserModalContent";

interface UsersRelatedToUserModalProps {
  user: User;
  children: ReactNode;
}

export function UsersRelatedToUserModal({
  user,
  children,
}: UsersRelatedToUserModalProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const closeDialog = () => setDialogIsOpen(false);

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent style={{ maxWidth: "500px" }}>
        <DialogClose asChild>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
          >
            <MdClose />
          </IconButton>
        </DialogClose>

        <DialogTitle>Usuários associados à {user.name}</DialogTitle>

        <ListDivider />

        <UsersRelatedToUserModalContent
          user={user}
          onCloseDialog={closeDialog}
        />
      </DialogContent>
    </Dialog>
  );
}
