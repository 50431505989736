import { User } from "@/core/models/user.model";
import { getActiveUsers } from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Checkbox,
  DialogClose,
  Flex,
  Heading,
  Input,
} from "@gogeo-io/ui-library";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import styled from "styled-components";
import { ShareOnGovendasSkeletonPage } from "./ShareOnGovendasSkeletonPage";
import { UserToShareListOnGovendas } from "./UserToShareListOnGovendas";

interface ShareOnGovendasProps {
  selectedUsersToShare: User[];
  onCloseShareListDialog: () => void;
  onOpenAddInfoToSharedListDialog: () => void;
  onSetSelectedUsersToShare: (users: User[]) => void;
}

export function ShareOnGovendas({
  selectedUsersToShare,
  onCloseShareListDialog,
  onOpenAddInfoToSharedListDialog,
  onSetSelectedUsersToShare,
}: ShareOnGovendasProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [allRelatedUsers, setAllRelatedUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

  const dispatch = useAppDispatch();

  const handleInputChange = async (value: string) => {
    if (value === "") {
      setFilteredUsers(allRelatedUsers);
    } else {
      setFilteredUsers(
        allRelatedUsers.filter(
          (user) => user.name.includes(value) || user.email.includes(value)
        )
      );
    }
  };

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  useEffect(() => {
    const loadRelatedUsers = async () => {
      setIsLoading(true);

      await dispatch(getActiveUsers()).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const relatedUsers: User[] = res.payload;
          setFilteredUsers(relatedUsers);
          setAllRelatedUsers(relatedUsers);
          setIsLoading(false);
        }
      });
    };

    loadRelatedUsers();
  }, []);

  const userIsSelected = (user: User): boolean => {
    return selectedUsersToShare.includes(user);
  };

  const handleChangeUserSelected = (value: boolean, user: User) => {
    if (value) {
      const users = [...selectedUsersToShare, user];
      onSetSelectedUsersToShare(users);
    } else {
      const users = selectedUsersToShare.filter(
        (prevUser) => prevUser.id !== user.id
      );
      onSetSelectedUsersToShare(users);
    }
  };

  const onSelectAllUsersToShare = (value: boolean) => {
    if (value) {
      onSetSelectedUsersToShare(allRelatedUsers);
    } else {
      onSetSelectedUsersToShare([]);
    }
  };

  const handleSendCopyOfFilter = async () => {
    onOpenAddInfoToSharedListDialog();
    onCloseShareListDialog();
  };

  const allUsersAreSelected =
    selectedUsersToShare.length === allRelatedUsers.length;

  return (
    <>
      {isLoading ? (
        <ShareOnGovendasSkeletonPage />
      ) : (
        <Flex
          css={{
            flexDirection: "column",
            gap: "$4",
            height: "100%",
            padding: "2px",
            overflow: "hidden",
          }}
        >
          <Input
            preffix={<MdSearch />}
            placeholder="Procurar"
            onChange={(e) => debounceOnChangeInput(e.target.value)}
          />

          <Flex css={{ flexDirection: "column", gap: "$5", height: "100%" }}>
            <Flex css={{ alignItems: "center", gap: "$3" }}>
              <Checkbox
                checked={allUsersAreSelected}
                onCheckedChange={(e) => onSelectAllUsersToShare(Boolean(e))}
              />
              <Heading
                size="subtitle1"
                css={{ textTransform: "capitalize", color: "$gray900" }}
              >
                Usuários selecionados
              </Heading>
              <Flex
                css={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "$1 $2",
                  height: "25px",
                  borderRadius: "4px",
                  color: "$white",
                  backgroundColor: "$gray800",
                }}
              >
                {selectedUsersToShare.length}
              </Flex>
            </Flex>

            <UsersContainer>
              {filteredUsers.map((user) => {
                return (
                  <UserToShareListOnGovendas
                    user={user}
                    userIsSelected={userIsSelected(user)}
                    onChangeUserSelected={handleChangeUserSelected}
                    key={user.id}
                  />
                );
              })}
            </UsersContainer>
          </Flex>

          <Flex
            css={{
              gap: "$2",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <DialogClose asChild>
              <Button bordered>Fechar</Button>
            </DialogClose>

            <Button onClick={handleSendCopyOfFilter}>Avançar</Button>
          </Flex>
        </Flex>
      )}
    </>
  );
}

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[3]};
  height: 40vh;
  overflow-y: scroll;

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    height: 20vh;
  }

  @media (max-width: ${(props) => props.theme.mediaSizes.smallLaptop}) {
    height: 10vh;
  }
`;
