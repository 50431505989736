import styled from "styled-components";

export const TimelineWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[4]};

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10px;
  }

  .right {
    padding-bottom: ${(props) => props.theme.space[5]};
    width: 100%;
  }
`;
