import { EmptyContent } from "@/components/EmptyContent";
import { ErrorContent } from "@/components/ErrorContent";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HistoryFilter } from "../components/HistoryFilter";
import { HistoryPageSkeleton } from "../components/HistoryPageSkeleton";
import { Timeline } from "../components/Timeline";
import { useHistoryBreadcrumbs } from "../hooks/useHistoryBreadcrumbs";
import { useHistoryMixpanel } from "../hooks/useHistoryMixpanel";
import { selectSelectedHistoryFilters } from "../store/historyFiltersSlice";
import {
  getHistory,
  getHistoryPage,
  selectHistory,
  selectHistoryHasError500,
  selectHistoryHasMorePages,
  selectHistoryStatus,
} from "../store/historySlice";

export function HistoryPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const [historyIsCollapsed, setHistoryIsCollapsed] = useState(true);

  const selectedClient = useSelector(selectClient);
  const selectedHistoryFilters = useSelector(selectSelectedHistoryFilters);
  const historyHasError500 = useSelector(selectHistoryHasError500);
  const selectedList = useSelector(selectSelectedList);
  const hasMorePages = useSelector(selectHistoryHasMorePages);
  const historyStatus = useSelector(selectHistoryStatus);
  const history = useSelector(selectHistory);

  const dispatch = useAppDispatch();
  const { historyBreadcrumbs } = useHistoryBreadcrumbs();
  const { historyLoadedEvent } = useHistoryMixpanel();

  const handleSetHistoryIsCollapsed = () =>
    setHistoryIsCollapsed((prevState) => !prevState);

  const getPaginatedHistory = async (currentPage: number) => {
    await dispatch(
      getHistoryPage({
        client_id: selectedClient.id,
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
        filters: selectedHistoryFilters,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        historyLoadedEvent(res.payload, selectedHistoryFilters);
      }
    });
  };

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  async function loadHistory() {
    setCurrentPage(0);

    await dispatch(
      getHistory({
        client_id: selectedClient.id,
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        filters: selectedHistoryFilters,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        historyLoadedEvent(res.payload, selectedHistoryFilters);
      }
    });
  }

  useEffect(() => {
    loadHistory();
  }, [selectedHistoryFilters]);

  useEffect(() => {
    historyBreadcrumbs();
  }, [selectedList, selectedClient]);

  useEffect(() => {
    if (historyStatus === "success" && history.length > 0) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (hasMorePages) {
            handleIncreasePageNumber();
          }
        }
      });

      intersectionObserver.observe(document.querySelector("#sentry"));

      return () => intersectionObserver.disconnect();
    }
  }, [historyStatus]);

  useEffect(() => {
    if (currentPage !== 0) {
      if (hasMorePages) {
        getPaginatedHistory(currentPage);
      }
    }
  }, [currentPage]);

  if (historyHasError500) {
    return (
      <>
        <G_UI.ClientInfoContent>
          <HistoryFilter />

          <ErrorContent size="medium">
            Não conseguimos buscar o seu histórico de atendimento, tente
            novamente mais tarde ou atualize a página
          </ErrorContent>
        </G_UI.ClientInfoContent>
      </>
    );
  }

  return (
    <>
      <G_UI.ClientInfoContent>
        <HistoryFilter />

        {historyStatus === "success" ? (
          <>
            {history.length === 0 ? (
              <EmptyContent size="large">
                Este cliente não possui histórico de atendimento
              </EmptyContent>
            ) : (
              <Flex css={{ overflowY: "scroll" }}>
                <Timeline
                  history={history}
                  historyIsCollapsed={historyIsCollapsed}
                  onHistoryIsCollapsed={handleSetHistoryIsCollapsed}
                  onHistoryRefresh={loadHistory}
                  hasMorePages={hasMorePages}
                />
              </Flex>
            )}
          </>
        ) : (
          <HistoryPageSkeleton />
        )}
      </G_UI.ClientInfoContent>
    </>
  );
}
