import { Client } from "@/core/models/client.model";
import { BusinessAssociateServicesModalContentSkeleton } from "@/features/businessAssociate/components/BusinessAssociateServicesModalContentSkeleton";
import {
  getBusinessAssociateServicesOfVoip,
  selectBusinessAssociateServicesOfVoipStatus,
} from "@/features/businessAssociate/store/businessAssociateServicesSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BusinessAssociateServicesOfVoipModalContent } from "./BusinessAssociateServicesOfVoipModalContent";

interface BusinessAssociateServicesOfVoipContentContainerProps {
  phoneNumber: string;
  client: Client;
  onCloseDialog: () => void;
}

export function BusinessAssociateServicesOfVoipContentContainer({
  phoneNumber,
  client,
  onCloseDialog,
}: BusinessAssociateServicesOfVoipContentContainerProps) {
  const businessAssociateServicesOfVoipStatus = useSelector(
    selectBusinessAssociateServicesOfVoipStatus
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadBusinessAssociateServices = async () => {
      await dispatch(getBusinessAssociateServicesOfVoip());
    };

    loadBusinessAssociateServices();
  }, []);

  return (
    <>
      {businessAssociateServicesOfVoipStatus === "success" ? (
        <BusinessAssociateServicesOfVoipModalContent
          phoneNumber={phoneNumber}
          client={client}
          onCloseDialog={onCloseDialog}
        />
      ) : (
        <BusinessAssociateServicesModalContentSkeleton />
      )}
    </>
  );
}
