import { Box, Text } from "@gogeo-io/ui-library";

interface UserHavePermissionProps {
  hasPermission: boolean;
  defaultFallback?: boolean;
  adicionalMessageToDefaultFallback?: string;
  customFallback?: React.ReactElement;
  children: any;
}

export function UserHavePermission({
  hasPermission,
  defaultFallback = false,
  adicionalMessageToDefaultFallback,
  customFallback,
  children,
}: UserHavePermissionProps) {
  return (
    <>
      {hasPermission ? (
        <Box>{children}</Box>
      ) : (
        <>
          {!customFallback && defaultFallback && (
            <Box>
              <Text>
                Você não tem acesso a esse recurso.{" "}
                {adicionalMessageToDefaultFallback}
              </Text>
            </Box>
          )}
          {customFallback && <Box>{customFallback}</Box>}
        </>
      )}
    </>
  );
}
