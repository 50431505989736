import { Breadcrumb } from "@/core/models/breadcrumb";
import { BusinessAssociate } from "@/core/models/business-associate.model";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";

export const useBreadcrumbsForBusinessAssociatePage = () => {
  const dispatch = useAppDispatch();

  const showBreadcrumbs = async (business: BusinessAssociate) => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Configurações", url: "/profile/user-profile/info" },
      {
        item: "Integrações",
        url: `/profile/integrations/partners/list`,
      },
      {
        item: "Parceiros",
        url: `/profile/integrations/partners/list`,
      },
      {
        item: business.name,
        url: ``,
      },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return {
    showBreadcrumbs,
  };
};
