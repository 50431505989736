import styled from "styled-components";

export const ReminderItem = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${(props) => props.theme.colors.gray400};
    cursor: pointer;
  }
`;
