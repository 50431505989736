import { selectColumnsToSort } from "@/features/clientInfo/store/clientColumnsToSortSlice";
import {
  Flex,
  gvTheme,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export interface SortableHeaderProps {
  headerName: string;
  property: string;
  onSort: (direction: "ASC" | "DESC" | "", property: string) => void;
  initialSortOrder: "ASC" | "DESC" | "";
}

export function SortableHeader({
  headerName,
  property,
  onSort,
  initialSortOrder,
}: SortableHeaderProps) {
  const [sort, setSort] = useState<"ASC" | "DESC" | "">(initialSortOrder);

  const selectedColumnsToSort = useSelector(selectColumnsToSort);

  useEffect(() => {
    const selectedColumn = selectedColumnsToSort.find(
      (s) => s.property === property
    );
    setSort(selectedColumn !== undefined ? selectedColumn.direction : "");
  }, [selectedColumnsToSort]);

  const handleSortClick = () => {
    if (sort === "ASC") {
      setSort("DESC");
      onSort("DESC", property);
    } else if (sort === "DESC") {
      setSort("");
      onSort("", property);
    } else if (sort === "") {
      setSort("ASC");
      onSort("ASC", property);
    }
  };

  return (
    <Flex onClick={handleSortClick} css={{ cursor: "pointer" }}>
      <Tooltip>
        <TooltipTrigger style={{ background: "transparent" }}>
          {headerName}
        </TooltipTrigger>
        <TooltipContent aria-label="Título da coluna">
          {headerName}
        </TooltipContent>
      </Tooltip>
      {sort === "" && (
        <Flex
          css={{
            flexDirection: "column",
          }}
        >
          <ArrowDropUp
            style={{
              width: "30px",
              height: "30px",
              color: gvTheme.colors.gray400,
            }}
          />
          <ArrowDropDown
            style={{
              marginTop: "-20px",
              width: "30px",
              height: "30px",
              color: gvTheme.colors.gray400,
            }}
          />
        </Flex>
      )}
      {sort === "ASC" && (
        <Flex
          css={{
            flexDirection: "column",
          }}
        >
          <ArrowDropUp
            style={{
              width: "30px",
              height: "30px",
              color: gvTheme.colors.primary600,
            }}
          />
          <ArrowDropDown
            style={{
              marginTop: "-20px",
              width: "30px",
              height: "30px",
              color: gvTheme.colors.gray400,
            }}
          />
        </Flex>
      )}
      {sort === "DESC" && (
        <Flex
          css={{
            flexDirection: "column",
          }}
        >
          <ArrowDropUp
            style={{
              width: "30px",
              height: "30px",
              color: gvTheme.colors.gray400,
            }}
          />
          <ArrowDropDown
            style={{
              marginTop: "-20px",
              width: "30px",
              height: "30px",
              color: gvTheme.colors.danger600,
            }}
          />
        </Flex>
      )}
    </Flex>
  );
}
