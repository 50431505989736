import { Recommendation } from "@/core/models/productsSugestion.model";
import { useFormatPrice } from "@/hooks/useFormatPrice";

export const useProductSuggestionFields = (recommendation: Recommendation) => {
  const { formatBRLPrice } = useFormatPrice();

  const fieldsToTranslate = [
    {
      user_field: "Nome do produto",
      translated_field: recommendation.product_name,
    },
    {
      user_field: "Quantidade do produto para desconto",
      translated_field: recommendation.num_items,
    },
    {
      user_field: "Preço do Produto",
      translated_field: formatBRLPrice(recommendation.unit_value),
    },
    {
      user_field: "Produto em alta",
      translated_field: recommendation.is_trending ? "Sim" : "Não",
    },
  ];

  return fieldsToTranslate;
};
