import { Role } from "@/core/models/user.model";
import { AttendancePage } from "@/features/attendance";
import { ClientsListPage } from "@/features/attendance/pages/ClientsListPage";
import { ListWithUrlFilterPage } from "@/features/attendance/pages/ListWithUrlFilterPage";
import { BusinessAssociatesList } from "@/features/businessAssociate/components/BusinessAssociatesList";
import { BusinessAssociatePage } from "@/features/businessAssociate/pages/BusinessAssociatePage";
import { BusinessAssociatePartnersContainer } from "@/features/businessAssociate/pages/BusinessAssociatePartnersContainer";
import { BusinessAssociateServiceOfPartnerConfigPage } from "@/features/businessAssociate/pages/BusinessAssociateServiceOfPartnerConfigPage";
import { BusinessAssociateServicesContainer } from "@/features/businessAssociate/pages/BusinessAssociateServicesContainer";
import { BusinessAssociatesPage } from "@/features/businessAssociate/pages/BusinessAssociatesPage";
import { BusinessAssociatesServicesPage } from "@/features/businessAssociate/pages/BusinessAssociatesServicesPage";
import { ClientProfilePage } from "@/features/clientInfo";
import { ClientInfoTabs } from "@/features/clientInfo/components/ClientInfoTabs";
import { ClientInfoPage } from "@/features/clientInfo/pages/ClientInfoPage";
import { HistoryPage } from "@/features/history";
import { NotificationPage } from "@/features/notifications/pages/NotificationPage";
import { NotificationsPage } from "@/features/notifications/pages/NotificationsPage";
import { ParamsClientsPage, ParamsPage } from "@/features/params";
import { ChangeParamsOfSalesmanPage } from "@/features/params/pages/ChangeParamsOfSalesmanPage";
import { ProductsSuggestionPage } from "@/features/productsSuggestion/pages/ProductsSuggestionPage";
import { UserProfilePage } from "@/features/profile";
import { ClientNoteCreate } from "@/features/profile/components/ClientNoteCreate";
import { ClientNoteListContainer } from "@/features/profile/components/ClientNoteListContainer";
import { ClientNoteUpdateContainer } from "@/features/profile/components/ClientNoteUpdateContainer";
import { ClientNotes } from "@/features/profile/components/ClientNotes";
import { AttendanceJustificationsPage } from "@/features/profile/pages/AttendanceJustificationsPage";
import { BusinessGoalsPage } from "@/features/profile/pages/BusinessGoalsPage";
import { ClientNotesPage } from "@/features/profile/pages/ClientNotesPage";
import { ParamsOfAccountFormPage } from "@/features/profile/pages/ParamsOfAccountFormPage";
import { PreDefinedMessagesPage } from "@/features/profile/pages/PreDefinedMessagesPage";
import { ProfileParamsPage } from "@/features/profile/pages/ProfileParamsPage";
import { SecurityPage } from "@/features/profile/pages/SecurityPage";
import { UserPermissionsListPage } from "@/features/profile/pages/UserPermissionsListPage";
import { UserProfileInfoPage } from "@/features/profile/pages/UserProfileInfoPage";
import { ReportsPage, SpecificReport } from "@/features/reports";
import { CreateRuptureRulePage } from "@/features/ruptureRules/pages/CreateRuptureRulePage";
import { RuptureRuleListPage } from "@/features/ruptureRules/pages/RuptureRuleListPage";
import { RuptureRulePage } from "@/features/ruptureRules/pages/RuptureRulePage";
import { UsersListPage } from "@/features/users/pages/UsersListPage";
import { ParamsHistoryPage } from "@features/history/pages/ParamsHistoryPage";
import { Navigate, Outlet } from "react-router-dom";

export interface Route {
  path: string;
  component: React.ReactElement;
  acl: {
    [key in Role]: {
      type: DecisionType;
      meta?: string;
    };
  };
  children?: Route[];
}

export enum DecisionType {
  ALLOW = "allow",
  DENY = "deny",
  REDIRECT = "redirect",
}

export const routes: Route[] = [
  {
    path: "/attendance",
    component: <AttendancePage />,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.SALESMAN]: {
        type: DecisionType.ALLOW,
      },
      [Role.MANAGER]: {
        type: DecisionType.ALLOW,
      },
      [Role.SUPERVISOR]: {
        type: DecisionType.ALLOW,
      },
    },
    children: [
      {
        path: "/attendance/:list_slug",
        component: <ClientsListPage />,
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
      },
      {
        path: "/attendance/filter",
        component: <ListWithUrlFilterPage />,
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
      },
    ],
  },
  //////////////////////////////
  {
    path: "/notifications",
    component: <NotificationsPage />,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.SALESMAN]: {
        type: DecisionType.ALLOW,
      },
      [Role.MANAGER]: {
        type: DecisionType.ALLOW,
      },
      [Role.SUPERVISOR]: {
        type: DecisionType.ALLOW,
      },
    },
    children: [
      {
        path: "/notifications/:notification_id",
        component: <NotificationPage />,
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
      },
    ],
  },
  //////////////////////////////
  {
    path: "/info",
    component: <ClientProfilePage />,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.SALESMAN]: {
        type: DecisionType.ALLOW,
      },
      [Role.MANAGER]: {
        type: DecisionType.ALLOW,
      },
      [Role.SUPERVISOR]: {
        type: DecisionType.ALLOW,
      },
    },
    children: [
      {
        path: "client/:client_id",
        component: <Navigate to="client-info" />,
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
      },
      {
        path: "client/:client_id/client-info",
        component: (
          <ClientInfoTabs route="client-info">
            <ClientInfoPage />
          </ClientInfoTabs>
        ),
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
      },
      {
        path: "client/:client_id/history",
        component: (
          <ClientInfoTabs route="history">
            <HistoryPage />
          </ClientInfoTabs>
        ),
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
      },
      {
        path: "client/:client_id/params-history",
        component: (
          <ClientInfoTabs route="params-history">
            <ParamsHistoryPage />
          </ClientInfoTabs>
        ),
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
      },
      {
        path: "client/:client_id/products-suggestion",
        component: (
          <ClientInfoTabs route="products-suggestion">
            <ProductsSuggestionPage />
          </ClientInfoTabs>
        ),
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
      },
      {
        path: "client/:client_id/notes",
        component: (
          <ClientInfoTabs route="notes">
            <ClientNotesPage />
          </ClientInfoTabs>
        ),
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
        children: [
          {
            path: "",
            component: <ClientNotes />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
          {
            path: "create",
            component: <ClientNoteCreate />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
          {
            path: "list/:note_id",
            component: <ClientNoteListContainer />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
          {
            path: "edit/:note_id",
            component: <ClientNoteUpdateContainer />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
        ],
      },
    ],
  },
  ///////////////////////////////
  {
    path: "/reports",
    component: <ReportsPage />,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.SALESMAN]: {
        type: DecisionType.ALLOW,
      },
      [Role.MANAGER]: {
        type: DecisionType.ALLOW,
      },
      [Role.SUPERVISOR]: {
        type: DecisionType.ALLOW,
      },
    },
    children: [
      {
        path: ":dashboard_name",
        component: <SpecificReport />,
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
      },
    ],
  },
  ///////////////////////////////
  {
    path: "/profile",
    component: <UserProfilePage />,
    acl: {
      [Role.ADMIN]: {
        type: DecisionType.ALLOW,
      },
      [Role.SALESMAN]: {
        type: DecisionType.ALLOW,
      },
      [Role.MANAGER]: {
        type: DecisionType.ALLOW,
      },
      [Role.SUPERVISOR]: {
        type: DecisionType.ALLOW,
      },
    },
    children: [
      {
        path: "user-profile",
        component: <Outlet />,
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
        children: [
          {
            path: "info",
            component: <UserProfileInfoPage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
          {
            path: "pre-defined-messages",
            component: <PreDefinedMessagesPage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
          {
            path: "security",
            component: <SecurityPage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
          {
            path: "permissions",
            component: <UserPermissionsListPage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
          {
            path: "attendance-params",
            component: <ProfileParamsPage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
          {
            path: "business-goals",
            component: <BusinessGoalsPage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
          {
            path: "attendance-justifications",
            component: <AttendanceJustificationsPage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
        ],
      },
      {
        path: "integrations",
        component: <BusinessAssociatesPage />,
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.DENY,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
        children: [
          {
            path: "partners",
            component: <BusinessAssociatePartnersContainer />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.DENY,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
            children: [
              {
                path: "list",
                component: <BusinessAssociatesList />,
                acl: {
                  [Role.ADMIN]: {
                    type: DecisionType.ALLOW,
                  },
                  [Role.SALESMAN]: {
                    type: DecisionType.DENY,
                  },
                  [Role.MANAGER]: {
                    type: DecisionType.ALLOW,
                  },
                  [Role.SUPERVISOR]: {
                    type: DecisionType.ALLOW,
                  },
                },
              },
              {
                path: ":business_attendance_id",
                component: <BusinessAssociatePage />,
                acl: {
                  [Role.ADMIN]: {
                    type: DecisionType.ALLOW,
                  },
                  [Role.SALESMAN]: {
                    type: DecisionType.DENY,
                  },
                  [Role.MANAGER]: {
                    type: DecisionType.ALLOW,
                  },
                  [Role.SUPERVISOR]: {
                    type: DecisionType.ALLOW,
                  },
                },
              },
            ],
          },
          {
            path: "services",
            component: <BusinessAssociateServicesContainer />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.DENY,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
            children: [
              {
                path: "list",
                component: <BusinessAssociatesServicesPage />,
                acl: {
                  [Role.ADMIN]: {
                    type: DecisionType.ALLOW,
                  },
                  [Role.SALESMAN]: {
                    type: DecisionType.DENY,
                  },
                  [Role.MANAGER]: {
                    type: DecisionType.ALLOW,
                  },
                  [Role.SUPERVISOR]: {
                    type: DecisionType.ALLOW,
                  },
                },
              },
              {
                path: ":business_associate_id/service/:service_id",
                component: <BusinessAssociateServiceOfPartnerConfigPage />,
                acl: {
                  [Role.ADMIN]: {
                    type: DecisionType.ALLOW,
                  },
                  [Role.SALESMAN]: {
                    type: DecisionType.DENY,
                  },
                  [Role.MANAGER]: {
                    type: DecisionType.ALLOW,
                  },
                  [Role.SUPERVISOR]: {
                    type: DecisionType.ALLOW,
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: "users-managment",
        component: <UsersListPage />,
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.DENY,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
      },
      {
        path: "params",
        component: <ParamsPage />,
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.ALLOW,
          },
          [Role.MANAGER]: {
            type: DecisionType.ALLOW,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.ALLOW,
          },
        },
        children: [
          {
            path: "change-params-account",
            component: <ParamsOfAccountFormPage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.DENY,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.DENY,
              },
            },
          },
          {
            path: "change-params-clients",
            component: <ParamsClientsPage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.ALLOW,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
          {
            path: "change-params-salesman",
            component: <ChangeParamsOfSalesmanPage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.DENY,
              },
              [Role.MANAGER]: {
                type: DecisionType.ALLOW,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.ALLOW,
              },
            },
          },
        ],
      },
      {
        path: "rupture-rule",
        component: <RuptureRuleListPage />,
        acl: {
          [Role.ADMIN]: {
            type: DecisionType.ALLOW,
          },
          [Role.SALESMAN]: {
            type: DecisionType.DENY,
          },
          [Role.MANAGER]: {
            type: DecisionType.DENY,
          },
          [Role.SUPERVISOR]: {
            type: DecisionType.DENY,
          },
        },
        children: [
          {
            path: ":rupture_rule",
            component: <RuptureRulePage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.DENY,
              },
              [Role.MANAGER]: {
                type: DecisionType.DENY,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.DENY,
              },
            },
          },
          {
            path: "create-rupture-rule",
            component: <CreateRuptureRulePage />,
            acl: {
              [Role.ADMIN]: {
                type: DecisionType.ALLOW,
              },
              [Role.SALESMAN]: {
                type: DecisionType.DENY,
              },
              [Role.MANAGER]: {
                type: DecisionType.DENY,
              },
              [Role.SUPERVISOR]: {
                type: DecisionType.DENY,
              },
            },
          },
        ],
      },
    ],
  },
];
