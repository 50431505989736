import styled from "styled-components";

export const TimelineAttendanceItemBox = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray50};
  padding: ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.radii.sm};
  margin-top: 5px;
  word-wrap: break-word;
`;
