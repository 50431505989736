import { BusinessAssociate } from "@/core/models/business-associate.model";
import GoVendasLogo from "@assets/logo.svg";
import { Caption, Flex, Heading, IconButton } from "@gogeo-io/ui-library";
import { BiChevronLeft } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ConfirmDisabledBusinessAssociateDialog } from "./ConfirmDisabledBusinessAssociateDialog";
import { EnableBusinessAssociateDialog } from "./EnableBusinessAssociateDialog";

interface BusinessAssociatedHeaderProps {
  business: BusinessAssociate;
  urlToNavigate: string;
  onDisableBusinessAssociate: () => void;
  onEnableBusinessAssociate: () => void;
}

export function BusinessAssociatedHeader({
  business,
  urlToNavigate,
  onDisableBusinessAssociate,
  onEnableBusinessAssociate,
}: BusinessAssociatedHeaderProps) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(urlToNavigate);
  };

  return (
    <Flex
      css={{
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Flex
        css={{
          flexDirection: "column",
          width: "100%",
          gap: "$2",
        }}
      >
        <Flex css={{ gap: "$2" }}>
          <IconButton size="small" type="soft" onClick={handleNavigate}>
            <BiChevronLeft />
          </IconButton>
          <Heading size="title6" css={{ fontWeight: "bold" }}>
            {business.name}
          </Heading>
        </Flex>

        {business.enabled ? (
          <Flex css={{ gap: "$2", alignItems: "center", color: "$primary500" }}>
            <FaCheckCircle color="inherit" size={18} />
            <Caption css={{ color: "$gray500" }}>Habilitado</Caption>
          </Flex>
        ) : (
          <Flex css={{ gap: "$2", alignItems: "center", color: "$red500" }}>
            <IoCloseCircle color="inherit" size={22} />
            <Caption css={{ color: "$gray500" }}>Desabilitado</Caption>
          </Flex>
        )}

        {business.homologated && (
          <Flex css={{ gap: "$2", alignItems: "center" }}>
            <img
              style={{ width: "22px" }}
              src={GoVendasLogo}
              alt="Logo do GoVendas"
            />
            <Caption css={{ color: "$gray500" }}>
              APLICATIVO CERTIFICADO PELO GOVENDAS
            </Caption>
          </Flex>
        )}
      </Flex>

      {business.enabled ? (
        <ConfirmDisabledBusinessAssociateDialog
          onDisableBusinessAssociate={onDisableBusinessAssociate}
        />
      ) : (
        <EnableBusinessAssociateDialog
          businessAssociate={business}
          onEnableBusinessAssociate={onEnableBusinessAssociate}
        />
      )}
    </Flex>
  );
}
