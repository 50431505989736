import { NoPageSelected } from "@/components/NoPageSelected";
import { Main } from "@/layouts/Main";
import ReportsImage from "@assets/reports.svg";
import { useEffect } from "react";
import { useBreadcrumbsForReportsPage } from "../hooks/useBreadcrumbsForReportsPage";

export function NoReportsSelected() {
  const { showBreadcrumbs } = useBreadcrumbsForReportsPage();

  useEffect(() => {
    showBreadcrumbs();
  }, []);

  return (
    <Main>
      <NoPageSelected
        src={ReportsImage}
        alt="Selecione um relatório"
        title="Selecione um relatório"
      >
        <span>Selecione um relatório e acompanhe o rendimento de seu time</span>
      </NoPageSelected>
    </Main>
  );
}
