import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import {
  AttrOpts,
  ListOfTags,
  ListOfTagsFilter,
  MatchTypes,
} from "@/core/models/listOfTags.model";
import { Tag } from "@/core/models/tag.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";
import { isEmpty } from "lodash";

export const useAttendanceMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const myClientsListClickedEvent = (listOfTags: ListOfTags) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.MY_CLIENTS_SELECTED_LIST,
      {
        ...buildProps(),
        "Lista selecionada": listOfTags.name,
        list: listOfTags,
      }
    );
  };

  const listOfTagsClickedEvent = (listOfTags: ListOfTags) => {
    MixpanelTracking.getInstance().track(TrackingEvents.SELECTED_LIST, {
      ...buildProps(),
      "Lista selecionada": listOfTags.name,
      list: listOfTags,
    });
  };

  const listUnfixedEvent = (listOfTags: ListOfTags) => {
    MixpanelTracking.getInstance().track(TrackingEvents.UNFIXED_LIST, {
      ...buildProps(),
      "Lista desfixada": listOfTags.name,
      list: listOfTags,
    });
  };

  const listFixedEvent = (listOfTags: ListOfTags) => {
    MixpanelTracking.getInstance().track(TrackingEvents.FIXED_LIST, {
      ...buildProps(),
      "Lista fixada": listOfTags.name,
      list: listOfTags,
    });
  };

  const listOfTagsEditEvent = (listOfTags: ListOfTags) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.LIST_OF_TAGS_CLICKED_TO_EDIT,
      {
        ...buildProps(),
        "Lista selecionada para editar": listOfTags.name,
        list: listOfTags,
      }
    );
  };

  const listOfTagsViewedEvent = (listOfTags: ListOfTags) => {
    MixpanelTracking.getInstance().track(TrackingEvents.LIST_OF_TAGS_VIEWED, {
      ...buildProps(),
      "Lista vista": listOfTags.name,
      list: listOfTags,
    });
  };

  const listOfTagsViewedWithSearchFiltersEvent = (
    listOfTags: ListOfTags,
    match_type: MatchTypes,
    match_value: string
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.LIST_OF_TAGS_WITH_SEARCH_VIEWED,
      {
        ...buildProps(),
        "Lista vista": listOfTags.name,
        list: listOfTags,
        "Busca feita por": match_type,
        "Termo de busca": match_value,
      }
    );
  };

  const filtersAppliedEvent = (filters: ListOfTagsFilter) => {
    MixpanelTracking.getInstance().track(TrackingEvents.ADVANCED_FILTERS, {
      ...buildProps(),
      filters,
    });
  };

  const updateListOfTagsEvent = (
    old_list: ListOfTags,
    updated_list: ListOfTags
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.UPDATE_LIST, {
      ...buildProps(),
      "Lista antiga": old_list,
      "Lista atualizada": updated_list,
    });
  };

  const deleteListOfTagsEvent = (deleted_list: ListOfTags) => {
    MixpanelTracking.getInstance().track(TrackingEvents.DELETE_LIST, {
      ...buildProps(),
      "Lista deletada": deleted_list,
    });
  };

  const searchListsEvent = (
    query: string,
    total: number,
    results: ListOfTags
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.SEARCH_LIST, {
      ...buildProps(),
      "Nome para busca": query,
      "Total de listas encontrada": total,
      "Listas encontrada": results,
    });
  };

  const createListOfTagsEvent = (created_list: ListOfTags) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CREATE_LIST, {
      ...buildProps(),
      "Lista criada": created_list,
    });
  };

  const duplicateListEvent = (oldList: ListOfTags, newList: ListOfTags) => {
    MixpanelTracking.getInstance().track(TrackingEvents.DUPLICATE_LIST, {
      ...buildProps(),
      "Nome da lista que foi duplicada": oldList.name,
      "Nome da lista da nova lista": newList.name,
      "Nova lista criada": newList,
    });
  };

  const clientClickedOnListEvent = (list_name: string, client_id: string) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLICKED_ON_LIST, {
      ...buildProps(),
      "Lista Selecionada": list_name,
      "Cliente Selecionado": client_id,
    });
  };

  const tagRemovedOnListEvent = (
    removedTag: Tag,
    filter?: ListOfTagsFilter,
    list?: ListOfTags
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.TAG_REMOVED, {
      ...buildProps(),
      ...(!isEmpty(filter) && { "Filtros atuais": filter }),
      ...(!isEmpty(list) && { "Lista atual": list }),
      "Tag removida": removedTag,
    });
  };

  const tagAddedOnListEvent = (
    addedTag: Tag,
    filter?: ListOfTagsFilter,
    list?: ListOfTags
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.TAG_ADDED, {
      ...buildProps(),
      ...(!isEmpty(filter) && { "Filtros atuais": filter }),
      ...(!isEmpty(list) && { "Lista atual": list }),
      "Tag adicionada": addedTag,
    });
  };

  const attributeRemovedEvent = (attribute: AttrOpts, list?: ListOfTags) => {
    MixpanelTracking.getInstance().track(TrackingEvents.ATTRIBUTE_REMOVED, {
      ...buildProps(),
      ...(!isEmpty(list) && { "Lista atual": list }),
      "Coluna removida ": attribute,
    });
  };

  const attributeAddedEvent = (attribute: AttrOpts, list?: ListOfTags) => {
    MixpanelTracking.getInstance().track(TrackingEvents.ATTRIBUTE_ADDED, {
      ...buildProps(),
      ...(!isEmpty(list) && { "Lista atual": list }),
      "Coluna removida": attribute,
    });
  };

  return {
    listOfTagsClickedEvent,
    listOfTagsEditEvent,
    filtersAppliedEvent,
    updateListOfTagsEvent,
    createListOfTagsEvent,
    myClientsListClickedEvent,
    listOfTagsViewedEvent,
    duplicateListEvent,
    clientClickedOnListEvent,
    listUnfixedEvent,
    listFixedEvent,
    deleteListOfTagsEvent,
    searchListsEvent,
    listOfTagsViewedWithSearchFiltersEvent,
    tagRemovedOnListEvent,
    tagAddedOnListEvent,
    attributeAddedEvent,
    attributeRemovedEvent,
  };
};
