import { ProductsFilter } from "@/core/models/productsSugestion.model";
import { Checkbox, Text } from "@gogeo-io/ui-library";
import { useState } from "react";
import styled from "styled-components";

interface ProductsFilterIsTrendingProps {
  filters: ProductsFilter;
  onFiltersChange: (filters: ProductsFilter) => void;
}

export function ProductsFilterIsTrending({
  filters,
  onFiltersChange,
}: ProductsFilterIsTrendingProps) {
  const [isTrending, setIsTrending] = useState(
    filters.is_trending ? filters.is_trending : false
  );

  const handleSetIsTrending = async (value: boolean) => {
    setIsTrending(value);
    onFiltersChange({ ...filters, is_trending: value });
  };

  return (
    <Container>
      <Checkbox
        id="isTrending"
        checked={isTrending}
        onCheckedChange={handleSetIsTrending}
      />
      <Text size="body1" css={{ color: "$gray800" }}>
        Em alta
      </Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space[2]};
`;
