import { SalesmanToRuptureRule } from "@/core/models/user.model";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
} from "@gogeo-io/ui-library";
import { ReactNode, useState } from "react";

interface EditUserFromRuptureRuleModalProps {
  children: ReactNode;
  user: SalesmanToRuptureRule;
  onEditUser: (user: SalesmanToRuptureRule) => void;
}

export function EditUserFromRuptureRuleModal({
  children,
  user,
  onEditUser,
}: EditUserFromRuptureRuleModalProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const closeDialog = () => setDialogIsOpen(false);

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <Flex
          css={{
            flexDirection: "column",
            gap: "$4",
            width: "500px",
            textAlign: "center",
          }}
        >
          <Heading size="title6">Editar vendedor</Heading>

          <Flex
            css={{
              alignItems: "flex-end",
              justifyContent: "flex-end",
              gap: "$3",
              mt: "$3",
            }}
          >
            <Button color="danger" variant="soft" onClick={closeDialog}>
              Cancelar
            </Button>
            <Button onClick={() => onEditUser(user)}>Editar vendedor</Button>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
