import { ListDivider } from "@/components/ListDivider";
import { FileMetadata } from "@/core/models/file.model";
import isHotkey from "is-hotkey";
import { useCallback, useMemo } from "react";
import {
  AiOutlineAlignCenter,
  AiOutlineAlignLeft,
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineUnderline,
} from "react-icons/ai";
import { BsCode, BsJustify } from "react-icons/bs";
import { MdOutlineFormatListNumbered } from "react-icons/md";
import {
  PiListBulletsFill,
  PiNumberSquareOneFill,
  PiNumberSquareTwoFill,
} from "react-icons/pi";
import { Descendant, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { TEXT_EDITOR_HOTKEYS } from "../config/textEditorConfig";
import { useTextEditorActions } from "../hooks/useTextEditorActions";
import { IFrameTextEditor } from "./IFrameTextEditor";
import { TextEditBlockButton } from "./TextEditBlockButton";
import { TextEditorElement } from "./TextEditorElement";
import { TextEditorFiles } from "./TextEditorFiles";
import { TextEditorFilesMetadata } from "./TextEditorFilesMetadata";
import { TextEditorLeaf } from "./TextEditorLeaf";
import { TextEditorMarkButton } from "./TextEditorMarkButton";
import { TextEditToolbar } from "./TextEditorToolbar";

interface TextEditorToEditNoteProps {
  files: FileMetadata[];
  filesToSend: File[];
  content: Descendant[];
  onContentChange: (newContent: Descendant[]) => void;
  onFilesToSendChange: (files: File[]) => void;
  onRemoveFile: (fileNameToRemove: string) => void;
  onRemoveFileToSend: (fileNameToRemove: string) => void;
}

export function TextEditorToEditNote({
  files,
  filesToSend,
  content,
  onContentChange,
  onFilesToSendChange,
  onRemoveFile,
  onRemoveFileToSend,
}: TextEditorToEditNoteProps) {
  const renderElement = useCallback(
    (props) => <TextEditorElement {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <TextEditorLeaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const handleBlur = useCallback(() => ReactEditor.deselect(editor), [editor]);

  const { toggleMark } = useTextEditorActions();

  const emptyContent = useMemo(
    () => [
      {
        type: "paragraph",
        children: [
          {
            text: "",
          },
        ],
      },
    ],
    []
  );
  const contentToEditor = useMemo(
    () => (content.length > 0 ? content : emptyContent),
    [content]
  );

  return (
    <Slate
      editor={editor}
      initialValue={contentToEditor}
      onChange={(e) => onContentChange(e)}
    >
      <TextEditToolbar>
        <TextEditorMarkButton format="bold" icon={<AiOutlineBold />} />
        <TextEditorMarkButton format="italic" icon={<AiOutlineItalic />} />
        <TextEditorMarkButton
          format="underline"
          icon={<AiOutlineUnderline />}
        />
        <TextEditorMarkButton format="code" icon={<BsCode />} />
        <TextEditBlockButton
          format="heading-one"
          icon={<PiNumberSquareOneFill />}
        />
        <TextEditBlockButton
          format="heading-two"
          icon={<PiNumberSquareTwoFill />}
        />
        <TextEditBlockButton
          format="numbered-list"
          icon={<MdOutlineFormatListNumbered />}
        />
        <TextEditBlockButton
          format="bulleted-list"
          icon={<PiListBulletsFill />}
        />
        <TextEditBlockButton format="left" icon={<AiOutlineAlignLeft />} />
        <TextEditBlockButton format="center" icon={<AiOutlineAlignCenter />} />
        <TextEditBlockButton format="right" icon={<AiOutlineAlignLeft />} />
        <TextEditBlockButton format="justify" icon={<BsJustify />} />
      </TextEditToolbar>

      {files.length > 0 && (
        <TextEditorFilesMetadata
          title="Arquivos da Nota: "
          files={files}
          onRemoveFile={onRemoveFile}
        />
      )}

      <TextEditorFiles
        title="Adicionar Mais Anexos: "
        files={filesToSend}
        onFilesChange={onFilesToSendChange}
        onRemoveFile={onRemoveFileToSend}
      />

      <ListDivider />

      <IFrameTextEditor onBlur={handleBlur}>
        <Editable
          // disableDefaultStyles
          style={{
            border: "none",
            outline: "none",
            overflowX: "hidden",
            width: "100%",
            height: "100%",
          }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Insira aqui seu texto"
          renderPlaceholder={({ children, attributes }) => (
            <div {...attributes}>
              <p>{children}</p>
            </div>
          )}
          onKeyDown={(event) => {
            for (const hotkey in TEXT_EDITOR_HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault();
                const mark = TEXT_EDITOR_HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </IFrameTextEditor>
    </Slate>
  );
}
