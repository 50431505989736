import ClientStatus from "@/components/ClientStatus";
import { Flex, Text } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";

interface TimelineHeaderInfoStatusProps {
  feedback_id: number;
  feedback: string;
}

export function TimelineHeaderInfoStatus({
  feedback_id,
  feedback,
}: TimelineHeaderInfoStatusProps) {
  return (
    <Flex css={{ flexDirection: "row", alignItems: "center", gap: "0.5rem" }}>
      <ClientStatus feedback_id={feedback_id} />
      <Text css={{ textTransform: "capitalize" }}>
        {!isEmpty(feedback) ? feedback : "Em Aberto"}
      </Text>
    </Flex>
  );
}
