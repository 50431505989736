import { ListDivider } from "@/components/ListDivider";
import { TrackingEvents } from "@/config/mixpanel";
import { emitEventToChangeClients } from "@/features/attendance/store/emitEventToChangeClientsSlice";
import { useMixpanel } from "@/hooks/useMixpanel";
import { ClientsTableSkeletonPage } from "@components/Skeleton/ClientsTableSkeletonPage";
import { User } from "@core/models/user.model";
import {
  getSalesmansRelatedToUser,
  selectUser,
} from "@core/store/users/usersSlice";
import {
  selectSelectedSalesmanToChangeParams,
  setSelectedSalesmanToChangeParams,
} from "@features/params/store/paramsSlice";
import { Flex } from "@gogeo-io/ui-library";
import useAppDispatch from "@hooks/useAppDispatch";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HeaderPageWithSalesmanToSelect } from "../components/HeaderPageWithSalesmanToSelect";
import { ParamsOfSalesmanForm } from "../components/ParamsOfSalesmanForm";
import { useBreadcrumbsForParamsPage } from "../hooks/useBreadcrumbsForParamsPage";

export function ChangeParamsOfSalesmanPage() {
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);
  const selectedSalesmanToChangeParams = useSelector(
    selectSelectedSalesmanToChangeParams
  );

  const dispatch = useAppDispatch();
  const { breadcrumbsForSalesmanParams } = useBreadcrumbsForParamsPage();
  const { pageViewed } = useMixpanel();

  const loadSalesmansRelatedToUser = async () => {
    setIsLoading(true);
    await dispatch(emitEventToChangeClients(false));

    if (user.role === "SALESMAN") {
      await dispatch(setSelectedSalesmanToChangeParams(user));
      setIsLoading(false);
      await dispatch(emitEventToChangeClients(true));
    } else {
      await dispatch(getSalesmansRelatedToUser({ user_id: user.id })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            const relatedUsers: User[] = res.payload;
            await dispatch(setSelectedSalesmanToChangeParams(relatedUsers[0]));
            setIsLoading(false);
            await dispatch(emitEventToChangeClients(true));
          }
        }
      );
    }
  };

  useEffect(() => {
    loadSalesmansRelatedToUser();
    breadcrumbsForSalesmanParams();
    pageViewed(TrackingEvents.PROFILE_SALESMAN_CHANGE_PARAMS_PAGE);
  }, []);

  return (
    <Flex css={{ flexDirection: "column", gap: "$3" }}>
      <HeaderPageWithSalesmanToSelect
        title="Parâmetros de atendimento do vendedor"
        description="Selecione um vendedor, e altere suas configurações."
      />

      <ListDivider />

      <>
        {!isLoading && !isEmpty(selectedSalesmanToChangeParams) ? (
          <ParamsOfSalesmanForm />
        ) : (
          <ClientsTableSkeletonPage />
        )}
      </>
    </Flex>
  );
}
