import { LinkToAnotherTab } from "@/components/LinkToAnotherTab";
import { selectUser } from "@/core/store/users/usersSlice";
import { Flex, Text } from "@gogeo-io/ui-library";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MenuItem } from "./ProfileSubMenuList";

interface ProfileSubMenuItemProps {
  url: string;
  baseUrl: string;
  text: string;
  menuItems?: MenuItem[];
  additionalHeaderComponent?: ReactElement;
  additionalActions?: any;
  isRouteActive: (baseUrl: string) => boolean;
}

export function ProfileSubMenuItem({
  url,
  baseUrl,
  text,
  menuItems,
  additionalHeaderComponent = null,
  additionalActions,
  isRouteActive,
}: ProfileSubMenuItemProps) {
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  const handleNavigate = (url: string) => {
    if (additionalActions) {
      additionalActions();
    }
    navigate(`/profile${url}`);
  };

  return (
    <>
      <ProfileSubMenuItemContainer
        onClick={!menuItems ? () => handleNavigate(url!) : undefined}
        $hasMenuItems={menuItems && menuItems.length > 0}
        $isSelected={
          menuItems && menuItems.length > 0 ? false : isRouteActive(baseUrl)
        }
      >
        <Flex
          css={{
            alignItems: "center",
            justifyContent: "space-between",
            gap: "$1",
            width: "100%",
          }}
        >
          <Text css={{ color: "inherit" }}>{text}</Text>
          {additionalHeaderComponent}
        </Flex>
      </ProfileSubMenuItemContainer>
      {menuItems && (
        <ul>
          {menuItems.map((child, index) => {
            if (
              child.rolesThatCannotSeeItem &&
              child.rolesThatCannotSeeItem.includes(user.role)
            ) {
              return null;
            }

            return (
              <ListItem key={index}>
                <LinkToAnotherTab url={`/profile${child.url}`}>
                  <ProfileSubMenuItem
                    url={child.url}
                    baseUrl={child.baseUrl}
                    text={child.text}
                    isRouteActive={isRouteActive}
                    additionalActions={child.additionalActions}
                    additionalHeaderComponent={child.additionalHeaderComponent}
                  />
                </LinkToAnotherTab>
              </ListItem>
            );
          })}
        </ul>
      )}
    </>
  );
}

interface ProfileSubMenuItemContainerProps {
  $isSelected?: boolean;
  $hasMenuItems?: boolean;
}

const ProfileSubMenuItemContainer = styled.div<ProfileSubMenuItemContainerProps>`
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.$isSelected ? props.theme.colors.gray100 : props.theme.colors.white};
  color: ${(props) =>
    props.$isSelected
      ? props.theme.colors.gray900
      : props.theme.colors.gray700};
  transition: 0.5s ease-in all;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.gray700};
  padding: ${(props) => props.theme.space[3]};
  cursor: ${(props) => (props.$hasMenuItems ? "default" : "pointer")};

  &:hover {
    background: ${(props) => props.theme.colors.gray100};
    color: ${(props) => props.theme.colors.gray900};
  }
`;

const ListItem = styled.li`
  padding-left: ${(props) => props.theme.space[5]};
`;
