import { PreDefinedMessageSubject } from "@/core/models/preDefinedMessage.model";
import { Flex, Text } from "@gogeo-io/ui-library";
import { useTranslateSubject } from "../../hooks/useTranslateSubject";

interface StatusProps {
  value: PreDefinedMessageSubject;
}

export function SubjectMessageCell({ value }: StatusProps) {
  const translateSubject = useTranslateSubject();

  return (
    <Flex css={{ paddingRight: "0.5rem" }}>
      {value ? (
        <Text size="body2">{translateSubject(value)}</Text>
      ) : (
        <Text size="body2">Não Possui</Text>
      )}
    </Flex>
  );
}
