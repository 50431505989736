import { BatchCreatedUser, User } from "@/core/models/user.model";
import { createUsers } from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useUsersMixpanel } from "../hooks/useUsersMixpanel";
import { UsersToCreateContent } from "./UsersToCreateContent";
import { UsersToCreateSkeleton } from "./skeleton/UsersToCreateSkeleton";

interface UsersToCreateValidationDialogProps {
  data: User[];
  initialDataIsValid: boolean;
  dataIsValid: boolean;
  onCloseUsersCreateModal: () => void;
}

export const UsersToCreateDialog = ({
  data,
  dataIsValid,
  initialDataIsValid,
  onCloseUsersCreateModal,
}: UsersToCreateValidationDialogProps) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [users, setUsers] = useState<BatchCreatedUser[]>([]);

  const dispatch = useAppDispatch();
  const { createUsersEvent } = useUsersMixpanel();

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const handleCloseDialog = () => {
    onCloseUsersCreateModal();
    setDialogIsOpen(false);
  };

  const onCreateUsers = async () => {
    setIsLoading(true);

    await dispatch(createUsers({ users: data })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const usersResponde: BatchCreatedUser[] = res.payload;

        setUsers(usersResponde);
        createUsersEvent(data);
      } else {
        handleSetDialogOpen(false);
        onCloseUsersCreateModal();
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (initialDataIsValid) {
      handleSetDialogOpen(true);
      onCreateUsers();
    }
  }, [initialDataIsValid]);

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild>
        <Flex
          css={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
            gap: "$2",
          }}
        >
          <Button bordered onClick={handleCloseDialog}>
            Cancelar
          </Button>
          <Button onClick={onCreateUsers} disabled={!dataIsValid}>
            Criar Usuários
          </Button>
        </Flex>
      </DialogTrigger>
      <DialogContent>
        <Flex css={{ flexDirection: "column", gap: "$4" }}>
          <DialogTitle>Informações de Usuários</DialogTitle>
          {isLoading ? (
            <UsersToCreateSkeleton />
          ) : (
            <UsersToCreateContent
              users={users}
              onCloseDialog={handleCloseDialog}
            />
          )}
        </Flex>
      </DialogContent>
    </Dialog>
  );
};
