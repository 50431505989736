import { Flex, Text } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface ReminderPickHourAmPm {
  selectedOption: "am" | "pm";
  onSelectedOptionChange: (option: "am" | "pm") => void;
}

export function ReminderPickHourAmPm({
  selectedOption,
  onSelectedOptionChange,
}: ReminderPickHourAmPm) {
  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <Item
        className={selectedOption === "am" ? "selected" : ""}
        onClick={() => onSelectedOptionChange("am")}
      >
        <Text>AM</Text>
      </Item>

      <Item
        className={selectedOption === "pm" ? "selected" : ""}
        onClick={() => onSelectedOptionChange("pm")}
      >
        <Text>PM</Text>
      </Item>
    </Flex>
  );
}

const Item = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  cursor: pointer;

  &.selected {
    background: ${(props) => props.theme.colors.gray100};
    border: 1px solid ${(props) => props.theme.colors.gray400};
  }
`;
