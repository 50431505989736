import { User } from "@/core/models/user.model";
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  RightSlot,
} from "@gogeo-io/ui-library";
import { FaUsers } from "react-icons/fa";
import { FiMoreHorizontal } from "react-icons/fi";
import { MdClose, MdDone, MdEdit, MdLockOpen } from "react-icons/md";
import { ActivateUserModal } from "../ActivateUserModal";
import { DeactivateUserModal } from "../DeactivateUserModal";
import { UpdateUserModal } from "../UpdateUserModal";
import { UserPermissionsModal } from "../UserPermissionsModal";
import { UsersRelatedToUserModal } from "../UsersRelatedToUserModal";

interface UserActionsCellProps {
  user: User;
}

export function UserActionsCell({ user }: UserActionsCellProps) {
  return (
    <Box>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton color="gray" type="ghost" size="small">
            <FiMoreHorizontal />
          </IconButton>
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5}>
          <UpdateUserModal user={user}>
            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
              Editar
              <RightSlot>
                <MdEdit />
              </RightSlot>
            </DropdownMenuItem>
          </UpdateUserModal>

          <UsersRelatedToUserModal user={user}>
            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
              Usuários Associados
              <RightSlot>
                <FaUsers />
              </RightSlot>
            </DropdownMenuItem>
          </UsersRelatedToUserModal>

          <UserPermissionsModal user={user}>
            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
              Permissões
              <RightSlot>
                <MdLockOpen />
              </RightSlot>
            </DropdownMenuItem>
          </UserPermissionsModal>

          {user.active ? (
            <DeactivateUserModal user={user}>
              <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                Desativar
                <RightSlot>
                  <MdClose />
                </RightSlot>
              </DropdownMenuItem>
            </DeactivateUserModal>
          ) : (
            <ActivateUserModal user={user}>
              <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                Ativar
                <RightSlot>
                  <MdDone />
                </RightSlot>
              </DropdownMenuItem>
            </ActivateUserModal>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </Box>
  );
}
