import { ClientHistoryNavbar } from "./ClientHistoryNavbar";
import { ClientInfoTabs } from "./ClientInfoTabs";
import { ClientInfoWrapper } from "./ClientInfoWrapper";
import { ClientProfile } from "./ClientProfile";

export default {
  ClientProfile,
  ClientInfoWrapper,
  ClientInfoTabs,
  ClientHistoryNavbar,
};
