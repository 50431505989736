import styled from "styled-components";

export const ReminderItemTitle = styled.p<{
  isRead: boolean;
  haveClientId: boolean;
}>`
  font-size: 16px;
  opacity: ${({ isRead }) => (isRead ? 0.6 : 1)};
  cursor: ${({ haveClientId }) => (haveClientId ? "pointer" : "auto")};
`;
