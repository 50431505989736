import OneSignal from "react-onesignal";
import { User } from "./core/models/user.model";

interface RunOneSignalProps {
  user: User;
}

export default async function runOneSignal({ user }: RunOneSignalProps) {
  await OneSignal.init({
    appId: import.meta.env.VITE_ONE_SIGNAL,
    allowLocalhostAsSecureOrigin: import.meta.env.DEV,
  });
  OneSignal.Slidedown.promptPush();
  OneSignal.login(user.account_id + "-" + user.id).then(() => {
    const userTags = {
      user_id: String(user.id),
      account_id: String(user.account_id),
    };
    OneSignal.User.addTags(userTags);
  });
}
