import { Client } from "@/core/models/client.model";
import { Recommendation } from "@/core/models/productsSugestion.model";
import { User } from "@/core/models/user.model";
import { useClientFields } from "./useClientFields";
import { useGeneralFields } from "./useGeneralFields";
import { useProductSuggestionFields } from "./useProductSuggestionFields";

export const useTranslatePreDefinedMessage = () => {
  const productsSuggestionFields = (
    client: Client,
    user: User,
    recommendation: Recommendation,
    text: string
  ) => {
    const clientsFields = useClientFields(client);
    const generalFields = useGeneralFields(user);
    const productsSuggestionFields = useProductSuggestionFields(recommendation);

    const fieldsToTranslate = [
      ...clientsFields,
      ...generalFields,
      ...productsSuggestionFields,
    ];

    const regex = /(\{{2})([^}|{]*)(\}{2})/gm;

    let textToShow = text;
    let textToSend = text;

    if (text !== undefined) {
      const x = text.match(regex)?.forEach((result) => {
        const token = result.substring(2, result.length - 2);
        const value = fieldsToTranslate.find(
          (field) => field.user_field === token
        )?.translated_field;
        if (value) {
          textToShow = textToShow.replace(
            result,
            `<span class='token'> ${value} </span>`
          );
          textToSend = textToSend.replace(result, `${value}`);
        } else {
          textToShow = textToShow.replace(
            result,
            `<span class='token'> "Campo Não Encontrado" </span>`
          );
          textToSend = textToSend.replace(result, `"Campo Não Encontrado"`);
        }
      });
    }

    return {
      textToSend,
      textToShow,
    };
  };

  const clientFields = (client: Client, user: User, text: string) => {
    const clientsFields = useClientFields(client);
    const generalFields = useGeneralFields(user);

    const fieldsToTranslate = [...clientsFields, ...generalFields];

    const regex = /(\{{2})([^}|{]*)(\}{2})/gm;

    let textToShow = text;
    let textToSend = text;

    if (text !== undefined) {
      const x = text.match(regex)?.forEach((result) => {
        const token = result.substring(2, result.length - 2);
        const value = fieldsToTranslate.find(
          (field) => field.user_field === token
        )?.translated_field;
        if (value) {
          textToShow = textToShow.replace(
            result,
            `<span class='token'> ${value} </span>`
          );
          textToSend = textToSend.replace(result, `${value}`);
        } else {
          textToShow = textToShow.replace(
            result,
            `<span class='token'> "Campo Não Encontrado" </span>`
          );
          textToSend = textToSend.replace(result, `"Campo Não Encontrado"`);
        }
      });
    }

    return {
      textToSend,
      textToShow,
    };
  };

  const generalFields = (user: User, text: string) => {
    const generalFields = useGeneralFields(user);

    const regex = /(\{{2})([^}|{]*)(\}{2})/gm;

    let textToShow = text;
    let textToSend = text;

    if (text !== undefined) {
      const x = text.match(regex)?.forEach((result) => {
        const token = result.substring(2, result.length - 2);
        const value = generalFields.find(
          (field) => field.user_field === token
        )?.translated_field;
        if (value) {
          textToShow = textToShow.replace(
            result,
            `<span class='token'> ${value} </span>`
          );
          textToSend = textToSend.replace(result, `${value} `);
        } else {
          textToShow = textToShow.replace(
            result,
            `<span class='token'> "Campo Não Encontrado" </span>`
          );
          textToSend = textToSend.replace(result, `"Campo Não Encontrado"`);
        }
      });
    }

    return {
      textToSend,
      textToShow,
    };
  };

  return {
    productsSuggestionFields,
    clientFields,
    generalFields,
  };
};
