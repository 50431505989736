import { UserPermission } from "@/core/models/user-permission.model";
import { User } from "@/core/models/user.model";
import { removeAllUserToBatchActions } from "@/core/store/users/usersSlice";
import {
  getUserPermissions,
  selectUserPermissionsStatus,
  updateUserPermissions,
} from "@/features/usersManagment/store/userPermissionsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@gogeo-io/ui-library";
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { useUsersMixpanel } from "../hooks/useUsersMixpanel";
import { UserPermissionsModalContent } from "./UserPermissionsModalContent";
import { UserPermissionsSkeleton } from "./skeleton/UserPermissionsSkeleton";

interface UserPermissionsModalProps {
  user: User;
  children: ReactNode;
}

export function UserPermissionsModal({
  user,
  children,
}: UserPermissionsModalProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const userPermissionsStatus = useSelector(selectUserPermissionsStatus);

  const dispatch = useAppDispatch();
  const { changeUserPermissionsEvent } = useUsersMixpanel();

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const closeDialog = () => setDialogIsOpen(false);

  const changePermissions = async (updated_permissions: UserPermission[]) => {
    await dispatch(
      updateUserPermissions({
        user_id: user.id,
        updated_permissions: updated_permissions,
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        changeUserPermissionsEvent(user, updated_permissions);
        await dispatch(removeAllUserToBatchActions());
      }
    });
  };

  const loadUserPermissions = async () => {
    await dispatch(getUserPermissions({ user_id: user.id }));
  };

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild onClick={loadUserPermissions}>
        {children}
      </DialogTrigger>
      <DialogContent style={{ width: "500px", maxHeight: "90vh" }}>
        <DialogTitle>Permissões dos usuário: {user.name}</DialogTitle>
        {userPermissionsStatus === "loading" ? (
          <UserPermissionsSkeleton />
        ) : (
          <UserPermissionsModalContent
            onChangePermissions={changePermissions}
            onCloseDialog={closeDialog}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
