import { UserHandler } from "@/core/models/user.model";
import {
  changeUserProfileInfo,
  selectUser,
  updateLoggedUser,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, Flex, Input, Text } from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";
import { useProfileMixpanel } from "../hooks/useProfileMixpanel";

const schema = yup.object().shape({
  name: yup.string().required("O nome do usuário é obrigatório."),
  email: yup.string().required("O email do usuário é obrigatório."),
  phone: yup.string().required("O telefone do usuário é obrigatório."),
});

interface UpdtateUserInfoProfileProps {
  onCloseDialog: () => void;
}

export function UpdtateUserInfoProfile({
  onCloseDialog,
}: UpdtateUserInfoProfileProps) {
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);

  const { editMyInfoEvent } = useProfileMixpanel();
  const dispatch = useAppDispatch();
  const { handleSubmit, control, clearErrors, setValue } = useForm<UserHandler>(
    {
      mode: "onBlur",
      resolver: yupResolver(schema),
      defaultValues: {
        name: user.name,
        email: user.email,
        phone: user.phone,
      },
    }
  );

  const onSubmit: SubmitHandler<UserHandler> = async (
    data: UserHandler
  ): Promise<void> => {
    setIsLoading(true);
    await dispatch(updateLoggedUser({ updated_user: data })).then(
      async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          editMyInfoEvent(user.id, data);
          toast.success(`Usuário ${data.name} alterado com sucesso`);
          await dispatch(changeUserProfileInfo(res.payload));
          setIsLoading(false);
          onCloseDialog();
        }
      }
    );
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível editar usuário");
    }
  };

  const cancelChanges = () => {
    clearErrors();
    onCloseDialog();
  };

  useEffect(() => {
    if (user !== undefined) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("phone", user.phone);
    }
  }, []);

  return (
    <UserModalContainer>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <Text>Digite o nome do usuário:</Text>
            <Input
              placeholder="Ex. André Felipe"
              type="text"
              isFullWidth
              hasError={!!error}
              errorMessage={error?.message}
              {...field}
            />
          </Flex>
        )}
      />

      <Controller
        control={control}
        name="email"
        render={({ field, fieldState: { error } }) => (
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <Text>Digite o email do usuário:</Text>
            <Input
              placeholder="Ex. meuemail@gmail.com"
              type="text"
              isFullWidth
              hasError={!!error}
              errorMessage={error?.message}
              {...field}
            />
          </Flex>
        )}
      />

      <Controller
        control={control}
        name="phone"
        render={({ field, fieldState: { error } }) => (
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <Text>Digite o telefone do usuário:</Text>
            <Input
              placeholder="Ex. (62) 99999-0000"
              type="text"
              isFullWidth
              hasError={!!error}
              errorMessage={error?.message}
              {...field}
            />
          </Flex>
        )}
      />

      <Flex
        css={{ alignItems: "flex-end", justifyContent: "flex-end", gap: "$3" }}
      >
        <Button color="danger" variant="soft" onClick={cancelChanges}>
          Cancelar
        </Button>
        <Button
          isLoading={isLoading}
          onClick={() => handleSubmit(onSubmit, onError)()}
        >
          Editar informações
        </Button>
      </Flex>
    </UserModalContainer>
  );
}

const UserModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: ${({ theme }) => theme.space[4]};
  border-radius: ${({ theme }) => theme.space[2]};
`;
