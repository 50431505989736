export const useByPlaceholder = () => {
  const buildPlaceholder = (option: string) => {
    if (option === "general") {
      return "Digite aqui o termo que está procurando...";
    } else if (option === "fantasy_name") {
      return "Digite o nome fantasia do cliente";
    } else if (option === "id") {
      return "Digite o código do cliente";
    } else if (option === "name") {
      return "Digite a razão social do cliente";
    } else {
      return "Digite aqui o termo que está procurando...";
    }
  };

  return { buildPlaceholder };
};
