import { Flex, Grid, Heading, Text } from "@gogeo-io/ui-library";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useBreadcrumbsForBusinessAssociatesPage } from "../hooks/useBreadcrumbsForBusinessAssociatesPage";
import { selectBusinessAssociates } from "../store/businessAssociatesSlice";
import { BusinessAssociatesListItem } from "./BusinessAssociatesListItem";

export function BusinessAssociatesList() {
  const businessAssociates = useSelector(selectBusinessAssociates);

  const { showBreadcrumbs } = useBreadcrumbsForBusinessAssociatesPage();

  useEffect(() => {
    showBreadcrumbs();
  }, []);

  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <Heading size="title6">Todas as Integrações do GoVendas</Heading>

      <Text>{businessAssociates.length} resultados</Text>

      <Grid
        css={{
          gridTemplateColumns: "repeat(auto-fill, 300px)",
          gap: "32px",
          mt: "$4",
        }}
      >
        {businessAssociates.map((business) => {
          return (
            <BusinessAssociatesListItem key={business.id} business={business} />
          );
        })}
      </Grid>
    </Flex>
  );
}
