import ServerDownImg from "@assets/server-down-red.svg";
import { gvTheme } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface ErrorContentProps {
  children: string;
  size?: "small" | "medium" | "large";
}

export function ErrorContent({ size = "large", children }: ErrorContentProps) {
  return (
    <Wrapper>
      <Image src={ServerDownImg} alt="Servidor caiu" $size={size} />
      <Content $size={size}>
        <h3>{children}</h3>
      </Content>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const handleWidthImage = (size: "small" | "medium" | "large") => {
  switch (size) {
    case "small":
      return "200px";
    case "medium":
      return "300px";
    case "large":
      return "400px";
    default:
      return "400px";
  }
};

const handleHeightImage = (size: "small" | "medium" | "large") => {
  switch (size) {
    case "small":
      return "300px";
    case "medium":
      return "400px";
    case "large":
      return "500px";
    default:
      return "500px";
  }
};

const handleFontSize = (size: "small" | "medium" | "large") => {
  switch (size) {
    case "small":
      return "1.5rem";
    case "medium":
      return "2.0rem";
    case "large":
      return "2.5rem";
    default:
      return "2.5rem";
  }
};

export const Image = styled.img<{ $size?: "small" | "medium" | "large" }>`
  width: ${({ $size }) => handleWidthImage($size)};
  height: ${({ $size }) => handleHeightImage($size)};

  @media (max-width: ${gvTheme.mediaSizes.laptop}) {
    width: 300px;
    height: 400px;
  }

  @media (max-width: ${gvTheme.mediaSizes.smallLaptop}) {
    width: 250px;
    height: 350px;
  }

  @media (max-width: ${gvTheme.mediaSizes.tablet}) {
    width: 200px;
    height: 300px;
  }
`;

export const Content = styled.div<{ $size?: "small" | "medium" | "large" }>`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[2]};

  @media (max-width: ${gvTheme.mediaSizes.laptop}) {
    font-size: 0.9rem;
  }

  @media (max-width: ${gvTheme.mediaSizes.smallLaptop}) {
    font-size: 0.9rem;
  }

  @media (max-width: ${gvTheme.mediaSizes.tablet}) {
    font-size: 0.8rem;
  }

  h3 {
    max-width: 800px;
    font-size: ${({ $size }) => handleFontSize($size)};

    @media (max-width: ${gvTheme.mediaSizes.smallLaptop}) {
      font-size: 1.5rem;
    }

    @media (max-width: ${gvTheme.mediaSizes.tablet}) {
      font-size: 1rem;
    }
  }
`;
