import { Flex } from "@gogeo-io/ui-library";
import { ReactNode } from "react";

interface ReportFilterContainerProps {
  children: ReactNode;
}

export function ReportFilterContainer({
  children,
}: ReportFilterContainerProps) {
  return (
    <Flex
      css={{
        padding: "$4",
        background: "$gray50",
        borderRadius: "$2",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
      }}
    >
      {children}
    </Flex>
  );
}
