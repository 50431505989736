import { EmptyContent } from "@/components/EmptyContent";
import { ErrorContent } from "@/components/ErrorContent";
import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import { AttendanceJustification } from "@/core/models/attendance-justification.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AttendanceJustificationsTable } from "../components/AttendanceJustificationsTable";
import { CreateAttendanceJustification } from "../components/CreateAttendanceJustification";
import { useAttendanceJustificationsColumns } from "../hooks/useAttendanceJustificationsColumns";
import { useAttendanceJustificationsMixpanel } from "../hooks/useAttendanceJustificationsMixpanel";
import {
  getAvailableActionsForAttendanceJustification,
  selectAttendanceJustificationsActionsStatus,
} from "../store/attendanceJustificationsActionsSlice";
import {
  createAttendanceJustification,
  deleteAttendanceJustification,
  getAttendanceJustifications,
  selectAttendanceJustificationsHasError500,
  setTotalOfAttendanceJustifications,
  updateAttendanceJustification,
} from "../store/attendanceJustificationsSlice";
import {
  getBusinessGoals,
  selectBusinessGoalsStatus,
} from "../store/businessGoalsSlice";

export type CreateAttendanceJustificationProps = Omit<
  AttendanceJustification,
  "id"
>;

export function AttendanceJustificationsPage() {
  const [attendanceJustifications, setAttendanceJustifications] = useState<
    AttendanceJustification[]
  >([]);
  const [attendanceJustificationsStatus, setAttendanceJustificationsStatus] =
    useState("loading");
  const [currentPage, setCurrentPage] = useState(0);
  const [businessGoalSelected, setBusinessGoalSelected] = useState("");
  const [categorySelected, setCategorySelected] = useState("");
  const [actionSelected, setActionSelected] = useState("");

  const dispatch = useAppDispatch();

  const businessGoalsStatus = useSelector(selectBusinessGoalsStatus);
  const attendanceJustificationsActionsStatus = useSelector(
    selectAttendanceJustificationsActionsStatus
  );

  const {
    createAttendanceJustificationEvent,
    editAttendanceJustificationEvent,
    deletedAttendanceJustificationEvent,
    getAttendanceJustificationsEvent,
  } = useAttendanceJustificationsMixpanel();

  useEffect(() => {
    async function loadAttendanceJustifications() {
      await dispatch(
        getAttendanceJustifications({
          page: 0,
          size: import.meta.env.VITE_PAGE_SIZE,
        })
      ).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setAttendanceJustifications((prevJustifications) => [
            ...prevJustifications,
            ...res.payload.attendance_justifications,
          ]);
          getAttendanceJustificationsEvent();
          setAttendanceJustificationsStatus("fulfilled");
          await dispatch(setTotalOfAttendanceJustifications(res.payload.total));
        }
      });
    }

    loadAttendanceJustifications();
  }, []);

  const getAttendanceJustificationsFromPagination = async (
    currentPage: number
  ) => {
    await dispatch(
      getAttendanceJustifications({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (!isEmpty(res.payload.attendance_justifications)) {
          setAttendanceJustifications((prevJustifications) => [
            ...prevJustifications,
            ...res.payload.attendance_justifications,
          ]);
        }
      }
    });
  };

  const handleDeleteAttendanceJustifications = async (id: number) => {
    setAttendanceJustificationsStatus("loading");

    await dispatch(deleteAttendanceJustification({ id })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const justificationsWithoutTheRemovedOne =
          attendanceJustifications.filter((msg) => msg.id !== res.payload.id);
        deletedAttendanceJustificationEvent(id, res.payload);
        setAttendanceJustifications(justificationsWithoutTheRemovedOne);
        setAttendanceJustificationsStatus("fulfilled");
      }
    });
  };

  const handleEditAttendanceJustifications = async (
    data: AttendanceJustification
  ) => {
    setAttendanceJustificationsStatus("loading");

    await dispatch(updateAttendanceJustification(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setAttendanceJustifications((prevJustifications) =>
          prevJustifications.map((jus) =>
            jus.id === res.payload.id ? res.payload : jus
          )
        );
        editAttendanceJustificationEvent(data.id, res.payload);
        setAttendanceJustificationsStatus("fulfilled");
      }
    });
  };

  const handleCreateAttendanceJustification = async (
    data: CreateAttendanceJustificationProps
  ) => {
    setAttendanceJustificationsStatus("loading");

    await dispatch(createAttendanceJustification(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setAttendanceJustifications((prevJustifications) => [
          ...prevJustifications,
          res.payload,
        ]);
        createAttendanceJustificationEvent(res.payload);
        setAttendanceJustificationsStatus("fulfilled");
      }
    });
  };

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSetActionSelected = (action: string) => setActionSelected(action);

  const handleSetCategorySelected = (action: string) =>
    setCategorySelected(action);

  const handleSetBusinessGoal = (action: string) =>
    setBusinessGoalSelected(action);

  const attendanceJustificationsColumns = useAttendanceJustificationsColumns(
    handleDeleteAttendanceJustifications,
    handleEditAttendanceJustifications
  );

  useEffect(() => {
    const loadBusinessGoals = async () => {
      await dispatch(getBusinessGoals());
    };

    loadBusinessGoals();
  }, []);

  useEffect(() => {
    const loadAvailableActions = async () => {
      await dispatch(
        getAvailableActionsForAttendanceJustification({
          category: categorySelected,
        })
      );
    };

    loadAvailableActions();
  }, [categorySelected]);

  return (
    <>
      {businessGoalsStatus === "success" &&
        attendanceJustificationsActionsStatus === "success" && (
          <CreateAttendanceJustification
            actionSelected={actionSelected}
            businessGoalSelected={businessGoalSelected}
            categorySelected={categorySelected}
            onActionSelected={handleSetActionSelected}
            onCategorySelected={handleSetCategorySelected}
            onBusinessGoalSelected={handleSetBusinessGoal}
            onCreateAttendanceJustification={
              handleCreateAttendanceJustification
            }
          />
        )}

      {selectAttendanceJustificationsHasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos buscar suas justificativas de atendimento, tente
          novamente mais tarde ou atualize a página
        </ErrorContent>
      ) : (
        <>
          {attendanceJustificationsStatus === "fulfilled" ? (
            <>
              {!isEmpty(attendanceJustifications) ? (
                <AttendanceJustificationsTable
                  columns={attendanceJustificationsColumns}
                  data={attendanceJustifications}
                  onPageChange={getAttendanceJustificationsFromPagination}
                  currentPage={currentPage}
                  onIncreasePageNumber={handleIncreasePageNumber}
                />
              ) : (
                <EmptyContent size="medium">
                  Você não tem justificativas de atendimento
                </EmptyContent>
              )}
            </>
          ) : (
            <ClientsTableSkeletonPage />
          )}
        </>
      )}
    </>
  );
}
