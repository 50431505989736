import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { RootState } from "../store";

interface UploadFileState {
  status: "loading" | "success" | "failed";
}

const initialState = {
  status: "loading",
} as UploadFileState;

interface UploadFileToRegisterAttendanceProps {
  feedback_id: string;
  data: FormData;
}

export const uploadFileToRegisterAttendance = createAsyncThunk(
  "uploadFileSlice/uploadFileToRegisterAttendance",
  async (
    { feedback_id, data }: UploadFileToRegisterAttendanceProps,
    thunkAPI
  ) => {
    try {
      const response = await axios({
        method: "post",
        url: `feedback/file/?feedback_id=${feedback_id}`,
        data: data,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      });
      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 202
      ) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface UploadFileToClientNoteProps {
  note_id: string;
  data: FormData;
}

export const uploadFileToClientNote = createAsyncThunk(
  "uploadFileSlice/uploadFileToClientNote",
  async ({ note_id, data }: UploadFileToClientNoteProps, thunkAPI) => {
    try {
      const response = await axios({
        method: "post",
        url: `client/note/file/?note_id=${note_id}`,
        data: data,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      });
      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 202
      ) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface GetFileProps {
  fileId: number;
}

export const getFile = createAsyncThunk(
  "uploadFileSlice/getFile",
  async ({ fileId }: GetFileProps, thunkAPI) => {
    try {
      const response = await axios({
        method: "get",
        url: `/file/${fileId}`,
      });
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const uploadFileSlice = createSlice({
  name: "uploadFileSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(uploadFileToClientNote.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(uploadFileToClientNote.fulfilled, (state, action) => {
      state.status = "success";
    });
    builder.addCase(
      uploadFileToClientNote.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error uploadind file after the client note register"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(uploadFileToRegisterAttendance.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(
      uploadFileToRegisterAttendance.fulfilled,
      (state, action) => {
        state.status = "success";
      }
    );
    builder.addCase(
      uploadFileToRegisterAttendance.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error uploadind file after the register attendance"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

////////////////////////////////////////////////////

export const selectUploadFileStatus = (state: RootState) =>
  state.uploadFileState.status;

///////////////////////////////////////////////////

export const uploadFileReducer = uploadFileSlice.reducer;
