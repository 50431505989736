import { Caption, Checkbox, Flex } from "@gogeo-io/ui-library";
import { useState } from "react";

interface DayWithRuptureFormFieldProps {
  value: number;
  text: string;
}

export function DayWithRuptureFormFieldDisabledToChange({
  value,
  text,
}: DayWithRuptureFormFieldProps) {
  const [isChecked, _] = useState(
    value !== undefined && value === 1 ? true : false
  );

  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Checkbox checked={isChecked} css={{ cursor: "not-allowed" }} />
      <Caption css={{ color: "$gray600" }}>{text}</Caption>
    </Flex>
  );
}
