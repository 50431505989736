import { ClientProfile } from "@/components/CustomCells/ClientProfile";
import { CurrencyCell } from "@/components/CustomCells/CurrencyCell";
import { DateCell } from "@/components/CustomCells/DateCell";
import { SortableHeader } from "@/components/CustomCells/SortableHeader";
import StatusCell from "@/components/CustomCells/StatusCell";
import { TextCell } from "@/components/CustomCells/TextCell";
import { LinkToAnotherTab } from "@/components/LinkToAnotherTab";
import { Client } from "@/core/models/client.model";
import { AttrOpts } from "@/core/models/listOfTags.model";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { selectAttrOptsToFilter } from "@/features/clientInfo/store/clientAttrOptsSlice";
import { selectColumnsToSort } from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { ReactElement, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectClientsCount } from "../store/clientsSlice";

export const useClientsColumns = (
  onSort: (direction: "ASC" | "DESC" | "", property: string) => void
) => {
  const selectedList = useSelector(selectSelectedList);
  const clientsCount = useSelector(selectClientsCount);
  const selectedAttributesToFilter = useSelector(selectAttrOptsToFilter);
  const selectedColumnsToSort = useSelector(selectColumnsToSort);

  const getSortOrder = (column: AttrOpts) => {
    const findedColumn = selectedList.filters?.sort?.orders?.find(
      (order) => order.property === column.id
    );

    return findedColumn !== undefined ? findedColumn.direction : "";
  };

  const clientProfileColumn = useMemo(() => {
    return [
      {
        title: (
          <SortableHeader
            headerName={`Clientes (${clientsCount ?? 0})`}
            property="name"
            onSort={onSort}
            initialSortOrder={getSortOrder({
              attr: "client",
              name: "Razão Social",
              id: "name",
              format: "text",
            } as AttrOpts)}
          />
        ),
        dataIndex: "client",
        key: "client",
        render: (record) => (
          <LinkToAnotherTab url={`/info/client/${record.id}/client-info`}>
            <ClientProfile client={record} />
          </LinkToAnotherTab>
        ),
        width: "30%",
      },
    ];
  }, [selectedList, selectedColumnsToSort, clientsCount]);

  const selectedColumns = useMemo(() => {
    return selectedAttributesToFilter.map((column) => {
      return {
        title: (
          <SortableHeader
            headerName={column.name}
            property={column.id}
            onSort={onSort}
            initialSortOrder={getSortOrder(column)}
          />
        ),
        dataIndex: "client",
        key: `client.${column.id}`,
        render: (record: Client) => {
          const accessor = column.id;
          const value = accessor.split(".").reduce((a, b) => a[b], record);

          let children: ReactElement = null;

          if (column.format === "status") {
            children = <StatusCell status={value as any} />;
          } else if (column.format === "date") {
            children = <DateCell value={value as any} />;
          } else if (column.format === "currency") {
            children = <CurrencyCell value={value as any} />;
          } else {
            children = <TextCell value={value as any} />;
          }

          return (
            <LinkToAnotherTab url={`/info/client/${record.id}/client-info`}>
              {children}
            </LinkToAnotherTab>
          );
        },
      };
    });
  }, [selectedList, selectedAttributesToFilter, selectedColumnsToSort]);

  return [...clientProfileColumn, ...selectedColumns];
};
