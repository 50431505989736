import { EmptyContent } from "@/components/EmptyContent";
import { IndicatorsItem } from "@/features/indicators/components/IndicatorsItem";
import { IndicatorsSkeletonPage } from "@/features/indicators/pages/IndicatorsSkeletonPage";
import {
  getListOfIndicators,
  selectListOfIndicators,
  selectSelectedIndicators,
} from "@/features/indicators/store/listIndicatosSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectStatusOfEmitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";

export function IndicatorsViewOfClients() {
  const availableIndicators = useSelector(selectListOfIndicators);
  const selectedIndicators = useSelector(selectSelectedIndicators);
  const statusOfEmitEventToChangeClients = useSelector(
    selectStatusOfEmitEventToChangeClients
  );

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoading(true);

    const loadIndicators = async () => {
      await dispatch(getListOfIndicators()).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setIsLoading(false);
        }
      });
    };

    if (statusOfEmitEventToChangeClients) {
      loadIndicators();
    }
  }, [statusOfEmitEventToChangeClients]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        width: "100%",
        height: "100%",
      }}
    >
      {!isLoading ? (
        <>
          {availableIndicators.length === 0 ? (
            <EmptyContent>Não temos indicadores disponíveis</EmptyContent>
          ) : (
            <IndicatorsItem
              selectedIndicatorsID={selectedIndicators.indicators_name}
            />
          )}
        </>
      ) : (
        <IndicatorsSkeletonPage />
      )}
    </Flex>
  );
}
