import { Caption, Flex, Input } from "@gogeo-io/ui-library";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

interface PriceRangeProps {
  selectedMinValue: number;
  selectedMaxValue: number;
  onSelectMinValue: (value: string) => void;
  onSelectMaxValue: (value: string) => void;
}

export function PriceRange({
  selectedMinValue,
  selectedMaxValue,
  onSelectMinValue,
  onSelectMaxValue,
}: PriceRangeProps) {
  return (
    <Flex
      css={{
        width: "100%",
        padding: 0,
        alignItems: "center",
        justifyContent: "center",
        gap: "$3",
      }}
    >
      <Flex
        css={{ flexDirection: "column", paddingLeft: "2px", width: "100%" }}
      >
        <Caption bold css={{ color: "$gray600", mb: "$1" }}>
          <label htmlFor="min">Mínimo</label>
        </Caption>
        <Input
          type="number"
          name="min"
          min="0"
          value={selectedMinValue}
          onChange={(e) => onSelectMinValue(e.target.value)}
          suffix={<AttachMoneyIcon />}
          style={{ width: "100%" }}
        />
      </Flex>

      <Flex
        css={{
          width: "50px",
          height: "2px",
          background: "$gray200",
          mt: "$4",
        }}
      ></Flex>

      <Flex
        css={{ flexDirection: "column", paddingRight: "3px", width: "100%" }}
      >
        <Caption bold css={{ color: "$gray600", mb: "$1" }}>
          <label htmlFor="max">Máximo</label>
        </Caption>
        <Input
          type="number"
          min="0"
          name="max"
          value={selectedMaxValue}
          onChange={(e) => onSelectMaxValue(e.target.value)}
          suffix={<AttachMoneyIcon />}
          style={{ width: "100%" }}
        />
      </Flex>
    </Flex>
  );
}
