import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { PaginatedRequestBaseProps } from "@/core/models/paginatedRequest.model";
import {
  ProductListSugestionPaginated,
  ProductsFilter,
  ProductsListRecommendation,
} from "@/core/models/productsSugestion.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ProductsSugestion {
  paginatedProducts: ProductListSugestionPaginated;
  totalProducts: number;
  status: "loading" | "success" | "failed";
}

const initialState = {
  paginatedProducts: {},
  status: "loading",
} as ProductsSugestion;

interface GetProductsSugestionProps extends PaginatedRequestBaseProps {
  client_id: string;
  visit_date: string;
  filters: ProductsFilter;
}

export const getProductsSugestion = createAsyncThunk(
  "productsSugestion/getProductsSugestion",
  async (
    { client_id, visit_date, filters, page, size }: GetProductsSugestionProps,
    thunkAPI
  ) => {
    const body = filters !== undefined ? filters : {};

    try {
      const response = await axios.post(
        `/products/lists/${visit_date}/${client_id}?page=${page}&size=${size}`,
        body
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export interface FilterProductsSugestionProps {
  client_id: string;
  visit_date: string;
  filters: ProductsFilter;
}

export const productsSugestionSlice = createSlice({
  name: "productsSugestionSlice",
  initialState,
  reducers: {
    addProductsOnState(
      state,
      action: PayloadAction<ProductsListRecommendation[]>
    ) {
      state.paginatedProducts.lists = action.payload;
    },

    setTotalOfProducts(state, action: PayloadAction<number>) {
      state.totalProducts = action.payload;
    },
    setProductsSuggestionToFulfilled(state) {
      state.status = "success";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getProductsSugestion.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the products suggestion");
          scope.setExtras(action.payload);
        });
        state.status = "failed";
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectProductsSugestion = (state: RootState) =>
  state.productsSugestionState.paginatedProducts.lists;
export const selectProductsStatus = (state: RootState) =>
  state.productsSugestionState.status;
export const selectProductsTotal = (state: RootState) =>
  state.productsSugestionState.totalProducts;

export const {
  addProductsOnState,
  setTotalOfProducts,
  setProductsSuggestionToFulfilled,
} = productsSugestionSlice.actions;

export const productsSugestionReducer = productsSugestionSlice.reducer;
