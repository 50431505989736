import {
  changeParams,
  selectParamsStatus,
} from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectAllClientsSelectedToChangeParams,
  selectClientSelectedToChangeParams,
} from "../../store/clientSelectedToChangeParamsSlice";

export function ChangeParamsReasonToChange() {
  const paramsStatus = useSelector(selectParamsStatus);
  const selectedClientsToChangeParams = useSelector(
    selectClientSelectedToChangeParams
  );
  const allClientsSelectedToChangeParams = useSelector(
    selectAllClientsSelectedToChangeParams
  );

  const ref = useRef(null);
  const dispatch = useAppDispatch();

  const handleInputChange = async (value: string) => {
    await dispatch(changeParams({ observations: value }));
  };

  useEffect(() => {
    const paramsChanged = async () => {
      if (paramsStatus === "success") {
        ref.current.value = "";
        await dispatch(changeParams({ observations: "indeterminate" }));
      }
    };

    paramsChanged();
  }, [paramsStatus]);

  useEffect(() => {
    const clientsChanged = async () => {
      ref.current.value = "";
      await dispatch(changeParams({ observations: "indeterminate" }));
    };

    clientsChanged();
  }, [selectedClientsToChangeParams]);

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  const textareaIsDisabled: boolean =
    selectedClientsToChangeParams.length <= 0 &&
    !allClientsSelectedToChangeParams;

  return (
    <Container>
      <textarea
        ref={ref}
        placeholder="Motivo da alteração"
        onChange={(e) => debounceOnChangeInput(e.target.value)}
        disabled={textareaIsDisabled}
      ></textarea>
    </Container>
  );
}

export const Container = styled.div`
  textarea {
    width: 100%;
    height: 100px;
    padding: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.gray200};
    resize: none;
  }
`;
