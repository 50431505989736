import {
  ReminderDataControl,
  ReminderRequest,
} from "@/core/models/reminder.model";
import { ReminderPickDate } from "@/features/reminder/components/ReminderPickDate";
import { ReminderPickHour } from "@/features/reminder/components/ReminderPickHour";
import { useReminderMixpanel } from "@/features/reminder/hooks/useReminderMixpanel";
import {
  addReminderOnState,
  createReminder,
} from "@/features/reminder/store/reminderSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatDate } from "@/hooks/useFormatDate";
import { Button, Input, Text } from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import G_UI from "@ui/index";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import UI from "../../ui";

interface RegisterAttendanceReminderFormProps {
  client_name: string;
  client_id: string;
  handleCanSeeReminderForm: (value: boolean) => void;
}

const schema = yup.object().shape({
  text: yup.string().required("O nome do lembrete é obrigatório."),
  day: yup.string().required("A data do lembrete é obrigatória."),
  hour: yup.string().required("A hora do lembrete é obrigatória."),
});

export default function RegisterAttendanceReminderForm({
  client_name,
  client_id,
  handleCanSeeReminderForm,
}: RegisterAttendanceReminderFormProps) {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { createdReminderEvent, createdReminderFailEvent } =
    useReminderMixpanel();
  const { fullDateWithTimezone, formatBasicDate } = useFormatDate();
  const { handleSubmit, control, reset, setValue } =
    useForm<ReminderDataControl>({
      mode: "onBlur",
      resolver: yupResolver(schema),
      defaultValues: {
        text: `Contactar ${client_name}`,
        day: formatBasicDate(new Date()),
      },
    });

  const onSubmit: SubmitHandler<ReminderDataControl> = async (
    data: ReminderDataControl
  ): Promise<void> => {
    setIsLoading(true);
    const reminderToCreate: ReminderRequest = {
      text: data.text,
      date: fullDateWithTimezone(`${data.day}T${data.hour}`),
      client_id,
    };
    await dispatch(createReminder(reminderToCreate)).then(async (obj) => {
      if (obj.meta.requestStatus) {
        await dispatch(addReminderOnState(obj.payload));
        toast.success("Lembrete criado com sucesso");
        createdReminderEvent(reminderToCreate);
        handleCanSeeReminderForm(false);
      }
    });
    setIsLoading(false);
  };

  const handleCancelForm = () => {
    reset();
    handleCanSeeReminderForm(false);
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível criar lembrete");
      createdReminderFailEvent(errors);
    }
  };

  return (
    <UI.RegisterAttendanceReminderForm noValidate>
      <Controller
        control={control}
        name="text"
        render={({ field, fieldState: { error } }) => (
          <div>
            <Text css={{ marginBottom: "$2" }}>Digite o nome do lembrete:</Text>
            <Input
              placeholder="Nome do lembrete"
              type="text"
              isFullWidth
              maxLength={40}
              hasError={!!error}
              errorMessage={error?.message}
              {...field}
            />
          </div>
        )}
      />

      <Controller
        control={control}
        name="day"
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <ReminderPickDate
                onChange={onChange}
                value={value}
                hasError={!!error}
                errorMessage={error?.message}
              />
            </>
          );
        }}
      />

      <Controller
        control={control}
        name="hour"
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <ReminderPickHour onChange={onChange} />
            </>
          );
        }}
      />

      <G_UI.ButtonGroup>
        <Button color="danger" variant="soft" onClick={handleCancelForm}>
          Cancelar
        </Button>
        <Button
          isLoading={isLoading}
          onClick={() => handleSubmit(onSubmit, onError)()}
        >
          Criar Lembrete
        </Button>
      </G_UI.ButtonGroup>
    </UI.RegisterAttendanceReminderForm>
  );
}
