import { ListOfTags, ListOfTagsFilter } from "@/core/models/listOfTags.model";
import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAttendanceMixpanel } from "../../hooks/useAttendanceMixpanel";
import { selectOriginalUrlFilters } from "../../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClientsFilters } from "../../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../../store/emitEventToChangeClientsSlice";
import { addListOnState, createListOfTags } from "../../store/listOfTagsSlice";
import { selectList } from "../../store/listSelectedSlice";
import { SaveListAsDialog } from "./SaveListAsDialog";

interface AdvancedFiltersActionsProps {
  onCleanFilters: () => Promise<void>;
}

export function AdvancedFiltersActionsWithUrlFilters({
  onCleanFilters,
}: AdvancedFiltersActionsProps) {
  const [createListDialogIsOpen, setCreateListDialogIsOpen] = useState(false);

  const originalUrlFilters = useSelector(selectOriginalUrlFilters);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { createListOfTagsEvent } = useAttendanceMixpanel();
  const { paramsOnEditListView } = useGetClientsByFiltersParams();

  const handleCreateListDialogIsOpen = (value: boolean) =>
    setCreateListDialogIsOpen(value);

  const handleCreateList = async (list_name: string, description: string) => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClientsFilters(false));

    let filters: ListOfTagsFilter = {};

    filters = paramsOnEditListView();
    delete filters.match;

    if (list_name === "")
      return toast.error("Dê um nome a lista antes de salvar");

    const args = {
      name: list_name,
      description,
      filters,
    };

    await dispatch(createListOfTags(args)).then(async (value) => {
      if (value.payload.status === 409) {
        return;
      }

      if (value.meta.requestStatus === "fulfilled") {
        const createdList: ListOfTags = value.payload;

        toast.success("Segmentação criada com sucesso");

        createListOfTagsEvent(createdList);
        await dispatch(addListOnState(createdList));

        await dispatch(selectList(createdList));

        await dispatch(emitEventToChangeClients(true));

        navigate(`/attendance/${createdList.slug}`);
      }
    });
  };

  const handleCancel = async () => {
    onCleanFilters();
    await dispatch(emitEventToChangeClientsFilters(true));
  };

  const errorsAreNotUndefined = () => {
    return (
      originalUrlFilters.errors.advanced_filters &&
      originalUrlFilters.errors.tags
    );
  };

  return (
    <>
      <Flex css={{ gap: "$4" }}>
        <Button fullWidth bordered onClick={handleCancel}>
          Cancelar
        </Button>

        <DropdownMenu>
          {errorsAreNotUndefined() &&
          originalUrlFilters.errors.advanced_filters.length +
            originalUrlFilters.errors.tags.length >
            0 ? (
            <Tooltip>
              <TooltipTrigger style={{ background: "transparent" }}>
                <Button fullWidth>Salvar</Button>
              </TooltipTrigger>
              <TooltipContent
                aria-label="Corriga o(s) filtro(s) ou o(s) remova para visualizar a lista de
                clientes"
              >
                Corriga o(s) filtro(s) ou o(s) remova para visualizar a lista de
                clientes
              </TooltipContent>
            </Tooltip>
          ) : (
            <DropdownMenuTrigger asChild>
              <Button fullWidth>Salvar</Button>
            </DropdownMenuTrigger>
          )}

          <DropdownMenuContent sideOffset={5}>
            <DropdownMenuItem onClick={() => setCreateListDialogIsOpen(true)}>
              Criar
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Flex>

      <Dialog
        open={createListDialogIsOpen}
        onOpenChange={handleCreateListDialogIsOpen}
      >
        <DialogTrigger />
        <DialogContent style={{ width: "450px" }}>
          <DialogTitle>Salvar Nova Segmentação</DialogTitle>

          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
            >
              <Close />
            </IconButton>
          </DialogClose>

          <SaveListAsDialog onSaveAsList={handleCreateList} />
        </DialogContent>
      </Dialog>
    </>
  );
}
