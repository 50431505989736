import { CellHeaderWithoutSort } from "@/components/CustomCells/CellHeaderWithoutSort";
import { BatchCreatedUser } from "@/core/models/user.model";
import { useMemo } from "react";
import { EditUserToCreateCell } from "../components/cells/EditUserToCreateCell";
import { ShowErrorMessageCell } from "../components/cells/ShowErrorMessageCell";
import { UserAlreadyExists } from "../components/cells/UserAlreadyExists";
import { UserCreatedCell } from "../components/cells/UserCreatedCell";
import { UserEmailCell } from "../components/cells/UserEmailCell";

export const useUsersToCreateColumns = () => {
  const userEmailColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Email" />,
        dataIndex: "user.email",
        key: "email",
        render: (_, record: BatchCreatedUser) => (
          <UserEmailCell email={record.user.email} />
        ),
      },
    ];
  }, []);

  const userIsCreatedColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Usuário criado?" />,
        dataIndex: "created",
        key: "created",
        render: (_, record: BatchCreatedUser) => (
          <UserCreatedCell created={record.created} />
        ),
      },
    ];
  }, []);

  const userAlreadyExistsColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Usuário já existe?" />,
        dataIndex: "already_exists",
        key: "already_exists",
        render: (_, record: BatchCreatedUser) => (
          <UserAlreadyExists already_exists={record.already_exists} />
        ),
      },
    ];
  }, []);

  const showErrorMessageColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Qual foi o erro?" />,
        dataIndex: "already_exists",
        key: "already_exists",
        render: (_, item: BatchCreatedUser) => (
          <ShowErrorMessageCell error={item.error} message={item.message} />
        ),
      },
    ];
  }, []);

  const userActionsColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Ações" />,
        dataIndex: "",
        key: "actions",
        render: (text: string, record: BatchCreatedUser) => (
          <EditUserToCreateCell user={record.user} onCloseDialog={() => {}} />
        ),
      },
    ];
  }, []);

  return [
    ...userEmailColumn,
    ...userIsCreatedColumn,
    ...userAlreadyExistsColumn,
    ...showErrorMessageColumn,
  ];
};
