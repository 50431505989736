import {
  BusinessAssociate,
  DynamicFormFields,
} from "@/core/models/business-associate.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, DialogClose, Flex, Heading } from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useBusinessAssociateMixpanel } from "../../hooks/useBusinessAssociateMixpanel";
import useCreateSchemaFormBusinessAsociateForm from "../../hooks/useCreateSchemaFormBusinessAsociateForm";
import { associateBusinessAssociateAndCompany } from "../../store/businessAssociatesSlice";
import { BusinessAssociateFieldsContainer } from "./BusinessAssociateFieldsContainer";
import { BussinessAssociateGenericFormDateType } from "./BussinessAssociateGenericFormDateType";
import { BussinessAssociateGenericFormInputType } from "./BussinessAssociateGenericFormInputType";
import { BussinessAssociateGenericFormLabel } from "./BussinessAssociateGenericFormLabel";
import { BussinessAssociateGenericFormMultiSelectType } from "./BussinessAssociateGenericFormMultiSelectType";
import { BussinessAssociateGenericFormPasswordType } from "./BussinessAssociateGenericFormPasswordType";
import { BussinessAssociateGenericFormSelectType } from "./BussinessAssociateGenericFormSelectType";

interface BussinessAssociateGenericFormStep2Props {
  businessAssociate: BusinessAssociate;
  govendas_fields: DynamicFormFields[];
  onEnableBusinessAssociate: () => void;
  onCloseDialog: () => void;
}

export function BussinessAssociateGenericFormStep2({
  businessAssociate,
  govendas_fields,
  onEnableBusinessAssociate,
  onCloseDialog,
}: BussinessAssociateGenericFormStep2Props) {
  const { associate_fields } = businessAssociate;
  const hasAssociateFields =
    businessAssociate.associate_fields &&
    businessAssociate.associate_fields.length > 0;

  const dispatch = useAppDispatch();
  const { businessAssociateConfigChangeEvent } = useBusinessAssociateMixpanel();

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues: { [key: string]: any } = {};

  associate_fields.forEach((field) => {
    switch (field.type) {
      case "string":
      case "password":
      case "date":
        defaultValues[field.property] = "";
        break;
      case "number":
        defaultValues[field.property] = 0;
        break;
      case "select":
        defaultValues[field.property] = "";
      case "multiselect":
        defaultValues[field.property] = [""];
        break;
      default:
        break;
    }
  });

  const { handleSubmit, control, setValue } = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(
      useCreateSchemaFormBusinessAsociateForm(associate_fields)
    ),
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<object> = async (
    data: object
  ): Promise<void> => {
    setIsLoading(true);

    const updatedAssociateFields = associate_fields.map((item) => {
      const prop = item.property;
      if (data[prop] !== undefined) {
        return {
          ...item,
          value: data[prop],
        };
      }
      return item;
    });

    const newData = {
      enabled: true,
      govendas_fields,
      associate_fields: updatedAssociateFields,
    };

    onCloseDialog();

    await dispatch(
      associateBusinessAssociateAndCompany({
        partner_id: businessAssociate.id,
        body: newData,
      })
    );
    businessAssociateConfigChangeEvent(businessAssociate, newData);

    setIsLoading(false);
    onEnableBusinessAssociate();
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível ativar o recurso");
    }
  };

  const handleReset = () => {
    associate_fields.forEach((field: DynamicFormFields) => {
      setValue(field.property, field.value);
    });
  };

  useEffect(() => {
    associate_fields.map((formField: DynamicFormFields) =>
      setValue(formField.property, formField.value)
    );
  }, [associate_fields]);

  useEffect(() => {
    const checkIfHasFieldsToFill = async () => {
      if (!hasAssociateFields) {
        const newData = {
          enabled: true,
          govendas_fields,
          associate_fields: businessAssociate.associate_fields,
        };

        await dispatch(
          associateBusinessAssociateAndCompany({
            partner_id: businessAssociate.id,
            body: newData,
          })
        );

        businessAssociateConfigChangeEvent(businessAssociate, newData);

        onEnableBusinessAssociate();
        onCloseDialog();
      }
    };

    checkIfHasFieldsToFill();
  }, []);

  return (
    <BussinessAssociateForm noValidate>
      <Heading>Campos para preencher - {businessAssociate.name}</Heading>

      <BusinessAssociateFieldsContainer>
        {associate_fields.length > 0 &&
          associate_fields.map((formField: DynamicFormFields) => {
            return (
              <Controller
                key={formField.id}
                control={control}
                name={formField.property}
                render={({ field, fieldState: { error } }) => {
                  if (formField.type === "string") {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <BussinessAssociateGenericFormLabel
                          formField={formField}
                        />
                        <BussinessAssociateGenericFormInputType
                          formField={formField}
                          error={error}
                          field={field}
                          type="text"
                        />
                      </Flex>
                    );
                  }
                  if (formField.type === "number") {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <BussinessAssociateGenericFormLabel
                          formField={formField}
                        />
                        <BussinessAssociateGenericFormInputType
                          formField={formField}
                          error={error}
                          field={field}
                          type="number"
                        />
                      </Flex>
                    );
                  }
                  if (formField.type === "password") {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <BussinessAssociateGenericFormLabel
                          formField={formField}
                        />
                        <BussinessAssociateGenericFormPasswordType
                          formField={formField}
                          error={error}
                          field={field}
                        />
                      </Flex>
                    );
                  }
                  if (formField.type === "select") {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <BussinessAssociateGenericFormLabel
                          formField={formField}
                        />
                        <BussinessAssociateGenericFormSelectType
                          formField={formField}
                          error={error}
                          onChange={field.onChange}
                        />
                      </Flex>
                    );
                  }
                  if (formField.type === "multiselect") {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <BussinessAssociateGenericFormLabel
                          formField={formField}
                        />
                        <BussinessAssociateGenericFormMultiSelectType
                          formField={formField}
                          error={error}
                          onChange={field.onChange}
                        />
                      </Flex>
                    );
                  }
                  if (formField.type === "date") {
                    return (
                      <Flex css={{ flexDirection: "column", gap: "$1" }}>
                        <BussinessAssociateGenericFormLabel
                          formField={formField}
                        />
                        <BussinessAssociateGenericFormDateType
                          formField={formField}
                          error={error}
                          onChange={field.onChange}
                        />
                      </Flex>
                    );
                  }
                }}
              />
            );
          })}
      </BusinessAssociateFieldsContainer>

      <Flex
        css={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          gap: "$2",
          mt: "$4",
        }}
      >
        <DialogClose>
          <Button
            bordered
            onClick={handleReset}
            style={{ background: "white" }}
          >
            Cancelar
          </Button>
        </DialogClose>
        <Button
          isLoading={isLoading}
          onClick={() => handleSubmit(onSubmit, onError)()}
        >
          Ativar
        </Button>
      </Flex>
    </BussinessAssociateForm>
  );
}

const BussinessAssociateForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
  width: 100%;
`;
