import { EmptyContent } from "@/components/EmptyContent";
import { TrackingEvents } from "@/config/mixpanel";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { useBreadcrumbsForClientsListPage } from "@/features/clients/hooks/useBreadcrumbsForClientsListPage";
import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useMixpanel } from "@/hooks/useMixpanel";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IndicatorsSkeletonPage } from "../pages/IndicatorsSkeletonPage";
import { getIndicators } from "../store/indicatorsSlice";
import { IndicatorsFrame } from "./IndicatorsFrame";

interface IndicatorsItemProps {
  selectedIndicatorsID: string;
}

export function IndicatorsItem({ selectedIndicatorsID }: IndicatorsItemProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [errorOnGetTheIndicators, setErrorOnGetTheIndicators] = useState(false);

  const selectedList = useSelector(selectSelectedList);

  const indicators_name = selectedIndicatorsID;
  const dispatch = useAppDispatch();
  const { indicatorsPageViewed } = useMixpanel();
  const { paramsOnEditIndicatorsView } = useGetClientsByFiltersParams();
  const { showBreadcrumbs } = useBreadcrumbsForClientsListPage();

  useEffect(() => {
    setIsLoading(true);

    async function loadIndicators() {
      const params = paramsOnEditIndicatorsView();

      await dispatch(
        getIndicators({
          indicators_name,
          data: params,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setIsLoading(false);
          showBreadcrumbs(selectedList);
        } else {
          setErrorOnGetTheIndicators(true);
        }
      });
    }
    if (indicators_name != "Selecione um dos indicadores") {
      loadIndicators();
      indicatorsPageViewed(TrackingEvents.REPORT_PAGE, indicators_name);
    }
  }, [indicators_name, selectedList]);

  if (
    errorOnGetTheIndicators ||
    indicators_name === "Selecione um dos indicadores"
  ) {
    return (
      <EmptyContent>Não foi possível encontrar os indicadores</EmptyContent>
    );
  }

  return <>{!isLoading ? <IndicatorsFrame /> : <IndicatorsSkeletonPage />}</>;
}
