import styled from "styled-components";

export const ReminderList = styled.div`
  width: 100%;
  max-height: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[4]};
`;
