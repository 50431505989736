import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { UsersToValidateContent } from "./UsersToValidateContent";

interface UsersToCreateValidationDialogProps {
  data: any;
  onCloseUsersCreateModal: () => void;
}

export const UsersToCreateValidationDialog = ({
  data,
  onCloseUsersCreateModal,
}: UsersToCreateValidationDialogProps) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const handleCloseDialog = () => {
    onCloseUsersCreateModal();
    setDialogIsOpen(false);
  };

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild>
        {data.length > 0 ? (
          <Button>Criar usuários</Button>
        ) : (
          <Button disabled>Criar usuários</Button>
        )}
      </DialogTrigger>
      <DialogContent style={{ width: "500px" }}>
        <Flex css={{ flexDirection: "column", gap: "$4" }}>
          <DialogTitle>Informações de Usuários</DialogTitle>

          <UsersToValidateContent
            data={data}
            onCloseDialog={handleCloseDialog}
          />
        </Flex>
      </DialogContent>
    </Dialog>
  );
};
