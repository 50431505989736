export class ReduxThunkError extends Error {
  constructor(payload: any) {
    super(payload);

    //this.name = `${payload.headers.user.account_name} - ${payload.headers.user.name}`;
    this.message = payload.errorMessage;

    Object.setPrototypeOf(this, new.target.prototype);
  }
}
