import { SalesmanToRuptureRule } from "@/core/models/user.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Caption,
  Checkbox,
  DialogClose,
  Flex,
  Input,
  Text,
} from "@gogeo-io/ui-library";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { getSalesmansToRuptureRule } from "../store/ruptureRuleSlice";
import { SelectConsumersSkeletonPage } from "./SelectConsumersSkeletonPage";

interface AddConsumerProps {
  originalSelectedSalesmans: SalesmanToRuptureRule[];
  onSetSelectedSalesmans: (salesmans: SalesmanToRuptureRule[]) => void;
}

export function AddConsumer({
  originalSelectedSalesmans,
  onSetSelectedSalesmans,
}: AddConsumerProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSalesmans, setSelectedSalesmans] = useState<
    SalesmanToRuptureRule[]
  >(originalSelectedSalesmans);
  const [salesmans, setSalesmans] = useState<SalesmanToRuptureRule[]>([]);

  const inputRef = useRef(null);

  const dispatch = useAppDispatch();

  const handleAddSelectSalesman = (user: SalesmanToRuptureRule) => {
    setSelectedSalesmans((prevSalesmans) => [...prevSalesmans, user]);

    const salesmansWithoutRemovedOne = salesmans.filter(
      (salesman) => salesman.id !== user.id
    );
    setSalesmans(salesmansWithoutRemovedOne);
  };

  const handleRemoveSelectSalesman = (user: SalesmanToRuptureRule) => {
    setSalesmans((prevSalesmans) => [...prevSalesmans, user]);

    const salesmansWithoutRemovedOne = selectedSalesmans.filter(
      (salesman) => salesman.id !== user.id
    );
    setSelectedSalesmans(salesmansWithoutRemovedOne);
  };

  const addLooseSalesman = () => {
    const salesman_id = inputRef.current.value;

    const newUser: SalesmanToRuptureRule = {
      id: uuidv4(),
      email: "-----",
      name: "Não cadastrado",
      phone: "-----",
      salesman_id: salesman_id,
    };

    setSelectedSalesmans((prevSalesmans) => [...prevSalesmans, newUser]);

    inputRef.current.value = "";
  };

  const applyChanges = () => onSetSelectedSalesmans(selectedSalesmans);

  useEffect(() => {
    setIsLoading(true);

    const loadSalesmans = async () => {
      await dispatch(
        getSalesmansToRuptureRule({
          salesman_ids: [],
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const salesmans: SalesmanToRuptureRule[] =
            res.payload as SalesmanToRuptureRule[];
          setSalesmans(salesmans);
          setIsLoading(false);
        }
      });
    };

    loadSalesmans();
  }, []);

  return (
    <Flex
      css={{
        maxHeight: "400px",
        flexDirection: "column",
        gap: "$2",
      }}
    >
      {isLoading ? (
        <SelectConsumersSkeletonPage />
      ) : (
        <>
          <Flex css={{ gap: "$2", padding: "4px", mb: "$4" }}>
            <Flex css={{ flex: "1", flexDirection: "column", gap: "$1" }}>
              <Caption>
                Adicionar vendedores avulsos - adicione o código RCA
              </Caption>
              <Flex css={{ gap: "$2" }}>
                <Input
                  placeholder="Ex. 785"
                  style={{ width: "100%", display: "flex" }}
                  isFullWidth
                  ref={inputRef}
                />
                <Button onClick={addLooseSalesman}>Adicionar</Button>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            css={{ flexDirection: "column", gap: "$2", overflowY: "scroll" }}
          >
            {selectedSalesmans.map((user) => {
              return (
                <Flex key={user.id} css={{ gap: "$1" }}>
                  <Checkbox
                    defaultChecked
                    onCheckedChange={(value) =>
                      value
                        ? handleAddSelectSalesman(user)
                        : handleRemoveSelectSalesman(user)
                    }
                  />
                  <Text>
                    {user.salesman_id} - {user.name}
                  </Text>
                </Flex>
              );
            })}
            {salesmans
              .filter(
                (user) =>
                  !selectedSalesmans.some(
                    (selected) => selected.salesman_id === user.salesman_id
                  )
              )
              .map((user) => {
                return (
                  <Flex key={user.id} css={{ gap: "$1" }}>
                    <Checkbox
                      onCheckedChange={(value) =>
                        value
                          ? handleAddSelectSalesman(user)
                          : handleRemoveSelectSalesman(user)
                      }
                    />
                    <Text>
                      {user.salesman_id} - {user.name}
                    </Text>
                  </Flex>
                );
              })}
          </Flex>
        </>
      )}
      <Flex
        css={{
          alignItems: "flex-end",
          justifyContent: "flex-end",
          gap: "$2",
        }}
      >
        <DialogClose>
          <Button bordered style={{ background: "white" }}>
            Cancelar
          </Button>
        </DialogClose>
        <DialogClose>
          <Button onClick={applyChanges}>Alterar</Button>
        </DialogClose>
      </Flex>
    </Flex>
  );
}
