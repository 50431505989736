import { Client } from "@/core/models/client.model";
import { PreDefinedMessageSubject } from "@/core/models/preDefinedMessage.model";
import { Recommendation } from "@/core/models/productsSugestion.model";
import { User } from "@/core/models/user.model";
import { EditPreDefinedMessageToSend } from "@/features/profile/components/EditPreDefinedMessageToSend";
import { useTranslatePreDefinedMessage } from "@/features/profile/hooks/useTranslatePreDefinedMessage";
import {
  selectPreDefinedMessageByTitleAndSubject,
  selectPreDefinedMessagesBySubject,
} from "@/features/profile/store/preDefinedMessagesSlice";
import { useWhatsapp } from "@/hooks/useWhatsapp";
import {
  Button,
  DialogClose,
  Flex,
  IconButton,
  Select,
  SelectItem,
  SelectItemText,
  Text,
} from "@gogeo-io/ui-library";
import { Edit } from "@mui/icons-material";
import G_UI from "@ui/index";
import DOMPurify from "dompurify";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ListDivider } from "./ListDivider";

interface SendProductSuggestionWhatsappProps {
  client: Client;
  user: User;
  recommendation: Recommendation;
  subject: PreDefinedMessageSubject;
}

export function SendProductSuggestionWhatsapp({
  client,
  user,
  recommendation,
  subject,
}: SendProductSuggestionWhatsappProps) {
  const allPreDefinedMessagesSelectedToProductsSuggestion = useSelector(
    selectPreDefinedMessagesBySubject(subject)
  );

  const [preDefinedMessageToShow, setPreDefinedMessageToShow] = useState("");
  const [preDefinedMessageToSend, setPreDefinedMessageToSend] = useState("");
  const [titleSelected, setTitleSelected] = useState(
    allPreDefinedMessagesSelectedToProductsSuggestion[0]?.title
  );

  const selectedPreDefinedMessage = useSelector(
    selectPreDefinedMessageByTitleAndSubject(titleSelected, subject)
  );

  const { sendWhatsappToClient } = useWhatsapp();

  const { productsSuggestionFields } = useTranslatePreDefinedMessage();

  const handleEditPreDefinedMessage = async (message: string) => {
    const textTranslated = productsSuggestionFields(
      client,
      user,
      recommendation,
      message
    );
    setPreDefinedMessageToShow(textTranslated.textToShow);
    setPreDefinedMessageToSend(textTranslated.textToSend);
  };

  useEffect(() => {
    if (selectedPreDefinedMessage !== undefined) {
      const textTranslated = productsSuggestionFields(
        client,
        user,
        recommendation,
        selectedPreDefinedMessage.message
      );
      setPreDefinedMessageToShow(textTranslated.textToShow);
      setPreDefinedMessageToSend(textTranslated.textToSend);
    }
  }, [selectedPreDefinedMessage]);

  const handleSendMenssage = () => {
    sendWhatsappToClient(client, client.main_phone, preDefinedMessageToSend);
  };

  const handleSendMessageWithoutText = () => {
    sendWhatsappToClient(client, client.main_phone, "");
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "1.5rem",
        padding: "1rem 0",
        maxWidth: "550px",
      }}
    >
      {!isEmpty(allPreDefinedMessagesSelectedToProductsSuggestion) ? (
        <>
          <Flex css={{ flexDirection: "column", gap: "$2" }}>
            <Text css={{ color: "$gray600" }}>
              Selecione uma mensagem pré definida
            </Text>

            <Select
              defaultValue={titleSelected}
              value={titleSelected}
              ariaLabel="Select title"
              onValueChange={(v) => setTitleSelected(v)}
            >
              {allPreDefinedMessagesSelectedToProductsSuggestion.map((msg) => {
                return (
                  <SelectItem key={msg.id} value={msg.title}>
                    <SelectItemText>{msg.title}</SelectItemText>
                  </SelectItem>
                );
              })}
            </Select>
          </Flex>
          <Flex css={{ flexDirection: "column", gap: "$2" }}>
            <Flex css={{ gap: "$1", color: "$gray600", alignItems: "center" }}>
              <Text css={{ color: "$gray600" }}>Mensagem</Text>
              {selectedPreDefinedMessage?.message && (
                <EditPreDefinedMessageToSend
                  preDefinedMessage={selectedPreDefinedMessage}
                  onEditPreDefinedMessage={handleEditPreDefinedMessage}
                >
                  <IconButton type="ghost">
                    <Edit />
                  </IconButton>
                </EditPreDefinedMessageToSend>
              )}
            </Flex>
            {preDefinedMessageToShow ? (
              <G_UI.Paragraph
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(preDefinedMessageToShow),
                }}
              />
            ) : (
              <G_UI.Paragraph css={{ color: "$red500" }}>
                * Selecione uma mensagem
              </G_UI.Paragraph>
            )}
          </Flex>
          <ListDivider />
        </>
      ) : (
        <Text>
          Não encontramos mensagens pré definidas cadastradas para sugestão de
          produto.
        </Text>
      )}

      <G_UI.ButtonGroup>
        <DialogClose asChild>
          <Button bordered onClick={handleSendMessageWithoutText}>
            Abrir Contato
          </Button>
        </DialogClose>
        {!isEmpty(allPreDefinedMessagesSelectedToProductsSuggestion) && (
          <DialogClose asChild>
            <Button onClick={handleSendMenssage}>Mandar Mensagem</Button>
          </DialogClose>
        )}
      </G_UI.ButtonGroup>
    </Flex>
  );
}
