import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { UserPermission } from "@/core/models/user-permission.model";
import { User, UserHandler } from "@/core/models/user.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";
import { ParamsOfAccountFormData } from "./paramsOfAccountFormSchema";

export const useProfileMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const editMyInfoEvent = (user_id: number, data: UserHandler) => {
    MixpanelTracking.getInstance().track(TrackingEvents.PROFILE_USER_INFO, {
      ...buildProps(),
      "Usuário alterado": user_id,
      "Informações alteradas": data,
    });
  };

  const userListPermissionsEvent = (permissions: UserPermission[]) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PROFILE_USER_PERMISSIONS,
      {
        ...buildProps(),
        "Permissões do usuário": permissions,
      }
    );
  };

  const userProfileParamsEvent = (params: any) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PROFILE_USER_ATTENDANCE_PARAMS,
      {
        ...buildProps(),
        "Parâmetros de atendimento": params,
      }
    );
  };

  const userAccountProfileParamsEvent = (params: any) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PROFILE_ACCOUNT_ATTENDANCE_PARAMS,
      {
        ...buildProps(),
        "Parâmetros de atendimento da conta": params,
      }
    );
  };

  const userAccountProfileChangeParamsEvent = (
    params: ParamsOfAccountFormData
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PROFILE_ACCOUNT_CHANGE_ATTENDANCE_PARAMS,
      {
        ...buildProps(),
        "Parâmetros alterados": params,
      }
    );
  };

  const changeParamsOfSalesmanEvent = (
    params: ParamsOfAccountFormData,
    salesman: User
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PROFILE_SALESMAN_CHANGE_PARAMS,
      {
        ...buildProps(),
        "Parâmetros alterados": params,
        "Vendedor no qual sera alterado os parâmetros": salesman,
      }
    );
  };

  const userClickedToChangePassword = () => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PROFILE_USER_CHANGE_PASSWORD,
      {
        ...buildProps(),
      }
    );
  };

  return {
    editMyInfoEvent,
    userListPermissionsEvent,
    userProfileParamsEvent,
    userAccountProfileParamsEvent,
    userClickedToChangePassword,
    userAccountProfileChangeParamsEvent,
    changeParamsOfSalesmanEvent,
  };
};
