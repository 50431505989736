import { EmptyContent } from "@/components/EmptyContent";
import { ErrorContent } from "@/components/ErrorContent";
import { LinkToAnotherTab } from "@/components/LinkToAnotherTab";
import { ListDivider } from "@/components/ListDivider";
import {
  ListIndicatorsResponse,
  ListOfTags,
  SearchFilters,
} from "@/core/models/listOfTags.model";
import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import {
  cleanColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { setClientsHasError500 } from "@/features/clients/store/clientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, Flex, Input, Spinner } from "@gogeo-io/ui-library";
import { Search } from "@mui/icons-material";
import { debounce, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAttendanceMixpanel } from "../hooks/useAttendanceMixpanel";
import {
  addTagsOnSelectedClientFilter,
  setSelectedAdvancedFilters,
} from "../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClientsFilters } from "../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import {
  addListsOnState,
  addMyClientsListOnState,
  changeAllList,
  getIndicators,
  getListOfAllClients,
  getListsOfTags,
  selectListsHasError500,
  selectListsOfTags,
  selectListsStatus,
  selectMyClientsStatus,
  selectMyListOfTags,
  selectTotalListOfTags,
  setListsStatusToFulfilled,
  setMyListStatusToFulfilled,
  setTotalListOfTags,
} from "../store/listOfTagsSlice";
import { selectList } from "../store/listSelectedSlice";
import { setSelectedSearchFilters } from "../store/selectedSearchFiltersSlice";
import { AttendanceAllClientsListItem } from "./AttendanceAllClientsListItem";
import { AttendanceListItem } from "./AttendanceListItem";
import { TagGroupListSkeleton } from "./TagsGroupListSkeleton";

export function TagGroupList() {
  const [sortedList, setSortedList] = useState<ListOfTags[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const listsOfTags = useSelector(selectListsOfTags);
  const myClientsList = useSelector(selectMyListOfTags);
  const listsOfTagsStatus = useSelector(selectListsStatus);
  const myClientsListStatus = useSelector(selectMyClientsStatus);
  const totalListOfTags = useSelector(selectTotalListOfTags);
  const listsHasError500 = useSelector(selectListsHasError500);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { searchListsEvent, listOfTagsEditEvent } = useAttendanceMixpanel();

  const onDragEnd = useCallback(
    async (result) => {
      if (!result.destination) return;
      const items = [...sortedList];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      putElementsInOrder(items);
      await dispatch(changeAllList({ lists: items }));
    },
    [sortedList]
  );

  const putElementsInOrder = (list: ListOfTags[]): void => {
    setSortedList([]);
    const listForSort = [...list];

    const brandNewList = listForSort.filter((list) => list.brand_new);
    const fixedList = listForSort.filter((list) => list.fixed);
    const suggestedByGovendasList = listForSort.filter(
      (list) => list.is_suggested_by_govendas
    );
    const nonPriorityList = listForSort.filter(
      (list) => !list.brand_new && !list.fixed && !list.is_suggested_by_govendas
    );

    const mergedLists = [
      ...brandNewList,
      ...fixedList,
      ...fixedList,
      ...suggestedByGovendasList,
      ...nonPriorityList,
    ];

    const uniqueLists = (list): ListOfTags[] =>
      [...new Set(list)] as ListOfTags[];

    setSortedList((prevList) => [...prevList, ...uniqueLists(mergedLists)]);
  };

  const getPaginatedListOfTags = async (currentPage: number) => {
    await dispatch(
      getListsOfTags({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (!isEmpty(res.payload.lists)) {
          const lists: ListOfTags[] = [...listsOfTags, ...res.payload.lists];

          await dispatch(addListsOnState(lists));

          const listsIds = lists.map((list) => list.id);

          await dispatch(getIndicators({ ids: listsIds })).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled") {
              const indicators: ListIndicatorsResponse[] = res.payload;

              const listsWithIndicators = lists.map((list) => {
                const indicator = indicators.find(
                  (indicator) => indicator.id === list.id
                );

                return list.id === indicator.id
                  ? {
                      ...list,
                      infos: indicator.infos,
                      scan_goal: indicator.scan_goal,
                    }
                  : list;
              });

              await dispatch(addListsOnState(listsWithIndicators));
            }
          });
        }
      }
    });
  };

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleClickSearchButton = async () => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClientsFilters(false));

    listOfTagsEditEvent(myClientsList);
    await dispatch(selectList(myClientsList));
    await dispatch(setClientsHasError500(false));

    const columnsToSort = myClientsList.filters?.sort?.orders;
    if (columnsToSort !== undefined && columnsToSort.length > 0) {
      await dispatch(setColumnsToSort(columnsToSort));
    } else {
      await dispatch(cleanColumnsToSort());
    }

    const advancedFilters = myClientsList.filters?.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = myClientsList.filters?.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    const attrOpts = myClientsList.filters?.attr_opts;
    if (attrOpts !== undefined) {
      await dispatch(setAttributesToFilter(attrOpts));
    } else {
      await dispatch(setDefaultAttributesToFilter());
    }

    const searchFilters: SearchFilters = {
      match_type: "Geral",
      match_value: "",
    };

    await dispatch(setSelectedSearchFilters(searchFilters));

    await dispatch(emitEventToChangeClients(true));

    navigate(`/attendance/${myClientsList.slug}`);
  };

  const handleInputChange = async (value: string) => {
    setCurrentPage(0);
    setIsLoading(true);

    await dispatch(
      getListsOfTags({
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        query: value,
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const lists: ListOfTags[] = [...res.payload.lists];

        await dispatch(addListsOnState(lists));
        await dispatch(setTotalListOfTags(res.payload.total));
        await dispatch(setListsStatusToFulfilled());
        searchListsEvent(value, res.payload.total, res.payload.lists);
        setIsLoading(false);

        const listsIds = lists.map((list) => list.id);

        await dispatch(getIndicators({ ids: listsIds })).then(async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            const indicators: ListIndicatorsResponse[] = res.payload;

            const listsWithIndicators = lists.map((list) => {
              const indicator = indicators.find(
                (indicator) => indicator.id === list.id
              );

              return list.id === indicator.id
                ? {
                    ...list,
                    infos: indicator.infos,
                    scan_goal: indicator.scan_goal,
                  }
                : list;
            });

            await dispatch(addListsOnState(listsWithIndicators));
          }
        });
      }
    });
  };

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  useEffect(() => {
    if (currentPage !== 0) {
      if (listsOfTags.length < totalListOfTags) {
        getPaginatedListOfTags(currentPage);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    const loadList = () => {
      if (!isEmpty(listsOfTags)) {
        putElementsInOrder(listsOfTags);
      }
    };
    loadList();
  }, [listsOfTags]);

  useEffect(() => {
    async function loadListsOfTags() {
      await dispatch(
        getListsOfTags({ page: 0, size: import.meta.env.VITE_PAGE_SIZE })
      ).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const lists: ListOfTags[] = res.payload.lists;

          await dispatch(addListsOnState(lists));
          await dispatch(setTotalListOfTags(res.payload.total));
          await dispatch(setListsStatusToFulfilled());

          const listsIds = lists.map((list) => list.id);

          await dispatch(getIndicators({ ids: listsIds })).then(async (res) => {
            if (res.meta.requestStatus === "fulfilled") {
              const indicators: ListIndicatorsResponse[] = res.payload;

              const listsWithIndicators = lists.map((list) => {
                const indicator = indicators.find(
                  (indicator) => indicator.id === list.id
                );

                return list.id === indicator.id
                  ? {
                      ...list,
                      infos: indicator.infos,
                      scan_goal: indicator.scan_goal,
                    }
                  : list;
              });

              await dispatch(addListsOnState(listsWithIndicators));
            }
          });
        }
      });
    }

    loadListsOfTags();

    const loadListOfAllClients = async () => {
      await dispatch(getListOfAllClients()).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const listOfAllClients: ListOfTags = res.payload;

          await dispatch(setMyListStatusToFulfilled());
          await dispatch(addMyClientsListOnState(listOfAllClients));

          await dispatch(getIndicators({ ids: [listOfAllClients.id] })).then(
            async (res) => {
              if (res.meta.requestStatus === "fulfilled") {
                const indicators: ListIndicatorsResponse[] = res.payload;

                const indicator = indicators[0];

                const listWithIndicator: ListOfTags = {
                  ...listOfAllClients,
                  infos: indicator.infos,
                  scan_goal: indicator.scan_goal,
                };

                await dispatch(addMyClientsListOnState(listWithIndicator));
              }
            }
          );
        }
      });
    };
    loadListOfAllClients();
  }, []);

  useEffect(() => {
    if (listsOfTagsStatus === "fulfilled") {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (listsOfTags.length < totalListOfTags) {
            handleIncreasePageNumber();
          }
        }
      });

      intersectionObserver.observe(document.querySelector("#sentry"));

      return () => intersectionObserver.disconnect();
    }
  }, [listsOfTagsStatus]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        height: "100%",
        width: "100%",
      }}
    >
      {myClientsListStatus === "fulfilled" ? (
        <AttendanceAllClientsListItem listSelected={myClientsList} />
      ) : (
        <TagGroupListSkeleton count={1} />
      )}
      <LinkToAnotherTab url={`/attendance/${myClientsList.slug}`}>
        <Button
          fullWidth
          style={{ marginTop: "0.5rem" }}
          onClick={handleClickSearchButton}
        >
          Busca Inteligente de Clientes
        </Button>
      </LinkToAnotherTab>
      <ListDivider />
      <Input
        placeholder="Busque por uma agenda ou lista"
        preffix={isLoading ? <Spinner /> : <Search fontSize="inherit" />}
        onChange={(e) => debounceOnChangeInput(e.target.value)}
        isFullWidth
      />
      {listsHasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos buscar seus listas, tente novamente mais tarde ou
          atualize a página
        </ErrorContent>
      ) : (
        <Flex
          css={{
            flexDirection: "column",
            gap: "$2",
            height: "calc(100% - 200px)",
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {isEmpty(sortedList) ? (
            <EmptyContent size="medium">
              Você não tem listas salvas
            </EmptyContent>
          ) : (
            <ul>
              {sortedList.map((list, idx) => (
                <div key={list.id}>
                  {list.brand_new ? (
                    <li key={list.id}>
                      <AttendanceListItem list={list} />
                      {idx !== sortedList.length - 1 && <ListDivider />}
                    </li>
                  ) : (
                    <li key={list.id} style={{ padding: 0, margin: 0 }}>
                      <AttendanceListItem list={list} />
                      {idx !== sortedList.length - 1 && <ListDivider />}
                    </li>
                  )}
                </div>
              ))}
            </ul>
          )}
          {listsOfTagsStatus === "fulfilled" && (
            <div id="sentry">
              {listsOfTags.length < totalListOfTags ? (
                <Flex css={{ mt: "$1" }}>
                  <TagGroupListSkeleton count={3} />
                </Flex>
              ) : null}
            </div>
          )}
        </Flex>
      )}
    </Flex>
  );
}
