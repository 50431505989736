import { WhatsappProductSuggestionDialog } from "@/components/WhatsappProductSuggestionDialog";
import { ProductRecommendation } from "@/core/models/productsSugestion.model";
import { selectUser } from "@/core/store/users/usersSlice";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { getPreDefinedMessages } from "@/features/profile/store/preDefinedMessagesSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatPrice } from "@/hooks/useFormatPrice";
import {
  Button,
  Caption,
  Flex,
  gvTheme,
  Heading,
  IconButton,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import { useByProductTagType } from "../hooks/useByProductTagType";
import { useProductsSuggestionMixpanel } from "../hooks/useProductsSuggestionMixpanel";

import UI from "../ui";

interface ProductItemProps {
  listName: string;
  recommendation: ProductRecommendation;
}

export function ProductItem({ listName, recommendation }: ProductItemProps) {
  const selectedClient = useSelector(selectClient);
  const user = useSelector(selectUser);

  const dispatch = useAppDispatch();
  const { formatBRLPrice } = useFormatPrice();
  const { dislikedProductEvent, likedProductEvent } =
    useProductsSuggestionMixpanel();
  const { getComponentByProductTagType } = useByProductTagType();

  const handleLikedProduct = () =>
    likedProductEvent(
      recommendation.product_name,
      recommendation.product_id,
      selectedClient.id
    );

  const handleDisLikedProduct = () =>
    dislikedProductEvent(
      recommendation.product_name,
      recommendation.product_id,
      selectedClient.id
    );

  return (
    <UI.ProductItem>
      <div className="header">
        {recommendation.is_trending && (
          <div>
            <TrendingUpIcon style={{ color: gvTheme.colors.primary500 }} />
            <Text wider>Aumento de Vendas</Text>
          </div>
        )}
        <div className="label">
          <Caption uppercase bold css={{ color: "$gray700" }}>
            {listName}
          </Caption>
        </div>
      </div>

      <div className="titleWrapper">
        <Heading size="title6" css={{ color: "$gray800" }}>
          {recommendation.product_name}
        </Heading>
        <div>
          <IconButton
            size="small"
            color="primary"
            type="ghost"
            onClick={handleLikedProduct}
          >
            <ThumbUpIcon />
          </IconButton>
          <IconButton
            size="small"
            color="danger"
            type="ghost"
            onClick={handleDisLikedProduct}
          >
            <ThumbDownIcon />
          </IconButton>
        </div>
      </div>

      <div className="tagsWrapper">
        {recommendation.tags &&
          recommendation.tags.slice(0, 3).map((tag, idx) => {
            return (
              <Tooltip key={idx}>
                <TooltipTrigger style={{ background: "transparent" }}>
                  {getComponentByProductTagType(
                    tag.type,
                    tag.text,
                    tag.tag_value
                  )}
                </TooltipTrigger>
                <TooltipContent aria-label="Remarcação de agendamento">
                  {tag.description}
                </TooltipContent>
              </Tooltip>
            );
          })}
      </div>

      <div className="bottom">
        <Flex css={{ gap: "1rem" }}>
          {recommendation.num_items && (
            <Text css={{ display: "flex", gap: "0.2rem" }}>
              Quantidade indicada:
              <Text bold css={{ color: "$gray900" }}>
                {recommendation.num_items}
              </Text>
            </Text>
          )}
          {recommendation.unit_value && (
            <Text css={{ display: "flex", gap: "0.2rem" }}>
              Preço da unidade:
              <Text bold css={{ color: "$gray900" }}>
                {formatBRLPrice(recommendation.unit_value)}
              </Text>
            </Text>
          )}
        </Flex>
        <WhatsappProductSuggestionDialog
          client={selectedClient}
          user={user}
          recommendation={recommendation}
        >
          <Button
            color="primary"
            size="large"
            variant="ghost"
            onClick={() =>
              dispatch(
                getPreDefinedMessages({
                  page: 0,
                  size: import.meta.env.VITE_PAGE_SIZE,
                })
              )
            }
          >
            <div className="buttonContent">
              <WhatsAppIcon style={{ color: gvTheme.colors.primary500 }} />
              <Caption uppercase bold css={{ color: "$gray700" }}>
                Enviar Mensagem
              </Caption>
            </div>
          </Button>
        </WhatsappProductSuggestionDialog>
      </div>
    </UI.ProductItem>
  );
}
