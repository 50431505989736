import { ListDivider } from "@/components/ListDivider";
import { Notification } from "@/core/models/notification.model";
import { Flex, Heading, Text } from "@gogeo-io/ui-library";
import { NotificationsItem } from "./NotificationsItem";

interface NotReadedNotificationsListProps {
  notReadedNotifications: Notification[];
}

export function NotReadedNotificationsList({
  notReadedNotifications,
}: NotReadedNotificationsListProps) {
  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <Heading size="subtitle1">• Notificações Não Lidas</Heading>
      {notReadedNotifications.length > 0 ? (
        <ul>
          {notReadedNotifications.map((notification, idx) => (
            <div key={notification.id}>
              <li key={notification.id} style={{ padding: 0, margin: 0 }}>
                <NotificationsItem notification={notification} />
                <ListDivider />
              </li>
            </div>
          ))}
        </ul>
      ) : (
        <Text>Você não tem notificações não lidas</Text>
      )}
    </Flex>
  );
}
