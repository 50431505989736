import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";

export const useBreadcrumbsForReportsPage = () => {
  const dispatch = useAppDispatch();

  const showBreadcrumbs = async () => {
    const breadcrumbs: Breadcrumb[] = [{ item: "Relatórios", url: "" }];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return {
    showBreadcrumbs,
  };
};
