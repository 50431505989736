import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  IconButton,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import { BussinessAssociateServiceConfigGenericFormStep1 } from "./BussinessAssociateServiceConfigGenericFormStep1";

interface EnableBusinessAssociateDialogProps {
  onEnableBusinessAssociate: () => void;
}

export function EnableBusinessAssociateServiceDialog({
  onEnableBusinessAssociate,
}: EnableBusinessAssociateDialogProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  return (
    <Dialog open={dialogIsOpen} onOpenChange={(e) => setDialogIsOpen(e)}>
      <DialogTrigger>
        <Button>Ativar</Button>
      </DialogTrigger>
      <Content onClick={(event) => event.stopPropagation()}>
        <DialogClose asChild>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
            onClick={(event) => event.stopPropagation()}
          >
            <MdClose />
          </IconButton>
        </DialogClose>
        <Flex
          css={{
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <BussinessAssociateServiceConfigGenericFormStep1
            onEnableBusinessAssociate={onEnableBusinessAssociate}
            onCloseDialog={() => setDialogIsOpen(false)}
          />
        </Flex>
      </Content>
    </Dialog>
  );
}

const Content = styled(DialogContent)`
  width: 450px;

  @media (max-width: ${({ theme }) => theme.mediaSizes.laptop}) {
    height: 500px;
  }

  @media (max-width: ${({ theme }) => theme.mediaSizes.smallLaptop}) {
    height: 400px;
  }
`;
