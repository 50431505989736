import { DateRangePicker } from "@/components/DateRangePicker";
import { useFormatDate } from "@/hooks/useFormatDate";
import {
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@gogeo-io/ui-library";
import { useDateByDateFilter } from "@hooks/useDateByDateField";
import { useDateWithoutTimezone } from "@hooks/useDateWithoutTimezone";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { format } from "date-fns";
import { useEffect, useState } from "react";

interface BetweenDateSelectProps {
  filterId: number;
  value: string;
  onFilterValueChange: (filterId: number, newValue: string) => void;
}

export function BetweenDateSelect({
  filterId,
  value,
  onFilterValueChange,
}: BetweenDateSelectProps) {
  const { getValuesByDateFilter } = useDateByDateFilter();
  const { getDateWithoutTimezone } = useDateWithoutTimezone();
  const { formatBasicDate, formatBasicDateWithSlash } = useFormatDate();

  const date = new Date();
  const defaultStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const defaultEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const dateFilter = getValuesByDateFilter(
    value !== undefined
      ? value
      : `${formatBasicDate(defaultStartDate)}~${formatBasicDate(
          defaultEndDate
        )}`
  );

  const [betweenStartDate, setBetweenStartDate] = useState<Date>(
    dateFilter !== undefined && dateFilter.between !== undefined
      ? getDateWithoutTimezone(dateFilter.between.start_date)
      : defaultStartDate
  );

  const [betweenEndDate, setBetweenEndDate] = useState<Date>(
    dateFilter !== undefined && dateFilter.between !== undefined
      ? getDateWithoutTimezone(dateFilter.between.end_date)
      : defaultEndDate
  );

  useEffect(() => {
    const startDate = format(betweenStartDate, "yyyy-MM-dd");
    const endDate = format(betweenEndDate, "yyyy-MM-dd");
    const formattedDate = `${startDate}~${endDate}`;
    onFilterValueChange(filterId, formattedDate);
  }, [betweenStartDate, betweenEndDate]);

  return (
    <Popover>
      <PopoverTrigger>
        <Flex
          align="center"
          css={{
            width: "100%",
            height: "100%",
            gap: "$3",
            background: "white",
          }}
        >
          <Text>{formatBasicDateWithSlash(betweenStartDate)}</Text>
          <ArrowRightAltIcon />
          <Text>{formatBasicDateWithSlash(betweenEndDate)}</Text>
        </Flex>
      </PopoverTrigger>
      <PopoverContent align="center" size="lg" style={{ zIndex: "9999" }}>
        <DateRangePicker
          startDate={betweenStartDate}
          endDate={betweenEndDate}
          setStartDate={setBetweenStartDate}
          setEndDate={setBetweenEndDate}
        />
      </PopoverContent>
    </Popover>
  );
}
