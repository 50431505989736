import { Caption } from "@gogeo-io/ui-library";
import styled from "styled-components";
import { Day, Tip } from "./styles";

interface NonePrefProps {
  day: string;
  prop: string;
  changeValue?: (prop: string) => void;
}

export function NonePref({ day, prop, changeValue = () => {} }: NonePrefProps) {
  const handleClick = () => {
    changeValue(prop);
  };

  return (
    <Day onClick={handleClick}>
      <Caption css={{ color: "$gray800" }}>{day}</Caption>
      <StyledTip />
    </Day>
  );
}

export const StyledTip = styled(Tip)`
  background: transparent;
`;
