import { useFormatPrice } from "@/hooks/useFormatPrice";
import { Flex, Text } from "@gogeo-io/ui-library";

interface TimelineHeaderInfoOrderTotalAmountProps {
  order_total_amount: number;
}

export function TimelineHeaderInfoOrderTotalAmount({
  order_total_amount,
}: TimelineHeaderInfoOrderTotalAmountProps) {
  const { formatBRLPrice } = useFormatPrice();

  return (
    <Flex
      css={{
        flexDirection: "row",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <Text css={{ color: "$gray900" }} bold>
        {formatBRLPrice(order_total_amount)}
      </Text>
    </Flex>
  );
}
