import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { ListOfTagsFilter } from "@/core/models/listOfTags.model";
import {
  selectSelectedAdvancedFilters,
  selectSelectedTagsOnClientFilters,
} from "@/features/attendance/store/clientAdvancedFiltersSlice";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { selectSelectedSearchFilters } from "@/features/attendance/store/selectedSearchFiltersSlice";
import { selectAttrOptsToFilter } from "@/features/clientInfo/store/clientAttrOptsSlice";
import { selectColumnsToSort } from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { isEmpty, isUndefined } from "lodash";
import { useSelector } from "react-redux";

export const useGetClientsByFiltersParams = () => {
  const selectedList = useSelector(selectSelectedList);
  const selectedSearchFilters = useSelector(selectSelectedSearchFilters);
  const selectedAttrOpts = useSelector(selectAttrOptsToFilter);
  const selectedAdvancedFilters = useSelector(selectSelectedAdvancedFilters);
  const selectedTagsOnClientFilters = useSelector(
    selectSelectedTagsOnClientFilters
  );
  const columnsToSort = useSelector(selectColumnsToSort);

  const paramsOnListView = () => {
    const params: ListOfTagsFilter = {
      ...selectedList.filters,
      ...(!isEmpty(selectedSearchFilters) && {
        match: {
          ...selectedSearchFilters,
        },
      }),
      ...(columnsToSort !== undefined &&
        columnsToSort.length > 0 && {
          sort: {
            orders: columnsToSort,
          },
        }),
    };

    return params;
  };

  const paramsOnKanbanView = (columnFilter: AdvancedFilter) => {
    const advancedFilters = selectedList.filters.advanced_filters;

    const params: ListOfTagsFilter = {
      ...selectedList.filters,
      ...(!isUndefined(advancedFilters)
        ? {
            advanced_filters: [
              columnFilter,
              ...selectedList.filters.advanced_filters,
            ],
          }
        : {
            advanced_filters: [columnFilter],
          }),
      ...(!isEmpty(selectedSearchFilters) && {
        match: {
          ...selectedSearchFilters,
        },
      }),
    };

    return params;
  };

  const paramsOnIndicatorsView = () => {
    const params: ListOfTagsFilter = {
      ...selectedList.filters,
      ...(!isEmpty(selectedSearchFilters) && {
        match: {
          ...selectedSearchFilters,
        },
      }),
      ...(columnsToSort !== undefined &&
        columnsToSort.length > 0 && {
          sort: {
            orders: columnsToSort,
          },
        }),
    };

    return params;
  };

  const paramsOnEditListView = () => {
    const params: ListOfTagsFilter = {
      ...(!isUndefined(selectedAdvancedFilters) && {
        advanced_filters: [...selectedAdvancedFilters],
      }),
      ...(selectedTagsOnClientFilters.length > 0 && {
        tags: [...selectedTagsOnClientFilters],
      }),
      ...(selectedAttrOpts.length > 0 && {
        attr_opts: [...selectedAttrOpts],
      }),
      ...(!isEmpty(selectedSearchFilters) && {
        match: {
          ...selectedSearchFilters,
        },
      }),
      ...(columnsToSort !== undefined &&
        columnsToSort.length > 0 && {
          sort: {
            orders: columnsToSort,
          },
        }),
    };

    return params;
  };

  const paramsOnEditKanbanView = (columnFilter: AdvancedFilter) => {
    const params: ListOfTagsFilter = {
      ...(!isUndefined(selectedAdvancedFilters) && {
        advanced_filters: [columnFilter, ...selectedAdvancedFilters],
      }),
      ...(selectedTagsOnClientFilters.length > 0 && {
        tags: [...selectedTagsOnClientFilters],
      }),
      ...(selectedAttrOpts.length > 0 && {
        attr_opts: [...selectedAttrOpts],
      }),
      ...(!isEmpty(selectedSearchFilters) && {
        match: {
          ...selectedSearchFilters,
        },
      }),
    };
    return params;
  };

  const paramsOnEditIndicatorsView = () => {
    const params: ListOfTagsFilter = {
      ...(!isUndefined(selectedAdvancedFilters) && {
        advanced_filters: [...selectedAdvancedFilters],
      }),
      ...(selectedTagsOnClientFilters.length > 0 && {
        tags: [...selectedTagsOnClientFilters],
      }),
      ...(selectedAttrOpts.length > 0 && {
        attr_opts: [...selectedAttrOpts],
      }),
      ...(!isEmpty(selectedSearchFilters) && {
        match: {
          ...selectedSearchFilters,
        },
      }),
      ...(columnsToSort !== undefined &&
        columnsToSort.length > 0 && {
          sort: {
            orders: columnsToSort,
          },
        }),
    };

    return params;
  };

  return {
    paramsOnListView,
    paramsOnKanbanView,
    paramsOnIndicatorsView,
    paramsOnEditListView,
    paramsOnEditKanbanView,
    paramsOnEditIndicatorsView,
  };
};
