import { User } from "@/core/models/user.model";

export const useGeneralFields = (user: User) => {
  const fieldsToTranslate = [
    {
      user_field: "Meu nome",
      translated_field: user.name,
    },
    {
      user_field: "Meu email",
      translated_field: user.email,
    },
    {
      user_field: "Meu ID",
      translated_field: user.id,
    },
    {
      user_field: "Empresa que trabalho",
      translated_field: user.account_name,
    },
    {
      user_field: "Meu telefone",
      translated_field: user.phone,
    },
  ];

  return fieldsToTranslate;
};
