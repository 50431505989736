import { ColumnOnClientsKanban } from "@/features/clients/components/ColumnOnClientsKanban";
import { Flex } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { selectSelectedFunnel } from "../store/funnelsSlice";

export function KanbanViewOfClients() {
  const selectedFunnel = useSelector(selectSelectedFunnel);

  return (
    <Flex
      css={{
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <Flex css={{ gap: "$2", height: "100%" }}>
        {selectedFunnel.kanban_columns !== undefined &&
          selectedFunnel.kanban_columns.map((column) => {
            return (
              <ColumnOnClientsKanban
                key={column.id}
                columnName={column.name}
                columnId={column.id}
                columnCount={column.clients_count}
                columnFilter={column.filter}
                columnIcon={column.icon}
                columnColor={column.color}
              />
            );
          })}
      </Flex>
    </Flex>
  );
}
