import { ListOfTags } from "@/core/models/listOfTags.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useBusinessAssociateMixpanel } from "../hooks/useBusinessAssociateMixpanel";
import {
  selectBusinessAssociateServicesOfList,
  updateSelectedBusinessAssociateServices,
} from "../store/businessAssociateServicesSlice";

interface BusinessAssociateServicesOfListModalContentProps {
  onCloseDialog: () => void;
  selectedList: ListOfTags;
}

export function BusinessAssociateServicesOfListModalContent({
  onCloseDialog,
  selectedList,
}: BusinessAssociateServicesOfListModalContentProps) {
  const [isLoading, setIsLoading] = useState(false);

  const businessAssociateServicesOfList = useSelector(
    selectBusinessAssociateServicesOfList
  );

  const dispatch = useAppDispatch();
  const { businessAssociateServicesOfListChanged } =
    useBusinessAssociateMixpanel();

  const [
    selectedBusinessAssociateServicesIds,
    setSelectedBusinessAssociateServicesIds,
  ] = useState<number[]>([]);

  const handleBusinessActionChange = (enabled: boolean, action_id: number) => {
    if (enabled) {
      setSelectedBusinessAssociateServicesIds((prevState) => [
        ...prevState,
        action_id,
      ]);
    } else {
      const stateWithoutRemovedOne =
        selectedBusinessAssociateServicesIds.filter((s) => s !== action_id);

      setSelectedBusinessAssociateServicesIds(stateWithoutRemovedOne);
    }
  };

  const cancelChanges = () => {
    setSelectedBusinessAssociateServicesIds(
      selectedList.services.map((service) => service.id)
    );
    onCloseDialog();
  };

  const handleUpdateSelectedBusinessAssociateServices = async () => {
    setIsLoading(true);

    await dispatch(
      updateSelectedBusinessAssociateServices({
        ids: selectedBusinessAssociateServicesIds,
        list_id: selectedList.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Alteração feita com sucesso");
        businessAssociateServicesOfListChanged(
          selectedList,
          selectedBusinessAssociateServicesIds
        );
      } else {
        toast.error("Ocorreu uma falha na alteração");
      }
    });

    setIsLoading(false);
    onCloseDialog();
  };

  const actionIsEnabled = (action_id: number) => {
    return selectedBusinessAssociateServicesIds.includes(action_id);
  };

  useEffect(() => {
    setSelectedBusinessAssociateServicesIds(
      selectedList.services
        ? selectedList.services.map((service) => service.id)
        : []
    );
  }, []);

  return (
    <Flex css={{ flexDirection: "column", gap: "$3", mt: "$3" }}>
      {businessAssociateServicesOfList &&
        businessAssociateServicesOfList.map((businessAssociate) => {
          if (businessAssociate && businessAssociate.services.length > 0) {
            return (
              <Flex
                css={{
                  flexDirection: "column",
                  gap: "$1",
                }}
                key={businessAssociate.id}
              >
                <Heading>{businessAssociate.name}</Heading>
                <Flex
                  css={{ flexDirection: "column", gap: "$2", marginLeft: "$2" }}
                >
                  {businessAssociate.services.map((service) => {
                    return (
                      <>
                        <Tooltip>
                          <TooltipTrigger
                            style={{
                              color: "inherit",
                              background: "transparent",
                            }}
                          >
                            <Flex
                              key={service.id}
                              css={{ gap: "$1", alignItems: "center" }}
                            >
                              <Box>
                                <Checkbox
                                  checked={actionIsEnabled(service.id)}
                                  onCheckedChange={(e) =>
                                    handleBusinessActionChange(
                                      Boolean(e),
                                      service.id
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </Box>
                              <Text>{service.name}</Text>
                            </Flex>
                          </TooltipTrigger>
                          <TooltipContent
                            style={{ zIndex: 100000 }}
                            aria-label={service.name}
                          >
                            {service.description}
                          </TooltipContent>
                        </Tooltip>
                      </>
                    );
                  })}
                </Flex>
              </Flex>
            );
          }
        })}

      <Flex
        css={{ alignItems: "flex-end", justifyContent: "flex-end", gap: "$2" }}
      >
        <Button bordered onClick={cancelChanges}>
          Cancelar
        </Button>
        <Button
          onClick={handleUpdateSelectedBusinessAssociateServices}
          isLoading={isLoading}
        >
          Salvar
        </Button>
      </Flex>
    </Flex>
  );
}
