import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { ClientNote } from "@/core/models/client-notes.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useClientNotesMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const notesLoadedEvent = (notes: ClientNote[]) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_NOTES_LOADED, {
      ...buildProps("client"),
      Notas: notes,
    });
  };

  const notesCreateEvent = () => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_NOTES_CREATE, {
      ...buildProps("client"),
    });
  };

  const notesCreatedEvent = (note: any, files: any) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_NOTES_CREATED, {
      ...buildProps("client"),
      "Nota criada": note,
      "Arquivos da nota": files,
    });
  };

  const noteOpenedEvent = (note: ClientNote) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_NOTES_OPENED, {
      ...buildProps("client"),
      "Nota acessada": note,
    });
  };

  const notesUpdatedEvent = (note: any, files: any) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_NOTES_UPDATED, {
      ...buildProps("client"),
      "Nota atualizada": note,
      "Arquivos da nota": files,
    });
  };

  const noteDeletedEvent = (note: ClientNote) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_NOTES_DELETED, {
      ...buildProps("client"),
      "Nota deletada": note,
    });
  };

  const noteSharedEvent = (note: ClientNote) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_NOTES_SHARED, {
      ...buildProps("client"),
      "Nota virou pública": note,
    });
  };

  const noteUnSharedEvent = (note: ClientNote) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_NOTES_UNSHARED, {
      ...buildProps("client"),
      "Nota virou privada": note,
    });
  };

  return {
    notesLoadedEvent,
    notesCreateEvent,
    notesCreatedEvent,
    noteOpenedEvent,
    notesUpdatedEvent,
    noteDeletedEvent,
    noteSharedEvent,
    noteUnSharedEvent,
  };
};
