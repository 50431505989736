import { Text } from "@gogeo-io/ui-library";
import { MdClose, MdDone } from "react-icons/md";
import styled from "styled-components";

interface UserCreatedCellProps {
  created: boolean;
}

export function UserCreatedCell({ created }: UserCreatedCellProps) {
  return (
    <Cell $created={created}>
      <Text css={{ color: "inherit", fontSize: "24px" }}>
        {created ? <MdDone color="inherit" /> : <MdClose color="inherit" />}
      </Text>
    </Cell>
  );
}

interface RoleCellProps {
  $created: boolean;
}

const Cell = styled.div<RoleCellProps>`
  color: ${(props) =>
    props.theme.colors[props.$created ? "primary500" : "red500"]};
`;
