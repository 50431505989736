import { ProductTagType } from "@/core/models/productsSugestion.model";
import { ByCalendar } from "../components/productsTagType/ByCalendar";
import { ByLocation } from "../components/productsTagType/ByLocation";
import { ByOkNo } from "../components/productsTagType/ByOkNo";
import { ByProgress } from "../components/productsTagType/ByProgress";
import { ByString } from "../components/productsTagType/ByString";
import { ByUpAndDown } from "../components/productsTagType/ByUpAndDown";

export const useByProductTagType = () => {
  const getComponentByProductTagType = (
    type: ProductTagType,
    text: string,
    tag_value?: any
  ) => {
    switch (type) {
      case "string":
        return <ByString text={text} />;
      case "updown":
        return <ByUpAndDown text={text} tag_value={tag_value} />;
      case "okno":
        return <ByOkNo text={text} tag_value={tag_value} />;
      case "progress":
        return <ByProgress text={text} tag_value={tag_value} />;
      case "location":
        return <ByLocation text={text} />;
      case "calendar":
        return <ByCalendar text={text} />;
      default:
        return <ByString text={text} />;
    }
  };

  return { getComponentByProductTagType };
};
