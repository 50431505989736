export const useTranslateFieldToCreateUser = () => {
  return (field: string) => {
    switch (field) {
      case "salesman_id":
        return "Código RCA do Vendedor";
      case "supervisor":
        return "Email do Supervisor";
      case "email":
        return "Email";
      case "password":
        return "Senha";
      case "role":
        return "Função";
      case "name":
        return "Nome";
      case "phone":
        return "Telefone";
      case "salesman_type":
        return "Tipo de Vendedor";
      case "skip_scheduling":
        return "Desabilitar agendamento";
      case "max_visit":
        return "Capacidade de Atendimento";
      case "access_all_clients":
        return "Acessar Todos Clientes";
      case "allow_change_params":
        return "Pode Alterar Parâmetros";
      default:
        return field;
    }
  };
};
