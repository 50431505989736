import { BusinessAssociate } from "@/core/models/business-associate.model";
import { Flex, Heading } from "@gogeo-io/ui-library";
import { BusinessAssociateConfigMapOfFields } from "./BusinessAssociateConfigMapOfFields";

interface BusinessAssociateConfigListProps {
  businessAssociate: BusinessAssociate;
}

export function BusinessAssociateConfigList({
  businessAssociate,
}: BusinessAssociateConfigListProps) {
  const { govendas_fields, associate_fields } = businessAssociate;

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {govendas_fields && govendas_fields.length > 0 && (
        <Flex css={{ flexDirection: "column", gap: "$2" }}>
          <Heading>Configurações - GoVendas</Heading>

          <BusinessAssociateConfigMapOfFields fields={govendas_fields} />
        </Flex>
      )}

      {associate_fields && associate_fields.length > 0 && (
        <Flex css={{ flexDirection: "column", gap: "$2" }}>
          <Heading>Configurações - {businessAssociate.name}</Heading>

          <BusinessAssociateConfigMapOfFields fields={associate_fields} />
        </Flex>
      )}
    </Flex>
  );
}
