import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { businessGoalsMocks } from "@/core/mocks/businessGoalsMock";
import {
  BusinessGoal,
  BusinessGoalCategorie,
} from "@/core/models/business-goal.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

interface BusinessGoalsState {
  businessGoals: BusinessGoal[];
  has500error: boolean;
  status: "loading" | "success" | "failed";
}

const initialState = {
  businessGoals: [],
  has500error: false,
  status: "loading",
} as BusinessGoalsState;

export const getBusinessGoals = createAsyncThunk(
  "businessGoals/getBusinessGoals",
  async (_, thunkAPI) => {
    return businessGoalsMocks;
    // try {
    //   const response = await axios.get(`/businessGoals`);
    //   if (response.status !== 200 && response.status !== 202) {
    //     return thunkAPI.rejectWithValue({
    //       errorMessage: response.data.message,
    //       timestamp: response.data.timestamp,
    //       status: response.status,
    //       headers: response.headers,
    //     });
    //   }
    //   return response.data;
    // } catch (error: unknown) {
    //   if (error instanceof AxiosError) {
    //     return thunkAPI.rejectWithValue({
    //       errorMessage: error.response?.data.message,
    //       headers: error.config.headers,
    //       method: error.config.method,
    //       url: error.config.url,
    //       status: error.response.status,
    //       timestamp: new Date(),
    //     });
    //   }
    // }
  }
);

interface CreateBusinessGoalProps {
  name: string;
  color: string;
  categories: BusinessGoalCategorie[];
}

export const createBusinessGoal = createAsyncThunk(
  "businessGoals/createBusinessGoal",
  async (data: CreateBusinessGoalProps, thunkAPI) => {
    // try {
    //   const response = await axios.post("/businessGoals", data);
    //   if (
    //     response.status !== 200 &&
    //     response.status !== 201 &&
    //     response.status !== 202
    //   ) {
    //     return thunkAPI.rejectWithValue({
    //       errorMessage: response.data.message,
    //       timestamp: response.data.timestamp,
    //       status: response.status,
    //       headers: response.headers,
    //     });
    //   }
    //   return response.data;
    // } catch (error: unknown) {
    //   if (error instanceof AxiosError) {
    //     return thunkAPI.rejectWithValue({
    //       errorMessage: error.response?.data.message,
    //       headers: error.config.headers,
    //       method: error.config.method,
    //       url: error.config.url,
    //       timestamp: new Date(),
    //     });
    //   }
    // }
  }
);

interface UpdateBusinessGoalProps {
  id: number;
  name?: string;
  color?: string;
  categories?: BusinessGoalCategorie[];
}

export const updateBusinessGoal = createAsyncThunk(
  "businessGoals/updateBusinessGoalName",
  async (data: UpdateBusinessGoalProps, thunkAPI) => {
    // try {
    //   const response = await axios.put(`/businessGoals/${data.id}`, data);
    //   if (response.status !== 200 && response.status !== 202) {
    //     return thunkAPI.rejectWithValue({
    //       errorMessage: response.data.message,
    //       timestamp: response.data.timestamp,
    //       status: response.status,
    //       headers: response.headers,
    //     });
    //   }
    //   return response.data;
    // } catch (error: unknown) {
    //   if (error instanceof AxiosError) {
    //     return thunkAPI.rejectWithValue({
    //       errorMessage: error.response?.data.message,
    //       headers: error.config.headers,
    //       method: error.config.method,
    //       url: error.config.url,
    //       timestamp: new Date(),
    //     });
    //   }
    // }
  }
);

interface DeleteBusinessGoalProps {
  id: number;
}

export const deleteBusinessGoal = createAsyncThunk(
  "businessGoals/deleteBusinessGoal",
  async (data: DeleteBusinessGoalProps, thunkAPI) => {
    // try {
    //   const response = await axios.delete(`/businessGoals/${data.id}`);
    //   if (response.status !== 200 && response.status !== 202) {
    //     return thunkAPI.rejectWithValue({
    //       errorMessage: response.data.message,
    //       timestamp: response.data.timestamp,
    //       status: response.status,
    //       headers: response.headers,
    //     });
    //   }
    //   return response.data;
    // } catch (error: unknown) {
    //   if (error instanceof AxiosError) {
    //     return thunkAPI.rejectWithValue({
    //       errorMessage: error.response?.data.message,
    //       headers: error.config.headers,
    //       method: error.config.method,
    //       url: error.config.url,
    //       timestamp: new Date(),
    //     });
    //   }
    // }
  }
);

export const businessGoalsSlice = createSlice({
  name: "businessGoalsSlice",
  initialState,
  reducers: {
    removeBusinessGoalFromState(state, action: PayloadAction<number>) {
      const stateWithoutRemovedOne = state.businessGoals.filter(
        (goal) => goal.id !== action.payload
      );
      state.businessGoals = stateWithoutRemovedOne;
    },
    updateBusinessGoalFromState(state, action: PayloadAction<BusinessGoal>) {
      state.businessGoals.forEach((goal) =>
        goal.id === action.payload.id ? action.payload : goal
      );
    },
    addBusinessGoalOnState(state, action: PayloadAction<BusinessGoal>) {
      state.businessGoals.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusinessGoals.pending, (state) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(
      getBusinessGoals.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.has500error = false;
        state.businessGoals = action.payload;
      }
    );
    builder.addCase(
      getBusinessGoals.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error on getting the business goals");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(createBusinessGoal.pending, (state) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(createBusinessGoal.fulfilled, (state) => {
      state.status = "success";
      state.has500error = false;
    });
    builder.addCase(
      createBusinessGoal.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error creating a business goal");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(updateBusinessGoal.pending, (state) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(updateBusinessGoal.fulfilled, (state) => {
      state.status = "success";
      state.has500error = false;
    });
    builder.addCase(
      updateBusinessGoal.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating a business goal");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(deleteBusinessGoal.pending, (state) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(deleteBusinessGoal.fulfilled, (state) => {
      state.status = "success";
      state.has500error = false;
    });
    builder.addCase(
      deleteBusinessGoal.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error deleting a business goal");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

////////////////////////////////////////////////

export const selectBusinessGoals = (state: RootState) =>
  state.businessGoalsState.businessGoals;
export const selectCategoriesByBusinessGoals = (state: RootState) =>
  state.businessGoalsState.businessGoals.find((buss) => buss.id === 1)
    .categories;
export const selectBusinessGoalsStatus = (state: RootState) =>
  state.businessGoalsState.status;
export const selectBusinessGoalsHasError500 = (state: RootState) =>
  state.businessGoalsState.has500error;

export const {
  removeBusinessGoalFromState,
  updateBusinessGoalFromState,
  addBusinessGoalOnState,
} = businessGoalsSlice.actions;

export const businessGoalsReducer = businessGoalsSlice.reducer;
