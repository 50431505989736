import { History } from "@/core/models/history.model";
import UI from "../ui";
import { HistoryActions } from "./HistoryActions";
import { TimelineList } from "./TimelineList";

interface TimelineProps {
  history: History[];
  historyIsCollapsed?: boolean;
  onHistoryIsCollapsed: () => void;
  onHistoryRefresh: () => void;
  hasMorePages: boolean;
}

export const Timeline = ({
  history,
  historyIsCollapsed = false,
  onHistoryIsCollapsed,
  onHistoryRefresh,
  hasMorePages,
}: TimelineProps) => {
  return (
    <UI.Timeline>
      <div className="history">
        <TimelineList
          history={history}
          historyIsCollapsed={historyIsCollapsed}
          hasMorePages={hasMorePages}
        />
      </div>

      <HistoryActions
        onHistoryIsCollapsed={onHistoryIsCollapsed}
        onHistoryRefresh={onHistoryRefresh}
      />
    </UI.Timeline>
  );
};
