import { ListDivider } from "@/components/ListDivider";
import { ClientProfileInfoPhone } from "@/features/attendance/components/ClientProfileInfoPhone";
import { ProfileInfo } from "@/features/attendance/components/ProfileInfo";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { Flex, Heading } from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useSelector } from "react-redux";

export function ClientProfileContactData() {
  const selectedClient = useSelector(selectClient);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "0.625rem",
        padding: "0.625rem",
      }}
    >
      <Heading size="subtitle1" css={{ color: "$gray800" }}>
        Dados de Contato
      </Heading>
      <ListDivider />
      {!isEmpty(selectedClient.email) && !isUndefined(selectedClient.email) ? (
        <ProfileInfo
          asOuterLink
          label="Email"
          value={selectedClient.email}
          href={selectedClient.email}
        />
      ) : (
        <ProfileInfo label="Email" value="Não consta" />
      )}

      {!isEmpty(selectedClient.main_phone) &&
      !isUndefined(selectedClient.main_phone) &&
      !isEmpty(selectedClient.unformatted_main_phone) &&
      !isUndefined(selectedClient.unformatted_main_phone) ? (
        <ClientProfileInfoPhone
          label="Telefone Principal"
          phone={selectedClient.main_phone}
          unformatted_phone={selectedClient.unformatted_main_phone}
          client={selectedClient}
          phoneActionsIsVisible
        />
      ) : (
        <ProfileInfo label="Telefone Principal" value="Não consta" />
      )}

      {!isEmpty(selectedClient.phones) &&
        !isUndefined(selectedClient.phones) &&
        selectedClient.phones.map((phone, idx) => {
          return (
            <ClientProfileInfoPhone
              key={idx}
              label={phone.label}
              phone={phone.value}
              unformatted_phone={phone.unformatted_value}
              client={selectedClient}
              phoneActionsIsVisible
            />
          );
        })}
    </Flex>
  );
}
