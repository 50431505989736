import { Breadcrumb } from "@/core/models/breadcrumb";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface BreadcrumbsState {
  breadcrumbs: Breadcrumb[];
}

const initialState = {
  breadcrumbs: [],
} as BreadcrumbsState;

export const breadcrumbsSlice = createSlice({
  name: "breadcrumbsSlice",
  initialState,
  reducers: {
    setBreadcrumbs(state, action: PayloadAction<Breadcrumb[]>) {
      state.breadcrumbs = action.payload;
    },
  },
});

export const selectBreadcrumbs = (state: RootState) =>
  state.breadcrumbsState.breadcrumbs;

export const { setBreadcrumbs } = breadcrumbsSlice.actions;

export const breadcrumbsReducer = breadcrumbsSlice.reducer;
