import { Feedback } from "@/core/models/feedback.model";
import { User } from "@/core/models/user.model";
import {
  getUsersRelatedToUser,
  selectSalesmansRelatedToUser,
  selectUser,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  Select,
  SelectItem,
  SelectItemText,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import RLSSkeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

interface TryAgainUploadFileToRegisterAttendanceProps {
  feedback: Feedback;
  onRegisterAttendance: (
    data: Feedback,
    salesmanIdSelectedToRegisterAttendance: string
  ) => void;
}

export function SelectSalesmanToRegisterAttendanceOn({
  feedback,
  onRegisterAttendance,
}: TryAgainUploadFileToRegisterAttendanceProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [
    selectedSalesmanIdToRegisterAttendance,
    setSelectedSalesmanIdToRegisterAttendance,
  ] = useState<string>("");

  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const salesmansRelatedToUser = useSelector(selectSalesmansRelatedToUser);

  const handleRegisterAttendance = () => {
    onRegisterAttendance(feedback, selectedSalesmanIdToRegisterAttendance);
  };

  useEffect(() => {
    const loadRelatedUsers = async () => {
      setIsLoading(true);

      await dispatch(
        getUsersRelatedToUser({ user_id: user.id, role: "SALESMAN" })
      ).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const relatedUsers: User[] = res.payload;
          setSelectedSalesmanIdToRegisterAttendance(
            relatedUsers.length > 0 ? relatedUsers[0].salesman_id : ""
          );
          setIsLoading(false);
        }
      });
    };

    loadRelatedUsers();
  }, []);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger />
      <DialogContent
        style={{ width: "450px" }}
        onInteractOutside={(e) => e.preventDefault()}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <DialogTitle>
          Selecionar vendedor para quem você irá registrar o atendimento
        </DialogTitle>

        <Flex
          css={{
            flexDirection: "column",
            gap: "$5",
            mt: "$5",
          }}
        >
          {isLoading ? (
            <RLSSkeleton height={37} />
          ) : (
            <Select
              value={selectedSalesmanIdToRegisterAttendance}
              defaultValue={selectedSalesmanIdToRegisterAttendance}
              ariaLabel="Select option"
              onValueChange={setSelectedSalesmanIdToRegisterAttendance}
            >
              {salesmansRelatedToUser.map((salesman) => {
                return (
                  <SelectItem
                    key={salesman.salesman_id}
                    value={salesman.salesman_id}
                  >
                    <SelectItemText>{salesman.name}</SelectItemText>
                  </SelectItem>
                );
              })}
            </Select>
          )}

          <Flex
            css={{
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Flex
              css={{
                gap: "0.5rem",
                marginTop: "0.75rem",
              }}
            >
              <DialogClose asChild>
                <Button
                  color="danger"
                  variant="soft"
                  onClick={() => setIsOpen(false)}
                >
                  Cancelar
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleRegisterAttendance();
                  }}
                >
                  Registrar
                </Button>
              </DialogClose>
            </Flex>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
