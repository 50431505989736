import { Button, DialogClose, Flex, Heading, Text } from "@gogeo-io/ui-library";

interface DeleteClientNoteConfirmationProps {
  onDeleteRuptureRule: () => void;
}

export function DeleteRuptureRuleConfirmation({
  onDeleteRuptureRule,
}: DeleteClientNoteConfirmationProps) {
  return (
    <Flex
      css={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5rem",
        textAlign: "center",
      }}
    >
      <Heading>Você tem certeza?</Heading>
      <Text>
        Você tem certeza que deseja excluir essa regra de ruptura? Ao excluir a
        regra, Você perderá controle sobre os vendedores com ruptura monitorada
        e aqueles que irão atender cliente em ruptura, e no mais, você não terá
        acesso as seguinte configurações: Período de Ruptura, Vendedores com
        ruptura monitorada. Vendedores que atenderão clientes em ruptura e
        Filtros genéricos;
      </Text>

      <Flex css={{ gap: "0.5rem", marginTop: "0.75rem" }}>
        <DialogClose asChild>
          <Button color="gray" onClick={(event) => event.stopPropagation()}>
            Cancelar
          </Button>
        </DialogClose>
        <DialogClose asChild>
          <Button
            color="danger"
            onClick={(event) => {
              event.stopPropagation();
              onDeleteRuptureRule();
            }}
          >
            Excluir
          </Button>
        </DialogClose>
      </Flex>
    </Flex>
  );
}
