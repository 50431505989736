import {
  PreDefinedMessageTextArea,
  PreDefinedMessageTextAreaActions,
  PreDefinedMessageTextAreaContainer,
} from "./PreDefinedMessageTextArea";

export default {
  PreDefinedMessageTextArea,
  PreDefinedMessageTextAreaContainer,
  PreDefinedMessageTextAreaActions,
};
