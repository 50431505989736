import { User } from "@/core/models/user.model";
import { Box, Text } from "@gogeo-io/ui-library";
import { Table } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

interface UsersTableProps {
  columns: any;
  data: any;
  isLoading: boolean;
}

export function RuptureRuleConsumersTable({
  columns,
  data,
  isLoading,
}: UsersTableProps) {
  const [users, setUsers] = useState(data);

  const generateRowKey = (record: User) => {
    return `${record.id}-${record.name}`;
  };

  useEffect(() => {
    setUsers(data);
  }, [data]);

  return (
    <>
      {!isEmpty(users) ? (
        <Box css={{ overflow: "auto" }}>
          <Table
            columns={columns}
            dataSource={users}
            loading={isLoading}
            pagination={false}
            rowKey={generateRowKey}
            sticky={{ offsetHeader: 0 }}
            scroll={{ x: "100%" }}
          />
        </Box>
      ) : (
        <Text>Nenhum vendedor encontrado</Text>
      )}
    </>
  );
}
