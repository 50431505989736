import useAppDispatch from "@/hooks/useAppDispatch";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useClientNotesBreadcrumbs } from "../hooks/useClientNotesBreadcrumbs";
import {
  getClientNote,
  selectClientNoteStatus,
} from "../store/clientNotesSlice";
import { ClientNoteList } from "./ClientNoteList";
import { ClientNoteSkeleton } from "./Skeleton/ClientNoteSkeleton";

export function ClientNoteListContainer() {
  const clientNoteStatus = useSelector(selectClientNoteStatus);

  const dispatch = useAppDispatch();
  const { note_id } = useParams();
  const { clientNotesBreadcrumbs } = useClientNotesBreadcrumbs();

  useEffect(() => {
    const loadClientNote = async () => {
      await dispatch(getClientNote(note_id));
    };

    loadClientNote();
    clientNotesBreadcrumbs();
  }, []);

  return (
    <>
      {clientNoteStatus === "success" ? (
        <ClientNoteList />
      ) : (
        <ClientNoteSkeleton />
      )}
    </>
  );
}
