import { ListOfTags } from "@/core/models/listOfTags.model";
import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, Heading } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { MdOutlineMoreVert } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  addTagsOnSelectedClientFilter,
  selectOriginalUrlFilters,
  setSelectedAdvancedFilters,
} from "../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClientsFilters } from "../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import { selectSelectedList } from "../store/listSelectedSlice";
import { SelectedListActions } from "./SelectedListActions";
import { AdvancedFiltersActionsWithUrlFilters } from "./advancedFilters/AdvancedFiltersActionsWithUrlFilters";

export function SelectedListHeaderWithFilters() {
  const originalUrlFilters = useSelector(selectOriginalUrlFilters);
  const selectedList = useSelector(selectSelectedList);

  const dispatch = useAppDispatch();

  const handleCleanFilters = async () => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClientsFilters(false));

    const advancedFilters = originalUrlFilters.filters.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = originalUrlFilters.filters.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    const attrOpts = originalUrlFilters.filters.attr_opts;
    if (attrOpts !== undefined) {
      await dispatch(setAttributesToFilter(attrOpts));
    } else {
      await dispatch(setDefaultAttributesToFilter());
    }

    await dispatch(emitEventToChangeClients(true));
  };

  const sharedNameIsAvailable = () => {
    return !!(
      originalUrlFilters &&
      originalUrlFilters.filters &&
      originalUrlFilters.filters.shared_name
    );
  };

  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <Flex css={{ gap: "$2", alignItems: "center" }}>
        <Heading css={{ color: "$gray900" }}>
          Lista Compartilhada:&nbsp;
          {sharedNameIsAvailable()
            ? originalUrlFilters.filters.shared_name
            : "Evento goVendas"}
        </Heading>
      </Flex>
      <Flex
        css={{
          gap: "$2",
          alignItems: "center",
        }}
      >
        <AdvancedFiltersActionsWithUrlFilters
          onCleanFilters={handleCleanFilters}
        />

        {!isEmpty(selectedList) && (
          <SelectedListActions
            icon={<MdOutlineMoreVert />}
            list={{} as ListOfTags}
            showFixButtonAction={false}
          />
        )}
      </Flex>
    </Flex>
  );
}
