import { UserCannotHaveAcess } from "@/components/UserCannotHaveAccess";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  Input,
  RightSlot,
} from "@gogeo-io/ui-library";
import { debounce } from "lodash";
import { FaList, FaUsers } from "react-icons/fa";
import { MdClose, MdDone, MdLockOpen, MdSearch } from "react-icons/md";
import { ActivateUsersModal } from "./ActivateUsersModal";
import { AddUserModal } from "./AddUserModal";
import { DeactivateUsersModal } from "./DeactivateUsersModal";
import { UsersCreateModal } from "./UsersCreateModal";
import { UsersPermissionsModal } from "./UsersPermissionsModal";

interface UserActionTopBarProps {
  onSearchUsers: (searchTerm: string) => Promise<void>;
}

export function UserActionTopBar({ onSearchUsers }: UserActionTopBarProps) {
  const handleInputChange = async (value: string) => {
    onSearchUsers(value);
  };

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <Input
        placeholder="Buscar"
        style={{ width: "240px" }}
        onChange={(e) => debounceOnChangeInput(e.target.value)}
        preffix={<MdSearch />}
      />

      <Flex css={{ alignItems: "center", gap: "$3" }}>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button color="primary" bordered>
              <Flex css={{ alignItems: "center", gap: "$2" }}>
                <FaList size={12} />
                Ações em lote
              </Flex>
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={5}>
            <UserCannotHaveAcess roles={["MANAGER", "SUPERVISOR", "SALESMAN"]}>
              <UsersCreateModal>
                <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                  Adicionar usuários em lote
                  <RightSlot>
                    <FaUsers />
                  </RightSlot>
                </DropdownMenuItem>
              </UsersCreateModal>
            </UserCannotHaveAcess>

            <UsersPermissionsModal>
              <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                Permissões em lote
                <RightSlot>
                  <MdLockOpen />
                </RightSlot>
              </DropdownMenuItem>
            </UsersPermissionsModal>

            <DeactivateUsersModal>
              <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                Desativar em lote
                <RightSlot>
                  <MdClose />
                </RightSlot>
              </DropdownMenuItem>
            </DeactivateUsersModal>

            <ActivateUsersModal>
              <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                Ativar em lote
                <RightSlot>
                  <MdDone />
                </RightSlot>
              </DropdownMenuItem>
            </ActivateUsersModal>
          </DropdownMenuContent>
        </DropdownMenu>

        <AddUserModal />
      </Flex>
    </Flex>
  );
}
