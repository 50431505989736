import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  Text,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export function TokenIsExpiringDialog() {
  const [dialogIsOpen, setDialogIsOpen] = useState(true);

  const auth = useAuth();
  const navigate = useNavigate();

  const handleSignIn = () => {
    auth.signinSilent();
    setDialogIsOpen(false);
  };

  const handleSignOut = () => {
    navigate("/logout");
  };

  return (
    <Dialog open={dialogIsOpen}>
      <DialogTrigger style={{ display: "none", visibility: "hidden" }} />
      <DialogContent
        style={{ width: "400px" }}
        onInteractOutside={(e) => e.preventDefault()}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <Flex
          css={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "$6",
          }}
        >
          <Heading>Sua Sessão Está Quase Expirando</Heading>

          <Text css={{ textIndent: "20px", textAlign: "justify" }}>
            Para manter sua sessão ativa, clique em{" "}
            <NoWrapSpan>&quot;Continuar&quot;</NoWrapSpan>. Caso deseje encerrar
            sua sessão, clique em{" "}
            <NoWrapSpan>&quot;Encerrar Sessão&quot;</NoWrapSpan>.
          </Text>

          <Flex css={{ gap: "$4", width: "100%" }}>
            <Button fullWidth bordered onClick={handleSignOut}>
              Encerrar Sessão
            </Button>
            <Button fullWidth onClick={handleSignIn}>
              Continuar
            </Button>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}

export const NoWrapSpan = styled.span`
  white-space: nowrap;
`;
