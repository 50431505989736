import { EmptyContent } from "@/components/EmptyContent";
import { LoadingEllipsis } from "@/components/LoadingEllipsis";
import { ClientsTableSkeletonRow } from "@/components/Skeleton/ClientsTableSkeletonRow";
import { ClientParamsResponse } from "@/core/models/client.model";
import { useAttendanceMixpanel } from "@/features/attendance/hooks/useAttendanceMixpanel";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import useOnScreen from "@/hooks/useOnScreen";
import { Box } from "@gogeo-io/ui-library";
import { Table } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface ClientsTableProps {
  columns: any;
  data: any;
  onPageChange: (page: number) => void;
  currentPage: number;
  onIncreasePageNumber: () => void;
  hasMorePages: boolean;
  status: string;
}

export function ClientsTable({
  columns,
  data,
  onPageChange,
  currentPage,
  onIncreasePageNumber,
  hasMorePages = true,
  status,
}: ClientsTableProps) {
  const selectedList = useSelector(selectSelectedList);

  const { clientClickedOnListEvent } = useAttendanceMixpanel();
  const navigate = useNavigate();
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const generateRowKey = (record: ClientParamsResponse) => {
    return `${record.client.index}-${record.client.id}`;
  };

  const showClient = (record: ClientParamsResponse) => {
    if (!isEmpty(selectedList)) {
      clientClickedOnListEvent(selectedList.name, record.client.id);
    }
    navigate(`/info/client/${record.client.id}/client-info`);
  };

  useEffect(() => {
    if (currentPage !== 0) {
      if (hasMorePages) {
        onPageChange(currentPage);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (hasMorePages) {
      onIncreasePageNumber();
    }
  }, [isVisible]);

  return (
    <>
      {!isEmpty(data) ? (
        <Box css={{ overflow: "auto" }}>
          <Table
            columns={columns}
            dataSource={data}
            loading={status === "loading"}
            pagination={false}
            onRow={(record) => ({
              onClick: () => showClient(record as ClientParamsResponse),
            })}
            rowKey={generateRowKey}
            sticky={{ offsetHeader: 0 }}
            scroll={{ x: "100%" }}
          />
          <div ref={ref}>
            {hasMorePages ? <ClientsTableSkeletonRow /> : null}
          </div>
        </Box>
      ) : (
        <>
          {status === "fulfilled" ? (
            <>
              <EmptyContent size="medium">
                Nenhum cliente encontrado
              </EmptyContent>
              <div ref={ref}></div>
            </>
          ) : (
            <LoadingComponent>
              <LoadingEllipsis />
            </LoadingComponent>
          )}
        </>
      )}
    </>
  );
}

export const LoadingComponent = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
