import { Address } from "@/core/models/client.model";
import { useMask } from "./useMask";

export const useFormatAddress = (address: Address): string => {
  const { useCepMask } = useMask();

  const thoroughfare = address.thoroughfare ? address.thoroughfare + "," : "";
  const house_number = address.house_number ? address.house_number + "," : "";
  const complement = address.complement ? address.complement + "," : "";
  const neighborhood = address.neighborhood ? address.neighborhood + "," : "";
  const post_code = address.post_code ? useCepMask(address.post_code) : "";

  return `${thoroughfare} ${house_number} ${complement} ${neighborhood} CEP: ${post_code}`;
};
