import { AuthProviderProps } from "react-oidc-context";

const getBaseUrl = (): string => {
  return window.location.origin;
};

const getCurrentUrl = (): string => {
  return window.location.href;
};

export const oidcConfig: AuthProviderProps = {
  authority: import.meta.env.VITE_KEYCLOAK_AUTHORITY,
  client_id: import.meta.env.VITE_KEYCLOAK_CLIENT,
  scope: "openid profile email",
  redirect_uri: getCurrentUrl(),
  post_logout_redirect_uri: getBaseUrl(),
  onSigninCallback: (_user: any | void): void => {
    window.history.replaceState({}, document.title, getCurrentUrl());
  },
  revokeTokenTypes: ["refresh_token", "access_token"],
  revokeTokensOnSignout: true,
  automaticSilentRenew: true,
  monitorSession: true,
};
