import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function GenericMultiSelectFilterContentSkeleton() {
  const timesToRepeat = Array.from(Array(5).keys());

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$4",
      }}
    >
      <Flex
        css={{
          flexDirection: "column",
          gap: "0.5rem",
          maxHeight: "300px",
          overflowY: "scroll",
        }}
      >
        {timesToRepeat.map((c) => {
          return (
            <Flex key={c} css={{ gap: "0.5rem", alignItems: "center" }}>
              <RLSSkeleton width={25} height={25} />

              <RLSSkeleton width={120} height={25} />
            </Flex>
          );
        })}
      </Flex>

      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <RLSSkeleton width={120} height={30} />
      </Flex>
    </Flex>
  );
}
