import { useFormatDate } from "./useFormatDate";

export const useTranslateDateFilter = () => {
  const { formatBasicDateWithSlash, newDateBasedOnDateWithoutTimezone } =
    useFormatDate();

  const getTranslatedFields = (suffix: string, plural?: boolean) => {
    if (suffix === "month" || suffix === "months") {
      return plural ? "meses" : "mês";
    } else if (suffix === "year" || suffix === "years") {
      return plural ? "anos" : "ano";
    } else if (suffix === "day" || suffix === "days") {
      return plural ? "dias" : "dia";
    } else if (suffix === "week" || suffix === "weeks") {
      return plural ? "semanas" : "semana";
    } else {
      return "mês";
    }
  };

  return (date: string) => {
    const dateNameRegex =
      /(before|next|inthis)(\d*)(minute[s]?|hour[s]?|day[s]?|week[s]?|month[s]?|year[s]?)(\~?)/;
    const dateFormatRegex = /(\d{4}-\d{2}-\d{2})\~(\d{4}-\d{2}-\d{2})/;

    if (dateNameRegex.test(date)) {
      const match = date.match(dateNameRegex);

      const preffix = match[1];
      const value = parseInt(match[2], 10);
      const suffix = match[3];

      if (preffix === "before") {
        const formattedDate = `${value} ${getTranslatedFields(
          suffix,
          value > 1
        )} ${value === 1 ? "anterior" : "anteriores"}`;

        return formattedDate;
      } else if (preffix === "next") {
        const formattedDate = `antes de ${value} ${getTranslatedFields(
          suffix
        )}`;

        return formattedDate;
      } else if (preffix === "inthis" && value === 1) {
        const formattedDate = `No(a) último(a) ${getTranslatedFields(suffix)}`;

        return formattedDate;
      } else if (preffix === "inthis" && value > 1) {
        const formattedDate = `Nos(as) últimos(as) ${value} ${getTranslatedFields(
          suffix,
          value > 1
        )}`;

        return formattedDate;
      }
    } else if (dateFormatRegex.test(date)) {
      const match = date.match(dateFormatRegex);

      const formattedDate = `Entre ${formatBasicDateWithSlash(
        newDateBasedOnDateWithoutTimezone(match[1])
      )} & ${formatBasicDateWithSlash(
        newDateBasedOnDateWithoutTimezone(match[2])
      )}`;

      return formattedDate;
    }

    return date;
  };
};
