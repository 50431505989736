import { Client } from "@core/models/client.model";
import {
  Caption,
  Flex,
  Heading,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import styled from "styled-components";
import { AvatarOfClientWithHoverInfo } from "./AvatarOfClientWithHoverInfo";

interface ClientInfoProps {
  client: Client;
}

export function ClientProfileWithExclusionStatus({ client }: ClientInfoProps) {
  return (
    <Container>
      <AvatarOfClientWithHoverInfo client={client} />

      <Flex css={{ flexDirection: "column", width: "100%", gap: "0rem" }}>
        <Tooltip>
          <TooltipTrigger
            style={{ background: "transparent", textAlign: "left" }}
          >
            <Heading
              size="subtitle1"
              css={{
                textTransform: "capitalize",
                maxWidth: "200px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {client.name.toLowerCase()}
            </Heading>
          </TooltipTrigger>
          <TooltipContent aria-label="Título da coluna">
            {client.name}
          </TooltipContent>
        </Tooltip>

        {client.fantasy_name && (
          <Tooltip>
            <TooltipTrigger
              style={{ background: "transparent", textAlign: "left" }}
            >
              <Text
                css={{
                  color: "$gray500",
                  textTransform: "capitalize",
                  maxWidth: "200px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {client.fantasy_name.toLowerCase()}
              </Text>
            </TooltipTrigger>
            <TooltipContent aria-label="Título da coluna">
              {client.fantasy_name}
            </TooltipContent>
          </Tooltip>
        )}

        <Caption bold css={{ color: "$gray500" }}>
          {client.id}
        </Caption>

        <Flex
          css={{
            flexDirection: "row",
            gap: "1rem",
            mt: "0.2rem",
            justifyContent: "space-between",
          }}
        >
          {!client.exclusion ? (
            <Tooltip>
              <TooltipTrigger style={{ background: "transparent" }}>
                <ExcludedBadge $bgColor="primary500">A</ExcludedBadge>
              </TooltipTrigger>
              <TooltipContent aria-label="Cliente Ativo">Ativo</TooltipContent>
            </Tooltip>
          ) : (
            <Tooltip>
              <TooltipTrigger style={{ background: "transparent" }}>
                <ExcludedBadge $bgColor="danger500">E</ExcludedBadge>
              </TooltipTrigger>
              <TooltipContent aria-label="Cliente Ativo">
                Excluído
              </TooltipContent>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[2]};
  align-items: center;
`;

interface ExcludedBadgeProps {
  $bgColor: "primary500" | "danger500";
}

const ExcludedBadge = styled.div<ExcludedBadgeProps>`
  display: flex;
  align-items: center;
  padding: 0.1rem 0.3rem;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors[props.$bgColor]};
  font-weight: bold;
  font-size: 0.875rem;
`;
