import {
  Flex,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Text,
} from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import DOMPurify from "dompurify";
import { useNormalizeTextWithTokens } from "../../hooks/useNormalizeTextWithTokens";

interface StatusProps {
  value: string;
}

export function MessageCell({ value }: StatusProps) {
  const normalizeText = useNormalizeTextWithTokens();

  return (
    <Flex css={{ paddingRight: "1rem" }}>
      {value ? (
        <HoverCard openDelay={500} closeDelay={150}>
          <HoverCardTrigger>
            <G_UI.Paragraph
              dangerouslySetInnerHTML={{
                __html: normalizeText(DOMPurify.sanitize(value)),
              }}
            />
          </HoverCardTrigger>

          <HoverCardContent
            align="start"
            css={{ width: "450px", lineHeight: "1.6" }}
            sideOffset={-5}
          >
            <G_UI.Paragraph
              dangerouslySetInnerHTML={{
                __html: normalizeText(DOMPurify.sanitize(value)),
              }}
            />
          </HoverCardContent>
        </HoverCard>
      ) : (
        <Text>Valor não encontrado</Text>
      )}
    </Flex>
  );
}
