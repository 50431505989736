import styled from "styled-components";

export const ReminderAddButton = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.colors.primary500};
  color: ${({ theme }) => theme.colors.white};
  font-size: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
`;
