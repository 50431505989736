import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import {
  AttendanceRecord,
  History,
  HistoryPaginated,
} from "@/core/models/history.model";
import { PaginatedRequestBaseProps } from "@/core/models/paginatedRequest.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface HistoryState {
  history: History[];
  has500error: boolean;
  hasMorePages: boolean;
  status: "loading" | "success" | "failed";
}

const initialState = {
  history: [],
  has500error: false,
  hasMorePages: false,
  status: "loading",
} as HistoryState;

export interface GetHistoryProps extends PaginatedRequestBaseProps {
  client_id: string;
  filters?: AdvancedFilter[];
}

export const getHistory = createAsyncThunk(
  "history/getHistory",
  async (
    { client_id, filters = [], page, size }: GetHistoryProps,
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `/followup/by-clients/${client_id}?page=${page}&size=${size}`,
        filters
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const getHistoryPage = createAsyncThunk(
  "history/getHistoryPage",
  async (
    { client_id, filters = [], page, size }: GetHistoryProps,
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `/followup/by-clients/${client_id}?page=${page}&size=${size}`,
        filters
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

interface UpdateItemOnHistoryProps {
  attendance_record: AttendanceRecord;
  visit_date: string;
}

export const historySlice = createSlice({
  name: "historySlice",
  initialState,
  reducers: {
    updateItemOnHistory(
      state,
      action: PayloadAction<UpdateItemOnHistoryProps>
    ) {
      state.history
        .find((history) => history.visit_date === action.payload.visit_date)
        .history_entries.forEach((entrie) =>
          entrie.attendance_record.forEach((record) =>
            record.id === action.payload.attendance_record.id
              ? action.payload.attendance_record
              : record
          )
        );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHistory.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(
      getHistory.fulfilled,
      (state, action: PayloadAction<HistoryPaginated>) => {
        state.hasMorePages = true;

        if (action.payload.history.length === 0) {
          state.hasMorePages = false;
          state.history = [];
        } else {
          state.history = action.payload.history;
        }
        state.status = "success";
        state.has500error = false;
      }
    );
    builder.addCase(
      getHistory.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the history");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(getHistoryPage.pending, (state, action) => {
      state.has500error = false;
    });
    builder.addCase(
      getHistoryPage.fulfilled,
      (state, action: PayloadAction<HistoryPaginated>) => {
        state.hasMorePages = true;

        if (action.payload.history.length === 0) {
          state.hasMorePages = false;
        } else {
          state.history = [...state.history, ...action.payload.history];
        }
        state.has500error = false;
      }
    );
    builder.addCase(
      getHistoryPage.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the history");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

///////////////////////////////////////

export const selectHistory = (state: RootState) => state.historyState.history;
export const selectHistoryStatus = (state: RootState) =>
  state.historyState.status;
export const selectHistoryHasError500 = (state: RootState) =>
  state.historyState.has500error;
export const selectHistoryHasMorePages = (state: RootState) =>
  state.historyState.hasMorePages;

///////////////////////////////////////

export const { updateItemOnHistory } = historySlice.actions;

///////////////////////////////////////

export const historyReducer = historySlice.reducer;
