export const TEXT_EDITOR_HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

export const TEXT_EDITOR_LIST_TYPES = ["numbered-list", "bulleted-list"];

export const TEXT_EDITOR_TEXT_ALIGN_TYPES = [
  "left",
  "center",
  "right",
  "justify",
];
