import {
  BusinessAssociateService,
  BusinessAssociateServices,
} from "@/core/models/business-associate.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Caption, Flex, Heading, Text } from "@gogeo-io/ui-library";
import { IoMdCloseCircle } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  setSelectedBusinessAssociate,
  setSelectedBusinessAssociateService,
} from "../store/businessAssociatesServicesConfigSlice";

interface BusinessAssociatesListItemProps {
  service: BusinessAssociateService;
  businessAssociate: BusinessAssociateServices;
}

export function BusinessAssociatesServiceConfigListItem({
  service,
  businessAssociate,
}: BusinessAssociatesListItemProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigate = async () => {
    await dispatch(setSelectedBusinessAssociateService(service));
    await dispatch(setSelectedBusinessAssociate(businessAssociate));
    navigate(
      `/profile/integrations/services/${businessAssociate.id}/service/${service.id}`
    );
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        width: "300px",
        gap: "$4",
        cursor: "pointer",
        background: "$gray50",
        borderRadius: "6px",
        boxShadow: "$sm",
        padding: "$4",
      }}
      onClick={() => handleNavigate()}
    >
      <Heading size="subtitle2" css={{ fontWeight: "bold" }}>
        {service.name}
      </Heading>
      <Text size="body2">
        {service.description && service.description.length > 0
          ? service.description
          : `Traga o serviço ${service.name} para sua conta`}
      </Text>

      <Flex
        css={{
          flexDirection: "column",
          gap: "$4",
          height: "100%",
          justifyContent: "flex-end",
        }}
      >
        {service.enabled ? (
          <Flex css={{ gap: "$2", alignItems: "center", color: "$primary500" }}>
            <IoCheckmarkCircle color="inherit" />
            <Caption css={{ color: "$gray500" }}>Habilitado</Caption>
          </Flex>
        ) : (
          <Flex css={{ gap: "$2", alignItems: "center", color: "$gray500" }}>
            <IoMdCloseCircle color="inherit" />
            <Caption css={{ color: "$gray500" }}>Desabilitado</Caption>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
