import { LabelWithTooltip } from "@/components/LabelWithTooltip";

export function PublicNoteLabel() {
  return (
    <LabelWithTooltip
      title="Compartilhada"
      description="Nota compartilhada com os integrantes de sua empresa"
      schemeColor="primary"
    />
  );
}
