import { EmptyContent } from "@/components/EmptyContent";
import { User } from "@/core/models/user.model";
import { getUsersRelatedToUser } from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Box,
  Button,
  Caption,
  Checkbox,
  Flex,
  Input,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import {
  setReportFiltersStatus,
  updateReportFilter,
} from "../store/reportSlice";
import { ReportSalesmanFilter } from "./ReportSalesmanFilter";

interface ReportSupervisorsFilterProps {
  salesmans: User[];
  supervisors: User[];
  selectedSalesmans: User[];
  selectedSupervisors: User[];
  onSetSalesmans: (user: User[]) => void;
  onSetSelectedSupervisors: (user: User[]) => void;
  onSetSelectedSalesmans: (user: User[]) => void;
}

export function ReportSupervisorsFilter({
  salesmans,
  supervisors,
  selectedSalesmans,
  selectedSupervisors,
  onSetSalesmans,
  onSetSelectedSupervisors,
  onSetSelectedSalesmans,
}: ReportSupervisorsFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [auxSupervisors, setAuxSupervisors] = useState(supervisors);
  const [auxSelectedSupervisors, setAuxSelectedSupervisors] =
    useState(selectedSupervisors);

  const dispatch = useAppDispatch();

  const handleInputChange = (value: string) => {
    const searchTerm = value.toLowerCase();
    const filteredUsers = supervisors.filter((user) =>
      user.name.toLowerCase().includes(searchTerm)
    );
    setAuxSupervisors(filteredUsers);
  };

  const cleanChanges = () => {
    setAuxSupervisors(supervisors);
    setAuxSelectedSupervisors(supervisors);
    onSetSalesmans(salesmans);
    onSetSelectedSalesmans(salesmans);
  };

  const handleSelectedSupervisorChange = async (
    checked: boolean,
    user: User
  ) => {
    let users: User[] = [];
    if (!checked) {
      users = auxSelectedSupervisors.filter((sup) => sup.id !== user.id);
    } else {
      users = [...auxSelectedSupervisors, user];
    }

    setAuxSelectedSupervisors(users);
  };

  const handleApply = async () => {
    setIsOpen(false);

    await dispatch(setReportFiltersStatus("not-filled"));

    onSetSelectedSupervisors(auxSelectedSupervisors);

    let uniqueSalesmans: User[] = [];

    for (const supervisor of auxSelectedSupervisors) {
      await dispatch(
        getUsersRelatedToUser({
          user_id: supervisor.id,
        })
      ).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const salesmans = res.payload;
          uniqueSalesmans = [
            ...uniqueSalesmans,
            ...salesmans.filter(
              (salesman) => !uniqueSalesmans.some((s) => s.id === salesman.id)
            ),
          ];
          await dispatch(
            updateReportFilter({
              filter_field: "salesmans",
              value: uniqueSalesmans,
            })
          );
        }
      });
    }

    onSetSalesmans(uniqueSalesmans);
    onSetSelectedSalesmans(uniqueSalesmans);
    await dispatch(setReportFiltersStatus("filled"));
    setIsOpen(false);
  };

  return (
    <>
      <Flex css={{ gap: "$2", alignItems: "center" }}>
        {auxSelectedSupervisors.length > 0 && (
          <Flex css={{ gap: "$2", alignItems: "center" }}>
            <Popover open={isOpen} onOpenChange={(v) => setIsOpen(v)}>
              <PopoverTrigger style={{ background: "transparent" }}>
                <FilterContainer>
                  <Flex
                    css={{
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Caption css={{ color: "$gray500" }}>
                      Supervisores selecionados:
                    </Caption>
                    <Caption css={{ color: "$gray700", ml: "4px" }}>
                      {auxSelectedSupervisors.length}
                    </Caption>
                  </Flex>
                </FilterContainer>
              </PopoverTrigger>
              <PopoverContent
                align="center"
                css={{
                  maxHeight: "450px",
                  maxWidth: "600px",
                  width: "100%",
                  borderRadius: "6px",
                  boxShadow: "$md",
                }}
              >
                <PopoverClose aria-label="Close">
                  <MdClose />
                </PopoverClose>

                <Flex css={{ flexDirection: "column", gap: "$4" }}>
                  <Flex css={{ flexDirection: "column", gap: "$1" }}>
                    <Caption>Procurar supervisor</Caption>
                    <Input
                      placeholder="Ex. Supervisor 4"
                      onChange={(e) => handleInputChange(e.target.value)}
                    />
                  </Flex>
                  <Box
                    css={{
                      minWidth: "300px",
                      maxHeight: "300px",
                      overflowY: "scroll",
                    }}
                  >
                    <Flex css={{ flexDirection: "column", gap: "$2" }}>
                      {auxSupervisors.length > 0 ? (
                        <>
                          {auxSupervisors.map((supervisor) => {
                            return (
                              <Flex key={supervisor.id} css={{ gap: "$2" }}>
                                <Checkbox
                                  checked={auxSelectedSupervisors.includes(
                                    supervisor
                                  )}
                                  onCheckedChange={(e) =>
                                    handleSelectedSupervisorChange(
                                      Boolean(e),
                                      supervisor
                                    )
                                  }
                                ></Checkbox>
                                <Text>{supervisor.name}</Text>
                              </Flex>
                            );
                          })}
                        </>
                      ) : (
                        <EmptyContent size="small">
                          Sem usuários associados
                        </EmptyContent>
                      )}
                    </Flex>
                  </Box>

                  <Flex
                    css={{
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      gap: "$2",
                    }}
                  >
                    <Button bordered onClick={cleanChanges}>
                      Cancelar alterações
                    </Button>
                    <Button onClick={handleApply}>Aplicar</Button>
                  </Flex>
                </Flex>
              </PopoverContent>
            </Popover>
            <Text css={{ color: "$gray500" }}>-</Text>
          </Flex>
        )}
        <ReportSalesmanFilter
          salesmans={salesmans}
          selectedSalesmans={selectedSalesmans}
          onSetSelectedSalesmans={onSetSelectedSalesmans}
        />
      </Flex>
    </>
  );
}

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[1]} 0.5rem;
  border-radius: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.gray100};
  cursor: pointer;

  svg {
    color: ${(props) => props.theme.colors.gray500};
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;
