import {
  ReminderDataControl,
  ReminderRequest,
} from "@/core/models/reminder.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatDate } from "@/hooks/useFormatDate";
import { Button, Input, Text } from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import { isPast } from "date-fns";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useReminderMixpanel } from "../hooks/useReminderMixpanel";
import { addReminderOnState, createReminder } from "../store/reminderSlice";
import UI from "../ui";
import { ReminderPickDate } from "./ReminderPickDate";
import { ReminderPickHour } from "./ReminderPickHour";

const schema = yup.object().shape({
  text: yup.string().required("O nome do lembrete é obrigatório."),
  day: yup.string().required("A data do lembrete é obrigatória."),
  hour: yup.string().required("A hora do lembrete é obrigatória."),
});

interface ReminderFormProps {
  handleCanSeeReminderForm: () => void;
}

export function ReminderForm({ handleCanSeeReminderForm }: ReminderFormProps) {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { createdReminderEvent, createdReminderFailEvent } =
    useReminderMixpanel();
  const { fullDateWithTimezone, formatBasicDate } = useFormatDate();
  const { handleSubmit, control, setError, setValue } =
    useForm<ReminderDataControl>({
      mode: "onBlur",
      resolver: yupResolver(schema),
      defaultValues: {
        day: formatBasicDate(new Date()),
      },
    });

  const onSubmit: SubmitHandler<ReminderDataControl> = async (
    data: ReminderDataControl
  ): Promise<void> => {
    setIsLoading(true);

    if (isPast(new Date(`${data.day}:${data.hour}`))) {
      setError("hour", {
        message:
          "Não é possível criar um lembrete para uma data e hora anterior a atual. Por favor selecione novamente...",
      });
      setIsLoading(false);
    } else {
      const reminderToCreate: ReminderRequest = {
        text: data.text,
        date: fullDateWithTimezone(`${data.day}T${data.hour}`),
      };
      await dispatch(createReminder(reminderToCreate)).then(async (obj) => {
        if (obj.meta.requestStatus === "fulfilled") {
          await dispatch(addReminderOnState(obj.payload));
          toast.success("Lembrete criado com sucesso");
          createdReminderEvent(reminderToCreate);
          handleCanSeeReminderForm();
        }
      });
      setIsLoading(false);
    }
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível criar lembrete");
      createdReminderFailEvent(errors);
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <UI.ReminderForm noValidate>
        <Controller
          control={control}
          name="text"
          render={({ field, fieldState: { error } }) => (
            <div>
              <Text css={{ marginBottom: "$2" }}>
                Digite o nome do lembrete:
              </Text>
              <Input
                placeholder="Nome do lembrete"
                type="text"
                isFullWidth
                maxLength={40}
                hasError={!!error}
                errorMessage={error?.message}
                {...field}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name="day"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <ReminderPickDate
                  onChange={onChange}
                  value={value}
                  hasError={!!error}
                  errorMessage={error?.message}
                />
              </>
            );
          }}
        />

        <Controller
          control={control}
          name="hour"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <ReminderPickHour onChange={onChange} />
              </>
            );
          }}
        />

        <UI.ReminderFormButtonWrapper>
          <Button
            color="danger"
            variant="soft"
            onClick={handleCanSeeReminderForm}
          >
            Cancelar
          </Button>
          <Button
            isLoading={isLoading}
            onClick={() => handleSubmit(onSubmit, onError)()}
          >
            Criar Lembrete
          </Button>
        </UI.ReminderFormButtonWrapper>
      </UI.ReminderForm>
    </div>
  );
}
