import styled from "styled-components";

export const AdvancedFilterListItem = styled.div`
  background: ${(props) => props.theme.colors.gray50};
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 150ms ease-out;
  border-radius: 6px;
`;
