import { Tooltip, TooltipContent, TooltipTrigger } from "@gogeo-io/ui-library";
import { ReactNode } from "react";

interface FilterContainer {
  description: string;
  triggerComponent: ReactNode;
}

export function AdvancedFilterContainer({
  description,
  triggerComponent,
}: FilterContainer) {
  return (
    <Tooltip>
      <TooltipTrigger style={{ background: "transparent" }}>
        {triggerComponent}
      </TooltipTrigger>
      <TooltipContent aria-label={description}>{description}</TooltipContent>
    </Tooltip>
  );
}
