import styled from "styled-components";

export const ReminderContainer = styled.div<{
  canSeeReminderForm;
}>`
  height: 100%;
  display: flex;
  flex-direction: ${({ canSeeReminderForm }) =>
    canSeeReminderForm ? "row" : "column"};
  gap: ${({ theme }) => theme.space[6]};

  .left {
    width: 400px;
    display: flex;
    flex-direction: column;
    padding-right: ${({ canSeeReminderForm }) => canSeeReminderForm && "20px"};
    border-right: ${({ canSeeReminderForm, theme }) =>
      canSeeReminderForm && `1px solid ${theme.colors.gray200}`};
  }

  .right {
    width: 400px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.space[5]};
  }
`;
