import { FileMetadata } from "@/core/models/file.model";
import { getFile } from "@/core/store/uploadFile/uploadFileSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFile } from "@/hooks/useFile";
import {
  Caption,
  Flex,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { useAttachmentIcon } from "../../hooks/useAttachmentIcon";

interface ClientNoteFilesProps {
  files: FileMetadata[];
}

export function ClientNoteFiles({ files }: ClientNoteFilesProps) {
  const dispatch = useAppDispatch();
  const getAttachmentIcon = useAttachmentIcon();
  const { downloadFileByUrl } = useFile();

  const handleDowloadFile = async (
    fileId: number,
    filename: string,
    type: string
  ) => {
    await dispatch(getFile({ fileId })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        downloadFileByUrl(res.payload.url, type, filename);
      }
    });
  };

  return (
    <Flex
      css={{
        flexWrap: "wrap",
        gap: "$2",
        alignItems: "center",
      }}
    >
      {files && files.length > 0 ? (
        files.map((file) => {
          return (
            <Tooltip key={file.id}>
              <TooltipTrigger style={{ background: "transparent" }}>
                <Flex
                  css={{
                    fontSize: "20px",
                    color: "$gray600",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleDowloadFile(file.id, file.filename, file.type)
                  }
                >
                  {getAttachmentIcon(file.filename)}
                </Flex>
              </TooltipTrigger>
              <TooltipContent aria-label="Arquivos da sua anotação">
                {file.filename}
              </TooltipContent>
            </Tooltip>
          );
        })
      ) : (
        <Caption css={{ color: "$gray500" }}>Sem arquivos na nota</Caption>
      )}
    </Flex>
  );
}
