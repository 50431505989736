import { Params } from "@/core/models/params.model";
import { Text } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";

interface PreferedWeeksProps {
  params: Params;
  isBlocked?: boolean;
}

export function PreferedWeeks({
  params,
  isBlocked = false,
}: PreferedWeeksProps) {
  return (
    <Text css={{ color: "$gray500" }}>
      Semana de atendimento no mês:
      <strong>
        {!isEmpty(params) && !isEmpty(params.week_pref) ? (
          <>{` ${params.week_pref.range} ${params.week_pref.value}`}</>
        ) : (
          " Não Possui"
        )}
      </strong>
    </Text>
  );
}
