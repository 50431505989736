import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Flex,
  Text,
} from "@gogeo-io/ui-library";
import { BookmarkBorder, InsertEmoticon } from "@mui/icons-material";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectPreDefinedMessageTokens } from "../store/preDefinedMessagesTokensSlice";
import UI from "../ui";

interface PreDefinedMessageTextAreaProps {
  newMessage: string;
  onNewMessageChange: (value: string) => void;
}

export function PreDefinedMessageTextArea({
  newMessage,
  onNewMessageChange,
}: PreDefinedMessageTextAreaProps) {
  const [iconModalIsOpen, setIconModalIsOpen] = useState(false);

  const preDefinedMessagesTokens = useSelector(selectPreDefinedMessageTokens);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleOnChangeTextArea = (value: string) => {
    onNewMessageChange(value);
  };

  const insertTag = (tag: string) => {
    const selectionStart = textareaRef.current.selectionStart;
    const selectionEnd = textareaRef.current.selectionEnd;

    const message =
      newMessage.substring(0, selectionStart) +
      `{{${tag}}}` +
      newMessage.substring(selectionEnd, newMessage.length);

    onNewMessageChange(message);
  };

  const insertEmoji = (emoji: string) => {
    setIconModalIsOpen(false);

    const selectionStart = textareaRef.current.selectionStart;
    const selectionEnd = textareaRef.current.selectionEnd;

    const message =
      newMessage.substring(0, selectionStart) +
      emoji +
      newMessage.substring(selectionEnd, newMessage.length);

    onNewMessageChange(message);
  };

  return (
    <UI.PreDefinedMessageTextAreaContainer>
      <UI.PreDefinedMessageTextArea
        ref={textareaRef}
        placeholder="Digite sua mensagem"
        value={newMessage}
        onChange={(e) => handleOnChangeTextArea(e.target.value)}
      />
      <UI.PreDefinedMessageTextAreaActions>
        <DropdownMenu open={iconModalIsOpen} onOpenChange={setIconModalIsOpen}>
          <DropdownMenuTrigger asChild>
            <Flex
              css={{
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <InsertEmoticon />
            </Flex>
          </DropdownMenuTrigger>

          <DropdownMenuContent sideOffset={5} className="emoji-picker">
            <Picker
              data={data}
              onEmojiSelect={(e) => insertEmoji(e.native)}
              locale="pt"
              previewPosition="none"
              searchPosition="none"
              theme="light"
            />
          </DropdownMenuContent>
        </DropdownMenu>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Flex
              css={{
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <BookmarkBorder />
              <Text>Inserir Token</Text>
            </Flex>
          </DropdownMenuTrigger>

          <DropdownMenuContent
            sideOffset={5}
            style={{
              zIndex: "99999",
              height: "auto",
              maxHeight: "400px",
              overflow: "auto",
            }}
          >
            {preDefinedMessagesTokens.map((tag, idx, tags) => {
              if (idx + 1 === tags.length) {
                return (
                  <DropdownMenuItem
                    key={tag.label}
                    onClick={(e) => insertTag(tag.label)}
                  >
                    {tag.label}
                  </DropdownMenuItem>
                );
              } else {
                return (
                  <div key={tag.label}>
                    <DropdownMenuItem onClick={() => insertTag(tag.label)}>
                      {tag.label}
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                  </div>
                );
              }
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      </UI.PreDefinedMessageTextAreaActions>
    </UI.PreDefinedMessageTextAreaContainer>
  );
}
