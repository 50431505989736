import { UserHandler } from "@/core/models/user.model";
import {
  addUser,
  removeAllUserToBatchActions,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { MdPersonAdd } from "react-icons/md";
import { toast } from "react-toastify";
import { useUsersMixpanel } from "../hooks/useUsersMixpanel";
import { emitEventToChangeUsers } from "../store/emitEventToChangeUsersSlice";
import { AddUpdateUserModalContent } from "./AddUpdateUserModalContent";

export function AddUserModal() {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { createUserEvent } = useUsersMixpanel();

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const closeDialog = () => setDialogIsOpen(false);

  const createUser = async (user: UserHandler) => {
    await dispatch(emitEventToChangeUsers(false));

    await dispatch(addUser({ user })).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        createUserEvent(user);
        toast.success(`Usuário ${user.name} adicionado com sucesso`);
        await dispatch(removeAllUserToBatchActions());
      }
    });

    await dispatch(emitEventToChangeUsers(true));
  };

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger>
        <Button>
          <Flex css={{ alignItems: "center", gap: "$2" }}>
            <MdPersonAdd size={16} />
            Adicionar usuário
          </Flex>
        </Button>
      </DialogTrigger>
      <DialogContent style={{ maxHeight: "90vh", overflowY: "auto" }}>
        <DialogTitle>Adicionar Usuário</DialogTitle>
        <AddUpdateUserModalContent
          onCloseDialog={closeDialog}
          onSubmitForm={createUser}
          submitButtonText="Adicionar Usuário"
        />
      </DialogContent>
    </Dialog>
  );
}
