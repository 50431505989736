import { SelectOption } from "@/core/models/advanced-filters.model";
import { Button, Checkbox, Flex, Text } from "@gogeo-io/ui-library";

interface GenericMultiSelectFilterContentProps {
  options: SelectOption[];
  optionsSelected: SelectOption[];
  onCheckboxChange: (isChecked: boolean, option: SelectOption) => void;
  onApplyFilter: () => void;
}

export function GenericMultiSelectFilterContent({
  options,
  optionsSelected,
  onCheckboxChange,
  onApplyFilter,
}: GenericMultiSelectFilterContentProps) {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$4",
      }}
    >
      <Flex
        css={{
          flexDirection: "column",
          gap: "0.5rem",
          maxHeight: "300px",
          overflowY: "scroll",
        }}
      >
        {options.map((attribute) => {
          return (
            <Flex
              key={attribute.id}
              css={{ gap: "0.5rem", alignItems: "center" }}
            >
              <Checkbox
                checked={
                  optionsSelected.length > 0
                    ? optionsSelected.filter((a) => a.id === attribute.id)
                        .length > 0
                    : false
                }
                onCheckedChange={(e) => onCheckboxChange(Boolean(e), attribute)}
              />

              <Text>{attribute.value}</Text>
            </Flex>
          );
        })}
      </Flex>

      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <Button onClick={onApplyFilter}>Aplicar</Button>
      </Flex>
    </Flex>
  );
}
