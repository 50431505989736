import { gvTheme, Text } from "@gogeo-io/ui-library";
import { CalendarMonth } from "@mui/icons-material";
import G_UI from "@ui/index";

interface ByUpAndDownProps {
  text: string;
}

export function ByCalendar({ text }: ByUpAndDownProps) {
  return (
    <G_UI.ProductTagLabel>
      <CalendarMonth style={{ color: gvTheme.colors.primary500 }} />
      <Text>{text}</Text>
    </G_UI.ProductTagLabel>
  );
}
