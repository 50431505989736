import {
  Caption,
  Flex,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";

interface LabelWithTooltipProps {
  title: string;
  description: string;
  schemeColor?: string;
}

export function LabelWithTooltip({
  title,
  description,
  schemeColor = "primary",
}: LabelWithTooltipProps) {
  return (
    <Tooltip>
      <TooltipTrigger
        style={{ background: "transparent", width: "fit-content" }}
      >
        <Flex css={{ gap: "$1", alignItems: "center" }}>
          <Caption
            css={{
              background: `$${schemeColor}100`,
              color: `$${schemeColor}500`,
              padding: "$1 $3",
              borderRadius: "2px",
              fontWeight: "bold",
            }}
          >
            {title}
          </Caption>
        </Flex>
      </TooltipTrigger>
      <TooltipContent aria-label={description}>{description}</TooltipContent>
    </Tooltip>
  );
}
