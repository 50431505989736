import { User, UserHandler, UserRole } from "@/core/models/user.model";
import { Button, Flex, Input, Text } from "@gogeo-io/ui-library";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";
import { AddUserSelectRole } from "./cells/AddUserSelectRole";

const schema = yup.object().shape({
  name: yup.string().required("O nome do usuário é obrigatório."),
  role: yup
    .mixed<UserRole>()
    .oneOf(
      ["SALESMAN", "ADMIN", "MANAGER", "SUPERVISOR"],
      "Selecione um cargo válido"
    )
    .required("A função é obrigatória"),
  salesman_id: yup.string().required("O código do ERP é obrigatório."),
  phone: yup.string().required("O telefone é obrigatório."),
  password: yup.string(),
  confirm_password: yup.string().when("password", (password, schema) => {
    return password
      ? schema
          .required("A confirmação da senha é obrigatória")
          .oneOf([yup.ref("password")], "As senhas não batem")
      : schema;
  }),
});

interface AddUpdateUserModalContentProps {
  submitButtonText: string;
  onCloseDialog: () => void;
  onSubmitForm: (user: UserHandler) => Promise<void>;
  userToUpdate?: User;
}

export function AddUpdateUserModalContent({
  submitButtonText,
  onCloseDialog,
  onSubmitForm,
  userToUpdate,
}: AddUpdateUserModalContentProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, clearErrors, setValue } = useForm<UserHandler>(
    {
      mode: "onBlur",
      resolver: yupResolver(schema),
      defaultValues: {
        role: userToUpdate ? userToUpdate.role : "SALESMAN",
        salesman_type: "INTERNAL",
      },
    }
  );

  const onSubmit: SubmitHandler<UserHandler> = async (
    data: UserHandler
  ): Promise<void> => {
    setIsLoading(true);
    onSubmitForm(data).then(() => {
      setIsLoading(false);
      onCloseDialog();
    });
  };

  const onError = (errors) => {
    if (!isEmpty(errors)) {
      toast.error("Não foi possível criar usuário");
    }
  };

  const cancelChanges = () => {
    clearErrors();
    onCloseDialog();
  };

  useEffect(() => {
    if (userToUpdate !== undefined) {
      setValue("name", userToUpdate.name);
      setValue("email", userToUpdate.email);
      setValue("phone", userToUpdate.phone);
      setValue("role", userToUpdate.role);
      setValue("salesman_id", userToUpdate.salesman_id);
      setValue("salesman_type", userToUpdate.salesman_type);
    }
  }, []);

  return (
    <UserModalContainer>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <Text>Digite o nome do usuário:</Text>
            <Input
              placeholder="Ex. André Felipe"
              type="text"
              isFullWidth
              hasError={!!error}
              errorMessage={error?.message}
              {...field}
            />
          </Flex>
        )}
      />

      <Controller
        control={control}
        name="role"
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <AddUserSelectRole
                onChange={onChange}
                value={value}
                hasError={!!error}
                errorMessage={error?.message}
              />
            </>
          );
        }}
      />

      <Controller
        control={control}
        name="salesman_id"
        render={({ field, fieldState: { error } }) => (
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <Text>Digite o código do ERP do usuário:</Text>
            <Input
              placeholder="Ex. 12345234"
              type="text"
              isFullWidth
              hasError={!!error}
              errorMessage={error?.message}
              {...field}
            />
          </Flex>
        )}
      />

      <Controller
        control={control}
        name="phone"
        render={({ field, fieldState: { error } }) => (
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <Text>Digite o telefone do usuário:</Text>
            <Input
              placeholder="Ex. (62) 99999-0000"
              type="text"
              isFullWidth
              hasError={!!error}
              errorMessage={error?.message}
              {...field}
            />
          </Flex>
        )}
      />

      <Controller
        control={control}
        name="email"
        render={({ field, fieldState: { error } }) => (
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <Text>Digite o email do usuário:</Text>
            <Input
              placeholder="Ex. meuemail@gmail.com"
              type="text"
              isFullWidth
              hasError={!!error}
              errorMessage={error?.message}
              {...field}
            />
          </Flex>
        )}
      />

      <Flex css={{ gap: "$2" }}>
        <Controller
          control={control}
          name="password"
          render={({ field, fieldState: { error } }) => (
            <Flex css={{ flexDirection: "column", gap: "$1" }}>
              <Text>Digite a senha do usuário:</Text>
              <Input
                placeholder="Ex. 123"
                showPassword
                isFullWidth
                hasError={!!error}
                errorMessage={error?.message}
                {...field}
              />
            </Flex>
          )}
        />
        <Controller
          control={control}
          name="confirm_password"
          render={({ field, fieldState: { error } }) => (
            <Flex css={{ flexDirection: "column", gap: "$1" }}>
              <Text>Confirme a senha do usuário:</Text>
              <Input
                placeholder="Ex. 123"
                showPassword
                isFullWidth
                hasError={!!error}
                errorMessage={error?.message}
                {...field}
              />
            </Flex>
          )}
        />
      </Flex>

      <Flex
        css={{ alignItems: "flex-end", justifyContent: "flex-end", gap: "$3" }}
      >
        <Button color="danger" variant="soft" onClick={cancelChanges}>
          Cancelar
        </Button>
        <Button
          isLoading={isLoading}
          onClick={() => handleSubmit(onSubmit, onError)()}
        >
          {submitButtonText}
        </Button>
      </Flex>
    </UserModalContainer>
  );
}

const UserModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: ${({ theme }) => theme.space[4]};
  border-radius: ${({ theme }) => theme.space[2]};
`;
