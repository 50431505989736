import {
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { MdInfo } from "react-icons/md";
import styled from "styled-components";

interface ShowErrorMessageCellProps {
  error: boolean;
  message?: string;
}

export function ShowErrorMessageCell({
  error,
  message,
}: ShowErrorMessageCellProps) {
  return (
    <Cell $error={error}>
      {error ? (
        <Tooltip>
          <TooltipTrigger
            style={{
              background: "transparent",
              color: "inherit",
              zIndex: "99999",
            }}
          >
            <Text css={{ color: "inherit", fontWeight: 500, fontSize: "24px" }}>
              <MdInfo color="inherit" width={24} />
            </Text>
          </TooltipTrigger>
          <TooltipContent style={{ zIndex: "99999" }} aria-label={message}>
            {message}
          </TooltipContent>
        </Tooltip>
      ) : (
        <Text>Sem erros</Text>
      )}
    </Cell>
  );
}

interface RoleCellProps {
  $error: boolean;
}

const Cell = styled.div<RoleCellProps>`
  text-align: center;
  color: ${(props) => props.theme.colors.red500};
`;
