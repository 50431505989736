import { User } from "@/core/models/user.model";
import {
  removeAllUserToBatchActions,
  selectSelectedUsersToBatchActions,
  updateUserEnabled,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  Text,
} from "@gogeo-io/ui-library";
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { useUsersMixpanel } from "../hooks/useUsersMixpanel";
import { emitEventToChangeUsers } from "../store/emitEventToChangeUsersSlice";

interface ActivateUsersModalProps {
  children: ReactNode;
}

export function ActivateUsersModal({ children }: ActivateUsersModalProps) {
  const selectedUsersToBatchActions = useSelector(
    selectSelectedUsersToBatchActions
  );

  const users = selectedUsersToBatchActions;
  const [selectedUsers, setSelectedUsers] = useState(
    selectedUsersToBatchActions
  );
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { activeUsersEvent } = useUsersMixpanel();

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const closeDialog = () => setDialogIsOpen(false);

  const handleCheckboxChange = async (isChecked: boolean, user: User) => {
    if (isChecked) {
      setSelectedUsers((prevState) => [...prevState, user]);
    } else {
      const usersWithoutRemovedOne = selectedUsers.filter(
        (selecteUser) => selecteUser.id !== user.id
      );
      setSelectedUsers(usersWithoutRemovedOne);
    }
  };

  const activateUsers = async () => {
    await dispatch(emitEventToChangeUsers(false));

    closeDialog();

    await dispatch(
      updateUserEnabled({
        active: true,
        user_ids: selectedUsers.map((user) => user.id),
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        activeUsersEvent(selectedUsers);
      }
    });
    await dispatch(emitEventToChangeUsers(true));
    await dispatch(removeAllUserToBatchActions());
  };

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <Flex
          css={{
            flexDirection: "column",
            gap: "$4",
            width: "500px",
          }}
        >
          <Heading size="title6" css={{ textAlign: "center" }}>
            Ativar Usuários
          </Heading>

          <Flex
            css={{
              alignItems: "flex-start",
              flexDirection: "column",
              gap: "$4",
            }}
          >
            {users.map((user) => {
              return (
                <Flex css={{ gap: "$2" }} key={user.id}>
                  <Checkbox
                    checked={selectedUsers.includes(user)}
                    onCheckedChange={(checked) =>
                      handleCheckboxChange(Boolean(checked), user)
                    }
                    css={{ cursor: "pointer" }}
                  />
                  <Text>{user.name}</Text>
                </Flex>
              );
            })}
          </Flex>

          {selectedUsers.length === 0 ? (
            <Text css={{ textAlign: "center" }}>
              Selecione ao menos um usuário para prosseguir.
            </Text>
          ) : (
            <Text css={{ textAlign: "center" }}>
              Você tem certeza que deseja ativar esses usuários? Ao ativá-los,
              ele poderão entrar na aplicação e utilizar dos recursos do
              goVendas.
            </Text>
          )}

          <Flex
            css={{
              alignItems: "flex-end",
              justifyContent: "flex-end",
              gap: "$3",
              mt: "$3",
            }}
          >
            <Button color="danger" variant="soft" onClick={closeDialog}>
              Cancelar
            </Button>
            <Button
              onClick={() => activateUsers()}
              disabled={selectedUsers.length === 0}
            >
              Ativar usuários
            </Button>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
