import { useFormatDate } from "@/hooks/useFormatDate";
import { Caption } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";

interface TimelineHeaderInfoTimestampProps {
  feedback_timestamp: string;
}

export function TimelineHeaderInfoTimestamp({
  feedback_timestamp,
}: TimelineHeaderInfoTimestampProps) {
  const {
    formatHourAndMinutes,
    formatBasicDateWithSlash,
    newDateBasedOnDateWithoutTimezone,
  } = useFormatDate();

  return (
    <>
      {!isEmpty(feedback_timestamp) ? (
        <Caption wider css={{ color: "$gray500" }}>
          <>
            {formatBasicDateWithSlash(
              newDateBasedOnDateWithoutTimezone(feedback_timestamp)
            )}
            &nbsp;|&nbsp;
            {formatHourAndMinutes(new Date(feedback_timestamp))}
            &nbsp;|&nbsp;
          </>
        </Caption>
      ) : (
        <Caption css={{ color: "$gray500" }}>
          –—/–—/–—–—&nbsp;|&nbsp;–—:–—&nbsp;|&nbsp;
        </Caption>
      )}
    </>
  );
}
