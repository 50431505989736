import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, Heading } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { getRecommendedTags } from "../../store/clientAdvancedFiltersSlice";
import { AdvancedFiltersTagsSkeleton } from "./AdvancedFiltersTagsSkeleton";
import { RecentCreatedTags } from "./RecentCreatedTags";
import { TrendingTags } from "./TrendingTags";

export function RecommendedTags() {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoading(true);

    async function loadTags() {
      await dispatch(getRecommendedTags()).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setIsLoading(false);
        }
      });
    }

    loadTags();
  }, []);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$4",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      {!isLoading ? (
        <>
          <Flex css={{ flexDirection: "column", gap: "$2" }}>
            <Heading size="subtitle1">🔥 Tags em alta</Heading>
            <TrendingTags />
          </Flex>
          <Flex css={{ flexDirection: "column", gap: "$2" }}>
            <Heading size="subtitle1">🏭 Tags mais recentes</Heading>
            <RecentCreatedTags />
          </Flex>
        </>
      ) : (
        <AdvancedFiltersTagsSkeleton count={8} />
      )}
    </Flex>
  );
}
