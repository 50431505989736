import { selectSelectedUsersToBatchActions } from "@/core/store/users/usersSlice";
import { useFormatPrice } from "@/hooks/useFormatPrice";
import { Flex, Text } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function UsersPermissionsNameOfUsers() {
  const [value, setValue] = useState("");

  const selectedUsersToBatchActions = useSelector(
    selectSelectedUsersToBatchActions
  );

  const { formatNumberToDecimal } = useFormatPrice();

  useEffect(() => {
    if (!isEmpty(selectedUsersToBatchActions)) {
      const title = `${formatNumberToDecimal(
        selectedUsersToBatchActions.length
      )} usuário(s) selecionados`;
      setValue(title);
    } else {
      setValue("Nenhum usuário selecionado");
    }
  }, [selectedUsersToBatchActions]);

  return (
    <Flex css={{ gap: "0.5rem" }}>
      <Text size="body1" css={{ color: "$black" }}>
        Usuário(s):
      </Text>

      <Text size="body1" bold css={{ color: "$black" }}>
        {value}
      </Text>
    </Flex>
  );
}
