import { useFormatBytes } from "@/hooks/useFormatBytes";
import { Caption, Flex, Text } from "@gogeo-io/ui-library";
import { InsertDriveFile as InsertDriveFileIcon } from "@mui/icons-material";
import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";

interface FileAttachedProps {
  file: any;
  onRemoveFileAttached: (fileNameToRemove: string) => void;
}

export function FileAttached({
  file,
  onRemoveFileAttached,
}: FileAttachedProps) {
  const { formatBytes } = useFormatBytes();

  return (
    <FileAttachedContainer>
      <Flex css={{ gap: "$2", alignItems: "center" }}>
        <IconForFile>
          <InsertDriveFileIcon />
        </IconForFile>

        <Flex css={{ flexDirection: "column", width: "100%" }}>
          <Flex
            css={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              color: "$gray500",
              fontSize: "18px",
            }}
          >
            <Text>{file.name}</Text>
            <AiOutlineClose
              color="inherit"
              fontSize="inherit"
              cursor="pointer"
              onClick={() => onRemoveFileAttached(file.name)}
            />
          </Flex>
          <Caption>{formatBytes(file.size)}</Caption>
        </Flex>
      </Flex>
    </FileAttachedContainer>
  );
}

export const FileAttachedContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: ${(props) => props.theme.space[2]};
  padding: ${(props) => props.theme.space[2]};
  background: ${(props) => props.theme.colors.gray50};
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background: ${(props) => props.theme.colors.primary600};
  border-radius: 4px;
`;

export const IconForFile = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  width: 18px;
  height: 18px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.gray200};

  svg {
    color: ${(props) => props.theme.colors.primary600};
    width: 18px;
    height: 18px;
  }
`;
