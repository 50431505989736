import { ListDivider } from "@/components/ListDivider";
import { DynamicMdIcon } from "@/components/icons/DynamicMdIcon";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { ClientParamsResponse } from "@/core/models/client.model";
import { selectUser } from "@/core/store/users/usersSlice";
import { selectStatusOfEmitEventToChangeClients } from "@/features/attendance/store/emitEventToChangeClientsSlice";
import {
  getFunnelCountOfClients,
  selectSelectedFunnel,
} from "@/features/attendance/store/funnelsSlice";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, Text } from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useBreadcrumbsForClientsListPage } from "../hooks/useBreadcrumbsForClientsListPage";
import { useGetClientsByFiltersParams } from "../hooks/useGetClientsByFiltersParams";
import { getClientsByFilters } from "../store/clientsSlice";
import { ClientItemOnKanban } from "./ClientItemOnKanban";
import { ClientOnKanbanSkeleton } from "./ClientOnKanbanSkeleton";
import { ClientOnKanbanSkeletonColumn } from "./ClientOnKanbanSkeletonColumn";

interface ColumnOnClientsKanbanProps {
  columnId: number;
  columnName: string;
  columnCount: number;
  columnFilter: AdvancedFilter;
  columnIcon?: string;
  columnColor: string;
}

export function ColumnOnClientsKanban({
  columnName,
  columnCount,
  columnFilter,
  columnIcon = "SentimentVeryDissatisfied",
  columnColor,
}: ColumnOnClientsKanbanProps) {
  const dispatch = useAppDispatch();
  const { paramsOnEditKanbanView } = useGetClientsByFiltersParams();
  const { showBreadcrumbs, showBreadcrumbsToFiltersPage } =
    useBreadcrumbsForClientsListPage();

  const [clients, setClients] = useState<ClientParamsResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [onLoadPage, setOnLoadPage] = useState("loading");

  const user = useSelector(selectUser);
  const selectedFunnel = useSelector(selectSelectedFunnel);
  const selectedList = useSelector(selectSelectedList);
  const statusOfEmitEventToChangeClients = useSelector(
    selectStatusOfEmitEventToChangeClients
  );

  const handleIncreasePageNumber = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const getPaginatedClients = async (currentPage: number) => {
    const params = paramsOnEditKanbanView(columnFilter);

    await dispatch(
      getClientsByFilters({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
        params,
      })
    ).then((res) => {
      if (res.payload.clients.length < Number(import.meta.env.VITE_PAGE_SIZE)) {
        setHasMorePages(false);
      } else {
        setClients((prevClients) => [...prevClients, ...res.payload.clients]);
      }
    });
  };

  useEffect(() => {
    if (statusOfEmitEventToChangeClients) {
      setCurrentPage(0);
      setHasMorePages(true);

      loadClients();
    }
  }, [statusOfEmitEventToChangeClients]);

  useEffect(() => {
    setCurrentPage(0);
    setHasMorePages(true);
    setOnLoadPage("loading");

    loadClients();
  }, []);

  async function loadClients() {
    const params = paramsOnEditKanbanView(columnFilter);

    await dispatch(
      getClientsByFilters({
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        params,
      })
    ).then(async (res) => {
      if (res.payload.clients.length === 0) {
        setHasMorePages(false);
        setClients([]);
      } else if (
        res.payload.clients.length < Number(import.meta.env.VITE_PAGE_SIZE)
      ) {
        setHasMorePages(false);
        setClients(res.payload.clients);
      } else {
        setClients(res.payload.clients);
      }
      if (!isEmpty(selectedList)) {
        showBreadcrumbs(selectedList);
      } else {
        showBreadcrumbsToFiltersPage();
      }

      setOnLoadPage("fulfilled");
    });
    await dispatch(
      getFunnelCountOfClients({
        id: selectedFunnel.id,
        params,
      })
    );
  }

  useEffect(() => {
    if (onLoadPage === "fulfilled") {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (hasMorePages) {
            handleIncreasePageNumber();
          }
        }
      });

      intersectionObserver.observe(document.querySelector("#kanbanIntersect"));

      return () => intersectionObserver.disconnect();
    }
  }, [onLoadPage]);

  useEffect(() => {
    if (currentPage !== 0) {
      if (hasMorePages) {
        getPaginatedClients(currentPage);
      }
    }
  }, [currentPage]);

  return (
    <Container $color={columnColor}>
      {onLoadPage === "fulfilled" ? (
        <>
          <Title>
            <DynamicMdIcon
              icon={`Md${columnIcon ?? "SentimentVeryDissatisfied"}`}
            />
            <Text size="body2" css={{ color: "inherit" }}>
              {columnName} {`(${columnCount !== undefined ? columnCount : 0})`}
            </Text>
          </Title>

          <ColumnList>
            {clients.map((client) => (
              <Flex css={{ flexDirection: "column" }} key={client.client.id}>
                <ClientItemOnKanban client={client.client} />
                <ListDivider />
              </Flex>
            ))}
            <div id="kanbanIntersect">
              {hasMorePages ? <ClientOnKanbanSkeleton /> : null}
            </div>
          </ColumnList>
        </>
      ) : (
        <>
          <ClientOnKanbanSkeletonColumn />
        </>
      )}
    </Container>
  );
}

interface ContainerProps {
  $color: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  gap: ${(props) => props.theme.space[4]};
  padding: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.gray100};
  background: ${({ theme, $color }) => ($color ? $color : theme.colors.gray50)};
  border-radius: 8px;
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[3]};
  color: ${(props) => props.theme.colors.gray900};
  font-size: 1rem;

  svg {
    color: ${(props) => props.theme.colors.gray900};
    width: 16px;
    height: 16px;
  }
`;

const ColumnList = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
  overflow-y: auto;

  /* -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  } */
`;
