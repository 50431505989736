import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function SelectConsumersSkeletonPage() {
  return (
    <Flex
      css={{
        maxHeight: "400px",
        overflowY: "auto",
        flexDirection: "column",
        gap: "$2",
      }}
    >
      <Flex css={{ gap: "$1" }}>
        <RLSSkeleton height={25} width={25} />
        <RLSSkeleton width={100} height={20} />
      </Flex>

      <Flex css={{ gap: "$1" }}>
        <RLSSkeleton height={25} width={25} />
        <RLSSkeleton width={100} height={20} />
      </Flex>

      <Flex css={{ gap: "$1" }}>
        <RLSSkeleton height={25} width={25} />
        <RLSSkeleton width={100} height={20} />
      </Flex>

      <Flex css={{ gap: "$1" }}>
        <RLSSkeleton height={25} width={25} />
        <RLSSkeleton width={100} height={20} />
      </Flex>

      <Flex css={{ gap: "$1" }}>
        <RLSSkeleton height={25} width={25} />
        <RLSSkeleton width={100} height={20} />
      </Flex>
    </Flex>
  );
}
