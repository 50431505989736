import { motion } from "framer-motion";
import { CSSProperties, ReactElement, SVGAttributes } from "react";
import { IconContext } from "react-icons";

export interface DynamicIconProps {
  icon: string;
  color?: string;
  size?: string;
  pulse?: boolean;
  className?: string;
  style?: CSSProperties;
  attr?: SVGAttributes<SVGElement>;
}

export interface DynamicProviderProps extends DynamicIconProps {
  children: ReactElement;
}

export function DynamicIconProvider({ ...props }: DynamicProviderProps) {
  return (
    <>
      {props.pulse ? (
        <motion.div
          style={{ display: "flex" }}
          variants={{
            pulse: {
              scale: [1, 1.1, 1, 1.2, 1, 1],
              transition: {
                repeat: Infinity,
                duration: 2,
              },
            },
          }}
          animate="pulse"
        >
          <Provider {...props} />
        </motion.div>
      ) : (
        <>
          <Provider {...props} />
        </>
      )}
    </>
  );
}

export function Provider({ ...props }: DynamicProviderProps) {
  const value: IconContext = {
    color: props.color,
    size: props.size,
    className: props.className,
    style: props.style,
    attr: props.attr,
  };

  return (
    <IconContext.Provider value={value}>{props.children}</IconContext.Provider>
  );
}
