import { AttendanceRecord } from "@core/models/history.model";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import UI from "../ui";
import { TimelineAttendanceRecordContent } from "./TimelineAttendanceRecordContent";
import { TimelineAttendanceRecordHeader } from "./TimelineAttendanceRecordHeader";

export interface TimeLineItemProps {
  lastAttendance: AttendanceRecord;
  othersAttendances: AttendanceRecord[];
  order_total_amount: number;
  salesman: string;
  historyIsCollapsed?: boolean;
}

export const TimelineAttendanceRecord = ({
  lastAttendance,
  othersAttendances,
  order_total_amount,
  salesman,
  historyIsCollapsed = false,
}: TimeLineItemProps) => {
  const isFirstRender = useRef(true);
  const [hasObservations] = useState(!isEmpty(lastAttendance.observations));
  const [hasAttendanceInfo] = useState(
    lastAttendance.attendance_info.length > 0
  );
  const [hasAttachments] = useState(!isEmpty(lastAttendance.files));
  const [hasMoreAttendanceRecord] = useState(othersAttendances.length > 0);
  const [canShowMoreInfo, setCanShowMoreInfo] = useState(
    hasObservations ||
      hasAttendanceInfo ||
      hasMoreAttendanceRecord ||
      hasAttachments
  );

  useEffect(() => {
    setCanShowMoreInfo(historyIsCollapsed);

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
  }, [historyIsCollapsed]);

  const handleShowMoreInfoAboutAttendance = () =>
    setCanShowMoreInfo((value) => !value);

  return (
    <UI.TimelineAttendanceRecord>
      <TimelineAttendanceRecordHeader
        lastAttendance={lastAttendance}
        canShowMoreInfo={canShowMoreInfo}
        hasObservations={hasObservations}
        hasAttendanceInfo={hasAttendanceInfo}
        hasMoreAttendanceRecord={hasMoreAttendanceRecord}
        onShowMoreInfoAboutAttendance={handleShowMoreInfoAboutAttendance}
        order_total_amount={order_total_amount}
        salesman={salesman}
      />

      {canShowMoreInfo && (
        <TimelineAttendanceRecordContent
          lastAttendance={lastAttendance}
          othersAttendances={othersAttendances}
          hasObservations={hasObservations}
          hasAttendanceInfo={hasAttendanceInfo}
          hasMoreAttendanceRecord={hasMoreAttendanceRecord}
          hasAttachments={hasAttachments}
        />
      )}
    </UI.TimelineAttendanceRecord>
  );
};
