import { ClientNote } from "@/core/models/client-notes.model";
import { selectUser } from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatDate } from "@/hooks/useFormatDate";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Caption,
  Flex,
  Heading,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateIsFavoriteOnClientNote } from "../../store/clientNotesSlice";
import { ClientNoteActions } from "../ClientNoteActions";
import { ClientNoteExcerpt } from "./ClientNoteExcerpt";
import { ClientNoteFilesOnItem } from "./ClientNoteFilesOnItem";
import { PersonalNoteLabel } from "./PersonalNoteLabel";
import { PublicNoteLabel } from "./PublicNoteLabel";

export interface ClientNoteItemProps {
  note: ClientNote;
}

export function ClientNoteItem({ note }: ClientNoteItemProps) {
  const user = useSelector(selectUser);

  const { formatLongDatePtBr } = useFormatDate();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClickNote = async () => {
    navigate(`list/${note.id}`);
  };

  const handleClickIsFavorite = async (
    note_id: string,
    is_favorite: boolean
  ) => {
    await dispatch(updateIsFavoriteOnClientNote({ note_id, is_favorite }));
  };

  const renderNoteTitle = (note_title: string) => {
    if (note_title.length > 30) {
      return `${note_title.slice(0, 29)}...`;
    }

    return note_title;
  };

  return (
    <Flex
      css={{
        width: "300px",
        boxShadow: "$sm",
        borderRadius: "4px",
        gap: "$2",
        padding: "$3",
        background: "$white",
        flexDirection: "column",
      }}
    >
      <Flex
        css={{
          alignItems: "center",
          justifyContent: "space-between",
          color: "$yellow500",
        }}
      >
        <Tooltip>
          <TooltipTrigger style={{ background: "transparent" }}>
            <Flex
              css={{
                alignItems: "flex-start",
                textAlign: "left",
                justifyContent: "space-between",
                color: "$yellow500",
              }}
            >
              <Heading size="subtitle2" css={{ color: "$gray800" }}>
                {renderNoteTitle(note.title)}
              </Heading>
            </Flex>
          </TooltipTrigger>
          <TooltipContent aria-label="Progresso realizado">
            {note.title}
          </TooltipContent>
        </Tooltip>

        <Flex css={{ alignItems: "center", gap: "0" }}>
          {note.is_favorite ? (
            <AiFillStar
              color="inherit"
              onClick={() => handleClickIsFavorite(note.id, false)}
              size={22}
              cursor="pointer"
            />
          ) : (
            <AiOutlineStar
              onClick={() => handleClickIsFavorite(note.id, true)}
              size={22}
              cursor="pointer"
            />
          )}
          {note.created_by.id === user.id && <ClientNoteActions note={note} />}
        </Flex>
      </Flex>

      {note && note.shared ? <PublicNoteLabel /> : <PersonalNoteLabel />}

      <Flex
        css={{
          flex: "1",
          cursor: "pointer",
          alignItems: "center",
        }}
        onClick={() => handleClickNote()}
      >
        <ClientNoteExcerpt excerpt={note.excerpt} />
      </Flex>

      <Flex css={{ gap: "$3", alignItems: "center" }}>
        <Caption css={{ color: "$gray500" }} bold>
          Anexos:
        </Caption>
        <ClientNoteFilesOnItem
          files={note.files}
          onOpenNote={handleClickNote}
        />
      </Flex>

      <Flex css={{ gap: "$2", alignItems: "center" }}>
        <Caption css={{ color: "$gray500" }} bold>
          Criada em:
        </Caption>
        <Caption css={{ color: "$gray500", textTransform: "capitalize" }}>
          {formatLongDatePtBr(note.inserted_at)}
        </Caption>
      </Flex>

      {note && note.created_by && (
        <Flex css={{ alignItems: "center", gap: "$3" }}>
          <Caption css={{ color: "$gray500" }} bold>
            Criada por
          </Caption>
          <Tooltip>
            <TooltipTrigger
              style={{ background: "transparent", width: "fit-content" }}
            >
              <Flex css={{ alignItems: "center", gap: "$1" }}>
                <Avatar size="small">
                  {note.created_by.user_photo.photo_sm_url ? (
                    <AvatarImage
                      src={note.created_by.user_photo.photo_sm_url}
                    />
                  ) : (
                    <AvatarFallback>
                      {note.created_by.user_photo.fallback}
                    </AvatarFallback>
                  )}
                </Avatar>

                <Caption css={{ color: "$gray500" }}>
                  {note.created_by.name}
                </Caption>
              </Flex>
            </TooltipTrigger>
            <TooltipContent aria-label={note.created_by.name}>
              {note.created_by.name}
            </TooltipContent>
          </Tooltip>
        </Flex>
      )}
    </Flex>
  );
}
