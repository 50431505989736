import { WhatsappClientDialog } from "@/components/WhatsappClientDialog";
import { Client } from "@/core/models/client.model";
import { selectUser } from "@/core/store/users/usersSlice";
import { getPreDefinedMessages } from "@/features/profile/store/preDefinedMessagesSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useEffect } from "react";
import { useSelector } from "react-redux";

interface OpenClientWhatsappDialogProps {
  phone: string;
  client: Client;
  onCloseDialog: () => void;
}

export function OpenClientWhatsappDialog({
  phone,
  client,
}: OpenClientWhatsappDialogProps) {
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  useEffect(() => {
    const loadMessages = async () => {
      await dispatch(
        getPreDefinedMessages({
          page: 0,
          size: import.meta.env.VITE_PAGE_SIZE,
        })
      );
    };

    loadMessages();
  }, []);

  return (
    <WhatsappClientDialog client={client} user={user} phone={phone}>
      <></>
    </WhatsappClientDialog>
  );
}
