import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";

export const useBreadcrumbsForRuptureRulePage = () => {
  const dispatch = useAppDispatch();

  const basicBreadrumb = async () => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Configurações", url: "/profile/user-profile/info" },
      {
        item: "Gestão de Ruptura",
        url: "/profile/rupture-rule/create-rupture-rule",
        notNavigate: true,
      },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  const breacrumbsToCreateRuptureRulePage = async () => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Configurações", url: "/profile/user-profile/info" },
      {
        item: "Gestão de Ruptura",
        url: "/profile/rupture-rule/create-rupture-rule",
      },
      { item: "Criar regra de ruptura", url: "" },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  const breacrumbsToRuptureRulePage = async (ruptureRuleName: string) => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Configurações", url: "/profile/user-profile/info" },
      {
        item: "Gestão de Ruptura",
        url: "/profile/rupture-rule/create-rupture-rule",
      },
      { item: `${ruptureRuleName}`, url: "" },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return {
    basicBreadrumb,
    breacrumbsToCreateRuptureRulePage,
    breacrumbsToRuptureRulePage,
  };
};
