import { useFormatPrice } from "@/hooks/useFormatPrice";
import { Flex, Text } from "@gogeo-io/ui-library";

interface CurrencyCellProps {
  value: number;
}

export function CurrencyCell({ value }: CurrencyCellProps) {
  const { formatBRLPrice } = useFormatPrice();

  return (
    <Flex>
      {value ? (
        <Text size="body2" css={{ textAlign: "center" }}>
          {formatBRLPrice(value)}
        </Text>
      ) : (
        <Text size="body2" css={{ textAlign: "center" }}>
          Não Possui
        </Text>
      )}
    </Flex>
  );
}
