import { DynamicFormFields } from "@/core/models/business-associate.model";
import { User } from "@/core/models/user.model";
import { useBusinessAttendanceServiceFields } from "./useBusinessAttendanceServiceFields";

export const useTranslateBusinessAttendanceServiceUrl = () => {
  const replaceUrl = (
    phone: string,
    redirect_url: string,
    user: User,
    govendas_fields_of_partner: DynamicFormFields[],
    govendas_fields_of_service: DynamicFormFields[]
  ) => {
    const fields = useBusinessAttendanceServiceFields(phone, user, [
      ...govendas_fields_of_partner,
      ...govendas_fields_of_service,
    ]);

    const regex = /(\{{2})([^}|{]*)(\}{2})/gm;

    let newUrl = redirect_url;

    if (redirect_url !== undefined) {
      redirect_url.match(regex)?.forEach((result) => {
        const token = result.substring(2, result.length - 2);
        const value = fields.find(
          (field) => field.field === token
        )?.translated_field;

        newUrl = newUrl.replace(result, `${value}`);
      });
    }

    return newUrl;
  };

  return {
    replaceUrl,
  };
};
