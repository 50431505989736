import { ReadReminderToastConfirmation } from "@/components/ReadReminderToastConfirmation";
import { RemindersWithoutReadDialog } from "@/components/RemindersWithoutReadDialog";
import { isSameMinute } from "date-fns";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  selectCountOfRemindersUnreaded,
  selectReminders,
} from "../store/reminderSlice";

export const useShowRemindersToNow = () => {
  const reminders = useSelector(selectReminders);
  const countOfRemindersUnreaded = useSelector(selectCountOfRemindersUnreaded);

  const handleRemindersNotReadedCount = () => {
    if (countOfRemindersUnreaded > 0) {
      toast.info(
        <RemindersWithoutReadDialog
          remindersNotReadedCount={countOfRemindersUnreaded}
        />
      );
    }
  };

  const handleListenerToSearchForRemindersToPopupOnScreen = () => {
    if (reminders !== undefined && reminders.length > 0) {
      const remindersNotReaded = reminders.filter(
        (reminder) => reminder.is_read === false
      );

      const remindersToNow = remindersNotReaded.filter((reminder) =>
        isSameMinute(new Date(reminder.date), new Date())
      );

      if (remindersToNow.length > 0) {
        remindersToNow.map((reminder) =>
          toast.info(
            <ReadReminderToastConfirmation
              reminderDate={reminder.date}
              reminderId={reminder.id}
              reminderText={reminder.text}
            />
          )
        );
      }
    }
  };

  return {
    handleRemindersNotReadedCount,
    handleListenerToSearchForRemindersToPopupOnScreen,
  };
};
