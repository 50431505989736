export const useFile = () => {
  const downloadFileByUrl = (
    url: string,
    fileType: string,
    filename: string
  ) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blobData) => {
        const blob: Blob = new Blob([blobData], { type: fileType });
        const fileName = filename;
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement(
          "a"
        ) as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      });
  };

  return { downloadFileByUrl };
};
