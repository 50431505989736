import { Flex } from "@gogeo-io/ui-library";
import { ChangeParamsForm } from "../components/ChangeParamsForm";

interface ChangeParamsPageProps {
  onUpdateParams: () => void;
}

export function ChangeParamsPage({ onUpdateParams }: ChangeParamsPageProps) {
  return (
    <Flex css={{ flexDirection: "column", gap: "$3", marginTop: "$1" }}>
      <ChangeParamsForm onUpdateParams={onUpdateParams} />
    </Flex>
  );
}
