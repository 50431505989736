import { ListDivider } from "@/components/ListDivider";
import { ClientNote } from "@/core/models/client-notes.model";
import { FileMetadata } from "@/core/models/file.model";
import { uploadFileToClientNote } from "@/core/store/uploadFile/uploadFileSlice";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import { TextEditorToEditNote } from "@/features/textEditor/components/TextEditorToEditNote";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useGoBack } from "@/hooks/useGoBack";
import { Flex } from "@gogeo-io/ui-library";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Descendant } from "slate";
import { useClientNotesMixpanel } from "../hooks/useClientNotesMixpanel";
import {
  selectSelectedClientNote,
  updateClientNote,
} from "../store/clientNotesSlice";
import { NoteIsSharedForm } from "./NoteIsSharedForm";
import { TryAgainUploadFileToClientNote } from "./TryAgainUploadFileToClientNote";
import { UpdateCreateClientNoteHeader } from "./clientNotes/UpdateCreateClientNoteHeader";

export function ClientNoteUpdate() {
  const selectedClientNote = useSelector(selectSelectedClientNote);
  const selectedClient = useSelector(selectClient);

  const dispatch = useAppDispatch();
  const { navigateGoBack } = useGoBack();
  const navigate = useNavigate();

  const [title, setTitle] = useState(selectedClientNote.title);
  const [content, setContent] = useState<Descendant[]>(
    selectedClientNote.content
  );
  const [shared, setShared] = useState(selectedClientNote.shared ?? false);
  const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState("");
  const [uploadFileHasError, setUploadFileHasError] = useState(false);
  const [clientNoteUpdated, setClientNoteUpdated] = useState<ClientNote>(
    {} as ClientNote
  );
  const [files, setFiles] = useState<FileMetadata[]>(selectedClientNote.files);
  const [filesToSend, setFilesToSend] = useState<File[]>([]);
  const [clientNoteStatus, setClientNoteStatus] = useState("success");

  const formData = new FormData();
  const { notesUpdatedEvent } = useClientNotesMixpanel();

  const handleUpdateClientNote = async () => {
    setClientNoteStatus("loading");
    setUploadFileHasError(false);

    await dispatch(
      updateClientNote({
        id: selectedClientNote.id,
        title,
        content,
        files,
        shared: !shared,
      })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const noteUpdated: ClientNote = res.payload;
        setClientNoteUpdated(res.payload);
        setFilesToSend(filesToSend);

        if (filesToSend && filesToSend.length > 0) {
          for (let i = 0; i < filesToSend.length; i++) {
            formData.append(`files`, filesToSend[i]);
          }

          await dispatch(
            uploadFileToClientNote({
              note_id: noteUpdated.id,
              data: formData,
            })
          ).then(async (res) => {
            const filesAddedToNote = res.payload;
            if (res.meta.requestStatus === "fulfilled") {
              notesUpdatedEvent(noteUpdated, filesAddedToNote);
              onClientNoteUpdateSuccess();
            } else {
              setClientNoteStatus("fail");
              setUploadFileHasError(true);
              setUploadFileErrorMessage(res.payload.errorMessage);
            }
          });
        } else {
          notesUpdatedEvent(noteUpdated, []);
          onClientNoteUpdateSuccess();
        }
      } else {
        setClientNoteStatus("fail");
      }
    });
  };

  const onClientNoteUpdateSuccess = async () => {
    toast.success("Nota atualizada com sucesso");
    navigate(`/info/client/${selectedClient.id}/notes`);
    setClientNoteStatus("success");
  };

  const handleSetNewTitle = (newTitle: string) => setTitle(newTitle);

  const handleSetNewContent = (newContent: Descendant[]) => {
    setContent(newContent);
  };

  const handleSetNewFilesToSend = (newFiles: File[]) => {
    setFilesToSend(newFiles);
  };

  const handleSetShared = (value: boolean) => {
    setShared(value);
  };

  const handleRemoveFile = (fileNameToRemove: string) => {
    const filesWithoutRemovedOne = files.filter(
      (file) => file.filename !== fileNameToRemove
    );
    setFiles(filesWithoutRemovedOne);
  };

  const handleRemoveFileToSend = (fileNameToRemove: string) => {
    const filesWithoutRemovedOne = filesToSend.filter(
      (file) => file.name !== fileNameToRemove
    );
    setFilesToSend(filesWithoutRemovedOne);
  };

  return (
    <>
      <Flex
        css={{
          flexDirection: "column",
          padding: "$4 0",
          gap: "$5",
          width: "100%",
          height: "100%",
        }}
      >
        <UpdateCreateClientNoteHeader
          title={title}
          saveButtonTitle="Salvar Nota"
          onTitleChange={handleSetNewTitle}
          onCreateNewClientNote={handleUpdateClientNote}
          clientNotesStatus={clientNoteStatus}
        />

        <ListDivider />

        <NoteIsSharedForm
          value={shared}
          onChangeNoteIsSharedChange={handleSetShared}
        />

        <TextEditorToEditNote
          files={files}
          filesToSend={filesToSend}
          content={content}
          onContentChange={handleSetNewContent}
          onRemoveFile={handleRemoveFile}
          onFilesToSendChange={handleSetNewFilesToSend}
          onRemoveFileToSend={handleRemoveFileToSend}
        />
      </Flex>

      {uploadFileHasError && (
        <TryAgainUploadFileToClientNote
          noteId={clientNoteUpdated.id}
          onCreateClientNoteSuccess={onClientNoteUpdateSuccess}
          onGoBack={navigateGoBack}
          triedToUploadFiles={filesToSend}
          errorMessage={uploadFileErrorMessage}
        />
      )}
    </>
  );
}
