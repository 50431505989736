import { UserCannotHaveAcess } from "@/components/UserCannotHaveAccess";
import { Breadcrumb } from "@/core/models/breadcrumb";
import { BusinessAssociateServices } from "@/core/models/business-associate.model";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import { selectUser } from "@/core/store/users/usersSlice";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { getBusinessAssociateServicesOfWhatsapp } from "@/features/businessAssociate/store/businessAssociateServicesSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ClientsPageSkeleton } from "../components/ClientsPageSkeleton";
import { ClientProfileActivityAreaData } from "../components/info-page/ClientProfileActivityAreaData";
import { ClientProfileContactData } from "../components/info-page/ClientProfileContactData";
import { ClientProfileFinancialData } from "../components/info-page/ClientProfileFinancialData";
import { ClientProfileGeneralInfo } from "../components/info-page/ClientProfileGeneralInfo";
import { ClientProfileParameters } from "../components/info-page/ClientProfileParameters";
import { useClientInfoMixpanel } from "../hooks/useClientInfoMixpanel";
import { selectClient, selectClientStatus } from "../store/clientSlice";

export function ClientInfoPage() {
  const [isLoading, setIsLoading] = useState(false);

  const clientStatus = useSelector(selectClientStatus);
  const selectedList = useSelector(selectSelectedList);
  const selectedClient = useSelector(selectClient);
  const selectedUser = useSelector(selectUser);

  const dispatch = useAppDispatch();
  const { clientInfoOpenedEvent } = useClientInfoMixpanel();

  useEffect(() => {
    const setTitle = async () => {
      const breadcrumbs: Breadcrumb[] = [
        { item: "Atendimento", url: "/attendance" },
        {
          item: `${
            !isEmpty(selectedList.name) && !isUndefined(selectedList.name)
              ? selectedList.name
              : "Clientes"
          }`,
          url: `/attendance/${
            !isEmpty(selectedList.slug) && !isUndefined(selectedList.slug)
              ? selectedList.slug
              : `${selectedUser.account_id}_${selectedUser.id}_clientes`
          }`,
        },
        {
          item: selectedClient.name,
          url: `/info/client/${selectedClient.id}/client-info`,
        },
      ];
      await dispatch(setBreadcrumbs(breadcrumbs));
    };

    setTitle();
    clientInfoOpenedEvent(selectedClient);
  }, [selectedList, selectedClient]);

  useEffect(() => {
    setIsLoading(true);

    const sonaxScript = document.getElementById("widget-script");
    const sonnaxWidgets = document.getElementsByClassName("SonaxWidget");

    const loadBusinessAssociateServices = async () => {
      await dispatch(getBusinessAssociateServicesOfWhatsapp()).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const businessAssociatedEnabled: BusinessAssociateServices[] =
            res.payload;

          // usar id do parceiro sonax
          const sonax = businessAssociatedEnabled.find(
            (b) => b.id === "198b4fd1-294e-45af-89fe-add40237ee13"
          );
          if (sonax) {
            // usar id do campo onde está cadastrado o embedding
            const embedding_info = sonax.associate_fields.find(
              (field) => field.id === 2222222222
            );
            if (embedding_info) {
              sonaxScript.setAttribute("src", embedding_info.value);

              if (sonnaxWidgets && sonnaxWidgets.length > 0) {
                const widget = sonnaxWidgets[0] as HTMLElement;
                widget.style.visibility = "visible";
              }

              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        }
      });
    };

    loadBusinessAssociateServices();

    return () => {
      if (sonaxScript) {
        sonaxScript.removeAttribute("src");
      }

      if (sonnaxWidgets && sonnaxWidgets.length > 0) {
        const widget = sonnaxWidgets[0] as HTMLElement;
        widget.style.visibility = "hidden";
      }
    };
  }, []);

  return (
    <>
      {clientStatus !== "success" && !isLoading ? (
        <ClientsPageSkeleton />
      ) : (
        <Flex css={{ gap: "$4" }}>
          <Flex css={{ flexDirection: "column", gap: "2.5rem", flex: 1 }}>
            <ClientProfileGeneralInfo />

            <ClientProfileContactData />
          </Flex>
          <Flex css={{ flexDirection: "column", gap: "2.5rem", flex: 1 }}>
            <UserCannotHaveAcess roles={["ADMIN", "MANAGER", "SUPERVISOR"]}>
              <ClientProfileParameters />
            </UserCannotHaveAcess>
            <ClientProfileFinancialData />
            <ClientProfileActivityAreaData />
          </Flex>
        </Flex>
      )}
    </>
  );
}
