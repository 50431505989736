import { ListDivider } from "@/components/ListDivider";
import { FileMetadata } from "@/core/models/file.model";
import { ClientNoteFiles } from "@/features/profile/components/clientNotes/ClientNoteFiles";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { useCallback, useMemo } from "react";
import { Descendant, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { IFrameTextEditor } from "./IFrameTextEditor";
import { TextEditorElement } from "./TextEditorElement";
import { TextEditorLeaf } from "./TextEditorLeaf";

interface TextEditorToListNoteProps {
  files: FileMetadata[];
  content: Descendant[];
}

export function TextEditorToListNote({
  content,
  files,
}: TextEditorToListNoteProps) {
  const renderElement = useCallback(
    (props) => <TextEditorElement {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <TextEditorLeaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const handleBlur = useCallback(() => ReactEditor.deselect(editor), [editor]);

  return (
    <Slate editor={editor} initialValue={content}>
      <Flex css={{ gap: "$3", alignItems: "center" }}>
        <Caption css={{ color: "$gray500" }}>Anexos:</Caption>
        <ClientNoteFiles files={files} />
      </Flex>

      <ListDivider />

      <IFrameTextEditor onBlur={handleBlur}>
        <Editable
          // disableDefaultStyles
          style={{
            border: "none",
            outline: "none",
            overflowX: "hidden",
            width: "100%",
            height: "100%",
          }}
          readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Insira aqui seu texto"
          renderPlaceholder={({ children, attributes }) => (
            <div {...attributes}>
              <p>{children}</p>
            </div>
          )}
        />
      </IFrameTextEditor>
    </Slate>
  );
}
