import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";

export const useBreadcrumbsForPermissionsListPage = () => {
  const dispatch = useAppDispatch();

  const showBreadcrumbs = async () => {
    const breadcrumbs: Breadcrumb[] = [
      {
        item: "Configurações",
        url: "/profile/user-profile/info",
        notNavigate: true,
      },
      {
        item: "Minhas informações",
        url: "/profile/user-profile/info",
        notNavigate: true,
      },
      { item: "Permissões", url: "" },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return {
    showBreadcrumbs,
  };
};
