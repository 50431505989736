import {
  Button,
  Caption,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  Text,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { HiDocumentAdd } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { DropzoneToAttachFile } from "./DropzoneToAttachFile";

interface AttachFileToRegisterProps {
  onChange: (...event: any[]) => void;
  files: File[];
  titleOfDialog: string;
}

export function AttachFileToRegister({
  onChange,
  files,
  titleOfDialog,
}: AttachFileToRegisterProps) {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [title, setTitle] = useState(titleOfDialog);

  const handleSetFiles = () => {
    onChange(selectedFiles);
    setSelectedFiles(selectedFiles);
  };

  const cancelAttachedFiles = () => {
    onChange(files);
    setSelectedFiles(files);
  };

  const handleSetSelectedFilesFiles = (filesToSet: File[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...filesToSet]);
  };

  const handleRemoveFileAttachedFromRegister = (fileNameToRemove: string) => {
    const filesWithoutRemovedOne = selectedFiles.filter(
      (file) => file.name !== fileNameToRemove
    );
    setSelectedFiles(filesWithoutRemovedOne);
    onChange(filesWithoutRemovedOne);
  };

  const handleRemoveFileAttachedFromFiles = (fileNameToRemove: string) => {
    const filesWithoutRemovedOne = selectedFiles.filter(
      (file) => file.name !== fileNameToRemove
    );
    setSelectedFiles(filesWithoutRemovedOne);
  };

  useEffect(() => {
    setSelectedFiles(files);
  }, [files]);

  useEffect(() => {
    if (files && files.length > 1) {
      setTitle(`${files.length} arquivos anexados`);
    } else if (files && files.length === 1) {
      setTitle(files[0].name);
    } else {
      setTitle(titleOfDialog);
    }
  }, [files]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Flex css={{ flexDirection: "column", gap: "$2" }}>
          <Flex css={{ alignItems: "center", gap: "$2", cursor: "pointer" }}>
            <Flex
              css={{
                padding: "6px",
                color: "$primary600",
                border: "1px dotted $primary600",
                borderRadius: "4px",
              }}
            >
              <HiDocumentAdd size={22} />
            </Flex>
            <Caption css={{ color: "$gray800" }}>{title}</Caption>
          </Flex>
        </Flex>
      </DialogTrigger>
      {files && files.length > 0 && (
        <Flex css={{ width: "100%", flexWrap: "wrap", gap: "$3" }}>
          {files &&
            files.map((file) => {
              return (
                <Flex
                  css={{
                    alignItems: "center",
                    color: "$gray500",
                    fontSize: "16px",
                    border: "1px solid $gray300",
                    gap: "2px",
                    padding: "$1 $2",
                    borderRadius: "$sm",
                  }}
                  key={file.name}
                >
                  <Text css={{ color: "$gray600" }}>{file.name}</Text>
                  <MdClose
                    color="inherit"
                    fontSize="inherit"
                    cursor="pointer"
                    onClick={() =>
                      handleRemoveFileAttachedFromRegister(file.name)
                    }
                  />
                </Flex>
              );
            })}
        </Flex>
      )}
      <DialogContent
        onPointerDownOutside={(event) => {
          event.stopPropagation();
          cancelAttachedFiles();
        }}
        style={{ maxHeight: "700px" }}
      >
        <DialogClose asChild>
          <MdClose
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              cursor: "pointer",
            }}
            onClick={(event) => {
              event.stopPropagation();
              cancelAttachedFiles();
            }}
          />
        </DialogClose>

        <DialogTitle>
          <Flex css={{ flexDirection: "column" }}>
            Faça o upload e anexe arquivos ao atendimento
          </Flex>
        </DialogTitle>

        <DropzoneToAttachFile
          value={files}
          onAttachFiles={handleSetSelectedFilesFiles}
          onRemoveFileAttached={handleRemoveFileAttachedFromFiles}
        />

        <Flex
          css={{
            flexDirection: "row",
            gap: "$2",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <DialogClose asChild>
            <Button
              color="primary"
              bordered
              onClick={(event) => {
                event.stopPropagation();
                cancelAttachedFiles();
              }}
            >
              Cancelar
            </Button>
          </DialogClose>

          <DialogClose asChild>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                handleSetFiles();
              }}
            >
              Atualizar
            </Button>
          </DialogClose>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
