import { ListDivider } from "@/components/ListDivider";
import { Flex, Input } from "@gogeo-io/ui-library";
import { Search } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectReports } from "../store/reportsSlice";
import { ReportCard } from "./ReportCard";

export function ReportsList() {
  const reports = useSelector(selectReports);
  const [filteredReports, setFilteredReports] = useState(reports);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleInputChange = (e) => setSearchTerm(e.target.value);

  useEffect(() => {
    const filteredList = reports.filter((list) =>
      list.title.toLowerCase().includes(searchTerm)
    );
    setFilteredReports(filteredList);
  }, [searchTerm]);

  return (
    <>
      <Input
        placeholder="Busque por relatório"
        preffix={<Search fontSize="inherit" />}
        onChange={handleInputChange}
        isFullWidth
      />
      <ListDivider style={{ marginTop: "1rem" }} />

      <Flex css={{ flexDirection: "column" }}>
        {filteredReports.map((report, idx) => (
          <div key={report.dashboard_name}>
            <ReportCard key={report.dashboard_name} report={report} />
            {idx !== filteredReports.length - 1 && <ListDivider />}
          </div>
        ))}
      </Flex>
    </>
  );
}
