import { User, UserOnRelationship } from "@/core/models/user.model";
import {
  getUserRelationships,
  selectSelectedUsersToAssociate,
  selectSelectedUsersToDisassociate,
  selectUsersStatus,
  setAllUsersToAssociate,
  setAllUsersToDisassociate,
  setUsersToAssociate,
  setUsersToDisassociate,
  updateUserRelationships,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, DialogClose, Flex, Input } from "@gogeo-io/ui-library";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useRelatedUsersColumns } from "../hooks/useRelatedUsersColumns";
import { useUsersMixpanel } from "../hooks/useUsersMixpanel";
import { emitEventToChangeUsers } from "../store/emitEventToChangeUsersSlice";
import { RelatedUsersTable } from "./RelatedUsersTable";
import { UsersRelatedToUserSelectRole } from "./UsersRelatedToUserSelectRole";
import { UsersRelatedSkeleton } from "./skeleton/UsersRelatedSkeleton";

interface UsersRelatedToUserModalContentProps {
  user: User;
  onCloseDialog: () => void;
}

export function UsersRelatedToUserModalContent({
  user,
  onCloseDialog,
}: UsersRelatedToUserModalContentProps) {
  const [selectedRole, setSelectRole] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<UserOnRelationship[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserOnRelationship[]>([]);

  const usersStatus = useSelector(selectUsersStatus);
  const selectedUsersToAssociate = useSelector(selectSelectedUsersToAssociate);
  const selectedUsersToDisassociate = useSelector(
    selectSelectedUsersToDisassociate
  );

  const dispatch = useAppDispatch();
  const { changeUserRelationships } = useUsersMixpanel();

  const handleInputChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleSetUsersToAssociate = async (isChecked: boolean) => {
    if (isChecked) {
      await dispatch(setAllUsersToAssociate(users));
    } else {
      await dispatch(setAllUsersToDisassociate(users));
    }
  };

  const handleSaveRelationships = async () => {
    await dispatch(emitEventToChangeUsers(false));

    const userIdsToAssociate = selectedUsersToAssociate.map((user) => user.id);
    const userIdsToDisassociate = selectedUsersToDisassociate.map(
      (user) => user.id
    );

    await dispatch(
      updateUserRelationships({
        user_id: user.id,
        usersToAssociate: userIdsToAssociate,
        usersToDisassociate: userIdsToDisassociate,
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        changeUserRelationships(
          user,
          selectedUsersToAssociate,
          selectedUsersToDisassociate
        );
        toast.success("Ação realizada com sucesso");
      }
    });

    onCloseDialog();

    await dispatch(emitEventToChangeUsers(true));
  };

  useEffect(() => {
    let filteredUsers: UserOnRelationship[] = [];

    if (selectedRole === "all") {
      if (searchTerm === "") {
        filteredUsers = users;
      } else {
        filteredUsers = users.filter((user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
    } else {
      filteredUsers = users.filter(
        (user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          user.role === selectedRole
      );
    }

    setFilteredUsers(filteredUsers);
  }, [selectedRole, searchTerm]);

  const filterUsers = (searchTerm: string, usersToFilter: User[]): User[] => {
    if (searchTerm === "") {
      return usersToFilter;
    } else {
      const searchedUsers = usersToFilter.filter(
        (user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          String(user.id).toLowerCase().includes(searchTerm.toLowerCase())
      );
      return searchedUsers;
    }
  };

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  const relatedUsersColumns = useRelatedUsersColumns();

  useEffect(() => {
    const loadUsers = async () => {
      setIsLoading(true);

      await dispatch(getUserRelationships({ user_id: user.id })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            const relatedUsers = res.payload.related;
            const unrelatedUsers = res.payload.unrelated;

            setUsers([...relatedUsers, ...unrelatedUsers]);
            setFilteredUsers([...relatedUsers, ...unrelatedUsers]);

            await dispatch(setUsersToAssociate(relatedUsers));
            await dispatch(setUsersToDisassociate(unrelatedUsers));

            setIsLoading(false);
          }
        }
      );
    };

    loadUsers();
  }, []);

  return (
    <>
      {isLoading ? (
        <UsersRelatedSkeleton />
      ) : (
        <Flex css={{ flexDirection: "column", gap: "$4", mt: "$2" }}>
          <Flex css={{ gap: "$2", alignItems: "center" }}>
            <Input
              placeholder="Ex. Vendedor 4"
              onChange={(e) => debounceOnChangeInput(e.target.value)}
            />
            <UsersRelatedToUserSelectRole
              selectedRole={selectedRole}
              onSelectRole={(value) => setSelectRole(value)}
            />
          </Flex>

          <Flex css={{ gap: "$2" }}>
            <Button bordered onClick={() => handleSetUsersToAssociate(true)}>
              Associar todos
            </Button>
            <Button bordered onClick={() => handleSetUsersToAssociate(false)}>
              Desassociar todos
            </Button>
          </Flex>

          <RelatedUsersTable
            status={usersStatus}
            data={filteredUsers}
            columns={relatedUsersColumns}
          />

          {filteredUsers && filteredUsers.length > 0 && (
            <Flex
              css={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                gap: "$2",
              }}
            >
              <DialogClose asChild>
                <Button
                  bordered
                  onClick={(event) => {
                    event.stopPropagation();
                    onCloseDialog();
                  }}
                >
                  Cancelar
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSaveRelationships();
                  }}
                >
                  Salvar
                </Button>
              </DialogClose>
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
}
