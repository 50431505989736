import {
  Input,
  Select,
  SelectItem,
  SelectItemText,
} from "@gogeo-io/ui-library";
import { useDateByDateFilter } from "@hooks/useDateByDateField";
import { useEffect, useState } from "react";

interface InThisDateSelectProps {
  filterId: number;
  value: string;
  onFilterValueChange: (filterId: number, newValue: string) => void;
}

export function InThisDateSelect({
  filterId,
  value,
  onFilterValueChange,
}: InThisDateSelectProps) {
  const { getValuesByDateFilter } = useDateByDateFilter();

  const dateFilter = getValuesByDateFilter(
    value !== undefined ? value : "inthis1months"
  );
  const [inThisDate, setInThisDate] = useState(
    dateFilter.inthis !== undefined ? dateFilter.inthis.date_option : "months"
  );
  const [inThisDateNumber, setInThisDateNumber] = useState(
    dateFilter.inthis !== undefined ? dateFilter.inthis.value : 1
  );

  const handleInputChange = (value: number) => {
    setInThisDateNumber(value);
  };

  useEffect(() => {
    const formattedDate = `inthis${inThisDateNumber}${inThisDate}`;
    onFilterValueChange(filterId, formattedDate);
  }, [inThisDate, inThisDateNumber]);

  return (
    <>
      <Input
        type="number"
        style={{ width: "55px" }}
        defaultValue={inThisDateNumber}
        onChange={(e) => handleInputChange(Number(e.target.value))}
      />
      <Select
        defaultValue={inThisDate}
        ariaLabel="Past Date"
        onValueChange={(v) => setInThisDate(v)}
      >
        <SelectItem value="days">
          <SelectItemText>Dias</SelectItemText>
        </SelectItem>
        <SelectItem value="weeks">
          <SelectItemText>Semanas</SelectItemText>
        </SelectItem>
        <SelectItem value="months">
          <SelectItemText>Meses</SelectItemText>
        </SelectItem>
        <SelectItem value="years">
          <SelectItemText>Anos</SelectItemText>
        </SelectItem>
      </Select>
    </>
  );
}
