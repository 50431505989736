import { SelectOption } from "@/core/models/advanced-filters.model";
import { DynamicFormFields } from "@/core/models/business-associate.model";
import { Checkbox, Flex, Text } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { useState } from "react";
import { FieldError } from "react-hook-form";

interface BussinessAssociateGenericFormMultiSelectTypeProps {
  formField: DynamicFormFields;
  error: FieldError;
  onChange: (...event: any[]) => void;
}

export function BussinessAssociateGenericFormMultiSelectType({
  formField,
  error,
  onChange,
}: BussinessAssociateGenericFormMultiSelectTypeProps) {
  const [optionsSelected, setOptionsSelected] = useState(
    formField.value as unknown as string[]
  );

  const handleCheckboxChange = (isChecked: boolean, option: SelectOption) => {
    if (isChecked) {
      const newOptions = [...optionsSelected, option.value];
      setOptionsSelected(newOptions);
      onChange(newOptions);
    } else {
      const newOptions = optionsSelected.filter((opt) => opt !== option.value);
      setOptionsSelected(newOptions);
      onChange(newOptions);
    }
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <Flex
        css={{
          flexDirection: "column",
          gap: "0.5rem",
          maxHeight: "300px",
          overflowY: "scroll",
        }}
      >
        {formField.options &&
          formField.options.map((attribute) => {
            return (
              <Flex
                key={attribute.id}
                css={{ gap: "0.5rem", alignItems: "center" }}
              >
                <Checkbox
                  defaultChecked={optionsSelected.includes(attribute.value)}
                  onCheckedChange={(e) =>
                    handleCheckboxChange(Boolean(e), attribute)
                  }
                />

                <Text>{attribute.value}</Text>
              </Flex>
            );
          })}
      </Flex>

      {!!error && <G_UI.ErrorMessage>{error?.message}</G_UI.ErrorMessage>}
    </Flex>
  );
}
