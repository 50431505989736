import styled from "styled-components";

export const TimelineAttendanceRecord = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.md};
  padding: ${(props) => props.theme.space[4]};
  border-radius: ${({ theme }) => theme.radii.base};
`;
