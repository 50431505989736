import { Tooltip, TooltipContent, TooltipTrigger } from "@gogeo-io/ui-library";

export interface CellHeaderWithoutSortProps {
  headerName: string;
}

export function CellHeaderWithoutSort({
  headerName,
}: CellHeaderWithoutSortProps) {
  return (
    <Tooltip>
      <TooltipTrigger style={{ background: "transparent" }}>
        {headerName}
      </TooltipTrigger>
      <TooltipContent aria-label="Título da coluna">
        {headerName}
      </TooltipContent>
    </Tooltip>
  );
}
