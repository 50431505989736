import { Box, Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";
import styled from "styled-components";

export function SelectGenericOptionSkeleton() {
  const timesToRepeat = Array.from(Array(5).keys());

  return (
    <Flex css={{ flexDirection: "column", gap: "$4" }}>
      <Flex css={{ flexDirection: "column", gap: "$4" }}>
        <Flex css={{ flexDirection: "column", gap: "$1" }}>
          <RLSSkeleton width={70} height={25} />

          <RLSSkeleton width={180} height={25} />
        </Flex>
        <Box css={{ maxHeight: "300px", overflowY: "scroll" }}>
          <Flex css={{ flexDirection: "column", gap: "$2" }}>
            {timesToRepeat.map((c) => {
              return <RLSSkeleton key={c} width={190} height={25} />;
            })}
          </Flex>
        </Box>
      </Flex>

      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <RLSSkeleton width={120} height={30} />
      </Flex>
    </Flex>
  );
}

interface SelectUserProps {
  selected?: boolean;
}

export const SelectUser = styled.div<SelectUserProps>`
  padding: 0.75rem;
  cursor: pointer;
  border-radius: ${(props) => props.theme.sizes[1]};

  background-color: ${(props) =>
    props.selected ? props.theme.colors.gray100 : "transparent"};

  &:hover {
    background: ${(props) => props.theme.colors.gray100};
  }
`;
