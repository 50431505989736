import { ErrorPageLayout } from "@/layouts/ErrorPageLayout";
import { Button, Heading } from "@gogeo-io/ui-library";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PageNotFoundSvg from "../assets/page-not-found.svg";

export function Page404() {
  const navigate = useNavigate();

  const handleNavigateToAttendancePage = () => {
    navigate("/attendance");
  };

  return (
    <ErrorPageLayout
      content={
        <Wrapper>
          <img src={PageNotFoundSvg} alt="imagem de página não encontrada" />
          <Heading size="title3" className="title">
            Página Não Encontrada
          </Heading>
          <Button
            onClick={handleNavigateToAttendancePage}
            style={{ marginTop: "20px" }}
          >
            Vá para página de atendimento
          </Button>
        </Wrapper>
      }
    />
  );
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[4]};

  img {
    width: 600px;
  }

  .title {
    color: ${({ theme }) => theme.colors.primary500};
  }
`;
