import { SalesmanToRuptureRule } from "@/core/models/user.model";
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  RightSlot,
} from "@gogeo-io/ui-library";
import { FiMoreHorizontal } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import { EditUserFromRuptureRuleModal } from "./EditUserFromRuptureRuleModal";
import { RemoveUserFromRuptureRuleModal } from "./RemoveUserFromRuptureRuleModal";

interface RuptureRuleConsumersActionsCellProps {
  user: SalesmanToRuptureRule;
  onRemoveUser: (user: SalesmanToRuptureRule) => void;
  onEditUser: (user: SalesmanToRuptureRule) => void;
}

export function RuptureRuleConsumersActionsCell({
  user,
  onRemoveUser,
  onEditUser,
}: RuptureRuleConsumersActionsCellProps) {
  const handleRemoveUser = (user: SalesmanToRuptureRule) => {
    onRemoveUser(user);
  };

  const handleEditUser = (user: SalesmanToRuptureRule) => {
    onEditUser(user);
  };

  return (
    <Box>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton color="gray" type="ghost" size="small">
            <FiMoreHorizontal />
          </IconButton>
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5}>
          <EditUserFromRuptureRuleModal user={user} onEditUser={handleEditUser}>
            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
              Editar
              <RightSlot>
                <MdEdit />
              </RightSlot>
            </DropdownMenuItem>
          </EditUserFromRuptureRuleModal>
        </DropdownMenuContent>
        <DropdownMenuContent sideOffset={5}>
          <RemoveUserFromRuptureRuleModal
            user={user}
            onRemoveUser={handleRemoveUser}
          >
            <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
              Remover
              <RightSlot>
                <MdDelete />
              </RightSlot>
            </DropdownMenuItem>
          </RemoveUserFromRuptureRuleModal>
        </DropdownMenuContent>
      </DropdownMenu>
    </Box>
  );
}
