import { Flex } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { selectReportFrame } from "../store/reportSlice";

export function ReportFrame() {
  const reportFrame = useSelector(selectReportFrame);

  return (
    <Flex css={{ width: "100%", height: "100%" }}>
      <iframe src={reportFrame} style={{ width: "100%" }} />
    </Flex>
  );
}
