import { FileMetadata } from "@/core/models/file.model";
import { getFile } from "@/core/store/uploadFile/uploadFileSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFile } from "@/hooks/useFile";
import {
  Caption,
  Flex,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { useAttachmentIcon } from "../../hooks/useAttachmentIcon";

interface ClientNoteFilesProps {
  files: FileMetadata[];
  onOpenNote: () => void;
}

export function ClientNoteFilesOnItem({
  files,
  onOpenNote,
}: ClientNoteFilesProps) {
  const dispatch = useAppDispatch();
  const getAttachmentIcon = useAttachmentIcon();
  const { downloadFileByUrl } = useFile();

  const handleDowloadFile = async (
    fileId: number,
    filename: string,
    type: string
  ) => {
    await dispatch(getFile({ fileId })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        downloadFileByUrl(res.payload.url, type, filename);
      }
    });
  };

  const first5files = files && files.slice(0, 5);
  const othersFiles = files && files.slice(5);

  return (
    <Flex
      css={{
        flexWrap: "wrap",
        gap: "$2",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {first5files && first5files.length > 0 && first5files[0] !== null ? (
        first5files.map((file, idx) => {
          return (
            <div key={idx}>
              {file !== null && (
                <Tooltip>
                  <TooltipTrigger style={{ background: "transparent" }}>
                    <Flex
                      css={{
                        fontSize: "20px",
                        color: "$gray600",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleDowloadFile(file.id, file.filename, file.type)
                      }
                    >
                      {getAttachmentIcon(file.filename)}
                    </Flex>
                  </TooltipTrigger>
                  <TooltipContent aria-label="Arquivos da sua anotação">
                    {file.filename}
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          );
        })
      ) : (
        <Caption css={{ color: "$gray500" }}>Sem arquivos na nota</Caption>
      )}
      {othersFiles && othersFiles.length > 0 && (
        <Caption
          css={{ color: "$gray500", cursor: "pointer" }}
          onClick={onOpenNote}
        >
          Ver todos
        </Caption>
      )}
    </Flex>
  );
}
