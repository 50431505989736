import { LabelWithTooltip } from "@/components/LabelWithTooltip";

interface SharedPreDefinedMessageCellProps {
  value: boolean;
}

export function SharedPreDefinedMessageCell({
  value,
}: SharedPreDefinedMessageCellProps) {
  return (
    <>
      {value ? (
        <LabelWithTooltip
          title="Compartilhada"
          description="Mensagem compartilhada com os integrantes de sua empresa"
        />
      ) : (
        <LabelWithTooltip
          title="Pessoal"
          description="Mensagem pessoal, apenas você pode vê-la"
          schemeColor="blue"
        />
      )}
    </>
  );
}
