import { CellHeaderWithoutSort } from "@/components/CustomCells/CellHeaderWithoutSort";
import { User, UserRole } from "@/core/models/user.model";
import { selectColumnsToSort } from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { SelectAllUsersCheckbox } from "../components/SelectAllUsersCheckbox";
import { SelectUserToBathActions } from "../components/SelectUserToBatchActions";
import { UserActionsCell } from "../components/cells/UserActionsCell";
import { UserActiveCell } from "../components/cells/UserActiveCell";
import { UserEmailCell } from "../components/cells/UserEmailCell";
import { UserNameCell } from "../components/cells/UserNameCell";
import { UserPhoneCell } from "../components/cells/UserPhoneCell";
import { UserRoleCell } from "../components/cells/UserRoleCell";

export const useUsersColumns = () => {
  const selectedColumnsToSort = useSelector(selectColumnsToSort);

  const selectUserColum = useMemo(() => {
    return [
      {
        title: () => <SelectAllUsersCheckbox />,
        dataIndex: "user",
        key: "user",
        render: (text: string, record: User) => (
          <SelectUserToBathActions user={record} />
        ),
        width: "50px",
      },
    ];
  }, [selectedColumnsToSort]);

  const userRoleColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Função" />,
        dataIndex: "role",
        key: "role",
        render: (text: UserRole) => <UserRoleCell role={text} />,
      },
    ];
  }, [selectedColumnsToSort]);

  const userNameColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Nome" />,
        dataIndex: "user",
        key: "name",
        render: (text: string, record: User) => (
          <UserNameCell
            name={record.name}
            id={record.salesman_id ? record.salesman_id : "-"}
          />
        ),
      },
    ];
  }, [selectedColumnsToSort]);

  const userEmailColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Email" />,
        dataIndex: "email",
        key: "email",
        render: (text: string) => <UserEmailCell email={text} />,
      },
    ];
  }, [selectedColumnsToSort]);

  const userPhoneColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Telefone" />,
        dataIndex: "phone",
        key: "phone",
        render: (text: string) => <UserPhoneCell phone={text} />,
      },
    ];
  }, [selectedColumnsToSort]);

  const userActiveColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Status" />,
        dataIndex: "active",
        key: "active",
        render: (text: boolean) => <UserActiveCell active={text} />,
      },
    ];
  }, [selectedColumnsToSort]);

  const userActionsColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Ações" />,
        dataIndex: "",
        key: "actions",
        render: (text: string, record: User) => (
          <UserActionsCell user={record} />
        ),
        width: "70px",
      },
    ];
  }, [selectedColumnsToSort]);

  return [
    ...selectUserColum,
    ...userRoleColumn,
    ...userNameColumn,
    ...userEmailColumn,
    ...userPhoneColumn,
    ...userActiveColumn,
    ...userActionsColumn,
  ];
};
