import { Calendar } from "@/components/Calendar";
import { useFormatDate } from "@/hooks/useFormatDate";
import {
  Button,
  Popover,
  PopoverActions,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@gogeo-io/ui-library";
import DateRangeIcon from "@mui/icons-material/DateRange";
import G_UI from "@ui/index";
import { isEmpty } from "lodash";
import { useState } from "react";
import UI from "../ui";

interface PickDateProps {
  onChange: (...event: any[]) => void;
  value: string;
  hasError: boolean;
  errorMessage: string;
}

export function ReminderPickDate({
  onChange,
  value,
  hasError,
  errorMessage,
}: PickDateProps) {
  const { formatLongDatePtBr } = useFormatDate();

  const [date, setDate] = useState(value);

  const handleDateChange = () => {
    onChange(date);
  };

  const handleCancelDate = () => {
    onChange("");
  };

  return (
    <>
      <Popover>
        <PopoverTrigger style={{ background: "transparent" }}>
          <Text css={{ marginBottom: "$2", textAlign: "left" }}>
            Selecione a data do lembrete:
          </Text>
          <UI.ReminderInputDate hasError={hasError}>
            {!isEmpty(value) ? (
              <Text>{formatLongDatePtBr(value, true)}</Text>
            ) : (
              <Text>Selecione uma data</Text>
            )}
            <div className="icon">
              <DateRangeIcon />
            </div>
          </UI.ReminderInputDate>
          {hasError && <G_UI.ErrorMessage>{errorMessage}</G_UI.ErrorMessage>}
        </PopoverTrigger>
        <PopoverContent
          align="center"
          side="right"
          size="lg"
          sideOffset={-70}
          style={{ zIndex: "9999" }}
        >
          <UI.ReminderPickDateContent>
            <Calendar dateToSet={setDate} disablePastDate />
            <PopoverActions>
              <PopoverClose onClick={handleCancelDate} closeOnTop={false}>
                <Button color="danger" variant="soft">
                  Cancelar
                </Button>
              </PopoverClose>
              <PopoverClose onClick={handleDateChange} closeOnTop={false}>
                <Button>Selecionar data</Button>
              </PopoverClose>
            </PopoverActions>
          </UI.ReminderPickDateContent>
        </PopoverContent>
      </Popover>
    </>
  );
}
