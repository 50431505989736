import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Client } from "@core/models/client.model";
import {
  Caption,
  Flex,
  Heading,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import styled from "styled-components";
import { AvatarOfClientWithHoverInfo } from "./AvatarOfClientWithHoverInfo";

interface ClientInfoProps {
  client: Client;
}

export function ClientProfile({ client }: ClientInfoProps) {
  const isLaptop = useMediaQuery({ screen: "laptop" });

  const renderClientName = (client_name: string) => {
    if (!isLaptop) {
      if (client_name.length > 25) {
        return `${client_name.toLowerCase().slice(0, 24)}...`;
      }
    } else {
      if (client_name.length > 16) {
        return `${client_name.toLowerCase().slice(0, 15)}...`;
      }
    }

    return client_name.toLowerCase();
  };

  const renderClientFantasyName = (fantasy_name: string) => {
    if (!isLaptop) {
      if (fantasy_name.length > 25) {
        return `${fantasy_name.toLowerCase().slice(0, 24)}...`;
      }
    } else {
      if (fantasy_name.length > 16) {
        return `${fantasy_name.toLowerCase().slice(0, 15)}...`;
      }
    }

    return fantasy_name.toLowerCase();
  };

  return (
    <Container>
      <AvatarOfClientWithHoverInfo client={client} />

      <Flex css={{ flexDirection: "column", alignItems: "flex-start" }}>
        <Tooltip>
          <TooltipTrigger
            style={{ background: "transparent", cursor: "pointer" }}
          >
            <Heading size="subtitle1" css={{ textTransform: "capitalize" }}>
              {renderClientName(client.name)}
            </Heading>
          </TooltipTrigger>
          <TooltipContent aria-label={client.name}>
            {client.name}
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger
            style={{ background: "transparent", cursor: "pointer" }}
          >
            <Text css={{ color: "$gray500", textTransform: "capitalize" }}>
              {renderClientFantasyName(client.fantasy_name)}
            </Text>
          </TooltipTrigger>
          <TooltipContent aria-label={client.fantasy_name}>
            {client.fantasy_name}
          </TooltipContent>
        </Tooltip>

        <Caption bold css={{ marginTop: "$1", color: "$gray500" }}>
          {client.id}
        </Caption>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[2]};
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
  }
`;
