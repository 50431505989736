import { useByFeedback } from "@/hooks/useByFeedback";
import { Caption } from "@gogeo-io/ui-library";
import styled from "styled-components";

type ClientStatusProps = {
  feedback_id: number;
};

export default function ClientStatus({ feedback_id }: ClientStatusProps) {
  const { getTextByFeedback, getIconByStatus, getColorByFeedback } =
    useByFeedback();

  return (
    <StatusPill style={{ backgroundColor: getColorByFeedback(feedback_id) }}>
      <StatusIcon>{getIconByStatus(feedback_id)}</StatusIcon>
      <Caption css={{ color: "$white" }}>
        {getTextByFeedback(feedback_id)}
      </Caption>
    </StatusPill>
  );
}

export const StatusPill = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
  padding-inline: 0.75rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 999rem;
  color: white;
  width: fit-content;
`;

export const StatusIcon = styled.div`
  margin-right: 0.25rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
`;
