import {
  BusinessGoal,
  BusinessGoalCategorie,
} from "@/core/models/business-goal.model";
import {
  Button,
  Caption,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  Input,
} from "@gogeo-io/ui-library";
import { Close as CloseIcon } from "@mui/icons-material";
import { useState } from "react";
import { MdChevronRight, MdHelpOutline } from "react-icons/md";
import styled from "styled-components";

interface BusinessGoalCategoriesCellProps {
  businessGoal: BusinessGoal;
  onUpdateBusinessGoalCategories: (
    id: number,
    categories: BusinessGoalCategorie[]
  ) => Promise<void>;
}

export function BusinessGoalCategoriesCell({
  businessGoal,
  onUpdateBusinessGoalCategories,
}: BusinessGoalCategoriesCellProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [selectedCategorie, setSelectedCategorie] =
    useState<BusinessGoalCategorie>({} as BusinessGoalCategorie);

  const handleCategorieNameChange = (newCategorie: BusinessGoalCategorie) => {
    setSelectedCategorie(newCategorie);
  };

  const handleSaveCategorie = () => {
    const newCategories = businessGoal.categories.map((b) =>
      b.field === selectedCategorie.field ? { ...selectedCategorie } : b
    );
    onUpdateBusinessGoalCategories(businessGoal.id, newCategories);
    setDialogIsOpen(false);
  };

  const handleSetCategorieSelected = (categorie: BusinessGoalCategorie) => {
    setSelectedCategorie(categorie);
  };

  return (
    <Dialog open={dialogIsOpen} onOpenChange={setDialogIsOpen}>
      <DialogTrigger asChild>
        <Container>
          {businessGoal.categories.map((categorie) => {
            return (
              <Item
                key={categorie.mapped_field}
                onClick={() => handleSetCategorieSelected(categorie)}
              >
                {categorie.mapped_field}
              </Item>
            );
          })}
        </Container>
      </DialogTrigger>
      <DialogContent style={{ width: "550px" }}>
        <DialogTitle>
          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            Mapeamento de Categorias
            <Flex css={{ alignItems: "center", gap: "$2", color: "$gray400" }}>
              <MdHelpOutline color="inherit" />
              <Caption css={{ color: "$gray400" }}>
                Os campos da esquerda são apenas para controle interno. Não se
                preocupe, altere conforme sua necessidade.
              </Caption>
            </Flex>
          </Flex>
        </DialogTitle>

        <DialogClose asChild>
          <CloseIcon
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              cursor: "pointer",
            }}
          />
        </DialogClose>

        <Flex css={{ flexDirection: "column", gap: "1.5rem", marginTop: "$6" }}>
          <Flex css={{ alignItems: "center", gap: "$2", color: "$gray400" }}>
            <Input value={selectedCategorie.field} disabled />
            <MdChevronRight color="inherit" />
            <Input
              defaultValue={selectedCategorie.mapped_field}
              onChange={(e) =>
                handleCategorieNameChange({
                  field: selectedCategorie.field,
                  mapped_field: e.target.value,
                } as BusinessGoalCategorie)
              }
            />
          </Flex>
        </Flex>

        <Flex
          css={{
            marginTop: "$6",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={handleSaveCategorie}>Salvar alteração</Button>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[2]};
`;

const Item = styled.div`
  background: ${(props) => props.theme.colors.gray100};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  color: ${(props) => props.theme.colors.gray500};
  padding: 0.375rem 1.75rem;
  border-radius: 6px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.75rem;

  &:hover {
    background: ${(props) => props.theme.colors.gray200};
    color: ${(props) => props.theme.colors.gray700};
  }
`;
