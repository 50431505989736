import RLSSkeleton from "react-loading-skeleton";
import UI from "../ui";

export function ReminderSkeleton() {
  const timesToRepeat = Array.from(Array(3).keys());

  return (
    <UI.ReminderSkeleton>
      <UI.ReminderSkeletonUserInfo>
        <div className="user">
          <RLSSkeleton width={130} height={30} />
          <RLSSkeleton width={115} height={20} />
        </div>
        <RLSSkeleton width={48} height={48} borderRadius={4} />
      </UI.ReminderSkeletonUserInfo>
      <div>
        <UI.ReminderList>
          {timesToRepeat.map((t) => {
            return (
              <UI.ReminderItem key={t}>
                <RLSSkeleton width={20} height={20} borderRadius={1} />

                <div className="info">
                  <RLSSkeleton width={120} height={20} />
                  <RLSSkeleton width={70} height={15} />
                </div>
              </UI.ReminderItem>
            );
          })}
        </UI.ReminderList>
        <UI.ReminderSkeletonAddButton>
          <RLSSkeleton width={40} height={40} borderRadius={4} />
        </UI.ReminderSkeletonAddButton>
      </div>
    </UI.ReminderSkeleton>
  );
}
