import { changeParams } from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Select, SelectItem, SelectItemText, Text } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectAllClientsSelectedToChangeParams,
  selectClientSelectedToChangeParams,
} from "../../store/clientSelectedToChangeParamsSlice";

export function ChangeParamsFormFrequency() {
  const [frequency, setFrequency] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const selectedClientsToChangeParams = useSelector(
    selectClientSelectedToChangeParams
  );
  const allClientsSelectedToChangeParams = useSelector(
    selectAllClientsSelectedToChangeParams
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allClientsSelectedToChangeParams) {
      setFrequency("indeterminate");
      setErrorMessage("");
    } else {
      if (!isEmpty(selectedClientsToChangeParams)) {
        if (selectedClientsToChangeParams.length === 1) {
          const clientSelected = selectedClientsToChangeParams[0];
          if (clientSelected.params.freq !== undefined) {
            setFrequency(String(clientSelected.params.freq));
            setErrorMessage("");
          } else {
            setFrequency("indeterminate");
            setErrorMessage("*Atenção parâmetro não configurado");
          }
        } else {
          setFrequency("indeterminate");
          setErrorMessage("");
        }
      } else {
        setFrequency("indeterminate");
        setErrorMessage("");
      }
    }
  }, [selectedClientsToChangeParams, allClientsSelectedToChangeParams]);

  const handleSelectValueChange = async (value: string) => {
    setFrequency(value);
    if (value !== "indeterminate") {
      await dispatch(changeParams({ freq: Number(value) }));
    } else {
      await dispatch(changeParams({ freq: "indeterminate" }));
    }
  };

  const selectedIsDisabled: boolean =
    selectedClientsToChangeParams.length <= 0 &&
    !allClientsSelectedToChangeParams;

  return (
    <Container>
      <div className="header">
        <Text size="body1" css={{ color: "$black" }}>
          Frequência de atendimento
        </Text>
      </div>
      <div className="content">
        <Select
          defaultValue={String(frequency)}
          value={String(frequency)}
          ariaLabel="Select filter"
          onValueChange={handleSelectValueChange}
          disabled={selectedIsDisabled}
        >
          <SelectItem value="7">
            <SelectItemText>7</SelectItemText>
          </SelectItem>
          <SelectItem value="14">
            <SelectItemText>14</SelectItemText>
          </SelectItem>
          <SelectItem value="21">
            <SelectItemText>21</SelectItemText>
          </SelectItem>
          <SelectItem value="28">
            <SelectItemText>28</SelectItemText>
          </SelectItem>
          <SelectItem value="42">
            <SelectItemText>42</SelectItemText>
          </SelectItem>
          <SelectItem value="56">
            <SelectItemText>56</SelectItemText>
          </SelectItem>
          <SelectItem value="84">
            <SelectItemText>84</SelectItemText>
          </SelectItem>
          <SelectItem value="indeterminate">
            <SelectItemText>Não alterar</SelectItemText>
          </SelectItem>
        </Select>

        <Text size="body1" css={{ color: "$black" }}>
          Dias
        </Text>
      </div>
      {errorMessage && <G_UI.Info>{errorMessage}</G_UI.Info>}
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};

  .header {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.space[2]};
  }

  .content {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.space[2]};
  }
`;
