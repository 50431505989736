import { ErrorContent } from "@/components/ErrorContent";
import { TrackingEvents } from "@/config/mixpanel";
import { selectClientsHasError500 } from "@/features/clients/store/clientsSlice";
import { useMixpanel } from "@/hooks/useMixpanel";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ListViewOfUsers } from "../components/ListViewOfUsers";
import { useBreadcrumbsForUsersListPage } from "../hooks/useBreadcrumbsForUsersListPage";

export function UsersListPage() {
  const hasError500 = useSelector(selectClientsHasError500);

  const { showBreadcrumbs } = useBreadcrumbsForUsersListPage();
  const { pageViewed } = useMixpanel();

  useEffect(() => {
    showBreadcrumbs();
    pageViewed(TrackingEvents.USERS_MANAGMENT_PAGE);
  }, []);

  return (
    <>
      {hasError500 ? (
        <ErrorContent size="medium">
          Não conseguimos buscar os clientes de sua lista, tente novamente mais
          tarde ou atualize a página
        </ErrorContent>
      ) : (
        <ListViewOfUsers />
      )}
    </>
  );
}
