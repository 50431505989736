import { AverageTicketSkeleton } from "@/components/Skeleton/AverageTicketSkeleton";
import { StatusSkeleton } from "@/components/Skeleton/StatusSkeleton";
import { TimeWithoutBuySkeleton } from "@/components/Skeleton/TimeWithoutBuySkeleton";
import { Flex } from "@gogeo-io/ui-library";

export function ClientsTableSkeletonRow() {
  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <AverageTicketSkeleton />

      <TimeWithoutBuySkeleton />

      <StatusSkeleton />
    </Flex>
  );
}
