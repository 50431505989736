import { PriceRange } from "@/components/PriceRange";
import { ProductsFilter } from "@/core/models/productsSugestion.model";
import { useState } from "react";
import UI from "../ui";

interface ProductsFilterPriceProps {
  filters: ProductsFilter;
  onFiltersChange: (filters: ProductsFilter) => void;
}

export function ProductsFilterPrice({
  filters,
  onFiltersChange,
}: ProductsFilterPriceProps) {
  const [minValue, setMinValue] = useState(filters.unit_value?.min_value);
  const [maxValue, setMaxValue] = useState(filters.unit_value?.max_value);

  const handleSetMinValue = (value: string) => {
    setMinValue(Number(value));
    onFiltersChange({
      ...filters,
      unit_value: { ...filters.unit_value, min_value: Number(value) },
    });
  };

  const handleSetMaxValue = (value: string) => {
    setMaxValue(Number(value));
    onFiltersChange({
      ...filters,
      unit_value: { ...filters.unit_value, max_value: Number(value) },
    });
  };

  return (
    <UI.ProductsFilterPrice>
      <PriceRange
        selectedMinValue={minValue}
        selectedMaxValue={maxValue}
        onSelectMinValue={handleSetMinValue}
        onSelectMaxValue={handleSetMaxValue}
      />
    </UI.ProductsFilterPrice>
  );
}
