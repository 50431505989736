import loadable from "@loadable/component";
import { DynamicIconProps, DynamicIconProvider } from "./DynamicIconProvider";

export function DynamicMdIcon({ ...props }: DynamicIconProps) {
  const Icon = loadable(() => import(`react-icons/md/index.js`), {
    resolveComponent: (el: JSX.Element) => {
      const key = props.icon as keyof JSX.Element;
      if (key in el) {
        return el[key];
      } else {
        const backupKey = `MdOutlineQuestionMark` as keyof JSX.Element;
        return el[backupKey];
      }
    },
  });

  return (
    <DynamicIconProvider {...props}>
      <Icon />
    </DynamicIconProvider>
  );
}
