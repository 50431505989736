import { TrackingEvents } from "@/config/mixpanel";
import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import {
  getClientAttrOpts,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import { getClientMatchTypes } from "@/features/clientInfo/store/matchTypesSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useMixpanel } from "@/hooks/useMixpanel";
import { PageLayout } from "@/layouts/PageLayout";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { NoListOfTagsSelected } from "../components/NoListOfTagsSelected";
import { TagGroupList } from "../components/TagsGroupList";

export function AttendancePage() {
  const [matchTypesStatus, setMatchTypesStatus] = useState("loading");
  const [clientAttrOptsStatus, setClientAttrOptsStatus] = useState("loading");

  const { pathname } = useLocation();
  const { pageViewed } = useMixpanel();
  const dispatch = useAppDispatch();

  useEffect(() => {
    pageViewed(TrackingEvents.CLIENTS_PAGE);

    async function loadBreadcrumbs() {
      const breadcrumbs: Breadcrumb[] = [
        { item: "Atendimento", url: "/attendance" },
      ];
      await dispatch(setBreadcrumbs(breadcrumbs));
    }

    async function loadClientAttrOpts() {
      await dispatch(getClientAttrOpts()).then(async (obj) => {
        if (obj.meta.requestStatus === "fulfilled") {
          await dispatch(setDefaultAttributesToFilter());
          setClientAttrOptsStatus("fulfilled");
        }
      });
    }

    async function loadMatchTypes() {
      await dispatch(getClientMatchTypes()).then(async (obj) => {
        if (obj.meta.requestStatus === "fulfilled") {
          setMatchTypesStatus("fulfilled");
        }
      });
    }

    loadBreadcrumbs();
    loadClientAttrOpts();
    loadMatchTypes();
  }, []);

  return (
    <PageLayout
      leftSidebar={<TagGroupList />}
      content={
        pathname === "/attendance" ? (
          <NoListOfTagsSelected />
        ) : (
          <>
            {matchTypesStatus === "fulfilled" &&
              clientAttrOptsStatus === "fulfilled" && <Outlet />}
          </>
        )
      }
    />
  );
}
