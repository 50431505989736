import { BusinessGoal } from "../models/business-goal.model";

export const businessGoalsMocks: BusinessGoal[] = [
  {
    id: 1,
    name: "Prospecção",
    color: "primary",
    categories: [
      { id: 1, field: "Não Vendi", mapped_field: "Sem Venda" },
      { id: 2, field: "Vendi", mapped_field: "Positivei" },
      { id: 3, field: "Em andamento", mapped_field: "Registro ativo" },
    ],
  },
  {
    id: 2,
    name: "Venda Base",
    color: "blue",
    categories: [
      { id: 1, field: "Não Vendi", mapped_field: "Não vendi" },
      { id: 2, field: "Vendi", mapped_field: "Vendi" },
      { id: 3, field: "Em andamento", mapped_field: "Em andamento" },
    ],
  },
  {
    id: 3,
    name: "Acompanhamento",
    color: "red",
    categories: [
      { id: 1, field: "Não Vendi", mapped_field: "Sem Venda" },
      { id: 2, field: "Vendi", mapped_field: "Positivei" },
      { id: 3, field: "Em andamento", mapped_field: "Registro ativo" },
    ],
  },
];
