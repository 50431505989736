import { AttendanceJustification } from "@/core/models/attendance-justification.model";
import { useMemo } from "react";
import { PreDefinedMessagesActionsCell } from "../components/CustomCells/PreDefinedMessagesActionsCell";

export const useAttendanceJustificationsColumns = (
  onDeleteAttendanceJustificationsMessage: (id: number) => void,
  onUpdateAttendanceJustificationsMessage: (
    data: AttendanceJustification
  ) => Promise<void>
) => {
  const columns = useMemo(
    () => [
      {
        Header: "Objetivo de Negócio",
        accessor: (row) => row.subject,
        Cell: ({ value }) => {
          return <></>;
        },
      },
      {
        Header: "Categoria",
        accessor: (row) => row.title,
        Cell: ({ value }) => {
          return <></>;
        },
      },
      {
        Header: "Justificativa",
        accessor: (row) => row.title,
        Cell: ({ value }) => {
          return <></>;
        },
      },
      {
        Header: "Descrição da Justificativa",
        accessor: (row) => row.title,
        Cell: ({ value }) => {
          return <></>;
        },
      },
      {
        Header: "Ação",
        accessor: (row) => row.title,
        Cell: ({ value }) => {
          return <></>;
        },
      },
      {
        Header: "Template",
        accessor: (row) => row.title,
        Cell: ({ value }) => {
          return <></>;
        },
      },
      {
        Header: "",
        accessor: (row) => row,
        Cell: ({ value }) => {
          return (
            <PreDefinedMessagesActionsCell
              preDefinedMessage={value}
              onDeletePreDefinedMessage={onDeletePreDefinedMessage}
              onUpdatePreDefinedMessage={onUpdatePreDefinedMessage}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};
