import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { BusinessAssociateServices } from "@/core/models/business-associate.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface State {
  has500error: boolean;
  servicesOfList: BusinessAssociateServices[];
  servicesOfWhatsapp: BusinessAssociateServices[];
  servicesOfVoip: BusinessAssociateServices[];
  listServicesStatus: "loading" | "success" | "failed";
  whatsappServicesStatus: "loading" | "success" | "failed";
  voipServicesStatus: "loading" | "success" | "failed";
}

const initialState = {
  has500error: false,
  servicesOfList: [],
  servicesOfWhatsapp: [],
  servicesOfVoip: [],
  listServicesStatus: "loading",
  whatsappServicesStatus: "loading",
  voipServicesStatus: "loading",
} as State;

interface GetBusinessAssociateServicesProps {
  list_id: string;
}

interface UpdateSelectedBusinessAssociateServicesProps
  extends GetBusinessAssociateServicesProps {
  ids: number[];
}

export const updateSelectedBusinessAssociateServices = createAsyncThunk(
  "businessAssociate/updateSelectedBusinessAssociateServices",
  async (
    { list_id, ids }: UpdateSelectedBusinessAssociateServicesProps,
    thunkAPI
  ) => {
    try {
      const response = await axios.put(
        `/business-associate/actions/list/${list_id}`,
        ids
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const getBusinessAssociateServicesOfList = createAsyncThunk(
  "businessAssociate/getBusinessAssociateServicesOfList",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        "/business-associate/actions/enabled?serviceTypeId=1"
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const getBusinessAssociateServicesOfWhatsapp = createAsyncThunk(
  "businessAssociate/getBusinessAssociateServicesOfWhatsapp",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        "/business-associate/actions/enabled?serviceTypeId=2"
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const getBusinessAssociateServicesOfVoip = createAsyncThunk(
  "businessAssociate/getBusinessAssociateServicesOfVoip",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        "/business-associate/actions/enabled?serviceTypeId=3"
      );
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const businessAssociateServicesSlice = createSlice({
  name: "businessAssociateServicesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getBusinessAssociateServicesOfList.pending,
      (state, action) => {
        state.listServicesStatus = "loading";
      }
    );
    builder.addCase(
      getBusinessAssociateServicesOfList.fulfilled,
      (state, action: PayloadAction<BusinessAssociateServices[]>) => {
        state.servicesOfList = action.payload;
        state.listServicesStatus = "success";
      }
    );
    builder.addCase(
      getBusinessAssociateServicesOfList.rejected,
      (state, action: PayloadAction<any>) => {
        state.listServicesStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the services by the business associate"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(getBusinessAssociateServicesOfWhatsapp.pending, (state) => {
      state.whatsappServicesStatus = "loading";
    });
    builder.addCase(
      getBusinessAssociateServicesOfWhatsapp.fulfilled,
      (state, action: PayloadAction<BusinessAssociateServices[]>) => {
        state.servicesOfWhatsapp = action.payload;
        state.whatsappServicesStatus = "success";
      }
    );
    builder.addCase(
      getBusinessAssociateServicesOfWhatsapp.rejected,
      (state, action: PayloadAction<any>) => {
        state.whatsappServicesStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the services by the business associate"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(getBusinessAssociateServicesOfVoip.pending, (state) => {
      state.voipServicesStatus = "loading";
    });
    builder.addCase(
      getBusinessAssociateServicesOfVoip.fulfilled,
      (state, action: PayloadAction<BusinessAssociateServices[]>) => {
        state.servicesOfVoip = action.payload;
        state.voipServicesStatus = "success";
      }
    );
    builder.addCase(
      getBusinessAssociateServicesOfVoip.rejected,
      (state, action: PayloadAction<any>) => {
        state.voipServicesStatus = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the services by the business associate"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      updateSelectedBusinessAssociateServices.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the services by the business associate"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectBusinessAssociateServicesOfList = (state: RootState) =>
  state.businessAssociateServicesState.servicesOfList;
export const selectBusinessAssociateServicesOfWhatsapp = (state: RootState) =>
  state.businessAssociateServicesState.servicesOfWhatsapp;
export const selectBusinessAssociateServicesOfVoip = (state: RootState) =>
  state.businessAssociateServicesState.servicesOfVoip;
export const selectBusinessAssociateServicesOfListStatus = (state: RootState) =>
  state.businessAssociateServicesState.listServicesStatus;
export const selectBusinessAssociateServicesOfWhatsappStatus = (
  state: RootState
) => state.businessAssociateServicesState.whatsappServicesStatus;
export const selectBusinessAssociateServicesOfVoipStatus = (state: RootState) =>
  state.businessAssociateServicesState.voipServicesStatus;
export const selectBusinessAssociateServicesHas500error = (state: RootState) =>
  state.businessAssociateServicesState.has500error;

export const businessAssociateServicesReducer =
  businessAssociateServicesSlice.reducer;
