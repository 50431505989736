import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { Rule, RuptureRule } from "@/core/models/rules";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useRuptureRuleMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const ruptureRuleSelectedEvent = (rule: RuptureRule) => {
    MixpanelTracking.getInstance().track(TrackingEvents.RUPTURE_RULE_SELECTED, {
      ...buildProps(),
      "Regra selecionada": rule.id,
      "Todas informações da regra": rule,
    });
  };

  const ruptureRuleUpdatedEvent = (
    rule_id: number,
    rupture_rule_id: number,
    relationships: any
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.RUPTURE_RULE_CHANGED, {
      ...buildProps(),
      "ID da regra alterada": rule_id,
      "ID da regra de ruptura alterada": rupture_rule_id,
      "Regras com a regra de ruptura alterada": relationships,
    });
  };

  const ruptureRuleCreateEvent = (
    rule_id: number,
    rupture_rule_id: number,
    relationships: any
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.RUPTURE_RULE_CREATED, {
      ...buildProps(),
      "ID da regra alterada": rule_id,
      "ID da regra de ruptura alterada": rupture_rule_id,
      "Regras com a ruptura adicionada": relationships,
    });
  };

  const ruptureRuleDeleteEvent = (
    rule_id: number,
    rupture_rule_id: any,
    relationships: any
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.RUPTURE_RULE_DELETED, {
      ...buildProps(),
      "ID da regra alterada": rule_id,
      "ID da regra de ruptura removida": rupture_rule_id,
      "Regras com a ruptura removida": relationships,
    });
  };

  const ruptureRulesEnabledEvent = (rule: Rule, enabled: boolean) => {
    MixpanelTracking.getInstance().track(TrackingEvents.RUPTURE_RULE_ENABLED, {
      ...buildProps(),
      "ID da regra habilitada": rule.id,
      "Informações completas da regra": rule,
      Habilitada: enabled,
    });
  };

  const ruptureRulesDisabledEvent = (rule: Rule, enabled: boolean) => {
    MixpanelTracking.getInstance().track(TrackingEvents.RUPTURE_RULE_DISABLED, {
      ...buildProps(),
      "ID da regra habilitada": rule.id,
      "Informações completas da regra": rule,
      Habilitada: enabled,
    });
  };

  return {
    ruptureRuleSelectedEvent,
    ruptureRuleUpdatedEvent,
    ruptureRuleCreateEvent,
    ruptureRuleDeleteEvent,
    ruptureRulesDisabledEvent,
    ruptureRulesEnabledEvent,
  };
};
