import { LinkToAnotherTab } from "@/components/LinkToAnotherTab";
import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Tooltip, TooltipContent, TooltipTrigger } from "@gogeo-io/ui-library";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { BreadcrumbItemContainer, BreadcrumbText } from "./BreadCrumbs";

interface BreadcrumbItemProps {
  breadcrumb: Breadcrumb;
  adicionalAction?: () => void;
}

export function BreadcrumbItem({
  breadcrumb,
  adicionalAction = () => {},
}: BreadcrumbItemProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigation = async (url: string, notNavigate?: boolean) => {
    if (!notNavigate) {
      await dispatch(setBreadcrumbs([]));
      navigate(url);
    }
  };

  const handleTrimBreadcrumbsText = (text: string, size: number) => {
    return `${text.slice(0, size)}...`;
  };

  return (
    <LinkToAnotherTab url={breadcrumb.url}>
      <BreadcrumbItemContainer
        key={breadcrumb.item}
        onClick={() => {
          handleNavigation(breadcrumb.url, breadcrumb.notNavigate);
          adicionalAction();
        }}
      >
        {breadcrumb.canAdjust ? (
          <>
            {breadcrumb.item.length > 14 ? (
              <Tooltip>
                <TooltipTrigger style={{ background: "transparent" }}>
                  <BreadcrumbText size="body1">
                    {handleTrimBreadcrumbsText(breadcrumb.item, 14)}
                  </BreadcrumbText>
                </TooltipTrigger>
                <TooltipContent aria-label="Remarcação de agendamento">
                  {breadcrumb.item}
                </TooltipContent>
              </Tooltip>
            ) : (
              <BreadcrumbText size="body1">{breadcrumb.item}</BreadcrumbText>
            )}
          </>
        ) : (
          <>
            {breadcrumb.item.length > 28 ? (
              <Tooltip>
                <TooltipTrigger style={{ background: "transparent" }}>
                  <BreadcrumbText size="body1">
                    {handleTrimBreadcrumbsText(breadcrumb.item, 28)}
                  </BreadcrumbText>
                </TooltipTrigger>
                <TooltipContent aria-label="Remarcação de agendamento">
                  {breadcrumb.item}
                </TooltipContent>
              </Tooltip>
            ) : (
              <BreadcrumbText size="body1">{breadcrumb.item}</BreadcrumbText>
            )}
          </>
        )}

        <KeyboardArrowRight />
      </BreadcrumbItemContainer>
    </LinkToAnotherTab>
  );
}
