import { User } from "@/core/models/user.model";
import {
  removeUserToBatchActions,
  selectSelectedUsersToBatchActions,
  selectUserToBatchActions,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Checkbox } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface SelectUserToBathActionsProps {
  user: User;
}

export function SelectUserToBathActions({
  user,
}: SelectUserToBathActionsProps) {
  const [checked, setChecked] = useState(false);

  const selectedUsersToBatchActions = useSelector(
    selectSelectedUsersToBatchActions
  );

  const dispatch = useAppDispatch();

  const handleCheckboxChange = async (
    isChecked: boolean,
    userToBatch: User
  ) => {
    if (isChecked) {
      await dispatch(selectUserToBatchActions(userToBatch));
    } else {
      await dispatch(removeUserToBatchActions(userToBatch));
    }
  };

  useEffect(() => {
    const checkedIsMarked = selectedUsersToBatchActions.some(
      (u) => u.id === user.id
    );
    setChecked(checkedIsMarked);
  }, [selectedUsersToBatchActions]);

  return (
    <>
      <Checkbox
        checked={checked}
        onCheckedChange={(checked) =>
          handleCheckboxChange(Boolean(checked), user)
        }
        css={{ cursor: "pointer" }}
      />
    </>
  );
}
