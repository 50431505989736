import { TrackingEvents } from "@/config/mixpanel";
import { useMixpanel } from "@/hooks/useMixpanel";
import { Button, Flex } from "@gogeo-io/ui-library";
import { useEffect } from "react";
import { UserProfileHeaderToPage } from "../components/UserProfileHeaderToPage";
import { useBreadcrumbsForSecurityPage } from "../hooks/useBreadcrumbsForSecurityPage";
import { useProfileMixpanel } from "../hooks/useProfileMixpanel";

export function SecurityPage() {
  const { showBreadcrumbs } = useBreadcrumbsForSecurityPage();
  const { pageViewed } = useMixpanel();
  const { userClickedToChangePassword } = useProfileMixpanel();

  useEffect(() => {
    showBreadcrumbs();
    pageViewed(TrackingEvents.PROFILE_USER_SECURITY_PAGE);
  }, []);

  const getCurrentUrl = (): string => {
    return window.location.href;
  };

  const generateCodeChallenge = () => {
    const array = new Uint8Array(40);
    window.crypto.getRandomValues(array);
    const codeVerifier = btoa(String.fromCharCode(...array))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");

    return window.crypto.subtle
      .digest("SHA-256", new TextEncoder().encode(codeVerifier))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const base64Hash = btoa(String.fromCharCode(...hashArray))
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=+$/, "");
        return base64Hash;
      });
  };

  const changePassword = () => {
    generateCodeChallenge().then((codeChallenge) => {
      const url = `${
        import.meta.env.VITE_KEYCLOAK_AUTHORITY
      }/protocol/openid-connect/auth?client_id=${
        import.meta.env.VITE_KEYCLOAK_CLIENT
      }&redirect_uri=${getCurrentUrl()}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD&code_challenge=${codeChallenge}&code_challenge_method=S256`;

      window.open(url);
    });
    userClickedToChangePassword();
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$5" }}>
      <UserProfileHeaderToPage
        title="Segurança"
        description="Defina as preferências relacionadas ao login e à segurança da sua
          conta pessoal."
      />

      <Button style={{ width: "fit-content" }} onClick={changePassword}>
        Redefinir minha senha
      </Button>
    </Flex>
  );
}
