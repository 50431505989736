import {
  BusinessGoal,
  BusinessGoalCategorie,
} from "@/core/models/business-goal.model";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  IconButton,
  RightSlot,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { BiSolidPencil } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs";
import { MdClose, MdMoreVert } from "react-icons/md";
import { UpdateBusinessGoal } from "../UpdateBusinessGoal";
import { DeleteBusinessGoalConfirmation } from "./DeleteBusinessGoalConfirmation";

interface BusinessGoalsActionsCellProps {
  businessGoal: BusinessGoal;
  onDeleteBusinessGoal: (id: number) => void;
  onUpdateBusinessGoal: (
    id: number,
    name: string,
    color: string,
    categories: BusinessGoalCategorie[]
  ) => Promise<void>;
}

export function BusinessGoalsActionsCell({
  businessGoal,
  onDeleteBusinessGoal,
  onUpdateBusinessGoal,
}: BusinessGoalsActionsCellProps) {
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);

  const handleDeleteBusinessGoal = () => {
    onDeleteBusinessGoal(businessGoal.id);
  };

  return (
    <Flex css={{ gap: "$2" }}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton color="gray" type="ghost">
            <MdMoreVert />
          </IconButton>
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5}>
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
              setEditIsOpen(true);
            }}
          >
            Editar
            <RightSlot>
              <BiSolidPencil color="inherit" fontSize="inherit" />
            </RightSlot>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={async (event) => {
              event.stopPropagation();
              setDeleteIsOpen(true);
            }}
          >
            Deletar
            <RightSlot>
              <BsTrashFill color="inherit" fontSize="inherit" />
            </RightSlot>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={deleteIsOpen} onOpenChange={(e) => setDeleteIsOpen(e)}>
        <DialogTrigger />

        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
              onClick={(event) => event.stopPropagation()}
            >
              <MdClose />
            </IconButton>
          </DialogClose>

          <DeleteBusinessGoalConfirmation
            onDeleteBusinessGoal={handleDeleteBusinessGoal}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={editIsOpen} onOpenChange={(e) => setEditIsOpen(e)}>
        <DialogTrigger />

        <DialogContent onClick={(event) => event.stopPropagation()}>
          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
              onClick={(event) => event.stopPropagation()}
            >
              <MdClose />
            </IconButton>
          </DialogClose>

          <DialogTitle>Atualizar objetivo de negócio</DialogTitle>

          <UpdateBusinessGoal
            businessGoal={businessGoal}
            onUpdateBusinessGoal={onUpdateBusinessGoal}
          />
        </DialogContent>
      </Dialog>
    </Flex>
  );
}
