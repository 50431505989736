import { ReactNode, useState } from "react";
import { GenericFilterPopover } from "../GenericFilterPopover";
import { GenericBooleanFilterContent } from "./GenericBooleanFilterContent";

interface GenericBooleanFilterProps {
  children?: ReactNode;
  hasError?: boolean;
  filterId: number;
  value: boolean;
  onUpdatedFilter: (filterId: number, value: boolean) => void;
}

interface FilterToChange {
  filterId: number;
  value: boolean;
}

export function GenericBooleanFilter({
  children,
  hasError = false,
  filterId,
  value,
  onUpdatedFilter,
}: GenericBooleanFilterProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [filterChanged, setFilterChanged] = useState<FilterToChange>(
    {} as FilterToChange
  );
  const [selectedValue, setSelectedValue] = useState(value);

  const handleCheckboxChange = (isChecked: boolean) => {
    if (isChecked) {
      const filter: FilterToChange = { filterId, value: isChecked };
      setFilterChanged(filter);
      setSelectedValue(filter.value);
    } else {
      const filter: FilterToChange = { filterId, value: isChecked };
      setFilterChanged(filter);
      setSelectedValue(filter.value);
    }
  };

  const handlePopoverOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const openPopover = () => {
    setIsOpen(true);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  const applyFilter = () => {
    onUpdatedFilter(filterChanged.filterId, filterChanged.value);
    setFilterChanged({} as FilterToChange);
    setSelectedValue(filterChanged.value);
    closePopover();
  };

  return (
    <GenericFilterPopover
      trigger={children}
      popoverIsOpen={hasError ? false : isOpen}
      onOpenPopover={!hasError && openPopover}
      onPopoverOpenChange={!hasError && handlePopoverOpenChange}
    >
      <GenericBooleanFilterContent
        selectedValue={selectedValue}
        onCheckboxChange={handleCheckboxChange}
        onApplyFilter={applyFilter}
      />
    </GenericFilterPopover>
  );
}
