import { SystemColors, systemColors } from "@/core/models/business-goal.model";
import { useState } from "react";
import styled, { css } from "styled-components";

interface CustomColorPickerProps {
  selectedColor: string;
  onColorChange: (color: string) => void;
}

export function CustomColorPicker({
  selectedColor,
  onColorChange,
}: CustomColorPickerProps) {
  const [selectedSquare, setSelectedSquare] = useState(selectedColor);

  const handleClickSquare = (color: SystemColors) => {
    setSelectedSquare(color);
    onColorChange(color);
  };

  return (
    <Container>
      <GridColors>
        {systemColors.map((color) => {
          return (
            <ColorSquare
              key={color}
              $square_color={color}
              $isSelected={selectedSquare === color}
              onClick={() => handleClickSquare(color)}
            />
          );
        })}
      </GridColors>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
  align-items: flex-start;
`;

const GridColors = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${(props) => props.theme.space[2]};
`;

interface ColorSquareProps {
  $square_color: SystemColors;
  $isSelected: boolean;
}

const ColorSquare = styled.div<ColorSquareProps>`
  width: 25px;
  height: 25px;
  border-radius: 2px;
  background: ${(props) => props.theme.colors[`${props.$square_color}500`]};
  ${(props) =>
    props.$isSelected &&
    css`
      outline-style: auto;
      outline-color: ${() => props.theme.colors[`${props.$square_color}300`]};
    `}
`;
