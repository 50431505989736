import RLSSkeleton from "react-loading-skeleton";
import styled from "styled-components";

interface ProductsSuggestionSkeletonProps {
  timesToRepet?: number;
}

export function ProductsSuggestionSkeleton({
  timesToRepet = 1,
}: ProductsSuggestionSkeletonProps) {
  const timesToRepeatSkeletonRow = Array.from(Array(timesToRepet).keys());

  return (
    <Wrapper>
      {timesToRepeatSkeletonRow.map((t) => {
        return (
          <Item key={t}>
            <Row style={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
              <RLSSkeleton width={110} height={20} />
            </Row>

            <Row style={{ justifyContent: "space-between" }}>
              <RLSSkeleton width={230} height={20} />
              <Row>
                <RLSSkeleton width={20} height={20} />
                <RLSSkeleton width={20} height={20} />
              </Row>
            </Row>

            <Row style={{ justifyContent: "space-between" }}>
              <RLSSkeleton width={130} height={20} />
              <RLSSkeleton width={140} height={20} />
            </Row>
          </Item>
        );
      })}
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[4]};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  padding: ${(props) => props.theme.space[4]};
  gap: ${(props) => props.theme.space[4]};
`;

export const Row = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[2]};
`;
