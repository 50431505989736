import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import { getIndicators } from "@/features/indicators/store/indicatorsSlice";
import {
  selectListOfIndicators,
  selectSelectedIndicators,
} from "@/features/indicators/store/listIndicatosSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";

export function SelectIndicators() {
  const availableIndicators = useSelector(selectListOfIndicators);
  const selectedIndicators = useSelector(selectSelectedIndicators);

  const { paramsOnEditIndicatorsView } = useGetClientsByFiltersParams();

  const dispatch = useAppDispatch();

  const onSelectIndicators = async (indicatorsName: string) => {
    await dispatch(emitEventToChangeClients(false));

    const params = paramsOnEditIndicatorsView();

    await dispatch(
      getIndicators({ indicators_name: indicatorsName, data: params })
    ).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        await dispatch(emitEventToChangeClients(true));
      }
    });
  };

  return (
    <>
      {selectedIndicators.indicators_name !== undefined && (
        <Select
          defaultValue={selectedIndicators.indicators_name}
          ariaLabel="Indicadores disponíveis"
          onValueChange={onSelectIndicators}
        >
          {availableIndicators.map((indicators) => {
            return (
              <SelectItem
                value={String(indicators.indicators_name)}
                key={indicators.indicators_id}
              >
                <SelectItemText>{indicators.title}</SelectItemText>
              </SelectItem>
            );
          })}
        </Select>
      )}
    </>
  );
}
