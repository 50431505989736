import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface ReportsState {
  has401error: boolean;
}

const initialState = {
  has401error: false,
} as ReportsState;

export const error401slice = createSlice({
  name: "error401slice",
  initialState,
  reducers: {
    setError401State(state, action: PayloadAction<boolean>) {
      state.has401error = action.payload;
    },
  },
});

export const selectHas401Error = (state: RootState) =>
  state.error401ReducerState.has401error;

export const { setError401State } = error401slice.actions;

export const error401Reducer = error401slice.reducer;
