import { AnimatePresence } from "framer-motion";

import { AppUnderMaintenance } from "@/components/AppUnderMaintenance";
import { DeniedToAccessContent } from "@/components/DeniedToAccessContent";
import { Logout } from "@/components/Logout";
import { Page404 } from "@/components/Page404";
import { UserIsNotInWorkingTime } from "@/components/UserIsNotInWorkingTime";
import { UserRole } from "@/core/models/user.model";
import { selectUser } from "@/core/store/users/usersSlice";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { DecisionType, Route as RouteConfig, routes } from "./routes";

const withAccessControl =
  (Component: React.ReactElement, acl: RouteConfig["acl"]) =>
  (currentUserRole: UserRole) => {
    const decision = acl[currentUserRole];
    if (!decision || decision.type === DecisionType.ALLOW) {
      return Component;
    } else if (decision.type === DecisionType.REDIRECT) {
      return <Navigate to={decision.meta || "/"} />;
    }
    return <DeniedToAccessContent size="medium" />;
  };

export const RoutesProvider: React.FC = () => {
  const user = useSelector(selectUser);

  const renderRoutes = (routes: RouteConfig[], userRole: UserRole) => {
    return routes.map(({ path, component, acl, children }) => (
      <Route
        key={path}
        path={path}
        element={withAccessControl(component, acl)(userRole)}
      >
        {children && renderRoutes(children, userRole)}
      </Route>
    ));
  };

  return (
    <AnimatePresence
      initial={true}
      onExitComplete={() => {
        if (typeof window !== "undefined") {
          window.scrollTo({ top: 0 });
        }
      }}
    >
      <Routes>
        <Route index element={<Navigate to="attendance" />} />

        {renderRoutes(routes, user.role)}

        <Route path="/logout" element={<Logout />} />
        <Route
          path="/app-under-maintenance"
          element={<AppUnderMaintenance />}
        />
        <Route
          path="/user-is-not-in-working-time"
          element={<UserIsNotInWorkingTime />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </AnimatePresence>
  );
};
