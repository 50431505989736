import { Caption, Flex, Heading, IconButton } from "@gogeo-io/ui-library";
import { BiChevronLeft } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectSelectedBusinessAssociateService } from "../store/businessAssociatesServicesConfigSlice";
import { ConfirmDisabledBusinessAssociateServiceDialog } from "./ConfirmDisabledBusinessAssociateServiceDialog";
import { EnableBusinessAssociateServiceDialog } from "./bussinessAssociateGenericForm/EnableBusinessAssociateServiceDialog";

interface BusinessAssociatedHeaderProps {
  onDisableBusinessAssociate: () => void;
  onEnableBusinessAssociate: () => void;
}

export function BusinessAssociatedServiceHeader({
  onDisableBusinessAssociate,
  onEnableBusinessAssociate,
}: BusinessAssociatedHeaderProps) {
  const selectedBusinessAssociateService = useSelector(
    selectSelectedBusinessAssociateService
  );

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/profile/integrations/services/list");
  };

  return (
    <Flex
      css={{
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Flex
        css={{
          flexDirection: "column",
          width: "100%",
          gap: "$2",
        }}
      >
        <Flex css={{ gap: "$2" }}>
          <IconButton size="small" type="soft" onClick={handleNavigate}>
            <BiChevronLeft />
          </IconButton>
          <Heading size="title6" css={{ fontWeight: "bold" }}>
            {selectedBusinessAssociateService.name}
          </Heading>
        </Flex>

        {selectedBusinessAssociateService.enabled ? (
          <Flex css={{ gap: "$2", alignItems: "center", color: "$primary500" }}>
            <FaCheckCircle color="inherit" size={18} />
            <Caption css={{ color: "$gray500" }}>Habilitado</Caption>
          </Flex>
        ) : (
          <Flex css={{ gap: "$2", alignItems: "center", color: "$red500" }}>
            <IoCloseCircle color="inherit" size={22} />
            <Caption css={{ color: "$gray500" }}>Desabilitado</Caption>
          </Flex>
        )}
      </Flex>

      {selectedBusinessAssociateService.enabled ? (
        <ConfirmDisabledBusinessAssociateServiceDialog
          onDisableBusinessAssociate={onDisableBusinessAssociate}
        />
      ) : (
        <EnableBusinessAssociateServiceDialog
          onEnableBusinessAssociate={onEnableBusinessAssociate}
        />
      )}
    </Flex>
  );
}
