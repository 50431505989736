import { selectUser } from "@/core/store/users/usersSlice";
import {
  getClientById,
  selectClientWithParams,
} from "@/features/clientInfo/store/clientSlice";
import { ChangeParamsDialog } from "@/features/params/components/ChangeParamsDialog";
import {
  removeAllClientToChangeParams,
  selectClientToChangeParams,
} from "@/features/params/store/clientSelectedToChangeParamsSlice";
import { setSelectedSalesmanToChangeParams } from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Params } from "@core/models/params.model";
import { TimelineItemOfParams } from "@features/history/components/TimelineItemOfParams";
import { Flex, Text } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

interface TimelineListProps {
  params: Params[];
}

export const TimelineListOfParams = ({ params }: TimelineListProps) => {
  const selectedClientWithParams = useSelector(selectClientWithParams);
  const user = useSelector(selectUser);

  const { client_id } = useParams();
  const dispatch = useAppDispatch();

  const handleSetClientToChangeParams = async () => {
    await dispatch(removeAllClientToChangeParams());
    await dispatch(selectClientToChangeParams(selectedClientWithParams));
    await dispatch(setSelectedSalesmanToChangeParams(user));
  };

  const handleUpdateParams = async () => {
    await dispatch(
      getClientById({
        client_id: client_id,
        shouldBringParams: user.role === "SALESMAN",
      })
    );
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$4",
        marginTop: "-12px",
      }}
    >
      <ChangeParamsDialog onUpdateParams={handleUpdateParams}>
        <Text
          css={{
            color: "$primary500",
            textAlign: "left",
            width: "fit-content",
            textDecoration: "underline",
          }}
          onClick={handleSetClientToChangeParams}
        >
          Editar parâmetros do cliente
        </Text>
      </ChangeParamsDialog>
      <div>
        {params.map((hist, idx) => {
          return <TimelineItemOfParams params={hist} key={idx} />;
        })}
      </div>
    </Flex>
  );
};
