import { DynamicFormFields } from "@/core/models/business-associate.model";
import * as yup from "yup";

export default function useCreateSchemaFormBusinessAsociateForm(
  fields: DynamicFormFields[]
) {
  const shape = {};

  fields.map((field) => {
    if (field.type === "string" || field.type === "date") {
      let yupValidation = yup.string();

      if (field.required === true) {
        yupValidation = yupValidation.required(
          `O campo ${field.property} é obrigatório(a).`
        );
      }

      shape[field.property] = yupValidation;
      return shape;
    }

    if (field.type === "number") {
      let yupValidation = yup.number();

      if (field.required === true) {
        yupValidation = yupValidation.required(
          `O campo ${field.property} é obrigatório(a).`
        );
      }

      shape[field.property] = yupValidation;
      return shape;
    }

    if (field.type === "select") {
      let yupValidation = yup.string().oneOf(
        field.options.map((option) => option.value),
        "Opção inválida"
      );

      if (field.required === true) {
        yupValidation = yupValidation.required(
          `O campo ${field.property} é obrigatório(a).`
        );
      }

      shape[field.property] = yupValidation;
      return shape;
    }

    if (field.type === "multiselect") {
      let yupValidation = yup.array();

      if (field.required === true) {
        yupValidation = yupValidation.required(
          `O campo ${field.property} é obrigatório(a).`
        );
      }

      shape[field.property] = yupValidation;
      return shape;
    }
  });

  const schema = yup.object().shape(shape);

  return schema;
}
