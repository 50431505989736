import styled from "styled-components";

export const ReminderHeader = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.space[2]};
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[5]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
