import { ClientPhoto } from "./client.model";
import { MetaParameters } from "./metaParameters.model";
import { StatisticsCustomerWallet } from "./statisticsCustomerWallet";

export type UserRole = "ADMIN" | "MANAGER" | "SUPERVISOR" | "SALESMAN";

export enum Role {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  SUPERVISOR = "SUPERVISOR",
  SALESMAN = "SALESMAN",
}

export interface UserHandler {
  name: string;
  role: UserRole;
  phone: string;
  salesman_id: string;
  email: string;
  password: string;
  salesman_type: "EXTERNAL" | "INTERNAL";
  confirm_password: string;
}

export interface UserOnRelationship {
  id: number;
  name: string;
  role: UserRole;
}

export interface UserRelationships {
  related: UserOnRelationship[];
  unrelated: UserOnRelationship[];
}

export interface User {
  id?: number;
  email?: string;
  password?: string;
  token?: string;
  salesman_id?: string;
  role?: UserRole;
  users?: User[];
  name: string;
  owner_id: number;
  account_id: number;
  account_name: string;
  user_photo: ClientPhoto;
  phone: string;
  salesman_ids: string[];
  salesman_type?: "EXTERNAL" | "INTERNAL";
  skip_scheduling: boolean;
  ignore_blocked: boolean;
  active: boolean;
  inactive_days: number;
  max_visit: number;
  lat_start: number;
  lon_start: number;
  lat_end: number;
  lon_end: number;
  is_associate?: boolean;
  permissions: {
    [key: string]: boolean;
  };
  statistics?: StatisticsCustomerWallet;
  meta_parameters: MetaParameters;
  group: number;
}

export interface BatchCreatedUser {
  user: User;
  created: boolean;
  already_exists: boolean;
  error: boolean;
  message?: string;
}

export interface SalesmanToRuptureRule {
  id: number;
  email: string;
  name: string;
  phone: string;
  salesman_id: string;
}
