import { ListDivider } from "@/components/ListDivider";
import { UserCannotHaveAcess } from "@/components/UserCannotHaveAccess";
import { selectUser } from "@/core/store/users/usersSlice";
import { BusinessAssociateServicesOfListModalContentContainer } from "@/features/businessAssociate/components/BusinessAssociateServicesOfListModalContentContainer";
import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import {
  cleanColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { setClientsHasError500 } from "@/features/clients/store/clientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { ListOfTags, SearchFilters } from "@core/models/listOfTags.model";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  IconButton,
  RightSlot,
} from "@gogeo-io/ui-library";
import { Close, ContentCopy } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import PushPinIcon from "@mui/icons-material/PushPin";
import { ReactElement, useState } from "react";
import { MdClose, MdShare } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAttendanceMixpanel } from "../hooks/useAttendanceMixpanel";
import {
  addTagsOnSelectedClientFilter,
  setSelectedAdvancedFilters,
} from "../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClientsFilters } from "../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import {
  addListOnState,
  createListOfTags,
  deleteList,
  fixList,
  fixListFromState,
  removeListFromState,
  selectMyListOfTags,
  unfixList,
  unfixListFromState,
} from "../store/listOfTagsSlice";
import { selectList } from "../store/listSelectedSlice";
import { setSelectedSearchFilters } from "../store/selectedSearchFiltersSlice";
import { DeleteListConfirmation } from "./attendanceListItem/DeleteListConfirmation";
import { DuplicateListConfirmation } from "./attendanceListItem/DuplicateListConfirmation";
import { ShareList } from "./attendanceListItem/ShareList";

export interface AttendanceListItemProps {
  list: ListOfTags;
  icon: ReactElement;
  showFixButtonAction?: boolean;
}

export function SelectedListActions({
  list,
  icon,
  showFixButtonAction = true,
}: AttendanceListItemProps) {
  const [deleteListDialogIsOpen, setDeleteListDialogIsOpen] = useState(false);
  const [duplicateListDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [shareListDialogIsOpen, setShareDialogIsOpen] = useState(false);
  const [businessAssociateDialogIsOpen, setBusinessAssociateDialogIsOpen] =
    useState(false);

  const user = useSelector(selectUser);
  const my_clients_list = useSelector(selectMyListOfTags);

  const dispatch = useAppDispatch();
  const { duplicateListEvent } = useAttendanceMixpanel();
  const navigate = useNavigate();
  const { list_slug } = useParams();
  const { listUnfixedEvent, listFixedEvent, deleteListOfTagsEvent } =
    useAttendanceMixpanel();

  const setSelectedFilters = async (listSelected: ListOfTags) => {
    const columnsToSort = listSelected.filters?.sort?.orders;
    if (columnsToSort !== undefined && columnsToSort.length > 0) {
      await dispatch(setColumnsToSort(columnsToSort));
    } else {
      await dispatch(cleanColumnsToSort());
    }

    const advancedFilters = listSelected.filters?.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = listSelected.filters?.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    const attrOpts = listSelected.filters?.attr_opts;
    if (attrOpts !== undefined) {
      await dispatch(setAttributesToFilter(attrOpts));
    } else {
      await dispatch(setDefaultAttributesToFilter());
    }

    const searchFilters: SearchFilters = {
      match_type: "Geral",
      match_value: "",
    };

    await dispatch(setSelectedSearchFilters(searchFilters));
  };

  const handleFixList = async () => {
    await dispatch(fixList({ id: list.id })).then(async (value) => {
      if (value.meta.requestStatus === "fulfilled") {
        listFixedEvent(list);
        await dispatch(fixListFromState(value.payload));
      }
    });
  };

  const handleUnFixList = async () => {
    await dispatch(unfixList({ id: list.id })).then(async (value) => {
      if (value.meta.requestStatus === "fulfilled") {
        listUnfixedEvent(list);
        await dispatch(unfixListFromState(value.payload));
      }
    });
  };

  const handleDeleteList = async () => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClientsFilters(false));

    await dispatch(deleteList({ id: list.id })).then(async (value) => {
      if (value.meta.requestStatus === "fulfilled") {
        await dispatch(removeListFromState(value.payload));
        deleteListOfTagsEvent(value.payload);
        if (list_slug === list.slug) {
          const my_clients_list_slug = `${user.account_id}_${user.id}_clientes`;
          await dispatch(selectList(my_clients_list));
          await setSelectedFilters(my_clients_list);
          await dispatch(setClientsHasError500(false));
          await dispatch(emitEventToChangeClients(true));
          await dispatch(emitEventToChangeClientsFilters(true));

          navigate(`/attendance/${my_clients_list_slug}`);
          await dispatch(emitEventToChangeClientsFilters(false));
        }
      }
    });
  };

  const handleDuplicateList = async (listName: string) => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClientsFilters(false));

    const args = {
      name: listName,
      filters: list.filters,
    };

    await dispatch(createListOfTags(args)).then(async (value) => {
      if (value.payload.status === 409) {
        return;
      }

      if (value.meta.requestStatus === "fulfilled") {
        const listDuplicated: ListOfTags = value.payload;
        duplicateListEvent(list, listDuplicated);
        await dispatch(addListOnState(listDuplicated));
        await dispatch(selectList(listDuplicated));
        await setSelectedFilters(listDuplicated);
        await dispatch(emitEventToChangeClients(true));
        await dispatch(emitEventToChangeClientsFilters(true));
        navigate(`/attendance/${listDuplicated.slug}`);
        await dispatch(emitEventToChangeClientsFilters(false));
      }
    });
  };

  return (
    <Flex>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton color="gray" type="ghost">
            {icon}
          </IconButton>
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5}>
          <>
            {showFixButtonAction && (
              <>
                {list && !list.fixed ? (
                  <DropdownMenuItem
                    onClick={async (event) => {
                      event.stopPropagation();
                      await handleFixList();
                    }}
                    disabled={list.is_suggested_by_govendas}
                  >
                    Fixar
                    <RightSlot>
                      <PushPinIcon fontSize="small" color="inherit" />
                    </RightSlot>
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem
                    onClick={async (event) => {
                      event.stopPropagation();
                      await handleUnFixList();
                    }}
                    disabled={list && list.is_suggested_by_govendas}
                  >
                    Desfixar
                    <RightSlot>
                      <PushPinIcon fontSize="small" color="inherit" />
                    </RightSlot>
                  </DropdownMenuItem>
                )}
              </>
            )}
          </>
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation();
              setDeleteListDialogIsOpen(true);
            }}
            disabled={list && list.is_suggested_by_govendas}
          >
            Excluir
            <RightSlot>
              <CloseIcon fontSize="small" color="inherit" />
            </RightSlot>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={async (event) => {
              event.stopPropagation();
              setDuplicateDialogIsOpen(true);
            }}
          >
            Duplicar
            <RightSlot>
              <ContentCopy fontSize="small" color="inherit" />
            </RightSlot>
          </DropdownMenuItem>

          <UserCannotHaveAcess roles={["SALESMAN"]}>
            <DropdownMenuItem
              onClick={async (event) => {
                event.stopPropagation();
                setBusinessAssociateDialogIsOpen(true);
              }}
            >
              Serviços
              <RightSlot>
                <ContentCopy fontSize="small" color="inherit" />
              </RightSlot>
            </DropdownMenuItem>
          </UserCannotHaveAcess>

          <DropdownMenuItem
            onClick={async (event) => {
              event.stopPropagation();
              setShareDialogIsOpen(true);
            }}
          >
            Compartilhar
            <RightSlot>
              <MdShare fontSize="small" color="inherit" />
            </RightSlot>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog
        open={businessAssociateDialogIsOpen}
        onOpenChange={(e) => setBusinessAssociateDialogIsOpen(e)}
      >
        <DialogTrigger />
        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <DialogTitle>Serviços Disponíveis</DialogTitle>

          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
              onClick={(event) => event.stopPropagation()}
            >
              <MdClose />
            </IconButton>
          </DialogClose>

          <ListDivider />

          <BusinessAssociateServicesOfListModalContentContainer
            onCloseDialog={() => setBusinessAssociateDialogIsOpen(false)}
            list_id={list && list.id}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteListDialogIsOpen}
        onOpenChange={(e) => setDeleteListDialogIsOpen(e)}
      >
        <DialogTrigger />
        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
              onClick={(event) => event.stopPropagation()}
            >
              <Close />
            </IconButton>
          </DialogClose>

          <DeleteListConfirmation onDeleteList={handleDeleteList} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={duplicateListDialogIsOpen}
        onOpenChange={(e) => setDuplicateDialogIsOpen(e)}
      >
        <DialogTrigger />
        <DialogContent
          style={{ width: "450px" }}
          onClick={(event) => event.stopPropagation()}
        >
          <DialogTitle>Duplicar Lista</DialogTitle>

          <DialogClose asChild>
            <IconButton
              type="ghost"
              css={{ position: "absolute", top: "1rem", right: "1rem" }}
              onClick={(event) => event.stopPropagation()}
            >
              <Close />
            </IconButton>
          </DialogClose>

          <DuplicateListConfirmation
            onDuplicateList={handleDuplicateList}
            selectedList={list}
          />
        </DialogContent>
      </Dialog>

      <ShareList
        list={list}
        shareListDialogIsOpen={shareListDialogIsOpen}
        onSetShareDialogIsOpen={(v) => setShareDialogIsOpen(v)}
      />
    </Flex>
  );
}
