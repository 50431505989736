import { LinkToAnotherTab } from "@/components/LinkToAnotherTab";
import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import {
  cleanColumnsToSort,
  setColumnsToSort,
} from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { setClientsHasError500 } from "@/features/clients/store/clientsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFormatPrice } from "@/hooks/useFormatPrice";
import { ListOfTags, SearchFilters } from "@core/models/listOfTags.model";
import {
  Caption,
  Flex,
  Progress,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { AttachMoney, EventNoteOutlined } from "@mui/icons-material";
import { useState } from "react";
import { MdOutlineMoreVert } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAttendanceMixpanel } from "../hooks/useAttendanceMixpanel";
import {
  addTagsOnSelectedClientFilter,
  setSelectedAdvancedFilters,
} from "../store/clientAdvancedFiltersSlice";
import {
  emitEventToChangeClientsFilters,
  selectStatusOfEmitEventToChangeClientsFilters,
} from "../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import {
  removeBrandNew,
  removedBrandNewFromState,
} from "../store/listOfTagsSlice";
import { selectList, selectSelectedList } from "../store/listSelectedSlice";
import { setSelectedSearchFilters } from "../store/selectedSearchFiltersSlice";
import UI from "../ui";
import { AttendanceListItemIcons } from "./AttendanceListItemIcons";
import { AttendanceListItemName } from "./AttendanceListItemName";
import { FiltersWillBeLostDialog } from "./FiltersWillBeLostDialog";
import { SelectedListActions } from "./SelectedListActions";

export interface AttendanceListItemProps {
  list: ListOfTags;
}

export function AttendanceListItem({ list }: AttendanceListItemProps) {
  const [openDialogOfFiltersWillBeLost, setOpenDialogOfFiltersWillBeLost] =
    useState(false);

  const statusOfClientsFiltersEvent = useSelector(
    selectStatusOfEmitEventToChangeClientsFilters
  );
  const selectedList = useSelector(selectSelectedList);

  const dispatch = useAppDispatch();
  const { listOfTagsClickedEvent } = useAttendanceMixpanel();
  const navigate = useNavigate();
  const { list_slug } = useParams();
  const { formatNumberToDecimal } = useFormatPrice();

  const handleClickCard = () => {
    if (statusOfClientsFiltersEvent) {
      setOpenDialogOfFiltersWillBeLost(true);
    } else {
      handleSelectList();
    }
  };

  const handleSelectList = async () => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClientsFilters(false));

    if (list.brand_new) {
      handleRemoveBrandNew();
    }

    listOfTagsClickedEvent(list);

    const columnsToSort = list.filters?.sort?.orders;
    if (columnsToSort !== undefined && columnsToSort.length > 0) {
      await dispatch(setColumnsToSort(columnsToSort));
    } else {
      await dispatch(cleanColumnsToSort());
    }

    const advancedFilters = list.filters?.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = list.filters?.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    const attrOpts = list.filters?.attr_opts;
    if (attrOpts !== undefined) {
      await dispatch(setAttributesToFilter(attrOpts));
    } else {
      await dispatch(setDefaultAttributesToFilter());
    }

    const searchFilters: SearchFilters = {
      match_type: "Geral",
      match_value: "",
    };

    await dispatch(setSelectedSearchFilters(searchFilters));

    await dispatch(selectList(list));
    await dispatch(setClientsHasError500(false));

    await dispatch(emitEventToChangeClients(true));

    navigate(`/attendance/${list.slug}`);
  };

  const handleRemoveBrandNew = async () => {
    await dispatch(removeBrandNew({ id: list.id })).then(async (value) => {
      if (value.meta.requestStatus === "fulfilled") {
        await dispatch(removedBrandNewFromState(value.payload));
      }
    });
  };

  const listMatchSelectedList = selectedList.slug === list.slug ? "active" : "";

  const realizedProgress = (
    list.infos?.total > 0 ? (list.infos?.realized / list.infos?.total) * 100 : 0
  ).toFixed(2);

  const positivedProgress = (
    list.infos?.total > 0
      ? (list.infos?.positived / list.infos?.total) * 100
      : 0
  ).toFixed(2);

  return (
    <LinkToAnotherTab url={`/attendance/${list.slug}`}>
      <UI.AttendanceListItem
        className={listMatchSelectedList}
        onClick={handleClickCard}
      >
        <Flex
          css={{
            flexDirection: "column",
            gap: "$1",
            padding: "$1",
            width: "100%",
            height: "100%",
          }}
        >
          <Flex
            css={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              paddingLeft: "$1",
            }}
          >
            <AttendanceListItemName>{list.name}</AttendanceListItemName>
            <SelectedListActions list={list} icon={<MdOutlineMoreVert />} />
          </Flex>

          <AttendanceListItemIcons list={list} />

          <UI.AttendanceIndicatorsWrapper>
            <Flex css={{ gap: "$2" }}>
              <UI.AttendanceIndicator
                className={
                  list.infos?.realized >= list.infos?.total ? "complete" : ""
                }
              >
                <EventNoteOutlined fontSize="inherit" />
                <Tooltip>
                  <TooltipTrigger
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Caption
                      bold
                      css={{
                        color: "$gray900",
                        marginRight: "5px",
                      }}
                    >
                      {list.infos?.realized === undefined ? (
                        <> - </>
                      ) : (
                        <b>{list.infos.realized}</b>
                      )}
                    </Caption>
                    <Caption css={{ color: "$gray700" }}>realizados</Caption>
                  </TooltipTrigger>
                  <TooltipContent aria-label="Progresso realizado">
                    Você realizou {realizedProgress}% dos seus atendimentos
                  </TooltipContent>
                </Tooltip>
              </UI.AttendanceIndicator>

              <UI.AttendanceIndicator
                className={
                  list.infos?.positived === list.infos?.realized
                    ? "complete"
                    : ""
                }
              >
                <AttachMoney fontSize="inherit" />

                <Tooltip>
                  <TooltipTrigger
                    style={{
                      background: "transparent",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Caption
                      bold
                      css={{
                        color: "$gray900",
                        marginRight: "5px",
                      }}
                    >
                      {list.infos?.positived === undefined ? (
                        <> - </>
                      ) : (
                        <b>{list.infos.positived}</b>
                      )}
                    </Caption>
                    <Caption css={{ color: "$gray700" }}>efetivados</Caption>
                  </TooltipTrigger>
                  <TooltipContent aria-label="Progresso efetivado">
                    Você efetivou {positivedProgress}% dos seus atendimentos
                  </TooltipContent>
                </Tooltip>
              </UI.AttendanceIndicator>
            </Flex>

            <Tooltip>
              <TooltipTrigger
                style={{
                  background: "transparent",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Caption
                  bold
                  css={{
                    color: "$gray600",
                  }}
                >
                  {list.infos?.total === undefined ? (
                    <> - </>
                  ) : (
                    <>{formatNumberToDecimal(list.infos.total)}</>
                  )}
                </Caption>
              </TooltipTrigger>
              <TooltipContent aria-label="Progresso realizado">
                Quantidade total de cliente **nesta** lista
              </TooltipContent>
            </Tooltip>
          </UI.AttendanceIndicatorsWrapper>

          <Flex css={{ flexDirection: "column", gap: "$1", mt: "$1" }}>
            <Tooltip>
              <TooltipTrigger style={{ background: "transparent" }}>
                <Progress size="medium" value={Number(realizedProgress)} />
              </TooltipTrigger>
              <TooltipContent aria-label="Progresso realizado">
                Você realizou {realizedProgress}% dos seus atendimentos
              </TooltipContent>
            </Tooltip>
          </Flex>
        </Flex>
      </UI.AttendanceListItem>

      <FiltersWillBeLostDialog
        isOpen={openDialogOfFiltersWillBeLost}
        onOpenChange={(e) => setOpenDialogOfFiltersWillBeLost(e)}
        onContinue={handleSelectList}
      />
    </LinkToAnotherTab>
  );
}
