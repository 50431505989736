import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
  Text,
} from "@gogeo-io/ui-library";
import { MdClose } from "react-icons/md";

interface ConfirmDisabledBusinessAssociateDialogProps {
  onDisableBusinessAssociate: () => void;
}

export function ConfirmDisabledBusinessAssociateServiceDialog({
  onDisableBusinessAssociate,
}: ConfirmDisabledBusinessAssociateDialogProps) {
  return (
    <Dialog>
      <DialogTrigger>
        <Button color="red" bordered style={{ background: "white" }}>
          Desativar
        </Button>
      </DialogTrigger>
      <DialogContent
        style={{ width: "450px" }}
        onClick={(event) => event.stopPropagation()}
      >
        <DialogClose asChild>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
            onClick={(event) => event.stopPropagation()}
          >
            <MdClose />
          </IconButton>
        </DialogClose>
        <Flex
          css={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
            textAlign: "center",
          }}
        >
          <Heading>Você tem certeza?</Heading>
          <Text>Tem certeza que quer desabilitar o serviço?</Text>

          <Flex css={{ gap: "0.5rem", marginTop: "0.75rem" }}>
            <DialogClose asChild>
              <Button color="gray" onClick={(event) => event.stopPropagation()}>
                Cancelar
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                color="danger"
                onClick={(event) => {
                  event.stopPropagation();
                  onDisableBusinessAssociate();
                }}
              >
                Desativar
              </Button>
            </DialogClose>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
