import { ReactNode, useState } from "react";
import { createPortal } from "react-dom";

interface IFrameTextEditorProps extends React.HTMLProps<HTMLIFrameElement> {
  children: ReactNode;
}

export function IFrameTextEditor({
  children,
  ...props
}: IFrameTextEditorProps) {
  const [iframeBody, setIframeBody] = useState(null);

  const handleLoad = (e) => {
    setIframeBody(e.target.contentDocument.body);
  };

  return (
    <iframe
      srcDoc={`<!DOCTYPE html>`}
      {...props}
      onLoad={handleLoad}
      style={{
        width: "100%",
        height: "100%",
        overflowX: "hidden",
      }}
    >
      {iframeBody && createPortal(children, iframeBody)}
    </iframe>
  );
}
