import * as yup from "yup";

export const registerAttendanceSchema = yup.object().shape({
  rescheduled_date: yup.string(),
  files: yup.array(),
  observations: yup.string(),
  feedback_id: yup
    .number()
    .required("A escolha de uma justificativa de atendimento é obrigatória."),
});

export const updateAttendanceSchema = yup.object().shape({
  rescheduled_date: yup.string(),
  files: yup.array(),
  observations: yup.string(),
});
