import { Flex, Text } from "@gogeo-io/ui-library";
import { isUndefined } from "lodash";
import { useSelector } from "react-redux";
import { selectTrendingTags } from "../../store/clientAdvancedFiltersSlice";
import { TagItem } from "./TagItem";

export function TrendingTags() {
  const tags = useSelector(selectTrendingTags);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        height: "100%",
      }}
    >
      <>
        {isUndefined(tags) || (tags && tags.length === 0) ? (
          <Text>Não temos recomendações de tags.</Text>
        ) : (
          <>
            {tags &&
              tags.map((tag) => {
                return <TagItem key={tag.label} tag={tag} />;
              })}
          </>
        )}
      </>
    </Flex>
  );
}
