import { Tag } from "@/core/models/tag.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex, Grid, Heading, Input, Spinner } from "@gogeo-io/ui-library";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import { useState } from "react";
import styled from "styled-components";
import { getUserTags } from "../../store/clientAdvancedFiltersSlice";
import { FoundedTags } from "./FoundedTags";
import { RecommendedTags } from "./RecommendedTags";
import { SelectedTagsHeader } from "./SelectedTagsHeader";

interface AdvancedFiltersTagsProps {
  onCloseDialogOfAdvancedFilters: () => void;
  onCloseDialogOfTags: () => void;
}

export function AdvancedFiltersTags({
  onCloseDialogOfAdvancedFilters,
  onCloseDialogOfTags,
}: AdvancedFiltersTagsProps) {
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagsStatus, setTagsStatus] = useState("loading");
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);

  const dispatch = useAppDispatch();

  const handleInputChange = async (value: string) => {
    setCurrentPage(0);
    setIsLoading(true);
    setHasMorePages(true);
    setQuery(value);

    await dispatch(
      getUserTags({
        page: 0,
        size: import.meta.env.VITE_PAGE_SIZE,
        query: value,
      })
    ).then(async (res) => {
      if (res.payload.tags.length === 0) {
        setHasMorePages(false);
        setTags([]);
      } else {
        setTags([...res.payload.tags]);
      }

      setIsLoading(false);
    });
  };

  const increaseCurrentPage = () => setCurrentPage((prev) => prev + 1);

  const handleSetHasMorePages = (value: boolean) => setHasMorePages(value);

  const handleSetTagsStatus = (value: string) => setTagsStatus(value);

  const handleIncreaseTags = (tags: Tag[]) =>
    setTags((prevTags) => [...prevTags, ...tags]);

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$4",
        height: "100%",
      }}
    >
      <Heading css={{ fontWeight: "bold" }}>
        Tags inteligentes disponíveis
      </Heading>

      <SelectedTagsHeader
        onCloseDialogOfAdvancedFilters={onCloseDialogOfAdvancedFilters}
        onCloseDialogOfTags={onCloseDialogOfTags}
      />

      <Input
        placeholder="Busque por uma tag"
        preffix={isLoading ? <Spinner /> : <SearchIcon fontSize="inherit" />}
        onChange={(e) => debounceOnChangeInput(e.target.value)}
        style={{ marginLeft: "2px", marginRight: "2px" }}
      />

      <Grid
        css={{
          gridTemplateColumns: "1fr 1fr",
          gap: "$4",
          height: "100%",
          overflowY: "hidden",
        }}
      >
        <Flex
          css={{
            flexDirection: "column",
            gap: "$2",
            height: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Heading size="subtitle1">🔎 Resultados encontrados</Heading>
          <FoundedTags
            tags={tags}
            tagsStatus={tagsStatus}
            onSetTagsStatus={handleSetTagsStatus}
            hasMorePages={hasMorePages}
            currentPage={currentPage}
            query={query}
            onIncreaseCurrentPage={increaseCurrentPage}
            onSetHasMorePages={handleSetHasMorePages}
            onIncreaseTags={handleIncreaseTags}
          />
        </Flex>

        <RecommendedTags />
      </Grid>
    </Flex>
  );
}

export const TagCloudWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 10px;
`;
