import { Flex, Heading, Radio, RadioGroup } from "@gogeo-io/ui-library";

interface FormRadioBooleanFieldOption {
  value: boolean;
  title: string;
}

interface FormRadioBooleanFieldProps {
  value: boolean;
  options: FormRadioBooleanFieldOption[];
  onChange: (...event: any[]) => void;
}

export function FormRadioBooleanField({
  value,
  options,
  onChange,
}: FormRadioBooleanFieldProps) {
  function stringToBoolean(value: string) {
    switch (value.toLowerCase().trim()) {
      case "true":
        return true;
      case "false":
        return false;
      case "null":
        return null;
    }
  }

  const handleValueChange = (value: string) => {
    onChange(stringToBoolean(value));
  };

  return (
    <RadioGroup
      defaultValue={String(value)}
      onValueChange={handleValueChange}
      style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
    >
      {options.map((option) => {
        return (
          <Flex key={option.title} css={{ alignItems: "center", gap: "$1" }}>
            <Radio value={String(option.value)} />
            <Heading size="subtitle2">
              <label htmlFor={String(option.value)}>{option.title}</label>
            </Heading>
          </Flex>
        );
      })}
    </RadioGroup>
  );
}
