import {
  Flex,
  Select,
  SelectItem,
  SelectItemText,
  Text,
} from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { useState } from "react";

interface AddUserSelectRoleProps {
  onChange: (...event: any[]) => void;
  value: string;
  hasError: boolean;
  errorMessage: string;
}

export function AddUserSelectRole({
  onChange,
  value,
  hasError,
  errorMessage,
}: AddUserSelectRoleProps) {
  const [role, setRole] = useState(value);

  const handleRoleChange = (selectedRole: string) => {
    onChange(selectedRole);
    setRole(selectedRole);
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$1" }}>
      <Text>Selecione a função do usuário:</Text>
      <Select
        defaultValue={role}
        value={role}
        ariaLabel="Função do usuário"
        onValueChange={handleRoleChange}
      >
        <SelectItem value="SALESMAN">
          <SelectItemText>Vendedor</SelectItemText>
        </SelectItem>
        <SelectItem value="ADMIN">
          <SelectItemText>Administrador</SelectItemText>
        </SelectItem>
        <SelectItem value="MANAGER">
          <SelectItemText>Gerente</SelectItemText>
        </SelectItem>
        <SelectItem value="SUPERVISOR">
          <SelectItemText>Supervisor</SelectItemText>
        </SelectItem>
      </Select>
      {hasError && <G_UI.ErrorMessage>{errorMessage}</G_UI.ErrorMessage>}
    </Flex>
  );
}
