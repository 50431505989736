import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { FeedbackMapping } from "../../../core/models/feedbackMapping.model";
import { RootState } from "../../../core/store/store";

interface FeedbackMappingState {
  feedbackMapping: FeedbackMapping[];
  has500error: boolean;
  status: "loading" | "success" | "failed";
}

const initialState = {
  feedbackMapping: [],
  has500error: false,
  status: "loading",
} as FeedbackMappingState;

export const getFeedbackMapping = createAsyncThunk(
  "feedback/getFeedbackMapping",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/feedback/mapping");
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
        });
      }
    }
  }
);

export const feedbackMappingSlice = createSlice({
  name: "feedbackMappingSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeedbackMapping.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(getFeedbackMapping.fulfilled, (state, action) => {
      state.status = "success";
      state.feedbackMapping = action.payload;
      state.has500error = false;
    });
    builder.addCase(
      getFeedbackMapping.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the feedback mapping");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectFeedbackMapping = (state: RootState) =>
  state.feedbackMappingState.feedbackMapping;
export const selectFeedbackMappingStatus = (state: RootState) =>
  state.feedbackMappingState.status;
export const selectFeedbackMappingHasError500 = (state: RootState) =>
  state.feedbackMappingState.has500error;

export const feedbackMappingReducer = feedbackMappingSlice.reducer;
