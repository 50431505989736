interface ObservationsProps {
  onChange: (...event: any[]) => void;
  value: string;
}

import { TextArea } from "@components/TextArea";

export function FeedbackObservations({ onChange, value }: ObservationsProps) {
  const handleInputChange = async (value: string) => {
    onChange(value);
  };

  return (
    <TextArea
      placeholder="Observações"
      onChange={(e) => handleInputChange(e.target.value)}
      value={value}
    ></TextArea>
  );
}
