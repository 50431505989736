import { ReactNode } from "react";
import styled from "styled-components";

interface SidebarLeftProps {
  children?: ReactNode;
}

export default function SidebarLeft({ children }: SidebarLeftProps) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.aside`
  height: calc(100% - var(--header-height));
  border-right: 2px solid ${({ theme }) => theme.colors.gray200};
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${({ theme }) => theme.colors.white};
  min-width: 400px;
  max-width: 400px;

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    min-width: 350px;
    max-width: 350px;
  }
`;
