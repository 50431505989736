import { Flex } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { selectIndicators } from "../store/indicatorsSlice";

export function IndicatorsFrame() {
  const indicators = useSelector(selectIndicators);

  return (
    <Flex css={{ width: "100%", height: "100%" }}>
      <iframe src={indicators.url} style={{ width: "100%" }} />
    </Flex>
  );
}
