import { FilterTrigger } from "@/components/advancedFilters/FilterTrigger";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { useEffect, useState } from "react";

interface BooleanFilterTriggerProps {
  filter: AdvancedFilter;
  hasError?: boolean;
  value: boolean;
  onRemoveFilter?: (filter: AdvancedFilter) => void;
  notShowCloseIcon?: boolean;
}

export function BooleanFilterTrigger({
  filter,
  hasError = false,
  value: selectedValue,
  onRemoveFilter,
  notShowCloseIcon,
}: BooleanFilterTriggerProps) {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (selectedValue) {
      setValue("Sim");
    } else {
      setValue(`Não`);
    }
  }, [selectedValue]);

  return (
    <FilterTrigger
      value={value}
      hasError={hasError}
      filter={filter}
      onRemoveFilter={onRemoveFilter}
      notShowCloseIcon={notShowCloseIcon}
    />
  );
}
