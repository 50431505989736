import { AddNewAdvancedFiltersSkeleton } from "@/components/advancedFilters/AddNewAdvancedFiltersSkeleton";
import { TrackingEvents } from "@/config/mixpanel";
import { ListOfTags } from "@/core/models/listOfTags.model";
import { User } from "@/core/models/user.model";
import { useMixpanel } from "@/hooks/useMixpanel";
import { useEffect, useState } from "react";
import { useConvertValidFiltersToUrlFilters } from "../../hooks/useConvertValidFiltersToUrlFilters";
import { AddInfoToShareListDialog } from "./AddInfoToShareListDialog";
import { ShareListDialog } from "./ShareListDialog";

interface ShareListProps {
  list: ListOfTags;
  shareListDialogIsOpen: boolean;
  onSetShareDialogIsOpen: (value: boolean) => void;
}

export function ShareList({
  list,
  shareListDialogIsOpen,
  onSetShareDialogIsOpen,
}: ShareListProps) {
  const [shareUrl, setShareUrl] = useState("https://example.com");
  const [title, setTitle] = useState(list.name);
  const [description, setDescription] = useState("");
  const [infoToSharedListDialogIsOpen, setInfoToSharedListDialogIsOpen] =
    useState(false);
  const [selectedUsersToShare, setSelectedUsersToShare] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { pageViewed } = useMixpanel();
  const convertValidFiltersToUrlFilters = useConvertValidFiltersToUrlFilters();

  const handleRemoveUserToShare = (user: User) => {
    const usersWithoutRemovedOne = selectedUsersToShare.filter(
      (selectedUser) => selectedUser.id !== user.id
    );
    setSelectedUsersToShare(usersWithoutRemovedOne);
  };

  const handleSetDefaultFilters = () => {
    setTitle(list.name);
    setShareUrl(convertValidFiltersToUrlFilters(list.filters, list.name));
    setDescription("");
    setSelectedUsersToShare([]);
  };

  useEffect(() => {
    setIsLoading(true);
    pageViewed(TrackingEvents.SHARE_LIST_PAGE);

    handleSetDefaultFilters();

    setIsLoading(false);
  }, [list]);

  return (
    <>
      {isLoading ? (
        <AddNewAdvancedFiltersSkeleton count={5} />
      ) : (
        <>
          <ShareListDialog
            list={list}
            shareUrl={shareUrl}
            title={title}
            shareListDialogIsOpen={shareListDialogIsOpen}
            selectedUsersToShare={selectedUsersToShare}
            onSetDefaultFilters={handleSetDefaultFilters}
            onSetShareDialogIsOpen={onSetShareDialogIsOpen}
            onOpenInfoToSharedListDialog={() =>
              setInfoToSharedListDialogIsOpen(true)
            }
            onSetSelectedUsersToShare={(users) =>
              setSelectedUsersToShare(users)
            }
          />
          <AddInfoToShareListDialog
            list={list}
            title={title}
            description={description}
            infoToSharedListDialogIsOpen={infoToSharedListDialogIsOpen}
            selectedUsersToShare={selectedUsersToShare}
            shareUrl={shareUrl}
            onTitleChange={(value) => setTitle(value)}
            onDescriptionChange={(value) => setDescription(value)}
            onSetInfoToSharedListDialog={setInfoToSharedListDialogIsOpen}
            onSetShareDialogIsOpen={onSetShareDialogIsOpen}
            onRemoveSelectedUserToShare={handleRemoveUserToShare}
          />
        </>
      )}
    </>
  );
}
