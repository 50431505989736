import { LinkToAnotherTab } from "@/components/LinkToAnotherTab";
import { UserRole } from "@/core/models/user.model";
import { selectUser } from "@/core/store/users/usersSlice";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  RightSlot,
} from "@gogeo-io/ui-library";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UserMenuAvatar } from "./UserMenuAvatar";

interface MenuItem {
  name: string;
  url: string;
  rolesThatCannotSeeItem?: UserRole[];
}

const profileItems: MenuItem[] = [
  {
    name: "Configurações",
    url: "/profile/user-profile/info",
  },
];

const menuItems: MenuItem[] = [
  {
    name: "Atendimento",
    url: "/attendance",
  },
  {
    name: "Relatórios",
    url: "/reports",
  },
];

export function UserMenuWithKeycloak(): JSX.Element {
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  const handleLogout = async () => {
    navigate("/logout");
  };

  const openGoVendasAcademyInAnotherTab = () => {
    window.open(
      "https://academia.govendas.com/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const showMenuItems = (items: MenuItem[]): JSX.Element => {
    return (
      <>
        {items.map((item) => {
          if (
            item.rolesThatCannotSeeItem &&
            item.rolesThatCannotSeeItem.includes(user.role)
          ) {
            return null;
          }

          return (
            <LinkToAnotherTab url={item.url} key={item.name}>
              <DropdownMenuItem key={item.name}>{item.name}</DropdownMenuItem>
            </LinkToAnotherTab>
          );
        })}
      </>
    );
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger style={{ background: "none" }}>
          <UserMenuAvatar />
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5} style={{ zIndex: "1" }}>
          {showMenuItems(menuItems)}

          <DropdownMenuSeparator />

          {showMenuItems(profileItems)}

          <DropdownMenuItem onClick={openGoVendasAcademyInAnotherTab}>
            Academia GoVendas
          </DropdownMenuItem>

          <DropdownMenuSeparator />

          <DropdownMenuItem onClick={handleLogout}>
            Sair
            <RightSlot>
              <LogoutIcon fontSize="inherit" />
            </RightSlot>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
export const UserAvatarMenu = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[2]};
  align-items: center;
  cursor: pointer;
`;
