import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";

export const useBreadcrumbsForSpecificReport = () => {
  const dispatch = useAppDispatch();

  const showBreadcrumbs = async (dashboard_name: string) => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Relatórios", url: "/reports" },
      {
        item: dashboard_name,
        url: ``,
      },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return {
    showBreadcrumbs,
  };
};
