import { selectUser } from "@/core/store/users/usersSlice";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Box,
  Flex,
  Heading,
} from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { useTranslateRole } from "../hooks/useTranslateRole";

export function UserProfileAvatar() {
  const user = useSelector(selectUser);

  const translateRole = useTranslateRole();

  return (
    <Flex
      css={{
        gap: "0.75rem",
      }}
    >
      <Avatar size="medium">
        {user.user_photo.photo_lg_url ? (
          <AvatarImage src={user.user_photo.photo_lg_url} />
        ) : (
          <AvatarFallback>{user.user_photo.fallback}</AvatarFallback>
        )}
      </Avatar>
      <Flex
        css={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Heading size="subtitle2">{user.name}</Heading>

        <Box
          css={{
            whiteSpace: "nowrap",
            background: "$blue100",
            color: "$blue500",
            fontWeight: "bold",
            borderRadius: "4px",
            padding: "0.2rem 0.4rem",
            textAlign: "center",
            margin: "0 2px",
          }}
        >
          {translateRole(user.role)}
        </Box>
      </Flex>
    </Flex>
  );
}
