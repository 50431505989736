import { Caption } from "@gogeo-io/ui-library";

interface TimelineHeaderInfoSalesmanProps {
  salesman: string;
}

export function TimelineHeaderInfoSalesman({
  salesman,
}: TimelineHeaderInfoSalesmanProps) {
  return (
    <Caption wider css={{ color: "$gray500" }}>
      {salesman}
    </Caption>
  );
}
