import { ListDivider } from "@/components/ListDivider";
import { ClientProfileInfoPhone } from "@/features/attendance/components/ClientProfileInfoPhone";
import { ProfileInfo } from "@/features/attendance/components/ProfileInfo";
import { useFormatAddress } from "@/hooks/useFormatAddress";
import { useMask } from "@/hooks/useMask";
import { Flex, Heading } from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useSelector } from "react-redux";
import { selectClient } from "../../store/clientSlice";
import { ClientProfileTags } from "../ClientProfileTags";

export function ClientProfileGeneralInfo() {
  const selectedClient = useSelector(selectClient);

  const { useCpfCnpjMask } = useMask();

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "0.625rem",
        padding: "0.625rem",
      }}
    >
      <Heading size="subtitle1" css={{ color: "$gray800" }}>
        Informações Gerais
      </Heading>
      <ListDivider />
      {!isEmpty(selectedClient.tags) && !isUndefined(selectedClient.tags) && (
        <ClientProfileTags client={selectedClient} />
      )}

      {!isEmpty(selectedClient.name) && !isUndefined(selectedClient.name) ? (
        <ProfileInfo label="Nome" value={selectedClient.name} />
      ) : (
        <ProfileInfo label="Nome" value="Não consta" />
      )}
      {!isEmpty(selectedClient.id) && !isUndefined(selectedClient.id) ? (
        <ProfileInfo label="ID do cliente" value={selectedClient.id} />
      ) : (
        <ProfileInfo label="ID do cliente" value="Não consta" />
      )}
      {!isEmpty(selectedClient.cnpj_cpf) &&
      !isUndefined(selectedClient.cnpj_cpf) ? (
        <ProfileInfo
          label="CNPJ/CPF"
          value={useCpfCnpjMask(selectedClient.cnpj_cpf)}
        />
      ) : (
        <ProfileInfo label="CNPJ/CPF" value="Não consta" />
      )}

      {!isEmpty(selectedClient.email) && !isUndefined(selectedClient.email) ? (
        <ProfileInfo
          asOuterLink
          label="Email"
          value={selectedClient.email}
          href={selectedClient.email}
        />
      ) : (
        <ProfileInfo label="Email" value="Não consta" />
      )}
      {!isEmpty(selectedClient.address) &&
      !isUndefined(selectedClient.address) ? (
        <ProfileInfo
          label="Endereço"
          value={useFormatAddress(selectedClient.address)}
        />
      ) : (
        <ProfileInfo label="Endereço" value="Não consta" />
      )}

      <ProfileInfo
        label="Cidade"
        value={`${selectedClient.address.city} - ${selectedClient.address.state}`}
      />
      {!isEmpty(selectedClient.main_phone) &&
      !isUndefined(selectedClient.main_phone) &&
      !isEmpty(selectedClient.unformatted_main_phone) &&
      !isUndefined(selectedClient.unformatted_main_phone) ? (
        <ClientProfileInfoPhone
          label="Telefone"
          phone={selectedClient.main_phone}
          unformatted_phone={selectedClient.unformatted_main_phone}
          client={selectedClient}
          phoneActionsIsVisible
        />
      ) : (
        <ProfileInfo label="Telefone" value="Não consta" />
      )}
    </Flex>
  );
}
