import { FileMetadata } from "@/core/models/file.model";
import { getFile } from "@/core/store/uploadFile/uploadFileSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useFile } from "@/hooks/useFile";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import UI from "../ui";

interface TimelineAttachmentsProps {
  files: FileMetadata[];
}

export function TimelineAttachments({ files }: TimelineAttachmentsProps) {
  const dispatch = useAppDispatch();
  const { downloadFileByUrl } = useFile();

  const handleDowloadFile = async (
    fileId: number,
    filename: string,
    type: string
  ) => {
    await dispatch(getFile({ fileId })).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        downloadFileByUrl(res.payload.url, type, filename);
      }
    });
  };

  return (
    <Flex css={{ flexWrap: "wrap", gap: "$5" }}>
      {files &&
        files.map((file) => {
          return (
            <UI.FileAttached
              key={file.id}
              onClick={() =>
                handleDowloadFile(file.id, file.filename, file.type)
              }
            >
              <AttachFileIcon />
              <Caption css={{ color: "$gray700" }}>{file.filename}</Caption>
            </UI.FileAttached>
          );
        })}
    </Flex>
  );
}
