import { Box, Flex } from "@gogeo-io/ui-library";
import { Table } from "antd";

interface BusinessGoalTableProps {
  columns: any;
  data: any;
}

export function BusinessGoalTable({ columns, data }: BusinessGoalTableProps) {
  console.log(columns);
  console.log(data);

  return (
    <Flex
      css={{
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box css={{ overflow: "auto" }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          sticky={{ offsetHeader: 0 }}
          scroll={{ x: "100%" }}
        />
      </Box>
    </Flex>
  );
}
