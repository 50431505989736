import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface ListNameSelectedToChangeInitialStateProps {
  listNameSelectedToChange: string;
}

const initialState = {
  listNameSelectedToChange: "",
} as ListNameSelectedToChangeInitialStateProps;

export const listNameSelectedToChange = createSlice({
  name: "listNameSelectedToChange",
  initialState,
  reducers: {
    setListNameSelectedToChange(state, action: PayloadAction<string>) {
      state.listNameSelectedToChange = action.payload;
    },
  },
});

export const selectListNameSelectedToChange = (state: RootState) =>
  state.listNameSelectedToChangeState.listNameSelectedToChange;

export const { setListNameSelectedToChange } = listNameSelectedToChange.actions;

export const listNameSelectedToChangeReducer = listNameSelectedToChange.reducer;
