import {
  remindersMock,
  remindersMockCreated,
  remindersMockUpdated,
} from "@/core/mocks/remindersMock";
import {
  ReminderRequest,
  ReminderResponse,
} from "@/core/models/reminder.model";
import { RootState } from "@/core/store/store";
import { sleep } from "@/utils/sleep";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ReduxThunkError } from "../../../core/errors/ReduxThunkError";

interface ReminderState {
  has500error: boolean;
  reminders: ReminderResponse[];
  countOfRemindersUnreaded: number;
  status: "loading" | "success" | "failed";
}

const initialState = {
  has500error: false,
  reminders: [],
  countOfRemindersUnreaded: 0,
  status: "loading",
} as ReminderState;

export const getReminders = createAsyncThunk(
  "reminder/getReminders",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return remindersMock;
    } else {
      try {
        const response = await axios.get("/reminders");
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const getCountOfRemindersUnreaded = createAsyncThunk(
  "reminder/getCountOfRemindersUnreaded",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return 2;
    } else {
      try {
        const response = await axios.get("/reminders/count/unread");
        if (response.status !== 200) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

interface UpdateReminderProps {
  id: number;
  isRead: boolean;
}

export const updateReminder = createAsyncThunk(
  "reminder/updateReminder",
  async ({ id, isRead }: UpdateReminderProps, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return remindersMockUpdated;
    } else {
      try {
        const response = await axios.put(`/reminders/${id}?isRead=${isRead}`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const deleteReminder = createAsyncThunk(
  "reminder/deleteReminder",
  async (id: number, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
    } else {
      try {
        const response = await axios.delete(`/reminders/${id}`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const createReminder = createAsyncThunk(
  "reminder/createReminder",
  async (reminder: ReminderRequest, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return remindersMockCreated;
    } else {
      try {
        const response = await axios.post("/reminders", reminder);
        if (
          response.status !== 200 &&
          response.status !== 201 &&
          response.status !== 202
        ) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const reminderSlice = createSlice({
  name: "reminderSlice",
  initialState,
  reducers: {
    addReminderOnState(state, action: PayloadAction<ReminderResponse>) {
      state.reminders.push(action.payload);
    },
    updateReminderToIsReadState(state, action: PayloadAction<number>) {
      [...state.reminders].filter((reminder) =>
        reminder.id === action.payload ? (reminder.is_read = true) : reminder
      );
    },
    updateReminderToUnreadState(state, action: PayloadAction<number>) {
      [...state.reminders].filter((reminder) =>
        reminder.id === action.payload ? (reminder.is_read = false) : reminder
      );
    },
    removeReminderFromState(state, action: PayloadAction<number>) {
      const stateWithoutRemovedOne = state.reminders.filter(
        (reminder) => reminder.id !== action.payload
      );
      state.reminders = stateWithoutRemovedOne;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReminders.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(getReminders.fulfilled, (state, action) => {
      state.reminders = action.payload;
      state.status = "success";
      state.has500error = false;
    });
    builder.addCase(
      getReminders.rejected,
      (state, action: PayloadAction<any>) => {
        if (action.payload.status === 500) {
          state.has500error = true;
        }
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the reminders");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      updateReminder.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error updating the reminder");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      deleteReminder.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error deleting the reminder");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(
      createReminder.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error when creating the reminder");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
    builder.addCase(getCountOfRemindersUnreaded.pending, (state, action) => {
      state.status = "loading";
      state.has500error = false;
    });
    builder.addCase(getCountOfRemindersUnreaded.fulfilled, (state, action) => {
      state.countOfRemindersUnreaded = action.payload;
      state.status = "success";
      state.has500error = false;
    });
    builder.addCase(
      getCountOfRemindersUnreaded.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the count of reminders unreaded"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectReminders = (state: RootState) =>
  state.reminderState.reminders;
export const selectRemindersStatus = (state: RootState) =>
  state.reminderState.status;
export const selectCountOfRemindersUnreaded = (state: RootState) =>
  state.reminderState.countOfRemindersUnreaded;
export const selectReminderHasError500 = (state: RootState) =>
  state.reminderState.has500error;

export const {
  addReminderOnState,
  updateReminderToIsReadState,
  updateReminderToUnreadState,
  removeReminderFromState,
} = reminderSlice.actions;

export const reminderReducer = reminderSlice.reducer;
