import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { PreDefinedMessage } from "@/core/models/preDefinedMessage.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const usePreDefinedMessagesMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const createPreDefinedMessageEvent = (message: PreDefinedMessage) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PRE_DEFINED_MESSAGE_CREATED,
      {
        ...buildProps(),
        "Mensagem pré definida criada": message,
      }
    );
  };

  const editPreDefinedMessageEvent = (
    edited_message_id: number,
    newMessage: PreDefinedMessage
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PRE_DEFINED_MESSAGE_UPDATED,
      {
        ...buildProps(),
        "ID da mensagem editada": edited_message_id,
        "Nova mensagem": newMessage,
      }
    );
  };

  const deletedPreDefinedMessageEvent = (
    deleted_message_id: number,
    deletedMessage: PreDefinedMessage
  ) => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PRE_DEFINED_MESSAGE_DELETED,
      {
        ...buildProps(),
        "ID da mensagem deleteda": deleted_message_id,
        "Mensagem deletada": deletedMessage,
      }
    );
  };

  const getMessagesPreDefinedMessageEvent = () => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.PRE_DEFINED_MESSAGE_GET,
      {
        ...buildProps(),
      }
    );
  };

  return {
    createPreDefinedMessageEvent,
    editPreDefinedMessageEvent,
    deletedPreDefinedMessageEvent,
    getMessagesPreDefinedMessageEvent,
  };
};
