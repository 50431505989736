import {
  removeAllClientToChangeParams,
  selectAllClientsSelectedToChangeParams,
  setAllClientsSelectedToChangeParams,
} from "@/features/params/store/clientSelectedToChangeParamsSlice";
import { changeParams } from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Checkbox } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";

export function ParamsSelectAllClientsCheckbox() {
  const allClientsSelectedToChangeParams = useSelector(
    selectAllClientsSelectedToChangeParams
  );

  const dispatch = useAppDispatch();

  const handleSetAllClients = async () => {
    await dispatch(setAllClientsSelectedToChangeParams());
    await dispatch(removeAllClientToChangeParams());
    await dispatch(changeParams({ params: "cleanAllFilters" }));
  };

  return (
    <Checkbox
      checked={allClientsSelectedToChangeParams}
      onCheckedChange={handleSetAllClients}
      css={{ cursor: "pointer" }}
    />
  );
}
