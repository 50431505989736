import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Flex,
  Input,
  Select,
  SelectItem,
  SelectItemText,
  Text,
} from "@gogeo-io/ui-library";
import { debounce } from "lodash";
import { MdSearch } from "react-icons/md";
import RLSSkeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useNotificationsMixpanel } from "../hooks/useNotificationsMixpanel";
import {
  getNotifications,
  markAllNotificationsToRead,
  selectNotificationTypes,
  selectNotificationTypesStatus,
  selectNotificationsQuery,
  selectNotificationsSelectedType,
  updateNotificationSelectedType,
  updateNotificationsQuery,
} from "../store/notificationsSlice";

export function NotificationsListHeader() {
  const notificationTypes = useSelector(selectNotificationTypes);
  const notificationTypesStatus = useSelector(selectNotificationTypesStatus);
  const notificationsQuery = useSelector(selectNotificationsQuery);
  const notificationsSelectedType = useSelector(
    selectNotificationsSelectedType
  );

  const dispatch = useAppDispatch();
  const { markAllNotificationsToReadEvent, notificationSearchEvent } =
    useNotificationsMixpanel();

  const handleChangeType = async (type: string) => {
    await dispatch(updateNotificationSelectedType(type));
    await dispatch(getNotifications({ type, query: notificationsQuery }));
    notificationSearchEvent(notificationsQuery, type);
  };

  const handleInputChange = async (value: string) => {
    await dispatch(updateNotificationsQuery(value));
    await dispatch(
      getNotifications({ type: notificationsSelectedType, query: value })
    );
    notificationSearchEvent(value, notificationsSelectedType);
  };

  const handleMarkAllNotificationsAsReaded = async () => {
    await dispatch(markAllNotificationsToRead()).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        await dispatch(
          getNotifications({
            type: notificationsSelectedType,
            query: notificationsQuery,
          })
        );
        markAllNotificationsToReadEvent();
      }
    });
  };

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  return (
    <Flex
      css={{
        flexDirection: "column",
        paddingLeft: "$1",
        gap: "$4",
      }}
    >
      <Flex css={{ gap: "$2" }}>
        <Input
          placeholder="Pesquise o título de alguma notificação"
          onChange={(e) => debounceOnChangeInput(e.target.value)}
          preffix={<MdSearch />}
          style={{ width: "120px" }}
        />
        {notificationTypesStatus === "success" ? (
          <Flex css={{ flexDirection: "column", gap: "$1", mt: "-2px" }}>
            <Select
              defaultValue={notificationsSelectedType}
              value={notificationsSelectedType}
              onValueChange={handleChangeType}
              ariaLabel="Select type"
            >
              {notificationTypes.map((type) => {
                return (
                  <SelectItem key={type.id} value={type.id}>
                    <SelectItemText>{type.value}</SelectItemText>
                  </SelectItem>
                );
              })}
            </Select>
          </Flex>
        ) : (
          <RLSSkeleton height={20} />
        )}
      </Flex>
      <Text
        css={{
          color: "$primary500",
          textAlign: "left",
          width: "fit-content",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={handleMarkAllNotificationsAsReaded}
      >
        Marcar tudo como lido
      </Text>
    </Flex>
  );
}
