import { PreDefinedMessagesSkeletonRow } from "@/components/Skeleton/PreDefinedMessagesSkeletonRow";
import { Flex } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTable } from "react-table";
import { selectTotalOfAttendanceJustifications } from "../store/attendanceJustificationsSlice";

interface AttendanceJustificationsTableProps {
  columns: any;
  data: any;
  onPageChange: (page: number) => void;
  currentPage: number;
  onIncreasePageNumber: () => void;
}

export function AttendanceJustificationsTable({
  columns,
  data,
  onPageChange,
  currentPage,
  onIncreasePageNumber,
}: AttendanceJustificationsTableProps) {
  const totalOfAttendanceJustifications = useSelector(
    selectTotalOfAttendanceJustifications
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  useEffect(() => {
    if (currentPage !== 0) {
      if (data.length < totalOfAttendanceJustifications) {
        onPageChange(currentPage);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    const instersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        if (data.length < totalOfAttendanceJustifications) {
          onIncreasePageNumber();
        }
      }
    });

    instersectionObserver.observe(document.querySelector("#sentry"));

    return () => instersectionObserver.disconnect();
  }, []);

  return (
    <Flex
      css={{
        flexDirection: "column",
        width: "100%",
        padding: "0 1rem",
      }}
    >
      <G_UI.Table {...getTableProps()}>
        <G_UI.Thead>
          {headerGroups.map((headerGroup, idx) => (
            <G_UI.Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
              {headerGroup.headers.map((column, idx) => (
                <G_UI.Th
                  key={idx}
                  {...column.getHeaderProps({
                    style: {
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      width: column.width,
                    },
                  })}
                >
                  {column.render("Header")}
                </G_UI.Th>
              ))}
            </G_UI.Tr>
          ))}
        </G_UI.Thead>

        <G_UI.Tbody {...getTableBodyProps()}>
          {rows.map((row, rowIdx) => {
            prepareRow(row);
            return (
              <G_UI.Tr {...row.getRowProps()} key={rowIdx}>
                {row.cells.map((cell, idx) => {
                  return (
                    <G_UI.Td
                      key={idx}
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.minWidth,
                          maxWidth: cell.maxWidth,
                          width: cell.width,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </G_UI.Td>
                  );
                })}
              </G_UI.Tr>
            );
          })}
          <G_UI.Tr id="sentry">
            {data.length < totalOfAttendanceJustifications ? (
              <PreDefinedMessagesSkeletonRow />
            ) : null}
          </G_UI.Tr>
        </G_UI.Tbody>
      </G_UI.Table>
    </Flex>
  );
}
