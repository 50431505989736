import { TrackingEvents } from "@/config/mixpanel";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useMixpanel } from "@/hooks/useMixpanel";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useBreadcrumbsForBusinessAssociatesPage } from "../hooks/useBreadcrumbsForBusinessAssociatesPage";
import {
  getBusinessAssociatesServicesConfig,
  selectBusinessAssociatesServicesConfigStatus,
} from "../store/businessAssociatesServicesConfigSlice";
import { BusinessAssociatesListSkeleton } from "./BusinessAssociatesListSkeleton";

export function BusinessAssociateServicesContainer() {
  const status = useSelector(selectBusinessAssociatesServicesConfigStatus);

  const dispatch = useAppDispatch();

  const { pageViewed } = useMixpanel();
  const { showBreadcrumbs } = useBreadcrumbsForBusinessAssociatesPage();

  useEffect(() => {
    async function loadBusinessAssociates() {
      await dispatch(getBusinessAssociatesServicesConfig());
    }

    loadBusinessAssociates();
    showBreadcrumbs();

    pageViewed(TrackingEvents.BUSINESS_ASSOCIATE_SERVICES_PAGE);
  }, []);

  return (
    <>
      {status !== "success" ? (
        <BusinessAssociatesListSkeleton count={5} />
      ) : (
        <Outlet />
      )}
    </>
  );
}
