import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectProductsSugestion } from "../store/productsSuggestionSlice";
import { ProductItem } from "./ProductItem";

export function ProductsList() {
  const productsSuggestion = useSelector(selectProductsSugestion);

  return (
    <Container>
      {productsSuggestion.map((product, idxProduct) => {
        return (
          <>
            {product.recommendations.map((recommendation, idxRec) => {
              return (
                <ProductItem
                  key={`${idxProduct}-${idxRec}`}
                  listName={product.list_name}
                  recommendation={recommendation}
                />
              );
            })}
          </>
        );
      })}
    </Container>
  );
}

export const Container = styled.div`
  padding: ${(props) => props.theme.space[3]};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[3]};
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;
