import { UsersTableSkeleton } from "@/components/Skeleton/UsersTableSkeleton";
import { User } from "@/core/models/user.model";
import {
  getUsers,
  selectUsers,
  selectUsersStatus,
} from "@/core/store/users/usersSlice";
import { UserProfileHeaderToPage } from "@/features/profile/components/UserProfileHeaderToPage";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUsersColumns } from "../hooks/useUsersColumns";
import { selectStatusOfEmitEventToChangeUsers } from "../store/emitEventToChangeUsersSlice";
import { selectUserSelectedAdvancedFilters } from "../store/usersAdvancedFiltersSlice";
import { UserActionTopBar } from "./UserActionTopBar";
import { UsersTable } from "./UsersTable";

export function ListViewOfUsers() {
  const [loadedPage, setLoadedPage] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const users = useSelector(selectUsers);
  const usersStatus = useSelector(selectUsersStatus);
  const statusOfEmitEventToChangeUsers = useSelector(
    selectStatusOfEmitEventToChangeUsers
  );
  const selectedUserAdvancedFilters = useSelector(
    selectUserSelectedAdvancedFilters
  );

  const dispatch = useAppDispatch();

  const usersColumns = useUsersColumns();

  const handleSearchUsers = async (searchedTerm: string) => {
    setSearchTerm(searchedTerm);
    setFilteredUsers(filterUsers(searchedTerm, users));
  };

  const filterUsers = (searchTerm: string, usersToFilter: User[]): User[] => {
    if (searchTerm === "") {
      return usersToFilter;
    } else {
      const searchedUsers = usersToFilter.filter(
        (user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          String(user.id).toLowerCase().includes(searchTerm.toLowerCase())
      );
      return searchedUsers;
    }
  };

  async function loadUsers() {
    await dispatch(getUsers()).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setFilteredUsers(res.payload);
        const loadUsers: User[] = res.payload;
        setFilteredUsers(filterUsers(searchTerm, loadUsers));
        setLoadedPage(true);
      }
    });
  }

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (statusOfEmitEventToChangeUsers) {
      loadUsers();
    }
  }, [statusOfEmitEventToChangeUsers]);

  return (
    <>
      {loadedPage ? (
        <>
          <Flex css={{ flexDirection: "column", height: "100%", gap: "$4" }}>
            <UserProfileHeaderToPage
              title="Gestão de Usuários"
              description="Aqui você pode gerenciar as informações dos integrantes do seu time"
            />

            {/* <Flex css={{ flexDirection: "column" }}>
              <Caption wider>Filtros</Caption>
              <Flex
                css={{
                  padding: "$2 $2",
                  borderRadius: "4px",
                  background: "$gray50",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <>
                  {selectedUserAdvancedFilters &&
                  selectedUserAdvancedFilters.length > 0 ? (
                    <SelectedUsersAdvancedFilters />
                  ) : (
                    <Text>Sem filtros</Text>
                  )}
                </>

                <AddNewAdvancedFilterToUsersFilter />
              </Flex>
            </Flex> */}

            <UserActionTopBar onSearchUsers={handleSearchUsers} />
            <UsersTable
              columns={usersColumns}
              data={filteredUsers}
              status={usersStatus}
            />
          </Flex>
        </>
      ) : (
        <UsersTableSkeleton />
      )}
    </>
  );
}
