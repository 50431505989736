import { ButtonGroup } from "./ButtonGroup";
import { ClientInfoContent } from "./ClientInfoContent";
import { ClientInfoFilterWrapper } from "./ClientInfoFilterWrapper";
import { ClientInfoItem } from "./ClientInfoItem";
import { ErrorMessage } from "./ErrorMessage";
import { Info } from "./Info";
import { Paragraph } from "./Paragraph";
import { ProductTagLabel } from "./ProductTagLabel";
import { Table } from "./Table/Table";
import { Tbody } from "./Table/Tbody";
import { Td } from "./Table/Td";
import { Th } from "./Table/Th";
import { Thead } from "./Table/Thead";
import { Tr } from "./Table/Tr";

export default {
  ButtonGroup,
  ErrorMessage,
  Info,
  ClientInfoFilterWrapper,
  ClientInfoContent,
  ClientInfoItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Paragraph,
  ProductTagLabel,
};
