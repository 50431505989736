import { CellHeaderWithoutSort } from "@/components/CustomCells/CellHeaderWithoutSort";
import { TextCell } from "@/components/CustomCells/TextCell";
import { User, UserOnRelationship, UserRole } from "@/core/models/user.model";
import { selectColumnsToSort } from "@/features/clientInfo/store/clientColumnsToSortSlice";
import { useTranslateRole } from "@/features/profile/hooks/useTranslateRole";
import { Flex } from "@gogeo-io/ui-library";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { UserIsAssociatedCell } from "../components/cells/UserIsAssociatedCell";
import { UserNameCell } from "../components/cells/UserNameCell";

export const useRelatedUsersColumns = () => {
  const selectedColumnsToSort = useSelector(selectColumnsToSort);

  const translateRole = useTranslateRole();

  const userIsAssociatedColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Associado" />,
        dataIndex: "user",
        key: "user",
        render: (_, record: UserOnRelationship) => (
          <Flex
            css={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UserIsAssociatedCell user={record} />
          </Flex>
        ),
        width: "120px",
      },
    ];
  }, [selectedColumnsToSort]);

  const userRoleColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Função" />,
        dataIndex: "role",
        key: "role",
        render: (text: UserRole) => <TextCell value={translateRole(text)} />,
      },
    ];
  }, [selectedColumnsToSort]);

  const userNameColumn = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Nome" />,
        dataIndex: "user",
        key: "name",
        render: (text: string, record: User) => (
          <UserNameCell name={record.name} />
        ),
      },
    ];
  }, [selectedColumnsToSort]);

  return [...userIsAssociatedColumn, ...userNameColumn, ...userRoleColumn];
};
