import { changeParams } from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Radio, RadioGroup, Text } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectAllClientsSelectedToChangeParams,
  selectClientSelectedToChangeParams,
} from "../../store/clientSelectedToChangeParamsSlice";

export function ChangeParamsFormRoutable() {
  const [value, setValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const allClientsSelectedToChangeParams = useSelector(
    selectAllClientsSelectedToChangeParams
  );
  const selectedClientsToChangeParams = useSelector(
    selectClientSelectedToChangeParams
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allClientsSelectedToChangeParams) {
      setValue("indeterminate");
      setErrorMessage("");
    } else {
      if (!isEmpty(selectedClientsToChangeParams)) {
        if (selectedClientsToChangeParams.length === 1) {
          const clientSelected = selectedClientsToChangeParams[0];
          if (clientSelected.params.routable !== undefined) {
            setValue(String(clientSelected.params.routable));
            setErrorMessage("");
          } else {
            setErrorMessage("*Atenção parâmetro não configurado");
          }
        } else {
          setValue("indeterminate");
          setErrorMessage("");
        }
      } else {
        setValue("");
        setErrorMessage("");
      }
    }
  }, [selectedClientsToChangeParams, allClientsSelectedToChangeParams]);

  const handleRadioValueChange = async (e: string) => {
    setValue(e);
    if (e !== "indeterminate") {
      await dispatch(changeParams({ routable: e === "true" }));
    } else {
      await dispatch(changeParams({ routable: e }));
    }
  };

  const radioIsDisabled: boolean =
    selectedClientsToChangeParams.length <= 0 &&
    !allClientsSelectedToChangeParams;

  return (
    <Container>
      <Text size="body1" css={{ color: "$black" }}>
        Roteirizável para visita?
      </Text>
      <RadioGroup
        defaultValue={value}
        value={value}
        orientation="horizontal"
        onValueChange={handleRadioValueChange}
        className="radioGroup"
      >
        <div className="item">
          <Radio value="true" id="true" disabled={radioIsDisabled} />
          <Text size="body1">Sim</Text>
        </div>
        <div className="item">
          <Radio value="false" id="false" disabled={radioIsDisabled} />
          <Text size="body1">Não</Text>
        </div>
        {((!isEmpty(selectedClientsToChangeParams) &&
          selectedClientsToChangeParams.length > 1) ||
          allClientsSelectedToChangeParams) && (
          <div className="item">
            <Radio value="indeterminate" id="indeterminate" />
            <Text size="body1">Não alterar</Text>
          </div>
        )}
      </RadioGroup>
      {errorMessage && <G_UI.Info>{errorMessage}</G_UI.Info>}
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};

  .radioGroup {
    display: flex;
    gap: ${({ theme }) => theme.space[5]};

    .item {
      display: flex;
      gap: ${({ theme }) => theme.space[1]};
    }
  }
`;
