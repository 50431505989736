import { RuptureRule } from "@/core/models/rules";
import { isUndefined } from "lodash";

export const useSetValuesForFormOfRuptureRule = () => {
  return (setValue: any, selectedRuptureRule: RuptureRule) => {
    const { id, name, producers, consumers, rules, schedule_prospects } =
      selectedRuptureRule;
    !isUndefined(id) ? setValue("id", id) : setValue("id", "");

    !isUndefined(name) ? setValue("name", name) : setValue("name", "");

    !isUndefined(schedule_prospects)
      ? setValue("schedule_prospects", schedule_prospects)
      : setValue("name", false);

    !isUndefined(producers)
      ? setValue("producers", producers)
      : setValue("producers", []);

    !isUndefined(consumers)
      ? setValue("consumers", consumers)
      : setValue("consumers", []);

    !isUndefined(rules?.sales_rupture_lower_bound)
      ? setValue(
          "rules.sales_rupture_lower_bound",
          rules.sales_rupture_lower_bound
        )
      : setValue("rules.sales_rupture_lower_bound", "");

    !isUndefined(rules?.sales_rupture_upper_bound)
      ? setValue(
          "rules.sales_rupture_upper_bound",
          rules.sales_rupture_upper_bound
        )
      : setValue("rules.sales_rupture_upper_bound", "");

    !isUndefined(rules?.visit_rupture_lower_bound)
      ? setValue(
          "rules.visit_rupture_lower_bound",
          rules.visit_rupture_lower_bound
        )
      : setValue("rules.visit_rupture_lower_bound", "");

    !isUndefined(rules?.visit_rupture_upper_bound)
      ? setValue(
          "rules.visit_rupture_upper_bound",
          rules.visit_rupture_upper_bound
        )
      : setValue("rules.visit_rupture_upper_bound", "");

    !isUndefined(rules?.generic_filter)
      ? setValue("rules.generic_filter", rules.generic_filter)
      : setValue("rules.generic_filter", "");
  };
};
