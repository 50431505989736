import { TrackingEvents } from "@/config/mixpanel";
import { TagGroupListSkeleton } from "@/features/attendance/components/TagsGroupListSkeleton";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useMixpanel } from "@/hooks/useMixpanel";
import { PageLayout } from "@/layouts/PageLayout";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { NoReportsSelected } from "../components/NoReportsSelected";
import { ReportsList } from "../components/ReportsList";
import { useBreadcrumbsForReportsPage } from "../hooks/useBreadcrumbsForReportsPage";
import { getReports, selectReportsStatus } from "../store/reportsSlice";
import { ReportLoaded } from "./ReportLoaded";
import { ReportSkeletonPage } from "./ReportSkeletonPage";

export const ReportsPage = () => {
  const status = useSelector(selectReportsStatus);

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { pageViewed } = useMixpanel();
  const { showBreadcrumbs } = useBreadcrumbsForReportsPage();

  useEffect(() => {
    async function loadReports() {
      await dispatch(getReports());
    }

    loadReports();
    showBreadcrumbs();

    pageViewed(TrackingEvents.REPORTS_PAGE);
  }, []);

  return (
    <PageLayout
      leftSidebar={
        status !== "success" ? (
          <TagGroupListSkeleton count={7} />
        ) : (
          <ReportsList />
        )
      }
      content={
        pathname === "/reports" ? (
          <NoReportsSelected />
        ) : (
          <>
            {status !== "success" ? <ReportSkeletonPage /> : <ReportLoaded />}
          </>
        )
      }
    />
  );
};
