import { selectUserStatus } from "@/core/store/users/usersSlice";
import SplashScreenLogo from "@assets/logo-splash-screen.png";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { LoadingEllipsis } from "./LoadingEllipsis";

export function SplashScreen() {
  const auth = useAuth();

  const userStatus = useSelector(selectUserStatus);

  useEffect(() => {
    if (userStatus === "failed") {
      auth.removeUser();
    }
  }, [userStatus, auth]);

  return (
    <AnimatePresence initial={false}>
      <Wrapper
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <Image src={SplashScreenLogo} alt="Govendas Logo" />
        <LoadingEllipsis />
      </Wrapper>
    </AnimatePresence>
  );
}

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.white};
`;

const Image = styled.img`
  width: 540px;
  height: 214px;
`;
