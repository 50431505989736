import * as yup from "yup";

export interface ParamsOfSalesmanFormData {
  salesman_id: string;
  ignore_blocked: boolean;
  salesman_type: "EXTERNAL" | "INTERNAL";
  inactive_days: number;
  max_visit: number;
  // lat_start: number;
  // lon_start: number;
  // lat_end: number;
  // lon_end: number;
  meta_parameters: {
    active_freq: number;
    inactive_freq: number;
    skip_defaulting: boolean;
    rupture_params: {
      freq: number;
      mon_pref: number;
      tue_pref: number;
      wed_pref: number;
      thu_pref: number;
      fri_pref: number;
      sat_pref: number;
      sun_pref: number;
    };
  };
}

export const paramsOfSalesmanFormSchema = yup.object().shape({
  salesman_id: yup.string(),
  ignore_blocked: yup.boolean(),
  salesman_type: yup.string().oneOf(["INTERNAL", "EXTERNAL"]),
  inactive_days: yup.number().min(30, "Deve ser maior que 30 dias"),
  max_visit: yup
    .number()
    .min(1, "Deve ser maior que 1 cliente/dia")
    .required("Campo obrigatório"),
  // lat_start: yup
  //   .number()
  //   .min(-90, "Latitude deve ser no mínimo -90")
  //   .max(90, "Latitude deve ser no máximo 90")
  //   .typeError("Latitude deve conter números"),
  // lon_start: yup
  //   .number()
  //   .min(-180, "Longitude deve ser no mínimo -180")
  //   .max(180, "Longitude deve ser no máximo 180")
  //   .typeError("Longitude deve conter números"),
  // lat_end: yup
  //   .number()
  //   .min(-90, "Latitude deve ser no mínimo -90")
  //   .max(90, "Latitude deve ser no máximo 90")
  //   .typeError("Latitude deve conter números"),
  // lon_end: yup
  //   .number()
  //   .min(-180, "Longitude deve ser no mínimo -180")
  //   .max(180, "Longitude deve ser no máximo 180")
  //   .typeError("Longitude deve conter números"),
  meta_parameters: yup.object().shape({
    active_freq: yup.number(),
    inactive_freq: yup.number(),
    skip_defaulting: yup.boolean(),
    rupture_params: yup.object().shape({
      freq: yup.number(),
      mon_pref: yup.number().oneOf([0, 1]),
      tue_pref: yup.number().oneOf([0, 1]),
      wed_pref: yup.number().oneOf([0, 1]),
      thu_pref: yup.number().oneOf([0, 1]),
      fri_pref: yup.number().oneOf([0, 1]),
      sat_pref: yup.number().oneOf([0, 1]),
      sun_pref: yup.number().oneOf([0, 1]),
    }),
  }),
});
