import RLSSkeleton from "react-loading-skeleton";
import styled from "styled-components";

export function Skeleton() {
  const timesToRepeatSkeletonRow = Array.from(Array(25).keys());

  return (
    <Wrapper>
      {timesToRepeatSkeletonRow.map((t) => {
        return (
          <RLSSkeleton
            width={Math.floor(Math.random() * 100) + 35}
            height={25}
            key={t}
          />
        );
      })}
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
