import { CSVSelector } from "@/components/CSVSelector";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
} from "@gogeo-io/ui-library";
import { ReactNode, useState } from "react";
import { UsersToCreateValidationDialog } from "./UsersToCreateValidationDialog";

interface UsersCreateModalProps {
  children: ReactNode;
}

export function UsersCreateModal({ children }: UsersCreateModalProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [data, setData] = useState<any>([]);

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
  };

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent style={{ width: "500px" }}>
        <Flex css={{ flexDirection: "column", gap: "$4" }}>
          <DialogTitle>Adicionar usuários</DialogTitle>

          <CSVSelector onFilesAreCompleted={(data) => setData(data)} />

          <Flex
            css={{
              gap: "$2",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              bordered
              color="danger"
              onClick={() => setDialogIsOpen(false)}
            >
              Cancelar
            </Button>
            <UsersToCreateValidationDialog
              data={data}
              onCloseUsersCreateModal={handleCloseDialog}
            />
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
