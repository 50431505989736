import styled from "styled-components";

export const Timeline = styled.div`
  height: 100%;
  width: 100%;
  padding: ${(props) => props.theme.space[4]};
  position: relative;

  .history {
    max-width: 750px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
  }
`;
