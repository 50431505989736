import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface UsersState {
  status: "loading" | "success" | "failed";
}

const initialState = {
  status: "loading",
} as UsersState;

export const getToken = createAsyncThunk(
  "login/getToken",
  async (_, thunkAPI) => {
    try {
      const response = await axios.post("/authentication/login", {
        username: "tests@govendas",
        password: "123",
      });
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getToken.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getToken.fulfilled, (state, action) => {
      state.status = "success";
    });
    builder.addCase(getToken.rejected, (state, action: PayloadAction<any>) => {
      state.status = "failed";
      Sentry.configureScope((scope) => {
        scope.setLevel("error");
        scope.setTransactionName(
          "Error when logging into development environment "
        );
        scope.setExtras(action.payload);
      });
      Sentry.captureException(new ReduxThunkError(action.payload));
      toast.error(action.payload.errorMessage);
    });
  },
});

export const loginReducer = loginSlice.reducer;
