import useAppDispatch from "@/hooks/useAppDispatch";
import { Switch, SwitchThumb } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useRuptureRuleMixpanel } from "../hooks/useRuptureRuleMixpanel";
import {
  enableRule,
  getRuptureRule,
  selectRuptureRule,
} from "../store/ruptureRuleSlice";

export function EnableRuptureRule() {
  const rule = useSelector(selectRuptureRule);

  const [ruleIsEnabled, setRuleIsEnabled] = useState(rule.enabled);

  const dispatch = useAppDispatch();
  const { ruptureRulesDisabledEvent, ruptureRulesEnabledEvent } =
    useRuptureRuleMixpanel();

  const handleEnableRule = async () => {
    await dispatch(enableRule({ rule_id: rule.id, enabled: true })).then(
      async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          await dispatch(getRuptureRule()).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
              toast.success("Regras multicanais habilitadas");
              ruptureRulesEnabledEvent(rule, true);
            }
          });
        }
      }
    );
  };

  const handleDisableRule = async () => {
    await dispatch(enableRule({ rule_id: rule.id, enabled: false })).then(
      async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          await dispatch(getRuptureRule()).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
              toast.success("Regras multicanais desabilitadas");
              ruptureRulesDisabledEvent(rule, false);
            }
          });
        }
      }
    );
  };

  const handleSwitchChange = (value: boolean) => {
    setRuleIsEnabled(value);
    value ? handleEnableRule() : handleDisableRule();
  };

  useEffect(() => {
    setRuleIsEnabled(rule.enabled);
  }, [rule.enabled]);

  return (
    <Switch
      defaultChecked={true}
      checked={ruleIsEnabled}
      id="s1"
      size="small"
      css={{ padding: "2px" }}
      onCheckedChange={handleSwitchChange}
    >
      <SwitchThumb />
    </Switch>
  );
}
