import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { Report, ReportParams } from "@/core/models/reports.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useReportMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const filterReportEvent = (params: ReportParams, report: Report) => {
    MixpanelTracking.getInstance().track(TrackingEvents.REPORT_FILTERED, {
      ...buildProps(),
      Filtros: params,
      "Relatório filtrado": report,
    });
  };

  return {
    filterReportEvent,
  };
};
