import { ProfileSkeleton } from "@/components/Skeleton/ProfileSkeleton";
import RLSSkeleton from "react-loading-skeleton";
import styled from "styled-components";

export function ClientsPageSkeleton() {
  return (
    <Wrapper>
      <ProfileSkeleton />

      <Row>
        <RLSSkeleton width={115} height={18} />
        <RLSSkeleton width={115} height={18} />
      </Row>

      <Row>
        <RLSSkeleton width={30} height={12} />
        <RLSSkeleton width={100} height={14} />
        <RLSSkeleton width={100} height={14} />
        <RLSSkeleton width={44} height={26} />
      </Row>

      <RLSSkeleton width={295} height={44} />

      <Column>
        <Form>
          <RLSSkeleton width={30} height={12} />
          <RLSSkeleton width={140} height={12} />
        </Form>

        <Form>
          <RLSSkeleton width={30} height={12} />
          <RLSSkeleton width={140} height={12} />
        </Form>

        <Form>
          <RLSSkeleton width={30} height={12} />
          <RLSSkeleton width={140} height={12} />
        </Form>

        <Form>
          <RLSSkeleton width={30} height={12} />
          <RLSSkeleton width={140} height={12} />
        </Form>
      </Column>

      <RLSSkeleton width={295} height={44} />

      <RLSSkeleton width={295} height={44} />

      <RLSSkeleton width={295} height={44} />
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[5]};
  padding: 0 ${(props) => props.theme.space[1]};
  border-radius: ${({ theme }) => theme.radii.base};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[2]};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.theme.space[2]};
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
