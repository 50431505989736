import { Flex } from "@gogeo-io/ui-library";
import { BsKanban } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";
import { MdTrendingUp } from "react-icons/md";
import styled from "styled-components";
import { ClientsTypeOfView } from "../pages/ClientsListPage";

interface SelectClientsTypeOfViewProps {
  clientsTypeOfView: ClientsTypeOfView;
  onClientsTypeOfViewChange: (option: ClientsTypeOfView) => void;
}

export function SelectClientsTypeOfView({
  clientsTypeOfView,
  onClientsTypeOfViewChange,
}: SelectClientsTypeOfViewProps) {
  return (
    <Flex>
      <ListButton
        className={clientsTypeOfView === "list" ? "selected" : ""}
        onClick={() => onClientsTypeOfViewChange("list")}
      >
        <FaListUl fontSize="inherit" color="inherit" />
      </ListButton>
      <KanbanButton
        className={clientsTypeOfView === "kanban" ? "selected" : ""}
        onClick={() => onClientsTypeOfViewChange("kanban")}
      >
        <BsKanban fontSize="inherit" color="inherit" />
      </KanbanButton>
      <ReportButton
        className={clientsTypeOfView === "reports" ? "selected" : ""}
        onClick={() => onClientsTypeOfViewChange("reports")}
      >
        <MdTrendingUp fontSize="inherit" color="inherit" />
      </ReportButton>
    </Flex>
  );
}

const DefaultButton = styled.div`
  width: 38px;
  height: 38px;
  background: ${(props) => props.theme.colors.gray100};
  color: ${(props) => props.theme.colors.gray600};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  transition: all 1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;

  svg {
    width: 18px;
    height: 18px;
  }

  &:hover {
    filter: brightness(95%);
  }

  &.selected {
    background: ${(props) => props.theme.colors.primary500};
    color: ${(props) => props.theme.colors.white};
  }
`;

const ListButton = styled(DefaultButton)`
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

const KanbanButton = styled(DefaultButton)``;

const ReportButton = styled(DefaultButton)`
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`;
