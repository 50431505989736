import { Flex } from "@gogeo-io/ui-library";
import { HelpOutline } from "@mui/icons-material";
import React from "react";

export const SupportFloatingButton = React.forwardRef(() => {
  return (
    <Flex
      css={{
        position: "fixed",
        bottom: "$5",
        right: -48,
        background: "$primary500",
        color: "$white",
        paddingInline: "$2",
        cursor: "pointer",
        transition: "all 250ms ease-in-out",
        gap: "$2",
        borderRadius: "$sm 0 0 $sm",
        height: "$5",
        alignItems: "center",
        zIndex: 10000,

        "&:hover, &:focus": {
          right: 0,
          background: "$primary600",
        },
      }}
    >
      <HelpOutline fontSize="inherit" htmlColor="white" />
      ajuda
    </Flex>
  );
});
