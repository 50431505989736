import { NoPageSelected } from "@/components/NoPageSelected";
import { Main } from "@/layouts/Main";
import OpenTabsImg from "@assets/choose.svg";

export function NoListOfTagsSelected() {
  return (
    <Main>
      <NoPageSelected
        src={OpenTabsImg}
        alt="Selecione uma lista"
        title="Selecione uma lista"
      >
        <span>Selecione uma lista de clientes e registre seu atendimento.</span>
      </NoPageSelected>
    </Main>
  );
}
