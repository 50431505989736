import { SelectedDateTypeOption } from "@/components/SelectedDateTypeOption";
import { Button, Flex } from "@gogeo-io/ui-library";
import { useState } from "react";
import { BeforeDateSelect } from "./BeforeDateSelect";
import { BetweenDateSelect } from "./BetweenDateSelect";
import { EmptyDateSelected } from "./EmptyDateSelect";
import { InThisDateSelect } from "./InThisDateSelect";
import { InThisOneDateSelect } from "./InThisOneDateSelect";

interface GenericDateFilterContentProps {
  optionSelected: string;
  onOptionSelected: (value: string) => void;
  filterId: number;
  value: string;
  onUpdatedFilter: (filterId: number, newValue: string) => void;
  onClosePopover: () => void;
}

interface FilterToChange {
  filterId: number;
  newValue: string;
}

export function GenericDateFilterContent({
  optionSelected,
  onOptionSelected,
  filterId,
  value,
  onUpdatedFilter,
  onClosePopover,
}: GenericDateFilterContentProps) {
  const [filterChanged, setFilterChanged] = useState<FilterToChange>(
    {} as FilterToChange
  );

  const applyFilter = () => {
    onUpdatedFilter(filterChanged.filterId, filterChanged.newValue);
    setFilterChanged({} as FilterToChange);
    onClosePopover();
  };

  const handleFilterValueChange = (filterId: number, newValue: string) => {
    const filter: FilterToChange = { filterId, newValue };
    setFilterChanged(filter);
  };

  return (
    <Flex
      css={{
        gap: "$4",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Flex css={{ gap: "$2", alignItems: "center" }}>
        <SelectedDateTypeOption
          optionSelected={optionSelected}
          setOptionSelected={onOptionSelected}
        />

        {optionSelected === "empty" && (
          <EmptyDateSelected
            filterId={filterId}
            onFilterValueChange={handleFilterValueChange}
          />
        )}

        {optionSelected === "before" && (
          <BeforeDateSelect
            value={value}
            filterId={filterId}
            onFilterValueChange={handleFilterValueChange}
          />
        )}

        {optionSelected === "inthis1" && (
          <InThisOneDateSelect
            value={value}
            filterId={filterId}
            onFilterValueChange={handleFilterValueChange}
          />
        )}

        {optionSelected === "inthis" && (
          <InThisDateSelect
            value={value}
            filterId={filterId}
            onFilterValueChange={handleFilterValueChange}
          />
        )}

        {optionSelected === "between" && (
          <BetweenDateSelect
            value={value}
            filterId={filterId}
            onFilterValueChange={handleFilterValueChange}
          />
        )}
      </Flex>

      <Flex
        css={{ alignItems: "flex-end", justifyContent: "flex-end" }}
        onClick={applyFilter}
      >
        <Button>Aplicar</Button>
      </Flex>
    </Flex>
  );
}
