import { Text } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface UserActiveCellProps {
  active: boolean;
}

export function UserActiveCell({ active }: UserActiveCellProps) {
  return (
    <AtiveCell $active={active}>
      <Text css={{ color: "inherit", fontWeight: 500 }}>
        {active ? "Ativo" : "Inativo"}
      </Text>
    </AtiveCell>
  );
}

interface RoleCellProps {
  $active: boolean;
}

const AtiveCell = styled.div<RoleCellProps>`
  width: 100px;
  padding: ${(props) => props.theme.space[1]} ${(props) => props.theme.space[4]};
  text-align: center;
  border: 1px solid
    ${(props) => props.theme.colors[props.$active ? "primary500" : "red500"]};
  color: ${(props) =>
    props.theme.colors[props.$active ? "primary500" : "red500"]};

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    width: 80px;
  }
`;
