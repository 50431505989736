import { uploadFileToClientNote } from "@/core/store/uploadFile/uploadFileSlice";
import { AttachFileToRegister } from "@/features/attendance/components/register-attendance/AttachFileToRegister";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  Text,
} from "@gogeo-io/ui-library";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";

interface TryAgainUploadFileToClientNoteProps {
  noteId: string;
  errorMessage: string;
  triedToUploadFiles: File[];
  onCreateClientNoteSuccess: (note_id: string) => void;
  onGoBack: () => void;
}

export function TryAgainUploadFileToClientNote({
  errorMessage,
  noteId,
  triedToUploadFiles,
  onCreateClientNoteSuccess,
  onGoBack,
}: TryAgainUploadFileToClientNoteProps) {
  const [files, setFiles] = useState<File[]>(triedToUploadFiles);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleTryUploadFileAgain = async () => {
    setIsLoading(true);

    const formData = new FormData();

    const mergedFiles = [...files, ...newFiles];

    if (mergedFiles && mergedFiles.length > 0) {
      for (let i = 0; i < mergedFiles.length; i++) {
        formData.append(`files`, mergedFiles[i]);
      }

      await dispatch(
        uploadFileToClientNote({
          note_id: noteId,
          data: formData,
        })
      ).then(async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setIsOpen(false);
          setIsLoading(false);
          onCreateClientNoteSuccess(noteId);
        } else {
          toast.error("Ocorreu novamente o erro, tente novamente mais tarde");
          setIsLoading(false);
          setIsOpen(false);
          onGoBack();
        }
      });
    } else {
      toast.success("Você criou a nota, mas sem arquivos");
      setIsLoading(false);
      setIsOpen(false);
      onGoBack();
    }
  };

  const handleAddNewFiles = (files: File[]) => setNewFiles(files);

  const handleRemoveFileAttached = (fileNameToRemove: string) => {
    const filesWithoutRemovedOne = files.filter(
      (file) => file.name !== fileNameToRemove
    );
    setFiles(filesWithoutRemovedOne);
  };

  const handleCloseFormWithoutUploadFile = () => {
    setIsOpen(false);
    onGoBack();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger />
      <DialogContent
        style={{ width: "450px" }}
        onInteractOutside={(e) => e.preventDefault()}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <Flex
          css={{
            flexDirection: "column",
            gap: "$5",
          }}
        >
          <Heading>Tivemos um problema no envio de arquivos</Heading>

          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <Text>O seguinte erro foi emitido: </Text>
            <Text css={{ color: "$red500" }}>{errorMessage}</Text>
          </Flex>

          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            {files.length > 0 && <Text>Arquivos que você enviou: </Text>}
            {files.length > 0 && (
              <Flex css={{ width: "100%", flexWrap: "wrap", gap: "$3" }}>
                {files &&
                  files.map((file) => {
                    return (
                      <Flex
                        css={{
                          alignItems: "center",
                          color: "$gray500",
                          fontSize: "16px",
                          border: "1px solid $gray300",
                          gap: "2px",
                          padding: "$1 $2",
                          borderRadius: "$sm",
                        }}
                        key={file.name}
                      >
                        <Text css={{ color: "$gray600" }}>{file.name}</Text>
                        <MdClose
                          color="inherit"
                          fontSize="inherit"
                          cursor="pointer"
                          onClick={() => handleRemoveFileAttached(file.name)}
                        />
                      </Flex>
                    );
                  })}
              </Flex>
            )}
          </Flex>

          <Flex css={{ flexDirection: "column", gap: "$1" }}>
            <Text>Adicionar mais arquivos: </Text>
            <AttachFileToRegister
              onChange={handleAddNewFiles}
              files={newFiles}
              titleOfDialog="Anexar Mais Arquivos"
            />
          </Flex>

          <Flex
            css={{
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Text>Deseja tentar novamente?</Text>

            <Flex
              css={{
                gap: "0.5rem",
                marginTop: "0.75rem",
              }}
            >
              <DialogClose asChild>
                <Button
                  color="danger"
                  variant="soft"
                  onClick={handleCloseFormWithoutUploadFile}
                >
                  Criar Nota sem arquivos
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button
                  isLoading={isLoading}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleTryUploadFileAgain();
                  }}
                >
                  Tentar Novamente
                </Button>
              </DialogClose>
            </Flex>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}
