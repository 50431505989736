import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { isEmpty } from "lodash";

export const useFormatDate = () => {
  const formatWithDigits = (hour: number, digits: number): string => {
    const hourFormatted = hour.toLocaleString("pt-BR", {
      minimumIntegerDigits: digits,
      useGrouping: false,
    });

    return hourFormatted;
  };

  const formatLongDatePtBr = (
    date: string,
    isDateWithoutTimezone?: boolean
  ): string => {
    if (date !== "" || date !== undefined) {
      let parsedDate: Date;

      if (isDateWithoutTimezone) {
        parsedDate = newDateBasedOnDateWithoutTimezone(date);
      } else {
        parsedDate = new Date(date);
      }

      return format(parsedDate, "EEEE',' dd 'de' MMMM 'de' yyyy", {
        locale: ptBR,
      });
    } else {
      return "- Não Consta -";
    }
  };

  const formatLongDatePtBrWithoutWeekday = (
    date: string,
    isDateWithoutTimezone?: boolean
  ): string => {
    if (!isEmpty(date)) {
      let parsedDate: Date;

      if (isDateWithoutTimezone) {
        parsedDate = newDateBasedOnDateWithoutTimezone(date);
      } else {
        parsedDate = new Date(date);
      }

      return format(parsedDate, "dd 'de' MMMM 'de' yyyy", {
        locale: ptBR,
      });
    }
  };

  const formatBasicDate = (date: Date): string => {
    return format(date, "yyyy-MM-dd");
  };

  const formatBasicDateWithSlash = (date: Date): string => {
    return format(date, "dd/MM/yyy");
  };

  const formatDateWithHourAndMinutes = (date: Date): string => {
    return format(date, "dd/MM/yyyy | hh:mm a ");
  };

  const formatHourAndMinutes = (date: Date): string => {
    return format(date, "HH:mm");
  };

  const getNowDateToRegisterFeedback = (): string => {
    return format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX");
  };

  const fullDateWithTimezone = (date: string): string => {
    return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ssXXX");
  };

  const newDateBasedOnDateWithoutTimezone = (date: string): Date => {
    const parsedDate = new Date(date);

    return new Date(
      parsedDate.valueOf() + parsedDate.getTimezoneOffset() * 60 * 1000
    );
  };

  const getDay = (option: string) => {
    switch (option) {
      case "mon":
        return "Segunda-Feira";
      case "tue":
        return "Terça-Feira";
      case "wed":
        return "Quarta-Feira";
      case "thu":
        return "Quinta-Feira";
      case "fri":
        return "Sexta-Feira";
      case "sat":
        return "Sábado";
      case "sun":
        return "Domingo";
      default:
        return "Segunda-Feira";
    }
  };

  return {
    formatWithDigits,
    formatLongDatePtBr,
    formatBasicDate,
    formatLongDatePtBrWithoutWeekday,
    getNowDateToRegisterFeedback,
    formatBasicDateWithSlash,
    formatDateWithHourAndMinutes,
    formatHourAndMinutes,
    getDay,
    fullDateWithTimezone,
    newDateBasedOnDateWithoutTimezone,
  };
};
