import { changeParams } from "@/features/params/store/paramsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Radio, RadioGroup, Text } from "@gogeo-io/ui-library";
import G_UI from "@ui/index";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectAllClientsSelectedToChangeParams,
  selectClientSelectedToChangeParams,
} from "../../store/clientSelectedToChangeParamsSlice";

interface SchedulingPreference {
  openWeekRestriction: (value: boolean) => void;
}

export function ChangeParamsFormSchedulingPreference({
  openWeekRestriction,
}: SchedulingPreference) {
  const [value, setValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const selectedClientsToChangeParams = useSelector(
    selectClientSelectedToChangeParams
  );
  const allClientsSelectedToChangeParams = useSelector(
    selectAllClientsSelectedToChangeParams
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allClientsSelectedToChangeParams) {
      setValue("indeterminate");
      openWeekRestriction(false);
      setErrorMessage("");
    } else {
      if (!isEmpty(selectedClientsToChangeParams)) {
        if (selectedClientsToChangeParams.length === 1) {
          const clientSelected = selectedClientsToChangeParams[0];
          if (clientSelected.params.fixed !== undefined) {
            setValue(String(clientSelected.params.fixed));
            openWeekRestriction(clientSelected.params.fixed);
            setErrorMessage("");
          } else {
            openWeekRestriction(false);
            setErrorMessage("*Atenção parâmetro não configurado");
          }
        } else {
          setValue("indeterminate");
          setErrorMessage("");
          openWeekRestriction(false);
        }
      } else {
        setValue("");
        openWeekRestriction(false);
        setErrorMessage("");
      }
    }
  }, [selectedClientsToChangeParams, allClientsSelectedToChangeParams]);

  const handleRadioValueChange = async (value: string) => {
    setValue(value);
    openWeekRestriction(value === "true");
    if (value !== "indeterminate") {
      if (value === "true") {
        await dispatch(changeParams({ fixed: true }));
        if (
          !isEmpty(selectedClientsToChangeParams) &&
          !isEmpty(selectedClientsToChangeParams[0].params.week_pref)
        ) {
          await dispatch(
            changeParams({
              week_pref: selectedClientsToChangeParams[0].params.week_pref,
            })
          );
        } else {
          await dispatch(changeParams({ week_pref: { range: "Irrestrito" } }));
        }
      } else {
        await dispatch(changeParams({ fixed: false }));
        await dispatch(changeParams({ week_pref: "cleanFilter" }));
      }
    } else {
      await dispatch(changeParams({ fixed: value }));
      await dispatch(changeParams({ week_pref: "cleanFilter" }));
    }
  };

  const radioIsDisabled: boolean =
    selectedClientsToChangeParams.length <= 0 &&
    !allClientsSelectedToChangeParams;

  return (
    <Container>
      <Text size="body1" css={{ color: "$black" }}>
        Preferência de agendamento
      </Text>
      <RadioGroup
        defaultValue={value}
        value={value}
        orientation="horizontal"
        onValueChange={handleRadioValueChange}
        className="radioGroup"
      >
        <div className="item">
          <Radio value="false" id="false" disabled={radioIsDisabled} />
          <Text size="body1">Dinâmico</Text>
        </div>
        <div className="item">
          <Radio value="true" id="true" disabled={radioIsDisabled} />
          <Text size="body1">Fixo</Text>
        </div>
        {((!isEmpty(selectedClientsToChangeParams) &&
          selectedClientsToChangeParams.length > 1) ||
          allClientsSelectedToChangeParams) && (
          <div className="item">
            <Radio value="indeterminate" id="indeterminate" />
            <Text size="body1">Não alterar</Text>
          </div>
        )}
      </RadioGroup>
      {errorMessage && <G_UI.Info>{errorMessage}</G_UI.Info>}
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};

  .radioGroup {
    display: flex;
    gap: ${({ theme }) => theme.space[5]};

    .item {
      display: flex;
      gap: ${({ theme }) => theme.space[1]};
    }
  }
`;
