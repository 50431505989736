import styled from "styled-components";

export const TextArea = styled.textarea`
  width: 99%;
  height: 170px;
  padding: 0.75rem;
  border-radius: ${({ theme }) => theme.radii.sm};
  resize: none;
  margin-left: 2px;
  transition: outline 150ms ease-in-out;
  outline: 2px solid ${({ theme }) => theme.colors.gray200};
  color: ${({ theme }) => theme.colors.gray600};

  font-family: ${({ theme }) => theme.fonts.base};
  line-height: 1.35;

  &:focus,
  &:hover {
    outline: 2px solid ${({ theme }) => theme.colors.gray400};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.gray900};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;
