import { AttendanceRecord } from "@core/models/history.model";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { TimelineAttendanceRecordAttendanceInfo } from "./TimelineAttendanceRecordAttendanceInfo";
import { TimelineAttendanceRecordAttendanceItem } from "./TimelineAttendanceRecordAttendanceItem";
import { TimelineHeaderInfoOrderFiles } from "./TimelineHeaderInfo/TimelineHeaderInfoOrderFiles";
import { TimelineHeaderInfoOrderObservations } from "./TimelineHeaderInfo/TimelineHeaderInfoOrderObservations";

export interface ContentProps {
  lastAttendance: AttendanceRecord;
  othersAttendances: AttendanceRecord[];
  hasObservations: boolean;
  hasAttendanceInfo: boolean;
  hasMoreAttendanceRecord: boolean;
  hasAttachments: boolean;
}
export function TimelineAttendanceRecordContent({
  lastAttendance,
  othersAttendances,
  hasObservations,
  hasAttendanceInfo,
  hasMoreAttendanceRecord,
  hasAttachments,
}: ContentProps) {
  const { observations, attendance_info } = lastAttendance;

  return (
    <Flex
      style={{ flexDirection: "column", gap: "0.5rem", marginTop: "0.5rem" }}
    >
      {hasObservations && (
        <div>
          <Caption>Observações:</Caption>
          <TimelineHeaderInfoOrderObservations observations={observations} />
        </div>
      )}

      {hasAttendanceInfo && (
        <div>
          <Caption>Informações do atendimento:</Caption>
          <TimelineAttendanceRecordAttendanceInfo
            attendance_info={attendance_info}
          />
        </div>
      )}

      {hasAttachments && (
        <div>
          <Caption>Anexos:</Caption>
          <TimelineHeaderInfoOrderFiles files={lastAttendance.files} />
        </div>
      )}

      {hasMoreAttendanceRecord && (
        <div>
          <Caption>Linha do tempo do atendimento</Caption>
          {othersAttendances.length > 0 &&
            othersAttendances.map((attendance, idx) => {
              return (
                <TimelineAttendanceRecordAttendanceItem
                  key={idx}
                  attendance={attendance}
                />
              );
            })}
        </div>
      )}
    </Flex>
  );
}
