import { AttendanceStatus } from "@/core/models/business-goal.model";
import { Caption } from "@gogeo-io/ui-library";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

interface RegisterAttendanceStatusProps {
  attendanceStatusSelected: AttendanceStatus;
  setAttendanceStatusSelected: (option: AttendanceStatus) => void;
}

export function RegisterAttendanceStatus({
  attendanceStatusSelected,
  setAttendanceStatusSelected,
}: RegisterAttendanceStatusProps) {
  const [attendanceStatus, setAttendanceStatus] = useState<AttendanceStatus>(
    attendanceStatusSelected
  );

  const handleClickAttendaceStatusButton = async (status: AttendanceStatus) => {
    setAttendanceStatus(status);
  };

  useEffect(() => {
    setAttendanceStatusSelected(attendanceStatus);
  }, [attendanceStatus]);

  return (
    <Wrapper>
      <CustomButton
        type="button"
        $isSelected={attendanceStatus === "Não Vendi"}
        onClick={() => handleClickAttendaceStatusButton("Não Vendi")}
      >
        <CloseIcon color="inherit" />
        {attendanceStatus === "Não Vendi" ? (
          <Caption css={{ color: "$primary500" }} bold>
            Não Vendi
          </Caption>
        ) : (
          <Caption>Não Vendi</Caption>
        )}
      </CustomButton>

      <CustomButton
        type="button"
        $isSelected={attendanceStatus === "Em andamento"}
        onClick={() => handleClickAttendaceStatusButton("Em andamento")}
      >
        <AccessTimeIcon color="inherit" />
        {attendanceStatus === "Em andamento" ? (
          <Caption css={{ color: "$primary500" }} bold>
            Em andamento
          </Caption>
        ) : (
          <Caption>Em andamento</Caption>
        )}
      </CustomButton>

      <CustomButton
        type="button"
        $isSelected={attendanceStatus === "Vendi"}
        onClick={() => handleClickAttendaceStatusButton("Vendi")}
      >
        <DoneIcon color="inherit" />
        {attendanceStatus === "Vendi" ? (
          <Caption css={{ color: "$primary500" }} bold>
            Vendi
          </Caption>
        ) : (
          <Caption>Vendi</Caption>
        )}
      </CustomButton>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
`;

export const CustomButton = styled.button<{
  $isSelected: boolean;
}>`
  border-radius: 60px;
  color: ${({ theme }) => theme.colors.gray400};
  background: transparent;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: ${({ theme }) => theme.space[2]};
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  text-align: center;

  ${(props) =>
    props.$isSelected &&
    css`
      background: ${({ theme }) => theme.colors.primary100};
      color: ${({ theme }) => theme.colors.primary500};
    `}
`;
