import styled from "styled-components";

export const RegisterAttendanceReminderForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: ${({ theme }) => theme.space[4]};
  border-radius: ${({ theme }) => theme.space[2]};
`;
