import { ReactElement } from "react";
import { useSlate } from "slate-react";
import styled from "styled-components";
import { TEXT_EDITOR_TEXT_ALIGN_TYPES } from "../config/textEditorConfig";
import { useTextEditorActions } from "../hooks/useTextEditorActions";

interface BlockButtonProps {
  format: string;
  icon: ReactElement;
}

export function TextEditBlockButton({ format, icon }: BlockButtonProps) {
  const editor = useSlate();
  const { isBlockActive, toggleBlock } = useTextEditorActions();

  return (
    <TextEditorBlockButtonStyled
      active={isBlockActive(
        editor,
        format,
        TEXT_EDITOR_TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </TextEditorBlockButtonStyled>
  );
}

interface TextEditorBlockButtonStyledProps {
  active: boolean;
}

export const TextEditorBlockButtonStyled = styled.div<TextEditorBlockButtonStyledProps>`
  svg {
    color: ${(props) =>
      props.active ? props.theme.colors.gray800 : props.theme.colors.gray400};
    font-size: ${(props) => props.theme.fontSizes.base};
  }
`;
