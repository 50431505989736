import { Caption, Flex } from "@gogeo-io/ui-library";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

interface FilterWithErrorProps extends React.HTMLAttributes<HTMLDivElement> {
  hasError?: boolean;
  hasCloseButton?: boolean;
  onClickCloseButton?: () => void;
}

export function FilterWithError({
  hasCloseButton = false,
  hasError = false,
  onClickCloseButton,
  children,
  ...props
}: FilterWithErrorProps) {
  return (
    <Container $hasError={hasError} {...props}>
      <Flex
        css={{
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Caption css={{ color: hasError ? "$red500" : "$gray700" }}>
          {children}
        </Caption>
      </Flex>
      {hasCloseButton && <CloseIcon onClick={onClickCloseButton} />}
    </Container>
  );
}

interface ContainerProps {
  $hasError: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[1]} 0.5rem;
  border-radius: ${(props) => props.theme.space[2]};
  border: 1px solid
    ${(props) =>
      props.$hasError ? props.theme.colors.red300 : props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.gray100};
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  svg {
    color: ${(props) =>
      props.$hasError ? props.theme.colors.red500 : props.theme.colors.gray500};
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;
