import { Tag } from "@/core/models/tag.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Caption, Checkbox, Flex, Text } from "@gogeo-io/ui-library";
import { isUndefined } from "lodash";
import { useSelector } from "react-redux";
import { useAttendanceMixpanel } from "../../hooks/useAttendanceMixpanel";
import {
  addTagOnSelectedClientFilter,
  removeTagOnSelectedClientFilter,
  selectSelectedTagsOnClientFilters,
} from "../../store/clientAdvancedFiltersSlice";
import { selectSelectedList } from "../../store/listSelectedSlice";
import UI from "../../ui";

interface TagProps {
  tag: Tag;
}

export function TagItem({ tag }: TagProps) {
  const selectedTags = useSelector(selectSelectedTagsOnClientFilters);
  const selectedList = useSelector(selectSelectedList);

  const dispatch = useAppDispatch();
  const { tagRemovedOnListEvent, tagAddedOnListEvent } =
    useAttendanceMixpanel();

  const handleRemoveTag = async (tagToRemove: Tag) => {
    await dispatch(removeTagOnSelectedClientFilter(tagToRemove));
  };

  const handleAddTag = async (tagToAdd: Tag) => {
    await dispatch(addTagOnSelectedClientFilter(tagToAdd));
  };

  const handleCheck = async (e: React.MouseEvent, tag: Tag) => {
    if (e.currentTarget === e.target) {
      if (e) {
        handleAddTag(tag);
        tagAddedOnListEvent(tag, selectedList.filters, selectedList);
      } else {
        handleRemoveTag(tag);
        tagRemovedOnListEvent(tag, selectedList.filters, selectedList);
      }
    }
  };

  const tagIsSelected = (tag: Tag) => {
    return selectedTags?.some(
      (list) =>
        list.label === tag.label || list.slug === tag.slug || list.id === tag.id
    );
  };

  return (
    <UI.TagsListItem className={tagIsSelected(tag) ? "active" : ""}>
      <Flex>
        <Checkbox
          id={String(tag.id)}
          checked={tagIsSelected(tag)}
          onCheckedChange={(e: any) => handleCheck(e, tag)}
          css={{ marginLeft: "5px", marginRight: "5px" }}
        />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "2px" }}>
        <Text size="body2" css={{ color: "$gray800" }}>
          {tag.label}
        </Text>
        <Caption css={{ display: "block", color: "$gray500" }}>
          <label htmlFor={String(tag.id)}>{tag.description}</label>
        </Caption>
        <Caption css={{ display: "block", color: "$gray500" }}>
          Quantidade de clientes:
          {!isUndefined(tag.clients_quantity)
            ? ` ${tag.clients_quantity}`
            : " Não calculado"}
        </Caption>
      </Flex>
    </UI.TagsListItem>
  );
}
