import styled from "styled-components";
import { RescheduleDate } from "../RescheduleDate";
import { FeedbackReminder } from "../feedback/FeedbackReminder";

interface DateActionsProps {
  onChange: (...event: any[]) => void;
  value: string;
}

export default function DateActions({ onChange, value }: DateActionsProps) {
  return (
    <ReminderWrapper>
      <RescheduleDate onChange={onChange} value={value} />

      <FeedbackReminder />
    </ReminderWrapper>
  );
}

export const ReminderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.space[2]} 0;
`;
