import { ListDivider } from "@/components/ListDivider";
import {
  PreDefinedMessage,
  PreDefinedMessageSubject,
} from "@/core/models/preDefinedMessage.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  Heading,
  IconButton,
} from "@gogeo-io/ui-library";
import { Close } from "@mui/icons-material";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getPreDefinedMessageTokens,
  selectPreDefinedMessageTokensStatus,
} from "../store/preDefinedMessagesTokensSlice";
import { CreatePreDefinedMessageSkeleton } from "./CreatePreDefinedMessageSkeleton";
import { PreDefinedMessageTextArea } from "./PreDefinedMessageTextArea";

interface EditPreDefinedMessageToSendProps {
  preDefinedMessage: PreDefinedMessage;
  onEditPreDefinedMessage: (message: string) => Promise<void>;
  children: ReactNode;
}

export function EditPreDefinedMessageToSend({
  preDefinedMessage,
  onEditPreDefinedMessage,
  children,
}: EditPreDefinedMessageToSendProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newMessage, setNewMessage] = useState(preDefinedMessage.message);
  const [preDefinedMessagesSubject, setPreDefinedMessagesSubject] =
    useState<PreDefinedMessageSubject>(preDefinedMessage.subject);

  const preDefinedMessageTokenStatus = useSelector(
    selectPreDefinedMessageTokensStatus
  );

  const dispatch = useAppDispatch();

  const handleSetNewMessage = (value: string) => setNewMessage(value);

  const handleUpdateMessage = () => {
    setModalIsOpen(true);

    if (newMessage === "") {
      toast.error("Texto da mensagem não pode ser vazio");
      return;
    }

    onEditPreDefinedMessage(newMessage).then(() => {
      setModalIsOpen(false);
    });
  };

  const handleDialogOpen = (value: boolean) => {
    setModalIsOpen(value);
  };

  useEffect(() => {
    const getTokens = async () => {
      await dispatch(getPreDefinedMessageTokens(preDefinedMessagesSubject));
    };

    getTokens();
  }, [preDefinedMessagesSubject]);

  return (
    <Dialog onOpenChange={handleDialogOpen} open={modalIsOpen}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent style={{ zIndex: 9999 }}>
        {preDefinedMessageTokenStatus !== "success" ? (
          <CreatePreDefinedMessageSkeleton />
        ) : (
          <>
            <DialogClose>
              <IconButton
                size="small"
                type="ghost"
                css={{ position: "absolute", top: "1rem", right: "1rem" }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </DialogClose>
            <DialogTitle>Editar mensagem antes de enviar</DialogTitle>

            <ListDivider />

            <Flex css={{ gap: "1.5rem", padding: "1rem 0" }}>
              <Flex css={{ flexDirection: "column", gap: "$2" }}>
                <Flex
                  css={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "$2",
                  }}
                >
                  <Heading
                    size="subtitle2"
                    css={{ display: "flex", flexDirection: "row" }}
                  >
                    Mensagem
                    <Heading
                      size="subtitle2"
                      css={{ color: "$red500", fontWeight: "bold" }}
                    >
                      *
                    </Heading>
                  </Heading>
                </Flex>
                <Flex css={{ gap: "$2", alignItems: "flex-start" }}>
                  <PreDefinedMessageTextArea
                    newMessage={newMessage}
                    onNewMessageChange={handleSetNewMessage}
                  />
                </Flex>
              </Flex>
            </Flex>
            <ListDivider />

            <Flex
              css={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}
            >
              <Button onClick={handleUpdateMessage} isLoading={!modalIsOpen}>
                Editar Mensagem
              </Button>
            </Flex>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
