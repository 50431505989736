import {
  Flex,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";

interface UserPhoneCellProps {
  phone: string;
}

export function UserPhoneCell({ phone }: UserPhoneCellProps) {
  return (
    <Flex>
      <Flex css={{ flexDirection: "column", width: "100%", gap: "0rem" }}>
        <Tooltip>
          <TooltipTrigger
            style={{ background: "transparent", textAlign: "left" }}
          >
            <Text
              size="body2"
              css={{
                textTransform: "capitalize",
                maxWidth: "200px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                color: "$gray700",
              }}
            >
              {phone}
            </Text>
          </TooltipTrigger>
          <TooltipContent aria-label={`O telefone do cliente é ${phone}`}>
            {phone}
          </TooltipContent>
        </Tooltip>
      </Flex>
    </Flex>
  );
}
