import {
  Input,
  Select,
  SelectItem,
  SelectItemText,
} from "@gogeo-io/ui-library";
import { useDateByDateFilter } from "@hooks/useDateByDateField";
import { useEffect, useState } from "react";

interface BeforeDateSelectProps {
  filterId: number;
  value: string;
  onFilterValueChange: (filterId: number, newValue: string) => void;
}

export function BeforeDateSelect({
  filterId,
  value,
  onFilterValueChange,
}: BeforeDateSelectProps) {
  const { getValuesByDateFilter } = useDateByDateFilter();

  const dateFilter = getValuesByDateFilter(
    value !== undefined ? value : "before1months"
  );
  const [beforeDate, setBeforeDate] = useState(
    dateFilter.before !== undefined ? dateFilter.before.date_option : "months"
  );
  const [beforeDateNumber, setBeforeDateNumber] = useState(
    dateFilter.before !== undefined ? dateFilter.before.value : 1
  );

  useEffect(() => {
    const formattedDate = `before${beforeDateNumber}${beforeDate}`;
    onFilterValueChange(filterId, formattedDate);
  }, [beforeDate, beforeDateNumber]);

  const handleInputChange = (value: number) => {
    setBeforeDateNumber(value);
  };

  return (
    <>
      <Input
        type="number"
        style={{ width: "55px" }}
        defaultValue={beforeDateNumber}
        onChange={(e) => handleInputChange(Number(e.target.value))}
      />
      <Select
        defaultValue={beforeDate}
        ariaLabel="Past Date"
        onValueChange={(v) => setBeforeDate(v)}
      >
        <SelectItem value="days">
          <SelectItemText>Dias</SelectItemText>
        </SelectItem>
        <SelectItem value="weeks">
          <SelectItemText>Semanas</SelectItemText>
        </SelectItem>
        <SelectItem value="months">
          <SelectItemText>Meses</SelectItemText>
        </SelectItem>
        <SelectItem value="years">
          <SelectItemText>Anos</SelectItemText>
        </SelectItem>
      </Select>
    </>
  );
}
