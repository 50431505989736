import {
  Flex,
  Select,
  SelectItem,
  SelectItemText,
  Text,
} from "@gogeo-io/ui-library";
import { useTranslateFieldToCreateUser } from "../hooks/useTranslateFieldToCreateUser";

interface UsersToValidateContentProps {
  incorrectProps: string[];
  correctProps: string[];
  onSelectCorrectProps: (incorrectProp: string, correctProp: string) => void;
}

export function ListIncorrectPropsToCorrect({
  incorrectProps,
  correctProps,
  onSelectCorrectProps,
}: UsersToValidateContentProps) {
  const translateField = useTranslateFieldToCreateUser();

  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      {incorrectProps.map((incorrectProp) => (
        <Flex key={incorrectProp} css={{ gap: "$4", alignItems: "center" }}>
          <Text css={{ width: "100px" }}>{incorrectProp}</Text>
          <Flex
            css={{
              flexDirection: "row",
              alignItems: "center",
              gap: "$2",
            }}
          >
            <Text>Você quis dizer:</Text>
            <Select
              defaultValue=""
              ariaLabel="Select correct prop"
              onValueChange={(correctProp) =>
                onSelectCorrectProps(incorrectProp, correctProp)
              }
            >
              {correctProps.map((correctProp) => {
                return (
                  <SelectItem key={correctProp} value={correctProp}>
                    <SelectItemText>
                      {translateField(correctProp)}
                    </SelectItemText>
                  </SelectItem>
                );
              })}
            </Select>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}
