import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Flex,
  Heading,
  Text,
} from "@gogeo-io/ui-library";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export function TokenHasExpiredDialog() {
  const navigate = useNavigate();

  const handleSignOut = () => {
    navigate("/logout");
  };

  return (
    <Dialog open={true}>
      <DialogTrigger style={{ display: "none", visibility: "hidden" }} />
      <DialogContent
        style={{ width: "400px" }}
        onInteractOutside={(e) => e.preventDefault()}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <Flex
          css={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "$6",
          }}
        >
          <Heading>Sua Sessão Expirou</Heading>

          <Text css={{ textIndent: "20px", textAlign: "justify" }}>
            Sua sessão foi encerrada. Se você deseja continuar explorando o{" "}
            <strong>goVendas</strong>, clique em{" "}
            <NoWrapSpan>&quot;Entrar Novamente&quot;</NoWrapSpan> para ser
            redirecionado à nossa página de login.
          </Text>

          <Flex
            css={{
              gap: "$4",
              width: "100%",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              fullWidth
              bordered
              onClick={handleSignOut}
              style={{ width: "fit-content" }}
            >
              Entrar Novamente
            </Button>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  );
}

export const NoWrapSpan = styled.span`
  white-space: nowrap;
`;
