import { AttendanceStatus } from "@/core/models/business-goal.model";
import { gvTheme } from "@gogeo-io/ui-library";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export const useByFeedback = () => {
  const getAttendanceStatusByFeedbackForAttendanceRegister = (
    feedback_id: number
  ): AttendanceStatus => {
    if (feedback_id >= 10 && feedback_id < 100) {
      return "Em andamento";
    } else if (feedback_id >= 100 && feedback_id < 200) {
      return "Vendi";
    } else {
      return "Não Vendi";
    }
  };

  const hasAttendanceRegister = (feedback_id: number): boolean =>
    feedback_id >= 10;

  const getTextByFeedback = (feedback_id: number) => {
    if (feedback_id === undefined) {
      return "Não Possui";
    } else {
      if (feedback_id === 0) {
        return "Em aberto";
      } else if (feedback_id > 0 && feedback_id < 10) {
        return "Não atendido";
      } else if (feedback_id >= 10 && feedback_id < 100) {
        return "Em andamento";
      } else if (feedback_id >= 100 && feedback_id < 200) {
        return "Positivado";
      } else {
        return "Não Venda";
      }
    }
  };

  const getIconByStatus = (feedback_id: number) => {
    if (feedback_id === undefined) {
      return <></>;
    } else {
      if (feedback_id === 0) {
        return <AccessTimeIcon color="inherit" fontSize="inherit" />;
      } else if (feedback_id > 0 && feedback_id < 10) {
        return <BlockIcon color="inherit" fontSize="inherit" />;
      } else if (feedback_id >= 10 && feedback_id < 100) {
        return <AutorenewIcon color="inherit" fontSize="inherit" />;
      } else if (feedback_id >= 100 && feedback_id < 200) {
        return <CheckIcon color="inherit" fontSize="inherit" />;
      } else {
        return <CloseIcon color="inherit" fontSize="inherit" />;
      }
    }
  };

  const getColorByFeedback = (feedback_id: number) => {
    if (feedback_id === undefined) {
      return gvTheme.colors.gray500;
    } else {
      if (feedback_id === 0) {
        return gvTheme.colors.gray500;
      } else if (feedback_id > 0 && feedback_id < 10) {
        return gvTheme.colors.red500;
      } else if (feedback_id >= 10 && feedback_id < 100) {
        return gvTheme.colors.blue500;
      } else if (feedback_id >= 100 && feedback_id < 200) {
        return gvTheme.colors.primary500;
      } else {
        return gvTheme.colors.yellow500;
      }
    }
  };

  return {
    getTextByFeedback,
    getIconByStatus,
    hasAttendanceRegister,
    getColorByFeedback,
    getAttendanceStatusByFeedbackForAttendanceRegister,
  };
};
