import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { ProductListType } from "@/core/models/productsSugestion.model";
import { RootState } from "@/core/store/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ProductsListTypeProps {
  listTypeOptions: ProductListType[];
  status: "loading" | "success" | "failed";
}

const initialState = {
  listTypeOptions: [],
  status: "loading",
} as ProductsListTypeProps;

export const getProductsListType = createAsyncThunk(
  "productsSugestion/getProductsListType",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/products/lists/types`);
      if (response.status !== 200) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const productsListTypeSlice = createSlice({
  name: "productsListTypeSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductsListType.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getProductsListType.fulfilled, (state, action) => {
      state.status = "success";
      state.listTypeOptions = action.payload;
    });
    builder.addCase(
      getProductsListType.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the products list type");
          scope.setExtras(action.payload);
        });
        state.status = "failed";
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectProductsListType = (state: RootState) =>
  state.productsListTypeState.listTypeOptions;
export const selectProductsListTypeStatus = (state: RootState) =>
  state.productsListTypeState.status;

export const productsListTypeReducer = productsListTypeSlice.reducer;
