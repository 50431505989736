import styled, { css } from "styled-components";

export const ReportPickDateButton = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.gray100};
    width: 100%;
    border-radius: 10px;
    padding: 10px;
  `}
`;
