import { ErrorPageLayout } from "@/layouts/ErrorPageLayout";
import { Flex, Heading } from "@gogeo-io/ui-library";
import styled from "styled-components";
import GoVendasSvg from "../assets/logo-app-bar.svg";
import MaintanceSvg from "../assets/maintance.svg";

export function AppUnderMaintenance() {
  return (
    <ErrorPageLayout
      content={
        <Wrapper>
          <img src={MaintanceSvg} alt="sistema fora do ar" />
          <Flex
            css={{ flexDirection: "column", gap: "$2", textAlign: "center" }}
          >
            <Heading size="title3" className="title">
              Aplicação em Manutenção
            </Heading>
            <Heading size="subtitle1" css={{ padding: "0 $6" }}>
              Informamos que nossa aplicação está passando por uma manutenção.
              Durante esse período, a aplicação estará temporariamente
              indisponível. Pedimos desculpas por qualquer inconveniente que
              isso possa causar e agradecemos sua compreensão. Estamos
              trabalhando arduamente para restaurar o serviço o mais rápido
              possível.
            </Heading>
          </Flex>
          <img
            className="govendas-logo"
            src={GoVendasSvg}
            alt="GoVendas Logo"
          />
        </Wrapper>
      }
    />
  );
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[6]};

  img {
    width: 600px;

    @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
      width: 400px;
    }

    @media (max-width: ${(props) => props.theme.mediaSizes.smallLaptop}) {
      width: 300px;
    }
  }

  .title {
    color: ${({ theme }) => theme.colors.primary500};
  }

  .govendas-logo {
    position: absolute;
    bottom: 12px;
    width: 120px;
  }
`;
