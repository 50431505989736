import { MatchTypes, SearchFilters } from "@/core/models/listOfTags.model";
import { SelectAttrOptsPopover } from "@/features/attendance/components/SelectAttrOptsPopover";
import { emitEventToChangeClients } from "@/features/attendance/store/emitEventToChangeClientsSlice";
import {
  selectSelectedSearchFilters,
  setSelectedSearchFilters,
} from "@/features/attendance/store/selectedSearchFiltersSlice";
import { selectMatchTypes } from "@/features/clientInfo/store/matchTypesSlice";
import { useClientsMixpanel } from "@/features/clients/hooks/useClientsMixpanel";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useByPlaceholder } from "@/hooks/useByPlaceholder";
import {
  Flex,
  Input,
  Select,
  SelectItem,
  SelectItemText,
} from "@gogeo-io/ui-library";
import { debounce } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";

export function ParamsTableFilterSearch() {
  const match_types = useSelector(selectMatchTypes);
  const selectedSearchFilters = useSelector(selectSelectedSearchFilters);

  const [filterOptionSelected, setFilterOptionSelected] = useState<MatchTypes>(
    selectedSearchFilters.match_type
  );
  const [searchTerm, setSearchTerm] = useState(
    selectedSearchFilters.match_value
  );

  const { buildPlaceholder } = useByPlaceholder();
  const { searchClientEvent } = useClientsMixpanel();
  const dispatch = useAppDispatch();

  const handleInputChange = async (value: string) => {
    await dispatch(emitEventToChangeClients(false));
    setSearchTerm(value);

    const searchFilters: SearchFilters = {
      match_type: filterOptionSelected,
      match_value: value,
    };

    await dispatch(setSelectedSearchFilters(searchFilters));
    await dispatch(emitEventToChangeClients(true));

    searchClientEvent(value, filterOptionSelected);
  };

  const handleSelectFilterOptionChange = async (value: MatchTypes) => {
    await dispatch(emitEventToChangeClients(false));
    setFilterOptionSelected(value);

    const searchFilters: SearchFilters = {
      match_type: value,
      match_value: searchTerm,
    };

    if (searchTerm !== "") {
      await dispatch(setSelectedSearchFilters(searchFilters));
      await dispatch(emitEventToChangeClients(true));
    }
  };

  const debounceOnChangeInput = debounce(handleInputChange, 500);

  return (
    <Flex
      css={{
        gap: "$2",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Flex css={{ gap: "$2", alignItems: "center" }}>
        <Input
          defaultValue={searchTerm}
          placeholder={buildPlaceholder(filterOptionSelected)}
          style={{ width: "240px" }}
          onChange={(e) => debounceOnChangeInput(e.target.value)}
        />
        <Select
          defaultValue={filterOptionSelected}
          ariaLabel="Select filter"
          onValueChange={(e) => handleSelectFilterOptionChange(e as MatchTypes)}
        >
          {match_types.map((match) => {
            return (
              <SelectItem key={match} value={match}>
                <SelectItemText>{match}</SelectItemText>
              </SelectItem>
            );
          })}
        </Select>
      </Flex>

      <Flex css={{ alignItems: "center", gap: "$2" }}>
        <SelectAttrOptsPopover />
      </Flex>
    </Flex>
  );
}
