import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { ReminderRequest } from "@/core/models/reminder.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useReminderMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const createFeedbackModalOpenedEvent = () => {
    MixpanelTracking.getInstance().track(
      TrackingEvents.REMINDER_CREATE_MODAL_OPENED,
      {
        ...buildProps(),
      }
    );
  };

  const createdReminderEvent = (data: ReminderRequest) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CREATED_REMINDER, {
      ...buildProps(),
      data,
    });
  };

  const createdReminderFailEvent = (errors: any) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CREATED_REMINDER_FAIL, {
      ...buildProps(),
      errors,
    });
  };

  const reminderUpdateEvent = (id: number, isRead: boolean) => {
    MixpanelTracking.getInstance().track(TrackingEvents.REMINDER_UPDATED, {
      ...buildProps(),
      "ID do lembrete": id,
      Visualizado: isRead,
    });
  };

  const reminderDeleteEvent = (id: number, isRead: boolean) => {
    MixpanelTracking.getInstance().track(TrackingEvents.REMINDER_DELETED, {
      ...buildProps(),
      "ID do lembrete": id,
    });
  };

  return {
    createdReminderEvent,
    createdReminderFailEvent,
    reminderUpdateEvent,
    createFeedbackModalOpenedEvent,
    reminderDeleteEvent,
  };
};
