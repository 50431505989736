import { selectUser } from "@/core/store/users/usersSlice";
import {
  Avatar,
  AvatarFallback,
  Flex,
  Heading,
  Text,
} from "@gogeo-io/ui-library";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import UI from "../ui";

export function ReminderHeader() {
  const user = useSelector(selectUser);

  return (
    <UI.ReminderHeader>
      <Flex direction="column">
        <Heading size="title6">{user.name}</Heading>
        <Text>Seus lembretes</Text>
      </Flex>

      <>
        {!isEmpty(user.user_photo) ? (
          <Avatar size="medium">
            <AvatarFallback>{user.user_photo.fallback}</AvatarFallback>
          </Avatar>
        ) : (
          <Avatar size="medium">
            <AvatarFallback>GO</AvatarFallback>
          </Avatar>
        )}
      </>
    </UI.ReminderHeader>
  );
}
