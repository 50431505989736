import styled from "styled-components";

export const TimelineAttendanceRecordAttendanceItem = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space[2]};

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colors.gray100};
  }
`;
