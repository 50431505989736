import { DynamicFormFields } from "@/core/models/business-associate.model";
import { Input } from "@gogeo-io/ui-library";
import { FieldError } from "react-hook-form";

interface BussinessAssociateGenericFormInputTypeProps {
  formField: DynamicFormFields;
  error: FieldError;
  field: {
    [key: string]: any;
  };
  type: "text" | "number";
}

export function BussinessAssociateGenericFormInputType({
  formField,
  error,
  field,
  type,
}: BussinessAssociateGenericFormInputTypeProps) {
  return (
    <Input
      placeholder={
        formField.placeholder ? formField.placeholder : formField.label
      }
      type={type}
      isFullWidth
      hasError={!!error}
      errorMessage={error?.message}
      {...field}
    />
  );
}
