import styled from "styled-components";

export const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.space[5]};
  border-radius: ${({ theme }) => theme.space[1]};
  gap: ${({ theme }) => theme.space[3]};
  border: 1px solid ${({ theme }) => theme.colors.gray100};

  .header {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: ${({ theme }) => theme.space[1]};
    }
  }

  .label {
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: 0;
    padding: 0.625rem 1rem;
    background-color: ${({ theme }) => theme.colors.gray200};
    border-radius: ${({ theme }) => theme.space[1]};
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    div {
      display: flex;
      gap: ${({ theme }) => theme.space[3]};
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
  }

  .tagsWrapper {
    background: ${({ theme }) => theme.colors.gray50};
    padding: ${({ theme }) => theme.space[2]};
    border-radius: ${({ theme }) => theme.space[1]};
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  .buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.space[1]};
  }
`;
