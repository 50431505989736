import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../core/store/store";

interface EmitEventToChangeUsersState {
  status: boolean;
}

const initialState = {
  status: false,
} as EmitEventToChangeUsersState;

export const emitEventToChangeUsersSlice = createSlice({
  name: "emitEventToChangeUsersSlice",
  initialState,
  reducers: {
    emitEventToChangeUsers(state, action: PayloadAction<boolean>) {
      state.status = action.payload;
    },
  },
});

export const selectStatusOfEmitEventToChangeUsers = (state: RootState) =>
  state.emitEventToChangeUsersState.status;

export const { emitEventToChangeUsers } = emitEventToChangeUsersSlice.actions;

export const emitEventToChangeUsersReducer =
  emitEventToChangeUsersSlice.reducer;
