import { Caption } from "@gogeo-io/ui-library";

interface ClientNoteExcerptProps {
  excerpt: string;
}

export function ClientNoteExcerpt({ excerpt }: ClientNoteExcerptProps) {
  return (
    <Caption css={{ color: "$gray500", wordBreak: "break-word" }}>
      {excerpt}
    </Caption>
  );
}
