import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";
import UI from "../ui";

export function NotificationsListSkeleton() {
  const timesToRepeat = Array.from(Array(5).keys());

  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      {timesToRepeat.map((t) => {
        return (
          <UI.NotificationsItem key={t} type="update" isRead>
            <RLSSkeleton width={20} height={20} borderRadius={1} />

            <div className="info">
              <RLSSkeleton width={120} height={20} />
              <RLSSkeleton width={70} height={15} />
            </div>
          </UI.NotificationsItem>
        );
      })}
    </Flex>
  );
}
