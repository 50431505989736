import { Flex, Text } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface PreferedDaysAndWeeksSubtitleProps {
  isOnTooltip?: boolean;
}

export function PreferedDaysAndWeeksSubtitle({
  isOnTooltip = false,
}: PreferedDaysAndWeeksSubtitleProps) {
  return (
    <Flex css={{ width: "100%", alignItems: "center", gap: "$2" }}>
      <Text css={{ color: isOnTooltip ? "$white" : "$gray500" }}>
        Preferência:
      </Text>

      <Flex css={{ alignItems: "center", gap: "$1" }}>
        <LowPrefCircle />
        <Text css={{ color: isOnTooltip ? "$white" : "$gray500" }}>Baixa </Text>
      </Flex>
      <Flex css={{ alignItems: "center", gap: "$1" }}>
        <NormalPrefCircle />
        <Text css={{ color: isOnTooltip ? "$white" : "$gray500" }}>Normal</Text>
      </Flex>
      <Flex css={{ alignItems: "center", gap: "$1" }}>
        <HighPrefCircle />
        <Text css={{ color: isOnTooltip ? "$white" : "$gray500" }}>Alta </Text>
      </Flex>
    </Flex>
  );
}

export const LowPrefCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 9999px;
  background: ${({ theme }) => theme.colors.yellow500};
`;

export const NormalPrefCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 9999px;
  background: ${({ theme }) => theme.colors.blue500};
`;

export const HighPrefCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 9999px;
  background: ${({ theme }) => theme.colors.green500};
`;
