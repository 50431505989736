import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";
import { ListDivider } from "../ListDivider";
import { ClientsTableSkeletonPage } from "./ClientsTableSkeletonPage";

export function ClientsSkeletonPage() {
  return (
    <Flex css={{ width: "100%", flexDirection: "column", gap: "$3" }}>
      <Flex css={{ flexDirection: "column" }}>
        <RLSSkeleton width={330} height={13} borderRadius={4} />
        <RLSSkeleton height={45} borderRadius={4} />
      </Flex>

      <ListDivider $isSkeleton />

      <Flex
        css={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Flex css={{ gap: "$2" }}>
          <RLSSkeleton width={260} height={35} borderRadius={4} />
          <RLSSkeleton width={90} height={35} borderRadius={4} />
        </Flex>

        <Flex css={{ gap: "$2" }}>
          <RLSSkeleton width={130} height={35} borderRadius={4} />
          <RLSSkeleton width={200} height={35} borderRadius={4} />
        </Flex>
      </Flex>

      <ClientsTableSkeletonPage />
    </Flex>
  );
}
