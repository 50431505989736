import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { attrOptsMock } from "@/core/mocks/attrOptsMock";
import { AttrOpts } from "@/core/models/listOfTags.model";
import { RootState } from "@/core/store/store";
import { sleep } from "@/utils/sleep";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ClientAttrOptsState {
  attributes: AttrOpts[];
  attributesToFilter: AttrOpts[];
  status: "loading" | "success" | "failed";
}

const initialState = {
  attributes: [],
  attributesToFilter: [],
  status: "loading",
} as ClientAttrOptsState;

export const getClientAttrOpts = createAsyncThunk(
  "client/getClientAttrOpts",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return attrOptsMock;
    } else {
      try {
        const response = await axios.get(`/client/search/attropts`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const clientAttrOptsSlice = createSlice({
  name: "clientAttrOptsSlice",
  initialState,
  reducers: {
    setAttributesToFilter(state, action: PayloadAction<AttrOpts[]>) {
      state.attributesToFilter = action.payload;
    },
    setDefaultAttributesToFilter(state) {
      state.attributesToFilter = state.attributes.filter(
        (attr) =>
          attr.id === "financial_situation.average_ticket_salesman" ||
          attr.id === "financial_situation.time_without_buy_salesman"
      );
    },
    setAttributesToEmpty(state) {
      state.attributesToFilter = [];
    },
    setAttributeToFilter(state, action: PayloadAction<AttrOpts>) {
      state.attributesToFilter.push(action.payload);
    },
    removeAttributeToFilter(state, action: PayloadAction<AttrOpts>) {
      const stateWithoutTheRemovedOne = state.attributesToFilter.filter(
        (att) => att.id !== action.payload.id
      );

      state.attributesToFilter = stateWithoutTheRemovedOne;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientAttrOpts.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(
      getClientAttrOpts.fulfilled,
      (state, action: PayloadAction<AttrOpts[]>) => {
        state.status = "success";
        const attributesSortedByName = action.payload.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        state.attributes = attributesSortedByName;
      }
    );
    builder.addCase(
      getClientAttrOpts.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the client optional attributes"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectClientAttrOpts = (state: RootState) =>
  state.clientAttrOptsState.attributes;
export const selectAttrOptsToFilter = (state: RootState) =>
  state.clientAttrOptsState.attributesToFilter;

export const {
  setAttributeToFilter,
  removeAttributeToFilter,
  setAttributesToFilter,
  setDefaultAttributesToFilter,
  setAttributesToEmpty,
} = clientAttrOptsSlice.actions;

export const clientAttrOptsReducer = clientAttrOptsSlice.reducer;
