import { DynamicBsIcon } from "@/components/icons/DynamicBsIcon";
import { DynamicFaIcon } from "@/components/icons/DynamicFaIcon";
import { DynamicMdIcon } from "@/components/icons/DynamicMdIcon";
import { ListOfTags } from "@/core/models/listOfTags.model";
import { Tooltip, TooltipContent, TooltipTrigger } from "@gogeo-io/ui-library";
import styled from "styled-components";

interface AttendanceListItemIconsProps {
  list: ListOfTags;
}

export function AttendanceListItemIcons({
  list,
}: AttendanceListItemIconsProps) {
  return (
    <Wrapper>
      {list.brand_new && (
        <Tooltip>
          <TooltipTrigger style={{ background: "none" }}>
            <Item>
              <DynamicMdIcon icon="MdNewReleases" pulse />
            </Item>
          </TooltipTrigger>
          <TooltipContent aria-label="Informações do atendimento">
            Essa lista á uma novidade goVendas
          </TooltipContent>
        </Tooltip>
      )}

      {list.is_suggested_by_govendas && (
        <Tooltip>
          <TooltipTrigger style={{ background: "none" }}>
            <Item>
              <DynamicFaIcon icon="FaLightbulb" />
            </Item>
          </TooltipTrigger>
          <TooltipContent aria-label="Informações do atendimento">
            Essa lista foi uma sugestão do govendas
          </TooltipContent>
        </Tooltip>
      )}

      {list.fixed && (
        <Tooltip>
          <TooltipTrigger style={{ background: "none" }}>
            <Item>
              <DynamicBsIcon icon="BsPinAngleFill" />
            </Item>
          </TooltipTrigger>
          <TooltipContent aria-label="Informações do atendimento">
            Essa lista foi fixada por você
          </TooltipContent>
        </Tooltip>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[1]};
  padding-left: ${({ theme }) => theme.space[1]};
  border-radius: ${({ theme }) => theme.radii.base};
`;

const Item = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary100};

  svg {
    width: 16px;
    height: 16px;
    color: ${(props) => props.theme.colors.primary500};
  }
`;
