import { AdvancedFilterContainer } from "@/components/advancedFilters/AdvancedFilterContainer";
import { GenericDateFilter } from "@/components/advancedFilters/GenericDateFilter";
import { DateFilterTrigger } from "@/components/advancedFilters/Triggers/DateFilterTrigger";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";

interface ReportDateFilterProps {
  dateToReport: string;
  onChangeDate: (filterId: number, newDate: string) => Promise<void>;
}

export function ReportDateFilter({
  dateToReport,
  onChangeDate,
}: ReportDateFilterProps) {
  return (
    <GenericDateFilter
      value={dateToReport}
      filterId={1}
      onUpdatedFilter={onChangeDate}
    >
      <AdvancedFilterContainer
        description="Filtro de data"
        triggerComponent={
          <DateFilterTrigger
            filter={
              {
                label: "Filtro de Data",
                type: "date",
              } as AdvancedFilter
            }
            value={dateToReport}
            notShowCloseIcon
          />
        }
      />
    </GenericDateFilter>
  );
}
