import { BatchCreatedUser } from "@/core/models/user.model";
import { selectUsersStatus } from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, Flex } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { useUsersToCreateColumns } from "../hooks/useUsersToCreateColumns";
import { emitEventToChangeUsers } from "../store/emitEventToChangeUsersSlice";
import { UsersToCreateTable } from "./UsersToCreateTable";

interface UsersToCreateContentProps {
  users: BatchCreatedUser[];
  onCloseDialog: () => void;
}

export function UsersToCreateContent({
  users,
  onCloseDialog,
}: UsersToCreateContentProps) {
  const usersStatus = useSelector(selectUsersStatus);

  const dispatch = useAppDispatch();

  const usersColumns = useUsersToCreateColumns();

  const handleProceed = async () => {
    await dispatch(emitEventToChangeUsers(false));

    onCloseDialog();

    await dispatch(emitEventToChangeUsers(true));
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$4" }}>
      <UsersToCreateTable
        columns={usersColumns}
        data={users}
        status={usersStatus}
      />

      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <Button onClick={handleProceed}>Prosseguir</Button>
      </Flex>
    </Flex>
  );
}
