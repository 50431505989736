import {
  Flex,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";

interface StatusProps {
  value: string;
}

export function TitleCell({ value }: StatusProps) {
  const handleShowMessageTooLong = (message: string) => {
    if (message.length > 34) {
      return `${message.toLowerCase().slice(0, 33)}...`;
    }

    return message.toLowerCase();
  };

  return (
    <Flex css={{ paddingRight: "0.5rem" }}>
      {value ? (
        <Tooltip>
          <TooltipTrigger style={{ background: "transparent" }}>
            <Text css={{ textTransform: "capitalize" }}>
              {handleShowMessageTooLong(value)}
            </Text>
          </TooltipTrigger>
          <TooltipContent
            aria-label="Tooltip de título da mensagem"
            side="bottom"
          >
            {value}
          </TooltipContent>
        </Tooltip>
      ) : (
        <Text>Valor não encontrado</Text>
      )}
    </Flex>
  );
}
