import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";

export const useBreadcrumbsForParamsPage = () => {
  const dispatch = useAppDispatch();

  const breadcrumbsForClientsParams = async () => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Configurações", url: "/profile/user-profile/info" },
      {
        item: "Gestão de atendimento",
        url: "/profile/params/change-params-account",
      },
      {
        item: "Parâmetros dos clientes",
        url: "/profile/params/change-params-clients",
        notNavigate: true,
      },
      { item: "Alterar parâmetros de atendimento dos clientes", url: "" },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  const breadcrumbsForSalesmanParams = async () => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Configurações", url: "/profile/user-profile/info" },
      {
        item: "Gestão de atendimento",
        url: "/profile/params/change-params-account",
      },
      {
        item: "Parâmetros dos vendedores",
        url: "/profile/params/change-params-salesman",
        notNavigate: true,
      },
      { item: "Alterar parâmetros de atendimento dos vendedores", url: "" },
    ];

    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return {
    breadcrumbsForClientsParams,
    breadcrumbsForSalesmanParams,
  };
};
