import { ContentWithImage } from "@/components/ContentWithImage";
import { IFrameTextEditor } from "@/features/textEditor/components/IFrameTextEditor";
import { TextEditorElement } from "@/features/textEditor/components/TextEditorElement";
import { TextEditorLeaf } from "@/features/textEditor/components/TextEditorLeaf";
import AnnotationImg from "@assets/annotation.svg";
import { useCallback, useMemo } from "react";
import { Descendant, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";

interface BusinessAssociateDetailedDescriptionProps {
  content: Descendant[];
}

export function BusinessAssociateDetailedDescription({
  content,
}: BusinessAssociateDetailedDescriptionProps) {
  const renderElement = useCallback(
    (props) => <TextEditorElement {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <TextEditorLeaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const handleBlur = useCallback(() => ReactEditor.deselect(editor), [editor]);

  return (
    <>
      {content && content.length > 0 ? (
        <Slate editor={editor} initialValue={content}>
          <IFrameTextEditor onBlur={handleBlur}>
            <Editable
              style={{
                border: "none",
                outline: "none",
                overflowX: "hidden",
                width: "100%",
                height: "100%",
              }}
              readOnly
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder="Insira aqui seu texto"
              renderPlaceholder={({ children, attributes }) => (
                <div {...attributes}>
                  <p>{children}</p>
                </div>
              )}
            />
          </IFrameTextEditor>
        </Slate>
      ) : (
        <ContentWithImage image={AnnotationImg} size="medium">
          Tão facil que nem precisa de descrição
        </ContentWithImage>
      )}
    </>
  );
}
