import { useGetClientsByFiltersParams } from "@/features/clients/hooks/useGetClientsByFiltersParams";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import {
  getFunnel,
  getFunnelCountOfClients,
  selectAvailableFunnels,
  selectSelectedFunnel,
} from "../store/funnelsSlice";

export function SelectFunnel() {
  const availableFunnels = useSelector(selectAvailableFunnels);
  const selectedFunnel = useSelector(selectSelectedFunnel);

  const dispatch = useAppDispatch();
  const { paramsOnEditListView } = useGetClientsByFiltersParams();

  const onSelectFunnel = async (funnelId: string) => {
    await dispatch(emitEventToChangeClients(false));

    const params = paramsOnEditListView();

    await dispatch(getFunnel({ id: funnelId, params })).then(async (res) => {
      if (res.meta.requestStatus === "fulfilled") {
        await dispatch(getFunnelCountOfClients({ id: res.payload.id, params }));
        await dispatch(emitEventToChangeClients(true));
      }
    });
  };

  return (
    <Select
      defaultValue={selectedFunnel.id}
      ariaLabel="Funis disponíveis"
      onValueChange={onSelectFunnel}
    >
      {availableFunnels.map((funnel) => {
        return (
          <SelectItem value={funnel.id} key={funnel.id}>
            <SelectItemText>{funnel.name}</SelectItemText>
          </SelectItem>
        );
      })}
    </Select>
  );
}
