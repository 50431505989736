import { Flex } from "@gogeo-io/ui-library";

interface BusinessAssociateFieldsContainerProps {
  children: any;
}

export function BusinessAssociateFieldsContainer({
  children,
}: BusinessAssociateFieldsContainerProps) {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        padding: "$1",
        height: "100%",
        overflowY: "auto",
      }}
    >
      {children}
    </Flex>
  );
}
