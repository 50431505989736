import { useDateByDateFilter } from "@hooks/useDateByDateField";
import { ReactNode, useState } from "react";
import { GenericFilterPopover } from "../GenericFilterPopover";
import { GenericDateFilterContent } from "./GenericDateFilterContent";

interface GenericDateFilterProps {
  children?: ReactNode;
  hasError?: boolean;
  filterId: number;
  value: string;
  onUpdatedFilter: (filterId: number, newValue: string) => void;
}

export function GenericDateFilter({
  children,
  hasError = false,
  filterId,
  value,
  onUpdatedFilter,
}: GenericDateFilterProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { getValuesByDateFilter } = useDateByDateFilter();

  const dateFilter = getValuesByDateFilter(value);
  const [optionSelected, setOptionSelected] = useState<string>(dateFilter.type);

  const handleOptionSelected = (newValue: string) => {
    setOptionSelected(newValue);
  };

  const handlePopoverOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const openPopover = () => {
    setIsOpen(true);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  return (
    <GenericFilterPopover
      trigger={children}
      popoverIsOpen={hasError ? false : isOpen}
      onOpenPopover={!hasError && openPopover}
      onPopoverOpenChange={!hasError && handlePopoverOpenChange}
    >
      <GenericDateFilterContent
        filterId={filterId}
        value={value}
        optionSelected={optionSelected}
        onUpdatedFilter={onUpdatedFilter}
        onOptionSelected={handleOptionSelected}
        onClosePopover={closePopover}
      />
    </GenericFilterPopover>
  );
}
