import { ListDivider } from "@/components/ListDivider";
import { UserPermission } from "@/core/models/user-permission.model";
import { selectUserPermissions } from "@/features/usersManagment/store/userPermissionsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, Flex } from "@gogeo-io/ui-library";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { emitEventToChangeUsers } from "../store/emitEventToChangeUsersSlice";
import { UserPermissionListItem } from "./UserPermissionsListItem";

interface UserPermissionsModalContentProps {
  onCloseDialog: () => void;
  onChangePermissions: (permissions: UserPermission[]) => Promise<void>;
}

export function UserPermissionsModalContent({
  onCloseDialog,
  onChangePermissions,
}: UserPermissionsModalContentProps) {
  const userPermissions = useSelector(selectUserPermissions);

  const dispatch = useAppDispatch();

  const [changedPermissions, setChangedPermissions] = useState(userPermissions);

  const handleCancel = () => {
    onCloseDialog();
  };

  const handleChangePermissions = (property: string, newValue: boolean) => {
    setChangedPermissions(
      changedPermissions.map((permission) =>
        permission.property === property
          ? { ...permission, value: newValue }
          : permission
      )
    );
  };

  const handleSave = async () => {
    await dispatch(emitEventToChangeUsers(false));

    onChangePermissions(changedPermissions);
    onCloseDialog();

    await dispatch(emitEventToChangeUsers(true));
  };

  return (
    <UserPermissionsContainer>
      <Flex
        css={{
          flexDirection: "column",
          gap: "$2",
          height: "100%",
        }}
      >
        <PermissionsContainer>
          <Flex css={{ flexDirection: "column", gap: "$2", width: "100%" }}>
            {userPermissions.map((permission, idx) => {
              return (
                <Flex
                  css={{ flexDirection: "column", gap: "$3", width: "100%" }}
                  key={permission.property}
                >
                  <UserPermissionListItem
                    userPermission={permission}
                    onChangePermission={handleChangePermissions}
                  />
                  {idx !== userPermissions.length - 1 && <ListDivider />}
                </Flex>
              );
            })}
          </Flex>
        </PermissionsContainer>

        <Flex
          css={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
            gap: "$2",
            mt: "$4",
          }}
        >
          <Button bordered onClick={handleCancel}>
            Cancelar
          </Button>
          <Button onClick={handleSave}>Salvar</Button>
        </Flex>
      </Flex>
    </UserPermissionsContainer>
  );
}

const UserPermissionsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: ${({ theme }) => theme.space[4]};
  border-radius: ${({ theme }) => theme.space[2]};
`;

export const PermissionsContainer = styled.div`
  display: flex;
  max-height: 500px;
  overflow-y: scroll;
  padding-right: 0.5rem;

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    height: 380px;
  }

  @media (max-width: ${(props) => props.theme.mediaSizes.smallLaptop}) {
    height: 300px;
  }
`;
