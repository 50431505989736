import {
  Flex,
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { Refresh } from "@mui/icons-material";

interface TimelineRefreshButtonProps {
  onHistoryRefresh: () => void;
}

export const TimelineRefreshButton = ({
  onHistoryRefresh,
}: TimelineRefreshButtonProps) => {
  return (
    <Flex css={{ gap: "$2", alignItems: "center" }}>
      <Tooltip>
        <TooltipTrigger style={{ background: "transparent" }}>
          <IconButton
            onClick={onHistoryRefresh}
            type="soft"
            color="gray"
            bordered
          >
            <Refresh />
          </IconButton>
        </TooltipTrigger>
        <TooltipContent aria-label="Atualizar Histórico">
          Atualizar Histórico
        </TooltipContent>
      </Tooltip>
    </Flex>
  );
};
