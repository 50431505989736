import { Button, Checkbox, Flex, Text } from "@gogeo-io/ui-library";

interface GenericBooleanFilterContentProps {
  selectedValue: boolean;
  onCheckboxChange: (value: boolean) => void;
  onApplyFilter: () => void;
}

export function GenericBooleanFilterContent({
  selectedValue,
  onCheckboxChange,
  onApplyFilter,
}: GenericBooleanFilterContentProps) {
  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$4",
      }}
    >
      <Flex
        css={{
          flexDirection: "column",
          gap: "0.5rem",
          justifyContent: "center",
        }}
      >
        <Flex css={{ gap: "0.5rem", alignItems: "center" }}>
          <Checkbox
            checked={selectedValue === true}
            onCheckedChange={(e) => onCheckboxChange(true)}
          />

          <Text>Sim</Text>
        </Flex>
        <Flex css={{ gap: "0.5rem", alignItems: "center" }}>
          <Checkbox
            checked={selectedValue === false}
            onCheckedChange={(e) => onCheckboxChange(false)}
          />

          <Text>Não</Text>
        </Flex>
      </Flex>

      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <Button onClick={onApplyFilter}>Aplicar</Button>
      </Flex>
    </Flex>
  );
}
