import { RulesOptions } from "@/core/models/rules";

export const useCleanAndConvertRules = () => {
  return (rules: RulesOptions) => {
    const cleanedRules = {};

    Object.keys(rules).forEach((key) => {
      const value = rules[key];

      if (value !== "") {
        cleanedRules[key] = key === "generic_filter" ? value : Number(value);
      }
    });

    return cleanedRules;
  };
};
