import { UserRole } from "@/core/models/user.model";

export const useTranslateRole = () => {
  return (role: UserRole) => {
    switch (role) {
      case "SALESMAN":
        return "Vendedor";
      case "SUPERVISOR":
        return "Supervisor";
      case "MANAGER":
        return "Gerente";
      case "ADMIN":
        return "Administrador";
      default:
        return "GoVendas";
    }
  };
};
