import { User, UserHandler } from "@/core/models/user.model";
import {
  removeAllUserToBatchActions,
  updateUser,
} from "@/core/store/users/usersSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@gogeo-io/ui-library";
import { ReactNode, useState } from "react";
import { toast } from "react-toastify";
import { useUsersMixpanel } from "../hooks/useUsersMixpanel";
import { emitEventToChangeUsers } from "../store/emitEventToChangeUsersSlice";
import { AddUpdateUserModalContent } from "./AddUpdateUserModalContent";

interface UpdateUserModalProps {
  user: User;
  children: ReactNode;
}

export function UpdateUserModal({ user, children }: UpdateUserModalProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { editUserEvent } = useUsersMixpanel();

  const handleSetDialogOpen = (value: boolean) => {
    setDialogIsOpen(value);
  };

  const closeDialog = () => setDialogIsOpen(false);

  const updateIser = async (updated_user: UserHandler) => {
    await dispatch(emitEventToChangeUsers(false));

    await dispatch(updateUser({ user_id: user.id, updated_user })).then(
      async (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          editUserEvent(updated_user);
          toast.success(`Usuário ${updated_user.name} alterado com sucesso`);
          await dispatch(removeAllUserToBatchActions());
        }
      }
    );

    await dispatch(emitEventToChangeUsers(true));
  };

  return (
    <Dialog open={dialogIsOpen} onOpenChange={handleSetDialogOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent style={{ maxHeight: "90vh", overflowY: "auto" }}>
        <DialogTitle>Editar Usuário</DialogTitle>
        <AddUpdateUserModalContent
          onCloseDialog={closeDialog}
          onSubmitForm={updateIser}
          userToUpdate={user}
          submitButtonText="Editar Usuário"
        />
      </DialogContent>
    </Dialog>
  );
}
