import { UserPermission } from "@/core/models/user-permission.model";
import { Caption, Flex, Radio, RadioGroup, Text } from "@gogeo-io/ui-library";
import { useState } from "react";
import styled from "styled-components";

interface UserPermissionListItemProps {
  userPermission: UserPermission;
  onChangePermission: (property: string, newValue: boolean) => void;
}

export function UserPermissionListItem({
  userPermission,
  onChangePermission,
}: UserPermissionListItemProps) {
  const [value, setValue] = useState<boolean>(userPermission.value);

  const handleRadioValueChange = (value: string) => {
    setValue(value === "true" ? true : false);
    onChangePermission(
      userPermission.property,
      value === "true" ? true : false
    );
  };

  return (
    <Flex css={{ flexDirection: "column", gap: "$3" }}>
      <Flex css={{ flexDirection: "column", gap: "0rem" }}>
        <Text size="body1" css={{ color: "$gray700" }}>
          {userPermission.title}
        </Text>
        <Caption>{userPermission.description}</Caption>
      </Flex>

      <RadioGroup
        defaultValue={String(value)}
        value={String(value)}
        onValueChange={handleRadioValueChange}
        className="radioGroup"
        style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
      >
        <RadioItem>
          <Text css={{ color: "$gray500" }}>Desativado</Text>
          <Radio value="false" id="false" />
        </RadioItem>
        <RadioItem>
          <Text css={{ color: "$gray500" }}>Ativado</Text>
          <Radio value="true" id="true" />
        </RadioItem>
      </RadioGroup>
    </Flex>
  );
}

const RadioItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
