import { selectUser } from "@/core/store/users/usersSlice";
import { useGoBack } from "@/hooks/useGoBack";
import { Button, Flex, Heading } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AccessDeniedSVG from "../assets/access-denied.svg";

type Size = "small" | "medium" | "large";

interface DeniedToAccessContentProps {
  size?: Size;
}

const getTitleSize = (size: Size) => {
  if (size === "small") {
    return "title5";
  } else if (size === "medium") {
    return "title4";
  } else {
    return "title3";
  }
};

const getSubtitleSize = (size: Size) => {
  if (size === "small") {
    return "subtitle2";
  } else if (size === "medium") {
    return "subtitle2";
  } else {
    return "subtitle1";
  }
};

const getImageWidth = (size: Size) => {
  if (size === "small") {
    return "300px";
  } else if (size === "medium") {
    return "400px";
  } else {
    return "500px";
  }
};

export function DeniedToAccessContent({
  size = "medium",
}: DeniedToAccessContentProps) {
  const user = useSelector(selectUser);

  const { navigateGoBack } = useGoBack();

  const handleGoBack = () => {
    navigateGoBack();
  };

  return (
    <Wrapper $size={size}>
      <img src={AccessDeniedSVG} alt="Sem permissão para acessar página" />
      <Flex css={{ flexDirection: "column", gap: "$2", textAlign: "center" }}>
        <Heading size={getTitleSize(size)} className="title">
          Você não tem acesso a esse recurso
        </Heading>
        <Heading size={getSubtitleSize(size)}>
          Você não pode acessar esse recurso, volte para onde você estava.
        </Heading>
      </Flex>
      <Flex css={{ gap: "$2" }}>
        {user !== undefined && (
          <Button bordered onClick={handleGoBack}>
            Voltar
          </Button>
        )}
      </Flex>
    </Wrapper>
  );
}

interface WrapperProps {
  $size: Size;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[6]};

  img {
    width: ${(props) => getImageWidth(props.$size)};
  }

  .title {
    color: ${({ theme }) => theme.colors.primary500};
  }
`;
