import { FilterWithError } from "@/components/FilterWithError";
import { TagLabel } from "@/components/TagLabel";
import { AddNewAdvancedFilterToClientFilter } from "@/components/advancedFilters/AddNewAdvancedFilterToClientFilter";
import { AdvancedFilterContainer } from "@/components/advancedFilters/AdvancedFilterContainer";
import { GenericBooleanFilter } from "@/components/advancedFilters/GenericBooleanFilter";
import { GenericCurrencyFilter } from "@/components/advancedFilters/GenericCurrencyFilter.tsx";
import { GenericDateFilter } from "@/components/advancedFilters/GenericDateFilter";
import { GenericMultiSelectFilter } from "@/components/advancedFilters/GenericMultiSelectFilter";
import { GenericSelectFilter } from "@/components/advancedFilters/GenericSelectFilter.tsx";
import { BooleanFilterTrigger } from "@/components/advancedFilters/Triggers/BooleanFilterTrigger";
import { CurrencyFilterTrigger } from "@/components/advancedFilters/Triggers/CurrencyFilterTrigger";
import { DateFilterTrigger } from "@/components/advancedFilters/Triggers/DateFilterTrigger";
import { MultiSelectFilterTrigger } from "@/components/advancedFilters/Triggers/MultiSelectFilterTrigger";
import { SelectFilterTrigger } from "@/components/advancedFilters/Triggers/SelectFilterTrigger";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { Tag } from "@/core/models/tag.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Caption,
  Flex,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { isEmpty, isUndefined } from "lodash";
import { useSelector } from "react-redux";
import { useAttendanceMixpanel } from "../hooks/useAttendanceMixpanel";
import {
  removeSelectedAdvancedFilter,
  removeSelectedAdvancedFilterFromOriginalFilters,
  removeSelectedTagFromOriginalFilters,
  removeTagOnSelectedClientFilter,
  selectOriginalUrlFilters,
  selectSelectedAdvancedFilters,
  selectSelectedTagsOnClientFilters,
  updateValueFromSelectedAdvancedFilter,
} from "../store/clientAdvancedFiltersSlice";
import { emitEventToChangeClientsFilters } from "../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import { selectSelectedList } from "../store/listSelectedSlice";

interface SelectedFiltersProps {
  canUpdateFilters?: boolean;
  showAssociatedUsersFilter?: boolean;
}

export function SelectedFilters({
  canUpdateFilters = true,
  showAssociatedUsersFilter = true,
}: SelectedFiltersProps) {
  const selectedAdvancedFilters = useSelector(selectSelectedAdvancedFilters);
  const selectedTags = useSelector(selectSelectedTagsOnClientFilters);
  const selectedList = useSelector(selectSelectedList);
  const originalUrlFilters = useSelector(selectOriginalUrlFilters);

  const dispatch = useAppDispatch();
  const { tagRemovedOnListEvent } = useAttendanceMixpanel();

  const removeTag = async (tag: Tag) => {
    await dispatch(emitEventToChangeClients(false));

    await dispatch(removeTagOnSelectedClientFilter(tag));
    if (isEmpty(selectedList)) {
      await dispatch(removeSelectedTagFromOriginalFilters(tag));
    }

    if (!isEmpty(selectedList)) {
      tagRemovedOnListEvent(tag, {}, selectedList);
    }

    await dispatch(emitEventToChangeClients(true));
    await dispatch(emitEventToChangeClientsFilters(true));
  };

  const handleRemoveFilter = async (filter: AdvancedFilter) => {
    await dispatch(emitEventToChangeClients(false));

    await dispatch(removeSelectedAdvancedFilter(filter));
    if (isEmpty(selectedList)) {
      await dispatch(removeSelectedAdvancedFilterFromOriginalFilters(filter));
    }

    await dispatch(emitEventToChangeClients(true));
    await dispatch(emitEventToChangeClientsFilters(true));
  };

  const handleUpdateValueFromSelectedHistoryFilter = async (
    filterId: number,
    newValue: any
  ) => {
    await dispatch(emitEventToChangeClients(false));

    await dispatch(
      updateValueFromSelectedAdvancedFilter({ filterId, newValue })
    );

    await dispatch(emitEventToChangeClients(true));
    await dispatch(emitEventToChangeClientsFilters(true));
  };

  const getErrorMessageOfAdvancedFilters = (filter: AdvancedFilter): string => {
    const findedError =
      originalUrlFilters.errors.advanced_filters &&
      originalUrlFilters.errors.advanced_filters.find((error) => {
        if (!isUndefined(error.id)) {
          return error.id === filter.id;
        } else {
          return error.property === filter.property;
        }
      });

    return !isUndefined(findedError)
      ? findedError.message
      : "Erro encontrado com o filtro";
  };

  const hasErrorOnFilter = (filter: AdvancedFilter): boolean => {
    if (!isEmpty(selectedList)) {
      return false;
    }

    const findedError =
      originalUrlFilters.errors.advanced_filters &&
      originalUrlFilters.errors.advanced_filters.find((error) => {
        if (!isUndefined(error.id)) {
          return error.id === filter.id;
        } else {
          return error.property === filter.property;
        }
      });

    return !isUndefined(findedError);
  };

  const getErrorMessageOfTags = (tag: Tag): string => {
    const findedError =
      originalUrlFilters.errors.tags &&
      originalUrlFilters.errors.tags.find((error) => {
        if (!isUndefined(error.id)) {
          return error.id === tag.id;
        } else {
          return error.slug === tag.slug;
        }
      });

    return !isUndefined(findedError)
      ? findedError.message
      : "Erro encontrado com o filtro";
  };

  const hasErrorOnTag = (tag: Tag): boolean => {
    if (!isEmpty(selectedList)) {
      return false;
    }

    const findedError =
      originalUrlFilters.errors.tags &&
      originalUrlFilters.errors.tags.find((error) => {
        if (!isUndefined(error.id)) {
          return error.id === tag.id;
        } else {
          return error.slug === tag.slug;
        }
      });

    return !isUndefined(findedError);
  };

  return (
    <Flex css={{ gap: "$2", flexWrap: "wrap" }}>
      {canUpdateFilters && (
        <AddNewAdvancedFilterToClientFilter
          showAssociatedUsersFilter={showAssociatedUsersFilter}
        />
      )}

      {(selectedAdvancedFilters && selectedAdvancedFilters.length > 0) ||
      (selectedTags && selectedTags.length > 0) ? (
        <>
          {selectedAdvancedFilters.map((filter) => {
            if (filter.type === "date") {
              return (
                <GenericDateFilter
                  key={filter.label}
                  hasError={hasErrorOnFilter(filter)}
                  value={filter.value}
                  filterId={filter.id}
                  onUpdatedFilter={handleUpdateValueFromSelectedHistoryFilter}
                >
                  <AdvancedFilterContainer
                    key={filter.label}
                    description={
                      hasErrorOnFilter(filter)
                        ? getErrorMessageOfAdvancedFilters(filter)
                        : filter.description
                    }
                    triggerComponent={
                      <DateFilterTrigger
                        filter={filter}
                        hasError={hasErrorOnFilter(filter)}
                        value={filter.value}
                        onRemoveFilter={handleRemoveFilter}
                        notShowCloseIcon={!canUpdateFilters}
                      />
                    }
                  />
                </GenericDateFilter>
              );
            } else if (filter.type === "currency") {
              return (
                <GenericCurrencyFilter
                  key={filter.label}
                  hasError={hasErrorOnFilter(filter)}
                  value={filter.value}
                  filterId={filter.id}
                  onUpdatedFilter={handleUpdateValueFromSelectedHistoryFilter}
                >
                  <AdvancedFilterContainer
                    key={filter.label}
                    description={
                      hasErrorOnFilter(filter)
                        ? getErrorMessageOfAdvancedFilters(filter)
                        : filter.description
                    }
                    triggerComponent={
                      <CurrencyFilterTrigger
                        filter={filter}
                        hasError={hasErrorOnFilter(filter)}
                        value={filter.value}
                        onRemoveFilter={handleRemoveFilter}
                        notShowCloseIcon={!canUpdateFilters}
                      />
                    }
                  />
                </GenericCurrencyFilter>
              );
            } else if (filter.type === "boolean") {
              return (
                <GenericBooleanFilter
                  key={filter.label}
                  hasError={hasErrorOnFilter(filter)}
                  value={filter.value}
                  filterId={filter.id}
                  onUpdatedFilter={handleUpdateValueFromSelectedHistoryFilter}
                >
                  <AdvancedFilterContainer
                    key={filter.label}
                    description={
                      hasErrorOnFilter(filter)
                        ? getErrorMessageOfAdvancedFilters(filter)
                        : filter.description
                    }
                    triggerComponent={
                      <BooleanFilterTrigger
                        filter={filter}
                        hasError={hasErrorOnFilter(filter)}
                        value={filter.value}
                        onRemoveFilter={handleRemoveFilter}
                        notShowCloseIcon={!canUpdateFilters}
                      />
                    }
                  />
                </GenericBooleanFilter>
              );
            } else if (filter.type === "select") {
              return (
                <GenericSelectFilter
                  key={filter.label}
                  hasError={hasErrorOnFilter(filter)}
                  value={filter.value}
                  filterId={filter.id}
                  onUpdatedFilter={handleUpdateValueFromSelectedHistoryFilter}
                  optionsOf="clients"
                >
                  <AdvancedFilterContainer
                    key={filter.label}
                    description={
                      hasErrorOnFilter(filter)
                        ? getErrorMessageOfAdvancedFilters(filter)
                        : filter.description
                    }
                    triggerComponent={
                      <SelectFilterTrigger
                        filter={filter}
                        hasError={hasErrorOnFilter(filter)}
                        value={filter.value}
                        onRemoveFilter={handleRemoveFilter}
                        notShowCloseIcon={!canUpdateFilters}
                      />
                    }
                  />
                </GenericSelectFilter>
              );
            } else if (filter.type === "multiselect") {
              return (
                <GenericMultiSelectFilter
                  value={filter.value}
                  hasError={hasErrorOnFilter(filter)}
                  filterId={filter.id}
                  onUpdatedFilter={handleUpdateValueFromSelectedHistoryFilter}
                  key={filter.label}
                  optionsOf="clients"
                >
                  <AdvancedFilterContainer
                    key={filter.label}
                    description={
                      hasErrorOnFilter(filter)
                        ? getErrorMessageOfAdvancedFilters(filter)
                        : filter.description
                    }
                    triggerComponent={
                      <MultiSelectFilterTrigger
                        filter={filter}
                        hasError={hasErrorOnFilter(filter)}
                        value={filter.value}
                        onRemoveFilter={handleRemoveFilter}
                        notShowCloseIcon={!canUpdateFilters}
                      />
                    }
                  />
                </GenericMultiSelectFilter>
              );
            } else {
              return (
                <FilterWithError
                  key={filter.id}
                  hasError={hasErrorOnFilter(filter)}
                  hasCloseButton={canUpdateFilters}
                  onClickCloseButton={() => handleRemoveFilter(filter)}
                >
                  <Tooltip>
                    <TooltipTrigger
                      style={{
                        background: "transparent",
                        color: "inherit",
                      }}
                    >
                      <Caption
                        css={{
                          color: hasErrorOnFilter(filter)
                            ? "$red500"
                            : "$gray500",
                        }}
                      >
                        Filtro:{" "}
                      </Caption>
                      {filter.property}
                    </TooltipTrigger>
                    <TooltipContent
                      aria-label={getErrorMessageOfAdvancedFilters(filter)}
                    >
                      {hasErrorOnFilter(filter)
                        ? getErrorMessageOfAdvancedFilters(filter)
                        : filter.description}
                    </TooltipContent>
                  </Tooltip>
                </FilterWithError>
              );
            }
          })}

          <Flex css={{ gap: "$2", flexWrap: "wrap" }}>
            {selectedTags &&
              selectedTags.map((tag) => {
                return (
                  <TagLabel
                    key={tag.id}
                    hasError={hasErrorOnTag(tag)}
                    hasCloseButton={canUpdateFilters}
                    onClickCloseButton={() => removeTag(tag)}
                  >
                    {hasErrorOnTag(tag) ? (
                      <Tooltip>
                        <TooltipTrigger
                          style={{
                            background: "transparent",
                            color: "inherit",
                          }}
                        >
                          <Caption
                            css={{
                              color: hasErrorOnTag(tag)
                                ? "$red500"
                                : "$gray500",
                            }}
                          >
                            Tag:{" "}
                          </Caption>
                          {tag.label}
                        </TooltipTrigger>
                        <TooltipContent aria-label={getErrorMessageOfTags(tag)}>
                          {getErrorMessageOfTags(tag)}
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      <>
                        <Caption
                          css={{
                            color: hasErrorOnTag(tag) ? "$red500" : "$gray500",
                          }}
                        >
                          Tag:{" "}
                        </Caption>
                        {tag.label}
                      </>
                    )}
                  </TagLabel>
                );
              })}
          </Flex>
        </>
      ) : (
        <></>
      )}
    </Flex>
  );
}
