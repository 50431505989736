import { AttendanceJustificationAction } from "../models/attendance-justification.model";

export const attendanceJustificationsActionsMock: AttendanceJustificationAction[] =
  [
    {
      id: 1,
      text: "Não remarcar cliente",
    },
    {
      id: 2,
      text: "Excluir cliente",
    },
    {
      id: 3,
      text: "Ficar 1 semana sem remarcar",
    },
    {
      id: 4,
      text: "Colocar cliente na agenda do dia",
    },
  ];
