import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface UserAdvancedFiltersState {
  available_advanced_filters: AdvancedFilter[];
  selected_advanced_filters: AdvancedFilter[];
  status: "loading" | "success" | "failed";
}

const initialState = {
  available_advanced_filters: [],
  selected_advanced_filters: [],
  status: "loading",
} as UserAdvancedFiltersState;

export const getAvailableUserFilters = createAsyncThunk(
  "userAdvancedFilters/getAvailableUserFilters",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/available-advanced-filters/client`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const userAdvancedFiltersSlice = createSlice({
  name: "userAdvancedFiltersSlice",
  initialState,
  reducers: {
    updateValueFromSelectedUserAdvancedFilter(
      state,
      action: PayloadAction<{ filterId: number; newValue: any }>
    ) {
      state.selected_advanced_filters = state.selected_advanced_filters.map(
        (filter) =>
          filter.id === action.payload.filterId
            ? { ...filter, value: action.payload.newValue }
            : filter
      );
    },
    removeSelectedUserAdvancedFilter(
      state,
      action: PayloadAction<AdvancedFilter>
    ) {
      state.selected_advanced_filters = state.selected_advanced_filters.filter(
        (filter) => filter.id !== action.payload.id
      );
    },
    addSelectedUserAdvancedFilter(
      state,
      action: PayloadAction<AdvancedFilter[]>
    ) {
      state.selected_advanced_filters = [
        ...state.selected_advanced_filters,
        ...action.payload,
      ];
    },
    setSelectedUserAdvancedFilters(
      state,
      action: PayloadAction<AdvancedFilter[]>
    ) {
      state.selected_advanced_filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailableUserFilters.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getAvailableUserFilters.fulfilled, (state, action) => {
      state.status = "success";
      state.available_advanced_filters = action.payload;
    });
    builder.addCase(
      getAvailableUserFilters.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName(
            "Error getting the available filters for user"
          );
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

////////////////////////////////////////////////////

export const selectUserAvailableAdvancedFilters = (state: RootState) =>
  state.userFiltersState.available_advanced_filters;
export const selectUserSelectedAdvancedFilters = (state: RootState) =>
  state.userFiltersState.selected_advanced_filters;
export const selectUserAvailableAdvancedFiltersStatus = (state: RootState) =>
  state.userFiltersState.status;

////////////////////////////////////////////////////

export const {
  setSelectedUserAdvancedFilters,
  removeSelectedUserAdvancedFilter,
  updateValueFromSelectedUserAdvancedFilter,
  addSelectedUserAdvancedFilter,
} = userAdvancedFiltersSlice.actions;

////////////////////////////////////////////////////

export const userFiltersReducer = userAdvancedFiltersSlice.reducer;
