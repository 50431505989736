import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function AccountInfoPageSkeleton() {
  const timesToRepeat = Array.from(Array(7).keys());

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {timesToRepeat.map((t) => {
        return (
          <Flex
            key={t}
            css={{
              flexDirection: "column",
              gap: "$1",
            }}
          >
            <RLSSkeleton width={50} height={13} />
            <RLSSkeleton width={120} height={15} />
          </Flex>
        );
      })}
    </Flex>
  );
}
