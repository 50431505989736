import { ClientPhoto } from "@/core/models/client.model";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Box,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";

interface SharedPreDefinedMessageCellProps {
  created_by: {
    id: number;
    name: string;
    user_photo: ClientPhoto;
  };
}

export function PreDefinedMessageCreatedBy({
  created_by,
}: SharedPreDefinedMessageCellProps) {
  return (
    <Box
      css={{
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      {created_by && (
        <Tooltip>
          <TooltipTrigger style={{ background: "transparent" }}>
            <Avatar size="small">
              {created_by.user_photo.photo_sm_url ? (
                <AvatarImage src={created_by.user_photo.photo_sm_url} />
              ) : (
                <AvatarFallback>
                  {created_by.user_photo.fallback}
                </AvatarFallback>
              )}
            </Avatar>
          </TooltipTrigger>
          <TooltipContent aria-label={created_by.name}>
            {created_by.name}
          </TooltipContent>
        </Tooltip>
      )}
    </Box>
  );
}
