import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { matchTypesMock } from "@/core/mocks/matchTypesMock";
import { RootState } from "@/core/store/store";
import { sleep } from "@/utils/sleep";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ClientState {
  match_types: string[];
  status: "loading" | "success" | "failed";
}

const initialState = {
  match_types: [],
  status: "loading",
} as ClientState;

export const getClientMatchTypes = createAsyncThunk(
  "client/getClientMatchTypes",
  async (_, thunkAPI) => {
    if (import.meta.env.VITE_USE_MOCK === "YES") {
      sleep(import.meta.env.VITE_SLEEP_TIME);
      return matchTypesMock;
    } else {
      try {
        const response = await axios.get(`/client/search/matchtypes`);
        if (response.status !== 200 && response.status !== 202) {
          return thunkAPI.rejectWithValue({
            errorMessage: response.data.message,
            timestamp: response.data.timestamp,
            status: response.status,
            headers: response.headers,
          });
        }
        return response.data;
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          return thunkAPI.rejectWithValue({
            errorMessage: error.response.data.message,
            headers: error.config.headers,
            method: error.config.method,
            url: error.config.url,
            timestamp: error.response.data.timestamp,
            status: error.response.status,
          });
        }
      }
    }
  }
);

export const matchTypesSlice = createSlice({
  name: "matchTypesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientMatchTypes.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getClientMatchTypes.fulfilled, (state, action) => {
      state.status = "success";
      state.match_types = action.payload;
    });
    builder.addCase(
      getClientMatchTypes.rejected,
      (state, action: PayloadAction<any>) => {
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the client match types");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectMatchTypes = (state: RootState) =>
  state.matchTypesState.match_types;
export const selectMatchTypesStatus = (state: RootState) =>
  state.matchTypesState.status;

export const matchTypesReducer = matchTypesSlice.reducer;
