import { Client } from "@/core/models/client.model";
import { PreDefinedMessageSubject } from "@/core/models/preDefinedMessage.model";
import { Recommendation } from "@/core/models/productsSugestion.model";
import { User } from "@/core/models/user.model";
import {
  selectPreDefinedMessagesHasError500,
  selectPreDefinedMessagesStatus,
} from "@/features/profile/store/preDefinedMessagesSlice";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  IconButton,
} from "@gogeo-io/ui-library";
import { Close } from "@mui/icons-material";
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { ErrorContent } from "./ErrorContent";
import { SendProductSuggestionWhatsapp } from "./SendProductSuggestionWhatsapp";
import { SelectPreDefinedMessageToSendSkeleton } from "./Skeleton/SelectPreDefinedMessageToSendSkeleton";

interface WhatsapProductSuggestionDialogProps {
  client: Client;
  user: User;
  recommendation: Recommendation;
  children: ReactNode;
}

export function WhatsappProductSuggestionDialog({
  client,
  user,
  recommendation,
  children,
}: WhatsapProductSuggestionDialogProps) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [subject, setSubject] = useState<PreDefinedMessageSubject>(
    "products_suggestion"
  );

  const preDefinedMessagesStatus = useSelector(selectPreDefinedMessagesStatus);
  const preDefinedMessagesHasError500 = useSelector(
    selectPreDefinedMessagesHasError500
  );

  return (
    <Dialog open={dialogIsOpen} onOpenChange={(e) => setDialogIsOpen(e)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent style={{ width: "500px", maxWidth: "500px" }}>
        <DialogTitle>Enviar Mensagem</DialogTitle>

        <DialogClose asChild>
          <IconButton
            type="ghost"
            css={{ position: "absolute", top: "1rem", right: "1rem" }}
          >
            <Close />
          </IconButton>
        </DialogClose>

        {preDefinedMessagesHasError500 ? (
          <ErrorContent size="medium">
            Não conseguimos buscar suas mensagens pré definidas, tente novamente
            mais tarde ou atualize a página
          </ErrorContent>
        ) : (
          <>
            {preDefinedMessagesStatus === "success" ? (
              <SendProductSuggestionWhatsapp
                subject={subject}
                user={user}
                client={client}
                recommendation={recommendation}
              />
            ) : (
              <SelectPreDefinedMessageToSendSkeleton />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
