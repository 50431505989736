import { Flex, Heading, Text } from "@gogeo-io/ui-library";

interface UserProfileHeaderToPageProps {
  title: string;
  description: string;
}

export function UserProfileHeaderToPage({
  title,
  description,
}: UserProfileHeaderToPageProps) {
  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <Flex css={{ flexDirection: "column", gap: "0.1rem" }}>
        <Heading size="title6" css={{ color: "$gray800" }}>
          {title}
        </Heading>
        <Text css={{ color: "$gray500" }}>{description}</Text>
      </Flex>
    </Flex>
  );
}
