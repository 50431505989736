import { PreDefinedMessageToken } from "../models/preDefinedMessageToken.model";

const preDefinedMessageTokensToUser: string[] = [
  "Meu nome",
  "Meu email",
  "Meu ID",
  "Empresa que trabalho",
  "Meu telefone",
];

const preDefinedMessageTokensToClient: string[] = [
  "Nome do cliente",
  "ID do cliente",
  "Email do cliente",
  "Nome fantansia do cliente",
  "Cidade que mora o cliente",
  "Telefone principal do cliente",
  "Data de última compra do cliente",
  "Ticket médio do cliente",
  "Tempo sem compra do cliente",
];

const preDefinedMessageTokensToProduct: string[] = [
  "Nome do produto",
  "Preço da unidade do produto",
  "Quantidade do produto para desconto",
  "Produto em alta",
];

////////////////////////

export const getGeneralTokens = (): PreDefinedMessageToken[] => {
  const preDefinedMessageTokensGeneral: PreDefinedMessageToken[] = [];
  preDefinedMessageTokensToUser.forEach((token) => {
    preDefinedMessageTokensGeneral.push({ label: token, subject: "general" });
  });
  return preDefinedMessageTokensGeneral;
};

//////////////////

export const getProductSuggestionTokens = (): PreDefinedMessageToken[] => {
  const preDefinedMessageTokensProductSuggestions: PreDefinedMessageToken[] =
    [];
  preDefinedMessageTokensToUser.forEach((token) => {
    preDefinedMessageTokensProductSuggestions.push({
      label: token,
      subject: "product-sugestion",
    });
  });
  preDefinedMessageTokensToClient.forEach((token) => {
    preDefinedMessageTokensProductSuggestions.push({
      label: token,
      subject: "product-sugestion",
    });
  });
  preDefinedMessageTokensToProduct.forEach((token) => {
    preDefinedMessageTokensProductSuggestions.push({
      label: token,
      subject: "product-sugestion",
    });
  });

  return preDefinedMessageTokensProductSuggestions;
};

//////////////////////////////

export const getClientTokens = (): PreDefinedMessageToken[] => {
  const preDefinedMessageTokensClients: PreDefinedMessageToken[] = [];
  preDefinedMessageTokensToUser.forEach((token) => {
    preDefinedMessageTokensClients.push({ label: token, subject: "clients" });
  });
  preDefinedMessageTokensToClient.forEach((token) => {
    preDefinedMessageTokensClients.push({ label: token, subject: "clients" });
  });

  return preDefinedMessageTokensClients;
};
