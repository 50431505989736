import { ProductFilterInfoItem } from "./ProductFilterInfoItem";
import { ProductItem } from "./ProductItem";
import { ProductsFilterTags } from "./ProductsFilterTags";
import { ProductsFilterPrice } from "./ProductsPriceFilter";

export default {
  ProductsFilterPrice,
  ProductsFilterTags,
  ProductItem,
  ProductFilterInfoItem,
};
