import { DynamicFormFields } from "@/core/models/business-associate.model";
import { User } from "@/core/models/user.model";

export const useBusinessAttendanceServiceFields = (
  phone: string,
  user: User,
  fields: DynamicFormFields[]
) => {
  const fieldsToTranslate = [
    {
      field: "PHONE",
      translated_field: phone,
    },
    {
      field: "USER_ID",
      translated_field: user.salesman_id,
    },
    {
      field: "BASE_URL",
      translated_field: fields.find((field) => field.property === "baseurl")
        ?.value,
    },
  ];

  return fieldsToTranslate;
};
