import { SelectedFilters } from "@/features/attendance/components/SelectedFilters";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { ParamsTableFilterSearch } from "./ParamsTableFilterSearch";

interface SearchClientsOnParamsPageProps {
  showAssociatedUsersFilter?: boolean;
}

export function SearchClientsOnParamsPage({
  showAssociatedUsersFilter = true,
}: SearchClientsOnParamsPageProps) {
  return (
    <Flex css={{ flexDirection: "column", gap: "$2" }}>
      <Flex css={{ flexDirection: "column" }}>
        <Caption wider>Filtros</Caption>
        <Flex
          css={{
            padding: "$2 $2",
            borderRadius: "4px",
            background: "$gray50",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SelectedFilters
            showAssociatedUsersFilter={showAssociatedUsersFilter}
          />
        </Flex>
      </Flex>

      <ParamsTableFilterSearch />
    </Flex>
  );
}
