import { AdvancedFilter } from "@/core/models/advanced-filters.model";
import { Caption, Flex } from "@gogeo-io/ui-library";
import { Close as CloseIcon } from "@mui/icons-material";
import { isEmpty, isUndefined } from "lodash";
import styled from "styled-components";

interface FilterTriggerProps {
  value: string;
  hasError?: boolean;
  filter: AdvancedFilter;
  onRemoveFilter?: (filter: AdvancedFilter) => void;
  notShowCloseIcon?: boolean;
}

export function FilterTrigger({
  onRemoveFilter,
  hasError = false,
  value,
  filter,
  notShowCloseIcon = false,
}: FilterTriggerProps) {
  return (
    <Container $hasError={hasError}>
      <Flex
        css={{
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {hasError ? (
          <Caption css={{ color: "$red500" }}>
            {!isUndefined(filter.label) && !isEmpty(filter.label)
              ? filter.label
              : filter.property}
          </Caption>
        ) : (
          <>
            <Caption css={{ color: "$gray500" }}>{filter.label}</Caption>
            {value && <Caption css={{ color: "$gray500" }}>:</Caption>}
            {value && (
              <Caption css={{ color: "$gray700", ml: "4px" }}>{value}</Caption>
            )}
          </>
        )}
      </Flex>
      {!notShowCloseIcon && (
        <CloseIcon onClick={() => onRemoveFilter(filter)} />
      )}
    </Container>
  );
}

interface ContainerProps {
  $hasError: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.space[1]};
  padding: ${(props) => props.theme.space[1]} 0.5rem;
  border-radius: ${(props) => props.theme.space[2]};
  border: 1px solid
    ${(props) =>
      props.$hasError ? props.theme.colors.red300 : props.theme.colors.gray300};
  background: ${(props) => props.theme.colors.gray100};
  cursor: pointer;

  svg {
    color: ${(props) =>
      props.$hasError ? props.theme.colors.red500 : props.theme.colors.gray500};
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;
