import { EmptyContent } from "@/components/EmptyContent";
import { Tag } from "@/core/models/tag.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Flex } from "@gogeo-io/ui-library";
import { useEffect } from "react";
import RLSSkeleton from "react-loading-skeleton";
import { getUserTags } from "../../store/clientAdvancedFiltersSlice";
import { AdvancedFiltersTagsSkeleton } from "./AdvancedFiltersTagsSkeleton";
import { TagItem } from "./TagItem";

interface FoundedTagsProps {
  tags: Tag[];
  tagsStatus: string;
  hasMorePages: boolean;
  currentPage: number;
  query: string;
  onIncreaseCurrentPage: () => void;
  onSetTagsStatus: (status: string) => void;
  onIncreaseTags: (tags: Tag[]) => void;
  onSetHasMorePages: (value: boolean) => void;
}

export function FoundedTags({
  tags,
  tagsStatus,
  hasMorePages,
  currentPage,
  query,
  onIncreaseCurrentPage,
  onSetHasMorePages,
  onIncreaseTags,
  onSetTagsStatus,
}: FoundedTagsProps) {
  const dispatch = useAppDispatch();

  const handleIncreasePageNumber = () => {
    onIncreaseCurrentPage();
  };

  const getPaginatedTags = async (currentPage: number) => {
    await dispatch(
      getUserTags({
        page: currentPage,
        size: import.meta.env.VITE_PAGE_SIZE,
        ...(query !== "" && {
          query,
        }),
      })
    ).then((res) => {
      if (res.payload.tags.length === 0) {
        onSetHasMorePages(false);
      } else {
        onIncreaseTags(res.payload.tags);
      }
    });
  };

  useEffect(() => {
    onSetHasMorePages(true);

    async function loadTags() {
      await dispatch(
        getUserTags({
          page: 0,
          size: import.meta.env.VITE_PAGE_SIZE,
          ...(query !== "" && {
            query,
          }),
        })
      ).then(async (res) => {
        if (res.payload.tags.length === 0) {
          onSetHasMorePages(false);
        } else {
          onIncreaseTags(res.payload.tags);
        }

        onSetTagsStatus("fulfilled");
      });
    }

    loadTags();
  }, []);

  useEffect(() => {
    if (tagsStatus === "fulfilled") {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (hasMorePages) {
            handleIncreasePageNumber();
          }
        }
      });

      intersectionObserver.observe(document.querySelector("#tags-sentry"));

      return () => intersectionObserver.disconnect();
    }
  }, [tagsStatus]);

  useEffect(() => {
    if (currentPage !== 0) {
      if (hasMorePages) {
        getPaginatedTags(currentPage);
      }
    }
  }, [currentPage]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$2",
        height: "100%",
      }}
    >
      {tagsStatus === "fulfilled" ? (
        <>
          {tags.length === 0 ? (
            <>
              <EmptyContent size="small">
                Não encontramos tags com essa busca
              </EmptyContent>
              <div id="tags-sentry"></div>
            </>
          ) : (
            <Flex
              css={{
                flexDirection: "column",
                gap: "$1",
              }}
            >
              {tags.map((tag) => {
                return <TagItem key={tag.label} tag={tag} />;
              })}
              <div id="tags-sentry">
                {hasMorePages ? (
                  <Flex css={{ mt: "$1" }}>
                    <RLSSkeleton width={300} height={35} />
                  </Flex>
                ) : null}
              </div>
            </Flex>
          )}
        </>
      ) : (
        <AdvancedFiltersTagsSkeleton count={8} />
      )}
    </Flex>
  );
}
