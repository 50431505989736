import { Breadcrumb } from "@/core/models/breadcrumb";
import { setBreadcrumbs } from "@/core/store/breadcrumbs/breadcrumbsSlice";
import { selectUser } from "@/core/store/users/usersSlice";
import { selectSelectedList } from "@/features/attendance/store/listSelectedSlice";
import { selectClient } from "@/features/clientInfo/store/clientSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { isEmpty, isUndefined } from "lodash";
import { useSelector } from "react-redux";

export const useHistoryBreadcrumbs = () => {
  const selectedList = useSelector(selectSelectedList);
  const selectedUser = useSelector(selectUser);
  const selectedClient = useSelector(selectClient);

  const dispatch = useAppDispatch();

  const historyBreadcrumbs = async (): Promise<void> => {
    const breadcrumbs: Breadcrumb[] = [
      { item: "Atendimento", url: "/attendance" },
      {
        item: `${
          !isEmpty(selectedList.name) && !isUndefined(selectedList.name)
            ? selectedList.name
            : "Clientes"
        }`,
        url: `/attendance/${
          !isEmpty(selectedList.slug) && !isUndefined(selectedList.slug)
            ? selectedList.slug
            : `${selectedUser.account_id}_${selectedUser.id}_clientes`
        }`,
      },
      {
        item: selectedClient.name,
        url: `/info/client/${selectedClient.id}/client-info`,
      },
      { item: "Histórico de atendimento", url: `` },
    ];
    await dispatch(setBreadcrumbs(breadcrumbs));
  };

  return { historyBreadcrumbs };
};
