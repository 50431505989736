import { Select, SelectItem, SelectItemText } from "@gogeo-io/ui-library";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectAvailableChannels } from "../../store/feedbackSlice";

interface ObservationsProps {
  onChange: (...event: any[]) => void;
  value: string;
}

export function AttendanceFeedbackChannels({
  onChange,
  value,
}: ObservationsProps) {
  const availableChannels = useSelector(selectAvailableChannels);

  const [selectedChannel, setSelectedChannel] = useState(value);

  const handleValueChange = async (newValue: string) => {
    onChange(newValue);
    setSelectedChannel(newValue);
  };

  return (
    <Select
      value={selectedChannel}
      defaultValue={selectedChannel}
      ariaLabel="Select option"
      onValueChange={handleValueChange}
    >
      {availableChannels["channels"]
        .map((channel) => channel["text"])
        .map((option) => {
          return (
            <SelectItem key={option} value={option}>
              <SelectItemText>{option}</SelectItemText>
            </SelectItem>
          );
        })}
    </Select>
  );
}
