import { Flex } from "@gogeo-io/ui-library";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";
import UI from "../ui";

interface MinutesProps {
  handleMinutesChange: (hour: number) => void;
  defaultMinutes: number;
}

export function ReminderPickMinutesInput({
  handleMinutesChange,
  defaultMinutes,
}: MinutesProps) {
  const [minutes, setMinutes] = useState(defaultMinutes);

  const handleInputChange = (e: number) =>
    minutes >= 0 && minutes < 60 ? setMinutes(e) : setMinutes(0);

  const handleDecrementHour = () =>
    minutes > 0 && minutes < 59 ? setMinutes(minutes - 1) : setMinutes(0);

  const handleIncrementHour = () =>
    minutes >= 0 && minutes < 59 ? setMinutes(minutes + 1) : setMinutes(0);

  useEffect(() => {
    handleMinutesChange(minutes);

    if (minutes > 60) {
      handleMinutesChange(0);
      setMinutes(0);
    }
  }, [minutes]);

  return (
    <UI.ReminderPickHourInput>
      <Flex onClick={handleIncrementHour}>
        <KeyboardArrowUpIcon />
      </Flex>
      <input
        value={minutes}
        onChange={(e) => handleInputChange(Number(e.target.value))}
      />
      <Flex onClick={handleDecrementHour}>
        <KeyboardArrowDownIcon />
      </Flex>
    </UI.ReminderPickHourInput>
  );
}
