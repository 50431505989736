import { MixpanelTracking, TrackingEvents } from "@/config/mixpanel";
import { Client } from "@/core/models/client.model";
import { ListOfTagsFilter } from "@/core/models/listOfTags.model";
import { Params } from "@/core/models/params.model";
import { User } from "@/core/models/user.model";
import { BuildMixpanelProps } from "@/utils/buildMixpanelProps";

export const useParamsMixpanel = () => {
  const buildProps = BuildMixpanelProps();

  const sendedBasicMessageEvent = (
    client: Client,
    phone: string,
    user: User
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CLIENT_SENDED_MESSAGE, {
      ...buildProps("client"),
      client,
      phone,
      user,
    });
  };

  const changedParamsEvent = (
    params: Params,
    clients: string[],
    filters: ListOfTagsFilter
  ) => {
    MixpanelTracking.getInstance().track(TrackingEvents.CHANGED_PARAMS, {
      ...buildProps(),
      params,
      clients,
      filters,
    });
  };

  return { sendedBasicMessageEvent, changedParamsEvent };
};
