import axios from "axios";
import type { CreateTicketRequestDTO } from "../model/ticket";

const MOVIDESK_TOKEN = import.meta.env.VITE_MOVIDESK_TOKEN;

const MOVIDESK_BASE_URL = "https://api.movidesk.com/public/v1";

export async function listTicketsForUser() {
  const response = await axios.get("/support/tickets");
  return response.data;
}

export async function createTicket({
  title,
  description,
}: CreateTicketRequestDTO) {
  const response = await axios.post("/support/tickets", {
    title,
    description,
  });
  return response.data;
}

export async function getTicketByID(id: string) {
  const response = await axios.get(`/support/tickets/${id}`);
  return response.data;
}

export async function answerTicket(id: string, answer: string) {
  const response = await axios.patch(`/support/tickets/${id}`, {
    description: answer,
  });
  return response.data;
}
