import { Flex } from "@gogeo-io/ui-library";
import RLSSkeleton from "react-loading-skeleton";

export function UsersRelatedSkeleton() {
  const timesToRepeat = Array.from(Array(4).keys());

  return (
    <Flex css={{ flexDirection: "column", gap: "$4" }}>
      <Flex css={{ alignItems: "center", gap: "$2" }}>
        <RLSSkeleton width={210} height={20} />
        <RLSSkeleton width={210} height={20} />
      </Flex>

      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        {timesToRepeat.map((t) => {
          return (
            <Flex css={{ gap: "$6" }} key={t}>
              <RLSSkeleton width={20} height={20} />
              <RLSSkeleton width={140} height={20} />
              <RLSSkeleton width={140} height={20} />
            </Flex>
          );
        })}
      </Flex>

      <Flex css={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
        <RLSSkeleton width={85} height={35} />
        <RLSSkeleton width={85} height={35} />
      </Flex>
    </Flex>
  );
}
