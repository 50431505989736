import { CellHeaderWithoutSort } from "@/components/CustomCells/CellHeaderWithoutSort";
import {
  BusinessGoal,
  BusinessGoalCategorie,
} from "@/core/models/business-goal.model";
import { useMemo } from "react";
import { BusinessGoalCategoriesCell } from "../components/CustomCells/BusinessGoalCategoriesCell";
import { BusinessGoalsActionsCell } from "../components/CustomCells/BusinessGoalsActionsCell";
import { BusinessNameCell } from "../components/CustomCells/BusinessNameCell";

export const useBusinessGoalsColumns = (
  onDeleteBusinessGoal: (id: number) => void,
  onUpdateBusinessGoalCategories: (
    id: number,
    categories: BusinessGoalCategorie[]
  ) => Promise<void>,
  onUpdateBusinessGoal: (
    id: number,
    name: string,
    color: string,
    categories: BusinessGoalCategorie[]
  ) => Promise<void>
) => {
  const nameCell = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Objetivo de Negócio" />,
        dataIndex: "name",
        key: "name",
        render: (text: string, record: BusinessGoal) => {
          return <BusinessNameCell businessGoal={record} />;
        },
        width: "30%",
      },
    ];
  }, []);

  const categoriesCell = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Categorias" />,
        dataIndex: "categories",
        key: "categories",
        render: (text: string, record: BusinessGoal) => {
          return (
            <BusinessGoalCategoriesCell
              businessGoal={record}
              onUpdateBusinessGoalCategories={onUpdateBusinessGoalCategories}
            />
          );
        },
        width: "70%",
      },
    ];
  }, []);

  const actionsCell = useMemo(() => {
    return [
      {
        title: <CellHeaderWithoutSort headerName="Ações" />,
        dataIndex: "actions",
        key: "actions",
        render: (text: string, record: BusinessGoal) => {
          return (
            <BusinessGoalsActionsCell
              businessGoal={record}
              onDeleteBusinessGoal={onDeleteBusinessGoal}
              onUpdateBusinessGoal={onUpdateBusinessGoal}
            />
          );
        },
        width: "10%",
      },
    ];
  }, []);

  return [...nameCell, ...categoriesCell, ...actionsCell];
};
