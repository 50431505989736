import { ReduxThunkError } from "@/core/errors/ReduxThunkError";
import { Indicators } from "@/core/models/indicators.model";
import { RootState } from "@/core/store/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ListIndicatorsState {
  indicators: Indicators[];
  selectedIndicators: Indicators;
  status: "loading" | "success" | "failed";
}

const initialState: ListIndicatorsState = {
  indicators: [],
  selectedIndicators: {} as Indicators,
  status: "loading",
};

export const getListOfIndicators = createAsyncThunk(
  "indicators/getListIndicators",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/indicators`);
      if (response.status !== 200 && response.status !== 202) {
        return thunkAPI.rejectWithValue({
          errorMessage: response.data.message,
          timestamp: response.data.timestamp,
          status: response.status,
          headers: response.headers,
        });
      }
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue({
          errorMessage: error.response.data.message,
          headers: error.config.headers,
          method: error.config.method,
          url: error.config.url,
          timestamp: error.response.data.timestamp,
          status: error.response.status,
        });
      }
    }
  }
);

export const listOfIndicatorsSlice = createSlice({
  name: "listOfIndicatorsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getListOfIndicators.fulfilled,
      (state, action: PayloadAction<Indicators[]>) => {
        state.indicators = action.payload;
        if (
          Object.keys(state.selectedIndicators).length === 0 &&
          action.payload[0] != undefined
        ) {
          state.selectedIndicators = action.payload[0];
        }
      }
    );
    builder.addCase(
      getListOfIndicators.rejected,
      (state, action: PayloadAction<any>) => {
        state.status = "failed";
        if (action.payload.status === 500) {
        }
        Sentry.configureScope((scope) => {
          scope.setLevel("error");
          scope.setTransactionName("Error getting the indicators");
          scope.setExtras(action.payload);
        });
        Sentry.captureException(new ReduxThunkError(action.payload));
        toast.error(action.payload.errorMessage);
      }
    );
  },
});

export const selectListOfIndicators = (state: RootState) =>
  state.listOfIndicatorsState.indicators;
export const selectSelectedIndicators = (state: RootState) =>
  state.listOfIndicatorsState.selectedIndicators;
export const selectListOfIndicatorsStatus = (state: RootState) =>
  state.listOfIndicatorsState.status;

export const listIndicatorsReducer = listOfIndicatorsSlice.reducer;
