import {
  setAttributesToFilter,
  setDefaultAttributesToFilter,
} from "@/features/clientInfo/store/clientAttrOptsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Flex,
  Heading,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@gogeo-io/ui-library";
import { MdOutlineInfo, MdOutlineMoreVert } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  addTagsOnSelectedClientFilter,
  setSelectedAdvancedFilters,
} from "../store/clientAdvancedFiltersSlice";
import {
  emitEventToChangeClientsFilters,
  selectStatusOfEmitEventToChangeClientsFilters,
} from "../store/emitEventToChangeClientsFiltersSlice";
import { emitEventToChangeClients } from "../store/emitEventToChangeClientsSlice";
import { selectSelectedList } from "../store/listSelectedSlice";
import { SelectedListActions } from "./SelectedListActions";
import { AdvancedFiltersActions } from "./advancedFilters/AdvancedFiltersActions";

export function SelectedListHeader() {
  const selectedList = useSelector(selectSelectedList);
  const statusOfClientsFiltersEvent = useSelector(
    selectStatusOfEmitEventToChangeClientsFilters
  );

  const dispatch = useAppDispatch();

  const handleCleanFilters = async () => {
    await dispatch(emitEventToChangeClients(false));
    await dispatch(emitEventToChangeClientsFilters(false));

    const advancedFilters = selectedList.filters.advanced_filters;
    if (advancedFilters !== undefined && advancedFilters.length > 0) {
      await dispatch(setSelectedAdvancedFilters(advancedFilters));
    } else {
      await dispatch(setSelectedAdvancedFilters([]));
    }

    const tags = selectedList.filters.tags;
    if (tags !== undefined && tags.length > 0) {
      await dispatch(addTagsOnSelectedClientFilter(tags));
    } else {
      await dispatch(addTagsOnSelectedClientFilter([]));
    }

    const attrOpts = selectedList.filters.attr_opts;
    if (attrOpts !== undefined) {
      await dispatch(setAttributesToFilter(attrOpts));
    } else {
      await dispatch(setDefaultAttributesToFilter());
    }

    await dispatch(emitEventToChangeClients(true));
  };

  return (
    <Flex css={{ alignItems: "center", justifyContent: "space-between" }}>
      <Flex css={{ gap: "$2", alignItems: "center" }}>
        <Heading size="title6" css={{ color: "$gray900" }}>
          {selectedList.name}
        </Heading>
        {selectedList.description && (
          <Tooltip>
            <TooltipTrigger style={{ background: "transparent" }}>
              <Flex css={{ color: "$gray500" }}>
                <MdOutlineInfo color="inherit" />
              </Flex>
            </TooltipTrigger>
            <TooltipContent aria-label={selectedList.name}>
              {selectedList.description}
            </TooltipContent>
          </Tooltip>
        )}
      </Flex>
      <Flex
        css={{
          gap: "$2",
          alignItems: "center",
        }}
      >
        {statusOfClientsFiltersEvent && (
          <AdvancedFiltersActions onCleanFilters={handleCleanFilters} />
        )}
        <SelectedListActions
          icon={<MdOutlineMoreVert />}
          list={selectedList}
          showFixButtonAction={false}
        />
      </Flex>
    </Flex>
  );
}
