import { SalesmanToRuptureRule } from "@/core/models/user.model";
import useAppDispatch from "@/hooks/useAppDispatch";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
} from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useRuptureRuleConsumersColumns } from "../hooks/useRuptureRuleConsumersColumns";
import { RuptureRuleTableSkeleton } from "../pages/RuptureRuleTableSkeleton";
import { getSalesmansToRuptureRule } from "../store/ruptureRuleSlice";
import { AddConsumer } from "./AddConsumer";
import { RuptureRuleConsumersTable } from "./RuptureRuleConsumersTable";

interface RuptureRuleConsumersProps {
  selectedConsumers: string[];
  onChange: (...event: any[]) => void;
}

export function RuptureRuleConsumers({
  selectedConsumers,
  onChange,
}: RuptureRuleConsumersProps) {
  const [selectedSalesmans, setSelectedSalesmans] = useState<
    SalesmanToRuptureRule[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleRemoveUser = (user: SalesmanToRuptureRule) => {
    const filteredSelectedSalesmans = selectedSalesmans.filter(
      (salesman) => salesman.salesman_id === user.salesman_id
    );
    setSelectedSalesmans(filteredSelectedSalesmans);
    onChange(filteredSelectedSalesmans.map((salesman) => salesman.salesman_id));
  };

  const handleEditUser = (user: SalesmanToRuptureRule) => {
    const editedUsers = selectedSalesmans.map((salesman) =>
      salesman.salesman_id === user.salesman_id ? { ...user } : { ...salesman }
    );
    setSelectedSalesmans(editedUsers);
    onChange(editedUsers);
  };

  const handleSetSelectedSalesmans = (salesmans: SalesmanToRuptureRule[]) => {
    setSelectedSalesmans(salesmans);
    onChange(salesmans.map((salesman) => salesman.salesman_id));
  };

  const columns = useRuptureRuleConsumersColumns(
    handleRemoveUser,
    handleEditUser
  );

  useEffect(() => {
    setIsLoading(true);

    const loadSalesmans = async () => {
      await dispatch(
        getSalesmansToRuptureRule({
          salesman_ids: selectedConsumers,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const salesmans: SalesmanToRuptureRule[] =
            res.payload as SalesmanToRuptureRule[];

          const returnedSalesmanIds = salesmans.map(
            (salesman) => salesman.salesman_id
          );
          const notFoundSalesmans: SalesmanToRuptureRule[] = selectedConsumers
            .filter((id) => !returnedSalesmanIds.includes(id))
            .map((id) => ({
              id: uuidv4(),
              email: "-----",
              name: "Não cadastrado",
              phone: "-----",
              salesman_id: id,
            }));

          const allSalesmans = [...salesmans, ...notFoundSalesmans];

          setSelectedSalesmans(allSalesmans);
        }
      });
    };

    if (selectedConsumers && selectedConsumers.length > 0) {
      loadSalesmans();
    }

    setIsLoading(false);
  }, [selectedConsumers]);

  return (
    <>
      {!isLoading ? (
        <>
          <RuptureRuleConsumersTable
            columns={columns}
            data={selectedSalesmans}
            isLoading={isLoading}
          />
          <Dialog>
            <DialogTrigger
              style={{ background: "tranparent", width: "fit-content" }}
            >
              <Button bordered style={{ background: "white" }}>
                Editar vendedores
              </Button>
            </DialogTrigger>
            <DialogContent
              style={{ width: "500px" }}
              onInteractOutside={(e) => e.preventDefault()}
              onPointerDownOutside={(e) => e.preventDefault()}
            >
              <DialogTitle>Editar vendedores</DialogTitle>

              <Flex
                css={{
                  flexDirection: "column",
                  gap: "$4",
                  mt: "$4",
                }}
              >
                <AddConsumer
                  originalSelectedSalesmans={selectedSalesmans}
                  onSetSelectedSalesmans={handleSetSelectedSalesmans}
                />
              </Flex>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <RuptureRuleTableSkeleton />
      )}
    </>
  );
}
