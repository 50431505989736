import { Flex } from "@gogeo-io/ui-library";
import { useSelector } from "react-redux";
import { selectClientNotes } from "../../store/clientNotesSlice";
import { ClientNoteItem } from "./ClientNoteItem";

export function ClientNotesList() {
  const clientNotes = useSelector(selectClientNotes);

  return (
    <Flex css={{ flexWrap: "wrap", gap: "3.5rem", padding: "0 $2" }}>
      {clientNotes.map((note) => {
        return <ClientNoteItem key={note.id} note={note} />;
      })}
    </Flex>
  );
}
