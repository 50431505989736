import { Flex, Text } from "@gogeo-io/ui-library";
import { useEffect, useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import UI from "../../ui";

interface TimelineHeaderInfoOrderObservationsProps {
  observations: string;
}

export function TimelineHeaderInfoOrderObservations({
  observations,
}: TimelineHeaderInfoOrderObservationsProps) {
  const [trimmedText, setTrimmedText] = useState("");
  const [showMoreText, setShowMoreText] = useState(false);

  const handleSetShowMoreText = (value: boolean) => setShowMoreText(value);

  useEffect(() => {
    const lines = observations.split("\n");

    const trimmedLines = lines.slice(0, 7);

    const trimmedText = trimmedLines.join("\n");

    if (lines.length > 7) {
      setTrimmedText(trimmedText);
    }
    if (trimmedText.length > 700) {
      setTrimmedText(trimmedText.substring(0, 700));
    }
  }, [observations]);

  return (
    <UI.TimelineAttendanceItemBox>
      {trimmedText.length > 0 ? (
        <>
          {!showMoreText ? (
            <Text>
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {trimmedText}...
              </pre>
            </Text>
          ) : (
            <Text>
              <pre style={{ whiteSpace: "pre-wrap" }}>{observations}</pre>
            </Text>
          )}
        </>
      ) : (
        <Text>
          <pre style={{ whiteSpace: "pre-wrap" }}>{observations}</pre>
        </Text>
      )}
      {trimmedText.length > 0 && (
        <>
          {!showMoreText ? (
            <Flex
              css={{ mt: "$4", color: "$primary500", cursor: "pointer" }}
              onClick={() => handleSetShowMoreText(true)}
            >
              <Text css={{ color: "inherit", fontWeight: "bold" }}>
                Mostar Mais
              </Text>
              <MdExpandMore style={{ fontWeight: "bold" }} />
            </Flex>
          ) : (
            <Flex
              css={{ mt: "$4", color: "$primary500", cursor: "pointer" }}
              onClick={() => handleSetShowMoreText(false)}
            >
              <Text css={{ color: "inherit", fontWeight: "bold" }}>
                Mostar Menos
              </Text>
              <MdExpandLess style={{ fontWeight: "bold" }} />
            </Flex>
          )}
        </>
      )}
    </UI.TimelineAttendanceItemBox>
  );
}
