import { AttendanceRecord } from "@core/models/history.model";
import UI from "../ui";
import { TimelineAttendanceRecord } from "./TimelineAttendanceRecord";
import { TimelineVerticalMark } from "./TimelineVerticalMark";

export interface TimeLineItemProps {
  attendance_record: AttendanceRecord[];
  order_total_amount: number;
  salesman: string;
  historyIsCollapsed?: boolean;
}

export const TimelineItem = ({
  attendance_record,
  order_total_amount,
  salesman,
  historyIsCollapsed = false,
}: TimeLineItemProps) => {
  const lastAttendance = attendance_record[0];
  const othersAttendances = attendance_record.slice(1);

  return (
    <UI.TimelineWrapper>
      <div className="left">
        <TimelineVerticalMark feedback_id={lastAttendance.feedback_id} />
      </div>
      <div className="right">
        <TimelineAttendanceRecord
          lastAttendance={lastAttendance}
          othersAttendances={othersAttendances}
          order_total_amount={order_total_amount}
          salesman={salesman}
          historyIsCollapsed={historyIsCollapsed}
        />
      </div>
    </UI.TimelineWrapper>
  );
};
