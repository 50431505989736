import styled from "styled-components";

export const ReportCard = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.white};
  width: 100%;
  position: relative;

  cursor: pointer;

  svg {
    width: 1.125rem;
    height: 1.125rem;
    color: ${(props) => props.theme.colors.gray500};
  }

  &:hover {
    background: ${(props) => props.theme.colors.gray50};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: transparent;
    transition: all 150ms ease-out;
  }

  &.active {
    background: ${(props) => props.theme.colors.gray50};

    &::before {
      background: ${(props) => props.theme.colors.primary400};
    }
  }

  transition: all 150ms ease-out;
`;
