import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Box, Flex } from "@gogeo-io/ui-library";
import { TimelineExpandButton } from "./TimelineExpandButton";
import { TimelineRefreshButton } from "./TimelineRefreshButton";

interface HistoryActionsProps {
  onHistoryIsCollapsed: () => void;
  onHistoryRefresh: () => void;
}

export const HistoryActions = ({
  onHistoryIsCollapsed,
  onHistoryRefresh,
}: HistoryActionsProps) => {
  const customScreen = useMediaQuery({ screen: "custom", customWidth: 1250 });

  return (
    <Box
      css={{
        position: "absolute",
        right: customScreen ? 0 : 16,
        top: 8,
      }}
    >
      <Flex css={{ flexDirection: "column", gap: "$2" }}>
        <TimelineExpandButton onHistoryIsCollapsed={onHistoryIsCollapsed} />
        <TimelineRefreshButton onHistoryRefresh={onHistoryRefresh} />
      </Flex>
    </Box>
  );
};
