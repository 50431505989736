import { ReactNode } from "react";
import styled from "styled-components";

interface BackgroundProps {
  children: ReactNode;
}

export function Background({ children }: BackgroundProps) {
  return (
    <Container>
      <Navbar />
      {children}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const Navbar = styled.div`
  width: 100%;
  height: 100px;
  background: ${({ theme }) => theme.colors.primary500};
  display: flex;
  align-items: center;
`;
