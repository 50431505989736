import { ReactElement } from "react";
import { useSlate } from "slate-react";
import styled from "styled-components";
import { useTextEditorActions } from "../hooks/useTextEditorActions";

interface TextEditorMarkButtonProps {
  format: string;
  icon: ReactElement;
}

export function TextEditorMarkButton({
  format,
  icon,
}: TextEditorMarkButtonProps) {
  const editor = useSlate();
  const { isMarkActive, toggleMark } = useTextEditorActions();

  return (
    <TextEditorMarkButtonStyled
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </TextEditorMarkButtonStyled>
  );
}

interface TextEditorMarkButtonStyledProps {
  active: boolean;
}

export const TextEditorMarkButtonStyled = styled.div<TextEditorMarkButtonStyledProps>`
  svg {
    color: ${(props) =>
      props.active ? props.theme.colors.gray800 : props.theme.colors.gray400};
    font-size: ${(props) => props.theme.fontSizes.base};
  }
`;
