import styled from "styled-components";

export const ReminderInputDate = styled.div<{
  hasError: boolean;
}>`
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.space[2]};
  border: 2px solid
    ${({ theme, hasError }) =>
      hasError ? theme.colors.red400 : theme.colors.gray200};

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  p {
    display: flex;
    flex: 1;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.gray600};
    }

    &:before {
      content: "";
      width: 2px;
      height: 25px;
      margin-right: 5px;
      background: ${({ theme }) => theme.colors.gray300};
    }
  }
`;
