import { gvTheme } from "@gogeo-io/ui-library";
import styled from "styled-components";

export const Td = styled.td`
  padding: 0.6rem 0;
  border: none;
  width: 100%;

  font-family: ${({ theme }) => theme.fonts.base};
  color: ${({ theme }) => theme.colors.gray600};
  font-size: 0.875rem;
  font-weight: 400;

  @media (max-width: ${gvTheme.mediaSizes.smallLaptop}) {
    font-size: 0.8rem;
  }
`;
